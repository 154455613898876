import * as actionTypes from '../../constants/actionTypes';

const initialState = {
  kits: [],
  totalPages: 0,
  component: null,
  loading: false,
  components: [],
  componentsLoading: false,
  filter: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_KIT_LIST_FROM_KIT_FINDER_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_KIT_LIST_FROM_KIT_FINDER_SUCCESS:
      return {
        ...state,
        kits: action.data.kits,
        totalPages: action.data.totalPages,
        loading: false,
      };

    case actionTypes.GET_KIT_LIST_FROM_KIT_FINDER_FAIL:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.SAVE_FILTER_STATE:
      return {
        ...state,
        filter: action.filter,
      };

    case actionTypes.GET_COMPONENT_INFO_FOR_KIT_FINDER_SUCCESS:
      return {
        ...state,
        component: action.data,
      };

    case actionTypes.GET_COMPONENT_LIST_FOR_KIT_FINDER_START:
      return {
        ...state,
        componentsLoading: true,
      };

    case actionTypes.GET_COMPONENT_LIST_FOR_KIT_FINDER_SUCCESS:
      return {
        ...state,
        componentsLoading: false,
        components: action.data.components,
      };

    case actionTypes.GET_COMPONENT_LIST_FOR_KIT_FINDER_FAIL:
      return {
        ...state,
        componentsLoading: false,
      };

    case actionTypes.CLEAR_COMPONENT_LIST_FOR_KIT_FINDER:
      return {
        ...state,
        components: [],
      };

    case actionTypes.UPDATE_KIT_VISIBILITY_SUCCESS:
      return {
        ...state,
        kits: state.kits.map((kit) =>
          kit.id === action.payload.id
            ? {
                ...kit,
                isVisible: action.payload.visibility,
              }
            : kit,
        ),
      };

    default:
      return { ...state };
  }
};
