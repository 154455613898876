import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import PropTypes from 'prop-types';

const Container = styled.span`
  font-size: 11px;
  color: ${(props) => props.color || '#d48900'};
  font-weight: 500;
  text-align: ${(props) => props.align};
`;

export default function UserNote(props) {
  const { text = '', starred, align = 'center', color } = props;
  return (
    <Container align={align} color={color}>
      {starred && <FontAwesomeIcon icon={faStar} style={{ marginRight: 5 }} />}
      {text}
    </Container>
  );
}

UserNote.propTypes = {
  text: PropTypes.string,
  starred: PropTypes.bool,
  align: PropTypes.string,
  color: PropTypes.string,
};
