import styled from '@emotion/styled';
import React from 'react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 20%;
`;

const Title = styled.div`
  font-size: 18px;
  color: #9e9e9e;
  height: 40px;
  font-weight: bold;
`;

export default function SpecialInstructions({ kit, setState }) {
  if (!kit.components.length) {
    return null;
  }

  return (
    <Container>
      <Title>Special Instructions</Title>
      <textarea
        placeholder="If you have a requested price or if shipping by method other than Standard Ground, please indicate here."
        className="form-control boxinput"
        rows="2"
        name="specialInstructions"
        onChange={setState}
        value={kit?.specialInstructions}
      />
    </Container>
  );
}
