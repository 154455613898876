import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import List from '../../Settings/KitTrays/List';
import { useDispatch, useSelector } from 'react-redux';
import { loadKitTrays } from '../../../redux/actions/kitTrays';
import FancyScroll from '../../../components/FancyScroll';
import { swapKitTray } from '../../../redux/actions/kits';
import GoBack from '../Helpers/GoBack';
import { cmsAccessRoleList, engineering, manufacturing, statuses } from '../../../constants/constants';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    & > hr {
        border-bottom: 2px solid white;
        margin: 25px 0px;
    }
    & > span {
        font-size: 12px;
        font-weight: 500;
        margin-top: 20%;
        text-align: center;
    }
`;

const KitSwapTray = ({
  kit,
  tray,
  role,
  buildDefaultPages,
  setFilterComponents,
}) => {
  const { trays } = useSelector((state) => state.kitTrays);
  const dispatch = useDispatch();
  const status = kit?.status;
  const showMessage =
    status === manufacturing ||
    (cmsAccessRoleList.every((r) => r !== role) && status === engineering);

  useEffect(() => {
    if (status && !showMessage) {
      dispatch(loadKitTrays());
    }
  }, [dispatch, showMessage, status]);

  if (!tray) return null;

  const swapTray = (tray) => {
    setFilterComponents({});
    dispatch(swapKitTray(kit.id, tray.id, buildDefaultPages));
  };

  return (
    <Container>
      <GoBack title="Swap Tray" />
      {showMessage ? (
        <span>
          You can not swap the Tray for a Kit with status:{" "}
          {statuses[kit?.status]?.label}
        </span>
      ) : (
        <>
          <List
            list={[tray]}
            isActive={tray}
            title="Active Tray"
            onItemClick={() => null}
          />
          <hr />
          <FancyScroll offset={280}>
            <List
              list={trays.filter((tray) => tray.isVisible)}
              title="Tray List"
              onItemClick={swapTray}
            />
          </FancyScroll>
        </>
      )}
    </Container>
  );
};

export default KitSwapTray;
