import superagentPromise from "superagent-promise";
import _superagent from "superagent";
// import mixpanel from "mixpanel-browser";
import { getToken } from "./utils/getToken";


// mixpanel.init("90f247f5aa6aa3f0faca8d214b207286");
const superagent = superagentPromise(_superagent, global.Promise);
const API_ROOT = process.env.REACT_APP_API_ENDPOINT;
const responseBody = res => res.body;
const responseBodyCSV = res => res.text;

const defaultResponse = res => res;

let token = getToken();
const tokenPlugin = req => {
  if (token) {
    req.set("authorization", `Bearer ${token}`);
  }
};

const requests = {
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      // .use(mixpanelRequest)
      .then(responseBody),
  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      // .use(mixpanelRequest)
      .set("Access-Control-Allow-Origin", "*")
      .set("mode", "cors")
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      // .use(mixpanelRequest)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      // .use(mixpanelRequest)
      .then(responseBody),
  getCsv: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      // .use(mixpanelRequest)
      .then(responseBodyCSV),
  getAgreement: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      // .use(mixpanelRequest)
      .responseType("blob")
      .then(responseBody)
};
const user = {
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}`, body).then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      // .use(mixpanelRequest)
      .then(responseBody),
  get: (url, body) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      // .use(mixpanelRequest)
      .responseType("blob")
      .then(responseBody)
};

const kitMaker = {
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      // .use(mixpanelRequest)
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      // .use(mixpanelRequest)
      .then(responseBody),
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      // .use(mixpanelRequest)
      .then(responseBody),
  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      // .use(mixpanelRequest)
      .then(responseBody)
};
const CustomKit = {
  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      // .use(mixpanelRequest)
      .set("Accept", "application/pdf")
      .set("Content-Type", "application/pdf")
      .responseType("arraybuffer")
      .then(responseBody)
};



const Default = {
  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      // .use(mixpanelRequest)
      .then(defaultResponse)
};

const UpdateCustomkit = {
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      // .use(mixpanelRequest)
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .then(responseBody)
};


// var mixpanelRequest = function (req) {
//   var tpye = abc(req.url);
//   req.on("response", function (res) {
//     if (token) {
//       // if (!window.location.href.includes("login")) {
//       const auth = window.localStorage.getItem("loggeduser");

//       let user = JSON.parse(auth);
//       mixpanel.identify(user.userName);
//       let options = {};
//       mixpanel.people.set({
//         $name: user.firstName,

//         $email: user.userName
//       });
//       if (req.method === "GET") {
//         options.url = req.url;
//         options.queryString = req._query;
//       }
//       if (req.method === "POST") {
//         options.url = req.url;
//         options.queryString = req._query;
//         options.data = req._data ? req._data : null;
//       }
//       if (req.method === "PUT") {
//         options.url = req.url;
//         options.body = req._data ? req._data : null;
//         options.query = req._query ? req._query : null;
//       }

//       mixpanel.track(tpye, options);
//       // }
//     }
//   });

// req.on("request", function (req, body, err) {
// redirect to login
// if (token) {
//   // if (!window.location.href.includes("login")) {
//   const auth = window.localStorage.getItem("loggeduser");
//   let user = JSON.parse(auth);
//   mixpanel.identify(user.userName);
//   let options = {};
//   mixpanel.people.set({
//     $name: user.firstName,

//     $email: user.userName
//   });
//   if (req.method == "GET") {
//     options.url=req.url;
//     options.queryString = req._query;
//   }
//   if (req.method == "POST") {
//     options.url=req.url;
//     options.queryString = req._query;
//     options.data = req._data ? req._data : null;
//   }
//   if (req.method == "PUT") {
//     options.body = req._data ? req._data : null;
//     options.query = req._query ? req._query : null;
//   }

//   mixpanel.track("ComponentRequestTest", options);
//   // }
// }
// });

// req.on('response', function (res) {
//   if (res.status === 401) {
//     // redirect to login
//   }
// });
// };

// const abc = function (url) {
//   let windowurrl = window.location.href;
//   var typename = "";
//   if (url.includes("user/image")) {
//     typename = "Request User Image";
//   }
//   else if (windowurrl.includes("kits") && url.includes("kits?Categories")) {
//     typename = "Request Kit";
//   }
//   else if (windowurrl.includes("kits") && url.includes("kits/categories")) {
//     typename = "Request Kit Categories";
//   }
//   else if (windowurrl.includes("component") && url.includes("components?Categories")) {
//     typename = "Request Component";
//   }
//   else if (windowurrl.includes("component") && url.includes("custom-kits/saved")) {
//     typename = "Request Custome Kits";
//   }

//   else if (windowurrl.includes("component") && url.includes("components/categories")) {
//     typename = "Request Component Categories";
//   }
//   else if (windowurrl.includes("login")) {
//     typename = "Request Login";
//   }
//   else if (windowurrl.includes("editprofile")) {
//     typename = "Request EditProfile";
//   }
//   else if (windowurrl.includes("kitmaker")) {
//     typename = "Request Kitmaker";

//   }
//   else if (windowurrl.includes("savedaskcomplete") && url.includes("custom-kits/")) {
//     typename = "Request Saved ASK For Complete  ";
//   }
//   else if (windowurrl.includes("savedaskcomplete") && url.includes("product-families")) {
//     typename = "Request Product Family";
//   }

//   else if (windowurrl.includes("confirmation")) {
//     typename = "Request Confirmation  ";
//   }
//   else if (windowurrl.includes("savedask") && url.includes("custom-kits/saved")) {
//     typename = "Request SavedAsk  ";
//   }
//   else if (windowurrl.includes("savedask") && url.includes("custom-kits/past")) {
//     typename = "Request SavedAsk Past ";
//   }
//   else if (url.includes("/pdf")) {
//     typename = " Request PDF";
//   }
//   else if (url.includes("/csv")) {
//     typename = " Request CSV";
//   }
//   else if (url.includes("/sua-template")) {
//     typename = "Request Agreement";
//   }
//   return typename;
// };

const Auth = {
  current: () => requests.get("/user"),

  login: (username, password) => {
    return superagent
      .post(API_ROOT + "/token")
      .send("userName=" + username)
      .send("password=" + password)
      .then(responseBody);
  },

  loginFailed: () => { },
  register: (username, password, firstName, lastName) => {
    return user.post("/api/user", { username, password, firstName, lastName });
  },

  updateUser: (UserName, FirstName, LastName, Street, SuiteApt, City, ZipCode, Phone, Image) => {
    let data = new FormData();
    data.append("userName", UserName);
    data.append("firstName", FirstName);
    data.append("lastName", LastName);
    data.append("street", Street);
    data.append("suiteApt", SuiteApt);
    data.append("city", City);
    data.append("zipCode", ZipCode);
    data.append("phone", Phone);
    data.append("image", Image);
    return user.put("/api/user", data);
  },

  getProfileImage: () => {
    return user.get("/api/user/image");
  }
};

const Home = {
  categories: () => {
    return requests.get("/api/components/categories");
  },
  components: (categories, PartTypeId, search, pageSize, page) => {
    return requests.get(
      "/api/components?Categories=" +
      categories +
      "&PartTypeId=" +
      PartTypeId +
      "&Search=" +
      search +
      "&PageSize=" +
      pageSize +
      "&Page=" +
      page
    );
  },
  componentdetail: Id => {
    return requests.get("/api/components/" + Id);
  },

  componetRequestForm: (catalogData, Id) => {
    var data = new FormData();
    data = JSON.stringify(catalogData);
    return kitMaker.post("/api/components/" + Id + "/order-sample", data);
  },

  getPdfCatalog: Id => {
    return CustomKit.get("/api/components/" + Id + "/pdf");
  }
};
const KITCATLOG = {
  kitcatalog: (
    Categories = '',
    Search = '',
    ComponentId,
    LikeKitId,
    PageSize,
    Page,
  ) => {
    console.log({ Categories, Search, ComponentId, LikeKitId, PageSize, Page });
    return requests.get(
      '/api/kits?Categories=' +
        Categories +
        '&Search=' +
        Search +
        '&ComponentId=' +
        ComponentId +
        '&LikeKitId=' +
        LikeKitId +
        '&PageSize=' +
        PageSize +
        '&Page=' +
        Page,
    );
  },
  categories: () => {
    return requests.get('/api/kits/categories');
  },
  kitbyId: (Id) => {
    return requests.get('/api/kits/' + Id);
  },
  getCSVData: (Id) => {
    return requests.getCsv('/api/kits/' + Id + '/csv');
  },

  getAgreement: () => {
    return requests.getAgreement('/api/kits/sua-template');
  },
  requestSample: (formData, kitId) => {
    var data = new FormData();
    data = JSON.stringify(formData);
    return kitMaker.post('/api/kits/' + kitId + '/order-sample', data);
  },
  postAgreement: (formData, notes, kitId) => {
    // var data = new FormData();
    // data.append('notes',notes);
    // data.append('filename',formData.path);
    return (
      superagent
        .post(API_ROOT + '/api/kits/' + kitId + '/sua')
        .use(tokenPlugin)
        // .use(mixpanelRequest)
        .field('notes', notes)
        .attach(formData.path, formData)
    );
  },
  pdfGenerate: (kitId) => {
    return CustomKit.get('/api/kits/' + kitId + '/components-pdf');
  },
};

const Cats = {
  getAll: () => requests.get("/api/categories")
};

const KITMAKER = {
  customKits: formData => {
    var data = new FormData();
    data = JSON.stringify(formData);
    return kitMaker.post("/api/custom-kits", data);
  },

  addNewComponentToKit: (component, id) => {
    return kitMaker.put("/api/custom-kits/" + id + "/components", component);
  },
  deletenewComponent: (kitId, componentId) => {
    return kitMaker.del(
      "/api/custom-kits/" + kitId + "/components?ids=" + componentId
    );
  },
  deleteKit: (kitId) => {
    return kitMaker.del("/api/custom-kits/" + kitId);
  },
  displaynewComponent: kitId => {
    return kitMaker.get("/api/custom-kits/" + kitId);
  },

  addComponentToKit: (kitId, kitData) => {
    var data = new FormData();
    data = JSON.stringify(
      (kitData ?? []).filter((component) => component?.quantity),
    );
    return kitMaker.post("/api/custom-kits/" + kitId + "/components", data);
  },
  customkitConfirmation: (customFormData, kitId) => {
    var data = new FormData();
    data = JSON.stringify(customFormData);
    return kitMaker.post("/api/custom-kits/" + kitId + "/confirm", data);
  },



  pdfGenerate: kitId => {
    return CustomKit.get("/api/custom-kits/" + kitId + "/pdf");
  },

  savedAskComplete: (CustomKitId, customData) => {
    var data = new FormData();
    data = JSON.stringify(customData);
    return CustomKit.get("/api/custom-kits/" + CustomKitId, data);
  },
  productFamily: () => {
    return kitMaker.get("/api/product-families");
  },
  updateSavedKit: (name, id) => {
    var data = new FormData();
    data = JSON.stringify(name);

    return UpdateCustomkit.put("/api/custom-kits/" + id, data);
  },

  estimatedPrice: Id => {
    return kitMaker.post("/api/custom-kits/" + Id + "/calculate");
  },

  validateName: (name, id) => {
    return kitMaker.get('/api/custom-kits/validate-name?name=' + name + "&id=" + id);

  }
};

const CART = {
  getCartCustomKits: () => {
    return kitMaker.get('/api/cart');
  },

  postCustomKits: (componentId) => {
    return kitMaker.post('/api/cart/' + componentId);
  },

  deleteCustomKits: (componentId) => {
    return kitMaker.del('/api/cart/' + componentId);
  },

  deleteAllCartComponents: () => {
    return kitMaker.del('/api/cart');
  }
}

const SAVEDASK =
{
  savedASk: () => {
    return Default.get("/api/custom-kits/saved");
  },

  previouslyOrderedKit: () => {
    return kitMaker.get("/api/custom-kits/past");
  },

  savedASKJustLikeKit: (LikeCustomKitId, page) => {
    return kitMaker.get("/api/kits/just-like-custom-kit?LikeCustomKitId=" + LikeCustomKitId + "&PageSize=" + 12 + "&Page=" + page);
  },

  cartJustLikeKit: (page) => {
    return kitMaker.get("/api/kits/just-like-cart?PageSize=" + 12 + "&Page=" + page);
  },
}

export default {
  Auth,
  Home,
  Cats,
  KITCATLOG,
  KITMAKER,
  CART,
  SAVEDASK,
  setToken: _token => {
    token = _token;
  }
};
