import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faSortDown, faAward } from '@fortawesome/free-solid-svg-icons'

const CategoryElement = styled.div`
    font-size: 12px;
    display: flex;
    font-weight: 500;
    align-items: baseline;
    background: ${props => props.isActive ? '#8D92AB' : ''};
    color: ${props => props.isActive ? 'white' : 'black'};
    cursor: pointer;
    padding: 10px 15px;
    align-items: center;

    & > span {
        padding: 0px 25px;
    }

    &:hover {
        background: ${props => !props.isActive ? '#eeeeee' : ''};
    }
`;

const SubCategoryElement = styled(CategoryElement)`
    background: ${props => props.isActive ? '#262268' : ''};
    padding-left: 30px;
`;

const Category = ({ category, ids, openMenu }) => {
    const showSubCategory = ids.find(cid => cid === category.id);

    const nestedCategories = (category.children || []).map(subCategory => {
        const activeSubCategory = ids.find(cid => cid === subCategory.id);

        return (
            <SubCategoryElement
                key={subCategory.id}
                isActive={activeSubCategory}
                onClick={() => openMenu(subCategory, true, category.id)}
            >
                {subCategory.isFavorite && <FontAwesomeIcon icon={faAward} style={{ position: 'absolute' }} />}
                <span>{subCategory.name}</span>
                {subCategory.children.length > 0 && <FontAwesomeIcon icon={faSortDown} />}
                {!subCategory.isVisible && <FontAwesomeIcon icon={faEyeSlash} style={{ color: '#CC2222' }} />}
            </SubCategoryElement>
        );
    });

    return (
        <>
            <CategoryElement isActive={showSubCategory} onClick={() => openMenu(category, false)}>
                {category.isFavorite && <FontAwesomeIcon icon={faAward} style={{ position: 'absolute' }} />}
                <span>{category.name}</span>
                {category.children.length > 0 && <FontAwesomeIcon icon={faSortDown} />}
                {!category.isVisible && <FontAwesomeIcon icon={faEyeSlash} style={{ color: '#CC2222' }} />}
            </CategoryElement>
            {showSubCategory && nestedCategories}
        </>
    );
};

export default Category;
