import styled from '@emotion/styled';
import { faPencilAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const SelectSection = styled.div`
    font-size: 12px;
    padding: 25px 10px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
        font-weight: 500;
    }

    svg {
        margin-left: 10px;
        cursor: pointer;
    }
`;

const SelectItem = styled.div`
  display: flex;
  border-radius: 2px;
  font-size: 10px;
  overflow: hidden;
  padding: 3px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  background: white;
  color: black;
  font-weight: 400;
  width: 100%;
  margin: 2px;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    `
      color: white;
      background: #61A431;
    `};

  & > svg {
    margin-left: 10px;
    opacity: ${(props) => (props.isActive ? 1 : 0.5)};
    font-size: 12px;

    &:hover {
      color: red;
      opacity: 1;
    }
  }

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

const EditableList = (props) => {
  const {
    list,
    onItemClick,
    onItemRemove,
    type,
    title,
    showDropdown,
    currentItemId,
  } = props;

  return (
    <>
      <SelectSection>
        <span>{title}</span>
        <FontAwesomeIcon
          icon={faPencilAlt}
          onClick={() => showDropdown(type)}
        />
      </SelectSection>

      <div style={{ display: "flex", flexWrap: "wrap", padding: "0px 10px" }}>
        {list.map(({ id, name, labelName, number }) => (
          <SelectItem key={id} isActive={currentItemId === id}>
            <span
              onClick={(event) => onItemClick(id, type, number, event)} // event for tooltip positioning
              data-tip
              data-for={type !== "incompatible" ? "Tip" : null}
              title={name || labelName}
            >
              {number && number + "-"}
              {name || labelName}
            </span>
            <FontAwesomeIcon
              icon={faTimesCircle}
              onClick={() => onItemRemove(id, type)}
            />
          </SelectItem>
        ))}
      </div>
    </>
  );
};

export default EditableList;
