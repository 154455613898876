import axios from 'axios';

const base =
  process.env.NODE_ENV === "test"
    ? "http://test.com/"
    : process.env.REACT_APP_API_ENDPOINT;

const instance = axios.create({
  baseURL: base,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!axios.isCancel(error)) {
      if (error.response?.status === 401) {
        localStorage.clear();
        window.location = "/";
      } else {
        return Promise.reject(error);
      }
    }
  }
);

export default instance;
