import * as actionTypes from '../../constants/actionTypes';
import { getToken } from '../../utils/getToken';
import axios from '../axiosMiddleware/axios';

export const login = (userName, password) => async dispatch => {
    try {
        dispatch({ type: actionTypes.LOGIN_STARTED });
        const formData = new FormData(); // @todo why login accepts formData in api ?
        formData.append('userName', userName);
        formData.append('password', password);

        const res = await axios.post('/token', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        if (res.data.resetPasswordCode) {
            dispatch({ type: actionTypes.REDIRECT, redirectTo: `/password-reset?email=${res.data.user.userName}&code=${res.data.resetPasswordCode}`});
        } else {
            dispatch({ type: actionTypes.LOGIN, payload: res.data });
        }
    } catch (err) {
        dispatch({ type: actionTypes.LOGIN_FAILED, error: err });
    }
};

export const getUserImage = () => async dispatch => {
    dispatch({ type: actionTypes.GET_PROFILE_IMAGE_START });

    try {
        const res = await axios.get('/api/user/image', { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.GET_PROFILE_IMAGE_SUCCESS, image: res.data });
    } catch (err) {
        dispatch({ type: actionTypes.GET_PROFILE_IMAGE_FAIL, error: err });
    }
};

export const requestResetLink = (Email, CallbackUrl) => async dispatch => {
    dispatch({ type: actionTypes.REQUEST_RESET_LINK_START });

    try {
        const request = {
            Email,
            CallbackUrl
        }
        await axios.post('/api/user/forgot-password', request);

        dispatch({ type: actionTypes.REQUEST_RESET_LINK_SUCCESS });
        dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'success', message: 'We have sent a link to reset your password' } });
    } catch (err) {
        dispatch({ type: actionTypes.REQUEST_RESET_LINK_FAIL, error: err });
        dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'error', message: 'Can\'t send reset link to email' } });
    }
};

export const resetPassword = (Email, Password, Code) => async dispatch => {
    dispatch({ type: actionTypes.RESET_PASSWORD_START });

    try {
        const request = {
            Email,
            Password,
            Code
        }
        const res = await axios.post('/api/user/reset-password', request);

        dispatch({ type: actionTypes.RESET_PASSWORD_SUCCESS });
        dispatch({ type: actionTypes.LOGIN, payload: res.data });
        // dispatch({type: actionTypes.SHOW_SNACKBAR, snackbar: {type: 'success', message: 'Password changed'}});
    } catch (err) {
        dispatch({ type: actionTypes.RESET_PASSWORD_FAIL, error: err });
        dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'error', message: "Can't reset password" } });
    }
};

export const logout = () => dispatch => dispatch({ type: actionTypes.LOGOUT });
