import React, { useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Wrapper from '../../../components/Wrapper/Wrapper';
import ComponentsActiveMenu from './ComponentsActiveMenu';
import Tabs from './Tabs';
import Images from './Images/Images';
import { cmsComponentsTabs, overlayStyle } from '../../../constants/constants';
import { addFallbackToComponent, createCmsComponentImage, deleteCmsComponentImage, deleteFallbackFromComponent, loadCmsComponentInfo, loadCmsComponents, updateCmsComponentImage, updateCmsComponentInfo } from '../../../redux/actions/components';
import { loadCategories } from '../../../redux/actions/categories';
import CompatibleReplacement from './CompatibleReplacements/CompatibleReplacements';
import TechnicalDetails from './TechnicalDetails';
import Confirmation from '../../../components/Confirmation/Confirmation';

const Content = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: center;
`;

const positiveProperties = ['width', 'height', 'depth', 'weight', 'cost', 'overrideCost'];

const Components = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [currentComponent, setCurrentComponent] = useState({});
    const [searchValue, setSearchValue] = useState('');

    const [tabs, setTabs] = useState([]);
    const [page, setPage] = useState(0);

    const { components, loading, component, categories, fallbackComponentSearchList, totalPages, justCreated } = useSelector(state => ({
        components: state.components.list,
        totalPages: state.components.totalPages,
        loading: state.components.componentsLoading,
        justCreated: state.components.justCreated,
        component: state.components.component,
        categories: state.categories.list,
        fallbackComponentSearchList: state.components.fallbackComponentSearchList
    }));

    useEffect(() => {
        dispatch(loadCmsComponents({}));
        dispatch(loadCategories());
    }, [dispatch]);

    useEffect(() => {
        if (justCreated) {
            onComponentClick(justCreated);
        }
        // eslint-disable-next-line
    }, [justCreated])

    useEffect(() => {
      if (component) {
        setCurrentComponent(component);
        setTabs(
          cmsComponentsTabs.map((tab) => ({
            ...tab,
            path: tab.path + '/' + component.id,
          })),
        );
      }
      // eslint-disable-next-line
    }, [component]);

    useEffect(() => {
        if (history.location.pathname === '/cms-components' && component) {
            setCurrentComponent({});
            setTabs([]);
        }
    }, [history.location.pathname, component]);

    const onSearch = (e, isFallback = false) => {
        !isFallback && setSearchValue(e.target.value);
        search(e.target.value, isFallback, page);
    };

    const search = useMemo(() => _.debounce((value, isFallback, page) => dispatch(loadCmsComponents({ search: value, page }, isFallback)), 1000), [dispatch]);

    const onComponentClick = (component) => {
        dispatch(loadCmsComponentInfo(component.id));
        history.push({ pathname: `/cms-components/images/${component.id}`, state: { component } });
    };

    const onImageChange = (e, selectedName = null) => {
        const file = e.target.files[0];

        if (!file) return;

        const name = selectedName || e.target.name;
        const images = [...currentComponent.images];
        const index = images.findIndex(img => img.name === name);

        if (index > -1) images[index] = { ...images[index], url: URL.createObjectURL(file), name };
        else images.push({ url: URL.createObjectURL(file), name, file });

        setCurrentComponent({ ...currentComponent, images });
        dispatch(index > -1 ? updateCmsComponentImage(currentComponent.id, images[index].id, file, name) : createCmsComponentImage(currentComponent.id, file, name))
    };

    const [deleteImageBuffer, setDeleteImageBuffer] = useState(null);

    const deleteImage = () => {
        const images = [...currentComponent.images];
        const index = images.findIndex(img => img.id === deleteImageBuffer);

        dispatch(deleteCmsComponentImage(currentComponent.id, images[index].id));
        setCurrentComponent({ ...currentComponent, images: images.filter(f => f.name !== deleteImageBuffer) });
        setDeleteImageBuffer(null);
    };

    const addReplacements = (fallback) => {
        const replacements = [...currentComponent.replacements];
        replacements.push({ id: fallback.id, number: fallback.number, description: fallback.description, labelName: fallback.labelName });
        dispatch(addFallbackToComponent(currentComponent.id, fallback.id));
        setCurrentComponent({ ...currentComponent, replacements });
    };

    const deleteFallback = (fallback) => {
        dispatch(deleteFallbackFromComponent(component.id, fallback.id));
        setCurrentComponent({ ...currentComponent, replacements: currentComponent.replacements.filter(r => r.id !== fallback.id) });
    };

    const onInputChange = (e) => {
        const { name, value } = e.target;
        const isNegative = positiveProperties.find(prop => prop === name) && value < 0;
        setCurrentComponent({ ...currentComponent, [name]: isNegative ? 0 : value });
    };

    const onPageChange = (page) => {
        setPage(page);
        dispatch(loadCmsComponents({ search: searchValue, page }));
    };

    const onSubmit = () => dispatch(updateCmsComponentInfo(currentComponent));

    const disabled = Object.keys(currentComponent).filter(key => key !== 'overrideCost').some(key => currentComponent[key] === '' || currentComponent[key] < 0);

    return (
        <div className="main-layout">
            <div className="content-area" style={{ paddingBottom: 0, paddingLeft: 0 }}>
                <LoadingOverlay
                    active={loading}
                    spinner
                    styles={overlayStyle}
                >
                    <div style={{ display: 'flex', height: 'calc(100vh - 65px)', overflow: 'hidden' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <ComponentsActiveMenu
                                components={components}
                                onSearch={onSearch}
                                searchValue={searchValue}
                                onComponentClick={onComponentClick}
                                showNavigation={!(history.location.pathname === '/cms-components')}
                                component={currentComponent}
                                overviewChange={onInputChange}
                                categories={categories}
                                onSelectChange={(option, name) => setCurrentComponent({ ...currentComponent, [name]: option ? { id: option.id, name: option.name } : null })}
                                onSubmit={onSubmit}
                                disabled={disabled}
                                customGoBack={() => history.replace('/cms-components')}
                                totalPages={totalPages}
                                handlePagination={onPageChange}
                                activePage={page}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Wrapper>
                                <Content>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <Tabs tabs={tabs} />
                                        <Switch>
                                            <Route exact path="/cms-components/images/:id" render={() => <Images component={currentComponent} onImageChange={onImageChange} deleteImage={(id) => setDeleteImageBuffer(id)} />} />
                                            <Route exact path="/cms-components/compatible-replacement/:id" render={() =>
                                                <CompatibleReplacement component={currentComponent} components={fallbackComponentSearchList} onSearch={onSearch} addReplacements={addReplacements} deleteFallback={deleteFallback} />} />
                                            <Route path="/cms-components/technical-data/:id" render={() =>
                                                <TechnicalDetails component={currentComponent} onInputChange={onInputChange} />}
                                            />
                                            <Route exact path="/cms-components/auto-add/:id" render={() => <div>To be determined</div>} />
                                        </Switch>
                                        {deleteImageBuffer && <Confirmation confirm={deleteImage} text={'Are you sure you want to delete "' + currentComponent.images.find(x => x.id === deleteImageBuffer)?.name + '" image?'}  close={() => setDeleteImageBuffer(null)} />}
                                    </div>
                                </Content>
                            </Wrapper>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
        </div>
    );
};

export default Components;
