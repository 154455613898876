import styled from '@emotion/styled';
import React from 'react';

const Label = styled.label`
    color: white;
    cursor: auto;
    font-size: 12px;
    margin-bottom: 0px;
    display: flex;
    align-items: flex-start;
    padding: 5px 0px;
    font-weight: 500;
    input {
        min-width: 17px;
        min-height: 17px;
    }
`;

const RadioButton = ({ label, checked, style, ...rest }) => {
    return (
        <div>
            <Label style={style}>
                <input
                    type="radio"
                    checked={!!checked}
                    style={{ marginBottom: 0, marginRight: 10, boxShadow: 'none' }}
                    {...rest}
                />
                {label}
            </Label>
        </div>
    );
};

export default RadioButton;
