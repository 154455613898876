import React, { Component } from 'react';
import { MDBTabPane, MDBTabContent, MDBBtn, MDBCardImage, MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle } from "mdbreact";
import { connect } from 'react-redux';
import { KITCATALOG_PAGE_UNLOADED, LOGOUT, KITCATEGORIES } from '../../constants/actionTypes';
import Tree from '../Tree';
import notfound from '../../shared/img/kitsDefaultImage.png';
import Loader from "../../components/loaders/Loader";
import Pagination from "../../components/Pagination/Pagination";
import { loadKitsList, loadSavedCustomKitsList, getCartCustomKits, deleteCartCustomKit, updateKitVisibility } from '../../redux/actions/kits';
import styled from '@emotion/styled';

const VisibilityWrapper = styled.span`
    border: unset !important;
    margin-top: -50px;
    padding-bottom: 50px;
    font-size: 24px;
`;

const VisibilityWrapper2 = styled.div`
    border: unset !important;
    margin-top: -50px;
    padding-bottom: 50px;
    font-size: 24px;
`;

const VisibilityButton = styled.div`
    float: right;
    font-size: 24px !important;
    color: #62A43E !important;
    border: unset !important;
    cursor: pointer;
`;
const VisibilityButtonOff = styled(VisibilityButton)`
    color: rgb(204,38,40) !important;
`;


const mapStateToProps = state => ({
    ...state.auth,
    currentUser: state.common.currentUser,
    kits: { ...state.kits, kits: undefined, data: state.kits.kits },
    customKitsSaved: state.customKitsSaved,
    cart: state.cart
});

const mapDispatchToProps = dispatch => ({
    onUnload: () =>
        dispatch({ type: KITCATALOG_PAGE_UNLOADED }),
    OnselectedTreeNode: (treeNode) =>
        dispatch({ type: KITCATEGORIES, payload: treeNode }),
    onClickLogout: () => dispatch({ type: LOGOUT }),
    loadKitsList: (params) => loadKitsList(params)(dispatch),
    loadSavedCustomKitsList: () => loadSavedCustomKitsList()(dispatch),
    getCartCustomKits: () => getCartCustomKits()(dispatch),
    deleteCartCustomKit: (id) => deleteCartCustomKit(id)(dispatch),
    updateKitVisibility: (id, visibility) => updateKitVisibility(id, visibility)(dispatch),
});

let KitArray = {
    kits: [{ description: "No results found" }],
    totalPages: 0,
    totalCount: 0
}
let storedNode = " ";
let storedNodeId = " ";
class KitCatalogJustLike extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reloadKey: Math.random(),
            kitData: null,
            showComponent: false,
            paginationActive: false,
            isOpen: false,
            searchText: window.sessionStorage.getItem('searchTextKit') || '',
            isChecked: false,
            fromChild: [],
            loading: 'initial',
            showJustLike: false,
            newStateOne: [],
            JustLikekitDetail: {},
            searachedCataegory: [],
            activeItem: "1",
            savedKitData: null,
            getCartComponents: null,
            everyPage: 12,
            currentPage: 1,
            pageReload: Math.random(),
            isSearch: false,
            componentData: null
        }
        this.onSearch = this.onSearch.bind(this);
        this.kitCatalogDetail = this.kitCatalogDetail.bind(this);
        this.kitCatalog = this.kitCatalog.bind(this);
        this.onJustLike = this.onJustLike.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.updateKitVisibility = this.updateKitVisibility.bind(this);
        this.useAsBase = this.useAsBase.bind(this);
        this.getSavedKitComponents = this.getSavedKitComponents.bind(this);
        this.changeUsername = ev => this.props.onChangeUsername(ev.target.value);
        this.changePassword = ev => this.props.onChangePassword(ev.target.value);
        this.submitForm = (username, password) => ev => {
            ev.preventDefault();
            this.props.onSubmit(username, password);
        };
        this.onUnloadForm = (Categories, ComponentId, PageSize, Page) => ev => {
            ev.preventDefault();
            this.props.onUnload(Categories, ComponentId, PageSize, Page);
        }
        const token = window.localStorage.getItem('jwt');
        let expiry = new Date(window.localStorage.getItem("tokenExpiry"));
        let current = new Date();
        if (token && expiry.getTime() >= current.getTime()) {
        }
        else { this.props.onClickLogout() }
    }

    async updateKitVisibility(id, vis) {
        await this.props.updateKitVisibility(id, !vis);
        this.onSearch();
    }

    componentWillMount() {
        const token = window.localStorage.getItem('jwt');
        if (token) {
            const urlParams = new URLSearchParams(window.location.search);
            const componentId = urlParams.get('componentId');
            const urlParamss = new URLSearchParams(window.location.search);
            const kit = urlParamss.get('kit');
            let storedComponentNode = (window.sessionStorage.getItem("kitCategory"));
            if (storedComponentNode && storedComponentNode !== " ") {
                storedNode = JSON.parse(window.sessionStorage.getItem("kitCategory"));
                storedNodeId = storedNode && storedNode !== "null" && storedNode !== " " && storedNode != null ? storedNode.id : "";
            } else {
                storedNode = "";
                storedNodeId = "";
            }
            if (componentId > 0) {
                this.props.loadKitsList({ ComponentId: componentId, PageSize: this.state.everyPage, Page: this.state.currentPage - 1 }).then(() => {
                    if (this.props.kits.totalCount === 0) {
                        this.setState({ kitData: KitArray, componentData: componentId });
                    }
                    else {
                        this.setState({ kitData: this.props.kits.data, componentData: componentId });
                    }
                })
            } else {
                this.props.loadKitsList({ Categories: storedNodeId, PageSize: this.state.everyPage, Page: this.state.currentPage - 1 }).then(() => {
                    if (this.props.kits.totalCount === 0) {
                        this.setState({ kitData: KitArray });
                    }
                    else {
                        this.setState({ kitData: this.props.kits.data });
                    }
                })
            }
            if (kit !== null) {
                this.setState({ searachedCataegory: storedNode });
                this.renderComponent();
            }
            this.props.loadSavedCustomKitsList().then((res) => {
                if (res.data === null) {
                    this.setState({ savedKitData: KitArray });
                }
                else {
                    this.setState({ savedKitData: res.data });
                }
            })
            this.props.getCartCustomKits().then((res) => {
                if (res.data === null) {
                    this.setState({ getCartComponents: KitArray });
                } else {
                    this.setState({ getCartComponents: res.data });
                }
            })
        }
        else {
            this.props.onClickLogout()
        }
    }

    componentDidMount = () => {
        window.onpopstate = () => {
            window.location.reload();
        }

        if (this.state.searchText.length > 0) this.onSearch();
    }
    toggle = tab => e => {
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab
            });
            this.setState({ blue: !this.state.blue })
        }
        this.props.getCartCustomKits().then((res) => {
            if (res === null) {
                this.setState({ getCartComponents: KitArray });
            } else {
                this.setState({ getCartComponents: res.data });
            }
        })
    };
    compoentIncludedInKit = () => {
        const eachCard = document.querySelectorAll(".onPrd");
        const xx = document.getElementById("mySidenav");

        let stil = window.getComputedStyle(xx).getPropertyValue("display");
        if (stil === "none") {
        } else {
            eachCard.forEach(element => {
                element.classList.add('col-xl-4')
                element.classList.remove('col-xl-3')
            });
        }
    };

    componentDataWithCustomKit = () => {
        const eachCard = document.querySelectorAll(".onPrd");
        const xx = document.getElementById("mySidenav");

        let stil = window.getComputedStyle(xx).getPropertyValue("display");
        if (stil === "none") {
        } else {
            eachCard.forEach(element => {
                element.classList.add('col-xl-6')
                element.classList.remove('col-xl-4')
            });
        }
    };

    customKits() {
        const urlParams = new URLSearchParams(window.location.search);
        const componentId = urlParams.get('componentId');


        if (componentId > 0) {
            const p = document.querySelectorAll(".onPrd");
            const x = document.getElementById("mySidenav");
            const y = document.getElementsByClassName("ryttray");
            const z = document.getElementById("onTray");

            let stil = window.getComputedStyle(x).getPropertyValue("display");
            if (stil === "none") {
                x.style.display = "inline";
                y[0].style.display = "inline-table";
                y[0].style.right = "0";
                y[0].style.position = "relative";
                y[0].style.top = "0";
                z.style.flex = "0 0 75%";
                z.style.maxWidth = "75%";
                for (let i = 0; i < p.length; i++) {
                    p[i].classList.add('col-xl-4')
                    p[i].classList.remove('col-xl-3')
                }
            } else {
                x.style.display = "none";
                y[0].style.right = '0';
                y[0].style.position = "fixed";
                y[0].style.top = "65px";
                z.style.flex = "0 0 100%";
                z.style.maxWidth = "100%";
                for (let j = 0; j < p.length; j++) {
                    p[j].classList.remove('col-xl-4')
                    p[j].classList.add('col-xl-3')
                }
            }
        } else {
            const p = document.querySelectorAll(".onPrd");
            const x = document.getElementById("mySidenav");
            const y = document.getElementsByClassName("ryttray");
            const z = document.getElementById("onTray");

            let stil = window.getComputedStyle(x).getPropertyValue("display");
            if (stil === "none") {
                x.style.display = "inline";
                y[0].style.display = "inline-table";
                y[0].style.right = "0";
                y[0].style.position = "relative";
                y[0].style.top = "0";
                z.style.flex = "0 0 75%";
                z.style.maxWidth = "75%";
                for (let i = 0; i < p.length; i++) {
                    p[i].classList.add('col-xl-6')
                    p[i].classList.remove('col-xl-4')
                }
            } else {
                x.style.display = "none";
                y[0].style.right = '0';
                y[0].style.position = "fixed";
                y[0].style.top = "65px";
                z.style.flex = "0 0 100%";
                z.style.maxWidth = "100%";
                for (let j = 0; j < p.length; j++) {
                    p[j].classList.remove('col-xl-6')
                    p[j].classList.add('col-xl-4')
                }
            }
        }
    }

    defaultData = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const componentId = urlParams.get('componentId');
        let storedComponentNode = (window.sessionStorage.getItem("kitCategory"));
        if (storedComponentNode && storedComponentNode !== " ") {
            storedNode = JSON.parse(window.sessionStorage.getItem("kitCategory"));
            storedNodeId = storedNode && storedNode !== "null" && storedNode !== " " && storedNode != null ? storedNode.id : "";
        } else {
            storedNode = "";
            storedNodeId = "";
        }
        if (componentId > 0) {
            this.props.loadKitsList({ ComponentId: componentId, PageSize: this.state.everyPage, Page: this.state.currentPage - 1 }).then(() => {
                if (this.props.kits.totalCount === 0) {
                    this.setState({ kitData: KitArray, componentData: componentId });
                }
                else {
                    this.setState({ kitData: this.props.kits, componentData: componentId });
                    this.compoentIncludedInKit();
                }
            })
        } else {
            this.props.loadKitsList({ Categories: storedNodeId, PageSize: this.state.everyPage, Page: this.state.currentPage - 1 }).then(() => {
                if (this.props.kits.totalCount === 0) {
                    this.setState({ kitData: KitArray });
                }
                else {
                    this.setState({ kitData: this.props.kits.data });
                    this.componentDataWithCustomKit();
                }
            })
        }
    }
    componentWillUnmount() {
        this.props.onUnload();
    }
    onClickGridView = () => {
        this.setState({ showComponent: false });
        this.defaultData();
    }
    onClickListView = () => {
        this.setState({ showComponent: true });
        this.defaultData();
    }
    onJustLike(kit) {
        let path = `/kitcatalogjustlike`;
        this.props.history.push(path + "/" + kit);
    }
    onSearch = () => {
        let searchText = this.state.searchText;
        this.setState({ isSearch: true });
        this.props.loadKitsList({ Categories: storedNodeId, Search: searchText, PageSize: this.state.everyPage, Page: this.state.currentPage - 1 }).then(() => {
            if (this.props.kits.totalCount === 0) {
                this.setState({ kitData: KitArray });
                this.setState({ isSearch: false });
            }
            else {
                this.setState({ kitData: this.props.kits.data });
                this.props.kits.data.categories.forEach(element => {
                    this.state.searachedCataegory.push(element);
                });
                this.setState({ searachedCataegory: this.state.searachedCataegory });
                this.setState({ isSearch: false });
            }
        })
    };
    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
    onClearFilter = () => {
        this.setState({ reloadKey: Math.random() });
        var searchedlist = storedNode;
        while (searchedlist.length > 0) { searchedlist.splice(0, 1); }
        window.sessionStorage.setItem("kitCategory", " ");
        storedNode = "";
        storedNodeId = "";
        this.setState({ searchText: "" });
        this.renderComponent();
    }
    kitCatalogDetail = (kitId) => {
        let path = `/kit`;
        let data = kitId;
        this.props.history.push(path + "/" + data);
    }
    onSelect = (node, ischecked) => {
        let isNodeChecked = true;
        let storedData = window.sessionStorage.getItem("kitCategory");
        if (storedData && storedData !== "" && storedData !== null && storedData !== "null" && storedData !== " ") {
            if (node.id === JSON.parse(storedData).id) {
                isNodeChecked = false;
            }
        }
        if (isNodeChecked) {
            this.props.OnselectedTreeNode(node);
            storedNode = JSON.parse(window.sessionStorage.getItem("kitCategory"));
            storedNodeId = storedNode.id;
            document.getElementById(node.id).checked = true;
            this.renderComponent();
        } else {
            window.sessionStorage.setItem("kitCategory", "");
            storedNode = ""; storedNodeId = "";
            document.getElementById(node.id).checked = false;
            this.renderComponent();
        }
        this.setState({ pageReload: Math.random() });
    }

    deleteComponents = (event, component) => {
        this.props.deleteCartCustomKit(component.id).then(() => {
            if (this.props.cart === null) {
                this.setState({ getCartComponents: KitArray });

            } else {
                this.setState({ getCartComponents: this.props.cart });
                this.setState({ reloadKey: Math.random() });
            }
        });
    }

    renderComponent() {
        this.setState({ currentPage: 1 })
        if (storedNode !== "") {
            this.props.loadKitsList({ Categories: storedNodeId, PageSize: this.state.everyPage, Page: this.state.currentPage - 1 }).then(() => {
                if (this.props.kits.totalCount === 0) {
                    this.setState({ kitData: KitArray });
                }
                else {
                    this.setState({ kitData: this.props.kits.data });
                    this.componentDataWithCustomKit();
                }
            })
        } else {
            this.props.loadKitsList({ PageSize: this.state.everyPage, Page: this.state.currentPage - 1 }).then(() => {
                if (this.props.kits.totalCount === 0) {
                    this.setState({ kitData: KitArray });
                }
                else {
                    this.setState({ kitData: this.props.kits.data });
                    this.componentDataWithCustomKit();
                }
            })
        }
    }

    handleClick = (data) => {
        this.setState({ kitData: data });
        this.componentDataWithCustomKit();
    }

    kitCatalogClick() {
        let path = `/kitcatalog`;
        this.props.history.push(path);
    }

    kitCatalog() {
        window.location.reload();
        this.setState({
            currentPage: Number(1)
        });
    }

    loadComponentData = (search, pageSize, page) => {
        let category = storedNodeId;
        this.props.loadKitsList({ Categories: category, Search: search, PageSize: pageSize, Page: page }).then(() => {
            if (this.props.kits.totalCount === 0) {
                this.setState({ kitData: KitArray });
            }
            else {
                this.setState({ kitData: this.props.kits.data });
            }
        })
    };
    handleChange = ({ target }) => {
        window.sessionStorage.setItem('searchTextKit', target.value);
        this.setState({
            searchText: target.value,
            currentPage: Number(1)
        });
    }
    addDefaultSrc(ev) {
        ev.target.src = notfound
    }
    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearch();
        }
    }
    useAsBase = (kitId) => {
        let path = "/kitmaker"
        let data = kitId;
        this.props.history.push(path + "/" + data);
        window.location.reload();
    };
    getSavedKitComponents = (kit) => {
        this.props.history.push('/kitmakercomponents?KitFromBase=' + null + "&kit=" + kit.name + "&kitId=" + kit.id + "&orederedKitId=" + null + "&isFavourite=1");
        window.location.reload();
    }
    getComponentUseAsBase = () => {
        let path = `/kitmaker`;
        this.props.history.push(path + '?isFavourite=1');
        window.location.reload();
    }
    kitmakerform = () => {
        let path = "/kitmaker";
        this.props.history.push(path);
        window.location.reload();
    }
    getJustLike = () => {
        let path = `/savedaskjustlike?isFavorite=1`;
        this.props.history.push(path);
        window.location.reload();
    }
    render() {
        if (this.state.kitData === null || this.state.savedKitData === null || this.state.getCartComponents === null) {
            return <Loader />
        } else {
            var KitCatalogArray = this.state.kitData;
            var currentTodos = KitCatalogArray.kits.filter(x => this.props.user.role.isAdmin || this.props.user.role.isSuperAdmin || x.isVisible);
            const isListviewIn = this.state.showComponent;
            var savedData = this.state.savedKitData;
            var cartComponents = this.state.getCartComponents || [];
            var kititem, savedKitData, getCartComponents = "";
            const activeStyle = { color: '#61a431' };
            const inactiveStyle = { color: '#959595' };
            savedKitData = savedData.map(kit => {
                return (
                    <div id="customkits" className="data-list" onPointerMove={this.hoverOn} onClick={(event) => this.getSavedKitComponents(kit)} key={"kitCatalogSavedKitData" + kit.id}>
                        <p className="kitName anchorColor" id="customKitName">{kit.name}<span></span></p>
                        <a href="#no" className="kitCount" id="customKitId">Components Count: {kit.componentsCount}</a>
                        <br />
                    </div>
                )
            })
            getCartComponents = cartComponents.map(cartComponent => {
                return (
                    <div id="cartCustom" className="data-list" key={"kitCatalogcartComponent" + cartComponent.id}>
                        <i className="fa fa-window-close" aria-hidden="true" onClick={(event) => this.deleteComponents(event, cartComponent)}></i>
                        <a href="#no" className="kitCount" id="customKitId"><p>SKU: {cartComponent.number}</p></a>
                        <p className="kitName anchorColor" id="customKitName">{cartComponent.description}<span></span></p>
                    </div>
                )
            })
            if (isListviewIn === false && this.state.kitData.totalCount > 0) {
                if (this.state.componentData > 0) {
                    kititem = currentTodos.map(kit => {
                        return (
                            <MDBCol xl="3" lg="3" md="6" className="onPrd" id={kit.id}>
                                <MDBCard className="product-area">
                                    <h5 onClick={() => this.onJustLike(kit.id)}>Just Like!</h5>
                                    <a href={"/kit/" + kit.id} className="anchorColor">
                                        <MDBCardImage className="img-fluid" src={kit.imageUrl === null ? notfound : kit.imageUrl} onError={this.addDefaultSrc} alt="" />
                                    </a>
                                    <MDBCardBody>
                                        <span>{kit.componentsCount} Components</span>
                                        <MDBCardTitle> <a className="anchorColor" href={"/kit/" + kit.id} >{kit.number}</a></MDBCardTitle>
                                        <MDBCardText>
                                            <a className="anchorColor" href={"/kit/" + kit.id} >
                                                {kit.description}
                                            </a>
                                        </MDBCardText>
                                        <MDBBtn onClick={() => this.kitCatalogDetail(kit.id)}>KIT DETAILS</MDBBtn>
                                        <MDBBtn className="gbtn" onClick={() => this.useAsBase(kit.id)}><i className="fa fa-shopping-basket" aria-hidden="true"></i>USE AS A BASE</MDBBtn>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        )
                    },
                    )

                } else {
                    kititem = currentTodos.map(kit => {
                        return (
                            <MDBCol xl="4" lg="6" md="12" className="onPrd" id={kit.id} key={"kitCatalog" + kit.id}>
                                <MDBCard className="product-area">
                                    <h5 onClick={() => this.onJustLike(kit.id)}>Just Like!</h5>
                                    <a href={"/kit/" + kit.id} className="anchorColor">
                                        <MDBCardImage className="img-fluid" src={kit.imageUrl === null ? notfound : kit.imageUrl} onError={this.addDefaultSrc} alt="" />
                                    </a>
                                    <MDBCardBody>
                                        <span>{kit.componentsCount} Components</span>
                                        <MDBCardTitle> <a className="anchorColor" href={"/kit/" + kit.id} >{kit.number}</a></MDBCardTitle>
                                        <MDBCardText>
                                            <a className="anchorColor" href={"/kit/" + kit.id} >
                                                {kit.description}
                                            </a>
                                            {(this.props.user.role.isAdmin || this.props.user.role.isSuperAdmin) ? <VisibilityWrapper2>
                                                {kit.isVisible ?
                                                    <VisibilityButton onClick={() => this.updateKitVisibility(kit.id, true)}><i className="fas fa-eye"></i></VisibilityButton> :
                                                    <VisibilityButtonOff onClick={() => this.updateKitVisibility(kit.id, false)}><i className="fas fa-eye-slash"></i></VisibilityButtonOff>}
                                            </VisibilityWrapper2> : null}
                                        </MDBCardText>
                                        <MDBBtn onClick={() => this.kitCatalogDetail(kit.id)}>KIT DETAILS</MDBBtn>
                                        <MDBBtn className="gbtn" onClick={() => this.useAsBase(kit.id)}><i className="fa fa-shopping-basket" aria-hidden="true"></i>USE AS A BASE</MDBBtn>

                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        )
                    },
                    );
                }
            }
            else if (isListviewIn === true && this.state.kitData.totalCount > 0) {
                kititem = currentTodos.map(kit => {
                    return (
                        <MDBCol lg="12" key={"kitCatalogIsListviewIn" + kit.id}>
                            <MDBCard className="product-area product-area-list">
                                <MDBRow>
                                    <MDBCol lg="3">
                                        <h5 onClick={() => this.onJustLike(kit.id)}>Just Like!</h5>
                                        <a href={"/kit/" + kit.id} >
                                            <MDBCardImage className="img-fluid" src={kit.imageUrl === null ? notfound : kit.imageUrl} onError={this.addDefaultSrc} alt="" />
                                        </a>
                                    </MDBCol>
                                    <MDBCol lg="5">
                                        <MDBCardBody>
                                            <span>{kit.componentsCount} Components</span>
                                            <MDBCardTitle>
                                                {kit.number}</MDBCardTitle>
                                            <MDBCardText>
                                                <a href={"/kit/" + kit.id} >
                                                    {kit.description}
                                                </a>
                                            </MDBCardText>
                                        </MDBCardBody>
                                    </MDBCol>
                                    {(this.props.user.role.isAdmin || this.props.user.role.isSuperAdmin) && <MDBCol lg="1">
                                        <MDBCardBody>
                                            {(this.props.user.role.isAdmin || this.props.user.role.isSuperAdmin) ? <VisibilityWrapper>
                                                {kit.isVisible ?
                                                    <VisibilityButton onClick={() => this.updateKitVisibility(kit.id, true)}><i className="fas fa-eye"></i></VisibilityButton> :
                                                    <VisibilityButtonOff onClick={() => this.updateKitVisibility(kit.id, false)}><i className="fas fa-eye-slash"></i></VisibilityButtonOff>}
                                            </VisibilityWrapper> : null}
                                        </MDBCardBody>
                                    </MDBCol>}
                                    <MDBCol lg="3">
                                        <div className="list-btn-area">
                                            <MDBBtn onClick={() => this.kitCatalogDetail(kit.id)}>KIT DETAILS</MDBBtn>
                                            <MDBBtn className="gbtn" onClick={() => this.useAsBase(kit.id)}><i className="fa fa-shopping-basket" aria-hidden="true"></i>USE AS A BASE</MDBBtn>
                                        </div>
                                    </MDBCol>
                                    <MDBRow>
                                    </MDBRow>
                                </MDBRow>
                            </MDBCard>
                        </MDBCol>
                    )
                }
                )
            }
            else {
                kititem = currentTodos.map(kit => {
                    return (
                        <MDBCol xl="12" lg="12" md="12" className="text-center">
                            <p>{kit.description}</p>
                        </MDBCol>
                    )
                }
                )
            }
            return (
                <div className="main-layout">
                    <div className="content-area">
                        <MDBContainer fluid>
                            <MDBRow>
                                <MDBCol id="onTray" lg="12" md="12 " sm="6" xs="12" className="mt20-xs">
                                    <h5>Kit Catalog</h5>
                                    <MDBRow>
                                        {this.state.componentData > 0 ? null : <MDBCol lg="3" md="6" sm="6" xs="12" className="mt20-xs">
                                            <h6><span>Filters</span></h6>
                                            <MDBCard >
                                                <MDBCardBody className="setHeight p-2">
                                                    <MDBCardTitle>KITS</MDBCardTitle>
                                                    <nav className="nav" role="navigation">
                                                        <Tree key={this.state.reloadKey} isFromParent={("KitCatalog")} onSelect={this.onSelect} selectedParentNode={storedNode === "" ? null : storedNode} />
                                                    </nav>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>}

                                        {this.state.componentData > 0 ? <MDBCol lg="12" md="12" sm="12" xs="12" className="mt20-xs pl15-xs">
                                            <h6><span></span>
                                                <p>
                                                    <i className="fa fa-th-large" aria-hidden="true" onClick={this.onClickGridView} style={this.state.showComponent === false ? activeStyle : inactiveStyle} key={Math.random()}></i>
                                                    <i className="fa fa-bars" aria-hidden="true" onClick={this.onClickListView} style={this.state.showComponent === true ? activeStyle : inactiveStyle} key={Math.random()}></i>
                                                </p></h6>
                                            <div className="input-group form-sm form-2">
                                                <input
                                                    className="form-control my-0 py-1 red-border"
                                                    type="text"
                                                    placeholder="Search"
                                                    aria-label="Search"
                                                    id="searchText"
                                                    name="textSearch"
                                                    value={this.state.searchText ? this.state.searchText : ""}
                                                    onChange={this.handleChange}
                                                    onKeyPressCapture={this.handleKeyPress}
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text white" id="basic-text1">
                                                        <i className="fas fa-search text-grey" aria-hidden="true" onClick={this.state.searchText === null ? this.kitCatalog : this.onSearch}></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <MDBRow>
                                                {kititem}
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol className="text-center" id="inkpagination" >
                                                    <Pagination
                                                        pageData={this.handleClick}
                                                        parent={("kitcatalog")}
                                                        searchText={(this.state.searchText)}
                                                        isSearch={(this.state.isSearch)}
                                                        componentId={(this.state.componentData)}
                                                        key={this.state.pageReload}
                                                    />
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCol>


                                            : <MDBCol lg="9" md="6" sm="6" xs="12" className="pl0  mt20-xs pl15-xs">
                                                <h6><span></span>
                                                    <p>
                                                        <i className="fa fa-th-large" aria-hidden="true" onClick={this.onClickGridView} style={this.state.showComponent === false ? activeStyle : inactiveStyle} key={Math.random()}></i>
                                                        <i className="fa fa-bars" aria-hidden="true" onClick={this.onClickListView} style={this.state.showComponent === true ? activeStyle : inactiveStyle} key={Math.random()}></i>
                                                    </p></h6>
                                                <div className="input-group form-sm form-2">
                                                    <input
                                                        className="form-control my-0 py-1 red-border"
                                                        type="text"
                                                        placeholder="Search"
                                                        aria-label="Search"
                                                        id="searchText"
                                                        name="textSearch"
                                                        value={this.state.searchText ? this.state.searchText : ""}
                                                        onChange={this.handleChange}
                                                        onKeyPressCapture={this.handleKeyPress}
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text white" id="basic-text1">
                                                            <i className="fas fa-search text-grey" aria-hidden="true" onClick={this.state.searchText === null ? this.kitCatalog : this.onSearch}></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <MDBRow>
                                                    {kititem}
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol className="text-center" id="kcpagination">
                                                        <Pagination
                                                            pageData={this.handleClick}
                                                            parent={("kitcatalog")}
                                                            searchText={(this.state.searchText)}
                                                            isSearch={(this.state.isSearch)}
                                                            key={this.state.pageReload}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCol>}
                                    </MDBRow>
                                </MDBCol>
                                <MDBCol lg="3" md="6" sm="6" xs="12" className="mt20-xs pl-lg-0 pl-xl-0">
                                    <div className="rytsidetray">
                                        <i className="fa fa-shopping-basket ryttray" aria-hidden="true" onClick={this.customKits}></i>
                                        <div id="mySidenav" className="sidetray kitmaker pt-2 ">
                                            <div id="authentication-layout">
                                                <div className="btn-section mtb2 tab-area mt-0">
                                                    <MDBBtn className="m-0 mb20-xs btn-whitee" active={this.state.activeItem === "1"} onClick={this.toggle("1")} >Custom kits</MDBBtn>
                                                    <MDBBtn className="m-0  mb20-xs btn-outline-indigo notwhite" active={this.state.activeItem === "2"} onClick={this.toggle("2")} > Components</MDBBtn>
                                                </div>
                                                <MDBTabContent activeItem={this.state.activeItem} >
                                                    <MDBTabPane tabId="1" role="tabpanel">
                                                        <div className="tbl-scrl">
                                                            {savedKitData}
                                                        </div>
                                                        <br />
                                                        <MDBBtn className="fullgbtn m-0" disabled={this.state.savedKitData.length === 0 ? true : false} onClick={() => this.kitmakerform()} >CREATE NEW KIT</MDBBtn>
                                                    </MDBTabPane>
                                                    <MDBTabPane tabId="2" role="tabpanel">
                                                        <div className="tbl-scrl">
                                                            {this.state.getCartComponents.length === 0 ? <p>No starred component available right now</p> : getCartComponents}
                                                        </div>
                                                        <MDBBtn className="fullgbtn " disabled={this.state.getCartComponents.length === 0 ? true : false} onClick={() => this.getComponentUseAsBase()} >CREATE NEW KIT</MDBBtn>
                                                        <MDBBtn className="fullgbtn " disabled={this.state.getCartComponents.length === 0 ? true : false} onClick={() => this.getJustLike()}>JUST LIKE</MDBBtn>
                                                    </MDBTabPane>
                                                </MDBTabContent>
                                            </div>
                                        </div>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </div>
                </div>
            )
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KitCatalogJustLike);