import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import siteLogo from '../shared/img/logo.png';
import styled from '@emotion/styled';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from 'mdbreact';
import { NavLink, Switch, withRouter, matchPath } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Profile from '../shared/img/profileimage.png';
import {
  betaRoutes,
  cmsAccessRoleList,
  defaultRoutes,
  pagesHideMenu,
  controlPanelRoutes,
  kitMakerLayoutRoutes,
} from '../constants/constants';
import { getUserImage, logout } from '../redux/actions/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import PrivateRoute from '../PrivateRoute';
import ComponentCagegoriesNew from './ComponentCatalog/ComponentCategoriesNew';
import KitCatalog from './KitCatalog/KitCatalog';
import KitCatalogJustLike from './KitCatalog/KitCatalogJustLike';
import KitCatalogDetail from './KitCatalog/KitCatalogDetail';
import ComponentDetail from './ComponentCatalog/ComponentDetail';
import KitMaker from './KitMaker/KitMaker';
import KitList from './NewKitMaker/KitList';
import KitMakerComponents from './KitMaker/KitMakerComponents';
import KitMakerRequestSample from './KitMaker/KitMakerRequestSample';
import KitMakerSurveyPage from './KitMaker/KitMakerSurveyPage';
import KitMakerConfirmation from './SavedAsk/KitMakerConfirmation';
import myProfile from './MyProfile/myProfile';
import editProfile from './MyProfile/editProfile';
import ComponentCatalogRequestSample from './ComponentCatalog/ComponentCatalogRequestSample';
import SavedASK from './SavedAsk/SavedASK';
import KitCatalogRequestsampleForm from './KitCatalog/KitCatalogRequestsampleForm';
import SavedAskComplete from './SavedAsk/SavedAskComplete';
import ComponentCatalogConfirmation from './ComponentCatalog/ComponentCatalogConfirmation';
import SavedASKJustLike from './SavedAsk/SavedASKJustLike';
import UserManagement from './Settings/UserManagement/UserManagement';
import Categories from './Settings/Categories/Categories';
import ProductFamilies from './Settings/ProductFamilies/ProductFamilies';
import KitTrays from './Settings/KitTrays/KitTrays';
import Components from './Settings/Components/Components';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import KitFinder from './NewKitCatalog/KitFinder';
import UploadComponentsPricing from './Settings/UploadComponentsPricing/UploadComponentPricing';
import KitTagging from './Settings/KitTagging/KitTagging';
import PackagingOptions from './Settings/PackagingOptions/PackagingOptions';
import { setLocalState as rSetLocalState } from '../redux/actions/menu';
import LogContainer from './Settings/InformationLogs/LogsContainer';
import { useMsal } from '@azure/msal-react';

const Sidebar = styled.div(
  ({ hide }) => `
    left: ${hide ? '-270px' : '0px'};
    transition: all 0.3s linear;
    position: fixed;
    min-width: 270px;
    top: 43px;
    z-index: 2;
`,
);

const Nav = styled.div`
  height: 100%;
  position: fixed;
  min-width: 270px;
  background: #1d265b;
`;

const Hr = styled.hr`
  border-bottom: 1px solid white;
  margin: 15px 0px;
`;

const SubHeader = styled.span`
  padding-left: 10px;
  color: lightgray;
  font-weight: 500;
`;

const Layout = (props) => {
  const { userName, role, profileImage } = useSelector(
    (state) => state.auth.user,
  );
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const active = instance.getActiveAccount();
  const localState = useSelector((state) => state.menu);
  const setLocalState = (obj) => dispatch(rSetLocalState(obj));
  //const [localState, setLocalState] = useState({ isOpen: false, isActive: false, hideSidebar: false });
  const showHideMenu = pagesHideMenu.some((item) =>
    props.location.pathname.includes(item),
  );
  const breadcrumbRoutes = [...betaRoutes, ...controlPanelRoutes]
    .filter((f) => f.visible)
    .find((route) => props.location.pathname.includes(route.value))
    ? [...betaRoutes, ...controlPanelRoutes]
    : defaultRoutes;

  const updateLocalState = (pathname) => {
    const route = breadcrumbRoutes.find((route) =>
      pathname.includes(route.value),
    );

    if (route) {
      setLocalState({ ...localState, hideSidebar: showHideMenu });
    } else {
      setLocalState({ ...localState, hideSidebar: false });
    }
  };

  useEffect(() => {
    updateLocalState(props.location.pathname);
    // eslint-disable-next-line
  }, [props.location.pathname]);

  useEffect(() => {
    dispatch(getUserImage());
  }, [dispatch]);

  useEffect(() => {
    if (props.history.location.pathname.includes('/my-kit-list')) {
      const routeArr = kitMakerLayoutRoutes.layout
        .concat(kitMakerLayoutRoutes.kitmaker)
        .concat(kitMakerLayoutRoutes.orphan)
        .concat(kitMakerLayoutRoutes.sales);
      const match = routeArr.some((route) =>
        matchPath(props.history.location.pathname, {
          path: route,
          exact: true,
          strict: false,
        }),
      );
      !match && props.history.replace('/my-kit-list');
    }
    // eslint-disable-next-line
  }, [props.history.location.pathname]);

  const onToggleSidebar = () => {
    const hideSidebar = !localState.hideSidebar;
    setLocalState({ ...localState, hideSidebar });
  };

  const navBarElement = (
    <Nav>
      {defaultRoutes.map(({ value, label, divider, roleLabel, subHeader }) => (
        <Fragment key={value}>
          {subHeader && <SubHeader>{subHeader}</SubHeader>}
          <NavLink
            to={value}
            activeClassName="active"
            onClick={() => updateLocalState(value)}
          >
            {roleLabel && cmsAccessRoleList.find((r) => r === role?.alias)
              ? roleLabel
              : label}
          </NavLink>
          {divider && <Hr />}
        </Fragment>
      ))}
      {cmsAccessRoleList.find((r) => r === role?.alias) && (
        <>
          {betaRoutes
            .filter((f) => f.visible)
            .map(({ value, label, divider, roleLabel, subHeader }) => (
              <Fragment key={value}>
                {subHeader && <SubHeader>{subHeader}</SubHeader>}
                <NavLink
                  activeClassName="active"
                  to={value}
                  onClick={() => updateLocalState(value)}
                >
                  {roleLabel && cmsAccessRoleList.find((r) => r === role?.alias)
                    ? roleLabel
                    : label}
                </NavLink>
                {divider && <Hr />}
              </Fragment>
            ))}

          {controlPanelRoutes
            .filter((f) => f.visible)
            .map((route) => (
              <Fragment key={route.value}>
                {route.subHeader && <SubHeader>{route.subHeader}</SubHeader>}
                <NavLink
                  activeClassName="active"
                  to={route.value}
                  onClick={() => updateLocalState(route.value)}
                >
                  {route.label}
                </NavLink>
              </Fragment>
            ))}
        </>
      )}
    </Nav>
  );

  function onLogout() {
    if (active) {
      instance.logoutPopup();
    } else {
      dispatch(logout());
    }
  }

  return (
    <div className={!localState.hideSidebar ? 'show-sidebar' : ''}>
      <div className="header-layout" style={{ display: 'flex' }}>
        <MDBNavbar color="white" light expand="md" style={{ zIndex: 3 }}>
          <FontAwesomeIcon
            icon={faBars}
            style={{
              marginRight: 10,
              fontSize: 'larger',
              cursor: 'pointer',
            }}
            onClick={onToggleSidebar}
          />
          <MDBNavbarBrand>
            <img src={siteLogo} height={40} alt="logo" />
          </MDBNavbarBrand>
          <MDBNavbarToggler
            onClick={() =>
              setLocalState({ ...localState, isOpen: !localState.isOpen })
            }
          />
          <MDBCollapse id="navbarCollapse3" isOpen={localState.isOpen} navbar>
            <Breadcrumb
              routes={breadcrumbRoutes}
              placeholder={breadcrumbRoutes[0]?.placeholder}
              role={role}
            />
            <MDBNavbarNav right>
              <MDBNavItem className="userinfo">{userName}</MDBNavItem>
              <MDBNavItem>
                <MDBDropdown style={{ width: 46 }}>
                  <MDBDropdownToggle nav>
                    <img
                      src={profileImage || Profile}
                      className="img-fluid z-depth-1 rounded-circle"
                      alt="logo"
                    />
                  </MDBDropdownToggle>
                  <MDBDropdownMenu className="dropdown-default">
                    <MDBDropdownItem onClick={onLogout}>
                      <i className="fa fa-sign-out-alt" aria-hidden="true"></i>
                      Logout
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
              <div className="mobnav">{navBarElement}</div>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
        <Sidebar className="sidenav" id="nav" hide={localState.hideSidebar}>
          {navBarElement}
        </Sidebar>
        <Switch>
          <PrivateRoute
            exact
            path={[
              '/components',
              '/components/categories/:id/requestType/:requestType/type/:type/page/:page',
            ]}
            component={ComponentCagegoriesNew}
          />
          <PrivateRoute path="/kits" component={KitCatalog} />
          <PrivateRoute path="/kit-catalog" component={KitFinder} />
          <PrivateRoute
            path="/kitcatalogjustlike"
            component={KitCatalogJustLike}
          />
          <PrivateRoute
            exact
            path={['/kit', '/kit/:id']}
            component={KitCatalogDetail}
          />
          <PrivateRoute path="/components/:id" component={ComponentDetail} />
          <PrivateRoute path="/kitmaker" component={KitMaker} />
          <PrivateRoute
            path="/kitmakercomponents"
            component={KitMakerComponents}
          />
          <PrivateRoute
            path="/kitmakerrequestsample"
            component={KitMakerRequestSample}
          />
          <PrivateRoute path="/kitmakercustom" component={KitMakerSurveyPage} />
          <PrivateRoute path="/Confirmation" component={KitMakerConfirmation} />
          <PrivateRoute path="/myprofile" component={myProfile} />
          <PrivateRoute path="/editprofile" component={editProfile} />
          <PrivateRoute
            path="/ComponentRequestSample"
            component={ComponentCatalogRequestSample}
          />
          <PrivateRoute path="/savedask" component={SavedASK} />
          <PrivateRoute
            path="/kitcatalogrequestform"
            component={KitCatalogRequestsampleForm}
          />
          <PrivateRoute path="/savedaskcomplete" component={SavedAskComplete} />
          <PrivateRoute
            path="/Catalogconfirmation"
            component={ComponentCatalogConfirmation}
          />
          <PrivateRoute path="/savedaskjustlike" component={SavedASKJustLike} />
          {cmsAccessRoleList.find((r) => r === role?.alias) && (
            <>
              <PrivateRoute
                exact
                path={kitMakerLayoutRoutes.layout}
                component={KitList}
              />
              <PrivateRoute
                path="/user-management"
                component={UserManagement}
              />
              <PrivateRoute path="/categories" component={Categories} />
              <PrivateRoute
                path="/product-families"
                component={ProductFamilies}
              />
              <PrivateRoute path="/cms-components" component={Components} />
              <PrivateRoute path="/kit-attributes" component={KitTagging} />
              <PrivateRoute path="/kit-trays" component={KitTrays} />
              <PrivateRoute
                path="/upload-components-pricing"
                component={UploadComponentsPricing}
              />
              <PrivateRoute
                path="/packaging-options"
                component={PackagingOptions}
              />
              <PrivateRoute path="/information-logs" component={LogContainer} />
              {/* <PrivateRoute
                path="/divider-images"
                component={DividerImagesContainer}
              /> */}
            </>
          )}
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(Layout);
