export const flatten = (arr, addPartType = true) => {
    let res = [];

    arr.forEach(a => {
        res.push(a);
        if (Array.isArray(a.children) && a.children.length) {
            res = res.concat(flatten(a.children, addPartType));
            if (addPartType && Array.isArray(a.partTypes) && a.partTypes.length) res = res.concat(flatten(a.partTypes, addPartType));
        }

        if (addPartType && Array.isArray(a.partTypes) && a.partTypes.length) res = res.concat(flatten(a.partTypes, addPartType));
    });

    return res;
};
