import * as actionTypes from '../../constants/actionTypes';
import { getToken } from '../../utils/getToken';
import axios from '../axiosMiddleware/axios';
import * as _ from 'lodash';
import qs from 'querystring';
import { showSnackbar } from './snackbar';
import { cmsAccessRoleList, rules } from '../../constants/constants';
import * as FileSaver from 'file-saver';
import { loadCmsComponentTooltipInfoSilent } from './kitTrays';

export const loadKitsList = (params) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_KITS_START });
  try {
    const res = await axios.get(`/api/kits?${qs.encode(params)}`, {
      headers: { Authorization: 'Bearer ' + getToken() },
    });
    dispatch({ type: actionTypes.GET_KITS_SUCCESS, kits: res.data });
  } catch (err) {
    dispatch({ type: actionTypes.GET_KITS_FAIL, error: err });
  }
};

export const loadSavedCustomKitsList = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_SAVED_CUSTOM_KITS_START });
  try {
    const res = await axios.get(`/api/custom-kits/saved`, {
      headers: { Authorization: 'Bearer ' + getToken() },
    });
    dispatch({
      type: actionTypes.GET_SAVED_CUSTOM_KITS_SUCCESS,
      customKitsSaved: res.data,
    });
    return res;
  } catch (err) {
    dispatch({ type: actionTypes.GET_SAVED_CUSTOM_KITS_FAIL, error: err });
  }
};

export const updateKitVisibility =
  (id, visibility, params) => async (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_KIT_VISIBILITY_START });
    try {
      await axios.put(
        `/api/cms/kits/${id}/visibility/${visibility}`,
        undefined,
        { headers: { Authorization: 'Bearer ' + getToken() } },
      );
      dispatch({
        type: actionTypes.UPDATE_KIT_VISIBILITY_SUCCESS,
        payload: {
          id,
          visibility,
        },
      });
      params && dispatch(loadKitsList(params));
      dispatch({
        type: actionTypes.SHOW_SNACKBAR,
        snackbar: { type: 'success', message: 'Kit visibility changed' },
      });
    } catch (err) {
      let message = '';

      if (err.response?.status === 404) {
        message = "Kit with the provided id doesn't exist";
      } else if (err.response?.status === 400) {
        message = err.response.data;
      } else {
        message = 'Something went wrong, please try later';
      }

      dispatch({
        type: actionTypes.SHOW_SNACKBAR,
        snackbar: { type: 'error', message },
      });
      dispatch({ type: actionTypes.UPDATE_KIT_VISIBILITY_FAIL, error: err });
    }
  };

export const loadKitsCategoriesList = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_KITS_CATEGORIES_START });
  try {
    const res = await axios.get(`/api/kits/categories`, {
      headers: { Authorization: 'Bearer ' + getToken() },
    });
    dispatch({
      type: actionTypes.GET_KITS_CATEGORIES_SUCCESS,
      kitsCategories: res.data,
    });
  } catch (err) {
    dispatch({ type: actionTypes.GET_KITS_CATEGORIES_FAIL, error: err });
  }
};

export const getCartCustomKits = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_CART_CUSTOM_KITS_START });
  try {
    const res = await axios.get(`/api/cart`, {
      headers: { Authorization: 'Bearer ' + getToken() },
    });
    dispatch({
      type: actionTypes.GET_CART_CUSTOM_KITS_SUCCESS,
      cart: res.data,
    });
    return res;
  } catch (err) {
    dispatch({ type: actionTypes.GET_CART_CUSTOM_KITS_FAIL, error: err });
  }
};

export const deleteCartCustomKit = (customKitId) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_CUSTOM_KIT_START });

  try {
    await axios.delete(`/api/cart/${customKitId}`, {
      headers: { Authorization: 'Bearer ' + getToken() },
    });

    dispatch({ type: actionTypes.DELETE_CUSTOM_KIT_SUCCESS });
    dispatch({
      type: actionTypes.SHOW_SNACKBAR,
      snackbar: { type: 'success', message: 'Custom kit delete success' },
    });
    dispatch(getCartCustomKits());
  } catch (err) {
    let message = '';

    if (err.response?.status === 404) {
      message = "Custom kit with the provided id doesn't exist";
    } else if (err.response?.status === 400) {
      message = err.response.data;
    } else {
      message = 'Something went wrong, please try later';
    }

    dispatch({
      type: actionTypes.SHOW_SNACKBAR,
      snackbar: { type: 'error', message },
    });
    dispatch({ type: actionTypes.DELETE_CUSTOM_KIT_FAIL, error: err });
  }
};

export const loadKitListFromKitDesign = (params) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_KIT_LIST_FROM_KIT_DESIGN_START });

  let routes = ['/api/kit-design/kits/my'];
  const kits = typeof params === 'object';

  if (kits) {
    routes = [`/api/kits?${qs.encode(params)}`];
  } else if (cmsAccessRoleList.find((role) => role === params)) {
    routes.push('/api/kit-design/kits/submitted');
  }

  try {
    const [kitList, submitted] = await Promise.all([
      axios.get(routes[0], {
        headers: { Authorization: 'Bearer ' + getToken() },
      }),
      routes[1] &&
        axios.get(routes[1], {
          headers: { Authorization: 'Bearer ' + getToken() },
        }),
    ]);

    const merged = routes[1]
      ? kitList.data.concat(submitted.data)
      : kitList.data;

    dispatch({
      type: actionTypes.GET_KIT_LIST_FROM_KIT_DESIGN_SUCCESS,
      data: {
        kits: kits ? _.orderBy(kitList.data.kits, ['trayId'], ['asc']) : merged,
        totalCount: kits ? kitList.data.totalCount : 0,
        totalPages: kits ? kitList.data.totalPages : 0,
      },
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_KIT_LIST_FROM_KIT_DESIGN_FAIL,
      error: err,
    });
    dispatch(showSnackbar('error', 'Kit list loading error'));
  }
};

export const createKitFromKitDesign =
  (baseKitId, history) => async (dispatch) => {
    dispatch({ type: actionTypes.CREATE_KIT_LIST_FROM_KIT_DESIGN_START });

    try {
      const res = await axios.post(
        '/api/kit-design/kits',
        { BaseKitId: baseKitId },
        { headers: { Authorization: 'Bearer ' + getToken() } },
      );

      dispatch({
        type: actionTypes.CREATE_KIT_LIST_FROM_KIT_DESIGN_SUCCESS,
        data: res.data,
      });
      dispatch(showSnackbar('success', 'New Kit successfully created'));
      history.replace(`/my-kit-list/${res.data?.id}/kit-request-form`);
    } catch (err) {
      dispatch({ type: actionTypes.CREATE_KIT_LIST_FROM_KIT_DESIGN_FAIL });
      dispatch(showSnackbar('error', 'New Kit not created'));
    }
  };

export const clearKitListData = () => (dispatch) =>
  dispatch({ type: actionTypes.CLEAR_KIT_LIST_FROM_KIT_DESIGN_DATA });

export const addComponentAutoAssignCavity =
  (KitId, ComponentId, isMyKitList) => async (dispatch) => {
    dispatch({ type: actionTypes.ADD_COMPONENT_TO_KIT_AUTOASSIGN_START });

    try {
      await axios.post(
        `/api/kit-design/kits/${KitId}/components`,
        { ComponentId },
        { headers: { Authorization: 'Bearer ' + getToken() } },
      );

      dispatch({
        type: actionTypes.ADD_COMPONENT_TO_KIT_AUTOASSIGN_SUCCESS,
        componentId: ComponentId,
      });
      dispatch(
        showSnackbar(
          'success',
          'Component has been added to kit list. Place all orphan components to display in digital sample!',
        ),
      );
      dispatch(loadKitInfoFromKitDesign(KitId, isMyKitList));
    } catch (err) {
      dispatch({ type: actionTypes.ADD_COMPONENT_TO_KIT_AUTOASSIGN_FAIL });
      dispatch(showSnackbar('error', 'Failed to add component'));
    }
  };

export const deleteAllComponentsById =
  (KitId, ComponentId, isMyKitList) => async (dispatch) => {
    dispatch({ type: actionTypes.REMOVE_ALL_COMPONENTS_START });
    try {
      await axios.delete(
        `/api/kit-design/kits/${KitId}/components/${ComponentId}/all`,
        { headers: { Authorization: 'Bearer ' + getToken() } },
      );
      dispatch({
        type: actionTypes.REMOVE_ALL_COMPONENTS_SUCCESS,
        data: { ComponentId },
      });
      dispatch(showSnackbar('success', 'Component removed from kit!'));
      dispatch(loadKitInfoFromKitDesign(KitId, isMyKitList));
    } catch (err) {
      dispatch({ type: actionTypes.REMOVE_ALL_COMPONENTS_FAIL });
      dispatch(showSnackbar('error', 'Failed to remove component'));
    }
  };

export const deleteComponentFromCavity =
  (KitId, ComponentId, CavityId, isMyKitList, count = 1) =>
  async (dispatch) => {
    dispatch({ type: actionTypes.REMOVE_COMPONENT_FROM_CAVITY_START });

    try {
      const endpoint = `/api/kit-design/kits/${KitId}/components/${ComponentId}`;
      await axios.delete(
        endpoint + (count > 1 ? '/all' : CavityId ? `/${CavityId}` : ''),
        {
          headers: { Authorization: 'Bearer ' + getToken() },
        },
      );

      dispatch({
        type: actionTypes.REMOVE_COMPONENT_FROM_CAVITY_SUCCESS,
        data: { ComponentId, CavityId },
      });
      dispatch(showSnackbar('success', 'Component removed from kit!'));
      dispatch(loadKitInfoFromKitDesign(KitId, isMyKitList));
    } catch (err) {
      dispatch({ type: actionTypes.REMOVE_COMPONENT_FROM_CAVITY_FAIL });
      dispatch(showSnackbar('error', 'Failed to remove component'));
    }
  };

export const swapComponentAutoAssignCavity =
  (
    KitId,
    OldComponentId,
    OldCavityId,
    NewComponentId,
    isMyKitList,
    overrideQuantity = 1,
    component,
    updateSwapState,
    newComponent,
  ) =>
  async (dispatch) => {
    dispatch({ type: actionTypes.SWAP_COMPONENT_TO_KIT_AUTOASSIGN_START });
    const body = { NewComponentId, OldCavityId, OldComponentId };
    const headers = { Authorization: 'Bearer ' + getToken() };
    const endpoints = [...Array(overrideQuantity).keys()].map(
      () => `/api/kit-design/kits/${KitId}/components/swap`,
    );

    try {
      for (const endpoint of endpoints) {
        await axios.post(endpoint, body, { headers });
      }

      dispatch({ type: actionTypes.SWAP_COMPONENT_TO_KIT_AUTOASSIGN_SUCCESS });
      dispatch(showSnackbar('success', 'Component changed successfully!'));
      dispatch(loadKitInfoFromKitDesign(KitId, isMyKitList));

      dispatch(saveSwappedComponentInformation(component));
      if (updateSwapState) {
        updateSwapState(newComponent);
      }
    } catch (err) {
      dispatch({ type: actionTypes.SWAP_COMPONENT_TO_KIT_AUTOASSIGN_FAIL });
      dispatch(showSnackbar('error', 'Failed to change component'));
    }
  };

export const loadKitInfoFromKitDesign =
  (kitId, isMyListRequest, callback, clearCache) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_KIT_INFO_FROM_KIT_DESIGN_START });

    const path = !isMyListRequest
      ? `/api/kit-design/base-kits/${kitId}`
      : `/api/kit-design/kits/${kitId}`;

    try {
      const res = await axios.get(path, {
        headers: { Authorization: 'Bearer ' + getToken() },
      });
      dispatch({
        type: actionTypes.GET_KIT_INFO_FROM_KIT_DESIGN_SUCCESS,
        data: res.data,
      });
      callback && callback();
      clearCache && dispatch(autoSaveKitFormData(null));
    } catch (err) {
      dispatch({ type: actionTypes.GET_KIT_INFO_FROM_KIT_DESIGN_FAIL });
      dispatch(
        showSnackbar(
          err?.response?.data ? 'warning' : 'error',
          err?.response?.data || 'Get Kit information failed',
        ),
      );
    }
  };

export const deleteFromMyKitList = (kitId, role) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_KIT_FROM_KIT_MY_LIST_START });

  try {
    await axios.delete(`/api/custom-kits/${kitId}`, {
      headers: { Authorization: 'Bearer ' + getToken() },
    });

    dispatch({ type: actionTypes.DELETE_KIT_FROM_KIT_MY_LIST_SUCCESS });
    dispatch(showSnackbar('success', 'Kit successfully deleted from list'));
    dispatch(loadKitListFromKitDesign(role));
  } catch (err) {
    dispatch({ type: actionTypes.DELETE_KIT_FROM_KIT_MY_LIST_FAIL });
    dispatch(showSnackbar('error', 'Delete Kit from list failed'));
  }
};

export const validateCustomKitName = (name, id) => async (dispatch) => {
  try {
    const res = await axios.get('/api/custom-kits/validate-name', {
      params: { name, id },
      headers: { Authorization: 'Bearer ' + getToken() },
    });

    dispatch({ type: actionTypes.VALIDATE_KIT_NAME, data: res.data });
  } catch (err) {
    dispatch(showSnackbar('error', 'Validation failed'));
  }
};

export const updateKitFromMyKitList =
  (params, clearCache) => async (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_KIT_FROM_MY_KIT_LIST_START });

    const requestData = {
      Name: params.name,
      ProductFamilyId: params.productFamilyId,
      RegionalManager: params.regionalManager,
      ContactTitle: params.contactTitle,
      ContactName: params.contactName,
      SalesRep: params.salesRep,
      AccountNumber: params.accountNumber,
      FacilityName: params.facilityName,
      FacilityStreet: params.facilityStreet,
      FacilitySuite: params.facilitySuite,
      FacilityCity: params.facilityCity,
      FacilityZipCode: params.facilityZipCode,
      IsNewBusiness: params.isNewBusiness,
      Competitor: params.competitor,
      ReplacedKit: params.replacedKit,
      CurrentPrice: params.currentPrice,
      RequestedPrice: params.requestedPrice,
      EstUsage: params.estUsage,
      IsDirectPurchase: params.isDirectPurchase,
      JitName: params.jitName,
      HospitalAccounts: params.hospitalAccounts,
    };

    try {
      await axios.put(`/api/kit-design/kits/${params.id}`, requestData, {
        headers: { Authorization: 'Bearer ' + getToken() },
      });

      dispatch({ type: actionTypes.UPDATE_KIT_FROM_MY_KIT_LIST_SUCCESS });
      dispatch(showSnackbar('success', 'Kit Information successfully updated'));
      dispatch(loadKitInfoFromKitDesign(params.id, true, null, clearCache));
    } catch (err) {
      dispatch({ type: actionTypes.UPDATE_KIT_FROM_MY_KIT_LIST_FAIL });
      dispatch(showSnackbar('error', 'Update kit data failed'));
    }
  };

export const sendToSubmit = (kitId) => async (dispatch) => {
  dispatch({ type: actionTypes.SEND_KIT_TO_SUBMIT_START });

  try {
    await axios.post(`/api/kit-design/kits/${kitId}/submit`, null, {
      headers: { Authorization: 'Bearer ' + getToken() },
    });

    dispatch({ type: actionTypes.SEND_KIT_TO_SUBMIT_SUCCESS });
    dispatch(loadKitListFromKitDesign());
    dispatch(showSnackbar('success', 'Kit was sent to submit'));
  } catch (err) {
    dispatch({ type: actionTypes.SEND_KIT_TO_SUBMIT_FAIL });
    dispatch(showSnackbar('error', 'Kit was not sent to submit'));
  }
};

export const pushBackToSales = (kitId, role) => async (dispatch) => {
  dispatch({ type: actionTypes.PUSH_BACK_KIT_TO_SALES_START });

  try {
    await axios.post(`/api/kit-design/kits/${kitId}/decline`, null, {
      headers: { Authorization: 'Bearer ' + getToken() },
    });

    dispatch({ type: actionTypes.PUSH_BACK_KIT_TO_SALES_SUCCESS });
    dispatch(loadKitListFromKitDesign(role));
    dispatch(showSnackbar('success', 'Kit was push back to Sales'));
  } catch (err) {
    dispatch({ type: actionTypes.PUSH_BACK_KIT_TO_SALES_FAIL });
    dispatch(showSnackbar('error', 'Kit was not push back to Sales'));
  }
};

export const submitToManufacturing = (kitId, role) => async (dispatch) => {
  dispatch({ type: actionTypes.SUBMIT_KIT_TO_MANUFACTURING_START });

  try {
    await axios.post(`/api/kit-design/kits/${kitId}/approve`, null, {
      headers: { Authorization: 'Bearer ' + getToken() },
    });

    dispatch({ type: actionTypes.SUBMIT_KIT_TO_MANUFACTURING_SUCCESS });
    dispatch(loadKitListFromKitDesign(role));
    dispatch(showSnackbar('success', 'Kit was push back to Sales'));
  } catch (err) {
    dispatch({ type: actionTypes.SUBMIT_KIT_TO_MANUFACTURING_FAIL });
    dispatch(showSnackbar('error', 'Kit was not push back to Sales'));
  }
};

export const addNoteToKit = (kitId, Text) => async (dispatch) => {
  dispatch({ type: actionTypes.NOTE_ADD_START });
  try {
    const res = await axios.post(
      `/api/kit-design/kits/${kitId}/notes`,
      { Text },
      { headers: { Authorization: 'Bearer ' + getToken() } },
    );
    dispatch({ type: actionTypes.NOTE_ADD_SUCCESS, data: res.data });
    dispatch(showSnackbar('success', 'Note added!'));
  } catch (err) {
    dispatch({ type: actionTypes.NOTE_ADD_FAIL });
    dispatch(showSnackbar('error', "Can't add note"));
  }
};

export const buildListPdf = (kitId, name) => async (dispatch) => {
  dispatch({ type: actionTypes.BUILD_KIT_LIST_START });

  try {
    const res = await axios.get(`/api/custom-kits/${kitId}/pdf`, {
      headers: { Authorization: 'Bearer ' + getToken() },
      responseType: 'blob',
    });

    downloadFile(res.data, name, 'pdf');
    dispatch({ type: actionTypes.BUILD_KIT_LIST_SUCCESS });
  } catch (err) {
    showSnackbar('error', 'Build Kit list error');
    dispatch({ type: actionTypes.BUILD_KIT_LIST_FAIL });
  }
};

export const downloadFile = (data, name, format) => {
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.${format}`);
  document.body.appendChild(link);
  link.click();
};

export const swapKitTray =
  (kitId, trayId, buildDefaultPages) => async (dispatch) => {
    dispatch({ type: actionTypes.SWAP_KIT_TRAY_START });

    dispatch({ type: actionTypes.SAVE_COMPONENTS_RANGE, range: null });
    try {
      const res = await axios.post(
        `/api/kit-design/kits/${kitId}/tray/${trayId}`,
        null,
        {
          headers: { Authorization: 'Bearer ' + getToken() },
        },
      );

      const { pages, objects } = buildDefaultPages(res.data);

      dispatch(showSnackbar('success', 'Tray successfully swaped'));
      dispatch({
        type: actionTypes.SWAP_KIT_TRAY_SUCCESS,
        data: { ...res.data, pages, objects },
      });
    } catch (err) {
      dispatch({ type: actionTypes.SWAP_KIT_TRAY_FAIL });
      dispatch(showSnackbar('error', 'Tray swap failed'));
    }
  };

export const changeOrphanComponent = (data) => (dispatch) =>
  dispatch({ type: actionTypes.CHANGE_ORPHAN_COMPONENT, data });

const setComponentQuantity = async (component, kitId, componentId) => {
  const { requestCount, orphanQuantity } = component;

  if (orphanQuantity > requestCount) {
    return;
  }

  await axios.post(
    `/api/kit-design/kits/${kitId}/components/${componentId}/quantity`,
    { Quantity: requestCount },
    {
      headers: { Authorization: 'Bearer ' + getToken() },
    },
  );
};

const getRequestBody = (component) => {
  return {
    ComponentId: component.id,
    CavityId: component.cavity.id,
    X: component.x,
    Y: component.y,
    Rotation: component.r,
    Placement: component.place,
    AssemblyOrder: component.assemblyOrder,
    Type: rules[component.type],
    IsHorizontalFlipped: component.isHorizontalFlipped,
    // IsOverride: component.isOverride
  };
};

export const addComponentToCavityOverride =
  (component, callback) => async (dispatch) => {
    dispatch({ type: actionTypes.ADD_COMPONENT_TO_CAVITY_OVERRIDE_START });

    const requestBody = getRequestBody(component);

    try {
      // check if overrides more then components instances then add instances first
      await setComponentQuantity(component, component.kitId, component.id);

      const endpoints = [...Array(+component.requestCount).keys()].map(
        () => `/api/kit-design/kits/${component.kitId}/components/place`,
      );

      for (const url of endpoints) {
        await axios.post(url, requestBody, {
          headers: { Authorization: 'Bearer ' + getToken() },
        });
      }

      dispatch({
        type: actionTypes.ADD_COMPONENT_TO_CAVITY_OVERRIDE_SUCCESS,
      });

      dispatch(loadKitListFromKitDesign(component.role));

      dispatch(
        showSnackbar(
          'success',
          'Component position successfully applied to sample',
        ),
      );

      // on success reload kit information and call goBack
      if (component.kitId) {
        dispatch(loadKitInfoFromKitDesign(component.kitId, true, callback));
      }
    } catch (err) {
      dispatch({ type: actionTypes.ADD_COMPONENT_TO_CAVITY_OVERRIDE_FAIL });
      dispatch(
        showSnackbar('error', 'Component position not applied to sample'),
      );
    }
  };

export const moveComponentToCavityOverride =
  (component, callback) => async (dispatch) => {
    dispatch({ type: actionTypes.MOVE_COMPONENT_TO_CAVITY_OVERRIDE_START });

    const requestBody = {
      ComponentId: component.id,
      CavityId: component.cavity.id,
      OldCavityId: component.oldCavityId,
      X: component.x,
      Y: component.y,
      Rotation: component.r,
      Placement: component.place,
      AssemblyOrder: component.assemblyOrder,
      Type: rules[component.type],
      IsHorizontalFlipped: component.isHorizontalFlipped,
      // IsOverride: component.isOverride
    };

    // if user want to change isHorizontalFlipped for overriden component
    // we need to check this component for multiples
    const count =
      component.type === 'override' ? component.overrideQuantity : 1;

    const endpoints = [...Array(count).keys()].map(
      () => `/api/kit-design/kits/${component.kitId}/components/move`,
    );

    try {
      for (const url of endpoints) {
        await axios.post(url, requestBody, {
          headers: { Authorization: 'Bearer ' + getToken() },
        });
      }

      dispatch({ type: actionTypes.MOVE_COMPONENT_TO_CAVITY_OVERRIDE_SUCCESS });
      dispatch(loadKitListFromKitDesign(component.role));
      dispatch(
        showSnackbar('success', 'Component successfully moved to a cavity'),
      );
      component.kitId &&
        dispatch(loadKitInfoFromKitDesign(component.kitId, true, callback));
    } catch (err) {
      dispatch({ type: actionTypes.MOVE_COMPONENT_TO_CAVITY_OVERRIDE_FAIL });
      dispatch(showSnackbar('error', 'Move component to a cavity failed'));
    }
  };

export const downloadAssemblyPdf = (kitId) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWLOAD_ASSEMBLY_PDF_START });
  try {
    dispatch(
      showSnackbar('warning', `Please wait for pdf generation finished`),
    );
    const res = await axios.get(`/api/kit-design/kits/${kitId}/snapshots-pdf`, {
      headers: { Authorization: 'Bearer ' + getToken() },
      responseType: 'blob',
    });

    if (!res.data.size) {
      dispatch({ type: actionTypes.DOWLOAD_ASSEMBLY_PDF_FAIL });
      dispatch(
        showSnackbar(
          'warning',
          `Assembly PDF has not been created, Go to 'Assembly Snapshots'`,
        ),
      );
      return;
    }

    dispatch({ type: actionTypes.DOWLOAD_ASSEMBLY_PDF_SUCCESS });
    downloadFile(res.data, 'Snapshots', 'pdf');
  } catch (err) {
    dispatch({ type: actionTypes.DOWLOAD_ASSEMBLY_PDF_FAIL });
    dispatch(showSnackbar('warning', 'Downloading Assembly PDF failed'));
  }
};

export const autoSaveKitFormData = (kitData) => async (dispatch) =>
  dispatch({ type: actionTypes.AUTO_SAVE_KIT_FORM_DATA, data: kitData });

export const assignTrayToBaseKit =
  (kitId, trayId, params, cb = null, customKitId = undefined) =>
  async (dispatch) => {
    dispatch({ type: actionTypes.ASSIGN_TRAY_TO_BASE_KIT_START });

    try {
      await axios.put(
        `/api/cms/kits/${kitId}`,
        { TrayId: trayId, CustomKitId: customKitId },
        { headers: { Authorization: 'Bearer ' + getToken() } },
      );

      dispatch({ type: actionTypes.ASSIGN_TRAY_TO_BASE_KIT_SUCCESS });
      params && dispatch(loadKitListFromKitDesign(params));
      cb && cb();
      dispatch(showSnackbar('success', 'Tray assigned to kit'));
    } catch (err) {
      dispatch(showSnackbar('error', 'Failed assign tray to kit'));
      dispatch({ type: actionTypes.ASSIGN_TRAY_TO_BASE_KIT_FAIL });
    }
  };

export const requestSuaTemplate = (kitName) => async (dispatch) => {
  try {
    const result = await axios.get(`/api/kits/sua-template`, {
      headers: { Authorization: 'Bearer ' + getToken() },
      responseType: 'blob',
    });
    const fileName = kitName + '_Supplemental_user_agreement.xls';
    FileSaver.saveAs(result.data, fileName);
    dispatch(
      showSnackbar('success', 'Supplemental User Agreement Template dowloaded'),
    );
  } catch (err) {
    dispatch(showSnackbar('error', 'Failed to dowload sua template'));
  }
};

export const postAgreement = (file, notes, kitId) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_KIT_TRAY_START });

  const formData = new FormData();

  formData.append('Notes', notes);
  formData.append(file.path, file);

  try {
    await axios.post(`/api/kits/${kitId}/sua`, formData, {
      headers: { Authorization: 'Bearer ' + getToken() },
    });

    dispatch(
      showSnackbar('success', 'Supplemental User Agreement Request was sent!'),
    );
  } catch (err) {
    dispatch(showSnackbar('error', err.response?.data || 'Failed to send sua'));
  }
};

export const listPageBreakImages = () => async (dispatch) => {
  dispatch({ type: actionTypes.LIST_PAGE_BREAK_IMAGES_START });
  try {
    const res = await axios.get(`/api/kit-design/page-break-images`, {
      headers: { Authorization: 'Bearer ' + getToken() },
    });
    dispatch({
      type: actionTypes.LIST_PAGE_BREAK_IMAGES_SUCCESS,
      data: res.data,
    });
  } catch (err) {
    dispatch({ type: actionTypes.LIST_PAGE_BREAK_IMAGES_FAIL });
    dispatch(showSnackbar('error', 'Failed to get list of page break images'));
  }
};

export const addPageBreakImage = (file, name, update) => async (dispatch) => {
  dispatch({
    type: update
      ? actionTypes.UPDATE_PAGE_BREAK_IMAGE_NAME_START
      : actionTypes.ADD_PAGE_BREAK_IMAGE_START,
  });
  const formData = new FormData();
  if (file) {
    formData.append(file.path, file);
    formData.append('Name', name);
  }

  try {
    const res = await axios({
      method: update ? 'PUT' : 'POST',
      url: `/api/kit-design/page-break-images${update ? '/' + update.id : ''}`,
      data: update ? { Name: name } : formData,
      headers: { Authorization: 'Bearer ' + getToken() },
    });
    dispatch({
      type: update
        ? actionTypes.UPDATE_PAGE_BREAK_IMAGE_NAME_SUCCESS
        : actionTypes.ADD_PAGE_BREAK_IMAGE_SUCCESS,
      data: update ? { ...update, name } : res.data,
    });

    dispatch(
      showSnackbar(
        'success',
        `Pagebreak Image ${update ? 'updated' : 'uploaded'}`,
      ),
    );
  } catch (err) {
    dispatch({
      type: update
        ? actionTypes.UPDATE_PAGE_BREAK_IMAGE_NAME_FAIL
        : actionTypes.ADD_PAGE_BREAK_IMAGE_FAIL,
    });
    dispatch(showSnackbar('error', 'Failed to upload page break image'));
  }
};

export const deletePageBreakImage = (id) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_PAGE_BREAK_IMAGE_START });

  try {
    await axios.delete(`/api/kit-design/page-break-images/${id}`, {
      headers: { Authorization: 'Bearer ' + getToken() },
    });
    dispatch({ type: actionTypes.DELETE_PAGE_BREAK_IMAGE_SUCCESS, data: id });
  } catch (err) {
    dispatch({ type: actionTypes.DELETE_PAGE_BREAK_IMAGE_FAIL });
    dispatch(showSnackbar('error', 'Failed to delete page break image'));
  }
};

export const setCurrentComponent = (component) => (dispatch) => {
  dispatch({ type: actionTypes.SELECT_CURRENT_COMPONENT, component });
};

export const getReplacements = async (kitId) => {
  try {
    const res = await axios.get(`/api/kit-design/kits/${kitId}/components`, {
      headers: { Authorization: 'Bearer ' + getToken() },
    });
    return res.data;
  } catch (e) {
    // console.log(e)
  }
  return [];
};

export const saveComponentsRange = (range) => (dispatch) => {
  dispatch({ type: actionTypes.SAVE_COMPONENTS_RANGE, range });
};

export const updateKitPackaging = (packaging, kitId) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_KIT_PACKAGING_START });

  try {
    await axios.put(
      `/api/kit-design/kits/${kitId}/packaging`,
      { PackagingId: packaging.id, Name: packaging.name },
      { headers: { Authorization: 'Bearer ' + getToken() } },
    );
    dispatch({ type: actionTypes.UPDATE_KIT_PACKAGING_SUCCESS });
    dispatch(showSnackbar('success', 'Kit packaging updated.'));
  } catch (err) {
    dispatch({ type: actionTypes.UPDATE_KIT_PACKAGING_FAIL });
    dispatch(showSnackbar('error', 'Failed to update kit packaging'));
  }
};

export const saveCurrentEditingPage = (page) => (dispatch) => {
  dispatch({ type: actionTypes.SAVE_UPDATED_PAGE, page });
};

export const saveSwappedComponentInformation = (component) => (dispatch) => {
  dispatch({
    type: actionTypes.SAVE_SWAPPED_COMPONENT_INFORMATION,
    component,
  });
};

export const addSwappedComponentToCavityOverride =
  (component, swappedComponentProps) => async (dispatch) => {
    dispatch(
      saveSwappedComponentInformation({
        ...swappedComponentProps,
        isSwapped: true,
      }),
    );

    dispatch({ type: actionTypes.ADD_COMPONENT_TO_CAVITY_OVERRIDE_START });

    const componentInfo = await loadCmsComponentTooltipInfoSilent(
      component.component.id,
    );

    const place = componentInfo.images.find(
      (img) => img.url === component.imageUrl,
    )?.name;

    const requestBody = {
      ComponentId: component.component.id,
      CavityId: component.cavityId,
      X: component.x,
      Y: component.y,
      Rotation: component.r,
      Placement: place,
      AssemblyOrder: component.assemblyOrder,
      Type: rules.override,
      IsHorizontalFlipped: component.isHorizontalFlipped,
      // IsOverride: component.isOverride
    };

    try {
      const endpoints = [...Array(+component.overrideQuantity).keys()].map(
        () => `/api/kit-design/kits/${component.kitId}/components/place`,
      );

      for (const url of endpoints) {
        await axios.post(url, requestBody, {
          headers: { Authorization: 'Bearer ' + getToken() },
        });
      }

      dispatch({
        type: actionTypes.ADD_COMPONENT_TO_CAVITY_OVERRIDE_SUCCESS,
      });

      dispatch(loadKitListFromKitDesign(component.role));

      dispatch(
        showSnackbar(
          'success',
          'Component position successfully applied to sample',
        ),
      );

      // on success reload kit information and call goBack
      if (component.kitId) {
        dispatch(loadKitInfoFromKitDesign(component.kitId, true));
      }
    } catch (err) {
      dispatch({ type: actionTypes.ADD_COMPONENT_TO_CAVITY_OVERRIDE_FAIL });
      dispatch(
        showSnackbar('error', 'Component position not applied to sample'),
      );
    }
  };

  export const setSpecialInstructions = async (kitId, specialInstructions) => {
    try {
      const res = await axios.post(
        `/api/custom-kits/${kitId}/pre-confirm`,
        {
          specialInstructions,
        },
        {
          headers: { Authorization: 'Bearer ' + getToken() },
        },
      );
      return res.data;
    } catch (e) {
      // console.log(e)
    }
  };
