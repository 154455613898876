import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faChevronRight, faPlus, faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import Filter from './Filter';
import { statuses } from '../../../constants/constants';
import CustomCheckbox from '../../../components/Inputs/CustomCheckbox';
import { AssignTray } from './components/AssignTray';
import CustomButton from '../../../components/Buttons/CustomButton';
import UserNote from '../../../shared/UserNote';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4px 4px -15px 4px;
`;

const Item = styled.div(
  ({ isStatic, isActive, lowLight }) => `
    cursor: ${isStatic ? 'auto' : 'pointer'};
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    position: relative;
    &:hover {
        background: ${isStatic ? '#242e68' : '#242e68'};
        color: ${isStatic ? 'unset' : 'white'} !important;
        & > div > svg > path {
          fill: orangered;
        }
        [class*='-placeholder'], [class*='-singleValue'], [class*='-indicatorContainer'] {
            color: white;
        }
        [class*='-control'] {
            border-bottom: 1px solid white;
        }
    }

    & > div > span {opacity: ${lowLight ? 0.4 : 1};}
    & > span > svg {
        display: inline-block;
    }

    background: ${isStatic ? '#242e68' : isActive ? '#cdcde0' : null};
    color: ${isStatic ? 'white' : isActive && 'black'};
`,
);

const Title = styled.div`
  background: ${(props) => props.isStatic && '#242e68'};
  color: ${(props) => props.isStatic && 'white'};
  padding: 10px 15px 0 15px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  & > svg {
    margin-top: 4px;
    cursor: pointer;
  }
`;

const SubTitle = styled.span(
  ({ showKitDetails }) => `
    padding-left: 20px;
    margin-top: 5px;
    font-weight: 100;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &:hover {
        font-weight: ${showKitDetails ? 'bold' : 100};
        path: {font-weight: ${showKitDetails ? 'bold' : 100};}
    }
    path {
        fill: currentColor !important;
    }
`,
);

const DeleteIcon = styled.div`
  position: absolute;
  top: 0;
  right: 8px;
  transition: all ease-in-out 100ms;
  path {
    fill: none;
  }
  &:hover {
    font-size: 16px;
    top: -2px;
    right: 4px;
  }
`;

const LabelName = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.isStatic ? 2 : 4)};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Restore = styled.span`
  position: absolute;
  right: 5px;
  top: 0;
  font-size: 10px;
  opacity: 0.4;
  transition: all ease-in-out 100ms;
  &:hover {
    opacity: 1;
    font-size: 11px;
  }
`;

const ImageWrapper = styled.img`
  max-height: 200px;
  padding: 5px;
  flex-shrink: 0;
  object-fit: contain;
  position: relative;
  width: 100%;
  height: 100%;
`;

const List = (props) => {
  const {
    list = [],
    title,
    filter,
    mainDevices,
    isActive,
    color,
    isStatic,
    valueKeys,
    trayList,
  } = props;
  const {
    onItemClick,
    onItemDelete,
    addItem,
    onFilterChange,
    showKitDetails,
    contactEngineering,
    onSearchChange,
    assignTray,
    restoreItem,
  } = props;
  const { onShowDeletedChange, showDeleted, showDeleteLabel, addStarred } =
    props;

  return (
    <Container>
      <Title isStatic={isStatic}>
        <span>{title}</span>
        {addItem && <FontAwesomeIcon icon={faPlus} onClick={addItem} />}
      </Title>

      {filter && (
        <Filter
          filterData={filter}
          onChange={onFilterChange}
          mainDevices={mainDevices}
          onSearchChange={onSearchChange}
        />
      )}
      {onShowDeletedChange && (
        <CustomCheckbox
          label={showDeleteLabel}
          onChange={() => onShowDeletedChange(!showDeleted)}
          checked={showDeleted}
          style={{
            paddingBottom: '20px',
            display: 'flex',
            justifyContent: 'center',
          }}
        />
      )}

      {list.map((l, index) => (
        <div key={l.id + '-' + index}>
          <Item
            onClick={() => onItemClick(l)}
            isActive={isActive?.id === l.id}
            style={{ color: color || null }}
            isStatic={isStatic}
            lowLight={
              valueKeys &&
              !valueKeys.every((v) => l[v]) &&
              isActive?.id !== l.id
            }
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '90%',
              }}
            >
              {l.name && (
                <span style={{ color: l.isOrphan ? 'orange' : 'inherit' }}>
                  {l.name}
                </span>
              )}

              {l.imageUrl && (
                <ImageWrapper alt={'Component'} src={l.imageUrl} />
              )}

              {l.labelName && (
                <LabelName isStatic={isStatic} title={l.labelName}>
                  {l.count && l.count > 1 && (
                    <span style={{ color: 'gray', paddingRight: '5px' }}>
                      ({l.count})
                    </span>
                  )}
                  <span style={{ color: l.isOrphan ? 'orange' : 'inherit' }}>
                    {l.labelName}
                  </span>
                </LabelName>
              )}

              {l.overrideQuantity && (
                <UserNote
                  text={`Quantity: ${l.overrideQuantity}`}
                  align="left"
                />
              )}

              {l.number && (
                <SubTitle style={{ color: l.isOrphan ? 'orange' : 'inherit' }}>
                  {l.isOverride && (
                    <FontAwesomeIcon
                      icon={faBolt}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginRight: 10,
                        color: 'red',
                      }}
                    />
                  )}
                  SKU: {l.number}
                </SubTitle>
              )}
              {l.category && l.category.name && (
                <SubTitle style={{ color: l.isOrphan ? 'orange' : 'inherit' }}>
                  Category: {l.category.name.toLowerCase()}
                </SubTitle>
              )}

              {showKitDetails && (
                <SubTitle
                  showKitDetails={!!showKitDetails}
                  onClick={(e) => {
                    e.stopPropagation();
                    showKitDetails(l);
                  }}
                >
                  See kit details{' '}
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ marginLeft: 10 }}
                  />
                </SubTitle>
              )}

              {valueKeys && !l.trayId && contactEngineering && (
                <SubTitle
                  showKitDetails={true}
                  style={{ opacity: 1 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    contactEngineering(l);
                  }}
                >
                  Contact Engineering
                </SubTitle>
              )}

              {assignTray && !l.trayId && (
                <AssignTray
                  isActive={isActive}
                  trays={trayList.filter((tray) => tray.isVisible)}
                  assignTray={assignTray}
                  tray={l}
                />
              )}

              {l.mainDevices && (
                <SubTitle>
                  Main Devices:{' '}
                  {l.mainDevices.map((device) => (
                    <span key={device.id}>&nbsp;{device.name};&nbsp;</span>
                  ))}
                </SubTitle>
              )}
              {l.facilityName && <SubTitle>{l.facilityName}</SubTitle>}
              {l.created && (
                <SubTitle>
                  Created: {new Date(l.created).toLocaleDateString('en-US')}
                </SubTitle>
              )}
              {l.status && (
                <SubTitle>Status: {statuses[l.status]?.label}</SubTitle>
              )}
              {l.activeTray?.number && (
                <SubTitle>Kit Tray: {l.activeTray.number}</SubTitle>
              )}
            </div>

            {restoreItem && l.isVisible && onItemDelete && (
              <DeleteIcon>
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={(e) => {
                    e.stopPropagation();
                    onItemDelete(l);
                  }}
                />
              </DeleteIcon>
            )}

            {restoreItem && !l.isVisible && (
              <Restore
                onClick={(e) => {
                  e.stopPropagation();
                  restoreItem(l);
                }}
              >
                Restore
              </Restore>
            )}

            {addStarred && (
              <>
                <FontAwesomeIcon
                  icon={faStar}
                  style={{ position: 'absolute', right: 0, top: 10 }}
                />
                <CustomButton
                  label="Add"
                  rounded
                  background="white"
                  color="black"
                  onClick={() => addStarred(l)}
                  hoverEffect
                />
              </>
            )}
          </Item>
        </div>
      ))}
    </Container>
  );
};

export default List;
