import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Route, Switch, useHistory } from 'react-router-dom';
import List from './List';
import TrayNavigation from './TrayNavigation';
import NavigationList from './Tray/NavigationList';
import { kitTrayRoutes } from '../../../constants/constants';
import TrayOverview from './Tray/Overview';
import CustomButton from '../../../components/Buttons/CustomButton';
// import TechnicalDetails from './Tray/TechnicalDetails';
import TrayRules from './Tray/Rules';
import Overview from './Cavity/Overview';
import TrayIcon from '../../../shared/TrayIcon';
import AssemblyOrder from "./Tray/AssemblyOrder/AssemblyOrder";
import FancyScroll from "../../../components/FancyScroll";
import FloatingComponents from './FloatingComponents/FloatingComponents';
import Priority from './Tray/Priority';

const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    background: #1d265b;
    margin-top: -11px;
    width: 270px;
    flex: 0 0 auto;
    color: white;
    position: relative;
    height: 100vh;
    z-index: 1;

    & > div {
        position: fixed;
        width: inherit;
        height: 100%;
        background: #1d265b;
    }
`;

const SubmitSection = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    font-size: 12px;
    bottom: 80px;
    position: absolute;
`;

const save = 'Save Tray Changes';
const create = 'Create New Tray';

const TrayActiveMenu = (props) => {
    const {
      trays,
      tray,
      showNavigation,
      mainDevices,
      selectedCavity,
      cavities,
      newCavityData,
      componentPlacement,
      showDeletedTrays,
      filterCavities,
      setFilterCavities,
      clonedTray,
      pageBreakImages,
      pageBreakImagesLoading,
      selectedPage,
    } = props;
    const {
      onTrayClick,
      deleteTray,
      onTrayChange,
      saveTrayChanges,
      setNewCavityData,
      setTray,
      mainDeviceChange,
      onCavityClick,
      setComponentPlacement,
      setVirtualCavity,
      setUnsavedData,
      setShowDeletedTrays,
      setClonedTray,
      restoreTray,
      setSelectedPage,
    } = props;

    const history = useHistory();

    const currentTray = tray.action
      ? tray
      : history.location.state?.tray || tray;
    const disabled =
      (!currentTray.data?.image &&
        !clonedTray &&
        !currentTray.data?.imageUrl) ||
      !currentTray.data?.number;
    const [filter, setFilter] = useState({ trays, value: null, search: "" });
    const [showDropdown, setShowDropDown] = useState(null);

    useEffect(() => {
      if (currentTray.action === 'Edit Tray' && history.location.state) {
        setTray({
          ...currentTray,
          data: trays.find(
            (t) => t.id === history.location.state.tray.data?.id,
          ),
        });
        return;
      }

      // eslint-disable-next-line
    }, [trays]);

    useEffect(() => {
      if (trays.length) {
        setFilter({ ...filter, trays });
      }
      // eslint-disable-next-line
    }, [trays]);

    useEffect(() => {
      if (history.location.pathname.includes('cavity-create')) {
        setNewCavityData({
          name: '',
          points: [],
        });
      }
      // eslint-disable-next-line
    }, [history.location.pathname]);

    useEffect(() => {
      setShowDropDown(null);
    }, [selectedCavity]);

    let buttonText =
      "/kit-trays" === history.location.pathname
        ? create
        : "/kit-trays/create" === history.location.pathname ||
          kitTrayRoutes.some(
            (l) => history.location.pathname.includes(l.path) && l.visible
          )
        ? save
        : "";

    if (
      ["cavity", "cavities", "floating-components", "priority"].some((path) =>
        history.location.pathname.includes(path)
      )
    ) {
      buttonText = "";
    }

    const onSubmit = () => {
      if (buttonText === save) {
        saveTrayChanges(currentTray);
      } else {
        onTrayClick(null);
        history.push({ pathname: "/kit-trays/create", state: { tray: null } });
      }
    };

    const handleFilterChange = (option) => {
      setFilter({
        ...filter,
        value: option,
        trays: option
          ? trays
              .filter((t) =>
                t.mainDevices.find((md) => option.some((o) => o.id === md.id))
              )
              .filter((t) =>
                t.number.toLowerCase().includes(filter.search.toLowerCase())
              )
          : trays.filter((t) =>
              t.number.toLowerCase().includes(filter.search.toLowerCase())
            ),
      });
    };

    const handleSearchChange = ({ target: { value } }) => {
      setFilter({
        ...filter,
        search: value,
        trays: filter.value
          ? trays
              .filter((t) =>
                t.mainDevices.find((md) =>
                  filter.value.some((o) => o.id === md.id)
                )
              )
              .filter((t) =>
                t.number.toLowerCase().includes(value.toLowerCase())
              )
          : trays.filter(
              (t) =>
                t.number.toLowerCase().includes(value.toLowerCase()) ||
                t.name?.toLowerCase().includes(value.toLowerCase())
            ),
      });
    };

    return (
      <LeftSection>
        <div>
          {showNavigation && (
            <TrayNavigation
              onCavityClick={onCavityClick}
              selectedCavity={selectedCavity}
              setNewCavityData={setNewCavityData}
              showDropdown={showDropdown}
              setShowDropDown={setShowDropDown}
              setUnsavedData={setUnsavedData}
            />
          )}
          <Switch>
            <Route exact path="/kit-trays">
              <FancyScroll offset={150}>
                <List
                  list={
                    showDeletedTrays
                      ? filter.trays
                      : filter.trays.filter((tray) => tray.isVisible)
                  }
                  onItemClick={(tray) => onTrayClick(tray)}
                  onItemDelete={(tray) => deleteTray(tray)}
                  title="Kit Trays"
                  filter={filter}
                  onFilterChange={handleFilterChange}
                  mainDevices={mainDevices}
                  onSearchChange={handleSearchChange}
                  valueKeys={["isVisible"]}
                  restoreItem={restoreTray}
                  onShowDeletedChange={() =>
                    setShowDeletedTrays(!showDeletedTrays)
                  }
                  showDeleted={showDeletedTrays}
                  showDeleteLabel="Show Deleted Trays"
                />
              </FancyScroll>
            </Route>
            <Route exact path="/kit-trays/edit/:id">
              <NavigationList
                list={
                  tray.data?.isVisible
                    ? kitTrayRoutes
                    : kitTrayRoutes.filter((r) => r.path === "/overview")
                }
                icon={
                  <TrayIcon
                    color="white"
                    size="12px"
                    styles={{ marginRight: 10 }}
                  />
                }
              />
            </Route>
            <Route exact path="/kit-trays/edit/:id/overview">
              <TrayOverview
                tray={currentTray}
                onTrayChange={onTrayChange}
                setTray={setTray}
                mainDevices={mainDevices}
                mainDeviceChange={mainDeviceChange}
                trays={trays}
              />
            </Route>
            <Route exact path="/kit-trays/edit/:id/priority">
              <Priority tray={currentTray} cavities={cavities} />
            </Route>
            <Route exact path="/kit-trays/create">
              <TrayOverview
                tray={currentTray}
                onTrayChange={onTrayChange}
                setTray={setTray}
                action="Create Tray"
                mainDevices={mainDevices}
                mainDeviceChange={mainDeviceChange}
                trays={trays}
                clonedTray={clonedTray}
                setClonedTray={setClonedTray}
              />
            </Route>
            {/* <Route exact path="/kit-trays/edit/:id/technical-details">
                        <TechnicalDetails tray={currentTray} onTrayChange={onTrayChange} />
                    </Route> */}
            <Route exact path="/kit-trays/edit/:id/rules">
              <TrayRules tray={currentTray} onTrayChange={onTrayChange} />
            </Route>
            <Route exact path="/kit-trays/edit/:id/cavity/overview">
              <FancyScroll>
                <Overview
                  tray={currentTray}
                  cavities={cavities.filter((f) => !f.isFloating)}
                  cavity={selectedCavity}
                  newCavityData={newCavityData}
                  onCavityClick={onCavityClick}
                  componentPlacement={componentPlacement}
                  setComponentPlacement={setComponentPlacement}
                  showDropdown={showDropdown}
                  setShowDropDown={setShowDropDown}
                />
              </FancyScroll>
            </Route>
            <Route exact path="/kit-trays/edit/:id/cavity-create">
              <FancyScroll>
                <Overview
                  tray={currentTray}
                  cavities={cavities.filter((f) => !f.isFloating)}
                  setNewCavityData={setNewCavityData}
                  newCavityData={newCavityData}
                  cavity={null}
                  componentPlacement={componentPlacement}
                  setComponentPlacement={setComponentPlacement}
                  showDropdown={showDropdown}
                  setShowDropDown={setShowDropDown}
                  hideLimits
                />
              </FancyScroll>
            </Route>
            <Route exact path="/kit-trays/edit/:id/cavity/assembly-order">
              <AssemblyOrder
                cavities={cavities.filter((f) => !f.isFloating)}
                trayId={currentTray.data?.id}
                onCavityClick={onCavityClick}
                filterCavities={filterCavities}
                setFilterCavities={setFilterCavities}
                pageBreakImages={pageBreakImages}
                pageBreakImagesLoading={pageBreakImagesLoading}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </Route>
            <Route exact path="/kit-trays/edit/:id/floating-components">
              <FloatingComponents
                showDropdown={showDropdown}
                setShowDropdown={setShowDropDown}
                componentPlacement={componentPlacement}
                setComponentPlacement={setComponentPlacement}
                selectedCavity={selectedCavity}
                onCavityClick={setVirtualCavity}
                tray={currentTray.data}
              />
            </Route>
          </Switch>
          {buttonText && (
            <SubmitSection>
              <CustomButton
                rounded
                label={buttonText}
                background={`#61A431`}
                onClick={onSubmit}
                disabled={buttonText === save && disabled}
              />
            </SubmitSection>
          )}
        </div>
      </LeftSection>
    );
};

export default TrayActiveMenu;
