import React, { Component } from 'react';
import values from 'lodash/values';
import { connect } from 'react-redux';
import TreeNode from './TreeNode';
import agent from '../agent';
import PropTypes from 'prop-types';

const mapStateToProps = state => ({
  ...state.category,
});

class Tree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nodes: {},
    };
  }

  generateAllFilters = (categories) => {
    const nodes = {};
    categories.forEach((element) => {
      const result = this.printLoop(element, "", true, true);
      const value = Object.values(result)[0];
      nodes[value.path] = value;

      if (element.children) {
        element.children.forEach((ch) => {
          const child = this.printLoop(ch, value.path, false, true);
          const cvalue = Object.values(child)[0];
          nodes[cvalue.path] = cvalue;
        });
      }
    });

    this.setState({ nodes });
  };

  loadTreeData = () => {
    switch (this.props.isFromParent) {
      case "ComponentCatalog":
        agent.Home.categories().then((res) => {
          this.generateAllFilters(res);
        });
        break;
      case "KitCatalog":
        agent.KITCATLOG.categories().then((res) => {
          res.forEach((element) => {
            this.printLoop(element, "", true);
          });
        });
        break;
      case "KitMaker":
        agent.Home.categories().then((res) => {
          res.forEach((element) => {
            this.printLoop(element, "", true);
          });
        });
        break;
      case "KitJustLike":
        agent.KITCATLOG.categories().then((res) => {
          res.forEach((element) => {
            this.printLoop(element, "", true);
          });
        });
        break;
      default:
    }
  };

  componentDidMount() {
    this.loadTreeData();
  }

  printLoop = (obj, parent, isRoot, unsetState) => {
    const acc = {};
    var path = "";
    path = parent + "$$$$" + obj.name;

    acc[path] = {};
    acc[path].path = path;
    acc[path].id = obj.id;
    if (obj.children != null && obj.children.length > 0) {
      var childArray = [];
      obj.children.forEach((elt) => {
        childArray.push(path + "$$$$" + elt.name);
      });
      acc[path].isRoot = isRoot;
      acc[path].children = childArray;
      acc[path].type = "folder";
      var partType = [];
      var partTypeParent = [];

      if (obj.children.partTypes != null) {
        obj.children.partTypes.forEach((elementt) => {
          partType.push(elementt);
        });
        acc[path].partType = partType;
      }
      if (obj.partTypes != null) {
        obj.partTypes.forEach((elementt) => {
          partTypeParent.push(elementt);
        });
        acc[path].partType = partTypeParent;
      }
    } else {
      acc[path].type = "file";
      acc[path].isRoot = isRoot;
      var partTypefile = [];
      obj.partTypes.forEach((element) => {
        partTypefile.push(element);
      });
      acc[path].partType = partTypefile;
    }

    !unsetState && this.setState({ nodes: acc }); // setState in the loop ????
    if (obj.children && !unsetState) {
      obj.children.forEach((elt) => {
        this.printLoop(elt, path, false);
      });
    }

    return acc;
  };

  getRootNodes = () => {
    let nodes = this.state.nodes;
    return values(nodes).filter((node) => node.isRoot === true);
  };

  getChildNodes = (node) => {
    let nodes = this.state.nodes;
    if (!node.children) return [];
    return node.children.map((path) => nodes[path]);
  };

  onToggle = (node) => {
    const temp = { ...this.state.nodes[node.path] };
    this.setState({
      nodes: {
        ...this.state.nodes,
        [node.path]: { ...temp, isOpen: !node.isOpen },
      },
    });
  };

  onNodeSelect = (node, isChecked) => {
    const { onSelect } = this.props;
    onSelect(node, isChecked);
  };

  render() {
    const rootNodes = this.getRootNodes();
    return (
      <div className="nav-tree mb-2">
        {rootNodes.map((node) => (
          <TreeNode
            node={node}
            getChildNodes={this.getChildNodes}
            onToggle={this.onToggle}
            onNodeSelect={this.onNodeSelect}
            selectedNode={this.props.selectedParentNode}
            key={"treeNode" + node.id}
            id={this.props.id}
          />
        ))}
      </div>
    );
  }
}

Tree.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Tree);