import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash-es";
import { NavLink } from "react-router-dom";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { changeCavitiesAssemblyOrder } from "../../../../../redux/actions/kitTrays";
import CustomButton from "../../../../../components/Buttons/CustomButton";
import FancyScroll from "../../../../../components/FancyScroll";
import {
  hideReminder,
  showReminder,
} from "../../../../../redux/actions/snackbar";
import ModeSwitcher from "../ModeSwitcher";
import { filterCavitiesFunc } from "../../../../../constants/constants";
import CavityDnD from "./CavityDnD";
import PageDnD from "./PageDnD";
import usePrevious from "../../../../../hooks/usePrevious";

export const Container = styled.div`
  display: flex;
  padding: 20px 10px;
  flex-direction: column;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
`;

export const Content = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  & > span {
    display: flex;
    margin-top: 40px;
    font-weight: 500;
    font-size: 12px;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    & > a {
      color: white;
      text-decoration: underline;
      margin-top: 5px;
    }
  }
`;

export const Description = styled.div`
  font-size: 12px;
  text-align: center;
  padding: 10px;
  font-weight: 500;

  & > div {
    border-top: 3px dashed white;
    padding-top: 10px;
  }
`;

function isPageExist(pageId, arr) {
  return arr.filter((r) => !r.points).find((p) => p.id === pageId);
}

function getPagesCount(array) {
  const count = array.filter((r) => !r.points).length;
  return count < 0 ? 0 : count;
}

const AssemblyOrder = (props) => {
  const {
    cavities,
    trayId,
    onCavityClick,
    isDragDisabled,
    icon,
    onIconClick,
    inActiveCavityId,
    filterCavities,
    setFilterCavities,
    offset = 310,
    pageBreakImages,
    pageBreakImagesLoading,
    selectedPage,
    setSelectedPage,
  } = props;

  const [state, setState] = useState([]);
  const dispatch = useDispatch();
  const { reminder } = useSelector((state) => state.snackbar);
  const [position, setPosition] = useState(null);

  const prev = usePrevious(cavities);
  useEffect(() => {
    if (cavities.length !== prev.length) {
      const res = [
        {
          id: 0,
          image:
            cavities.find((cav) => cav.pageId === 0)?.pageBreakImageId || null,
        },
      ];

      cavities.forEach((item) => {
        if (item.pageId !== null && !isPageExist(item.pageId, res)) {
          res.push({ id: item.pageId, image: item.pageBreakImageId }, item);
        } else {
          res.push(item);
        }
      });
      setState(res);
    }
  }, [cavities, prev.length]);

  useEffect(() => {
    return () => {
      dispatch(hideReminder());
    };
  }, [dispatch]);

  const rebuildPageIds = useCallback((result, updateCavityCount) => {
    let count = result.filter((r) => r.points).length;
    let pageId = 0;

    return result.map((item) => {
      if (item.points) {
        return {
          ...item,
          ...(updateCavityCount && { assemblyOrder: count-- }),
          pageId: pageId - 1,
        };
      }

      return { ...item, id: pageId++ };
    });
  }, []);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(cloneDeep(list));
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    if (!reminder) {
      dispatch(showReminder());
    }

    return rebuildPageIds(result, true);
  };

  const onDragEnd = ({ destination, source }) => {
    if (!destination || !destination.index) return;

    const items = reorder(state, source.index, destination.index);
    setState(items);
  };

  const addDefaultPage = useCallback(() => {
    setState((prevState) => {
      const page = { id: 0, image: null };

      return [
        page,
        ...prevState.map((ps) =>
          ps.points
            ? { ...ps, pageId: ps.pageId + 1 }
            : { id: ps.id + 1, image: ps.image }
        ),
      ];
    });
  }, []);

  const removeDefaultPage = useCallback(
    (id) => {
      setState((prevState) => {
        const result = prevState.filter((ps) =>
          !ps.points ? ps.id !== id : true
        );

        return rebuildPageIds(result);
      });
    },
    [rebuildPageIds]
  );

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    userSelect: "none",
    background: isDragging ? "#cdcde0" : "",
    color: isDragging ? "black" : "",
  });

  const multiList = state
    .filter((item) => item.points)
    .map((x) => ({
      value: x.assemblyOrder,
      label: `${x.assemblyOrder}. ${x.name}`,
    }));

  const onPageClick = useCallback(
    (page, type) => {
      const cavities = state.filter((st) => st.pageId === page.value);

      if (cavities.length) {
        setFilterCavities((prev) => ({
          ...prev,
          [type]:
            type === "from"
              ? cavities[0].assemblyOrder
              : cavities[cavities.length - 1].assemblyOrder,
        }));
      }
    },
    [state, setFilterCavities]
  );

  return (
    <>
      <ModeSwitcher
        title={"Displayed cavities"}
        options={multiList}
        onChange={(value) => value === 0 && setFilterCavities({})}
        filterCavities={filterCavities}
        setFilterCavities={setFilterCavities}
        pages={state
          .filter((st) => !st.points)
          .map((p, _, arr) => ({
            label: `Page ${arr.length - p.id}`,
            value: p.id,
          }))}
        onPageClick={onPageClick}
      />
      <FancyScroll offset={offset}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Container {...provided.droppableProps} ref={provided.innerRef}>
                <Title>
                  <span>Cavity Id</span>
                  <span>Assembly Order</span>
                </Title>
                <Content>
                  {state.length > 0 ? (
                    state
                      .filter((x) => filterCavitiesFunc(x, filterCavities))
                      .map((item, i) => {
                        if (item.points) {
                          return (
                            <CavityDnD
                              cavity={item}
                              isDragDisabled={isDragDisabled}
                              onDragEnd={onDragEnd}
                              onIconClick={onIconClick}
                              onCavityClick={onCavityClick}
                              inActiveCavityId={inActiveCavityId}
                              icon={icon}
                              i={i}
                              key={"cavity-" + item.id}
                              getItemStyle={getItemStyle}
                            />
                          );
                        }

                        return (
                          <PageDnD
                            page={item}
                            key={"page-" + item.id}
                            i={i}
                            getItemStyle={getItemStyle}
                            removeDefaultPage={removeDefaultPage}
                            papesQuantity={getPagesCount(state)}
                            setAssemblyState={setState}
                            setSelectedPage={setSelectedPage}
                            selectedPage={selectedPage}
                            pageBreakImages={pageBreakImages}
                            pageBreakImagesLoading={pageBreakImagesLoading}
                            position={position}
                            setPosition={setPosition}
                          />
                        );
                      })
                  ) : (
                    <>
                      <span>
                        ! There are no cavities created for this tray.
                        <NavLink to={`/kit-trays/edit/${trayId}/cavity-create`}>
                          {" "}
                          Create a cavity now
                        </NavLink>
                      </span>
                    </>
                  )}
                  {provided.placeholder}
                </Content>
              </Container>
            )}
          </Droppable>
        </DragDropContext>
      </FancyScroll>
      <CustomButton
        rounded
        background="#61A431"
        label="Add Default Page"
        onClick={addDefaultPage}
        style={{
          margin: "0 auto",
          display: "flex",
          fontSize: 10,
          alignItems: "center",
        }}
        icon={<FontAwesomeIcon icon={faPlus} />}
        disabled={!state.length}
      />
      {!isDragDisabled && (
        <>
          <Description>
            <div>Drag Up or Down to change Order</div>
          </Description>
          <CustomButton
            rounded
            background="#61A431"
            label="Save Changes"
            onClick={() => {
              dispatch(
                changeCavitiesAssemblyOrder(
                  trayId,
                  state.filter((s) => s.points)
                )
              );

              dispatch(hideReminder());
            }}
            style={{ margin: "0 auto", display: "flex", fontSize: 12 }}
            disabled={!state.length}
          />
        </>
      )}
    </>
  );
};

export default AssemblyOrder;
