import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch } from "react-redux";
import CustomInput from "../../../../components/Inputs/CustomInput";
import { changeOrphanComponent } from "../../../../redux/actions/kits";
import { faCircle as fasCircle } from "@fortawesome/free-regular-svg-icons";

const InputGroup = styled.div`
  display: flex;
  width: 100%;
  font-size: 12px;
  padding: 0 10px;
  align-items: baseline;
  position: relative;
  justify-content: space-between;
  & > div {
    width: ${({ width }) => width || 100}%;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input {
    background: #1d265b;
    color: #ffffffa3;
    text-align: center;
  }
  input:focus + label span,
  input:valid + label span {
    color: white !important;
    font-size: 12px !important;
  }
  label,
  label:after {
    border-bottom: 1px solid #ffffffa3;
  }
`;

export default function Positions({
  orphanComponent,
  componentAssignments = [],
}) {
  const dispatch = useDispatch();
  const isOrphan = !componentAssignments.find(
    (c) =>
      c.component.id === orphanComponent?.id &&
      c.cavityId === orphanComponent?.oldCavityId
  );

  const limitTo4Decimal = (val) => {
    if (!val || Number.isInteger(+val)) {
      return val;
    }

    return (+val).toFixed(4);
  };

  const onPositionChange = (e) => {
    const { name, value } = e.target;
    dispatch(changeOrphanComponent({ ...orphanComponent, [name]: value }));
  };

  const changeQuantity = (e) => {
    dispatch(
      changeOrphanComponent({
        ...orphanComponent,
        requestCount: e.target.value,
      })
    );
  };

  return (
    <>
      <br />
      <InputGroup width={30}>
        <CustomInput
          value={limitTo4Decimal(orphanComponent?.x)}
          name="x"
          type="number"
          onChange={onPositionChange}
          label="X:"
        />
        <CustomInput
          value={limitTo4Decimal(orphanComponent?.y)}
          name="y"
          type="number"
          onChange={onPositionChange}
          label="Y:"
        />
        <CustomInput
          value={limitTo4Decimal(orphanComponent?.r)}
          name="r"
          type="number"
          onChange={onPositionChange}
          label="R:"
        />
        <FontAwesomeIcon
          icon={fasCircle}
          style={{
            marginRight: 0,
            position: "absolute",
            top: 10,
            right: 25,
            fontSize: 6,
          }}
        />
      </InputGroup>
      <br />
      {isOrphan && (
        <InputGroup>
          <CustomInput
            label="Quantity"
            value={orphanComponent?.requestCount}
            type="number"
            onChange={changeQuantity}
          />
        </InputGroup>
      )}
    </>
  );
}
