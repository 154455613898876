import React, { useState } from 'react';
import styled from '@emotion/styled';
import { faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FancyScroll from '../../../components/FancyScroll';
import { getAspectRatioOffset } from "../../../constants/constants";

const Container = styled.div`
  width: 250px;
  min-height: 300px;
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1d2659;
`;

const Title = styled.div`
  color: #1d2659;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  width: 100%;
`;

const Icon = styled.div`
  position: absolute;
  bottom: 10px;
  right: 20px;
  font-size: 14px;
`;

const Input = styled.input`
  height: 150px;
  border-radius: 10px;
  width: 260px;
  margin: 0px 10px 0px 10px !important;
  text-indent: 30px;
  box-shadow: unset !important;
  border: 1px solid #1d2659 !important;
  color: #1d2659;
`;

const InputWrapper = styled.div`
  position: relative;
  padding-top: 35px;
`;

const NotesWrapper = styled.div`
  width: 250px;
`;

const UserInfo = styled.div`
  position: absolute;
  top: 40px;
  right: 20px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #1d2659;
  text-align: right;
`;

const Button = styled.div`
  padding-top: 35px;
  position: relative;
  color: #1d2659;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline #1d2659;
  cursor: pointer;
  padding-bottom: 10px;
`;

const Note = styled.div`
  text-align: left;
`;

const NoteInfo = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #1d2659;
  text-align: left;
  padding-top: 10px;
`;

const NoteTooltip = ({ notes, user, addNote, close }) => {
  const [note, setNote] = useState("");

  const submit = () => {
    if (note.length > 0) {
      addNote(note);
      setNote("");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submit();
    }
  };

  return (
    <Container>
      <Title>Notes</Title>
      {notes && notes.length > 0 && (
        <FancyScroll offset={getAspectRatioOffset()}>
          <NotesWrapper>
            {notes.map((note) => (
              <React.Fragment key={note.id}>
                <NoteInfo>
                  {note.createdBy.firstName} {note.createdBy.lastName} -{" "}
                  {note.createdBy.role} <br />
                  {new Date(note.createdAt).toLocaleDateString("en-US")}
                </NoteInfo>
                <Note>{note.text}</Note>
              </React.Fragment>
            ))}
          </NotesWrapper>
        </FancyScroll>
      )}
      <InputWrapper>
        <Icon>
          <FontAwesomeIcon icon={faPencilAlt} />
        </Icon>
        {user && (
          <UserInfo>
            {user.firstName} {user.lastName} - {user.role.name} <br />
            {new Date().toLocaleDateString("en-US")}
          </UserInfo>
        )}
        <Input
          value={note}
          onChange={(e) => setNote(e.target.value)}
          onKeyPress={handleKeyPress}
        />
      </InputWrapper>
      <Button
        onClick={submit}
        style={{
          opacity: note.length > 0 ? 1 : 0.5,
          cursor: note.length > 0 ? "pointer" : "unset",
        }}
      >
        add note
      </Button>
      {close && (
        <FontAwesomeIcon
          onClick={close}
          icon={faTimes}
          style={{
            color: "orangered",
            top: 5,
            right: 10,
            position: "absolute",
            cursor: "pointer",
          }}
        />
      )}
    </Container>
  );
};

export default NoteTooltip;
