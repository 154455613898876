import React, { useEffect, useState } from 'react';
import styled from "@emotion/styled";

const Container = styled.div`
    opacity: 1;
    transition: all 0.2s;
`;

const Overlay = styled.div(() => `
    background-color: gray;
    opacity: 0.8;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 500000;
    transition: all 0.3s;
`);

const Modal = styled.div`
    background-color: white;
    box-shadow: 0px 2px 5px #333;
    color: black;
    left: 55%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 600000;
    padding: 10px 20px;
    transition: all 0.3s;
    border-radius: 10px;
`;

const ModalTitle = styled.p`
    border-bottom: 1px solid red;
    font-size: 1.5em;
    padding-bottom: 10px;
`;

export default props => {
    const { title, close, width = 650 } = props;
    const [show, setShow] = useState(false);

    useEffect(() => {
        window.addEventListener('keydown', escDown, false);
        return () => window.removeEventListener('keydown', escDown, false);
    }, []);
    useEffect(() => setShow(true), [setShow]);

    const escDown = e => {
        if (e.key !== 'Escape') return;
        setShow(false);
    }

    const onClose = () => {
        setShow(false);
    };

    const transitionEnd = () => {
        if (show === false) {
            close();
        }
    };

    return (
        <Container style={{ opacity: show ? '1' : '0' }}>
            <Overlay style={{ opacity: show ? '0.8' : '0' }} onClick={onClose} />
            <Modal onTransitionEnd={transitionEnd} role="dialog" style={{ top: show ? '50%' : '30%', width }}>
                {title ? <ModalTitle>{title}</ModalTitle> : null}
                {React.Children.map(props.children, child => ({ ...child, props: { ...child.props, onClose } }))}
            </Modal>
        </Container>
    );
};