import * as actions from '../../constants/actionTypes';

const initialState = {
    packagingOptions: [],
    components: [],
    packagingOptionsLoading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_PACKAGING_OPTIONS_START:
        case actions.DELETE_PACKAGING_OPTIONS_START:
        case actions.UPDATE_PACKAGING_OPTIONS_START:
        case actions.CREATE_PACKAGING_OPTIONS_START:
        case actions.DELETE_PACKAGING_OPTION_IMAGE_START:
        case actions.UPDATE_PACKAGING_OPTION_IMAGE_START:
            return {
                ...state,
                packagingOptionsLoading: true
            }

        case actions.UPDATE_PACKAGING_OPTIONS_SUCCESS:
            return {
                ...state,
                packagingOptions: [...state.packagingOptions.filter(x => x.id !== action.id), {...state.packagingOptions.find(x => x.id === action.id), name: action.requestBody.Name}],
                packagingOptionsLoading: false
            }

        case actions.GET_PACKAGING_OPTIONS_SUCCESS:
            return {
                ...state,
                packagingOptions: action.options,
                packagingOptionsLoading: false
            }

        case actions.DELETE_PACKAGING_OPTION_IMAGE_SUCCESS:
            return {
                ...state,
                packagingOptions: [...state.packagingOptions.filter(x => x.id !== action.id), {...state.packagingOptions.find(x => x.id === action.id), imageUrl: undefined}],
                packagingOptionsLoading: false
            }

        case actions.CREATE_PACKAGING_OPTIONS_SUCCESS:
            return {
                ...state,
                packagingOptions: [...state.packagingOptions, action.data],
                packagingOptionsLoading: false
            }

        case actions.DELETE_PACKAGING_OPTIONS_SUCCESS:
            return {
                ...state,
                packagingOptions: state.packagingOptions.filter(x => x.id !== action.id),
                packagingOptionsLoading: false
            }

        case actions.CLEAR_PACKAGING_OPTION_COMPONENTS_DATA:
            return {
                ...state,
                components: []
            }

        case actions.GET_PACKAGING_OPTION_COMPONENTS_SUCCESS:
            return {
                ...state,
                components: action.components
            }

        case actions.DELETE_PACKAGING_OPTION_COMPONENT_SUCCESS:
            return {
                ...state,
                components: state.components.filter(x => x.component.id !== action.componentId)
            }

        default:
            return {
                ...state,
                packagingOptionsLoading: false
            }
    };
};
