import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { orderBy } from "lodash-es";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import CustomButton from "../../../../components/Buttons/CustomButton";
import GridList from "../../../../components/Lists/GridList";
import { manufacturing } from "../../../../constants/constants";
import GoBack from "../../Helpers/GoBack";
import { loadComponentCategories } from "../../../../redux/actions/componentCategories";
import { flatten } from "../../../../utils/categoryMapping";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import SortSwitcher from "../../Helpers/SortSwitcher";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  .__react_component_tooltip.place-right::after {
    margin-top: ${(props) => -props.tooltipOffset}px !important;
  }
  .__react_component_tooltip.place-right {
    margin-top: ${(props) => props.tooltipOffset}px !important;
  }
  .__react_component_tooltip {
    padding: 8px;
  }
  .__react_component_tooltip.show {
    margin-left: 10px !important;
  }
`;

const TooltipCotainer = styled.div`
  display: flex;
  width: 250px;
  height: 320px;
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-weight: 500;
    font-size: 12px;
    text-align: left;
    width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
`;

const Image = styled.div`
  margin-top: 5px;
  background: #ececec;
  width: 220px;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #8e92ad;
  padding: 5px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
  svg {
    font-size: 60px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  font-size: 10px;
  margin-top: 15px;
`;

const Description = styled.span`
  color: orangered;
  text-align: center !important;
  opacity: 0.7;
  font-size: 10px;
  margin-top: 5px;
`;

const OrphanComponents = ({ status, deleteComponent, isEngineering }) => {
  const { kitDetails, categories } = useSelector((state) => ({
    kitDetails: state.kits.kitDetails,
    categories: state.componentCatalog.categories,
  }));
  const [activeComponent, setActiveComponent] = useState({});
  const [position, setPosition] = useState(null);
  const [sort, setSort] = useState("asc");

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef(null);
  const refContainer = useRef(null);
  const readOnly = status === manufacturing;
  const flattenCategories = orderBy(flatten(categories, false), "name", "asc");

  useEffect(() => {
    dispatch(loadComponentCategories());
  }, [dispatch]);

  useLayoutEffect(() => {
    ReactTooltip.rebuild();
  }, [kitDetails]);

  const getCategoryImage = (categoryId) =>
    flattenCategories.find((category) => category.id === categoryId)?.photoUrl;

  const orphans = kitDetails?.componentAssignments
    .filter((c) => c.isOrphan)
    .map((c) => ({
      ...c.component,
      assemblyOrder: c.assemblyOrder,
      x: c.x,
      y: c.y,
      r: c.rotation,
      imageUrl: c.imageUrl || getCategoryImage(c.component.category.id),
    }));

  const swapOrphanComponent = () => {
    history.push({
      pathname: `/my-kit-list/${kitDetails.id}/kitmaker/add-component`,
      state: {
        component: activeComponent,
      },
    });
  };

  const placeOrphanInTray = () => {
    history.push({
      pathname: `/my-kit-list/${kitDetails.id}/kitmaker/orphan-components/place-in-tray`,
      state: {
        component: activeComponent,
        quantity: orphans.filter((o) => o.id === activeComponent.id).length,
        isOrphan: true,
      },
    });
  };

  const deleteOrphanComponent = (component) => {
    deleteComponent(component.id, null, true);
  };

  const closeTooltip = () => {
    ref.current.tooltipRef = null;
    ReactTooltip.hide();
  };

  const handleActiveComponent = (item, event) => {
    setActiveComponent(item);
    if (event.clientY > refContainer.current.offsetHeight - 130) {
      setPosition(-130);
    } else if (event.clientY < 200) {
      setPosition(150);
    } else {
      setPosition(null);
    }
  };

  return (
    <Container ref={refContainer} tooltipOffset={position || 50}>
      <GoBack title="Orphan Components" />

      <SortSwitcher sortDirection={sort} setSortDirection={setSort} />

      <GridList
        list={orderBy(orphans, "number", sort)}
        labelKey={"number"}
        label={"SKU"}
        offset={150}
        onItemClick={handleActiveComponent}
        onItemDelete={readOnly ? null : deleteOrphanComponent}
      />
      <ReactTooltip
        ref={ref}
        isCapture={true}
        place="right"
        event="click"
        id="GridTip"
        effect="solid"
        type="light"
        clickable={true}
        scrollHide={true}
        resizeHide={true}
      >
        <TooltipCotainer>
          <FontAwesomeIcon
            icon={faTimesCircle}
            style={{
              color: "orangered",
              alignSelf: "flex-end",
              cursor: "pointer",
            }}
            onClick={closeTooltip}
          />
          <span title={activeComponent.labelName}>
            Name: {activeComponent.labelName}
          </span>
          <span title={activeComponent.number}>
            SKU: {activeComponent.number}
          </span>
          <span title={activeComponent.description}>
            Description: {activeComponent.description}
          </span>
          <span>Category: {activeComponent.category?.name}</span>
          <Image>
            {activeComponent.imageUrl ? (
              <img src={activeComponent.imageUrl} alt="Tooltip Grid" />
            ) : (
              <>
                <FontAwesomeIcon icon={faImage} />
                No image
              </>
            )}
          </Image>
          <Buttons>
            <CustomButton
              label="Swap Component"
              onClick={swapOrphanComponent}
              disabled={readOnly}
            />
            {isEngineering && (
              <CustomButton
                label="Place in Tray"
                disabled={readOnly}
                onClick={placeOrphanInTray}
              />
            )}
          </Buttons>
          {readOnly && <Description>Kit status: Approved</Description>}
        </TooltipCotainer>
      </ReactTooltip>
    </Container>
  );
};

export default OrphanComponents;
