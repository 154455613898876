import React from 'react';
import styled from '@emotion/styled';

import { corsPatch } from '../../constants/constants';

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  padding: 10px;
  background: #ececec;
  border: 1px solid #959595;
  font-weight: 700;
  font-size: 5px;
  text-align: center;
  cursor: pointer;

  & img {
    max-height: 100px;
    max-width: 180px;
    padding-bottom: 0.75rem;
    object-fit: contain;
  }

  & span {
    letter-spacing: 1px;
    font-size: 0.75rem;

    & a {
      padding: 0px 2px;
      cursor: pointer;
    }
  }
`;

const RemoveIcon = styled.i`
  position: absolute;
  top: 5px;
  right: 5px;
  color: #de231d;
  font-size: 16px;
  cursor: pointer;
`;

const ComponentGridCard = ({
  component,
  increaseComponentQuantity,
  decreaseComponentQuantity,
  removeComponent,
  getComponentDetail,
  isPdfLoading,
}) => {
  return (
    <Card
      onClick={() => {
        getComponentDetail(component.id);
      }}
    >
      {component.imageUrl && (
        <img alt="" src={corsPatch + component.imageUrl} />
      )}
      {!isPdfLoading && (
        <RemoveIcon
          className="fa fa-window-close"
          aria-hidden="true"
          onClick={(e) => {
            e.stopPropagation();
            removeComponent(component);
          }}
        />
      )}
      <span>
        Quantity: {component.quantity}
        {!isPdfLoading && (
          <>
            <a href="#no">
              <i
                className="fa fa-plus"
                onClick={(e) => {
                  e.stopPropagation();
                  increaseComponentQuantity(component.id);
                }}
              ></i>
            </a>
            <a href="#no">
              <i
                className="fa fa-minus"
                onClick={(e) => {
                  e.stopPropagation();
                  decreaseComponentQuantity(component.id);
                }}
              ></i>
            </a>
          </>
        )}
      </span>
      <span>Name: {component.labelName}</span>
      <span>SKU: {component.number}</span>
    </Card>
  );
};

export default ComponentGridCard;
