import React from 'react';

const TrayIcon = ({ color, size, styles }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" fill={color} width={size} height={size} viewBox="0 0 21 21" style={styles}>
            <defs>
                <clipPath id="clip-Artboard_3">
                    <rect width="21" height="21" />
                </clipPath>
            </defs>
            <g id="Artboard_3" data-name="Artboard – 3" clipPath="url(#clip-Artboard_3)">
                <g id="Group_63563" data-name="Group 63563" transform="translate(92 -21)">
                    <g id="Group_63473" data-name="Group 63473" transform="translate(14192 9674)">
                        <path id="Icon_metro-insert-template" data-name="Icon metro-insert-template" d="M9.944,5.615H12.4V6.844H9.944Zm3.687,0h2.458V6.844H13.631Zm6.145,0v4.916H16.089V9.3h2.458V6.844H17.318V5.615ZM8.715,9.3h2.458v1.229H8.715Zm3.687,0H14.86v1.229H12.4ZM6.258,6.844V9.3H7.486v1.229H5.029V5.615H8.715V6.844Zm3.687,6.145H12.4v1.229H9.944Zm3.687,0h2.458v1.229H13.631Zm6.145,0V17.9H16.089V16.675h2.458V14.217H17.318V12.989ZM8.715,16.675h2.458V17.9H8.715Zm3.687,0H14.86V17.9H12.4ZM6.258,14.217v2.458H7.486V17.9H5.029V12.989H8.715v1.229ZM21,3.157H3.8V20.362H21V3.157Zm1.229-1.229V21.591H2.571V1.928H22.234Z" transform="translate(-14285.745 -9653.928)" />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default TrayIcon;
