import * as actionTypes from '../../constants/actionTypes';
import { getToken } from '../../utils/getToken';
import axios from '../axiosMiddleware/axios';

export const loadPackagingOptions = () => async dispatch => {
    dispatch({ type: actionTypes.GET_PACKAGING_OPTIONS_START });

    try {
        const res = await axios.get('/api/cms/packagings', { headers: { Authorization: 'Bearer ' + getToken() } });
        dispatch({ type: actionTypes.GET_PACKAGING_OPTIONS_SUCCESS, options: res.data });
    } catch (err) {
        dispatch({ type: actionTypes.GET_PACKAGING_OPTIONS_FAIL, error: err });
    }
};

export const updatePackagingOption = (id, name) => async dispatch => {
    dispatch({ type: actionTypes.UPDATE_PACKAGING_OPTIONS_START });

    const requestBody = {
        Name: name,
    };

    try {
        await axios.put(`/api/cms/packagings/${id}`, requestBody, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.UPDATE_PACKAGING_OPTIONS_SUCCESS, id, requestBody });
        dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'success', message: 'Packaging option updated' } });
    } catch (err) {
        let message = '';

        if (err.response?.status === 404) {
            message = "Product family with the provided id doesn't exist";
        } else if (err.response?.status === 400) {
            message = err.response.data;
        } else {
            message = 'Something went wrong, please try later'
        }

        dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'error', message } });
        dispatch({ type: actionTypes.UPDATE_PACKAGING_OPTIONS_FAIL, error: err });
    }
};

export const createPackagingOption =
  (name, setSelected, history) => async (dispatch) => {
    dispatch({ type: actionTypes.CREATE_PACKAGING_OPTIONS_START });

    const requestBody = {
      Name: name,
    };

    try {
      const res = await axios.post("/api/cms/packagings", requestBody, {
        headers: { Authorization: "Bearer " + getToken() },
      });

      setSelected(res.data.id);
      history.push(`/packaging-options/${res.data.id}`);

      dispatch({
        type: actionTypes.CREATE_PACKAGING_OPTIONS_SUCCESS,
        data: res.data,
      });
      dispatch({
        type: actionTypes.SHOW_SNACKBAR,
        snackbar: { type: "success", message: "Packaging option created" },
      });
    } catch (err) {
      let message = "";

      if (err.response?.status === 400) {
        message = "Some parameters are invalid";
      } else {
        message = "Something went wrong, please try later";
      }

      dispatch({
        type: actionTypes.SHOW_SNACKBAR,
        snackbar: { type: "error", message },
      });
      dispatch({ type: actionTypes.CREATE_PACKAGING_OPTIONS_FAIL, error: err });
    }
  };

export const deletePackagingOption = (id) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_PACKAGING_OPTIONS_START });

  try {
    await axios.delete(`/api/cms/packagings/${id}`, {
      headers: { Authorization: "Bearer " + getToken() },
    });

    dispatch({ type: actionTypes.DELETE_PACKAGING_OPTIONS_SUCCESS, id });
    dispatch({
      type: actionTypes.SHOW_SNACKBAR,
      snackbar: { type: "success", message: "Packaging option deleted" },
    });
  } catch (err) {
    let message = "";

    if (err.response?.status === 404) {
      message = "Packaging option with the provided id doesn't exist";
    } else if (err.response?.status === 400) {
      message = err.response.data;
    } else {
      message = "Something went wrong, please try later";
    }

    dispatch({
      type: actionTypes.SHOW_SNACKBAR,
      snackbar: { type: "error", message },
    });
    dispatch({ type: actionTypes.DELETE_PACKAGING_OPTIONS_FAIL, error: err });
  }
};

export const deletePackagingOptionImage = (id) => async dispatch => {
    dispatch({ type: actionTypes.DELETE_PACKAGING_OPTION_IMAGE_START });

    try {
        await axios.delete(`/api/cms/packagings/${id}/image`, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.DELETE_PACKAGING_OPTION_IMAGE_SUCCESS, id });
        dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'success', message: 'Packaging option image deleted' } });
    } catch (err) {
        let message = '';

        if (err.response?.status === 404) {
            message = "Packaging option with the provided id doesn't exist";
        } else if (err.response?.status === 400) {
            message = err.response.data;
        } else {
            message = 'Something went wrong, please try later';
        }

        dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'error', message } });
        dispatch({ type: actionTypes.DELETE_PACKAGING_OPTION_IMAGE_FAIL, error: err });
    }
};

export const updatePackagingOptionImage = (id, file) => async dispatch => {
    dispatch({ type: actionTypes.UPDATE_PACKAGING_OPTION_IMAGE_START });

    const formData = new FormData();

    formData.append('image', file);

    try {
        await axios.post(`/api/cms/packagings/${id}/image`, formData, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'success', message: 'Image successfully updated' } });
        dispatch({ type: actionTypes.UPDATE_PACKAGING_OPTION_IMAGE_SUCCESS });
        dispatch(loadPackagingOptions());
    } catch (err) {
        dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'error', message: 'Image update failed' } });
        dispatch({ type: actionTypes.UPDATE_PACKAGING_OPTION_IMAGE_FAIL, error: err });
    }
};

export const addComponentToPackagingOption = (id, componentId, groupId) => async dispatch => {
    dispatch({ type: actionTypes.ADD_COMPONENT_TO_PACKAGING_OPTION_START });

    const requestBody = {
        GroupNumber: groupId || 0,
        ComponentId: componentId
    };

    try {
        await axios.post(`/api/cms/packagings/${id}/components`, requestBody, { headers: { Authorization: 'Bearer ' + getToken() } });
        dispatch(loadPackagingOptionComponents(id));
        dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'success', message: 'Component added' } });
        dispatch({ type: actionTypes.ADD_COMPONENT_TO_PACKAGING_OPTION_SUCCESS });
    } catch (err) {
        dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'error', message: 'Failed to add a component' } });
        dispatch({ type: actionTypes.ADD_COMPONENT_TO_PACKAGING_OPTION_FAIL, error: err });
    }
};

export const clearPackagingOptionComponentsData = () => dispatch => dispatch({ type: actionTypes.CLEAR_PACKAGING_OPTION_COMPONENTS_DATA });

export const loadPackagingOptionComponents = (id) => async dispatch => {
    if (id) {
        dispatch({ type: actionTypes.GET_PACKAGING_OPTION_COMPONENTS_START });

        try {
            const res = await axios.get(`/api/cms/packagings/${id}/components`, { headers: { Authorization: 'Bearer ' + getToken() } });
            dispatch({ type: actionTypes.GET_PACKAGING_OPTION_COMPONENTS_SUCCESS, components: res.data });
        } catch (err) {
            dispatch({ type: actionTypes.GET_PACKAGING_OPTION_COMPONENTS_FAIL, error: err });
        }
    }
};

export const deletePackagingOptionComponent = (id, componentId) => async dispatch => {

    dispatch({ type: actionTypes.DELETE_PACKAGING_OPTION_COMPONENT_START });

    try {
        await axios.delete(`/api/cms/packagings/${id}/components/${componentId}`, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.DELETE_PACKAGING_OPTION_COMPONENT_SUCCESS, id, componentId });
        dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'success', message: 'Packaging option deleted' } });
    } catch (err) {
        let message = '';

        if (err.response?.status === 404) {
            message = "Packaging option with the provided id doesn't exist";
        } else if (err.response?.status === 400) {
            message = err.response.data;
        } else {
            message = 'Something went wrong, please try later';
        }

        dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'error', message } });
        dispatch({ type: actionTypes.DELETE_PACKAGING_OPTION_IMAGE_FAIL, error: err });
    }
};