import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';

const Image = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 100px;
    height: 100px;
    cursor: pointer;
    padding: 10px;
    color: ${props => props.active ? 'white' : ''};
    background: ${props => props.active ? '#1d265b' : ''};

    & > div {
        width: 50px;
        height: 50px;
        font-size: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: ${props => props.border ? 'none' : '4px solid #BABDCC'};
        border-radius: 10px;
        & > img {max-height: 50px; max-width: 50px;}
    }

    & > span:first-of-type {
        font-size: 12px;
        font-weight: 500;
        & > svg {margin-right: 5px;}
    }

    & > span:last-of-type {
        font-size: 10px;
        opacity: .8;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 80px;
    }
`;

const ImageType = ({ images, setPreview, preview, id, icon, showTooltip, label }) => {
    const image = images.find(img => img.id === id);

    return (
      <Image
        border={image?.name}
        onClick={() => setPreview(id)}
        active={preview === id}
      >
        <div>
          {image ? (
            <img src={image.url} alt={image?.name || ""} />
          ) : (
            <FontAwesomeIcon icon={faImage} />
          )}
        </div>
        <span>
          {icon}
          {image?.name}
        </span>
        <span
          style={{ color: "#52912d", textDecoration: "underline" }}
          data-tip
          data-for="Tip"
          onClick={(e) => {
            showTooltip(image || { name: label });
          }}
        >
          Edit image
        </span>
      </Image>
    );
};

export default ImageType;
