import React, { useState } from 'react';
import Wrapper from "../../../components/Wrapper/Wrapper";
import Cavities from "./Cavity/Cavities";
import ImageDnD from "./ImageDnD";
import styled from "@emotion/styled";
import { filterCavitiesFunc } from '../../../constants/constants';

const Content = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: center;
`;

const KitTrayStage = (props) => {
    const {
      cavities,
      tray,
      setSelectedCavity,
      selectedCavity,
      newCavityData,
      setNewCavityData,
      cavityDelete,
      componentPlacement,
      setComponentPlacement,
      onTrayChange,
      filterCavities,
      clonedTray,
      selectedPage,
      pageBreakImages,
    } = props;

    const hasImage = tray?.data?.image || tray?.data?.imageUrl;
    const [imageLoading, setImageLoading] = useState(true);

    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Wrapper>
          <Content>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                position: "relative",
              }}
            >
              {/*<Tabs tabs={kitTrayTabs} />*/}
              {tray.action === "Edit Tray" && hasImage ? (
                <Cavities
                  cavities={
                    tray.data?.isVisible
                      ? cavities.filter((x) =>
                          filterCavitiesFunc(x, filterCavities)
                        )
                      : []
                  }
                  tray={tray.data}
                  setSelectedCavity={setSelectedCavity}
                  selectedCavity={selectedCavity}
                  newCavityData={newCavityData}
                  setNewCavityData={setNewCavityData}
                  deleteCavity={cavityDelete}
                  componentPlacement={componentPlacement}
                  setComponentPlacement={setComponentPlacement}
                  imageLoading={imageLoading}
                  setImageLoading={setImageLoading}
                  fps={60}
                  pageBreakImages={pageBreakImages}
                  selectedPageOnCanvas={selectedPage}
                  noAddNote
                />
              ) : (
                <ImageDnD
                  onTrayChange={onTrayChange}
                  tray={tray}
                  clonedTray={clonedTray}
                />
              )}
            </div>
          </Content>
        </Wrapper>
      </div>
    );
};

export default KitTrayStage;
