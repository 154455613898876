import { faBars, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MDBRow, MDBTable, MDBTableHead, MDBTableBody, MDBCol } from 'mdbreact';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FancyScroll from '../../../components/FancyScroll';
import styled from '@emotion/styled';
import { corsPatch } from '../../../constants/constants';
import Cavities from '../../Settings/KitTrays/Cavity/Cavities';
import _ from 'lodash';
import { generateTrayPDF } from '../Helpers/snapshotsToPDF';
import { showSnackbar } from '../../../redux/actions/snackbar';
import { useDispatch } from 'react-redux';

const ButtonsWrapper = styled.div`
    position: absolute;
    top: 0px;
    right: 20px;
    width: 150px;
    height: 50px;
    display: flex;
    z-index: 100;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    & > svg {
        display: flex;
        margin: 10px;
        flex: 0.25;
        cursor: pointer !important;
    }
    & > div {
        display: flex;
        cursor: pointer !important;
        flex: 2;
        font-weight: 600;
    }
`;

const GridWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 50px;
`;

const GridElement = styled.div`
    position: relative;
    background: #ECECEC;
    padding: 10px;
    width: 200px;
    margin: 20px;
    border: 1px solid #959595;
    color: white;
    font-weight: bold;
    font-size: 5px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    & img {
        max-height: 100px;
        max-width: 180px;
        object-fit: contain;
        padding-bottom: 10px;
    }
    & span {
        color: black;
        letter-spacing: 1px;
        font-size: 12px;
        & a {
            padding: 0px 2px;
            cursor: pointer;
        }
    }
`;

const hiddenStyle = {
    position: 'absolute', 
    left: 0, 
    opacity: 0, 
    zIndex: -99999,
    height: 1000,
    width: 1000
};

const ComponentsList = ({
  kit,
  kitDetails,
  addComponent,
  deleteComponent,
  deleteAllComponentsById,
  pdfGenerationState = false,
  wrapperRef,
  grid = false,
  objects,
  isSales,
}) => {
  const [components, setComponents] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  const [imageLoading, setImageLoading] = useState(true);
  const [canvasElemRef, setCanvasElemRef] = useState(null);

  const sortComponents = (components) => {
    let componentsSortable = components.map((x) => ({
      ...x,
      sortValue: x.labelName
        .replace(/([0-9][0-9]* [mMLl]*)/g, "")
        .replace(/([#][0-9]*)/g, "")
        .trim(),
    }));
    return _.sortBy(componentsSortable, ["sortValue"]).map((x) => ({
      ...x,
      sortValue: undefined,
    }));
  };

  useEffect(() => {
    if (kitDetails?.componentAssignments?.length > 0) {
      let nComp = [];
      for (let a of kitDetails?.componentAssignments) {
        const existIndex = nComp.findIndex((x) => x.id === a.component.id);
        if (existIndex !== -1) {
          nComp[existIndex].quantity++;
        } else {
          nComp.push({
            ...a.component,
            quantity: 1,
            imageUrl: a.imageUrl,
            cavityId: a.cavityId,
          });
        }
      }
      setComponents(nComp);
    } else {
      setComponents([]);
    }
  }, [kitDetails]);

  const getComponentDetails = (id) => {
    history.push({ pathname: "/components/" + id });
  };

  const decreaseComponentQuantity = (component) => {
    deleteComponent(component.id, null, true);
  };

  const deleteComponents = (component) => {
    deleteAllComponentsById(component.id, true);
  };

  const increaseComponentQuantity = (component) => {
    const cavity = kitDetails.tray.cavities.find(cav => cav.id === component.cavityId);
    if (cavity && !cavity.allowsMultiple) {
      return dispatch(showSnackbar("warning", "This cavity not allows multiple components"));
    }
    addComponent(component.id, true);
  };

  const dataComponents = (pdf) =>
    sortComponents(components).map((component, index) => {
      return (
        <tr align="left" key={"componentrow" + index}>
          <td
            align="center"
            className="cursorptr"
            onClick={() => getComponentDetails(component.id)}
          >
            <p>{component.quantity}</p>
          </td>
          {!pdf && (
            <td>
              {(!isSales || kit?.status !== 2) && (
                <div className="tbl-addon">
                  <div className="tbl-addon">
                    <a href="#no">
                      <i
                        className="fa fa-plus"
                        onClick={() => increaseComponentQuantity(component)}
                      ></i>
                    </a>
                    <a href="#no">
                      <i
                        className="fa fa-minus"
                        onClick={() => decreaseComponentQuantity(component)}
                      ></i>
                    </a>
                  </div>
                </div>
              )}
            </td>
          )}
          <td
            className="cursorptr"
            onClick={() => getComponentDetails(component.id)}
          >
            <span className="datalngth">{component.number}</span>
          </td>

          <td
            className="cursorptr"
            onClick={() => getComponentDetails(component.id)}
          >
            {component.labelName}
          </td>
          {!pdf && (
            <td>
              {(!isSales || kit?.status !== 2) && (
                <i
                  className="fa fa-window-close"
                  aria-hidden="true"
                  onClick={() => deleteComponents(component)}
                ></i>
              )}
            </td>
          )}
        </tr>
      );
    });

  const displayContent = { display: "block" };
  const hideContent = { display: "none" };

  const [isGrid, setIsGrid] = useState(grid);

  const [isPdfGeneration, setIsPdfGeneration] = useState(pdfGenerationState);

  const generatePDFCallback = () => {
    generateTrayPDF({
      setKitPdfGenerationProcess: setIsPdfGeneration,
      canvasElemRef,
      listWrapperRef: wrapperRef,
      kitId: kit.name,
      isSales,
      fileName: "_ComponentsList.pdf",
      offsetHeight: 400,
      offsetWidth: 200,
      imageOffsetX: 100,
      imageOffsetY: 100,
    });
  };

  return (
    <div style={pdfGenerationState ? hiddenStyle : undefined}>
      {kitDetails && (
        <div style={hiddenStyle}>
          <Cavities
            tray={kitDetails.tray}
            cavities={kitDetails.tray.cavities}
            selectedCavity={null}
            setSelectedCavity={() => {}}
            componentPlacement={kitDetails.componentAssignments
              ?.filter((x) => x.cavityId !== null)
              .map((x) => ({
                image: x.imageUrl,
                id: x.component?.id,
                values: { x: x.x, y: x.y, r: x.rotation },
                cavityId: x.cavityId,
                sku: x.component?.number,
              }))}
            objects={objects}
            ignoreComponentChanges={true}
            showCavities={false}
            setComponentPlacement={() => {}}
            imageLoading={imageLoading}
            setImageLoading={setImageLoading}
            setCanvasElemRef={setCanvasElemRef}
          />
        </div>
      )}
      <FancyScroll
        styles={{ color: "gray", backcolor: "#F9F9F9" }}
        offset={200}
      >
        <div ref={wrapperRef} style={{ position: "relative" }}>
          {components.length > 0 && !isPdfGeneration && (
            <ButtonsWrapper>
              <FontAwesomeIcon
                icon={faThLarge}
                style={{ color: isGrid ? "#61a431" : "#959595" }}
                onClick={() => setIsGrid(!isGrid)}
              />
              <FontAwesomeIcon
                icon={faBars}
                style={{ color: !isGrid ? "#61a431" : "#959595" }}
                onClick={() => setIsGrid(!isGrid)}
              />
              <div onClick={generatePDFCallback}>PDF</div>
            </ButtonsWrapper>
          )}
          {!isGrid ? (
            <MDBRow>
              <MDBCol md="12" id="onTray" className="onPrd">
                <MDBRow>
                  <MDBCol md="12" className="text-left">
                    <div
                      style={
                        components.length === 0 ? hideContent : displayContent
                      }
                    >
                      <MDBTable
                        small
                        striped
                        className="componenttbl"
                        cellSpacing="10"
                        style={{ paddingRight: 10 }}
                      >
                        <MDBTableHead>
                          <tr align="left">
                            <th>Quantity</th>
                            {!isPdfGeneration && <th></th>}
                            <th>SKU #</th>
                            <th>Component Name</th>
                            {!isPdfGeneration && <th></th>}
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                          {dataComponents(isPdfGeneration)}
                        </MDBTableBody>
                      </MDBTable>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          ) : (
            <GridWrapper>
              {sortComponents(components).map((component, index) => (
                <GridElement key={index}>
                  {component.imageUrl && (
                    <img alt="" src={corsPatch + component.imageUrl} />
                  )}
                  {(!isSales || kit?.status !== 2) && !isPdfGeneration && (
                    <i
                      className="fa fa-window-close"
                      aria-hidden="true"
                      onClick={() => deleteComponents(component)}
                      style={{
                        position: "absolute",
                        top: 5,
                        right: 5,
                        color: "#de231d",
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                    ></i>
                  )}
                  <span>
                    Quantity: {component.quantity}
                    {(!isSales || kit.status !== 2) && !isPdfGeneration && (
                      <>
                        <a href="#no">
                          <i
                            className="fa fa-plus"
                            onClick={() => increaseComponentQuantity(component)}
                          ></i>
                        </a>
                        <a href="#no">
                          <i
                            className="fa fa-minus"
                            onClick={() => decreaseComponentQuantity(component)}
                          ></i>
                        </a>
                      </>
                    )}
                  </span>
                  <span>Name: {component.labelName}</span>
                  <span>SKU: {component.number}</span>
                </GridElement>
              ))}
            </GridWrapper>
          )}
        </div>
      </FancyScroll>
    </div>
  );
};;

export default ComponentsList;