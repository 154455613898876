
// options
export const lumens = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 }
];

export const preloadedtechoptions = [
    { value: 'rhythm', label: 'Rhythm' },
    { value: 'vps', label: 'VPS' },
    { value: 'stylet', label: 'Stylet (No Technology)' },
    { value: undefined, label: 'None' }
];

export const pressureInjectableOptions = [
    { value: 'pressureInjectable', label: 'Pressure Injectable' },
    { value: 'nonPressureInjectable', label: 'Non Pressure Injectable' }
];

export const needleTypeOptions = [
    { value: 'tuohy', label: 'Tuohy' },
    { value: 'weiss', label: 'Weiss' },
    { value: 'huested', label: 'Huested' },
    { value: 'crawford', label: 'Crawford' }
];

export const spinalNeedleTypeOptions = [
    { value: 'sprotte', label: 'Sprotte' },
    { value: 'whitacre', label: 'Whitacre' },
    { value: 'gertie', label: 'Gertie' },
    { value: 'marx', label: 'Marx' }
];

export const frSizeOptions = [
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 4.5, label: 4.5 },
    { value: 5, label: 5 },
    { value: 5.5, label: 5.5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 8.5, label: 8.5 },
    { value: 9, label: 9 },
    { value: 12, label: 12 },
    { value: 14, label: 14 }
];

export const lengthOptions = [
    { value: 8, label: '8cm' },
    { value: 10, label: '10cm' },
    { value: 11.5, label: '11.5cm' },
    { value: 13, label: '13cm' },
    { value: 15, label: '15cm' },
    { value: 16, label: '16cm' },
    { value: 20, label: '20cm' },
    { value: 30, label: '30cm' },
    { value: 40, label: '40cm' },
    { value: 45, label: '45cm' },
    { value: 50, label: '50cm' },
    { value: 55, label: '55cm' },
    { value: 60, label: '60cm' }
];

export const catheterSizeOptions = [
    { value: '20GAx5CM', label: '20GA x 5CM' },
    { value: '22GAx5CM', label: '22GA x 5CM' },
    { value: '24GAx5CM', label: '24GA x 5CM' },
    { value: '18GAx8CM', label: '18GA x 8CM' },
    { value: '20GAx8CM', label: '20GA x 8CM' },
    { value: '22GAx8CM', label: '22GA x 8CM' },
    { value: '18GAx12CM', label: '18GA x 12CM' },
    { value: '20GAx12CM', label: '20GA x 12CM' },
    { value: '22GAx12CM', label: '22GA x 12CM' },
    { value: '18GAx16CM', label: '18GA x 16CM' },
    { value: '20GAx16CM', label: '20GA x 16CM' }
];

export const typeOptions = [
    { value: 'vascular', label: 'Vascular' },
    { value: 'anesthesia', label: 'Anesthesia' },
    { value: 'interventional', label: 'Interventional' }
];

export const geoButtons = [{ value: 'us', label: 'United States' }, { value: 'ous', label: 'Outside US' }];
export const accessoryOptions = [
    // { value: 'maxBarrierSizeDrape', label: 'Max Barrier Size Drape' },
    { value: 'chloraprep', label: 'Chloraprep' },
    { value: 'gown', label: 'Gown' },
    { value: 'capAndMask', label: 'Cap and Mask' },
    { value: 'transducerCover', label: 'Transducer Cover' }
];

export const procedureOptions = [
    { value: 'cvc', label: 'CVC' },
    { value: 'mac', label: 'MAC' },
    { value: 'midline', label: 'Midline' },
    { value: 'psi', label: 'PSI' },
    { value: 'dialysis', label: 'Dialysis' },
    { value: 'picc', label: 'PICC' },
    { value: 'endurance', label: 'Endurance' }
];

export const anesthesiaOptions = [
    { value: 'epidural', label: 'Epidural' },
    { value: 'combined', label: 'Combined Spinal Epidural' },
    { value: 'spinal', label: 'Spinal' },
    { value: 'singleShot', label: 'Single Shot' },
    { value: 'nerveBlock', label: 'Peripheral Nerve Block (PNB)' },
    { value: 'access', label: 'Access' }
];

export const pouchVsAdvancerTubeOptions = [{ value: 'pouch', label: 'Pouch' }, { value: 'advancerTube', label: 'Advancer - Tube' }];
export const multiPortVsSingleOpenEndHoleOptions = [{ value: 'multiPort', label: 'Multiport' }, { value: 'singleOpenEndHole', label: 'Single Open - End Hole' }];
export const nerveBlockOptions = [{ value: 'stimulating', label: 'Stimulating' }, { value: 'nonStimulating', label: 'Non - Stimulating' }];
export const lorOptions = [{ value: 'plastic', label: 'Plastic' }, { value: 'glass', label: 'Glass' }];
export const antisepticOptions = [{ value: 'povidoneLodine', label: 'Povidone Lodine' }, { value: 'duraprep', label: 'Duraprep' }];


// request keys
const filterKeys = [
  'vascular',
  'anesthesia',
  'interventional',
  'us',
  'ous',
  'coated',
  'nonCoated',
  'cvc',
  'picc',
  'midline',
  'dialysis',
  'mac',
  'psi',
  'endurance',
  'arterial',
  'Reference Size',
  'length',
  'lumens',
  'pressureInjectable',
  'nonPressureInjectable',
  'preLoadedTech',
  'seldinger',
  'integrated',
  'catheterSize',
  'meds',
  'prefilledSalineSyringe',
  'chloraprep',
  'gown',
  'capAndMask',
  'transducerCover',
  'epidural',
  'singleShot',
  'spinal',
  'combined',
  'access',
  'epiduralNeedleType',
  'spinalNeedleType',
  'stimulating',
  'nonStimulating',
  'providoneLodine',
  'duraprep',
  'plastic',
  'glass',
  'drape',
  'maxBarrierSizeDrape',
  'maxBarrier',
  'pouchVsAdvancerTube',
  'multiPortVsSingleOpenEndHole',
  'nerveBlock',
  'stimuQuick',
  'flexBlock',
];

// default values
export const centralAccessProps = {
  centralAccess: false,
  cvc: false,
  coated: false,
  nonCoated: false,
  pressureInjectable: false,
  nonPressureInjectable: false,
  'Reference Size': null,
  length: null,
  lumens: null,
  mac: false,
  psi: false,
  dialysis: false,
};

export const peripheralAccessProps = {
  peripheralAccess: false,
  picc: false,
  preLoadedTech: null,
  midline: false,
  endurance: false,
};

export const arterialProps = {
  arterialType: false,
  arterial: false,
  seldinger: false,
  integrated: false,
  sac: false,
  catheterSize: null,
};

export const anesthesialProps = {
  epidural: false,
  singleShot: false,
  pouchVsAdvancerTube: null,
  multiPortVsSingleOpenEndHole: null,
  epiduralNeedleType: null,
  spinalNeedleType: null,
  spinal: false,
  combined: false,
  nerveBlock: false,
  stimulating: false,
  flexBlock: false,
  stimuQuick: false,
  nonStimulating: false,
  access: false,
};

export const accessTrayProps = {
  accessTray: 'access',
  meds: false,
  prefilledSalineSyringe: false,
  chloraprep: false,
  gown: false,
  capAndMask: false,
  transducerCover: false,
  povidoneLodine: false,
  duraprep: false,
  lorSyringe: false,
  drape: false,
  maxBarrier: false,
};

// table data (Kit tagging)
export const tagsForDisplay = {
  vascular: 'Vascular',
  anesthesia: 'Anesthesia',
  us: 'US',
  ous: 'OUS',
  cvc: 'CVC',
  picc: 'PICC',
  midline: 'Midline',
  endurance: 'Endurance',
  arterial: 'Arterial',
};

const multiTagKeys = [
  'length',
  'Reference Size',
  'lumens',
  'catheterSize',
  'epiduralNeedleType',
  'spinalNeedleType',
];

// mapping functions
export const getTags = (tags) => {
  return Object.keys(tags)
    .filter((f) => filterKeys.some((key) => f === key) && tags[f])
    .map((key) =>
      Array.isArray(tags[key])
        ? tags[key].map((tag) => ({ key, value: tag }))
        : { key, value: tags[key] }
    )
    .flat();
};

export const mapFiltersToString = (filters) => {
  const applied = getAppliedFilters(filters);

  return Object.keys(applied)
    .map((key) => {
      if (Array.isArray(applied[key])) {
        return applied[key].map((item) => `${[key]}:${item}`);
      } else {
        return `${[key]}:${applied[key]}`;
      }
    })
    .flat()
    .join(",");
};

export const getAppliedFilters = (filters) => {
  return Object.keys(filters)
    .filter((f) => filterKeys.some((key) => f === key))
    .reduce((acc, key) => {
      if (filters[key]) {
        acc[key] = Array.isArray(filters[key])
          ? filters[key].map((filter) => filter.value)
          : filters[key];
      }
      return acc;
    }, {});
};

export const getComponentsIdList = (components) =>
  components
    .filter((component) => component.checked)
    .map((component) => component.id)
    .join(",");

export const mapTagsToKit = (tags) => {
  const result = {};

  tags.forEach(({ key, value }) => {
    if (multiTagKeys.some((mtk) => mtk === key)) {
      if (result[key]) {
        result[key].push(value);
      } else {
        result[key] = [value];
      }
    } else {
      result[key] = value;
    }
  });

  return result;
};
