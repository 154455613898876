import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Logs from './Logs';
import { useDispatch, useSelector } from 'react-redux';
import { loadLogs } from '../../../redux/actions/logs';
import { isValidDate } from '../../../utils/dates';

// memoizated selector
const getMemoizatedLogsSelector = (state) => state.logs;

const date = new Date();
const year = date.getUTCFullYear();
const month = date.getUTCMonth() + 1;
const day = date.getUTCDate();
const dateString = `${year}-${month < 10 ? '0' + month : month}-${day}`;

export default function LogContainer() {
  const [page, setPage] = useState(0);
  const [logLevels, setLogLevels] = useState([1]);
  const [dates, setDates] = useState({
    start: '2020-01-01',
    end: dateString,
  });
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();
  const { logs, totalPages, loading } = useSelector(getMemoizatedLogsSelector);

  useEffect(() => {
    dispatch(loadLogs({ page, logLevels, dates, search }));
  }, [dispatch, page, logLevels, dates, search]);

  const highlightedLogs = useMemo(() => {
    return logs.map((log) => ({
      ...log,
      highlighted:
        search && (log.message || '').toString().toLowerCase().includes(search),
    }));
  }, [logs, search]);

  const formatDate = useCallback((timestamp) => {
    if (!isValidDate(timestamp)) {
      return timestamp;
    }

    const date = new Date(timestamp);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(date);
  }, []);

  return (
    <div className="main-layout">
      <div
        className="content-area"
        style={{ paddingBottom: 0, paddingLeft: 0 }}
      >
        <div
          style={{
            height: 'calc(100vh - 65px)',
          }}
        >
          <Logs
            page={page}
            setPage={setPage}
            logs={highlightedLogs}
            totalPages={totalPages}
            loading={loading}
            search={search}
            setSearch={setSearch}
            formatDate={formatDate}
            logLevels={logLevels}
            setLogLevels={setLogLevels}
            dates={dates}
            setDates={setDates}
          />
        </div>
      </div>
    </div>
  );
}
