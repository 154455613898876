import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from '../../../components/loaders/Loader';
import {
  createKitTray,
  loadKitTrays,
  updateKitTray,
  deleteTrayCavity,
  loadMainDeviceList,
  updateTrayVisibility,
  loadTrayCavities,
  createTrayFromCloned,
} from '../../../redux/actions/kitTrays';
import Confirmation from '../../../components/Confirmation/Confirmation';
import TrayActiveMenu from './TrayActiveMenu';
import KitTrayStage from './KitTrayStage';
import LoadingOverlay from 'react-loading-overlay';
import { hideReminder, showReminder } from '../../../redux/actions/snackbar';
import { overlayStyle } from '../../../constants/constants';
import usePrevious from '../../../hooks/usePrevious';
import { listPageBreakImages } from '../../../redux/actions/kits';

export const defaultPlacement = [
  { image: null, values: { x: 0, y: 0, r: 0 }, priority: 0, cavity: null },
];

const KitTrays = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    trays,
    loading,
    cavities,
    createdTray,
    mainDevices,
    createdCavity,
    actionLoading,
  } = useSelector((state) => state.kitTrays);
  const { reminder } = useSelector((state) => state.snackbar);
  const { pageBreakImages, pageBreakImagesLoading } = useSelector(
    (state) => state.kits,
  );

  const [cavityDeleteId, setCavityDeleteId] = useState(null);
  const [unsavedData, setUnsavedData] = useState(false);
  const [showDeletedTrays, setShowDeletedTrays] = useState(false);
  const [componentPlacement, setComponentPlacement] =
    useState(defaultPlacement);
  const [tray, setTray] = useState({ data: {}, action: '' });
  const [selectedCavity, setSelectedCavityValue] = useState(null);
  const [newCavityData, setNewCavityData] = useState(null);
  const [filterCavities, setFilterCavities] = useState({});
  const [clonedTray, setClonedTray] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null);

  const resetSelectedCavity = useCallback(() => {
    if (history.location.pathname === '/kit-trays' && selectedCavity) {
      setSelectedCavityValue(null);
    }
  }, [history.location.pathname, selectedCavity]);

  useEffect(() => {
    resetSelectedCavity();
  }, [resetSelectedCavity]);

  useEffect(() => {
    dispatch(listPageBreakImages());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadKitTrays());
    dispatch(loadMainDeviceList());
    return () => {
      dispatch(hideReminder());
    };
  }, [dispatch]);

  useEffect(() => {
    if (tray?.data?.id) {
      setTray(trays.find((x) => x.id === tray.data.id));
    }
    // eslint-disable-next-line
  }, [trays]);

  useEffect(() => {
    if (createdTray) {
      setTray({ ...tray, data: createdTray, action: 'Edit Tray' });
      history.push({
        pathname: `/kit-trays/edit/${createdTray.id}`,
        state: { tray: { data: createdTray, action: 'Edit Tray' } },
      });
    }
    // eslint-disable-next-line
  }, [createdTray]);

  useEffect(() => {
    if (createdCavity) {
      setSelectedCavityValue(createdCavity);
      history.push({
        pathname: `/kit-trays/edit/${tray.data.id}/cavity/overview`,
        state: { tray, cavity: createdCavity },
      });
    }
    // eslint-disable-next-line
  }, [createdCavity]);

  const prevId = usePrevious(tray?.data?.id);
  useEffect(() => {
    if (tray?.data?.id && tray?.data?.id !== prevId) {
      dispatch(loadTrayCavities(tray.data.id));
    }
  }, [tray, dispatch, prevId]);

  useEffect(() => {
    if (history.location.pathname === '/kit-trays') {
      setTray({ data: {}, action: '' });
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (history.location.pathname.includes('/kit-trays/edit/')) {
      let id = history.location.pathname.split('/kit-trays/edit/');
      if (id.length > 0) {
        setTray({
          data: trays.find((x) => x.id === Number.parseInt(id[1])),
          action: 'Edit Tray',
        });
      }
    }
    // eslint-disable-next-line
  }, [trays]);

  if (loading) return <Loader />;

  const setSelectedCavity = (cavity, updateHistory = true) => {
    if (cavity) {
      if (!reminder && selectedCavity?.id === cavity.id) {
        dispatch(showReminder());
      }
      setSelectedCavityValue(cavity);
      if (updateHistory)
        history.push({
          pathname: `/kit-trays/edit/${tray.data.id}/cavity/overview`,
          state: { tray, cavity },
        });
    } else {
      setSelectedCavityValue(null);
      history.goBack();
      dispatch(hideReminder());
    }
  };

  const setVirtualCavity = (cavity) => setSelectedCavityValue(cavity);

  const onTrayChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setTray({
        ...tray,
        data: {
          ...tray.data,
          image: URL.createObjectURL(e.target.files[0]),
          file: e.target.files[0],
        },
        action: !tray.data && !tray.action ? 'Create Tray' : tray.action,
      });
    } else {
      setTray({
        ...tray,
        data: { ...tray.data, [e.target.name]: e.target.value },
        action: !tray.data && !tray.action ? 'Create Tray' : tray.action,
      });
    }

    if (!reminder) {
      dispatch(showReminder());
    }
  };

  const confirmDelete = () => {
    dispatch(updateTrayVisibility(tray.data.id, false));
    setTray({ data: null, action: '' });
  };

  const restoreTray = (tray) => dispatch(updateTrayVisibility(tray.id, true));

  const confirmCavityDelete = () => {
    dispatch(deleteTrayCavity(tray.data.id, cavityDeleteId));
    setCavityDeleteId(null);
    setSelectedCavity(null);
  };

  const handleTrayClick = (tray) => {
    const params = { data: tray, action: tray ? 'Edit Tray' : 'Create Tray' };
    tray &&
      history.push({
        pathname: `/kit-trays/edit/${tray.id}`,
        state: { tray: params },
      });
    setTray(params);
  };

  const mainDeviceChange = (option) => {
    setTray({ ...tray, data: { ...tray.data, mainDevices: option } });
    if (!reminder) {
      dispatch(showReminder());
    }
  };

  const cavityDelete = (cavity) => setCavityDeleteId(cavity.id);

  const confirmBack = () => {
    setUnsavedData(false);
    if (selectedCavity) {
      setSelectedCavity(null);
    } else {
      setNewCavityData(null);
      history.goBack();
    }
  };

  const saveTrayChanges = (t) => {
    if (reminder) {
      dispatch(hideReminder());
    }

    if (clonedTray) {
      return dispatch(createTrayFromCloned(clonedTray.id, tray.data.number));
    }

    if (tray.action === 'Create Tray') {
      dispatch(createKitTray(t.data));
    } else {
      dispatch(updateKitTray(t.data));
    }
  };

  return (
    <div className="main-layout">
      <div
        className="content-area"
        style={{ paddingBottom: 0, paddingLeft: 0 }}
      >
        <LoadingOverlay active={actionLoading} spinner styles={overlayStyle}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              height: 'calc(100vh - 65px)',
              overflowY: 'hidden',
            }}
          >
            <TrayActiveMenu
              saveTrayChanges={saveTrayChanges}
              deleteTray={(tray) => setTray({ data: tray, action: 'Delete' })}
              onTrayClick={(tray) => handleTrayClick(tray)}
              onTrayChange={onTrayChange}
              showNavigation={!(history.location.pathname === '/kit-trays')}
              trays={trays}
              tray={tray}
              setTray={setTray}
              cavities={cavities}
              mainDevices={mainDevices}
              mainDeviceChange={mainDeviceChange}
              onCavityClick={setSelectedCavity}
              selectedCavity={selectedCavity}
              setNewCavityData={setNewCavityData}
              newCavityData={newCavityData}
              componentPlacement={componentPlacement}
              setComponentPlacement={setComponentPlacement}
              setVirtualCavity={setVirtualCavity}
              setUnsavedData={setUnsavedData}
              showDeletedTrays={showDeletedTrays}
              setShowDeletedTrays={setShowDeletedTrays}
              restoreTray={restoreTray}
              filterCavities={filterCavities}
              setFilterCavities={setFilterCavities}
              clonedTray={clonedTray}
              setClonedTray={setClonedTray}
              pageBreakImages={pageBreakImages}
              pageBreakImagesLoading={pageBreakImagesLoading}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />

            <KitTrayStage
              cavities={cavities}
              filterCavities={filterCavities}
              tray={tray}
              setSelectedCavity={setSelectedCavity}
              selectedCavity={selectedCavity}
              newCavityData={newCavityData}
              setNewCavityData={setNewCavityData}
              cavityDelete={cavityDelete}
              componentPlacement={componentPlacement}
              setComponentPlacement={setComponentPlacement}
              onTrayChange={onTrayChange}
              clonedTray={clonedTray}
              pageBreakImages={pageBreakImages}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
          </div>
          {unsavedData && (
            <Confirmation
              confirm={confirmBack}
              text="Are you sure you want to go back? There are unsaved changes"
              close={() => setUnsavedData(false)}
            />
          )}
          {cavityDeleteId && (
            <Confirmation
              confirm={confirmCavityDelete}
              text="Are you sure you want to delete cavity?"
              close={() => setCavityDeleteId(null)}
            />
          )}
          {tray.action === 'Delete' && (
            <Confirmation
              confirm={confirmDelete}
              text="Are you sure you want to delete tray?"
              close={() => setTray({ ...tray, action: '' })}
            />
          )}
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default KitTrays;
