import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Container = styled.div`
    display: flex;
    align-items: center;
    font-size: ${props => sizes[props.size].font}px;

    & > span {
        margin-right: 10px;
        pointer-events: ${props => props.disabled ? 'none' : 'auto'};
        opacity: ${props => props.disabled ? .4 : 1};
    }
`;

const Label = styled.label(({ size, background, uncheckedColor, checkedColor, disabled }) => `
    position: relative;
    display: inline-block;
    width: ${sizes[size].width}px;
    height: ${sizes[size].height}px;
    margin-right: 10px;
    margin-bottom: 0px !important;
    pointer-events: ${disabled ? 'none' : 'auto'};
    opacity: ${disabled ? .4 : 1};

    & > input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    & > span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;
        border: 1px solid #CCC;
        background: ${background};
    }

    & > span:before {
        position: absolute;
        content: "";
        top: ${sizes[size].top}px;
        bottom: 1px;
        height: ${sizes[size].circle}px;
        width: ${sizes[size].circle}px;
        left: 1px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
        background-color: ${uncheckedColor || '#CC2222'};
    }

    & > input:checked + span:before {
        background-color: ${checkedColor || '#61A431'};
    }

    & > input:checked + span:before {
        -webkit-transform: translateX(${sizes[size].transform}px);
        -ms-transform: translateX(${sizes[size].transform}px);
        transform: translateX(${sizes[size].transform}px);
    }
`);

const sizes = {
  small: { width: 40, height: 19, transform: 19, circle: 15, top: 1, font: 12 },
  medium: {
    width: 80,
    height: 38,
    transform: 38,
    circle: 32,
    top: 3.5,
    font: 22,
  },
  large: {
    width: 120,
    height: 57,
    transform: 57,
    circle: 48,
    top: 4,
    font: 36,
  },
};

const Switcher = (props) => {
  const {
    onChange,
    leftLabel,
    rightLabel,
    checked,
    size,
    background,
    uncheckedColor,
    checkedColor,
    disabled,
    ...rest
  } = props;

  return (
    <Container size={size} disabled={disabled}>
      {leftLabel && <span>{leftLabel}</span>}
      <Label
        size={size}
        background={background}
        uncheckedColor={uncheckedColor}
        checkedColor={checkedColor}
        disabled={disabled}
      >
        <input
          type="checkbox"
          onChange={onChange}
          checked={checked}
          value={!!checked}
          disabled={disabled || false}
          {...rest}
        />
        <span />
      </Label>
      {rightLabel && <span>{rightLabel}</span>}
    </Container>
  );
};

Switcher.propTypes = {
  checked: PropTypes.bool,
  leftLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  rightLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  size: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  background: PropTypes.string,
  uncheckedColor: PropTypes.string,
  checkedColor: PropTypes.string,
};

Switcher.defaultProps = {
  size: "small",
  leftLabel: null,
  rightLabel: null,
  checked: false,
};

export default Switcher;