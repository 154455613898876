import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import ButtonGroup from "../../../../components/Buttons/ButtonGroup";
import CustomSelect from "../../../../components/Selects/CustomSelect";
import usePrevious from "../../../../hooks/usePrevious";
import { isEqual } from "lodash-es";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { saveComponentsRange } from "../../../../redux/actions/kits";

const SwitchTitle = styled.div`
  text-align: center;
  font-size: 14px;
  padding-bottom: 10px;
`;

const SwitchWrapper = styled.div`
  margin: 0px 10px 0px 10px;
  button {
    font-size: 12px;
  }
`;

const buttonsMode = [
  { label: "Show all", value: 0 },
  { label: "Cavity range", value: 1 },
];

export const selectStyles = {
  container: (provided) => ({ ...provided, minWidth: 140, color: "black" }),
  control: (provided, state) => ({
    ...provided,
    border: "none",
    height: 30,
    minHeight: 30,
    boxShadow: "none",
    borderBottom: `2px solid ${state.menuIsOpen ? "#4285f4" : "black"}`,
    borderRadius: 10,
    paddingLeft: 20,
    margin: 10,
    paddingTop: 0,
    outline: "none",
    backgroundColor: "white",
  }),
  valueContainer: (provided) => ({
    ...provided,
    lineHeight: "15px",
    padding: "0",
  }),
  placeholder: (provided) => ({
    ...provided,
    marginTop: -5,
    textAlign: "center",
  }),
  singleValue: (provided) => ({
    ...provided,
    marginTop: -5,
    lineHeight: "20px",
    textAlign: "center",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "30px",
  }),
};

const ModeSwitcher = ({
  defaultValue = 0,
  title,
  onChange,
  options,
  filterCavities,
  setFilterCavities,
  setExcludeFloating,
  excludeFloating,
  limit = undefined,
  pages,
  onPageClick,
}) => {
  if (pages?.length && !buttonsMode.find((b) => b.value === 2)) {
    buttonsMode.push({ label: "Page range", value: 2 });
  }

  const { componentsRange } = useSelector((state) => state.kits, shallowEqual);
  const [mode, setMode] = useState(defaultValue);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setFilterCavities({ from: undefined, to: undefined });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (componentsRange?.mode) {
      setMode(componentsRange.mode);
    }
  }, [componentsRange]);
  const prevOptions = usePrevious(options);

  useEffect(() => {
    if (componentsRange) {
      mode !== 0 && setFilterCavities(componentsRange);
      setExcludeFloating && setExcludeFloating(componentsRange.excludeFloating);
    }
    // eslint-disable-next-line
  }, [componentsRange]);

  useEffect(() => {
    if (componentsRange?.from) {
      return;
    }

    if (mode === 1) {
      if (!isEqual(prevOptions, options) && Array.isArray(options) && limit) {
        if (options.length <= limit) {
          setFilterCavities({
            from: options[0]?.value || options[0]?.id,
            to:
              options[options.length - 1]?.value ||
              options[options.length - 1]?.id,
            fromCavityId: options[0]?.cavityId,
            toCavityId: options[options.length - 1]?.cavityId,
          });
        } else {
          setFilterCavities({
            from: options[options.length - 1 - limit]?.value,
            to: options[options.length - 1]?.value,
            fromCavityId: options[options.length - 1 - limit]?.cavityId,
            toCavityId: options[options.length - 1]?.cavityId,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [mode, options, prevOptions]);

  useEffect(() => {
    if (mode === 0 && filterCavities?.from) {
      setFilterCavities({ ...filterCavities, from: undefined, to: undefined });
    }
    // eslint-disable-next-line
  }, [mode]);

  useEffect(() => {
    if (filterCavities?.from) {
      dispatch(saveComponentsRange({ ...filterCavities, excludeFloating }));
    } else if (componentsRange && mode === 1) {
      setFilterCavities(componentsRange);
      setExcludeFloating && setExcludeFloating(componentsRange.excludeFloating);
    }
    // eslint-disable-next-line
  }, [filterCavities?.from, dispatch, mode, excludeFloating]);

  const selectMode = useCallback(
    (value) => {
      if (mode !== value) {
        onChange && onChange(value);
        setMode(value);
      }
      dispatch(saveComponentsRange({ ...filterCavities, mode: value }));
    },
    [dispatch, filterCavities, mode, onChange]
  );

  return (
    <>
      {title && <SwitchTitle>{title}</SwitchTitle>}
      <SwitchWrapper>
        <ButtonGroup
          buttons={buttonsMode}
          isActive={buttonsMode.find((x) => x.value === mode)?.value}
          onClick={(button) => selectMode(button.value)}
          borderRadius={0}
          activeWhite={true}
          borderColor={"white"}
        />
      </SwitchWrapper>
      {mode === 1 && (
        <>
          <CustomSelect
            customStyles={selectStyles}
            placeholder="From"
            isSearchable={false}
            options={options}
            value={options?.find(
              (x) =>
                (x.id || x.value) === filterCavities?.from &&
                x.cavityId === filterCavities?.fromCavityId
            )}
            onChange={(elem) => {
              setFilterCavities({
                ...filterCavities,
                from: elem.value,
                fromCavityId: elem.cavityId,
              });
              dispatch(
                saveComponentsRange({
                  ...filterCavities,
                  from: elem.value,
                  fromCavityId: elem.cavityId,
                  excludeFloating,
                })
              );
            }}
          />
          <CustomSelect
            customStyles={selectStyles}
            placeholder="To"
            isSearchable={false}
            options={options}
            value={options?.find(
              (x) =>
                (x.id || x.value) === filterCavities?.to &&
                x.cavityId === filterCavities?.toCavityId
            )}
            onChange={(elem) => {
              setFilterCavities({
                ...filterCavities,
                to: elem.value,
                toCavityId: elem.cavityId,
              });
              dispatch(
                saveComponentsRange({
                  ...filterCavities,
                  to: elem.value,
                  toCavityId: elem.cavityId,
                  excludeFloating,
                })
              );
            }}
          />
        </>
      )}

      {mode === 2 && (
        <>
          <CustomSelect
            customStyles={selectStyles}
            placeholder="From"
            isSearchable={false}
            options={pages}
            onChange={(elem) => onPageClick(elem, "from")}
          />
          <CustomSelect
            customStyles={selectStyles}
            placeholder="To"
            isSearchable={false}
            options={pages}
            onChange={(elem) => onPageClick(elem, "to")}
          />
        </>
      )}
    </>
  );
};

export default ModeSwitcher;
