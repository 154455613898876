import styled from '@emotion/styled';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { kitTrayRoutes } from '../../../constants/constants';
import CavityIcon from '../../../shared/CavityIcon';
import TrayIcon from '../../../shared/TrayIcon';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 15px 10px;
    font-weight: 500;
`;

const Back = styled.div`
    align-items: center;
    display: flex;
    cursor: pointer;
`;

const TrayNavigation = ({ onCavityClick, selectedCavity, setNewCavityData, showDropdown, setShowDropDown, customGoBack, setUnsavedData }) => {
    const history = useHistory();
    const [title, setTitle] = useState('');

    const { reminder } = useSelector(state => state.snackbar);

    useEffect(() => {
        const path = history.location.pathname;

        if (path.includes('/cavity-create')) {
            setTitle('Cavity');
            return;
        }

        setTitle(kitTrayRoutes.find(t => path.includes(t.path))?.label);
    }, [history.location.pathname]);

    const onClick = () => {
        if (showDropdown) {
          return setShowDropDown(null);
        }

        if (
          reminder &&
          (history.location.pathname.includes("cavity-create") ||
            history.location.pathname.includes("cavity/overview"))
        ) {
          return setUnsavedData(true);
        }

        if (customGoBack) {
          return customGoBack();
        }

        if (history.location.pathname.includes("cavity-create")) {
          setNewCavityData(null);
        }

        if (selectedCavity) {
          onCavityClick(null);
        } else {
          history.goBack();
        }
    };

    return (
        <Container>
            <Back onClick={onClick}>
                <FontAwesomeIcon icon={faAngleLeft} />
                <span style={{ marginLeft: 5 }}>Back</span>
            </Back>
            {title && <div style={{ display: 'flex', alignItems: 'center' }}>
                {history.location.pathname.includes('cavity') ? <CavityIcon color='white' size='14px' styles={{ marginRight: 10 }} /> : <TrayIcon color='white' size='14px' styles={{ marginRight: 10 }} />}
                {title}
            </div>}
        </Container>
    );
};

export default TrayNavigation;
