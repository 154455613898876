import { cloneDeep } from 'lodash-es';

export const getPageId = (list, currentComponent) => {
  return list.find(
    (x) =>
      x.componentId === currentComponent.component.id &&
      x.cavityId === currentComponent.cavityId,
  )?.pageId;
};

export const overrideNotePosition = (cavity, component) => {
  const position = cloneDeep(cavity.points)[1] || {};

  if (cavity.isFloating) {
    position.x = (component.x < 0 ? -component.x : component.x) + 60 || 100;
    position.y = (component.y < 0 ? -component.y : component.y) + 60 || 100;
  }
  return position;
};

export const getCorrectSku = (
  isOverridden,
  text,
  components,
  currentComponent,
) => {
  const hasQuantity = text?.match(/\((.*?)\)/);

  if (isOverridden) {
    return text;
  }

  const length = components.filter(
    (c) =>
      c.component.id === currentComponent.component.id &&
      c.cavityId === currentComponent.cavityId,
  ).length;

  if (hasQuantity) {
    return text.replace(hasQuantity[0], length > 1 ? '(' + length + ')' : '');
  }

  return (
    currentComponent.component?.number + (length > 1 ? ' (' + length + ')' : '')
  );
};

export function setLowLevelComponents(components, objects, current) {
  const componentsOnSamePageLower = objects.filter(
    (o) => o.pageId >= current.elem.pageId && o.id > current.elem.id,
  );

  return components
    .filter((item) =>
      componentsOnSamePageLower.find(
        (o) =>
          o.componentId === item.component.id && o.cavityId === item.cavityId,
      ),
    )
    .map(({ cavityId, component, imageUrl, x, y, rotation: r }) => ({
      cavityId: cavityId,
      id: component.id,
      image: imageUrl,
      sku: component.number,
      values: { x, y, r },
    }));
}

export function pdfGeneragtionValidation(
  version,
  components,
  dispatch,
  showSnackbar,
  smallScreen,
) {
  if (smallScreen) {
    dispatch(
      showSnackbar(
        'warning',
        'PDF export requires a minimum screen resolution of 1280 x 720.',
      ),
    );
  }

  if (!version) {
    dispatch(showSnackbar('warning', 'Please add Graphic Revision Number'));
  }

  const unshown = components.some((component) => !component.cavityId);
  if (unshown) {
    dispatch(showSnackbar('warning', 'Please place Unshown components'));
  }

  return unshown || !version || smallScreen;
}
