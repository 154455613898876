import React from 'react';
import styled from '@emotion/styled';
import CustomSelect from '../../../../components/Selects/CustomSelect';
import { typeOptions, accessoryOptions, geoButtons, centralAccessProps, peripheralAccessProps, arterialProps, anesthesialProps } from '../../../NewKitCatalog/Filters/utils';
import ButtonGroup from '../../../../components/Buttons/ButtonGroup';
import AccessoryOptionTypes from './AccessoryOptionTypes';
import VascularInterventionalTypes from './VascularInterventionalTypes';
import AnesthesiaOptionTypes from './AnesthesiaOptionTypes';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Title = styled.div`
    font-weight: 500;
    padding: 10px;
`;

const ItemBlock = styled.div`
    width: ${props => props.width};
    background: ${props => props.background};
    padding: 10px 20px;
    button {
        font-size: 12px;
    }
`;

const TagsContainer = ({ kit, setKit }) => {
    if (!kit) {
        return <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center', fontSize: 18, opacity: .5, height: '100%' }}>
            No active Kit selected
        </div>;
    }

    const handleTypeSelect = (option) => {
        switch(option.value) {
            case 'vascular':
                setKit({ ...kit, ...centralAccessProps, ...peripheralAccessProps, ...arterialProps, ...anesthesialProps, vascular: true, anesthesia: false });
                break;
            case 'anesthesia':
                const accessories = { meds: false, prefilledSalineSyringe: false };
                accessoryOptions.forEach(o => accessories[o.value] = false);
                setKit({ ...kit, ...centralAccessProps, ...peripheralAccessProps, ...arterialProps, ...anesthesialProps, ...accessories, anesthesia: true, vascular: false });
                break;
            default:
                setKit({ ...kit, ...centralAccessProps, ...peripheralAccessProps, ...arterialProps, ...anesthesialProps, vascular: false, anesthesia: false, interventional: true });
                break;
        }
    };

    const handleGeoChange = (geo, isSet = true) => {
        if (!isSet) {
            setKit({ ...kit, us: false, ous: false });
            return;
        }
        if (geo.value === 'us') {
            setKit({ ...kit, us: true, ous: false });
        } else {
            const accessories = { meds: false, prefilledSalineSyringe: false };
            setKit({ ...kit, us: false, ous: true, ...accessories });
        }
    };

    return (
        <Container>
            <Title style={{ padding: '20px 20px 0px 20px', display: 'flex', justifyContent: 'space-between' }}>
                <span>{kit.number}</span>
                <span>{kit.componentsCount} Components</span>
            </Title>
            <ItemBlock width='70%'>
                <Title>TYPE</Title>
                <CustomSelect applyStyles isSearchable={false} isClearable={false} options={typeOptions} onChange={(o) => handleTypeSelect(o)} value={typeOptions.find(o => kit[o.value]) || null} />
            </ItemBlock>
            <ItemBlock width='100%' style={{ pointerEvents: (!kit.vascular && !kit.anesthesia) ? 'none' : 'auto', opacity: (!kit.vascular && !kit.anesthesia) ? .4 : 1 }}>
                <Title>GEO</Title>
                <ButtonGroup buttons={geoButtons} showNone isActive={kit.us ? 'us' : kit.ous ? 'ous' : ''} onClick={handleGeoChange} />
            </ItemBlock>
            <ItemBlock width='100%' background='#e8e9ee'>
                <VascularInterventionalTypes kit={kit} setKit={setKit} />
            </ItemBlock>
            <br />
            <ItemBlock width='100%' background='#e8e9ee'>
                <AnesthesiaOptionTypes kit={kit} setKit={setKit} />
            </ItemBlock>
            <br/>
            <ItemBlock width='100%' background='#e8e9ee'>
                <Title style={{ textAlign: 'center' }}>Accessory Options</Title>
                <AccessoryOptionTypes kit={kit} setKit={setKit} />
            </ItemBlock>
        </Container>
    );
};

export default TagsContainer;
