export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';
export const ARTICLE_SUBMITTED = 'ARTICLE_SUBMITTED';
export const SETTINGS_SAVED = 'SETTINGS_SAVED';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const SETTINGS_PAGE_UNLOADED = 'SETTINGS_PAGE_UNLOADED';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const ARTICLE_PAGE_LOADED = 'ARTICLE_PAGE_LOADED';
export const ARTICLE_PAGE_UNLOADED = 'ARTICLE_PAGE_UNLOADED';
export const ADD_COMMENT = 'ADD_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const ARTICLE_FAVORITED = 'ARTICLE_FAVORITED';
export const ARTICLE_UNFAVORITED = 'ARTICLE_UNFAVORITED';
export const SET_PAGE = 'SET_PAGE';
export const APPLY_TAG_FILTER = 'APPLY_TAG_FILTER';
export const CHANGE_TAB = 'CHANGE_TAB';
export const PROFILE_PAGE_LOADED = 'PROFILE_PAGE_LOADED';
export const PROFILE_PAGE_UNLOADED = 'PROFILE_PAGE_UNLOADED';
export const LOGIN = 'LOGIN';
export const LOGIN_STARTED = 'LOGIN_STARTED';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const EDITOR_PAGE_LOADED = 'EDITOR_PAGE_LOADED';
export const EDITOR_PAGE_UNLOADED = 'EDITOR_PAGE_UNLOADED';
export const ADD_TAG = 'ADD_TAG';
export const REMOVE_TAG = 'REMOVE_TAG';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const UPDATE_FIELD_EDITOR = 'UPDATE_FIELD_EDITOR';
export const FOLLOW_USER = 'FOLLOW_USER';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const PROFILE_FAVORITES_PAGE_UNLOADED = 'PROFILE_FAVORITES_PAGE_UNLOADED';
export const PROFILE_FAVORITES_PAGE_LOADED = 'PROFILE_FAVORITES_PAGE_LOADED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const CATEGORIES = 'CATEGORIES';
export const KITCATALOG = 'KITCATALOG';
export const KITCATALOG_PAGE_UNLOADED = 'KITCATALOG_PAGE_UNLOADED';
export const COMPONENTDETAIL = 'COMPONENTDETAIL';
export const KITFORMDATA = 'KITFORMDATA';
export const KITCATEGORIES = 'KITCATEGORIES';
export const KITCATALOGADDCOMPONENTS = 'KITCATALOGADDCOMPONENTS';
export const PARTTYPE = 'PARTTYPE';
export const POPULARCATEGORIES = 'POPULARCATEGORIES';
export const POPULARCATEGORIESKIT = 'POPULARCATEGORIESKIT';

export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const GET_PRODUCT_FAMILIES_START = 'GET_PRODUCT_FAMILIES_START';
export const GET_PRODUCT_FAMILIES_SUCCESS = 'GET_PRODUCT_FAMILIES_SUCCESS';
export const GET_PRODUCT_FAMILIES_FAIL = 'GET_PRODUCT_FAMILIES_FAIL';

export const GET_ROLES_START = 'GET_ROLES_START';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAIL = 'GET_ROLES_FAIL';

export const CHANGE_USER_ROLE_START = 'CHANGE_USER_ROLE_START';
export const CHANGE_USER_ROLE_SUCCESS = 'CHANGE_USER_ROLE_SUCCESS';
export const CHANGE_USER_ROLE_FAIL = 'CHANGE_USER_ROLE_FAIL';

export const CHANGE_USER_ACCESS_START = 'CHANGE_USER_ACCESS_START';
export const CHANGE_USER_ACCESS_SUCCESS = 'CHANGE_USER_ACCESS_SUCCESS';
export const CHANGE_USER_ACCESS_FAIL = 'CHANGE_USER_ACCESS_FAIL';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const UPDATE_PRODUCT_FAMILY_START = 'UPDATE_PRODUCT_FAMILY_START';
export const UPDATE_PRODUCT_FAMILY_SUCCESS = 'UPDATE_PRODUCT_FAMILY_SUCCESS';
export const UPDATE_PRODUCT_FAMILY_FAIL = 'UPDATE_PRODUCT_FAMILY_FAIL';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const UPDATE_KIT_VISIBILITY_START = 'UPDATE_KIT_VISIBILITY_START';
export const UPDATE_KIT_VISIBILITY_SUCCESS = 'UPDATE_KIT_VISIBILITY_SUCCESS';
export const UPDATE_KIT_VISIBILITY_FAIL = 'UPDATE_KIT_VISIBILITY_FAIL';

export const CREATE_PRODUCT_FAMILY_START = 'CREATE_PRODUCT_FAMILY_START';
export const CREATE_PRODUCT_FAMILY_SUCCESS = 'CREATE_PRODUCT_FAMILY_SUCCESS';
export const CREATE_PRODUCT_FAMILY_FAIL = 'CREATE_PRODUCT_FAMILY_FAIL';

export const DELETE_PRODUCT_FAMILY_START = 'DELETE_PRODUCT_FAMILY_START';
export const DELETE_PRODUCT_FAMILY_SUCCESS = 'DELETE_PRODUCT_FAMILY_SUCCESS';
export const DELETE_PRODUCT_FAMILY_FAIL = 'DELETE_PRODUCT_FAMILY_FAIL';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const DELETE_CUSTOM_KIT_START = 'DELETE_CUSTOM_KIT_START';
export const DELETE_CUSTOM_KIT_SUCCESS = 'DELETE_CUSTOM_KIT_SUCCESS';
export const DELETE_CUSTOM_KIT_FAIL = 'DELETE_CUSTOM_KIT_FAIL';

export const GET_KITS_START = 'GET_KITS';
export const GET_KITS_SUCCESS = 'GET_KITS_SUCCESS';
export const GET_KITS_FAIL = 'GET_KITS_FAIL';

export const GET_KITS_CATEGORIES_START = 'GET_KITS_CATEGORIES';
export const GET_KITS_CATEGORIES_SUCCESS = 'GET_KITS_CATEGORIES_SUCCESS';
export const GET_KITS_CATEGORIES_FAIL = 'GET_KITS_CATEGORIES_FAIL';

export const GET_CART_CUSTOM_KITS_START = 'GET_CART_CUSTOM_KITS';
export const GET_CART_CUSTOM_KITS_SUCCESS = 'GET_CART_CUSTOM_KITS_SUCCESS';
export const GET_CART_CUSTOM_KITS_FAIL = 'GET_CART_CUSTOM_KITS_FAIL';

export const GET_SAVED_CUSTOM_KITS_START = 'GET_SAVED_CUSTOM_KITS';
export const GET_SAVED_CUSTOM_KITS_SUCCESS = 'GET_SAVED_CUSTOM_KITS_SUCCESS';
export const GET_SAVED_CUSTOM_KITS_FAIL = 'GET_SAVED_CUSTOM_KITS_FAIL';

export const GET_PROFILE_IMAGE_START = 'GET_PROFILE_IMAGE';
export const GET_PROFILE_IMAGE_SUCCESS = 'GET_PROFILE_IMAGE_SUCCESS';
export const GET_PROFILE_IMAGE_FAIL = 'GET_PROFILE_IMAGE_FAIL';

export const REQUEST_RESET_LINK_START = 'REQUEST_RESET_LINK_START';
export const REQUEST_RESET_LINK_SUCCESS = 'REQUEST_RESET_LINK_SUCCESS';
export const REQUEST_RESET_LINK_FAIL = 'REQUEST_RESET_LINK_FAIL';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const SNACKBAR_CLEAR = 'SNACKBAR_CLEAR';

export const SHOW_REMINDER = 'SHOW_REMINDER';
export const HIDE_REMINDER = 'HIDE_REMINDER';

export const GET_CATEGORIES_START = 'GET_CATEGORIES_START';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';

export const UPLOAD_DEFAULT_PHOTO_START = 'UPLOAD_DEFAULT_PHOTO_START';
export const UPLOAD_DEFAULT_PHOTO_SUCCESS = 'UPLOAD_DEFAULT_PHOTO_SUCCESS';
export const UPLOAD_DEFAULT_PHOTO_FAIL = 'UPLOAD_DEFAULT_PHOTO_FAIL';

export const UPDATE_CATEGORY_START = 'UPDATE_CATEGORY_START';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';

export const UPDATE_PARTTYPE_START = 'UPDATE_PARTTYPE_START';
export const UPDATE_PARTTYPE_SUCCESS = 'UPDATE_PARTTYPE_SUCCESS';
export const UPDATE_PARTTYPE_FAIL = 'UPDATE_PARTTYPE_FAIL';

export const GET_COMPONENTS_START = 'GET_COMPONENTS_START';
export const GET_COMPONENTS_SUCCESS = 'GET_COMPONENTS_SUCCESS';
export const GET_COMPONENTS_FAIL = 'GET_COMPONENTS_FAIL';

export const GET_COMPONENTS_FROM_CART_START = 'GET_COMPONENTS_FROM_CART_START';
export const GET_COMPONENTS_FROM_CART_SUCCESS = 'GET_COMPONENTS_FROM_CART_SUCCESS';
export const GET_COMPONENTS_FROM_CART_FAIL = 'GET_COMPONENTS_FROM_CART_FAIL';

export const ADD_COMPONENT_TO_CART_START = 'ADD_COMPONENT_TO_CART_START';
export const ADD_COMPONENT_TO_CART_SUCCESS = 'ADD_COMPONENT_TO_CART_SUCCESS';
export const ADD_COMPONENT_TO_CART_FAIL = 'ADD_COMPONENT_TO_CART_FAIL';

export const DELETE_COMPONENT_FROM_CART_START = 'DELETE_COMPONENT_FROM_CART_START';
export const DELETE_COMPONENT_FROM_CART_SUCCESS = 'DELETE_COMPONENT_FROM_CART_SUCCESS';
export const DELETE_COMPONENT_FROM_CART_FAIL = 'DELETE_COMPONENT_FROM_CART_FAIL';

export const GET_SAVED_KITS_START = 'GET_SAVED_KITS_START';
export const GET_SAVED_KITS_SUCCESS = 'GET_SAVED_KITS_SUCCESS';
export const GET_SAVED_KITS_FAIL = 'GET_SAVED_KITS_FAIL';

export const ADD_COMPONENT_TO_KIT_START = 'ADD_COMPONENT_TO_KIT_START';
export const ADD_COMPONENT_TO_KIT_SUCCESS = 'ADD_COMPONENT_TO_KIT_SUCCESS';
export const ADD_COMPONENT_TO_KIT_FAIL = 'ADD_COMPONENT_TO_KIT_FAIL';

export const GET_COMPONENT_CATEGORIES_START = 'GET_COMPONENT_CATEGORIES_START';
export const GET_COMPONENT_CATEGORIES_SUCCESS = 'GET_COMPONENT_CATEGORIES_SUCCESS';
export const GET_COMPONENT_CATEGORIES_FAIL = 'GET_COMPONENT_CATEGORIES_FAIL';

export const GET_KIT_TRAYS_START = 'GET_KIT_TRAYS_START';
export const GET_KIT_TRAYS_SUCCESS = 'GET_KIT_TRAYS_SUCCESS';
export const GET_KIT_TRAYS_FAIL = 'GET_KIT_TRAYS_FAIL';

export const CREATE_KIT_TRAY_START = 'CREATE_KIT_TRAY_START';
export const CREATE_KIT_TRAY_SUCCESS = 'CREATE_KIT_TRAY_SUCCESS';
export const CREATE_KIT_TRAY_FAIL = 'CREATE_KIT_TRAY_FAIL';

export const UPDATE_KIT_TRAY_START = 'UPDATE_KIT_TRAY_START';
export const UPDATE_KIT_TRAY_SUCCESS = 'UPDATE_KIT_TRAY_SUCCESS';
export const UPDATE_KIT_TRAY_FAIL = 'UPDATE_KIT_TRAY_FAIL';

export const DELETE_KIT_TRAY_START = 'DELETE_KIT_TRAY_START';
export const DELETE_KIT_TRAY_SUCCESS = 'DELETE_KIT_TRAY_SUCCESS';
export const DELETE_KIT_TRAY_FAIL = 'DELETE_KIT_TRAY_FAIL';

export const GET_TRAY_CAVITIES_START = 'GET_TRAY_CAVITIES_START';
export const GET_TRAY_CAVITIES_SUCCESS = 'GET_TRAY_CAVITIES_SUCCESS';
export const GET_TRAY_CAVITIES_FAIL = 'GET_TRAY_CAVITIES_FAIL';

export const CREATE_TRAY_CAVITY_START = 'CREATE_TRAY_CAVITY_START';
export const CREATE_TRAY_CAVITY_SUCCESS = 'CREATE_TRAY_CAVITY_SUCCESS';
export const CREATE_TRAY_CAVITY_FAIL = 'CREATE_TRAY_CAVITY_FAIL';

export const UPDATE_TRAY_CAVITY_START = 'UPDATE_TRAY_CAVITY_START';
export const UPDATE_TRAY_CAVITY_SUCCESS = 'UPDATE_TRAY_CAVITY_SUCCESS';
export const UPDATE_TRAY_CAVITY_FAIL = 'UPDATE_TRAY_CAVITY_FAIL';

export const DELETE_TRAY_CAVITY_START = 'DELETE_TRAY_CAVITY_START';
export const DELETE_TRAY_CAVITY_SUCCESS = 'DELETE_TRAY_CAVITY_SUCCESS';
export const DELETE_TRAY_CAVITY_FAIL = 'DELETE_TRAY_CAVITY_FAIL';

export const GET_MAIN_DEVICE_START = 'GET_MAIN_DEVICE_START';
export const GET_MAIN_DEVICE_SUCCESS = 'GET_MAIN_DEVICE_SUCCESS';
export const GET_MAIN_DEVICE_FAIL = 'GET_MAIN_DEVICE_FAIL';

export const CHANGE_CAVITIES_ASSEMBLY_ORDER_START = 'CHANGE_CAVITIES_ASSEMBLY_ORDER_START';
export const CHANGE_CAVITIES_ASSEMBLY_ORDER_SUCCESS = 'CHANGE_CAVITIES_ASSEMBLY_ORDER_SUCCESS';
export const CHANGE_CAVITIES_ASSEMBLY_ORDER_FAIL = 'CHANGE_CAVITIES_ASSEMBLY_ORDER_FAIL';

export const GET_CMS_COMPONENTS_START = 'GET_CMS_COMPONENTS_START';
export const GET_CMS_COMPONENTS_SUCCESS = 'GET_CMS_COMPONENTS_SUCCESS';
export const GET_CMS_COMPONENTS_FAIL = 'GET_CMS_COMPONENTS_FAIL';

export const CREATE_CMS_COMPONENTS_START = 'CREATE_CMS_COMPONENTS_START';
export const CREATE_CMS_COMPONENTS_SUCCESS = 'CREATE_CMS_COMPONENTS_SUCCESS';
export const CREATE_CMS_COMPONENTS_FAIL = 'CREATE_CMS_COMPONENTS_FAIL';

export const GET_CMS_COMPONENT_INFO_START = 'GET_CMS_COMPONENT_INFO_START';
export const GET_CMS_COMPONENT_INFO_SUCCESS = 'GET_CMS_COMPONENT_INFO_SUCCESS';
export const GET_CMS_COMPONENT_INFO_FAIL = 'GET_CMS_COMPONENT_INFO_FAIL';

export const UPDATE_CMS_COMPONENT_INFO_START = 'UPDATE_CMS_COMPONENT_INFO_START';
export const UPDATE_CMS_COMPONENT_INFO_SUCCESS = 'UPDATE_CMS_COMPONENT_INFO_SUCCESS';
export const UPDATE_CMS_COMPONENT_INFO_FAIL = 'UPDATE_CMS_COMPONENT_INFO_FAIL';

export const CREATE_CMS_COMPONENT_IMAGE_START = 'CREATE_CMS_COMPONENT_IMAGE_START';
export const CREATE_CMS_COMPONENT_IMAGE_SUCCESS = 'CREATE_CMS_COMPONENT_IMAGE_SUCCESS';
export const CREATE_CMS_COMPONENT_IMAGE_FAIL = 'CREATE_CMS_COMPONENT_IMAGE_FAIL';

export const UPDATE_CMS_COMPONENT_IMAGE_START = 'UPDATE_CMS_COMPONENT_IMAGE_START';
export const UPDATE_CMS_COMPONENT_IMAGE_SUCCESS = 'UPDATE_CMS_COMPONENT_IMAGE_SUCCESS';
export const UPDATE_CMS_COMPONENT_IMAGE_FAIL = 'UPDATE_CMS_COMPONENT_IMAGE_FAIL';

export const DELETE_CMS_COMPONENT_IMAGE_START = 'DELETE_CMS_COMPONENT_IMAGE_START';
export const DELETE_CMS_COMPONENT_IMAGE_SUCCESS = 'DELETE_CMS_COMPONENT_IMAGE_SUCCESS';
export const DELETE_CMS_COMPONENT_IMAGE_FAIL = 'DELETE_CMS_COMPONENT_IMAGE_FAIL';

export const CLEAR_FALLBACK_COMPONENT_SEARCH_LIST = 'CLEAR_FALLBACK_COMPONENT_SEARCH_LIST';

export const ADD_FALLBACK_COMPONENT_START = 'ADD_FALLBACK_COMPONENT_START';
export const ADD_FALLBACK_COMPONENT_SUCCESS = 'ADD_FALLBACK_COMPONENT_SUCCESS';
export const ADD_FALLBACK_COMPONENT_FAIL = 'ADD_FALLBACK_COMPONENT_FAIL';

export const DELETE_FALLBACK_COMPONENT_START = 'DELETE_FALLBACK_COMPONENT_START';
export const DELETE_FALLBACK_COMPONENT_SUCCESS = 'DELETE_FALLBACK_COMPONENT_SUCCESS';
export const DELETE_FALLBACK_COMPONENT_FAIL = 'DELETE_FALLBACK_COMPONENT_FAIL';

export const GET_CMS_COMPONENT_TOOLTIP_INFO_START = 'GET_CMS_COMPONENT_TOOLTIP_INFO_START';
export const GET_CMS_COMPONENT_TOOLTIP_INFO_SUCCESS = 'GET_CMS_COMPONENT_TOOLTIP_INFO_SUCCESS';
export const GET_CMS_COMPONENT_TOOLTIP_INFO_FAIL = 'GET_CMS_COMPONENT_TOOLTIP_INFO_FAIL';

export const CLEAR_CMS_COMPONENT_TOOLTIP_INFO = 'CLEAR_CMS_COMPONENT_TOOLTIP_INFO';

export const GET_KIT_LIST_FROM_KIT_DESIGN_START = 'GET_KIT_LIST_FROM_KIT_DESIGN_START';
export const GET_KIT_LIST_FROM_KIT_DESIGN_SUCCESS = 'GET_KIT_LIST_FROM_KIT_DESIGN_SUCCESS';
export const GET_KIT_LIST_FROM_KIT_DESIGN_FAIL = 'GET_KIT_LIST_FROM_KIT_DESIGN_FAIL';

export const CREATE_KIT_LIST_FROM_KIT_DESIGN_START = 'CREATE_KIT_LIST_FROM_KIT_DESIGN_START';
export const CREATE_KIT_LIST_FROM_KIT_DESIGN_SUCCESS = 'CREATE_KIT_LIST_FROM_KIT_DESIGN_SUCCESS';
export const CREATE_KIT_LIST_FROM_KIT_DESIGN_FAIL = 'CREATE_KIT_LIST_FROM_KIT_DESIGN_FAIL';

export const CLEAR_KIT_LIST_FROM_KIT_DESIGN_DATA = 'CLEAR_KIT_LIST_FROM_KIT_DESIGN_DATA';

export const ADD_COMPONENT_TO_KIT_AUTOASSIGN_SUCCESS = 'ADD_COMPONENT_TO_KIT_AUTOASSIGN_SUCCESS';
export const ADD_COMPONENT_TO_KIT_AUTOASSIGN_START = 'ADD_COMPONENT_TO_KIT_AUTOASSIGN_START';
export const ADD_COMPONENT_TO_KIT_AUTOASSIGN_FAIL = 'ADD_COMPONENT_TO_KIT_AUTOASSIGN_FAIL';

export const SWAP_COMPONENT_TO_KIT_AUTOASSIGN_SUCCESS = 'SWAP_COMPONENT_TO_KIT_AUTOASSIGN_SUCCESS';
export const SWAP_COMPONENT_TO_KIT_AUTOASSIGN_START = 'SWAP_COMPONENT_TO_KIT_AUTOASSIGN_START';
export const SWAP_COMPONENT_TO_KIT_AUTOASSIGN_FAIL = 'SWAP_COMPONENT_TO_KIT_AUTOASSIGN_FAIL';

export const REMOVE_COMPONENT_FROM_CAVITY_SUCCESS = 'REMOVE_COMPONENT_FROM_CAVITY_SUCCESS';
export const REMOVE_COMPONENT_FROM_CAVITY_START = 'REMOVE_COMPONENT_FROM_CAVITY_START';
export const REMOVE_COMPONENT_FROM_CAVITY_FAIL = 'REMOVE_COMPONENT_FROM_CAVITY_FAIL';

export const REMOVE_ALL_COMPONENTS_SUCCESS = 'REMOVE_ALL_COMPONENTS_SUCCESS';
export const REMOVE_ALL_COMPONENTS_START = 'REMOVE_ALL_COMPONENTS_START';
export const REMOVE_ALL_COMPONENTS_FAIL = 'REMOVE_ALL_COMPONENTS_FAIL';

export const GET_KIT_INFO_FROM_KIT_DESIGN_START = 'GET_KIT_INFO_FROM_KIT_DESIGN_START';
export const GET_KIT_INFO_FROM_KIT_DESIGN_SUCCESS = 'GET_KIT_INFO_FROM_KIT_DESIGN_SUCCESS';
export const GET_KIT_INFO_FROM_KIT_DESIGN_FAIL = 'GET_KIT_INFO_FROM_KIT_DESIGN_FAIL';

export const DELETE_KIT_FROM_KIT_MY_LIST_START = 'DELETE_KIT_FROM_KIT_MY_LIST_START';
export const DELETE_KIT_FROM_KIT_MY_LIST_SUCCESS = 'DELETE_KIT_FROM_KIT_MY_LIST_SUCCESS';
export const DELETE_KIT_FROM_KIT_MY_LIST_FAIL = 'DELETE_KIT_FROM_KIT_MY_LIST_FAIL';

export const VALIDATE_KIT_NAME = 'VALIDATE_KIT_NAME';

export const UPDATE_KIT_FROM_MY_KIT_LIST_START = 'UPDATE_KIT_FROM_MY_KIT_LIST_START';
export const UPDATE_KIT_FROM_MY_KIT_LIST_SUCCESS = 'UPDATE_KIT_FROM_MY_KIT_LIST_SUCCESS';
export const UPDATE_KIT_FROM_MY_KIT_LIST_FAIL = 'UPDATE_KIT_FROM_MY_KIT_LIST_FAIL';

export const SEND_KIT_TO_SUBMIT_START = 'SEND_KIT_TO_SUBMIT_START';
export const SEND_KIT_TO_SUBMIT_SUCCESS = 'SEND_KIT_TO_SUBMIT_SUCCESS';
export const SEND_KIT_TO_SUBMIT_FAIL = 'SEND_KIT_TO_SUBMIT_FAIL';

export const PUSH_BACK_KIT_TO_SALES_START = 'PUSH_BACK_KIT_TO_SALES_START';
export const PUSH_BACK_KIT_TO_SALES_SUCCESS = 'PUSH_BACK_KIT_TO_SALES_SUCCESS';
export const PUSH_BACK_KIT_TO_SALES_FAIL = 'PUSH_BACK_KIT_TO_SALES_FAIL';

export const SUBMIT_KIT_TO_MANUFACTURING_START = 'SUBMIT_KIT_TO_MANUFACTURING_START';
export const SUBMIT_KIT_TO_MANUFACTURING_SUCCESS = 'SUBMIT_KIT_TO_MANUFACTURING_SUCCESS';
export const SUBMIT_KIT_TO_MANUFACTURING_FAIL = 'SUBMIT_KIT_TO_MANUFACTURING_FAIL';

export const NOTE_ADD_START = 'NOTE_ADD_START';
export const NOTE_ADD_SUCCESS = 'NOTE_ADD_SUCCESS';
export const NOTE_ADD_FAIL = 'NOTE_ADD_FAIL';

export const LIST_SNAPSHOTS_START = 'LIST_SNAPSHOTS_START';
export const LIST_SNAPSHOTS_SUCCESS = 'LIST_SNAPSHOTS_SUCCESS';
export const LIST_SNAPSHOTS_FAIL = 'LIST_SNAPSHOTS_FAIL';

export const CREATE_SNAPSHOT_START = 'CREATE_SNAPSHOT_START';
export const CREATE_SNAPSHOT_SUCCESS = 'CREATE_SNAPSHOT_SUCCESS';
export const CREATE_SNAPSHOT_FAIL = 'CREATE_SNAPSHOT_FAIL';

export const DELETE_SNAPSHOT_START = 'DELETE_SNAPSHOT_START';
export const DELETE_SNAPSHOT_SUCCESS = 'DELETE_SNAPSHOT_SUCCESS';
export const DELETE_SNAPSHOT_FAIL = 'DELETE_SNAPSHOT_FAIL';

export const UPDATE_SNAPSHOT_ORDER_START = 'UPDATE_SNAPSHOT_ORDER_START';
export const UPDATE_SNAPSHOT_ORDER_SUCCESS = 'UPDATE_SNAPSHOT_ORDER_SUCCESS';
export const UPDATE_SNAPSHOT_ORDER_FAIL = 'UPDATE_SNAPSHOT_ORDER_FAIL';

export const BUILD_KIT_LIST_START = 'BUILD_KIT_LIST_START';
export const BUILD_KIT_LIST_SUCCESS = 'BUILD_KIT_LIST_SUCCESS';
export const BUILD_KIT_LIST_FAIL = 'BUILD_KIT_LIST_FAIL';

export const SWAP_KIT_TRAY_START = 'SWAP_KIT_TRAY_START';
export const SWAP_KIT_TRAY_SUCCESS = 'SWAP_KIT_TRAY_SUCCESS';
export const SWAP_KIT_TRAY_FAIL = 'SWAP_KIT_TRAY_FAIL';

export const CHANGE_ORPHAN_COMPONENT = 'CHANGE_ORPHAN_COMPONENT';

export const ADD_COMPONENT_TO_CAVITY_OVERRIDE_START = 'ADD_COMPONENT_TO_CAVITY_OVERRIDE_START';
export const ADD_COMPONENT_TO_CAVITY_OVERRIDE_SUCCESS = 'ADD_COMPONENT_TO_CAVITY_OVERRIDE_SUCCESS';
export const ADD_COMPONENT_TO_CAVITY_OVERRIDE_FAIL = 'ADD_COMPONENT_TO_CAVITY_OVERRIDE_FAIL';

export const MOVE_COMPONENT_TO_CAVITY_OVERRIDE_START = 'MOVE_COMPONENT_TO_CAVITY_OVERRIDE_START';
export const MOVE_COMPONENT_TO_CAVITY_OVERRIDE_SUCCESS = 'MOVE_COMPONENT_TO_CAVITY_OVERRIDE_SUCCESS';
export const MOVE_COMPONENT_TO_CAVITY_OVERRIDE_FAIL = 'MOVE_COMPONENT_TO_CAVITY_OVERRIDE_FAIL';

export const UPDATE_ASSEMBLY_ORDER_START = 'UPDATE_ASSEMBLY_ORDER_START';
export const UPDATE_ASSEMBLY_ORDER_SUCCESS = 'UPDATE_ASSEMBLY_ORDER_SUCCESS';
export const UPDATE_ASSEMBLY_ORDER_FAIL = 'UPDATE_ASSEMBLY_ORDER_FAIL';

export const DOWLOAD_ASSEMBLY_PDF_START = 'DOWLOAD_ASSEMBLY_PDF_START';
export const DOWLOAD_ASSEMBLY_PDF_SUCCESS = 'DOWLOAD_ASSEMBLY_PDF_SUCCESS';
export const DOWLOAD_ASSEMBLY_PDF_FAIL = 'DOWLOAD_ASSEMBLY_PDF_FAIL';

export const AUTO_SAVE_KIT_FORM_DATA = 'AUTO_SAVE_KIT_FORM_DATA';

export const UPLOAD_COMPONENTS_PRICING_START = 'UPLOAD_COMPONENTS_PRICING_START';
export const UPLOAD_COMPONENTS_PRICING_SUCCESS = 'UPLOAD_COMPONENTS_PRICING_SUCCESS';
export const UPLOAD_COMPONENTS_PRICING_FAIL = 'UPLOAD_COMPONENTS_PRICING_FAIL';

export const GET_KIT_LIST_FROM_KIT_FINDER_START = 'GET_KIT_LIST_FROM_KIT_FINDER_START';
export const GET_KIT_LIST_FROM_KIT_FINDER_SUCCESS = 'GET_KIT_LIST_FROM_KIT_FINDER_SUCCESS';
export const GET_KIT_LIST_FROM_KIT_FINDER_FAIL = 'GET_KIT_LIST_FROM_KIT_FINDER_FAIL';

export const GET_COMPONENT_INFO_FOR_KIT_FINDER_START = 'GET_COMPONENT_INFO_FOR_KIT_FINDER_START';
export const GET_COMPONENT_INFO_FOR_KIT_FINDER_SUCCESS = 'GET_COMPONENT_INFO_FOR_KIT_FINDER_SUCCESS';
export const GET_COMPONENT_INFO_FOR_KIT_FINDER_FAIL = 'GET_COMPONENT_INFO_FOR_KIT_FINDER_FAIL';

export const GET_COMPONENT_LIST_FOR_KIT_FINDER_START = 'GET_COMPONENT_LIST_FOR_KIT_FINDER_START';
export const GET_COMPONENT_LIST_FOR_KIT_FINDER_SUCCESS = 'GET_COMPONENT_LIST_FOR_KIT_FINDER_SUCCESS';
export const GET_COMPONENT_LIST_FOR_KIT_FINDER_FAIL = 'GET_COMPONENT_LIST_FOR_KIT_FINDER_FAIL';

export const CLEAR_COMPONENT_LIST_FOR_KIT_FINDER = 'CLEAR_COMPONENT_LIST_FOR_KIT_FINDER';

export const GET_KITS_TABLE_DATA_START = 'GET_KITS_TABLE_DATA_START';
export const GET_KITS_TABLE_DATA_SUCCESS = 'GET_KITS_TABLE_DATA_SUCCESS';
export const GET_KITS_TABLE_DATA_FAIL = 'GET_KITS_TABLE_DATA_FAIL';

export const GET_PACKAGING_OPTIONS_START = 'GET_PACKAGING_OPTIONS_START';
export const GET_PACKAGING_OPTIONS_SUCCESS = 'GET_PACKAGING_OPTIONS_SUCCESS';
export const GET_PACKAGING_OPTIONS_FAIL = 'GET_PACKAGING_OPTIONS_FAIL';

export const GET_PACKAGING_OPTION_COMPONENTS_START = 'GET_PACKAGING_OPTION_COMPONENTS_START';
export const GET_PACKAGING_OPTION_COMPONENTS_SUCCESS = 'GET_PACKAGING_OPTION_COMPONENTS_SUCCESS';
export const GET_PACKAGING_OPTION_COMPONENTS_FAIL = 'GET_PACKAGING_OPTION_COMPONENTS_FAIL';

export const CREATE_PACKAGING_OPTIONS_START = 'CREATE_PACKAGING_OPTIONS_START';
export const CREATE_PACKAGING_OPTIONS_SUCCESS = 'CREATE_PACKAGING_OPTIONS_SUCCESS';
export const CREATE_PACKAGING_OPTIONS_FAIL = 'CREATE_PACKAGING_OPTIONS_FAIL';

export const UPDATE_PACKAGING_OPTIONS_START = 'UPDATE_PACKAGING_OPTIONS_START';
export const UPDATE_PACKAGING_OPTIONS_SUCCESS = 'UPDATE_PACKAGING_OPTIONS_SUCCESS';
export const UPDATE_PACKAGING_OPTIONS_FAIL = 'UPDATE_PACKAGING_OPTIONS_FAIL';

export const DELETE_PACKAGING_OPTIONS_START = 'DELETE_PACKAGING_OPTIONS_START';
export const DELETE_PACKAGING_OPTIONS_SUCCESS = 'DELETE_PACKAGING_OPTIONS_SUCCESS';
export const DELETE_PACKAGING_OPTIONS_FAIL = 'DELETE_PACKAGING_OPTIONS_FAIL';

export const DELETE_PACKAGING_OPTION_COMPONENT_START = 'DELETE_PACKAGING_OPTION_COMPONENT_START';
export const DELETE_PACKAGING_OPTION_COMPONENT_SUCCESS = 'DELETE_PACKAGING_OPTION_COMPONENT_SUCCESS';
export const DELETE_PACKAGING_OPTION_COMPONENT_FAIL = 'DELETE_PACKAGING_OPTION_COMPONENT_FAIL';

export const DELETE_PACKAGING_OPTION_IMAGE_START = 'DELETE_PACKAGING_OPTION_IMAGE_START';
export const DELETE_PACKAGING_OPTION_IMAGE_SUCCESS = 'DELETE_PACKAGING_OPTION_IMAGE_SUCCESS';
export const DELETE_PACKAGING_OPTION_IMAGE_FAIL = 'DELETE_PACKAGING_OPTION_IMAGE_FAIL';

export const UPDATE_PACKAGING_OPTION_IMAGE_START = 'UPDATE_PACKAGING_OPTION_IMAGE_START';
export const UPDATE_PACKAGING_OPTION_IMAGE_SUCCESS = 'UPDATE_PACKAGING_OPTION_IMAGE_SUCCESS';
export const UPDATE_PACKAGING_OPTION_IMAGE_FAIL = 'UPDATE_PACKAGING_OPTION_IMAGE_FAIL';

export const ADD_COMPONENT_TO_PACKAGING_OPTION_START = 'ADD_COMPONENT_TO_PACKAGING_OPTION_START';
export const ADD_COMPONENT_TO_PACKAGING_OPTION_SUCCESS = 'ADD_COMPONENT_TO_PACKAGING_OPTION_SUCCESS';
export const ADD_COMPONENT_TO_PACKAGING_OPTION_FAIL = 'ADD_COMPONENT_TO_PACKAGING_OPTION_FAIL';

export const CLEAR_PACKAGING_OPTION_COMPONENTS_DATA = 'CLEAR_PACKAGING_OPTION_COMPONENTS_DATA';

export const UPDATE_KIT_TAG_START = 'UPDATE_KIT_TAG_START';
export const UPDATE_KIT_TAG_SUCCESS = 'UPDATE_KIT_TAG_SUCCESS';
export const UPDATE_KIT_TAG_FAIL = 'UPDATE_KIT_TAG_FAIL';

export const ASSIGN_TRAY_TO_BASE_KIT_START = 'ASSIGN_TRAY_TO_BASE_KIT_START';
export const ASSIGN_TRAY_TO_BASE_KIT_SUCCESS = 'ASSIGN_TRAY_TO_BASE_KIT_SUCCESS';
export const ASSIGN_TRAY_TO_BASE_KIT_FAIL = 'ASSIGN_TRAY_TO_BASE_KIT_FAIL';

export const LIST_PAGE_BREAK_IMAGES_START = 'LIST_PAGE_BREAK_IMAGES_START';
export const LIST_PAGE_BREAK_IMAGES_SUCCESS = 'LIST_PAGE_BREAK_IMAGES_SUCCESS';
export const LIST_PAGE_BREAK_IMAGES_FAIL = 'LIST_PAGE_BREAK_IMAGES_FAIL';

export const ADD_PAGE_BREAK_IMAGE_START = 'ADD_PAGE_BREAK_IMAGE_START';
export const ADD_PAGE_BREAK_IMAGE_SUCCESS = 'ADD_PAGE_BREAK_IMAGE_SUCCESS';
export const ADD_PAGE_BREAK_IMAGE_FAIL = 'ADD_PAGE_BREAK_IMAGE_FAIL';

export const UPDATE_PAGE_BREAK_IMAGE_NAME_START =
  "UPDATE_PAGE_BREAK_IMAGE_NAME_START";
export const UPDATE_PAGE_BREAK_IMAGE_NAME_SUCCESS =
  "UPDATE_PAGE_BREAK_IMAGE_NAME_SUCCESS";
export const UPDATE_PAGE_BREAK_IMAGE_NAME_FAIL =
  "UPDATE_PAGE_BREAK_IMAGE_NAME_FAIL";

export const RESET_OVERRIDE_START = 'RESET_OVERRIDE_START';
export const RESET_OVERRIDE_SUCCESS = 'RESET_OVERRIDE_SUCCESS';
export const RESET_OVERRIDE_FAIL = 'RESET_OVERRIDE_FAIL';

export const DELETE_PAGE_BREAK_IMAGE_START = 'DELETE_PAGE_BREAK_IMAGE_START';
export const DELETE_PAGE_BREAK_IMAGE_SUCCESS = 'DELETE_PAGE_BREAK_IMAGE_SUCCESS';
export const DELETE_PAGE_BREAK_IMAGE_FAIL = 'DELETE_PAGE_BREAK_IMAGE_FAIL';

export const SET_MENU_STATE = 'SET_MENU_STATE';

export const SAVE_FILTER_STATE = 'SAVE_FILTER_STATE';

export const SELECT_CURRENT_COMPONENT = 'SELECT_CURRENT_COMPONENT';

export const SAVE_COMPONENTS_RANGE = "SAVE_COMPONENTS_RANGE";

export const UPDATE_KIT_PACKAGING_START = "UPDATE_KIT_PACKAGING_START";
export const UPDATE_KIT_PACKAGING_SUCCESS = "UPDATE_KIT_PACKAGING_SUCCESS";
export const UPDATE_KIT_PACKAGING_FAIL = "UPDATE_KIT_PACKAGING_FAIL";

export const UPDATE_COMPONENT_IMAGE_ORDER_START =
  "UPDATE_COMPONENT_IMAGE_ORDER_START";
export const UPDATE_COMPONENT_IMAGE_ORDER_SUCCESS =
  "UPDATE_COMPONENT_IMAGE_ORDER_SUCCESS";
export const UPDATE_COMPONENT_IMAGE_ORDER_FAIL =
  "UPDATE_COMPONENT_IMAGE_ORDER_FAIL";
  
export const SAVE_UPDATED_PAGE = "SAVE_UPDATED_PAGE";
export const UPDATE_LAYOUT_DATA_SUCCESS = "UPDATE_LAYOUT_DATA_SUCCESS";

export const SAVE_SWAPPED_COMPONENT_INFORMATION =
  'SAVE_SWAPPED_COMPONENT_INFORMATION';

export const LOAD_LOGS_START = 'LOAD_LOGS_START';
export const LOAD_LOGS_SUCCESS = 'LOAD_LOGS_SUCCESS';
export const LOAD_LOGS_FAIL = 'LOAD_LOGS_FAIL';