import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import CustomInput from '../../../../components/Inputs/CustomInput';
import { useDispatch } from 'react-redux';
import { hideReminder } from '../../../../redux/actions/snackbar';

const Container = styled.div`
    display: flex;
    padding: 20px 10px;
    flex-direction: column;
`;

const Title = styled.div`
    justify-content: space-between;
    margin-bottom: 10px;
    display: flex;
    width: 220px;
    font-weight: 500;
    font-size: 14px;
    margin-top: 20px;
`;

const Item = styled(Title)`
    margin-bottom: 5px;
    color: #d0d0d0;
    font-size: 10px;
    align-items: flex-end;
    margin-top: 0px;

    input {
        padding-top: 0px;
        height: 22px;
        background: #1d265b;
        color: white;
        text-align: center;
        width: 50px;
        font-size: 10px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    label, label::after {
        border-color: white;
    }

    & > div {
        height: 24px;
    }
`;

const Rules = ({ tray, onTrayChange }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(hideReminder());
        }
    }, [dispatch]);

    return (
        <Container>
            <Title>
                <span>Rule</span>
                <span>Value</span>
            </Title>
            <Item>
                <span>Maximum components allowed</span>
                <CustomInput type='number' minn='0' value={tray.data?.maxComponents} name='maxComponents' onChange={onTrayChange} />
            </Item>
            {/*<Item>
                <span>Weight Limit</span>
                <CustomInput type='number' step="0.01" minn='0' value={tray.data?.maxWeight} name='maxWeight' onChange={onTrayChange} />
            </Item>*/}
        </Container>
    );
};

export default Rules;
