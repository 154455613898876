import React from 'react';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import {
  ComponentContent,
  ComponentContentInfoWrapper,
  ComponentContentName,
  ComponentContentOverridenIcon,
  ComponentContentSku,
  ComponentContenWrapper,
} from './styles';
import { setLowLevelComponents } from '../helper';

export default function ComponentElement(props) {
  const history = useHistory();
  // drag props
  const { provided, snapshot, getItemStyle, DragItem } = props;

  // component props
  const {
    obj,
    setMouseOver,
    componentAssignments,
    setSelectedPage,
    kit,
    objects,
    tray,
    notesObj,
  } = props;

  if (obj.type === 0) {
    return null;
  }

  const clickOnComponentArrow = (obj) => {
    const assignment = componentAssignments.find(
      (x) =>
        x.cavityId === obj.elem.cavityId &&
        x.component.id === obj.elem.componentId,
    );
    if (assignment) {
      setSelectedPage(null);

      const cavity = tray.cavities.find((x) => x.id === assignment.cavityId);
      const label = notesObj.find(
        (x) =>
          x.cavityId === cavity?.id &&
          x.componentId === assignment.component?.id,
      );

      history.push({
        pathname: `/my-kit-list/${kit?.id}/kitmaker/add-component`,
        state: {
          component: { ...assignment.component, imageUrl: assignment.imageUrl },
          cavity,
          label,
          lowLevelComponents: setLowLevelComponents(
            componentAssignments,
            objects,
            obj,
          ),
        },
      });
    }
  };
  const count = obj.elem.count > 1 ? `(${obj.elem.count})` : '';

  return (
    <DragItem
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getItemStyle(
        snapshot.isDragging,
        provided.draggableProps.style,
        obj.elem,
      )}
    >
      <ComponentContenWrapper
        data-tip
        data-for="Tip"
        onMouseOver={() => setMouseOver(obj.elem)}
      >
        <ComponentContent onClick={() => clickOnComponentArrow(obj)}>
          {obj.elem.isOverride && (
            <ComponentContentOverridenIcon icon={faBolt} />
          )}
          <ComponentContentInfoWrapper>
            <ComponentContentSku>{obj.elem.sku}</ComponentContentSku>
            <ComponentContentName>
              {count} {obj.elem.name}
            </ComponentContentName>
          </ComponentContentInfoWrapper>
        </ComponentContent>
      </ComponentContenWrapper>
    </DragItem>
  );
}
