import React from "react";
// Import the useDropzone hooks from react-dropzone
import { useDropzone } from "react-dropzone";
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
const Dropzone = ({ onDrop, accept }) => {
  // Initializing useDropzone hooks with options
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  /* 
    useDropzone hooks exposes two functions called getRootProps and getInputProps
    and also exposes isDragActive boolean
  */

  return (
    <div {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center">
        {isDragActive ? (
          <p className="dropzone-content">Release to drop the file here</p>
        ) : (
            <div className=" dropzone-content text-center">
              {/* <p className="dropzone-content"> */}
              <MDBRow>
                <MDBCol lg="12">
                  <MDBBtn className="greybtn">Choose Files</MDBBtn>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol lg="12" className="dropName">
                <p> Drop a file here or 
                click to select file</p>
       </MDBCol>
              </MDBRow>
              {/* </p> */}

            </div>
          )}
      </div>
    </div>
  );
};

export default Dropzone;