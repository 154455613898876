import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { NavLink, useHistory } from 'react-router-dom';
import {
  faArrowAltCircleRight,
  faClipboardList,
  faEdit,
  faTrashAlt,
  faArrowUp,
  faArrowDown,
  faBolt,
  faSpinner,
  faBan,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import Confirmation from '../../../components/Confirmation/Confirmation';
import { manufacturing, statuses } from '../../../constants/constants';
import {
  clearComponentCatalogList,
  getComponentsFromCart,
} from '../../../redux/actions/componentCategories';
import SearchSection from '../../Settings/Components/CompatibleReplacements/SearchSection';
import List from '../../Settings/KitTrays/List';
import GoBack from '../Helpers/GoBack';
import FancyScroll from '../../../components/FancyScroll';
import { loadKitInfoFromKitDesign } from '../../../redux/actions/kits';
import { showSnackbar } from '../../../redux/actions/snackbar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  a {
    font-size: 13px;
    color: white;
    padding: 5px 10px;
    font-weight: 500;
    margin: 2px 5px;
  }
  a:hover {
    background: #cdcde0;
    color: black;
  }
`;

const Link = styled.span`
  font-size: 13px;
  color: white;
  padding: 5px 10px;
  font-weight: 500;
  margin: 2px 5px;
  cursor: pointer;
  &:hover {
    background: #cdcde0;
    color: black;
  }
`;

const AccessNotification = styled.span`
  font-size: 12px;
  font-weight: 500;
  margin-top: 20%;
  text-align: center;
`;

const Component = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 10px;
  outline: 1px solid white;
  font-weight: 400;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ActionLink = styled.div`
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  padding: 10px;
  opacity: 0.7;
  svg {
    margin-right: 10px;
  }
  &:hover {
    opacity: 1;
  }
`;

const ArrowButton = styled.span`
  padding-left: 5px;
  opacity: 0.7;
  cursor: unset;
`;

const ArrowButtonActive = styled(ArrowButton)`
  opacity: 1;
  cursor: pointer;
`;

const Span = styled.span`
  pointer-events: ${(props) => (props.disabled ? 'none' : 'default')};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

const AddComponentToCustomKit = (props) => {
  const {
    onComponentSearch,
    searchedComponents,
    selectedCavity,
    setSelectedCavity,
    deleteComponent,
    status,
    addComponent,
    onSwap,
    readOnly,
    kit,
    customizeKit,
    updateAssemblyOrder,
    objects,
    canMoveComponent,
    accessLock,
    pages,
    version,
    resetComponentOverride,
    setFilterComponents,
    setExcludeFloating,
    notShownQuantityOfSelectedNote,
  } = props;

  const { kitDetails, starred, updateAssemblyOrderLoading } = useSelector(
    (state) => ({
      kitDetails: state.kits.kitDetails,
      starred: state.componentCatalog.cartComponents,
      updateAssemblyOrderLoading: state.snapshots.updateAssemblyOrderLoading,
    }),
  );

  const [selectedComponent, setSelectedComponent] = useState(null);
  const [addedComponent, setAddedComponent] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState({
    delete: false,
    placeInTray: null,
  });

  const [swappedComponent, setSwappedComponent] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();

  const isMyKitList = !!history.location.pathname.includes('/my-kit-list');
  const { cavity, component, label, lowLevelComponents } =
    history.location.state || {};

  const componentAssignments = kitDetails?.componentAssignments || [];
  const activeKit = kit ? kit : kitDetails || [];
  const hideSwapComponentElement =
    history.location.state?.location?.includes('/add-component');

  useEffect(() => {
    if (addedComponent) {
      const assigment = componentAssignments.find(
        (x) => x.component.id === addedComponent.id,
      );

      if (assigment.cavityId) {
        setFilterComponents({});
        setExcludeFloating(true);
        setAddedComponent(null);
      }
    }

    // eslint-disable-next-line
  }, [componentAssignments]);

  useEffect(() => {
    dispatch(getComponentsFromCart());
  }, [dispatch]);

  const swapAction = (assigment) => {
    if (assigment.isOrphan) {
      dispatch(
        showSnackbar(
          'success',
          'Swapped component has been added to Orphan list',
        ),
      );
      history.goBack();
    } else {
      const cavity = kitDetails?.tray?.cavities.find(
        (x) => x.id === assigment.cavityId,
      );
      history.replace({
        pathname: history.location.pathname,
        state: {
          component: swappedComponent,
          cavity,
          label: {
            ...label,
            text: swappedComponent.number,
            position: { x: cavity.points[0]?.x, y: cavity.points[0]?.y },
          },
        },
      });
      setSwappedComponent(null);
    }
    // eslint-disable-next-line
  };

  useEffect(() => {
    const assigment = componentAssignments.find(
      (x) => x.component.id === swappedComponent?.id,
    );

    if (assigment && swappedComponent) {
      swapAction(assigment);
      return;
    }

    setSelectedComponent(component);
    setSelectedCavity({ ...cavity, component, label });
    // eslint-disable-next-line
  }, [componentAssignments, history]);

  useEffect(() => {
    if (swappedComponent) {
      dispatch(loadKitInfoFromKitDesign(kitDetails?.id, true));
    }
    // eslint-disable-next-line
  }, [swappedComponent]);

  const currentAssignment = componentAssignments.find(
    (x) => x.cavityId === cavity?.id && x.component?.id === component?.id,
  );
  const overrideQuantity = componentAssignments.filter(
    (ca) =>
      ca.component.id === selectedComponent?.id && ca.cavityId === cavity?.id,
  )?.length;

  useEffect(() => {
    dispatch(clearComponentCatalogList());
  }, [selectedComponent, dispatch]);

  const handleAddComponent = (component) => {
    setAddedComponent(component);
    addComponent(component.id, isMyKitList);
    onComponentSearch('');
    setShowConfirmation({ ...showConfirmation, placeInTray: component });
  };

  const handleDeleteComponent = (component, count) => {
    deleteComponent(component.id, selectedCavity?.id, isMyKitList, count);
    setSelectedCavity(null);
    setShowConfirmation(false);
    customGoBack();
  };

  const updateSwapState = (newComponent) => {
    setSwappedComponent(newComponent);
  };

  const swapComponent = (component) => {
    onSwap(
      selectedComponent.id,
      selectedCavity?.id,
      component,
      isMyKitList,
      overrideQuantity,
      updateSwapState,
    );
    onComponentSearch('');
  };

  const customGoBack = () => {
    setSelectedCavity(null);

    if (hideSwapComponentElement && selectedComponent) {
      setSelectedComponent(null);
    } else {
      history.replace({ ...history.location, state: undefined });
      history.goBack();
    }
  };

  const moveComponent = () => {
    const {
      x,
      y,
      rotation,
      cavityId,
      assemblyOrder,
      isOverride,
      isHorizontalFlipped,
    } =
      componentAssignments.find(
        (ca) =>
          ca.component.id === selectedComponent.id &&
          ca.cavityId === cavity?.id,
      ) ?? {};

    const component = {
      ...selectedComponent,
      x,
      y,
      r: rotation,
      cavityId,
      ...(cavityId && { action: 'move' }),
      assemblyOrder,
      isOverride,
      isHorizontalFlipped,
      overrideQuantity,
    };

    history.push({
      pathname: `/my-kit-list/${activeKit?.id}/kitmaker/orphan-components/place-in-tray`,
      state: {
        component,
        lowLevelComponents,
      },
    });
  };

  const resetOverrideFn = () => {
    resetComponentOverride(selectedComponent?.id, selectedCavity?.id);
    history.goBack();
  };

  const removeAll = objects?.find(
    (o) =>
      o.cavityId === selectedCavity?.id &&
      selectedCavity?.component?.id === o.componentId,
  );

  const swapComponentElement = (
    <>
      <SearchSection
        components={searchedComponents}
        assignComponent={swapComponent}
        onSearch={(e) => onComponentSearch(e.target.value, selectedCavity?.id)}
        onSearchChange={(e) => onComponentSearch(e.target.value)}
        placeholder="Search to Swap Component..."
        itemColor="black"
        offset={410}
      />
      {deleteComponent && (
        <>
          <ActionLink>
            <FontAwesomeIcon icon={faTrashAlt} />
            <span
              onClick={() =>
                setShowConfirmation({ ...showConfirmation, delete: true })
              }
            >
              Remove One Component
            </span>
          </ActionLink>
          {removeAll?.count > 1 && (
            <ActionLink>
              <FontAwesomeIcon icon={faBan} />
              <span
                onClick={() =>
                  setShowConfirmation({
                    ...showConfirmation,
                    delete: true,
                    count: removeAll.count,
                  })
                }
              >
                Remove all {selectedComponent?.number || ''} from this kit
              </span>
            </ActionLink>
          )}
        </>
      )}
      {canMoveComponent && (
        <ActionLink>
          <FontAwesomeIcon icon={faArrowAltCircleRight} />
          <span onClick={moveComponent}>Edit Component</span>
        </ActionLink>
      )}
      {currentAssignment?.isOverride && (
        <ActionLink>
          <FontAwesomeIcon icon={faBolt} />
          <span onClick={resetOverrideFn}>Reset Override</span>
        </ActionLink>
      )}

      {!!notShownQuantityOfSelectedNote && (
        <span
          style={{
            color: 'orange',
            textAlign: 'center',
            fontSize: 12,
            fontWeight: 500,
          }}
        >
          This component has Qty {notShownQuantityOfSelectedNote} currently
          unshown
        </span>
      )}
    </>
  );

  const helperMessage = (
    <AccessNotification>
      Kits that have been sent to {statuses[status]?.label} are locked, so you
      are not able to modify the kit's component list
    </AccessNotification>
  );

  const showLinks = history.location.pathname.includes('/details');

  const arrowButtonHandler = (i) => {
    let message = 'Assembly order updated';

    const result = Array.from(objects);
    const index = result.findIndex(
      (x) =>
        x.componentId === selectedComponent?.id &&
        x.cavityId === selectedCavity?.id,
    );

    const [removed] = result.splice(index, 1);
    const calcPrevIndex = i < 0 ? index - 1 : index;
    const calcCurrIndex = index > result.length - 1 ? index - 1 : index;

    if (removed.pageId !== result[calcPrevIndex].pageId) {
      if (Math.abs(removed.pageId - result[calcPrevIndex].pageId) > 1) {
        removed.pageId = removed.pageId + i;
      } else {
        removed.pageId = result[calcPrevIndex].pageId;
      }
      result.splice(index, 0, removed);
      message = 'Only the page has been updated for this component';
    } else {
      removed.id = result[calcPrevIndex].id;
      result[calcCurrIndex].id = removed.id - i;
      result.splice(index + i, 0, removed);
    }

    updateAssemblyOrder(
      result.map((x) => {
        delete x.value;
        delete x.label;
        return x;
      }),
      pages,
      version,
      message,
    );
  };

  const getComponentIndex = (type) => {
    if (!objects) return;

    const realObjects = objects.filter((x) => x?.componentId);

    if (type === 'max') {
      return (
        realObjects.findIndex(
          (x) =>
            x.componentId === selectedComponent?.id &&
            x.cavityId === selectedCavity?.id,
        ) ===
        realObjects.length - 1
      );
    }

    return (
      realObjects.findIndex(
        (x) =>
          x.componentId === selectedComponent?.id &&
          x.cavityId === selectedCavity?.id,
      ) + 1
    );
  };

  // const placeComponentInTray = () => {
  //   history.push({
  //     pathname: `/my-kit-list/${activeKit?.id}/kitmaker/orphan-components/place-in-tray`,
  //     state: { component: showConfirmation.placeInTray },
  //   });
  // };

  return (
    <Container>
      <GoBack title="Components" goBack={customGoBack} />
      {showLinks && (
        <>
          <NavLink to={`/kitcatalogrequestform/${activeKit?.id}`}>
            <FontAwesomeIcon icon={faClipboardList} /> Order this Kit
          </NavLink>
          {customizeKit && (
            <Link onClick={customizeKit}>
              <FontAwesomeIcon icon={faEdit} /> Customize this Kit
            </Link>
          )}
        </>
      )}
      <List
        list={[activeKit]}
        isActive={activeKit}
        title="Active Kit"
        onItemClick={() => null}
        isStatic
      />
      <br />
      {selectedComponent && (
        <>
          <span style={{ padding: '2px 10px', fontWeight: 600 }}>
            Component Info
          </span>
          <Component>
            <span>Name: {selectedComponent.labelName}</span>
            <span>SKU: {selectedComponent.number}</span>
            <span>Description: {selectedComponent.description}</span>
            {!readOnly && !(status === manufacturing || accessLock) && (
              <Span disabled={updateAssemblyOrderLoading}>
                Override layer order:
                {getComponentIndex() === 1 ? (
                  <ArrowButton>
                    <FontAwesomeIcon icon={faArrowUp} />
                  </ArrowButton>
                ) : (
                  <ArrowButtonActive onClick={() => arrowButtonHandler(-1)}>
                    <FontAwesomeIcon icon={faArrowUp} />
                  </ArrowButtonActive>
                )}{' '}
                {getComponentIndex()}
                {getComponentIndex('max') ? (
                  <ArrowButton>
                    <FontAwesomeIcon icon={faArrowDown} />
                  </ArrowButton>
                ) : (
                  <ArrowButtonActive onClick={() => arrowButtonHandler(1)}>
                    <FontAwesomeIcon icon={faArrowDown} />
                  </ArrowButtonActive>
                )}
                {updateAssemblyOrderLoading && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{ marginLeft: '5px' }}
                  />
                )}
              </Span>
            )}
          </Component>
          <br />
        </>
      )}
      {status === manufacturing || accessLock ? (
        helperMessage
      ) : (
        <>
          {selectedComponent ? (
            swapComponentElement
          ) : (
            <>
              {!readOnly && (
                <>
                  <SearchSection
                    components={searchedComponents}
                    assignComponent={handleAddComponent}
                    onSearch={(e) =>
                      onComponentSearch(e.target.value, selectedCavity?.id)
                    }
                    onSearchChange={(e) => onComponentSearch(e.target.value)}
                    placeholder="Add Component..."
                    itemColor="black"
                    offset={310}
                  />
                  <FancyScroll offset={280}>
                    <List
                      list={starred}
                      title={`${
                        !starred.length ? 'No' : ''
                      } Starred Components`}
                      addStarred={handleAddComponent}
                      onItemClick={() => null}
                    />
                  </FancyScroll>
                </>
              )}
            </>
          )}
        </>
      )}

      {showConfirmation.delete && (
        <Confirmation
          confirm={() =>
            handleDeleteComponent(selectedComponent, showConfirmation.count)
          }
          text="Are you sure you want to delete this Component?"
          close={() =>
            setShowConfirmation({ ...showConfirmation, delete: false })
          }
        />
      )}

      {/* {showConfirmation.placeInTray && (
        <Confirmation
          confirm={placeComponentInTray}
          text="This component has been added to the kit but is not visible. Would you like to place this now?"
          close={() =>
            setShowConfirmation({ ...showConfirmation, placeInTray: null })
          }
          cancelLabel="NO, I will postition it later"
          confirmLabel="YES, position now"
        />
      )} */}
    </Container>
  );
};

export default AddComponentToCustomKit;
