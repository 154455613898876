import * as actionTypes from '../../constants/actionTypes';

export const showSnackbar = (type, message) => (dispatch) => {
  dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type, message } });
};

export const clearSnackbar = () => dispatch => {
    dispatch({ type: actionTypes.SNACKBAR_CLEAR });
};

export const showReminder = (title = 'Reminder', message = 'There are unsaved changes') => dispatch => {
    dispatch({ type: actionTypes.SHOW_REMINDER, reminder: { title, message } });
};

export const showNecessaryFields = (fields) => dispatch => {
    dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'warning', message: {
        title: 'You need to enter some necessary data:',
        fields
    } } });
};

export const hideReminder = () => dispatch => dispatch({ type: actionTypes.HIDE_REMINDER });
