import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTabContent,
  MDBTabPane,
} from 'mdbreact';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../components/loaders/Loader';
import {
  faBars,
  faCheckCircle,
  faPlus,
  faSearch,
  faShoppingBasket,
  faThLarge,
} from '@fortawesome/free-solid-svg-icons';
import {
  addComponentToCart,
  deleteComponentFromCart,
  getComponentsFromCart,
  getCustomKitById,
  loadComponentCatalog,
  loadComponentCategories,
  loadSavedKits,
} from '../../redux/actions/componentCategories';
import ComponentCategoriesPartTypeFilters from './ComponentCategories/ComponentCategoriesPartTypeFilters';
import PaginationNew from '../../components/Pagination/PaginationNew';
import Tree from '../Tree';
import notfound from '../../shared/img/defaultlist.png';
import { flatten } from '../../utils/categoryMapping';
import { isNumber } from 'lodash-es';

const ComponentCagegoriesNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    partTypes,
    components,
    loading,
    componentsLoading,
    categories,
    totalPages,
    savedKits,
    cartComponents,
  } = useSelector((state) => ({
    categories: state.componentCatalog.categories,
    partTypes: state.componentCatalog.partTypes,
    loading: state.componentCatalog.loading,
    components: state.componentCatalog.list,
    componentsLoading: state.componentCatalog.componentsLoading,
    totalPages: state.componentCatalog.totalPages,
    savedKits: state.componentCatalog.savedKits,
    cartComponents: state.componentCatalog.cartComponents,
  }));

  const [catalog, setCatalog] = useState({
    categories,
    partTypes,
    components,
    search: '',
    page: 0,
    pagesVisible: 7,
    isGridView: true,
    popular: [],
    savedKits: [],
    totalPages: 0,
    activeItem: 1,
    categoryId: null,
    partTypeId: null,
    dragStart: false,
    draggedComponent: null,
  });

  const [lastLoadedPage, setLastLoadedPage] = useState(0);

  const { id, type, page, requestType } = useParams(); // requestType enum {0: category, 1: partType}
  const { search } = useLocation();
  const searchParam = search.split('=')[1];

  useEffect(() => {
    dispatch(loadComponentCategories());
    dispatch(loadSavedKits());
    dispatch(getComponentsFromCart());
    setCatalog((prev) => ({ ...prev, search: searchParam, page: +page }));
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    setCatalog({
      ...catalog,
      categories,
      popular: partTypes
        .filter((partType) => partType.isFavorite)
        .concat(categories.filter((c) => c.isFavorite)),
      partTypes,
      savedKits,
    });
    // eslint-disable-next-line
  }, [partTypes, categories, savedKits]);

  useEffect(() => {
    setCatalog({ ...catalog, components, totalPages, search: searchParam });
    // eslint-disable-next-line
  }, [components]);

  useEffect(() => {
    let params = {
      page,
      search: searchParam,
    };

    if (+id) {
      params = {
        ...params,
        ...(+requestType ? { partTypeId: +id } : { categoryId: +id }),
      };

      setCatalog((prev) => ({ ...prev, search: searchParam }));
    }

    dispatch(loadComponentCatalog(params));
  }, [id, requestType, page, searchParam, dispatch]);

  const handleSelect = (selectedItem, type) => {
    const popularUncheck = catalog.popular.map((item) => ({
      ...item,
      checked: false,
      ...(item.children && {
        children: item.children.map((ch) => ({
          ...ch,
          checked: ch.id === selectedItem.id && !ch.checked,
        })),
      }),
    }));

    const categoriesUnckeck = catalog.categories.map((item) => ({
      ...item,
      checked: false,
      ...(item.children && {
        children: item.children.map((ch) => ({
          ...ch,
          checked: ch.id === selectedItem.id && !ch.checked,
        })),
      }),
    }));

    const temp = catalog[type].map((item) => ({
      ...item,
      checked:
        selectedItem.id === item.id && selectedItem.id !== +id && !item.checked,
      ...(item.children && {
        children: item.children.map((ch) => ({
          ...ch,
          checked:
            ch.id === selectedItem.id && selectedItem.id !== +id && !ch.checked,
        })),
      }),
    }));

    const checkedId =
      temp.find((t) => t.checked)?.id ||
      (temp.findIndex((s) => s.children?.find((ch) => ch.checked)) > -1
        ? temp[
            temp.findIndex((s) => s.children?.find((ch) => ch.checked))
          ]?.children?.find((ch) => ch.checked)?.id
        : '');

    setCatalog({
      ...catalog,
      [type]: temp,
      page: 0,
      ...(selectedItem.isPartType
        ? { partTypeId: checkedId, categoryId: null }
        : { categoryId: checkedId, partTypeId: null }),
      ...(type === 'popular'
        ? { categories: categoriesUnckeck }
        : { popular: popularUncheck }),
    });

    saveParams(
      checkedId || null,
      type,
      0,
      searchParam || '',
      false,
      !!selectedItem.isPartType,
    );
  };

  const makeFavorite = (component, isDelete) => {
    const temp = catalog.components.map((c) => ({
      ...c,
      isStarred:
        c.id === component.id ? (c.isStarred ? false : true) : c.isStarred,
    }));
    setCatalog({ ...catalog, components: temp });

    if (isDelete) {
      dispatch(deleteComponentFromCart(component.id));
      return;
    }

    if (catalog.components.find((t) => t.id === component.id)?.isStarred) {
      dispatch(deleteComponentFromCart(component.id));
    } else {
      dispatch(addComponentToCart(component.id));
    }
  };

  const handleSearch = (e) => {
    const { partTypeId, categoryId, search } = catalog;

    const id = partTypeId ?? categoryId;

    if (e === 'search') {
      dispatch(
        loadComponentCatalog({ partTypeId, page: 0, categoryId, search }),
      );
      setCatalog({ ...catalog, search, page: 0 });
      saveParams(id, type, 0, search, true, Number.isInteger(+partTypeId));
      return;
    }

    if (e.key === 'Enter') {
      saveParams(id, type, 0, e.target.value, true, isNumber(partTypeId));
    }

    setCatalog({ ...catalog, search: e.target.value, page: 0 });
  };

  const handlePagination = (page) => {
    setCatalog({ ...catalog, page });
    saveParams(
      id,
      type,
      page,
      searchParam,
      false,
      isNumber(catalog.partTypeId),
    );
  };

  const onDragStart = (component) => {
    setCatalog({ ...catalog, dragStart: true, draggedComponent: component });
  };

  const onDrop = (e, kit) => {
    e.target.style.border = 'none';
    dispatch(getCustomKitById(kit.id, catalog.draggedComponent));
  };

  const saveParams = (id, type, page, search, isReplace, isPartType) => {
    const requestType = isPartType ? 1 : 0;

    setLastLoadedPage(+page);
    history[isReplace ? 'replace' : 'push']({
      pathname: `/components/categories/${
        id ?? null
      }/requestType/${requestType}/type/${type}/page/${page}`,
      search: search ? `?search=${search}` : '',
    });
  };

  const loadCatalog = useCallback(
    (search, page) => {
      const { partTypeId, categoryId } = catalog;
      setCatalog({
        ...catalog,
        page: +page,
        search: search ?? '',
        totalPages,
      });

      dispatch(
        loadComponentCatalog({
          partTypeId,
          categoryId,
          search: search || '',
          page,
        }),
      );
    },
    [catalog, dispatch, totalPages],
  );

  useEffect(() => {
    if (lastLoadedPage !== +page && page !== undefined) {
      loadCatalog(searchParam, page);
    }
    // eslint-disable-next-line
  }, [lastLoadedPage, page]);
  if (loading) return <Loader />;

  return (
    <div className="main-layout">
      <div className="content-area">
        <MDBContainer fluid>
          <MDBRow>
            <MDBCol
              id="onTray"
              lg="12"
              md="12 "
              sm="6"
              xs="12"
              className="mt20-xs"
            >
              <h5>Component Catalog</h5>
              <MDBRow>
                <MDBCol lg="3" md="6" sm="6" xs="12" className="mt20-xs">
                  <ComponentCategoriesPartTypeFilters
                    partTypes={catalog.popular}
                    selectPartType={handleSelect}
                    id={type === 'popular' ? +id : null}
                  />
                  <MDBCard>
                    <MDBCardBody className="setHeight p-2 scrlboxTree">
                      <MDBCardTitle>All Filters</MDBCardTitle>
                      <nav className="nav" role="navigation" id="cctreefilter">
                        <Tree
                          isFromParent="ComponentCatalog"
                          selectedParentNode={catalog.categories}
                          onSelect={handleSelect}
                          id={type === 'categories' ? +id : null}
                        />
                      </nav>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
                <MDBCol
                  lg="9"
                  md="6"
                  sm="6"
                  xs="12"
                  className="pl0 mt30-xs pt15-xs pl15-md"
                >
                  <h6>
                    <p>
                      <FontAwesomeIcon
                        icon={faThLarge}
                        style={{
                          color: catalog.isGridView ? '#61a431' : '#959595',
                        }}
                        onClick={() =>
                          setCatalog({ ...catalog, isGridView: true })
                        }
                      />
                      <FontAwesomeIcon
                        icon={faBars}
                        style={{
                          color: !catalog.isGridView ? '#61a431' : '#959595',
                        }}
                        onClick={() =>
                          setCatalog({ ...catalog, isGridView: false })
                        }
                      />
                    </p>
                  </h6>
                  <div className="input-group form-sm form-2">
                    <input
                      className="form-control my-0 py-1 red-border"
                      type="text"
                      placeholder="Search"
                      aria-label="Search"
                      id="searchText"
                      name="textSearch"
                      value={catalog.search}
                      onChange={handleSearch}
                      onKeyPress={handleSearch}
                    />
                    <div
                      className="input-group-append"
                      onClick={() => handleSearch('search')}
                    >
                      <span className="input-group-text white" id="basic-text1">
                        <FontAwesomeIcon icon={faSearch} />
                      </span>
                    </div>
                  </div>
                  <MDBRow>
                    {componentsLoading ? (
                      <Loader />
                    ) : !catalog.components.length ? (
                      <p style={{ margin: '0 auto', padding: 20 }}>
                        No results found
                      </p>
                    ) : (
                      catalog.components.map((component) => {
                        if (catalog.isGridView) {
                          return (
                            <MDBCol
                              xl="4"
                              lg="6"
                              md="12"
                              className="onPrd"
                              draggable
                              onDragStart={() => onDragStart(component)}
                              key={'componentCategory' + component.id}
                            >
                              <MDBCard
                                className={
                                  catalog.isGridView
                                    ? 'product-area'
                                    : 'product-area product-area-list'
                                }
                              >
                                <div
                                  style={{ padding: '15px' }}
                                  onClick={() => makeFavorite(component)}
                                  id="myfavorite"
                                  className={
                                    component.isStarred
                                      ? 'fa fa-star active'
                                      : 'fa fa-star'
                                  }
                                  aria-hidden="true"
                                ></div>
                                <NavLink
                                  to={'/components/' + component.id}
                                  style={{
                                    background:
                                      component.imageUrl ||
                                      flatten(catalog.categories).find(
                                        (c) => c.id === component.category.id,
                                      )?.photoUrl
                                        ? 'white'
                                        : '#F6F6F6',
                                    height: 300,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <MDBCardImage
                                    style={{ maxHeight: '300px' }}
                                    className="img-fluid imgh"
                                    src={
                                      component.imageUrl ||
                                      flatten(catalog.categories).find(
                                        (c) => c.id === component.category.id,
                                      )?.photoUrl ||
                                      notfound
                                    }
                                  />
                                </NavLink>
                                <MDBCardBody>
                                  <NavLink to={'/components/' + component.id}>
                                    <MDBCardTitle>
                                      {component.number}
                                    </MDBCardTitle>
                                    <MDBCardText className="anchorColor">
                                      {component.description}
                                    </MDBCardText>
                                  </NavLink>
                                  <MDBCardText></MDBCardText>
                                  <MDBBtn
                                    onClick={() =>
                                      history.push(
                                        `/kits?componentId=${component.id}`,
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    Included in kits
                                  </MDBBtn>
                                  <MDBBtn
                                    onClick={() =>
                                      setCatalog({
                                        ...catalog,
                                        dragStart: !catalog.dragStart,
                                      })
                                    }
                                  >
                                    <FontAwesomeIcon icon={faPlus} />
                                    Add to new kit
                                  </MDBBtn>
                                </MDBCardBody>
                              </MDBCard>
                            </MDBCol>
                          );
                        } else {
                          return (
                            <MDBCol
                              lg="12"
                              key={'currentTodos_' + component.id}
                            >
                              <MDBCard className="product-area product-area-list">
                                <MDBRow
                                  draggable
                                  onDrag={() => onDragStart(component)}
                                >
                                  <MDBCol
                                    xl="3"
                                    lg="3"
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <div
                                      style={{ margin: '10px 20px' }}
                                      onClick={() => makeFavorite(component)}
                                      id="myfavorite"
                                      className={
                                        component.isStarred
                                          ? 'fa fa-star active'
                                          : 'fa fa-star'
                                      }
                                      aria-hidden="true"
                                    ></div>
                                    <NavLink
                                      to={'/components/' + component.id}
                                      style={{ width: '100%' }}
                                    >
                                      <MDBCardImage
                                        style={{ maxHeight: '130px' }}
                                        className="img-fluid imgh"
                                        src={
                                          component.imageUrl ||
                                          flatten(catalog.categories).find(
                                            (c) =>
                                              c.id === component.category.id,
                                          )?.photoUrl ||
                                          notfound
                                        }
                                      />
                                    </NavLink>
                                  </MDBCol>
                                  <MDBCol xl="5" lg="5">
                                    <MDBCardBody>
                                      <NavLink
                                        to={'/components/' + component.id}
                                      >
                                        <MDBCardTitle>
                                          {component.number}
                                        </MDBCardTitle>
                                        <MDBCardText className="anchorColor">
                                          {component.description}
                                        </MDBCardText>
                                      </NavLink>
                                      <MDBCardText></MDBCardText>
                                    </MDBCardBody>
                                  </MDBCol>
                                  <MDBCol xl="4" lg="4">
                                    <div className="list-btn-area">
                                      <MDBBtn
                                        onClick={() =>
                                          history.push(
                                            `/kits?componentId=${component.id}`,
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon icon={faCheckCircle} />
                                        Included in kits
                                      </MDBBtn>
                                      <MDBBtn
                                        onClick={() =>
                                          setCatalog({
                                            ...catalog,
                                            dragStart: !catalog.dragStart,
                                          })
                                        }
                                      >
                                        <FontAwesomeIcon icon={faPlus} />
                                        Add to new kit
                                      </MDBBtn>
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                              </MDBCard>
                            </MDBCol>
                          );
                        }
                      })
                    )}
                  </MDBRow>
                  <MDBRow>
                    <MDBCol className="text-center" id="ccpagination">
                      {!componentsLoading && totalPages > 1 && (
                        <PaginationNew
                          totalPages={totalPages}
                          activePage={catalog.page || +page || 0}
                          onChange={handlePagination}
                          pagesVisible={7}
                        />
                      )}
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol
              lg="3"
              md="6"
              sm="6"
              xs="12"
              className="mt20-xs pl-lg-0 pl-xl-0"
            >
              <div className="rytsidetray">
                <FontAwesomeIcon
                  icon={faShoppingBasket}
                  className="ryttray"
                  onClick={() =>
                    setCatalog({ ...catalog, dragStart: !catalog.dragStart })
                  }
                  style={{ right: catalog.dragStart ? '21%' : 0 }}
                />
                {catalog.dragStart && (
                  <div
                    id="mySidenav"
                    className="sidetray kitmaker pt-2 "
                    style={{
                      position: 'fixed',
                      width: '21%',
                      top: 65,
                      right: 0,
                      display: 'inline',
                    }}
                  >
                    <div id="authentication-layout">
                      <div className="btn-section mtb2 tab-area mt-0">
                        <MDBBtn
                          className="m-0 mb20-xs btn-whitee"
                          active={catalog.activeItem === 1}
                          onClick={() =>
                            setCatalog({ ...catalog, activeItem: 1 })
                          }
                        >
                          Custom kits
                        </MDBBtn>
                        <MDBBtn
                          className="m-0  mb20-xs btn-outline-indigo notwhite"
                          active={catalog.activeItem === 2}
                          onClick={() =>
                            setCatalog({ ...catalog, activeItem: 2 })
                          }
                        >
                          Components
                        </MDBBtn>
                      </div>
                      <MDBTabContent activeItem={catalog.activeItem}>
                        <MDBTabPane tabId={1} role="tabpanel">
                          <div className="tbl-scrl">
                            {catalog.savedKits.map((kit) => (
                              <div
                                id="customkits"
                                className="data-list"
                                onDrop={(e) => onDrop(e, kit)}
                                onDragOver={(e) => e.preventDefault()}
                                onDragEnter={(e) =>
                                  (e.target.style.border = '3px dashed #1d265b')
                                }
                                onDragLeave={(e) =>
                                  (e.target.style.border = 'none')
                                }
                                onClick={() =>
                                  history.push(
                                    `/kitmakercomponents?KitFromBase=${null}&kit=${
                                      kit.name
                                    }&kitId=${
                                      kit.id
                                    }&orederedKitId=${null}&isFavourite=0`,
                                  )
                                }
                                key={'componentCategoryKit' + kit.id}
                              >
                                <p
                                  className="kitName anchorColor"
                                  id="customKitName"
                                >
                                  {kit.name}
                                  <span></span>
                                </p>
                                <a
                                  href="#no"
                                  className="kitCount"
                                  id="customKitId"
                                >
                                  Components Count: {kit.componentsCount}
                                </a>
                                <br />
                              </div>
                            ))}
                          </div>
                          <br />
                          <p>
                            Drag component from the list to add to an existing
                            Saved kit
                          </p>
                          <br />
                          <MDBBtn
                            className="fullgbtn"
                            disabled={catalog.savedKits.length === 0}
                            onClick={() => history.push(`/kitmaker`)}
                          >
                            CREATE NEW KIT
                          </MDBBtn>
                        </MDBTabPane>
                        <MDBTabPane tabId={2} role="tabpanel">
                          <div className="tbl-scrl">
                            {cartComponents.length === 0 ? (
                              <p>No starred component available right now</p>
                            ) : (
                              cartComponents.map((cartComponent) => (
                                <div
                                  id="cartCustom"
                                  className="data-list"
                                  key={'cartComponent_' + cartComponent.number}
                                >
                                  <i
                                    className="fa fa-window-close"
                                    aria-hidden="true"
                                    onClick={() =>
                                      makeFavorite(cartComponent, true)
                                    }
                                  />
                                  <a
                                    href="#no"
                                    className="kitCount"
                                    id="customKitId"
                                  >
                                    <p>SKU: {cartComponent.number}</p>
                                  </a>
                                  <p
                                    className="kitName anchorColor"
                                    id="customKitName"
                                  >
                                    {cartComponent.description}
                                    <span></span>
                                  </p>
                                </div>
                              ))
                            )}
                          </div>
                          <MDBBtn
                            className="fullgbtn"
                            disabled={cartComponents.length === 0}
                            onClick={() =>
                              history.push(`/kitmaker?isFavorite=1`)
                            }
                          >
                            CREATE NEW KIT
                          </MDBBtn>
                          <MDBBtn
                            className="fullgbtn"
                            disabled={cartComponents.length === 0}
                            onClick={() =>
                              history.push(
                                `/kit-catalog?applyStarredComponents=1`,
                              )
                            }
                          >
                            JUST LIKE
                          </MDBBtn>
                        </MDBTabPane>
                      </MDBTabContent>
                    </div>
                  </div>
                )}
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </div>
  );
};

export default ComponentCagegoriesNew;
