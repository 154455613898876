import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { promiseMiddleware, localStorageMiddleware } from './middleware';
import thunk from 'redux-thunk';
import reducer from './redux/reducer';

//import createHistory from 'history/createBrowserHistory';
// let hist = require("history")
// export const history = hist.createBrowserHistory();// createHistory();

// Build the middleware for intercepting and dispatching navigation actions
// const myRouterMiddleware = routerMiddleware(history);

const getMiddleware = () => {
  // if (process.env.NODE_ENV === 'production') {
  // return applyMiddleware(myRouterMiddleware, promiseMiddleware, localStorageMiddleware,trackMixpanel);
  return applyMiddleware(promiseMiddleware, localStorageMiddleware, thunk);

  // } else {
  // Enable additional logging in non-production environments.
  // return applyMiddleware(myRouterMiddleware, promiseMiddleware, localStorageMiddleware,trackMixpanel, createLogger())
  // return applyMiddleware(promiseMiddleware, localStorageMiddleware, thunk)

  // }
};

export const store = createStore(
  reducer, composeWithDevTools(getMiddleware()));
