import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';

export const generatePdf = (params) => {
  const {
    currentPagePdf,
    setPdfDocument,
    setCurrentPagePdf,
    setSelectedPage,
    setNotesEditable,
    setPdfLoader,
    isSales,
    pages,
    setRunPdfGeneration,
    name,
    pdfDocument,
    canvasElemRef,
    versionValue,
    imageLoading,
    selectedPage,
  } = params;
  if (currentPagePdf === null) {
    const doc = new jspdf({
      unit: 'px',
      orientation: 'landscape',
    });

    doc.deletePage(1);
    setPdfDocument(doc);
    setCurrentPagePdf(pages.length - 1);
    setSelectedPage(null);
    setNotesEditable(false);
    setPdfLoader(true);
  } else {
    if (currentPagePdf >= 0) {
      setSelectedPage(pages[currentPagePdf]);
      if (!imageLoading) {
        if (selectedPage?.id === pages[currentPagePdf].id) {
          setPdfLoader(true);

          const rows = [
            {
              content: name.split('_')[0],
              styles: { lineWidth: { right: 1, left: 1, bottom: 1 } },
            },
            {
              content: versionValue || '',
              styles: { lineWidth: { right: 1, left: 1, bottom: 1 } },
            },
            {
              content: 'Component part numbers in ',
              styles: {
                lineWidth: { right: 0, bottom: 1 },
                halign: 'right',
              },
            },
            {
              content: 'blue',
              styles: {
                textColor: 'blue',
                lineWidth: { left: 0, right: 0, bottom: 1 },
                cellWidth: 40,
              },
            },
            {
              content: ' font are substitute',
              styles: { halign: 'left', lineWidth: { left: 0, bottom: 1 } },
            },
            {
              content: `Page ${pages.length - currentPagePdf} of ${
                pages.length
              }`,
              styles: { lineWidth: { right: 1, left: 1, bottom: 1 } },
            },
          ];

          if (isSales) {
            rows.push({
              content: 'Prototype request',
              styles: { lineWidth: { right: 1, left: 1, bottom: 1 } },
            });
          }

          const headers = [
            {
              content: 'Part #',
              colSpan: 1,
            },
            { content: 'Graphic Revision', colSpan: 1 },
            { content: 'Legend', colSpan: 3 },
            { content: 'Page', colSpan: 1 },
          ];

          if (isSales) {
            headers.push({ content: '', colSpan: 1 });
          }

          // for huge size divider images
          const delay = Number.isInteger(pages[currentPagePdf].image)
            ? 2000
            : 500;

          setTimeout(() => {
            pdfDocument.addPage([
              canvasElemRef.height + 200,
              canvasElemRef.width + 200,
            ]);
            pdfDocument.setPage(pages.length - currentPagePdf);
            pdfDocument.autoTable({
              head: [headers],
              body: [rows],
              bodyStyles: {
                fontSize: 22,
                halign: 'center',
                valign: 'middle',
              },
              headStyles: {
                fontSize: 22,
                halign: 'center',
                fillColor: [29, 38, 91],
              },
              theme: 'grid',
              pageBreak: 'always',
            });

            pdfDocument.addImage(
              canvasElemRef,
              'JPEG',
              120,
              120,
              canvasElemRef.width,
              canvasElemRef.height,
            );
            setTimeout(() => setCurrentPagePdf(currentPagePdf - 1), delay);
          }, delay);
        }
      } else {
        setPdfLoader(false);
      }
    } else {
      if (pdfDocument) {
        let count = pdfDocument.internal.getNumberOfPages() - 1;

        pdfDocument.deletePage(1);
        while (count > pages.length) {
          pdfDocument.deletePage(count);
          count--;
        }

        const clearedName = name.split('_')[0];

        pdfDocument.save(`${clearedName}_${versionValue}.pdf`);
      }

      setTimeout(() => {
        setPdfLoader(false);
        setPdfDocument(null);
        setSelectedPage(null);
        setCurrentPagePdf(null);
        setRunPdfGeneration(false);
      }, 2000);
    }
  }
};

export const generateTrayPDF = async ({
  setKitPdfGenerationProcess,
  canvasElemRef,
  listWrapperRef,
  kitId,
  isSales,
  fileName = '_trayInfo.pdf',
  offsetHeight = 300,
  offsetWidth = 100,
  imageOffsetX = 50,
  imageOffsetY = 50,
}) => {
  setKitPdfGenerationProcess(true);
  setTimeout(async () => {
    const canvases = [
      ...(canvasElemRef ? [canvasElemRef] : []),
      await html2canvas(listWrapperRef.current, {
        useCORS: true,
        scale: 1,
      }),
    ];

    const doc = new jspdf({ unit: 'px' });
    doc.deletePage(1);
    canvases.forEach((canvas, i) => {
      doc.addPage(
        [canvas.height + offsetHeight, canvas.width + offsetWidth],
        canvas.height / canvas.width > 1 ? 'p' : 'l',
      );
      doc.setPage(i + 1);

      if (isSales && i === 0) {
        doc.setFontSize(36);
        doc.text(canvas.width / 2, 30, 'Prototype request');
      }

      doc.addImage(
        canvas,
        'JPEG',
        imageOffsetX,
        imageOffsetY,
        canvas.width,
        canvas.height,
      );
    });

    await doc.save(kitId + fileName, { returnPromise: true });
    setKitPdfGenerationProcess(false);
  }, 100);
};
