import {
        CATEGORIES
} from '../../constants/actionTypes';

  const defaultState = {
    categories:null
  };
  export default (state = defaultState, action) => {
    switch (action.type) {
        case CATEGORIES: 
    return {      
      ...state,
      categories: action.error ? null : action.payload
    }; 
    default:
        return state;
    }

  }