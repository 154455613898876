import agent from "./agent";

import { ASYNC_START, ASYNC_END, LOGIN, LOGOUT, CATEGORIES, KITFORMDATA, KITCATEGORIES, KITCATALOGADDCOMPONENTS, PARTTYPE, POPULARCATEGORIES, POPULARCATEGORIESKIT } from "./constants/actionTypes";
let formdata = {
    name: "",
    regionalManager: "",
    contactTitle: "",
    contactName: "",
    salesRep: "",
    accountNumber: "",
    facility: "",
    isNewBusiness: "",
    competitor: "",
    currentPrice: "",
    requestedPrice: "",
    estUsage: "",
    purchaseType: "",
    jitName: "",
    productFamilyId: ""
}
// import mixpanel from "mixpanel-browser";

// mixpanel.init("90f247f5aa6aa3f0faca8d214b207286");
const promiseMiddleware = store => next => action => {
    if (!action) {
        window.location.reload();
    } else if (isPromise(action.payload)) {
        store.dispatch({ type: ASYNC_START, subtype: action.type });

        const currentView = store.getState().viewChangeCounter;
        const skipTracking = action.skipTracking;

        action.payload.then(
            res => {
                const currentState = store.getState();
                if (!skipTracking && currentState.viewChangeCounter !== currentView) {
                    return;
                }

                action.payload = res;
                store.dispatch({ type: ASYNC_END, promise: action.payload });
                store.dispatch(action);
            },
            error => {
                const currentState = store.getState();
                if (!skipTracking && currentState.viewChangeCounter !== currentView) {
                    return;
                }

                action.error = true;
                action.payload = error.response.text;
                if (!action.skipTracking) {
                    store.dispatch({ type: ASYNC_END, promise: action.payload });
                }
                store.dispatch(action);
            }
        );

        return;
    }

    next(action);
};

const localStorageMiddleware = store => next => action => {
    if (!action) {
        return;
    }
    if (action.type === LOGIN) {
        if (!action.error) {
            window.localStorage.setItem("jwt", action.payload.accessToken);
            window.localStorage.setItem(
                "loggeduser",
                JSON.stringify(action.payload.user)
            );

            window.localStorage.setItem("tokenExpiry", action.payload.expires);
            agent.setToken(action.payload.accessToken);
            if (action.payload.expires < Date.now() / 1000) {
                next(action);
                window.localStorage.setItem("jwt", action.payload.accessToken);
                agent.setToken(action.payload.accessToken);
            }
        }
    } else if (action.type === LOGOUT) {
        window.localStorage.removeItem("jwt");
        agent.setToken(null);
        window.sessionStorage.removeItem("kitName");
        window.sessionStorage.removeItem("regionalManager");
        window.sessionStorage.removeItem("accountNumber");
        window.sessionStorage.removeItem("contactTitle");
        window.sessionStorage.removeItem("contactName");
        window.sessionStorage.removeItem("salesRep");
        window.sessionStorage.removeItem("accountNumber");
        window.sessionStorage.removeItem("facilityName");
        window.sessionStorage.removeItem("facilityStreet");
        window.sessionStorage.removeItem("facilitySuite");
        window.sessionStorage.removeItem("facilityCity");
        window.sessionStorage.removeItem("facilityZipCode");
        window.sessionStorage.removeItem("isNewBusiness");
        window.sessionStorage.removeItem("competitor");
        window.sessionStorage.removeItem("currentPrice");
        window.sessionStorage.removeItem("requestedPrice");
        window.sessionStorage.removeItem("estUsage");
        window.sessionStorage.removeItem("purchaseType");
        window.sessionStorage.removeItem("jitName");
        window.sessionStorage.removeItem("productFamilyId");
        window.sessionStorage.setItem("PopularCategory", null);
        window.sessionStorage.setItem("ComponentCategory", null);
        window.sessionStorage.setItem("PartType", null);
        window.sessionStorage.setItem("PopularCategoryKit", null);
        window.sessionStorage.setItem("kitCategory", null);
    }
    else if (action.type === CATEGORIES) {
        window.sessionStorage.setItem("ComponentCategory", JSON.stringify(action.payload));
    }

    else if (action.type === POPULARCATEGORIES) {
        window.sessionStorage.setItem("PopularCategory", JSON.stringify(action.payload));
    }

    else if (action.type === POPULARCATEGORIESKIT) {
        window.sessionStorage.setItem("PopularCategoryKit", JSON.stringify(action.payload));
    }

    else if (action.type === PARTTYPE) {
        window.sessionStorage.setItem("PartType", JSON.stringify(action.payload));
    }
    else if (action.type === KITCATEGORIES) {
        window.sessionStorage.setItem("kitCategory", JSON.stringify(action.payload));
    }

    else if (action.type === KITCATALOGADDCOMPONENTS) {
        window.sessionStorage.setItem("kitCatalogaddcomponents", JSON.stringify(action.payload));
    }

    else if (action.type === KITFORMDATA) {
        switch (action.payload.name) {
            case "kitId":
                formdata.name = action.payload.value;
                window.sessionStorage.setItem("kitId", action.payload.value);
                break;
            case "kitName":
                formdata.name = action.payload.value;
                window.sessionStorage.setItem("kitName", action.payload.value);
                break;

            case "regionalManager":
                formdata.regionalManager = action.payload.value;
                window.sessionStorage.setItem("regionalManager", action.payload.value);
                break;

            case "contactTitle":
                formdata.contactTitle = action.payload.value;
                window.sessionStorage.setItem("contactTitle", action.payload.value);
                break;

            case "contactName":
                formdata.contactName = action.payload.value;
                window.sessionStorage.setItem("contactName", action.payload.value);
                break;

            case "salesRep":
                formdata.salesRep = action.payload.value;
                window.sessionStorage.setItem("salesRep", action.payload.value);
                break;

            case "accountNumber":
                formdata.accountNumber = action.payload.value;
                window.sessionStorage.setItem("accountNumber", action.payload.value);
                break;

            case "facilityName":
                formdata.facility = action.payload.value;
                window.sessionStorage.setItem("facilityName", action.payload.value);
                break;

            case "facilityStreet":
                formdata.facility = action.payload.value;
                window.sessionStorage.setItem("facilityStreet", action.payload.value);
                break;
            case "facilitySuite":
                formdata.facility = action.payload.value;
                window.sessionStorage.setItem("facilitySuite", action.payload.value);
                break;
            case "facilityCity":
                formdata.facility = action.payload.value;
                window.sessionStorage.setItem("facilityCity", action.payload.value);
                break;
            case "facilityZipCode":
                formdata.facility = action.payload.value;
                window.sessionStorage.setItem("facilityZipCode", action.payload.value);
                break;
            case "isNewBusiness":
                formdata.isNewBusiness = action.payload.value;
                window.sessionStorage.setItem("isNewBusiness", action.payload.value);
                break;

            case "competitor":
                formdata.competitor = action.payload.value;
                window.sessionStorage.setItem("competitor", action.payload.value);
                break;

            case "currentPrice":
                formdata.currentPrice = action.payload.value;
                window.sessionStorage.setItem("currentPrice", action.payload.value);
                break;

            case "requestedPrice":
                formdata.requestedPrice = action.payload.value;
                window.sessionStorage.setItem("requestedPrice", action.payload.value);
                break;

            case "estUsage":
                formdata.estUsage = action.payload.value;
                window.sessionStorage.setItem("estUsage", action.payload.value);
                break;

            case "purchaseType":
                formdata.purchaseType = action.payload.value;
                window.sessionStorage.setItem("purchaseType", action.payload.value);
                break;

            case "jitName":
                formdata.jitName = action.payload.value;
                window.sessionStorage.setItem("jitName", action.payload.value);
                break;

            case "productFamilyId":
                formdata.productFamilyId = action.payload.value;
                window.sessionStorage.setItem("productFamilyId", action.payload.value);
                break;

            default:
                break;
        }

    }

    next(action);
};

function isPromise(v) {
    return v && typeof v.then === "function";
}

// const trackMixpanel = store => next => action => {
//   const env = process.env.NODE_ENV;

//   // if (env !== "production") {
//   //   return next(action);
//   // }


//   if (action.meta && action.meta.track && action.meta.track.ignore) {
//     return next(action);
//   } else {
//     //const auth = store.getState();

//     if (!window.location.href.includes("login")) {
//       const auth = window.sessionStorage.getItem("loggeduser");
//       let user = JSON.parse(auth);
//       mixpanel.identify(user.userName);

//       mixpanel.people.set({
//         $name: user.firstName,

//         $email: user.userName
//       });

//       const options = action.payload ? action.payload : {};

//       mixpanel.track("ComponentRequest", options);
//     }

//     return next(action);
//   }
// };

export { promiseMiddleware, localStorageMiddleware };
