import {
  APP_LOAD,
  REDIRECT,
  LOGOUT,
  ARTICLE_SUBMITTED,
  SETTINGS_SAVED,
  LOGIN,
  LOGIN_FAILED,
  REGISTER,
  DELETE_ARTICLE,
  ARTICLE_PAGE_UNLOADED,
  EDITOR_PAGE_UNLOADED,
  HOME_PAGE_UNLOADED,
  PROFILE_PAGE_UNLOADED,
  PROFILE_FAVORITES_PAGE_UNLOADED,
  SETTINGS_PAGE_UNLOADED,
  LOGIN_PAGE_UNLOADED,
  REGISTER_PAGE_UNLOADED,  
} from '../../constants/actionTypes';

const defaultState = {
  appName: 'Teleflex',
  token: null,
  viewChangeCounter: 0,
  categories:null,
  menuActive:null
};

export default (state = defaultState, action) => {
  
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        currentUser: action.payload ? action.payload.user : null
      };
    case REDIRECT:
      return { ...state, redirectTo: action.redirectTo };
    case LOGOUT:
      return { ...state, redirectTo: '/login', token: null, currentUser: null };
    case ARTICLE_SUBMITTED:
      const redirectUrl = `/article/${action.payload.article.slug}`;
      return { ...state, redirectTo: redirectUrl };
    case SETTINGS_SAVED:
      return {
        ...state,
        redirectTo: action.error ? null : '/',
        currentUser: action.error ? null : action.payload.user
      };
    case LOGIN:
    {
      
    return {
      ...state,
      redirectTo: action.error ? 'loginerror' : 'components',
      token: action.error ? null : action.payload.accessToken,
      currentUser: action.error ? null : action.payload.user.userName
    };
    
  }
  case LOGIN_FAILED:
  {
    
  return {
    ...state,
    redirectTo: action.error ? 'loginerror' : 'login',
    //token: action.error ? null : action.payload.accessToken,
    //currentUser: action.error ? null : action.payload.user.userName
  };
  
}
    case REGISTER:
      return {
        ...state,
        redirectTo: action.error ? null : 'login',
        //token: action.error ? null : action.payload.accessToken,
        //currentUser: action.error ? null : action.payload.user.userName
      };
    case DELETE_ARTICLE:
      return { ...state, redirectTo: '/' };
    case ARTICLE_PAGE_UNLOADED:
    case EDITOR_PAGE_UNLOADED:
    case HOME_PAGE_UNLOADED:
    case PROFILE_PAGE_UNLOADED:
    case PROFILE_FAVORITES_PAGE_UNLOADED:
    case SETTINGS_PAGE_UNLOADED:
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
      return { ...state, viewChangeCounter: state.viewChangeCounter + 1 };
    default:
      return state;
  }
};
