import * as actionTypes from '../../constants/actionTypes';
import { getToken } from '../../utils/getToken';
import axios from '../axiosMiddleware/axios';
import * as _ from 'lodash';
import * as origAxios from 'axios';
import { loadKitInfoFromKitDesign, loadKitListFromKitDesign } from './kits';
import { showSnackbar } from './snackbar';
import { corsPatch } from '../../constants/constants';
import centroid from 'polygon-centroid';

export const loadKitTrays = (isLoaded) => async dispatch => {
    dispatch({ type: actionTypes.GET_KIT_TRAYS_START, loading: !isLoaded });

    try {
        const res = await axios.get('/api/cms/trays', { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.GET_KIT_TRAYS_SUCCESS, trays: res.data });
    } catch (err) {
        dispatch(showSnackbar('error', 'Load kit tray list error'));
        dispatch({ type: actionTypes.GET_KIT_TRAYS_FAIL, error: err });
    }
};

export const createKitTray = (tray) => async dispatch => {
    dispatch({ type: actionTypes.CREATE_KIT_TRAY_START });

    const formaData = new FormData();

    formaData.append('Name', tray.name);
    formaData.append('Number', tray.number);
    formaData.append('image', tray.file);
    tray.details && formaData.append('Details', tray.details);
    tray.maxWeight && formaData.append('MaxWeight', tray.maxWeight);
    tray.maxComponents && formaData.append('MaxComponents', tray.maxComponents);
    tray.mainDevices && tray.mainDevices.map((md, index) => formaData.append(`MainDevices[${index}]`, md.id));

    try {
        const res = await axios.post('/api/cms/trays', formaData, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch(loadKitTrays(true));
        dispatch(showSnackbar('success', 'Tray was successfully created'));
        dispatch({ type: actionTypes.CREATE_KIT_TRAY_SUCCESS, createdTray: res.data });
    } catch (err) {
        dispatch(showSnackbar('error', err.response?.data || 'Create kit tray error'));
        dispatch({ type: actionTypes.CREATE_KIT_TRAY_FAIL, error: err });
    }
};

export const updateKitTray = (tray) => async dispatch => {
    dispatch({ type: actionTypes.UPDATE_KIT_TRAY_START });

    const formaData = new FormData();

    formaData.append('Name', tray.name);
    formaData.append('Number', tray.number);
    tray.file && formaData.append('image', tray.file);
    tray.details && formaData.append('Details', tray.details);
    tray.maxWeight && formaData.append('MaxWeight', tray.maxWeight);
    tray.maxComponents && formaData.append('MaxComponents', tray.maxComponents);
    tray.mainDevices && tray.mainDevices.map((md, index) => formaData.append(`MainDevices[${index}]`, md.id));

    try {
        await axios.put(`/api/cms/trays/${tray.id}`, formaData, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch(loadKitTrays(true));
        dispatch(showSnackbar('success', 'Tray was successfully updated'));
        dispatch({ type: actionTypes.UPDATE_KIT_TRAY_SUCCESS });
    } catch (err) {
        dispatch(showSnackbar('error', err.response?.data || 'Update kit tray error'));
        dispatch({ type: actionTypes.UPDATE_KIT_TRAY_FAIL, error: err });
    }
};

export const updateTrayVisibility = (trayId, isVisible) => async dispatch => {
    dispatch({ type: actionTypes.DELETE_KIT_TRAY_START });

    try {
        await axios.put(`/api/cms/trays/${trayId}/visibility/${isVisible}`, null, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch(loadKitTrays(true));
        dispatch(showSnackbar('success', 'Tray was successfully deleted'));
        dispatch({ type: actionTypes.DELETE_KIT_TRAY_SUCCESS });
    } catch (err) {
        dispatch(showSnackbar('error', err.response?.data || 'Delete kit tray error'));
        dispatch({ type: actionTypes.DELETE_KIT_TRAY_FAIL, error: err });
    }
};

export const loadTrayCavities = (trayId) => async dispatch => {
    dispatch({ type: actionTypes.GET_TRAY_CAVITIES_START });

    try {
        const res = await axios.get(`/api/cms/trays/${trayId}/cavities`, { headers: { Authorization: 'Bearer ' + getToken() } });
        dispatch({ type: actionTypes.GET_TRAY_CAVITIES_SUCCESS, cavities: res.data });
    } catch (err) {
        dispatch(showSnackbar('error', 'Load tray cavity list error'));
        dispatch({ type: actionTypes.GET_TRAY_CAVITIES_FAIL, error: err });
    }
};

export const createTrayCavity = (trayId, cavity) => async dispatch => {
    dispatch({ type: actionTypes.CREATE_TRAY_CAVITY_START });

    const requestBody = {
        Name: cavity.name,
        MaxQuantity: cavity.maxQuantity,
        MaxLength: cavity.maxLength,
        MaxWidth: cavity.maxWidth,
        AllowsMultiple: cavity.allowsMultiple,
        MaxVolume: cavity.maxVolume,
        SpecialInstructions: cavity.specialInstructions,
        Vertices: JSON.stringify(cavity.points),
        X: Math.min(...cavity.points.map(p => p.x)),
        Y: Math.min(...cavity.points.map(p => p.y)),
        Categories: cavity.categories.map(category => ({ CategoryId: category.id, Name: category.name, X: category.x, Y: category.y, Rotation: category.rotation, Placement: JSON.stringify(category.placement) })),
        ComponentRules: cavity.componentRules.allowed
            .map(component => ({ ComponentId: component.id, allowed: true, X: component.x, Y: component.y, Rotation: component.rotation, Priority: component.priority, Placement: JSON.stringify(component.placement) }))
            .concat(cavity.componentRules.restricted.map(component => ({ ComponentId: component.id, allowed: false, X: component.x, Y: component.y, Rotation: component.rotation, Placement: JSON.stringify(component.placement) })))
    };

    try {
        const res = await axios.post(`/api/cms/trays/${trayId}/cavities`, requestBody, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch(loadTrayCavities(trayId));
        dispatch(showSnackbar('success', 'Cavity was successfully created'));
        dispatch({ type: actionTypes.CREATE_TRAY_CAVITY_SUCCESS, data: res.data });
    } catch (err) {
        dispatch(showSnackbar('error', err.response?.data || 'Create cavity error'));
        dispatch({ type: actionTypes.CREATE_TRAY_CAVITY_FAIL, error: err });
    }
};

export const updateTrayCavity = (trayId, cavity, role, kitId, priority = false) => async dispatch => {
    dispatch({ type: actionTypes.UPDATE_TRAY_CAVITY_START });

    const requestBody = {
      Name: cavity.name,
      MaxQuantity: cavity.maxQuantity,
      MaxLength: cavity.maxLength,
      MaxWidth: cavity.maxWidth,
      MaxVolume: cavity.maxVolume,
      AllowsMultiple: cavity.allowsMultiple,
      SpecialInstructions: cavity.specialInstructions,
      Vertices: JSON.stringify(cavity.points),
      X: Math.min(...cavity.points.map((p) => p.x)),
      Y: Math.min(...cavity.points.map((p) => p.y)),
      Categories: cavity.categories.map((category) => ({
        CategoryId: category.id,
        Name: category.name,
        X: category.x,
        Y: category.y,
        Rotation: category.rotation,
        Placement: category.placement,
        ...(category.assemblyOrder && {
          AssemblyOrder: category.assemblyOrder,
        }),
      })),
      ComponentRules: cavity.componentRules.allowed
        .map((component) => ({
          ComponentId: component.id,
          Allowed: true,
          X: component.x,
          Y: component.y,
          Rotation: component.rotation,
          Placement: component.placement,
          Priority: component.priority,
          ImageOverrideJson: component.imageOverrideJson,
          ...(component.assemblyOrder && {
            AssemblyOrder: component.assemblyOrder,
          }),
          IsHorizontalFlipped: component.isHorizontalFlipped,
          LabelData: component.labelData,
        }))
        .concat(
          cavity.componentRules.restricted.map((component) => ({
            ComponentId: component.id,
            Allowed: false,
            X: component.x,
            Y: component.y,
            Rotation: component.rotation,
            Placement: component.placement,
            ImageOverrideJson: component.imageOverrideJson,
            ...(component.assemblyOrder && {
              AssemblyOrder: component.assemblyOrder,
            }),
            IsHorizontalFlipped: component.isHorizontalFlipped,
            LabelData: component.labelData,
          }))
        ),
      IncompatibleCategories: cavity.incompatibleCategories.map(({ id }) => id),
    };

    try {
        await axios.put(`/api/cms/trays/${trayId}/cavities/${cavity.id}`, requestBody, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch(loadTrayCavities(trayId));
        !priority && dispatch(showSnackbar('success', 'Cavity was successfully updated'));
        dispatch({ type: actionTypes.UPDATE_TRAY_CAVITY_SUCCESS });
        role && dispatch(loadKitListFromKitDesign(role));
        kitId && dispatch(loadKitInfoFromKitDesign(kitId, true));
    } catch (err) {
        dispatch(showSnackbar('error', err.response?.data || 'Update cavity error'));
        dispatch({ type: actionTypes.UPDATE_TRAY_CAVITY_FAIL, error: err });
    }
};

export const deleteTrayCavity = (trayId, cavityId) => async dispatch => {
    dispatch({ type: actionTypes.DELETE_TRAY_CAVITY_START });

    try {
        await axios.delete(`/api/cms/trays/${trayId}/cavities/${cavityId}`, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch(loadTrayCavities(trayId));
        dispatch(showSnackbar('success', 'Cavity was successfully deleted'));
        dispatch({ type: actionTypes.DELETE_TRAY_CAVITY_SUCCESS });
    } catch (err) {
        dispatch(showSnackbar('error', err.response?.data || 'Delete cavity error'));
        dispatch({ type: actionTypes.DELETE_TRAY_CAVITY_FAIL, error: err });
    }
};

export const loadMainDeviceList = () => async dispatch => {
    dispatch({ type: actionTypes.GET_MAIN_DEVICE_START });

    try {
        const res = await axios.get('/api/cms/devices', { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.GET_MAIN_DEVICE_SUCCESS, devices: res.data });
    } catch (err) {
        dispatch(showSnackbar('error', 'Load main device list error'));
        dispatch({ type: actionTypes.GET_MAIN_DEVICE_FAIL, error: err });
    }
};

export const changeCavitiesAssemblyOrder = (trayId, cavities) => async dispatch => {
  dispatch({ type: actionTypes.CHANGE_CAVITIES_ASSEMBLY_ORDER_START });

  const requestBody = cavities.map((cavity) => ({
    Id: cavity.id,
    Order: cavity.assemblyOrder,
    PageId: cavity.pageId,
    PageBreakImageId: cavity.pageBreakImageId,
  }));

  try {
    await axios.put(`/api/cms/trays/${trayId}/cavities/order`, requestBody, {
      headers: { Authorization: "Bearer " + getToken() },
    });
    dispatch(loadTrayCavities(trayId));
    dispatch(showSnackbar("success", "Assembly Order successfully changed"));
    dispatch({ type: actionTypes.CHANGE_CAVITIES_ASSEMBLY_ORDER_SUCCESS });
  } catch (err) {
    dispatch(
      showSnackbar("error", err.response?.data || "Assembly Order not changed")
    );
    dispatch({
      type: actionTypes.CHANGE_CAVITIES_ASSEMBLY_ORDER_FAIL,
      error: err,
    });
  }
};

export const loadCmsComponentTooltipInfoSilent = async (id, type) => {
    const route = type === 'allowed' ? `/api/cms/categories/${id}/first-component` : `/api/cms/components/${id}`;
    const res = await axios.get(route, { headers: { Authorization: 'Bearer ' + getToken() } });
    return res.data;
};

export const loadCmsComponentTooltipInfo = (id, type) => async dispatch => {
    dispatch({ type: actionTypes.GET_CMS_COMPONENT_TOOLTIP_INFO_START });

    try {
        const data = await loadCmsComponentTooltipInfoSilent(id, type);
        dispatch({ type: actionTypes.GET_CMS_COMPONENT_TOOLTIP_INFO_SUCCESS, data });
    } catch (err) {
        dispatch({ type: actionTypes.GET_CMS_COMPONENT_TOOLTIP_INFO_FAIL, error: err });
        if (err?.response?.status === 404) dispatch(showSnackbar('warning', 'Sorry there are no components with images for this category'));
        else dispatch(showSnackbar('error', 'Load component info failed'));
    }
};

export const clearComponentTooltipInfo = () => async dispatch => dispatch({ type: actionTypes.CLEAR_CMS_COMPONENT_TOOLTIP_INFO });

export const getImageSize = async (url) => {
    const response = await origAxios.get(url, { responseType: 'arraybuffer' });
    const base64 = Buffer.from(response.data, 'binary').toString('base64');
    const header = atob(base64.slice(0, 50)).slice(16, 24)
    const uint8 = Uint8Array.from(header, c => c.charCodeAt(0))
    const dataView = new DataView(uint8.buffer)
    return {
        width: dataView.getInt32(0),
        height: dataView.getInt32(4)
    }
};

export const setPlacement = async (id, name, cavity) => {
    try {
        const response = await loadCmsComponentTooltipInfoSilent(id, name);
        if (response.images.length > 0 && cavity?.points.length > 0) {
            const imageSize = await getImageSize(corsPatch + response.images[0].url);
            const center = centroid(cavity.points);

            const point = _.sortBy(cavity.points, [o => o.y + o.x])[0];
            return {
                x: imageSize.width / 2 - (center.x - point.x),
                y: imageSize.height / 2 - (center.y - point.y),
                rotation: 0
            }
        }

        return null;
    } catch (e) {
        // @todo: should notify user or not?
    }
};

export const createTrayFromCloned = (id, sku) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_KIT_TRAY_START });

  try {
    const res = await axios.post(
      `/api/cms/trays/${id}/clone`,
      { Number: sku },
      { headers: { Authorization: "Bearer " + getToken() } }
    );

    dispatch(loadKitTrays(true));
    dispatch(showSnackbar("success", "Tray was successfully created"));
    dispatch({
      type: actionTypes.CREATE_KIT_TRAY_SUCCESS,
      createdTray: res.data,
    });
  } catch (err) {
    dispatch(
      showSnackbar("error", err.response?.data || "Create kit tray error")
    );
    dispatch({ type: actionTypes.CREATE_KIT_TRAY_FAIL, error: err });
  }
};