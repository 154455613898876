import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDropzone } from 'react-dropzone'
import { imageFormats, maxImageSize } from '../../../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbar } from '../../../../redux/actions/snackbar';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomButton from '../../../../components/Buttons/CustomButton';
import { createCmsComponentImage, updateCmsComponentImage } from '../../../../redux/actions/components';
import usePrevious from '../../../../hooks/usePrevious';
import CustomInput from '../../../../components/Inputs/CustomInput';
import CustomCheckbox from '../../../../components/Inputs/CustomCheckbox';

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.maxHeight || 160}px;
  width: 80%;
  color: #8e92ad;
  flex-direction: column;
  border: 3px dashed #8e92ad;
  font-weight: 500;
  padding: 10px;
  font-size: 10px;
  cursor: pointer;

  & > div {
    max-height: 250px;
    max-width: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Label = styled.span`
  color: #1d2659;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 5px 0px;
`;

const Placement = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding: 0px 10px;

  & > div {
    width: 55%;
    input {
      text-align: center;
    }
    span {
      font-size: 12px;
      white-space: nowrap;
    }
  }
`;

const UploadComponentImage = ({
  data,
  imagePlacement,
  cancel,
  type,
  trayImages = [],
  isCatalog = false,
  hideTooltip,
  maxHeight,
  orphanQuantity,
  noFlip,
  allowMultiple,
  changeImageFlipProperty,
  isHorizontalFlipped,
  updateRules,
  showSave,
}) => {
  const [image, setImage] = useState(null);
  const [placement, setPlacement] = useState(
    isCatalog ? 'catalog' : imagePlacement?.trim(),
  );
  const { componentsLoading } = useSelector((state) => state.components);
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();

  const { id, images } = data || {};
  const prevValue = usePrevious(componentsLoading);

  useEffect(() => {
    if (!componentsLoading && prevValue !== componentsLoading && cancel) {
      cancel(null);
    }
  }, [componentsLoading, cancel, prevValue]);

  useEffect(() => {
    setImage(!data ? null : images?.find((img) => img.name === imagePlacement));
    setPlacement(imagePlacement?.trim());
  }, [data, imagePlacement, images]);

  useEffect(() => {
    setQuantity(image ? image.quantity : 1);
    // eslint-disable-next-line
  }, [image]);

  const onDrop = useCallback(
    (files) => {
      if (!files.length) {
        dispatch(
          showSnackbar(
            'warning',
            'Your File  should be 30 Mb or smaller to be processed',
          ),
        );
        return;
      }

      setImage({ file: files[0], url: URL.createObjectURL(files[0]) });
    },
    [dispatch],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: maxImageSize,
    accept: imageFormats,
  });

  const onUpload = () => {
    if (trayImages.find((x) => x.name === placement)) {
      dispatch(showSnackbar('warning', 'Image name must be unique'));
      return;
    }
    const existingImage = images?.find((img) => img.name === placement);
    const qValue = allowMultiple ? quantity : 1;

    if (existingImage?.id || image?.id) {
      dispatch(
        updateCmsComponentImage(
          id,
          existingImage?.id || image?.id,
          image?.file || undefined,
          placement,
          type,
          qValue,
          null,
          true,
        ),
      );
    } else {
      dispatch(
        createCmsComponentImage(id, image.file, placement, type, qValue),
      );
    }

    if (updateRules) {
      updateRules();
    }
    hideTooltip && hideTooltip();
    // setBufferedImage(null);
    // setImage(null);
  };

  const error = image && !placement ? 'Enter image position name.' : '';

  const [bufferedImage, setBufferedImage] = useState(null);

  useEffect(() => {
    if (!bufferedImage && image?.url) {
      setBufferedImage(image.url);
    }
    // eslint-disable-next-line
  }, [image]);

  const showSaveButton = updateRules || image?.file || showSave;

  return (
    <>
      <canvas
        id="FlipHorizontalCanvas"
        style={{ position: 'fixed', opacity: 0, zIndex: -1000 }}
      />
      {!isCatalog && (
        <Placement>
          <Label>Placement: </Label>
          <CustomInput
            placeholder="Top, left, custom..."
            value={placement}
            onChange={(e) => setPlacement(e.target.value)}
            errorText={error}
            invalid={!!error}
          />
        </Placement>
      )}
      {isCatalog && bufferedImage && !bufferedImage.includes('blob:') && (
        <ImageContainer
          style={{ cursor: 'default', height: '180px' }}
          maxHeight={maxHeight}
        >
          <img src={bufferedImage} alt="Current catalog" />
          <span style={{ paddingTop: 10 }}>Current catalog image</span>
        </ImageContainer>
      )}
      <ImageContainer {...getRootProps()} maxHeight={maxHeight}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the PNG file here ...</p>
        ) : (
          <>
            {(image?.url && !isCatalog) ||
            (isCatalog &&
              image?.url &&
              (image?.url !== bufferedImage ||
                image?.url.includes('blob:'))) ? (
              <img src={image?.url} alt="Upload" />
            ) : (
              <>
                <FontAwesomeIcon icon={faImage} style={{ fontSize: 72 }} />
                <span>
                  {isCatalog
                    ? 'Upload new image for catalog'
                    : 'Upload a Component image'}
                </span>
              </>
            )}
          </>
        )}
      </ImageContainer>

      {!noFlip && (
        <CustomCheckbox
          style={{ color: '#1D2659' }}
          label={'Flip Horizontal'}
          checked={isHorizontalFlipped}
          onChange={changeImageFlipProperty}
        />
      )}

      {allowMultiple && (
        <>
          <Placement>
            <Label>Quantity: </Label>
            <CustomInput
              type="number"
              placeholder="Enter number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              min={1}
              max={orphanQuantity || Number.MAX_SAFE_INTEGER}
            />
          </Placement>
          {orphanQuantity && (
            <span>
              {`You have ${orphanQuantity} components in orphan list.`}
            </span>
          )}
        </>
      )}
      <Buttons>
        {cancel && (
          <CustomButton
            label="Cancel"
            background="orangered"
            onClick={() => cancel(null)}
          />
        )}

        {showSaveButton && (
          <CustomButton
            label="Save"
            onClick={onUpload}
            disabled={!image || !placement}
            icon={
              componentsLoading ? (
                <i
                  className="fas fa-spinner fa-spin"
                  style={{ marginRight: 5 }}
                />
              ) : null
            }
          />
        )}
      </Buttons>
    </>
  );
};

export default UploadComponentImage;
