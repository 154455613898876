import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Sketch from 'react-p5';
import inside from 'point-in-polygon';
import styled from '@emotion/styled';
import { sortBy, isEqual, cloneDeep } from "lodash-es";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStickyNote, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { loadTrayCavities } from "../../../../redux/actions/kitTrays";
import CustomButton from "../../../../components/Buttons/CustomButton";
import Loader from "../../../../components/loaders/Loader";
import {
  canvasBufferOptions,
  controlPanelRoutes,
  corsPatch,
} from "../../../../constants/constants";
import Spinner from "../../../../components/loaders/Spinner";
import usePrevious from "../../../../hooks/usePrevious";
import { renderWithAspectRatio } from "./resize";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  canvas {
    border: 2px dashed #8e93ad;
  }
`;

function rotatePoint({ x, y, originX, originY, angle }) {
  const radianAngle = (angle * Math.PI) / 180.0;

  return {
    x:
      Math.cos(radianAngle) * (x - originX) -
      Math.sin(radianAngle) * (y - originY) +
      originX,
    y:
      Math.sin(radianAngle) * (x - originX) +
      Math.cos(radianAngle) * (y - originY) +
      originY,
  };
}

function getRectangleCenter(points) {
  const xPoints = points.map((p) => p.x);
  const yPoints = points.map((p) => p.y);

  const minX = Math.min(...xPoints);
  const maxX = Math.max(...xPoints);

  const minY = Math.min(...yPoints);
  const maxY = Math.max(...yPoints);

  return {
    originX: (minX + maxX) / 2,
    originY: (minY + maxY) / 2,
  };
}

const Cavities = ({
  setSelectedCavity,
  showCavities = true,
  selectedCavity,
  cavities,
  newCavityData,
  setNewCavityData,
  tray,
  deleteCavity,
  componentPlacement,
  setComponentPlacement,
  ignoreComponentChanges = false,
  readOnly,
  pageImage,
  setCanvasElemRef = null,
  pageBreakImages,
  notesObj,
  setNotesObj,
  notesEditable,
  setSelectedNote,
  selectedNote,
  setAddNoteEvent,
  objects,
  pages,
  selectedPageOnCanvas,
  imageLoading,
  setImageLoading,
  fps = 10,
  labelVisibility,
  runPdfGeneration,
  updateLayout,
  kit,
  noAddNote,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const wrapperElem = useRef(null);
  const [componentImageUrls, setComponentImageUrls] = useState([]);
  const trayId = tray?.id;
  const { value: visibility } = labelVisibility ?? {};

  let notesOnPage = [];

  switch (visibility) {
    case "none":
      notesOnPage = [];
      break;

    case "all":
      notesOnPage = notesObj;
      break;

    case "allHidden":
      notesOnPage = notesObj?.filter((n) => n.isHidden);
      break;

    default:
      notesOnPage = notesObj?.filter(
        (n) => n.pageId === selectedPageOnCanvas?.id
      );
  }

  const needLoadCavities =
    trayId &&
    controlPanelRoutes.some(({ value }) =>
      history.location.pathname.includes(value)
    );
  const [font, setFont] = useState(null);

  const [image, setImage] = useState(null); // Background tray image
  const [mouseDown, setMouseDown] = useState(false);
  const [mouseClicked, setMouseClicked] = useState(false);
  const [multiplier, setMultiplier] = useState([1, 1]); // Used for image sizing
  const [cavityMultiplier, setCavityMultiplier] = useState([1, 1]); // Used for positioning
  const [currentTrayId, setCurrentTrayId] = useState(-1);
  const [dragPointNumber, setDragPointNumber] = useState(-1);
  const [canvasElem, setCanvasElem] = useState(null);
  const [canvasCoord, setCanvasCoord] = useState(null);
  const [cachedImages, setCachedImages] = useState([]);
  const [deltaWheel, setDeltaWheel] = useState(0);
  const [dragPlacementStart, setDragPlacementStart] = useState(null);
  const [dragNote, setDragNote] = useState(null);
  const [spinnerFlag, setSpinnerFlag] = useState(false);
  const [pageImageCache, setPageImageCache] = useState({});
  const [dragNoteTemplateStart, setDragNoteTemplateStart] = useState(null);

  const [componentImagesLoading, setComponentsImagesLoading] = useState(false);
  const { offset, imageSizeOffset } = canvasBufferOptions;

  const lowLevelComponents = history.location.state?.lowLevelComponents;
  const [lowLevelComponentImages, setLowLevelComponentImages] = useState([]);

  useEffect(() => {
    if (needLoadCavities) {
      dispatch(loadTrayCavities(trayId));
    }
  }, [dispatch, needLoadCavities, trayId]);

  useEffect(() => {
    setCurrentTrayId(-1);
  }, [tray.image]);

  useEffect(() => {
    if (wrapperElem) {
      wrapperElem.current.addEventListener('wheel', onScroll, {
        passive: false,
      });
    }
    // eslint-disable-next-line
  }, [wrapperElem]);

  const prevComponentImageUrls = usePrevious(componentImageUrls);

  useEffect(() => {
    if (!isEqual(prevComponentImageUrls, componentImageUrls)) {
      setSpinnerFlag(true);
      setTimeout(() => setCachedImages([]), 200);
    }
    // eslint-disable-next-line
  }, [prevComponentImageUrls, componentImageUrls]);

  useEffect(() => {
    setComponentImageUrls((componentPlacement || []).map((x) => x.image));
  }, [componentPlacement]);

  const getMinHoveredArea = (cavities = []) => {
    const result = { area: Number.MAX_VALUE, cavity: null };
    const cavs = newCavityData?.points?.length ? [newCavityData] : cavities;

    cavs.forEach((cavity) => {
      const { points } = cavity;
      const dx = points[0].x - points[2].x; // change the order of coordinates if they changed in a cavity
      const dy = points[0].y - points[2].y; // to make dx, dy correct

      const currentArea = Math.abs(dx) * Math.abs(dy);

      if (currentArea <= result.area) {
        result.area = currentArea;
        result.cavity = cavity;
      }
    });
    return result.cavity;
  };

  const findNoteByPoint = (point, note, width, height = 0) => {
    if (!point || !Number.isFinite(point.x) || !Number.isFinite(point.y)) {
      return null;
    }

    const { position = {}, rotation } = note;

    const posX = position.x * cavityMultiplier[0] + offset;
    const posY = position.y * cavityMultiplier[1] + offset;

    const poligon = [
      // left top corner
      { x: posX - (+rotation ? height : 0), y: posY },
      // left bottom corner
      {
        x: posX - (+rotation ? height : 0),
        y: posY + (+rotation ? width : height),
      },
      // right bottom corner
      {
        x: posX + (+rotation ? height : width),
        y: posY + (+rotation ? width : height),
      },

      // right top corner
      { x: posX + (+rotation ? height : width), y: posY },
    ];

    let catched = false;

    if (
      inside(
        [point.x, point.y],
        poligon.map((p) => [p.x, p.y]),
      )
    ) {
      catched = true;
    }

    return catched;
  };

  const hoveredCavities = [];
  const findCavityByPoint = (point) => {
    const cavs = newCavityData ? [newCavityData] : cavities;

    if (!point || !Number.isFinite(point.x) || !Number.isFinite(point.y)) {
      return null;
    }

    for (let cavity of cavs.filter((x) => x?.points && x.points.length === 4)) {
      if (
        inside(
          [point.x, point.y],
          cavity.points.map((p) => [p.x, p.y]),
        )
      ) {
        hoveredCavities.push(cavity);
      }
    }

    if (!hoveredCavities.length) {
      return null;
    }

    return getMinHoveredArea(hoveredCavities);
  };

  const dbp = (a, b) => Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2);

  const setup = (p5, canvasParentRef) => {
    if (canvasParentRef) {
      p5.frameRate(fps);
      let canvas = p5.createCanvas(0, 0);
      canvas.parent(canvasParentRef);
      setCanvasElem(canvas.canvas);
      p5.loadFont(
        `${window.location.protocol}//${window.location.host}/roboto-v20-latin-regular.ttf`,
        (pfont) => {
          setFont(pfont);
        },
      );
      if (setCanvasElemRef) {
        setCanvasElemRef(canvas.canvas);
      }
    }
  };

  // bug with p5 text bounding
  const processField = (text) => (text ? decodeURI(text).split('\n') : []);

  const moveCavity = (mouse, sum, id, isNew) => {
    setDragPlacementStart(mouse);
    if (sum > 15 && dragPlacementStart) {
      const points = isNew ? newCavityData.points : selectedCavity.points;

      points.forEach((p) => {
        p.x = p.x + (mouse.x - dragPlacementStart.x);
        p.y = p.y + (mouse.y - dragPlacementStart.y);
      });

      if (isNew) {
        setNewCavityData({ ...newCavityData, points });
      } else {
        setSelectedCavity({ ...selectedCavity, points }, false);
      }
    }

    if (sum > 15 && !findCavityByPoint(mouse)) {
      !isNew && setSelectedCavity(null);
      setMouseDown(false);
      return;
    }

    if (!(findCavityByPoint(mouse) && sum > 15)) {
      setDragPointNumber(id);
    }
  };

  const renderComponent = (component, p5, filteredCavity, image) => {
    let componentImage =
      image || cachedImages[componentPlacement.indexOf(component)];

    if (componentImage && component) {
      let cavity = cavities.find((x) => x.id === component.cavityId);

      if (!image && filteredCavity) {
        cavity = filteredCavity;
      }

      p5.push();

      let point = sortBy(cavity?.points, [(o) => o.y + o.x])[0] || {
        x: 0,
        y: 0,
      };

      let points = [
        {
          x: point.x * cavityMultiplier[0] + offset,
          y: point.y * cavityMultiplier[1] + offset,
        },
        {
          x: componentImage.width * multiplier[0],
          y: componentImage.height * multiplier[1],
        },
      ];

      const imageCenter = [
        points[0].x + points[1].x / 2,
        points[0].y + points[1].y / 2,
      ];

      p5.translate(
        imageCenter[0] - component.values.x * cavityMultiplier[0],
        imageCenter[1] - component.values.y * cavityMultiplier[1],
      );

      if (component.isHorizontalFlipped) {
        p5.scale(-1, 1);
      }

      p5.rotate((p5.PI / 180) * component.values.r);

      const blured =
        ignoreComponentChanges &&
        selectedCavity &&
        selectedCavity.id !== cavity?.id;

      const isComponentSelected =
        selectedCavity?.component &&
        selectedCavity.component.id !== component.id;

      if (isComponentSelected || blured) {
        p5.tint(255, 0);
      } else {
        p5.tint(255, 255);
      }

      if (image) {
        p5.tint(255, selectedCavity ? 150 : 0);
      }

      p5.image(
        componentImage,
        -points[1].x / 2,
        -points[1].y / 2,
        points[1].x,
        points[1].y,
      );

      p5.pop();
      p5.strokeWeight(1);
    }
  };

  const draw = async (p5) => {
    const realPageImage =
      (selectedPageOnCanvas?.image
        ? pageBreakImages.find((x) => x.id === selectedPageOnCanvas?.image)?.url
        : null) || pageImage;

    if (
      !spinnerFlag &&
      realPageImage &&
      pageImageCache?.url !== realPageImage
    ) {
      setSpinnerFlag(true);
      setPageImageCache({
        file: p5.loadImage(corsPatch + realPageImage),
        url: realPageImage,
      });
      return;
    }

    if (!!cachedImages.map((x) => x?.width).find((x) => x === 1)) {
      setComponentsImagesLoading(false);
    } else {
      setComponentsImagesLoading(true);
    }

    if (spinnerFlag && realPageImage && realPageImage.file?.width === 1) {
      return;
    } else {
      setSpinnerFlag(false);
    }

    if (font) {
      p5.textFont(font);
    }

    if (
      deltaWheel !== 0 &&
      p5.mouseX > 0 &&
      p5.mouseY > 0 &&
      p5.mouseX < 1000 * multiplier[0] &&
      p5.mouseY < 1000 * multiplier[1]
    ) {
      const rotateFn = (currentCavity, isNew) => {
        const points = currentCavity.points.map((p) => ({
          x: p.x * cavityMultiplier[0] + offset,
          y: p.y * cavityMultiplier[1] + offset,
        }));

        const { originX, originY } = getRectangleCenter(points);
        const angle = deltaWheel > 0 ? 5 : -5;

        points.forEach((p) => {
          const { x, y } = rotatePoint({
            x: p.x,
            y: p.y,
            originX,
            originY,
            angle,
          });

          p.x = (x - offset) / cavityMultiplier[0];
          p.y = (y - offset) / cavityMultiplier[1];
        });

        if (isNew) {
          setNewCavityData({
            ...newCavityData,
            points,
          });
        } else {
          cavities.forEach((c) => {
            if (c.id === currentCavity.id) {
              c.points = points;
            }
          });

          setSelectedCavity(
            {
              ...currentCavity,
              points,
              vertices: JSON.stringify(points),
            },
            false,
          );
        }
      };

      if (selectedCavity && !componentImageUrls[0]) {
        rotateFn(selectedCavity);
      }

      if (newCavityData) {
        rotateFn(newCavityData, true);
      }

      if (componentImageUrls && componentImageUrls[0]) {
        const values = {
          ...componentPlacement[0].values,
          r: +componentPlacement[0].values.r + deltaWheel / imageSizeOffset,
        };
        setComponentPlacement([
          {
            ...componentPlacement[0],
            values,
            imageOverrideJson: fillImageOverrideJson(
              componentPlacement,
              values,
            ),
          },
        ]);
      }
      setDeltaWheel(0);
    }

    if (currentTrayId !== tray.id && tray.imageUrl) {
      setCurrentTrayId(tray.id);
      setImage(
        p5.loadImage(tray.imageUrl ? corsPatch + tray.imageUrl : tray.image),
      );
      return;
    }

    resized(p5);

    if (
      pageImageCache?.file && pageImageCache?.url === realPageImage
        ? pageImageCache.file.width > 1
        : image && image.width > 1
    ) {
      p5.background('#f9f9f9');

      if (!realPageImage) {
        // tray image
        renderWithAspectRatio(p5, image, canvasElem);
      }

      // divider image
      if (realPageImage) {
        renderWithAspectRatio(p5, pageImageCache?.file, canvasElem);
      }

      setImageLoading(false);
    } else {
      setImageLoading(true);
      return;
    }

    p5.strokeJoin(p5.ROUND);
    p5.strokeWeight(3);
    p5.strokeCap(p5.SQUARE);

    const mouse = {
      x: (p5.mouseX - offset) / cavityMultiplier[0],
      y: (p5.mouseY - offset) / cavityMultiplier[1],
    };

    let mouseOverCavity = findCavityByPoint(mouse);

    for (let cavity of [
      ...cavities
        .filter((c) => c.id !== selectedCavity?.id)
        .filter((c) => !c.hidden),
      newCavityData,
      selectedCavity,
    ]) {
      if (!cavity || cavity.points.length < 4) {
        continue;
      }

      if (!(showCavities || selectedCavity?.id === cavity.id)) {
        continue;
      }

      if (
        componentPlacement.find((x) => x.image !== null) &&
        selectedCavity &&
        selectedCavity.id !== cavity.id
      ) {
        continue;
      }

      if (selectedPageOnCanvas) {
        break;
      }

      if (
        (selectedCavity && cavity.id === selectedCavity.id) ||
        cavity === newCavityData ||
        (mouseOverCavity && cavity.id === mouseOverCavity.id)
      ) {
        p5.fill(p5.color(255, 255, 255, 90));
        p5.stroke(p5.color(0, 0, 0, 120));
      } else {
        p5.fill(p5.color(255, 255, 255, 0));
        p5.stroke(p5.color(0, 0, 0, 120));
      }

      p5.beginShape();

      for (let p of cavity.points) {
        p5.vertex(
          p.x * cavityMultiplier[0] + offset,
          p.y * cavityMultiplier[1] + offset,
        );
      }

      p5.endShape(p5.CLOSE);
    }

    const dragCavity = selectedCavity || newCavityData;
    // Draw drag handlers
    if (
      dragCavity &&
      dragCavity.points &&
      dragCavity.points.length === 4 &&
      !selectedPageOnCanvas
    ) {
      for (let i = 0; i < dragCavity?.points.length; ++i) {
        if (dragCavity.isFloating) {
          p5.fill('rgba(0,0,0,0)');
          p5.stroke('rgba(0,0,0,0)');
        } else {
          p5.fill(100);
          p5.stroke(80);
        }
        // p5.circle(
        //   dragCavity.points[i].x * cavityMultiplier[0] + offset,
        //   dragCavity.points[i].y * cavityMultiplier[1] + offset,
        //   10
        // );
      }
    }

    if (mouseOverCavity && showCavities) {
      document.body.style.cursor = 'pointer';
    } else {
      document.body.style.cursor = 'default';
    }

    if (componentImageUrls.length > 0 && cachedImages.length === 0) {
      const links = componentImageUrls.filter((url) => url);
      const res = await Promise.all(
        links.map((x) => p5.loadImage(corsPatch + encodeURIComponent(x))),
      );

      setCachedImages(res);
      setSpinnerFlag(false);
    }

    if (!componentImageUrls || componentImageUrls.length === 0) {
      setCachedImages([]);
      setSpinnerFlag(false);
    }

    let shadowRendered = false;

    let objectsFiltered = [];

    if (pageImage) {
      return;
    }

    if (objects) {
      if (selectedPageOnCanvas) {
        objectsFiltered = objects
          .filter((x) => x.pageId >= selectedPageOnCanvas.id)
          .reverse();
      } else {
        objectsFiltered.push(...objects.filter(Boolean).reverse());
      }
    }

    for (let object of objectsFiltered.filter((x) => x.componentId)) {
      if (realPageImage && object.pageId !== selectedPageOnCanvas?.id) {
        continue;
      }
      if (
        object.pageId === selectedPageOnCanvas?.id &&
        !shadowRendered &&
        !realPageImage
      ) {
        const isFirstPage = pages?.slice(-1)[0]?.id === selectedPageOnCanvas.id;

        if (!isFirstPage) {
          p5.background('rgba(0,0,0,0.3)');
          shadowRendered = true;
        }
      }

      const component = componentPlacement.find(
        (x) => x.id === object.componentId && x.cavityId === object.cavityId,
      );
      renderComponent(component, p5);
    }

    if (lowLevelComponentImages.length && lowLevelComponents) {
      lowLevelComponents.forEach((component, i) => {
        renderComponent(component, p5, null, lowLevelComponentImages[i]);
      });
    }

    if (lowLevelComponents && !lowLevelComponentImages.length) {
      const res = await Promise.all(
        lowLevelComponents.map((c) => p5.loadImage(corsPatch + c.image)),
      );

      setLowLevelComponentImages(res);
    }

    for (let cavity of cavities.filter((x) =>
      objects?.length
        ? objectsFiltered.map((y) => y.cavityId).includes(x?.id)
        : true,
    )) {
      if (objectsFiltered.length === 0 && !objects?.length) {
        const components = componentPlacement.filter((x) => {
          return (
            (x.cavityId === cavity.id ||
              x.cavityId ===
                (selectedCavity?.id === cavity.id ? undefined : cavity.id)) &&
            x.image !== null
          );
        });

        for (let component of components) {
          renderComponent(component, p5, cavity);
        }
      }
    }

    if (
      objectsFiltered.length === 0 &&
      !objects &&
      selectedCavity?.isFloating
    ) {
      for (let component of componentPlacement) {
        renderComponent(component, p5);
      }
    }

    function renderLabels(note, catchNote) {
      function rotate(x, y, degree = 0) {
        p5.translate(x, y);
        p5.rotate((p5.PI / 180) * degree);
        p5.translate(-x, -y);
      }

      if (note.isHidden && visibility !== 'allHidden') {
        return;
      }

      const size = 15;
      const yMulti = multiplier[1] < 0.85 ? 0.85 : multiplier[1];
      const fontSize = size * yMulti;
      const textProps = {};

      if (note.position && !(note.hideOnPDF && !notesEditable)) {
        const notePos = [
          note.position.x * cavityMultiplier[0] + offset,
          note.position.y * cavityMultiplier[1] + offset,
        ];
        let [width, height] = [0, 0];
        const padding = 5;

        const [text, replacement] = [
          processField(note.text),
          processField(note.replacement),
        ];

        const [boundingText, boundingReplacement] = [[], []];

        for (let line of text) {
          const bounding = font.textBounds(
            line,
            notePos[0],
            notePos[1] + height,
            fontSize,
          );
          width = Math.max(width, bounding.w);
          height += fontSize;
          boundingText.push(bounding);
        }

        for (let line of replacement) {
          const bounding = font.textBounds(
            line,
            notePos[0],
            notePos[1] + height,
            fontSize,
          );
          width = Math.max(width, bounding.w);
          height += fontSize;
          boundingReplacement.push(bounding);
        }

        p5.push();

        const isRotated = +note.rotation;
        if (isRotated) {
          rotate(notePos[0], notePos[1], note.rotation);
        }

        if (
          (selectedNote && selectedNote.id === note.id) ||
          note?.highlighted ||
          catchNote
        ) {
          p5.fill(255, 204, 0);
        } else p5.fill(255);

        p5.stroke(0);

        if (width > 10000 || height > 10000) {
          return;
        }

        p5.rect(
          ...notePos,
          width + fontSize,
          height + (fontSize + padding) / 2,
        );
        p5.textAlign(p5.LEFT);
        p5.pop();
        p5.push();
        p5.fill(0);
        p5.strokeWeight(0);

        p5.textSize(fontSize);

        for (let i = 0; i < text.length; ++i) {
          if (isRotated && i === 0) {
            rotate(
              boundingText[i].x,
              boundingText[i].y + fontSize / 1.5,
              note.rotation,
            );
          }

          p5.text(
            text[i],
            boundingText[i].x + fontSize / 3,
            boundingText[i].y + fontSize * 2,
            1000, // real width makes problem when you have spaces in your text (K-10-536-66 (5))
            height,
          );
        }

        p5.fill(255);

        for (let i = 0; i < replacement.length; ++i) {
          p5.fill(40, 65, 255);
          p5.text(
            replacement[i],
            boundingReplacement[i].x + fontSize / 3,
            boundingReplacement[i].y + fontSize,
            1000, // real width makes problem when you have spaces in your text (K-10-536-66 (5))
            height,
          );
        }

        p5.fill(255);
        p5.pop();

        textProps.width = width;
        textProps.height = height + (fontSize + padding) / 2;
      }

      if (
        !runPdfGeneration &&
        note.position &&
        (notesEditable || !note.hideOnPDF)
      ) {
        p5.fill(255);
        p5.stroke(0);
        // p5.circle(
        //   note.position.x * cavityMultiplier[0],
        //   note.position.y * cavityMultiplier[1],
        //   15
        // );
      }

      return textProps;
    }

    let text;
    if (notesOnPage) {
      const arr = [];
      notesOnPage.forEach((note) => {
        const textInfo = renderLabels(note);
        arr.push({ ...textInfo, ...note });
      });
      text = arr;
    }

    // move template label
    const labelData = componentPlacement[0]?.labelData;
    if (labelData) {
      const point = { x: p5.mouseX, y: p5.mouseY };
      const { text: noteText, x, y, rotation } = labelData;
      const note = { text: decodeURI(noteText), position: { x, y }, rotation };

      text = renderLabels(note, dragNoteTemplateStart);

      if (mouseDown && !dragNoteTemplateStart) {
        setDragNoteTemplateStart(
          findNoteByPoint(point, note, text.width, text.height),
        );
      }

      if (!mouseDown && dragNoteTemplateStart) {
        setDragPlacementStart(null);
        setDragNoteTemplateStart(false);
      }

      if (dragNoteTemplateStart && dragPlacementStart) {
        const labelPos = {
          ...labelData,
          x: labelData.x - dragPlacementStart.x + mouse.x,
          y: labelData.y - dragPlacementStart.y + mouse.y,
        };

        const imageOverrideJson = fillImageOverrideJson(
          componentPlacement,
          componentPlacement[0].values,
          null,
          labelPos,
        );

        setComponentPlacement([
          {
            ...componentPlacement[0],
            labelData: labelPos,
            imageOverrideJson,
          },
        ]);
        setDragPlacementStart(mouse);
        return;
      }
    }

    if (selectedCavity && selectedCavity.label) {
      renderLabels(selectedCavity.label);
    }

    if (notesOnPage) {
      if (dragNote !== null) {
        if (mouseDown) {
          if (dragPlacementStart) {
            const obj = [...notesOnPage];
            obj[dragNote] = {
              ...obj[dragNote],
              position: {
                x:
                  notesOnPage[dragNote].position.x -
                  dragPlacementStart.x +
                  mouse.x,
                y:
                  notesOnPage[dragNote].position.y -
                  dragPlacementStart.y +
                  mouse.y,
              },
            };
            const replaceDragged = notesObj.map((n) =>
              n.id === obj[dragNote].id
                ? { ...n, position: obj[dragNote].position }
                : { ...n },
            );
            setNotesObj(replaceDragged);
            updateLayout && updateLayout(replaceDragged, kit?.id);
            //if (!obj[dragNote].cavityId) {
            setSelectedNote(obj[dragNote]);
            //}
            setDragPlacementStart(mouse);
          } else {
            setDragPlacementStart(mouse);
          }
        } else {
          setDragNote(null);
          setMouseClicked(false);
          setDragPlacementStart(null);
        }
      } else {
        if (mouseClicked && setSelectedNote) {
          setSelectedNote(null);
          for (let i = notesOnPage.length - 1; i >= 0; --i) {
            const isLabelOnPage =
              !notesOnPage[i].cavityId &&
              notesOnPage[i].pageId === selectedPageOnCanvas?.id;

            const isLabelInNotes =
              selectedPageOnCanvas &&
              objectsFiltered.find(
                (x) =>
                  x.componentId === notesOnPage[i].componentId &&
                  x.cavityId === notesOnPage[i].cavityId,
              )?.pageId === selectedPageOnCanvas.id;

            if (isLabelOnPage || isLabelInNotes) {
              const c_sum = dbp(notesOnPage[i].position ?? {}, mouse);
              if (c_sum <= 15) {
                setDragNote(i);
                break;
              }

              const textInfo = Array.isArray
                ? text.find((t) => t.id === notesOnPage[i].id)
                : text;

              const width = textInfo?.width < 20 ? 20 : textInfo?.width;

              const clickedNote = findNoteByPoint(
                { x: p5.mouseX, y: p5.mouseY },
                notesOnPage[i],
                width,
                textInfo?.height,
                p5,
              );

              if (clickedNote) {
                setSelectedNote(notesOnPage[i]);
                setDragNote(i);
                setMouseClicked(false);
                break;
              }
            }
          }
          setMouseClicked(false);
        }
      }
    }

    if (!readOnly && !selectedPageOnCanvas) {
      if (!newCavityData || newCavityData.points.length === 4) {
        if (
          componentImageUrls[0] &&
          selectedCavity &&
          !ignoreComponentChanges
        ) {
          if (mouseClicked) {
            if (mouseDown) {
              if (dragPlacementStart && !dragNoteTemplateStart) {
                const values = {
                  ...componentPlacement[0].values,
                  x:
                    componentPlacement[0].values.x +
                    dragPlacementStart.x -
                    mouse.x,
                  y:
                    componentPlacement[0].values.y +
                    dragPlacementStart.y -
                    mouse.y,
                };

                const imageOverrideJson = fillImageOverrideJson(
                  componentPlacement,
                  values,
                );

                setComponentPlacement([
                  {
                    ...componentPlacement[0],
                    values,
                    imageOverrideJson,
                  },
                ]);
                setDragPlacementStart(mouse);
              } else {
                setDragPlacementStart(mouse);
              }
            } else {
              setMouseClicked(false);
              setDragPlacementStart(null);
            }
          }
        } else if (mouseClicked) {
          if (mouseDown) {
            if (selectedCavity && selectedCavity.points) {
              if (dragPointNumber === -1) {
                let id = 0;
                let sum = dbp(
                  selectedCavity.points[0] || { x: 0, y: 0 },
                  mouse,
                );
                for (let i = 1; i < selectedCavity.points.length; ++i) {
                  const c_sum = dbp(selectedCavity.points[i], mouse);
                  if (c_sum < sum) {
                    id = i;
                    sum = c_sum;
                  }
                }

                // cavity DND
                moveCavity(mouse, sum, id);
              } else {
                const new_points = selectedCavity.points;
                new_points[dragPointNumber] = mouse;
                setSelectedCavity(
                  { ...selectedCavity, points: new_points },
                  false,
                );
              }
            } else if (newCavityData?.points) {
              if (dragPointNumber === -1) {
                let id = 0;
                let sum = dbp(newCavityData.points[0], mouse);
                for (let i = 1; i < newCavityData.points.length; ++i) {
                  const c_sum = dbp(newCavityData.points[i], mouse);
                  if (c_sum < sum) {
                    id = i;
                    sum = c_sum;
                  }
                }

                // cavity DND
                moveCavity(mouse, sum, id, true);
              } else {
                const new_points = newCavityData.points;
                new_points[dragPointNumber] = mouse;
                setNewCavityData({ ...newCavityData, points: new_points });
              }
            }
          } else {
            setMouseClicked(false);
            if (dragPointNumber !== -1) {
              setDragPointNumber(-1);
            } else {
              if (!newCavityData && (selectedCavity || mouseOverCavity)) {
                setSelectedCavity(mouseOverCavity, selectedCavity === null);
              }
            }
          }
        }
      } else {
        if (mouseClicked) {
          setMouseClicked(false);
          let tempPoints = [...newCavityData.points, mouse];

          if (tempPoints.length > 1) {
            const { points } = newCavityData;
            const { x, y } = points[0];

            tempPoints = [
              { x, y },
              { x, y: mouse.y },
              { x: mouse.x, y: mouse.y },
              { x: mouse.x, y },
            ];
          }
          setNewCavityData({ ...newCavityData, points: tempPoints });
        }

        if (!mouseDown && newCavityData.points.length > 0) {
          setMouseClicked(true);
        }

        p5.stroke(p5.color(0, 0, 0, 120));

        if (newCavityData.points.length > 0) {
          const { points } = newCavityData;
          const { x, y } = points[0];

          p5.rect(
            x * cavityMultiplier[0] + offset,
            y * cavityMultiplier[1] + offset,
            p5.mouseX - (x * cavityMultiplier[0] + offset),
            p5.mouseY - (y * cavityMultiplier[1] + offset),
          );
        } else {
          p5.point(p5.mouseX, p5.mouseY);
        }
      }
    }
  };

  const resized = (p5) => {
    if (image) {
      p5.resizeCanvas(0, 0);
      const landscapeRatio = image.height / image.width;
      const portraitRatio = image.width / image.height;

      const windWidth = wrapperElem.current.clientWidth;
      const windHeight = wrapperElem.current.clientHeight;

      function getWidth() {
        // landscape
        if (landscapeRatio < 1) {
          return windWidth;
        }

        // portrait
        return windHeight * portraitRatio;
      }

      function getHeight() {
        // landscape
        if (landscapeRatio < 1) {
          // TODO remove hard coded numbers in the future
          const fill = (landscapeRatio < 0.4 ? 170 : 100) / landscapeRatio;
          return (windWidth + fill) * landscapeRatio;
        }

        // portrait
        return windHeight;
      }

      let [width, height] = [getWidth(), getHeight()];

      if (height > windHeight + 20) {
        const d = height / (windHeight + 20);
        width /= d;
        height /= d;
      }

      setMultiplier([
        (width - imageSizeOffset) / image.width,
        (height - imageSizeOffset) / image.height,
      ]);

      setCavityMultiplier([
        (width - imageSizeOffset) / 1000,
        (height - imageSizeOffset) / 1000,
      ]);

      p5.resizeCanvas(width, height);

      if (selectedCavity && selectedCavity.points) {
        let coords = [
          canvasElem.getBoundingClientRect().left,
          canvasElem.getBoundingClientRect().top - 50,
        ];
        let point = sortBy(selectedCavity.points, [(o) => -o.y])[0] || {
          x: 0,
          y: 0,
        };
        coords = [
          coords[0] + point.x * cavityMultiplier[0],
          coords[1] + point.y * cavityMultiplier[1],
        ];
        setCanvasCoord(coords);
      } else {
        setCanvasCoord(null);
      }
    }
  };

  const onScroll = (event) => {
    event.preventDefault();
    let delta = event.deltaX + event.deltaY;
    if (componentPlacement) {
      setDeltaWheel(deltaWheel + delta);
    }
  };

  const keyEvent = (e) => {
    if (e.keyCode === 27) {
      if (newCavityData) {
        setNewCavityData(null);
        history.goBack();
      }
    }
  };

  return (
    <>
      <Wrapper
        onMouseUp={() => {
          setMouseDown(false);
          setDragPlacementStart(null);
        }}
        onMouseDown={() => {
          setMouseDown(true);
          setMouseClicked(true);
        }}
        ref={wrapperElem}
      >
        {imageLoading ? <Loader style={{ left: "48%" }} /> : null}
        <Sketch
          setup={setup}
          draw={draw}
          windowResized={resized}
          keyPressed={keyEvent}
        />
      </Wrapper>

      {((spinnerFlag && !imageLoading) || !componentImagesLoading) && (
        <Spinner
          text={"processing"}
          position={{ top: "100px", right: "200px" }}
        />
      )}

      {/*{selectedCavity && canvasCoord && !componentPlacement &&*/}
      {selectedCavity &&
        canvasCoord &&
        !ignoreComponentChanges &&
        deleteCavity && (
          <CustomButton
            label="Delete Cavity"
            icon={<FontAwesomeIcon icon={faTrashAlt} />}
            background={`orangered`}
            rounded
            style={{
              position: "absolute",
              zIndex: 1000,
              right: 30,
              top: 0,
              // I broke this somehow I think... but I almost think it's better static like this
              // left: canvasCoord[0],
              // top: canvasCoord[1],
              fontSize: 10,
            }}
            onClick={() => deleteCavity(selectedCavity)}
          />
        )}
      {!!(selectedPageOnCanvas && !noAddNote) && (
        <CustomButton
          label="Add note"
          icon={<FontAwesomeIcon icon={faStickyNote} />}
          background={`#61A431`}
          rounded
          style={{
            position: "absolute",
            zIndex: 1,
            right: "3%",
            top: "10%",
            fontSize: 10,
          }}
          onClick={() => setAddNoteEvent(true)}
        />
      )}
    </>
  );
};

export default Cavities;

export function fillImageOverrideJson(
  componentPlacement,
  values,
  newFlippedProp,
  newLabelData
) {
  if (!componentPlacement[0]) {
    return null;
  }

  const { imageOverrideJson, imageId, image, isHorizontalFlipped, labelData } =
    componentPlacement[0];

  const imageOverrideJsonCopy = cloneDeep(imageOverrideJson || []);
  const isImageExist = imageOverrideJsonCopy.some(
    (image) => image.imageId === imageId
  );

  if (!isImageExist) {
    imageOverrideJsonCopy.push({
      imageId,
      imageUrl: image,
      isHorizontalFlipped: newFlippedProp ?? isHorizontalFlipped,
      labelData: newLabelData || labelData,
      ...values,
    });
  }

  return imageOverrideJsonCopy.map((image) => {
    if (image.imageId === imageId) {
      return {
        ...image,
        ...values,
        labelData: newLabelData || labelData,
        isHorizontalFlipped: newFlippedProp ?? isHorizontalFlipped,
      };
    }

    return image;
  });
}