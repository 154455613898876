import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomCheckbox from '../../../../components/Inputs/CustomCheckbox';
import CustomButton from '../../../../components/Buttons/CustomButton';
import { showSnackbar } from '../../../../redux/actions/snackbar';
import { pdfGeneragtionValidation } from '../helper';
import styled from '@emotion/styled';
import useWindowResizeInnerHeight from '../../../../hooks/useWindowResizeInnerHeight';
import { MIN_SCREEN_HEIGHT } from '../../../../constants/constants';

const HideLabels = styled.div`
  position: absolute;
  bottom: 65px;
  display: flex;
  justify-content: center;
  width: 80%;
`;

export default function BottomActions(props) {
  const [hideLabels, setHideLabels] = useState(false);
  const dispatch = useDispatch();

  const {
    selectedPage,
    updateAssemblyOrder,
    versionValue,
    componentAssignments,
    setRunPdfGeneration,
    pages,
    objects,
    setSelectedPage,
    setAllNotes,
    allNotes,
    notesObj,
    kit,
    manufacturing,
    buildDefaultPages,
    SubmitSection,
    setNotesObj,
  } = props;

  const innerHeight = useWindowResizeInnerHeight();

  const addPage = () => {
    if (!selectedPage) {
      addPageToTop();
      return;
    }

    updateSelectedPage();

    updateAssemblyOrder(
      updateComponentsPageId(),
      addPageAboveSelected(),
      versionValue,
    );
  };

  const savePdf = () => {
    const smallScreen = innerHeight < MIN_SCREEN_HEIGHT;
    const inValid = pdfGeneragtionValidation(
      versionValue,
      componentAssignments,
      dispatch,
      showSnackbar,
      smallScreen,
    );

    if (!inValid) {
      setRunPdfGeneration(true);
    }
  };

  const addPageAboveSelected = () => {
    const newPages = [];

    pages.forEach((page) => {
      if (selectedPage?.id === page.id) {
        newPages.push(
          {
            id: page.id,
            image: null,
          },
          { ...page, id: page.id + 1 },
        );
      } else {
        newPages.push(
          page.id < selectedPage?.id ? page : { ...page, id: page.id + 1 },
        );
      }
    });

    return newPages;
  };

  const updateComponentsPageId = () => {
    return objects.map((o) =>
      o.pageId >= selectedPage.id ? { ...o, pageId: o.pageId + 1 } : o,
    );
  };

  const updateSelectedPage = () => {
    setSelectedPage({
      ...selectedPage,
      id: selectedPage.id + 1,
    });
  };

  const addPageToTop = () => {
    const components = objects.map((o) => ({ ...o, pageId: o.pageId + 1 }));
    const newPages = [
      { id: 0, image: null },
      ...pages.map((p) => ({ ...p, id: p.id + 1 })),
    ];

    updateAssemblyOrder(components, newPages, versionValue);
  };
  const hideAllLabels = () => {
    setAllNotes([
      ...allNotes.map((note) => ({ ...note, hideOnPDF: !hideLabels })),
    ]);
    setNotesObj([
      ...notesObj.map((note) => ({ ...note, hideOnPDF: !hideLabels })),
    ]);
    setHideLabels(!hideLabels);
  };

  return (
    <>
      <SubmitSection>
        <CustomButton
          rounded
          label={'Save PDF'}
          background={`#61A431`}
          onClick={savePdf}
          style={{ fontSize: 11, padding: '5px 10px' }}
        />

        <CustomButton
          rounded
          label={'Add Page'}
          onClick={addPage}
          disabled={kit?.status === manufacturing}
          style={{ fontSize: 11, padding: '5px 10px' }}
        />
        <CustomButton
          rounded
          label="Reset Default Pages"
          onClick={() => buildDefaultPages()}
          background={'cadetblue'}
          style={{ fontSize: 11, padding: '5px 10px' }}
        />
      </SubmitSection>
      <HideLabels>
        <CustomCheckbox
          label="Hide Labels on PDF"
          checked={hideLabels}
          onChange={hideAllLabels}
        />
      </HideLabels>
    </>
  );
}
