import styled from '@emotion/styled';
import React from 'react';
import {
    anesthesiaOptions,
    needleTypeOptions,
    spinalNeedleTypeOptions,
    pouchVsAdvancerTubeOptions,
    multiPortVsSingleOpenEndHoleOptions,
    nerveBlockOptions,
    lorOptions,
    antisepticOptions,
    anesthesialProps
} from '../../../NewKitCatalog/Filters/utils';
import CustomSelect from '../../../../components/Selects/CustomSelect';
import ButtonGroup from '../../../../components/Buttons/ButtonGroup';
import CustomCheckbox from '../../../../components/Inputs/CustomCheckbox';

const Title = styled.div`
    font-weight: 500;
    padding: 10px 0px;
`;

const Selects = styled.div`
    [class*='-control'] {
        height: 100% !important;
    }
    [class*='-multiValue'] {
        background: #ced3ec;
    }
`;

const AnesthesiaOptionTypes = ({ kit, setKit }) => {
    const changePouchOrAdvancer = ({ value }, isSet = true) => setKit({ ...kit, pouchVsAdvancerTube: isSet ? value : undefined });
    const changeMultiport = ({ value }, isSet = true) => setKit({ ...kit, multiPortVsSingleOpenEndHole: isSet ? value : undefined });
    const changeNerveBlock = ({ value }, isSet = true) => setKit({ ...kit, stimulating: false, flexBlock: false, stimuQuick: false, nonStimulating: false, [value]: isSet });
    const changeLOR = ({ value }, isSet = true) => setKit({ ...kit, plastic: false, glass: false, [value]: isSet });
    const changeAntiseptic = ({ value }, isSet = true) => setKit({ ...kit, povidoneLodine: false, duraprep: false, [value]: isSet });

    return (
        <div style={{ pointerEvents: kit.anesthesia ? 'auto' : 'none', opacity: kit.anesthesia ? 1 : .4, paddingRight: 10 }}>
            <Title style={{ textAlign: 'center' }}>Anesthesia Options</Title>
            <div style={{ paddingRight: 10, paddingBottom: 20 }}>
                <Selects>
                    <CustomSelect
                        applyStyles
                        isSearchable={false}
                        isClearable={false}
                        options={anesthesiaOptions}
                        onChange={(o) => setKit({ ...kit, ...anesthesialProps, [o.value]: o.value })}
                        value={anesthesiaOptions.find(ao => !!kit[ao.value])}
                        placeholder='Select Option'

                    />
                </Selects>
            </div>

            <div style={{ pointerEvents: (kit.epidural || kit.combined) ? 'auto' : 'none', opacity: (kit.epidural || kit.combined) ? 1 : .4 }}>
                <ButtonGroup showNone buttons={pouchVsAdvancerTubeOptions} isActive={kit.pouchVsAdvancerTube} onClick={changePouchOrAdvancer} />
                <br />
                <ButtonGroup showNone buttons={multiPortVsSingleOpenEndHoleOptions} isActive={kit.multiPortVsSingleOpenEndHole} onClick={changeMultiport} />
            </div>

            <div style={{ pointerEvents: (kit.epidural || kit.combined || kit.singleShot) ? 'auto' : 'none', opacity: (kit.epidural || kit.combined || kit.singleShot) ? 1 : .4, paddingRight: 10 }}>
                <Title>Needle Options</Title>
                <Selects>
                    <CustomSelect
                        applyStyles
                        isSearchable={false}
                        isClearable={false}
                        options={needleTypeOptions}
                        onChange={(o) => setKit({
                            ...kit,
                            epiduralNeedleType: o ? o.map(option => option.value) : null,
                            ...(kit.epidural && { spinalNeedleType: null })
                        })}
                        value={needleTypeOptions.filter(o => kit.epiduralNeedleType?.find(ent => ent === o.value))}
                        placeholder='Epidural Needle Type'
                        isMulti
                        closeMenuOnSelect={false}
                    />
                </Selects>
            </div>
            <div style={{ pointerEvents: (kit.spinal || kit.combined || (kit.epiduralNeedleType && !kit.singleShot)) ? 'auto' : 'none', opacity: (kit.spinal || kit.combined || (kit.epiduralNeedleType && !kit.singleShot)) ? 1 : .4 }}>
                <Title>Spinal Options</Title>
                <Selects>
                    <CustomSelect
                        applyStyles
                        isSearchable={false}
                        isClearable={false}
                        options={spinalNeedleTypeOptions}
                        onChange={(o) => setKit({
                            ...kit,
                            spinalNeedleType: o ? o.map(option => option.value) : null,
                            ...(kit.spinal && { epiduralNeedleType: null })
                        })}
                        value={spinalNeedleTypeOptions.filter(o => kit.spinalNeedleType?.find(snt => snt === o.value))}
                        placeholder='Spinal Needle Type'
                        isMulti
                        closeMenuOnSelect={false}
                    />
                </Selects>
            </div>
            <br />

            <div style={{ pointerEvents: kit.nerveBlock ? 'auto' : 'none', opacity: kit.nerveBlock ? 1 : .4 }}>
                <Title>Peripheral Nerve Block (PNB)</Title>
                <ButtonGroup showNone buttons={nerveBlockOptions} isActive={nerveBlockOptions.find(ao => kit[ao.value])?.value} onClick={changeNerveBlock} />
                {kit.stimulating && <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <CustomCheckbox label='FlexBlock' onChange={() => setKit({ ...kit, flexBlock: !kit.flexBlock })} checked={kit.flexBlock} />
                    <CustomCheckbox label='StimuQuick' onChange={() => setKit({ ...kit, stimuQuick: !kit.stimuQuick })} checked={kit.stimuQuick} />
                </div>}
            </div>

            <Title>LOR Options</Title>
            <ButtonGroup showNone buttons={lorOptions} isActive={lorOptions.find(ao => kit[ao.value])?.value} onClick={changeLOR} />
            <Title>Antiseptic Options</Title>
            <ButtonGroup showNone buttons={antisepticOptions} isActive={antisepticOptions.find(ao => kit[ao.value])?.value} onClick={changeAntiseptic} />
        </div>
    );
};

export default AnesthesiaOptionTypes;
