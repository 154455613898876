export const validateField = (fieldName, value, isDirectPurchase) => {
    const trimmedValue = value ? value.toString().trim() : '';

    switch (fieldName) {
        case 'regionalManager':
        case 'salesRep':
        case 'contactTitle':
        case 'contactName':
        case 'accountNumber':
        case 'facilityName':
        case 'facilityStreet':
        case 'estUsage':
        case 'jitName':
            return (!isDirectPurchase && trimmedValue === '') ? 'This field is required' : '';
        case 'facilityCity':
            return trimmedValue === '' ? 'This field is required' :
                !trimmedValue.match(/^[A-Z a-z]*,[ A-Za-z][A-Za-z]*$/) ? 'Format is City, State (Chicago, Illinois)' : '';
        case 'facilityZipCode':
            return trimmedValue === '' ? 'This field is required' :
                !trimmedValue.match(/^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _]*$/) ? 'Special charcters are not allowed' : '';
        default:
            return '';
    };
};

export const validateFieldList = [
    'regionalManager',
    'salesRep',
    'contactTitle',
    'contactName',
    'accountNumber',
    'facilityName',
    'facilityStreet',
    'estUsage',
    'jitName',
    'facilityCity',
    'facilityZipCode'
];
