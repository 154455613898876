import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { assignTrayToBaseKit } from '../../../redux/actions/kits';
import Wrapper from '../../../components/Wrapper/Wrapper';
import TopSection from '../UserManagement/TopSection';
import Table from '../../../components/Tables/Table';
import { loadKitsTableData, updateKitTags } from '../../../redux/actions/kitTagging';
import PaginationNew from '../../../components/Pagination/PaginationNew';
import Loader from '../../../components/loaders/Loader';
import TagsContainer from './components/TagsContainer';
import FancyScroll from '../../../components/FancyScroll';
import CustomButton from '../../../components/Buttons/CustomButton';
import { getTags, mapTagsToKit, tagsForDisplay } from '../../NewKitCatalog/Filters/utils';
import Tabs from '../Components/Tabs';
import { loadKitTrays } from '../../../redux/actions/kitTrays';
import PublishLinkContainer from './components/PublishLinkContainer';

const Container = styled.div`
    display: flex;
    font-size: 14px;
    padding: 0px 20px;
    & > div:first-of-type {
        width: 67%;
        flex-direction: column;
        min-height: calc(100vh - 200px);
        position: relative;
    }
    & > div:last-of-type {
        flex-direction: column;
        width: 30%;
        min-height: calc(100vh - 200px);
        padding: 0px;
        & > div {width: 100%;}
        & > button {
            width: fit-content;
            align-self: center;
            font-size: 12px;
            background: #61A431;
            margin: 10px 0px;
        }
    }
`;

const Pagination = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 20px;
    width: 100%;
    & > div {
        ul {margin: 10px !important;}
    }
`;

const Header = styled.div`
    button, input, svg {font-size: 12px;}
    input {height: 35px;}
    & > div > div > div > div {top: 5px;}
`;

const Row = styled.tr(({ isActive }) => `
    &:hover {
        cursor: pointer;
        background: #E8E9EE;
    }
    background: ${isActive && '#E8E9EE'};
`);

const Column = styled.td`
    overflow: hidden;
    max-width: 120px;
    text-overflow: ellipsis;
`;

const PaginationButtons = styled.div`
    cursor: pointer;
    display: flex;
    width: 30%;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
`;

const tabs = ['Attributes', 'Links'];

const KitTagging = () => {
    const searchDelay = 1000;
    const pageSize = 40;
    const { kits, totalPages, loading, updateKitTagsLoading } = useSelector(state => state.kitTagging);
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [kitSearchValue, setKitSearchValue] = useState('');
    const [activeKit, setActiveKit] = useState(null);
    const [activeTab, setActiveTab] = useState(null);
    const [linksData, setLinksData] = useState({});

    useEffect(() => {
        dispatch(loadKitsTableData({ search: '', pageSize: pageSize, page: 0, loading: true }));
        dispatch(loadKitTrays());
    }, [dispatch]);

    const handleKitSearch = (e) => {
        setKitSearchValue(e.target.value);
        search(e.target.value);
    };

    const search = useMemo(() => _.debounce((value) => {
        setPage(0);
        dispatch(loadKitsTableData({ search: value, pageSize: pageSize, page: 0 }));
    }, searchDelay), [dispatch]);

    const handlePageChanges = (page) => {
        setPage(page);
        dispatch(loadKitsTableData({ search: kitSearchValue, pageSize: pageSize, page }));
    };

    const handleKitClick = (kit) => {
        setActiveKit({ ...kit, ...mapTagsToKit(kit.tags) });
        setActiveTab(tabs[0]);
    };

    const saveKitChanges = () => {
        const tags = getTags(activeKit);
        dispatch(updateKitTags(activeKit.id, tags, { loading: false, pageSize: pageSize, page, search: kitSearchValue }));
    };

    const saveLinksChanges = () => {
        dispatch(assignTrayToBaseKit(activeKit.id, linksData.trayId !== undefined ? linksData.trayId : activeKit.trayId, null,
            () => {
                dispatch(loadKitsTableData({ search: '', pageSize: pageSize, page: 0, loading: true }));
                setLinksData({});
                setActiveTab(tabs[0]);
                setActiveKit(null);
            },
            linksData.customKitId !== undefined ? linksData.customKitId : activeKit.customKitId
        ));
    };

    const displayTag = (tags, types) => tagsForDisplay[tags.find(t => types.includes(t.key))?.key] || '';

    if (loading) return <Loader style={{ left: '60%' }} />

    return (
        <div className="main-layout">
            <div className="content-area" style={{ height: '100%' }}>
                <Header>
                    <TopSection title='Kit Attributes' onChange={handleKitSearch} buttonLabel='Create New Component' searchPlaceholder='Kit Search...' searchable />
                </Header>
                <Container>
                    <Wrapper>
                        <FancyScroll offset={280} styles={{ color: "gray", backcolor: "unset" }}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>SKU</th>
                                        <th>Type</th>
                                        <th>Geo</th>
                                        <th>Procedure</th>
                                        <th>Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {kits.map(kit => <Row key={kit.id} onClick={() => handleKitClick(kit)} isActive={kit.id === activeKit?.id}>
                                        <td>{kit.number}</td>
                                        <td>{displayTag(kit.tags, ['vascular', 'anesthesia'])}</td>
                                        <td>{displayTag(kit.tags, ['us', 'ous'])}</td>
                                        <td>{displayTag(kit.tags, ['cvc', 'picc', 'midline', 'arterial', 'endurance'])}</td>
                                        <Column>{kit.labelName}</Column>
                                    </Row>)}
                                </tbody>
                            </Table>
                        </FancyScroll>
                        <Pagination>
                            <PaginationButtons onClick={() => handlePageChanges(0)}>First page</PaginationButtons>
                            <PaginationNew activePage={page} totalPages={totalPages} onChange={handlePageChanges} isMobile={true} pagesNumber={3} />
                            <PaginationButtons onClick={() => handlePageChanges(totalPages - 1)}>Last page</PaginationButtons>
                        </Pagination>
                    </Wrapper>
                    <Wrapper>
                        {activeKit && <Tabs tabs={tabs} setTab={setActiveTab} active={activeTab} />}
                        <FancyScroll offset={320} styles={{ color: "gray", backcolor: "unset" }}>
                            {!activeTab || activeTab === tabs[0] ?
                                <TagsContainer kit={activeKit} setKit={setActiveKit} /> :
                                activeKit && <PublishLinkContainer
                                    setActiveKit={setActiveKit}
                                    activeKit={activeKit}
                                    setLinksData={setLinksData}
                                    linksData={linksData}
                                />
                            }
                        </FancyScroll>
                        {activeKit && <CustomButton
                            icon={updateKitTagsLoading ? <i className="fas fa-spinner fa-spin" style={{ marginRight: 5 }}></i> : null}
                            label='Save Changes'
                            onClick={() => activeTab === tabs[0] ? saveKitChanges() : saveLinksChanges()}
                            disabled={updateKitTagsLoading}
                        />}
                    </Wrapper>
                </Container>
            </div>
        </div>
    );
};

export default KitTagging;
