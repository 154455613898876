import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cavities from '../../../Settings/KitTrays/Cavity/Cavities';
import { changeOrphanComponent } from '../../../../redux/actions/kits';
import GoBack from '../../Helpers/GoBack';

const CavitiesContainer = ({ prevSelected }) => {
  const { kitDetails, orphanComponent } = useSelector((state) => state.kits);
  const [selectedCavity, setSelectedCavity] = useState(null);
  const dispatch = useDispatch();
  const tray = kitDetails?.tray || {};

  // you can assign component to only empty / multiple cavity
  const emptyCavities = (tray.cavities || []).map((cavity) => {
    return {
      ...cavity,
      hidden: !(
        !kitDetails.componentAssignments?.find(
          (co) =>
            co.cavityId === cavity.id &&
            co.cavityId !== orphanComponent?.oldCavityId
        ) ||
        cavity.id === prevSelected?.id ||
        cavity.allowsMultiple
      ),
    };
  });

  const cavity = orphanComponent?.cavity || null;
  const [imageLoading, setImageLoading] = useState(true);
  useEffect(() => {
    setSelectedCavity(cavity);
  }, [cavity]);

  useEffect(() => {
    return () => {
      dispatch(changeOrphanComponent({ ...orphanComponent, cavity: null }));
    };
    // eslint-disable-next-line
  }, []);

  const setComponentPlacement = (params) => {
    const {
      values: { x, y, r },
    } = params[0];
    dispatch(changeOrphanComponent({ ...orphanComponent, x, y, r }));
  };

  const setCavity = (cavity) => {
    setSelectedCavity(cavity);
    dispatch(
      changeOrphanComponent({
        ...orphanComponent,
        cavity: cavity,
        trayId: tray.id,
      })
    );
  };

  const cancel = () => {
    setSelectedCavity(null);
    dispatch(
      changeOrphanComponent({
        ...orphanComponent,
        cavity: null,
        uncheckFloating: selectedCavity?.isFloating,
      })
    );
  };

  const message =
    emptyCavities.length === 0
      ? "No cavities available"
      : "Select Cavity to Move";

  return (
    <>
      {selectedCavity ? (
        <GoBack goBack={cancel} text="Select another cavity" />
      ) : (
        <div style={{ fontSize: 12, height: 35 }}>{message}</div>
      )}
      <Cavities
        tray={tray}
        cavities={emptyCavities}
        selectedCavity={selectedCavity}
        setSelectedCavity={setCavity}
        componentPlacement={[
          {
            image: orphanComponent?.active,
            values: {
              x: orphanComponent?.x,
              y: orphanComponent?.y,
              r: orphanComponent?.r,
            },
            cavityId: selectedCavity
              ? undefined
              : orphanComponent?.oldCavityId || prevSelected?.id,
            isHorizontalFlipped: orphanComponent?.isHorizontalFlipped,
          },
        ]}
        setComponentPlacement={setComponentPlacement}
        imageLoading={imageLoading}
        setImageLoading={setImageLoading}
        fps={30}
      />
    </>
  );
};

export default CavitiesContainer;
