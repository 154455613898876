import styled from '@emotion/styled';
import { faShoppingBasket, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import CustomButton from '../Buttons/CustomButton';
import FancyScroll from '../FancyScroll';

const Container = styled.div`
    display: flex;
    top: 85px;
    position: fixed;
    right: ${props => props.isOpen ? -10 : -250}px;
    transition: all 0.3s linear;
    width: 300px;
    z-index: 99;
    & > svg {
        background: #fff;
        padding: 10px 15px;
        box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.16);
        border-radius: 20px 0 0 20px;
        color: #959595;
        cursor: pointer;
        font-size: 46px;
    }
    & > div {
        display: flex;
        flex-direction: column;
        background: white;
        padding: 10px;
    }
`;

const Buttons = styled.div`
    background: white;
    font-size: 12px;
    margin-bottom: 10px;
    button {
        border-radius: 0px;
        border: 1px solid #1d265b;
        width: 50%;
    }
`;

const Item = styled.div`
    padding: 10px;
    text-align: left;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ceeeee;
    position: relative;
    &:hover {
        background-color: ${props => props.hoverEffect ? '#ceeeee' : 'white'};
        cursor: ${props => props.hoverEffect ? 'pointer' : 'auto'};
    }
    span {margin-bottom: 5px;}
    & > svg {
        height: 15px;
        position: absolute;
        right: 5px;
        top: 4px;
        background: orangered;
        color: white;
        padding: 2px;
        border-radius: 4px;
        width: 15px !important;
        cursor: pointer;
    }
`;

const EmptyList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 12px;
    padding: 0px 10px;
    text-align: center;
`;

const SubmitSection = styled.div`
    display: flex;
    flex-direction: column;
    button {
        border-radius: 0px;
        width: fit-content;
        font-size: 12px;
        align-self: center;
        margin-bottom: 10px;
        width: 65%;
    }
`;

const CollapsableMenu = ({ showMenu, open, kits, components, onKitClick, onComponentDelete, createNewKit, justLike }) => {
    const [showKits, setShowKits] = useState(true);

    const showEmptyList = <EmptyList>No starred component available right now</EmptyList>;

    const kitList = kits.map(kit => <Item key={kit.id} onClick={() => onKitClick(kit)} hoverEffect={true}>
        <span>{kit.name}</span>
        <span>{`Components Count ${kit.componentsCount}`}</span>
    </Item>);

    const componentList = components.map(component => <Item key={component.id}>
        <span>{component.number}</span>
        <span>{component.description}</span>
        <FontAwesomeIcon icon={faTimes} onClick={() => onComponentDelete(component)} />
    </Item>);

    return (
        <Container isOpen={showMenu}>
            <FontAwesomeIcon icon={faShoppingBasket} onClick={() => open(!showMenu)} />
            <div>
                <Buttons halfWidth>
                    <CustomButton label='Custom Kits' background={showKits ? '#1d265b' : '#ffff'} color={showKits ? '#ffff' : '#1d265b'} onClick={() => setShowKits(true)} />
                    <CustomButton label='Components' background={!showKits ? '#1d265b' : '#ffff'} color={!showKits ? '#ffff' : '#1d265b'} onClick={() => setShowKits(false)} />
                </Buttons>
                <FancyScroll offset={400}>
                    {showKits ? kitList : !components.length ? showEmptyList : componentList}
                </FancyScroll>
                <SubmitSection>
                    <CustomButton label='CREATE NEW KIT' onClick={() => createNewKit(!showKits)} disabled={!showKits && !components.length} />
                    {!showKits && <CustomButton label='JUST LIKE' onClick={justLike} disabled={!components.length} />}
                </SubmitSection>
            </div>
        </Container>
    )
};

export default CollapsableMenu;
