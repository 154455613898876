import * as actionTypes from '../../constants/actionTypes';
import { getToken } from '../../utils/getToken';
import axios from '../axiosMiddleware/axios';
import { loadKitInfoFromKitDesign } from './kits';
import { loadCmsComponentTooltipInfo } from './kitTrays';
import { showSnackbar } from './snackbar';

export const createCmsComponent = ({ sku, description, label, categoryId }) => async dispatch => {
    dispatch({ type: actionTypes.CREATE_CMS_COMPONENTS_START });

    const data = {
        Number: sku,
        Description: description,
        LabelName: label,
        CategoryId: categoryId
    };

    try {
        const res = await axios.post(`/api/cms/components`, data, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch(loadCmsComponents({}))
        dispatch({ type: actionTypes.CREATE_CMS_COMPONENTS_SUCCESS, data: res.data });
        dispatch(showSnackbar('success', 'Component created!'));
    } catch (err) {
        dispatch({ type: actionTypes.CREATE_CMS_COMPONENTS_FAIL, err });
        dispatch(showSnackbar('error', 'Failed to create component'));
    }
}

export const loadCmsComponents = ({ partTypeId = '', search = '', page = 0, categoryId = '' }, isFallback) => async dispatch => {
    dispatch({ type: actionTypes.GET_CMS_COMPONENTS_START });

    const params = {
        Categories: categoryId,
        PartTypeId: partTypeId,
        Search: search,
        PageSize: 12,
        Page: page
    };

    try {
        const res = await axios.get(`/api/cms/components`, {
            params,
            headers: { Authorization: 'Bearer ' + getToken() }
        });

        dispatch({ type: actionTypes.GET_CMS_COMPONENTS_SUCCESS, data: res.data, isFallback });
    } catch (err) {
        dispatch({ type: actionTypes.GET_CMS_COMPONENTS_FAIL, err });
        dispatch(showSnackbar('error', 'Load component list failed'));
    }
};

export const loadCmsComponentInfo = (componentId, isFallback) => async dispatch => {
    dispatch({ type: actionTypes.GET_CMS_COMPONENT_INFO_START });

    try {
        const res = await axios.get(`/api/cms/components/${componentId}`, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.GET_CMS_COMPONENT_INFO_SUCCESS, data: res.data, isFallback });
    } catch (err) {
        dispatch({ type: actionTypes.GET_CMS_COMPONENT_INFO_FAIL, error: err });
        dispatch(showSnackbar('error', 'Load component info failed'));
    }
};

export const updateCmsComponentInfo = (component) => async dispatch => {
    dispatch({ type: actionTypes.UPDATE_CMS_COMPONENT_INFO_START });

    const requestBody = {
        Number: component.number,
        Description: component.description,
        LabelName: component.labelName,
        IsVisible: component.isVisible,
        IsSterile: component.isSterile,
        OverrideCost: component.overrideCost,
        Width: component.width,
        Height: component.height,
        Depth: component.depth,
        Weight: component.weight,
        CategoryId: component.category.id,
        ...(component.partType && {
            PartTypeId: component.partType.id
        })
    };

    try {
        await axios.put(`/api/cms/components/${component.id}`, requestBody, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.UPDATE_CMS_COMPONENT_INFO_SUCCESS });
        dispatch(showSnackbar('success', 'Component successfully updated'));
        dispatch(loadCmsComponents({}));
    } catch (err) {
        dispatch({ type: actionTypes.UPDATE_CMS_COMPONENT_INFO_FAIL, error: err });
        dispatch(showSnackbar('error', 'Component not updated'));
    }
};

export const createCmsComponentImage = (componentId, file, name, type, quantity = 1) => async dispatch => {
    dispatch({ type: actionTypes.CREATE_CMS_COMPONENT_IMAGE_START });

    const formData = new FormData();

    formData.append('Name', name);
    formData.append('image', file);
    formData.append('Quantity', quantity);

    try {
        await axios.post(`/api/cms/components/${componentId}/images`, formData, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.CREATE_CMS_COMPONENT_IMAGE_SUCCESS });
        dispatch(loadCmsComponentInfo(componentId));
        dispatch(showSnackbar('success', 'Image successfully uploaded'));
        type && dispatch(loadCmsComponentTooltipInfo(componentId, type));
    } catch (err) {
        dispatch({ type: actionTypes.CREATE_CMS_COMPONENT_IMAGE_FAIL });
        dispatch(showSnackbar('error', 'Image not uploaded'));
    }
};

export const updateCmsComponentImage =
  (
    componentId,
    imageId,
    file,
    name,
    type,
    quantity = 1,
    labelData,
    keepOldState
  ) =>
  async (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_CMS_COMPONENT_IMAGE_START });

    const formData = new FormData();

    formData.append("Name", name);
    formData.append("Quantity", quantity);
    formData.append("image", file);
    labelData && formData.append("LabelData", JSON.stringify(labelData));

    try {
      await axios.put(
        `/api/cms/components/${componentId}/images/${imageId}`,
        formData,
        { headers: { Authorization: "Bearer " + getToken() } }
      );

      dispatch({ type: actionTypes.UPDATE_CMS_COMPONENT_IMAGE_SUCCESS });
      dispatch(showSnackbar("success", "Image successfully updated"));
      if (keepOldState) {
        return;
      }
      dispatch(loadCmsComponentInfo(componentId));
      type && dispatch(loadCmsComponentTooltipInfo(componentId, type));
    } catch (err) {
      dispatch({ type: actionTypes.UPDATE_CMS_COMPONENT_IMAGE_FAIL });
      dispatch(showSnackbar("error", "Image not uploaded"));
    }
  };

export const deleteCmsComponentImage = (componentId, imageId, type) => async dispatch => {
    dispatch({ type: actionTypes.DELETE_CMS_COMPONENT_IMAGE_START });

    try {
        await axios.delete(`/api/cms/components/${componentId}/images/${imageId}`, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.DELETE_CMS_COMPONENT_IMAGE_SUCCESS });
        dispatch(loadCmsComponentInfo(componentId));
        dispatch(showSnackbar('success', 'Image successfully deleted'));
        type && dispatch(loadCmsComponentTooltipInfo(componentId, type)); // removing image from tray management should update tooltip info
    } catch (err) {
        dispatch({ type: actionTypes.DELETE_CMS_COMPONENT_IMAGE_FAIL });
        dispatch(showSnackbar('error', 'Can not delete image'));
    }
};

export const clearFallbackComponentSearchList = () => dispatch => {
    dispatch({ type: actionTypes.CLEAR_FALLBACK_COMPONENT_SEARCH_LIST });
};

export const addFallbackToComponent = (componentId, fallbackId) => async dispatch => {
    dispatch({ type: actionTypes.ADD_FALLBACK_COMPONENT_START });

    try {
        await axios.post(`/api/cms/components/${componentId}/replacements/${fallbackId}`, null, { headers: { Authorization: 'Bearer ' + getToken() } });
        dispatch({ type: actionTypes.ADD_FALLBACK_COMPONENT_SUCCESS });
        dispatch(showSnackbar('success', 'Fallback created'));
    } catch (err) {
        dispatch({ type: actionTypes.ADD_FALLBACK_COMPONENT_FAIL });
        dispatch(showSnackbar('error', 'Fallback creation failed'));
    }
};

export const deleteFallbackFromComponent = (componentId, fallbackId) => async dispatch => {
    dispatch({ type: actionTypes.DELETE_FALLBACK_COMPONENT_START });

    try {
        await axios.delete(`/api/cms/components/${componentId}/replacements/${fallbackId}`, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.DELETE_FALLBACK_COMPONENT_SUCCESS });
        dispatch(showSnackbar('success', 'Fallback deleted'));
    } catch (err) {
        dispatch({ type: actionTypes.DELETE_FALLBACK_COMPONENT_FAIL });
        dispatch(showSnackbar('error', 'Fallback delete error'));
    }
};

export const uploadComponentsPricing = (file) => async dispatch => {
    dispatch({ type: actionTypes.UPLOAD_COMPONENTS_PRICING_START });

    const formaData = new FormData();
    formaData.append('file', file);

    try {
        await axios.post('/api/integration/pricing', formaData, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.UPLOAD_COMPONENTS_PRICING_SUCCESS });
        dispatch(showSnackbar('success', 'Components pricing uploaded'));
    } catch (err) {
        dispatch({ type: actionTypes.UPLOAD_COMPONENTS_PRICING_FAIL });
        dispatch(showSnackbar('error', err.response.status === 400 ? err.response.data : 'Upload failed'));
    }
};

export const resetOverrideForComponent = (kitId, componentId, cavityId) => async dispatch => {
    dispatch({ type: actionTypes.RESET_OVERRIDE_START });

    try {
        await axios.delete(`/api/kit-design/kits/${kitId}/overrides/${componentId}/${cavityId}`, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.RESET_OVERRIDE_SUCCESS });
        dispatch(showSnackbar('success', 'Override deleted'));
        dispatch(loadKitInfoFromKitDesign(kitId, true));
    } catch (err) {
        dispatch({ type: actionTypes.RESET_OVERRIDE_FAIL });
        dispatch(showSnackbar('error', 'Failed to reset override'));
    }
};

export const updateCmsComponentImageOrder =
  (componentId, order) => async (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_COMPONENT_IMAGE_ORDER_START });

    try {
      await axios.put(
        `/api/cms/components/${componentId}/images/order`,
        order,
        { headers: { Authorization: "Bearer " + getToken() } }
      );

      dispatch({ type: actionTypes.UPDATE_COMPONENT_IMAGE_ORDER_SUCCESS });
      dispatch(showSnackbar("success", "Image order updated"));
    } catch (err) {
      dispatch({ type: actionTypes.UPDATE_COMPONENT_IMAGE_ORDER_FAIL });
      dispatch(showSnackbar("error", "Image order update failed"));
    }
  };