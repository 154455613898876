import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import styled from '@emotion/styled';
import microsoftImage from '../../shared/img/microsoft.png';
import { showSnackbar } from '../../redux/actions/snackbar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;
  align-items: center;
  button {
    padding: 5px !important;
  }
  img {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    margin-right: 10px;
  }
`;

export default function MicrosoftSSOLogin({ dispatch }) {
  const { instance } = useMsal();
  const active = instance.getActiveAccount();

  const token = window.localStorage.getItem('jwt');

  const login = () => {
    instance.loginPopup({ ...loginRequest, prompt: 'create' }).catch((err) => {
      console.log(err);
      dispatch(showSnackbar('error', err));
    });
  };

  return (
    <Container>
      {!active || !token ? (
        <UnauthenticatedTemplate>
          <button onClick={login}>
            <img src={microsoftImage} width={30} height={30} />
            Sign in with Microsoft
          </button>
        </UnauthenticatedTemplate>
      ) : null}
    </Container>
  );
}
