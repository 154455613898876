import * as actionTypes from '../../constants/actionTypes';
import { getToken } from '../../utils/getToken';
import axios from '../axiosMiddleware/axios';
import { showSnackbar } from './snackbar';

export const loadComponentCatalog = ({ partTypeId = '', search = '', page = 0, categoryId = '' }) => async dispatch => {
    dispatch({ type: actionTypes.GET_COMPONENTS_START });

    const params = {
        Categories: categoryId,
        PartTypeId: partTypeId,
        Search: search,
        PageSize: 12,
        Page: page
    };

    try {
        const res = await axios.get(`/api/components`, {
            params,
            headers: { Authorization: 'Bearer ' + getToken() }
        });
        dispatch({ type: actionTypes.GET_COMPONENTS_SUCCESS, data: res.data });

        return res.data;
    } catch (err) {
        dispatch(showSnackbar('error', 'Can\t get list of components'));
    }
};

export const clearComponentCatalogList = () => async dispatch => dispatch({ type: actionTypes.GET_COMPONENTS_SUCCESS, data: { components: [], totalCount: 0, totalPages: 0 } });

export const getComponentsFromCart = () => async dispatch => {
    dispatch({ type: actionTypes.GET_COMPONENTS_FROM_CART_START });

    try {
        const res = await axios.get('/api/cart', { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.GET_COMPONENTS_FROM_CART_SUCCESS, data: res.data });
    } catch (err) {
        dispatch({ type: actionTypes.GET_COMPONENTS_FROM_CART_FAIL });
        dispatch(showSnackbar('error', 'Load components from cart failed'));
    }
};

export const addComponentToCart = (id) => async dispatch => {
    dispatch({ type: actionTypes.ADD_COMPONENT_TO_CART_START });

    try {
        await axios.post(`/api/cart/${id}`, null, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch(getComponentsFromCart());
        dispatch(showSnackbar('success', 'Component successfully added to cart'));
    } catch (err) {
        dispatch(showSnackbar('error', err.response.data));
    }
};

export const deleteComponentFromCart = (id) => async dispatch => {
    dispatch({ type: actionTypes.ADD_COMPONENT_TO_CART_START });

    try {
        await axios.delete(`/api/cart/${id}`, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch(getComponentsFromCart());
        dispatch(showSnackbar('success', 'Component successfully deleted from cart'));
    } catch (err) {
        dispatch(showSnackbar('error', err.response.data));
    }
};

export const loadSavedKits = () => async dispatch => {
    dispatch({ type: actionTypes.GET_SAVED_KITS_START });

    try {
        const res = await axios.get('/api/custom-kits/saved', { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.GET_SAVED_KITS_SUCCESS, kits: res.data });
    } catch (err) {
        dispatch(showSnackbar('error', err.response?.data || 'Something went wrong, please try later'));
    }
};

export const addComponentToKit = (kitId, component) => async dispatch => {
    dispatch({ type: actionTypes.ADD_COMPONENT_TO_KIT_START });

    try {
        const requestBody = [{
            Id: component.id,
            Quantity: 1
        }];

        await axios.post(`/api/custom-kits/${kitId}/components`, requestBody, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch(showSnackbar('success', 'Component successfully added to kit'));
        dispatch(loadSavedKits());
    } catch (err) {
        dispatch(showSnackbar('error', err.response?.data || 'Something went wrong, please try later'));
    }
};

export const getCustomKitById = (id, component) => async dispatch => {
    try {
        const res = await axios.get(`/api/custom-kits/${id}`, { headers: { Authorization: 'Bearer ' + getToken() } });

        if (res.data.components.find(c => c.id === component.id)) {
            dispatch(showSnackbar('warning', 'Component duplicate'));
        } else {
            dispatch(addComponentToKit(id, component));
        }
    } catch (err) {
        dispatch(showSnackbar('error', 'Something went wrong, please try later'));
    }
};

export const loadComponentCategories = () => async dispatch => {
    dispatch({ type: actionTypes.GET_COMPONENT_CATEGORIES_START });

    try {
        const res = await axios.get('/api/components/categories', { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.GET_COMPONENT_CATEGORIES_SUCCESS, data: res.data });
    } catch (err) {
        dispatch({ type: actionTypes.GET_COMPONENT_CATEGORIES_FAIL, error: err });
    }
};

export const listOfCompatibleComponents = (CavityId) => async dispatch => {
    dispatch({ type: actionTypes.SWAP_COMPONENT_TO_KIT_AUTOASSIGN_START });

    try {
        const res = await axios.get(`/api/kit-design/cavity/${CavityId}/components`, { headers: { Authorization: 'Bearer ' + getToken() } });
        dispatch({ type: actionTypes.GET_COMPONENTS_SUCCESS, data: { components: res.data, totalPages: 1, totalCount: res.data.length } });
        return res.data;
    } catch (err) {
        dispatch(showSnackbar('error', 'Can\t get list of components'));
    }
}