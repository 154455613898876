import * as actionTypes from '../../constants/actionTypes';
import { getToken } from '../../utils/getToken';
import axios from '../axiosMiddleware/axios';
import qs from 'querystring';
import { showSnackbar } from './snackbar';

export const loadKitListFromKitFinder = (params) => async dispatch => {
    dispatch({ type: actionTypes.GET_KIT_LIST_FROM_KIT_FINDER_START });
    try {
        const res = await axios.get(`/api/kits/finder?${qs.encode(params)}`, { headers: { Authorization: 'Bearer ' + getToken() } });
        dispatch({ type: actionTypes.GET_KIT_LIST_FROM_KIT_FINDER_SUCCESS, data: res.data });
    } catch (err) {
        dispatch({ type: actionTypes.GET_KIT_LIST_FROM_KIT_FINDER_FAIL, error: err });
    }
};

export const loadComponentInfoById = (id) => async dispatch => {
    dispatch({ type: actionTypes.GET_COMPONENT_INFO_FOR_KIT_FINDER_FAIL });

    try {
        const res = await axios.get(`/api/components/${id}`, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.GET_COMPONENT_INFO_FOR_KIT_FINDER_SUCCESS, data: res.data });
    } catch (err) {
        dispatch({ type: actionTypes.GET_COMPONENT_INFO_FOR_KIT_FINDER_FAIL, error: err });
    }
};

export const loadComponentList = ({ partTypeId = '', search = '', page = 0, categoryId = '' }) => async dispatch => {
    if (search) {
        dispatch({ type: actionTypes.GET_COMPONENT_LIST_FOR_KIT_FINDER_START });

        const params = {
            Categories: categoryId,
            PartTypeId: partTypeId,
            Search: search,
            PageSize: 50,
            Page: page
        };

        try {
            const res = await axios.get(`/api/components`, {
                params,
                headers: { Authorization: 'Bearer ' + getToken() }
            });
            dispatch({ type: actionTypes.GET_COMPONENT_LIST_FOR_KIT_FINDER_SUCCESS, data: res.data });

            return res.data;
        } catch (err) {
            dispatch(showSnackbar('error', 'Can\t get list of components'));
        }
    } else {
        dispatch(clearComponentList());
    }
};

export const clearComponentList = () => dispatch => dispatch({ type: actionTypes.CLEAR_COMPONENT_LIST_FOR_KIT_FINDER });

export const saveFilter = (filter) => dispatch => dispatch({ type: actionTypes.SAVE_FILTER_STATE, filter });
