import React from 'react';
import styled from '@emotion/styled';
import CustomSelect from '../../../components/Selects/CustomSelect';
import { multiSelectStyles } from '../../../constants/constants';
import SearchRounded from '../../../components/Inputs/SearchRounded';

const Container = styled.div`
    margin: 0px 10px 20px 10px;
    display: flex;
    align-items: flex-end;
    color: white;
    flex-direction: column;

    & > div {
        width: 100%;
        font-size: 12px;
    }
`;

const Filter = ({ filterData, onChange, mainDevices, onSearchChange }) => {
    return (
        <Container>
            <div>
                <CustomSelect
                    customStyles={{ ...multiSelectStyles, input: (provided) => ({ ...provided, height: 20, color: 'white' }) }}
                    value={filterData.value}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    options={mainDevices}
                    onChange={(option) => onChange(option)}
                    placeholder='Filter by Main Device'
                    isMulti
                    closeMenuOnSelect={false}
                    isSearchable
                    isClearable={false}
                />
            </div>
            <div style={{ margin: 10, position: 'relative', color: '#1d265b' }}>
                <SearchRounded placeholder='Search by Tray SKU...' onChange={onSearchChange} />
            </div>
        </Container>
    );
};

export default Filter;
