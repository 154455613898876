import {
  faPlus,
  faRulerVertical,
  faCircle,
  faSyringe,
  faFileImage,
  faExclamationTriangle,
  faStickyNote,
  faTasks,
} from '@fortawesome/free-solid-svg-icons';
import { faCircle as fasCircle } from '@fortawesome/free-regular-svg-icons';

export const userManagementTableColumns = [
  { field: 'firstName', label: 'First', sortable: true },
  { field: 'lastName', label: 'Last', sortable: true },
  { field: 'userName', label: 'Email', sortable: true },
  { field: 'role', label: 'Role', sortable: true },
  { field: 'isActive', label: 'Access', sortable: true },
  { field: 'password', label: 'Password', sortable: false },
  { field: 'action', label: 'Edit', sortable: false },
];

export const overlayStyle = {
  content: {
    position: 'fixed',
    left: '10%',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  overlay: {
    position: 'absolute',
    height: 'calc(100% + 10px)',
    width: '100%',
    top: -10,
    left: 0,
    display: 'flex',
    textAlign: 'center',
    fontSize: '1.2em',
    color: '#FFF',
    background: 'rgba(0,0,0,0.7)',
    zIndex: '9999',
    WebkitTransition: 'opacity 500ms ease-in',
    transition: 'opacity 500ms ease-in',
    opacity: 1,
  },
};

export const selectStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: 150,
    paddingTop: 10,
    color: 'black',
  }),
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    height: 43,
    boxShadow: 'none',
    borderBottom: `2px solid ${state.menuIsOpen ? '#4285f4' : 'black'}`,
    borderRadius: 20,
    paddingLeft: 20,
    margin: 10,
    paddingTop: 0,
    outline: 'none',
    backgroundColor: 'white',
  }),
  placeholder: (provided) => ({
    ...provided,
    marginTop: -3,
    textAlign: 'center',
  }),
  singleValue: (provided) => ({
    ...provided,
    marginTop: -3,
    textAlign: 'center',
  }),
};

export const kitStatusTableColumns = [
  { field: 'created', label: 'Date', sortable: true },
  { field: 'name', label: 'Kit Name', sortable: true },
  { field: 'facilityName', label: 'Facility Name', sortable: true },
  { label: 'Created by', sortable: true, notForSales: true },
  { label: 'Updated by', sortable: true, notForSales: true },
  { field: 'status', label: 'Status', sortable: true },
  { label: 'Options', sortable: false },
  { label: 'Tray Image', sortable: false },
];

export const defaultUserManagementData = {
  firstName: '',
  lastName: '',
  userName: '',
  password: '',
  passwordConfirm: '',
};

export const editUserAction = 'edit';
export const createUserAction = 'create';

export const fieldRequiredMessage = 'This field is required';
export const fieldsNotMatchMessage = 'Passwords do not match';
export const userEmailExistMessage = 'This Email is already exist';

export const controlPanelRoutes = [
  {
    value: '/categories',
    label: 'Part Classes',
    visible: true,
    placeholder: 'Control panel',
    showSettings: true,
    subHeader: 'Settings',
  },
  {
    value: '/product-families',
    label: 'Product Families',
    visible: true,
    showSettings: true,
  },
  // {
  //   value: '/packaging-options',
  //   label: 'Packaging Options',
  //   visible: true,
  //   showSettings: true,
  // },
  {
    value: '/cms-components',
    label: 'Components',
    visible: true,
    showSettings: true,
  },
  {
    value: '/kit-attributes',
    label: 'Kit Attributes',
    visible: true,
    showSettings: false,
  },
  {
    value: '/kit-trays',
    label: 'Kit Trays',
    visible: true,
    showSettings: true,
  },
  {
    value: '/user-management',
    label: 'User Management',
    visible: true,
    showSettings: true,
  },
  {
    value: '/upload-components-pricing',
    label: 'Upload Components Pricing',
    visible: true,
    showSettings: true,
  },
  {
    value: '/information-logs',
    label: 'Information Logs',
    visible: true,
    showSettings: true,
  },
];

export const defaultRoutes = [
  {
    value: '/myprofile',
    label: 'My Profile',
    visible: true,
    placeholder: 'Navigation',
    showSettings: false,
  },
  {
    value: '/components',
    label: 'Component Catalog',
    visible: true,
    showSettings: false,
  },
  //{ value: '/kits', label: 'Kit Catalog', visible: true, showSettings: false },
  {
    value: '/kit-catalog',
    label: 'Kit Catalog',
    visible: true,
    showSettings: true,
  },
  {
    value: '/kitmaker',
    label: 'New Kit Request',
    visible: true,
    showSettings: false,
  },
  {
    value: '/savedask',
    label: 'ASK Requests',
    visible: true,
    showSettings: false,
    divider: true,
  },
];

export const betaRoutes = [
  {
    value: '/kitlist',
    label: 'Kit List',
    visible: true,
    showSettings: false,
    subHeader: 'Beta',
  },
  {
    value: '/my-kit-list',
    label: 'My Kit List',
    visible: true,
    showSettings: false,
    divider: true,
    roleLabel: 'Submitted Kits',
  },
];

export const cmsAccessRoleList = ['SuperAdmin', 'Admin', 'Engineering'];
export const generalAccessRoleList = ['Sales'];

export const snackbarColors = {
  error: '#d80505',
  warning: 'orange',
  success: '#61A431',
};

export const imageFormats = ['image/jpeg', 'image/png'];

export const cmsComponentsTabs = [
  // { path: '/cms-components/technical-data', label: 'Technical Details' },
  { path: '/cms-components/images', label: 'Image' },
  // { path: '/cms-components/auto-add', label: 'Auto Add' },
  {
    path: '/cms-components/compatible-replacement',
    label: 'Compatible Replacement',
  },
];

export const pagesHideMenu = [
  '/cms-components',
  '/kit-trays',
  '/kitlist',
  '/my-kit-list',
  '/kit-catalog',
  '/packaging-options',
  '/information-logs',
  '/kitmakercomponents',
];

export const kitTrayRoutes = [
  { path: '/overview', label: 'Overview', visible: true },
  // { path: '/technical-details', label: 'Technical Details', visible: true },
  { path: '/rules', label: 'Rules', visible: false },
  { path: '/cavity/assembly-order', label: 'Cavity List', visible: true },
  { path: '/floating-components', label: 'Floating Components', visible: true },
  { path: '/edit', label: 'Tray', visible: false },
  {
    path: '/cavity-create',
    label: 'Create Cavity',
    visible: true,
    icon: faPlus,
  },
  { path: '/create', label: '', visible: false },
  {
    path: '/priority',
    label: 'Priority Placements',
    icon: faTasks,
    visible: true,
  },
];

export const kitMakerRoutes = (trayId, disabledSnapshots, isSales) => [
  {
    path: '/add-component',
    label: 'Add a component',
    visible: true,
    icon: faSyringe,
  },
  // { path: '/list-components', label: 'Components List', visible: true, icon: faListUl },
  { path: '/swap-tray', label: 'Swap Tray', visible: !isSales },
  {
    path: '/snapshots',
    label: 'Assembly Snapshots',
    visible: !isSales,
    icon: faFileImage,
  },
  //{ path: '/assembly-pdf', label: 'Assembly PDF' + (disabledSnapshots ? " (No snapshots)" : ""), visible: !isSales, icon: faFilePdf, disabled: disabledSnapshots, disabledTitle: "You need to create snapshots first" },
  // { path: '/build', label: 'Build List', visible: true, icon: faListUl },
  {
    path: '/notes',
    label: 'Notes ',
    visible: true,
    icon: faStickyNote,
    divider: true,
    count: 'notesCount',
    tooltip: true,
  },
  {
    path: '/orphan-components',
    label: 'This kit has components that are not displayed: Click here ',
    visible: true,
    count: 'orphansCount',
    icon: faExclamationTriangle,
    color: 'orange',
  },
  {
    path: `/kit-trays/edit/${trayId}`,
    label: 'Change tray settings',
    visible: !isSales,
    divider: true,
    dividerAfter: true,
    direct: true,
  },
];

export const componentsListRoutes = (kitId, disabledSnapshots) => [
  {
    path: `/my-kit-list/${kitId}/kitmaker/add-component`,
    label: 'Add a component',
    visible: true,
    icon: faSyringe,
    direct: true,
  },
  //{ path: `/my-kit-list/${kitId}/kitmaker/assembly-pdf`, label: 'Download Sample Preview' + (disabledSnapshots ? " (No snapshots)" : ""), visible: true, icon: faFilePdf, disabled: disabledSnapshots, disabledTitle: "You need to create snapshots first", direct: true },
  {
    path: '/notes',
    label: 'Notes ',
    visible: true,
    icon: faStickyNote,
    divider: true,
    count: 'notesCount',
    tooltip: true,
  },
  {
    path: `/my-kit-list/${kitId}/kitmaker/list-components`,
    direct: true,
    label: 'This kit has components that are displayed: Click here ',
    visible: true,
    count: 'componentsCount',
    icon: faExclamationTriangle,
    color: 'orange',
  },
];

export const filterCavitiesFunc = (obj, filterCavities) => {
  let check = (from, to) =>
    !(from && from < obj.assemblyOrder) && !(to && to > obj.assemblyOrder);
  let { from, to } = filterCavities;
  return check(from, to) || (!!from && !!to && check(to, from));
};

export const filterComponentsFunc = (objects, filterValues) => {
  let bounds = [...Object.values(filterValues)];

  if (bounds.every((x) => x === undefined)) {
    return objects;
  } else {
    if (bounds.includes(undefined)) {
      if (bounds[0] === undefined) {
        const index = objects.findIndex((x) => x.id === bounds[1]);
        return [...objects].splice(0, index + 1);
      } else {
        const index = objects.findIndex((x) => x.id === bounds[0]);
        return [...objects].splice(index, objects.length);
      }
    } else {
      let foundOne = false;
      let result = [];

      for (let object of objects) {
        if (bounds.includes(object?.id)) {
          if (!foundOne) {
            foundOne = true;
          } else {
            result.push(object);
            return result;
          }
        }
        if (foundOne) {
          result.push(object);
        }
      }

      return result;
    }
  }
};

export const kitTrayTabs = [
  {
    label: 'Inner Tray',
    path: '#',
  },
  {
    label: 'Floating',
    path: 'workflow1',
  },
  {
    label: 'Wrapping',
    path: 'workflow2',
  },
  {
    label: 'Shipping',
    path: 'workflow3',
  },
];

export const maxImageSize = 31457280; // 30 MB
export const MIN_SCREEN_HEIGHT = 720; // 720px

export const breadcrumbs = [
  { value: 'kit-trays', label: 'Kit Trays' },
  { value: 'create', label: 'Create New Tray' },
  { value: 'cavity-create', label: 'Create New Cavity' },
  { value: 'cms-components', label: 'Components' },
  { value: 'kitlist', label: 'Kit List' },
  { value: 'my-kit-list', label: 'My Kit List', roleLabel: 'Submitted Kits' },
  { value: 'kit-catalog', label: 'Kit Catalog' },
];

export const multiSelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: '2px solid white',
    borderRadius: 'unset',
    boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    border: state.data.borderTop,
    background: state.data.background || provided.backgroundColor,
    color: state.data.color || provided.color,
  }),
  valueContainer: (provided) => ({ ...provided, alignItems: 'flex-end' }),
  placeholder: (provided) => ({
    ...provided,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'white',
    '&:hover': { color: 'white' },
  }),
  loadingIndicator: (provided) => ({ ...provided, color: 'white' }),
  menu: (provided) => ({
    ...provided,
    color: 'black',
    fontSize: 12,
    fontWeight: 400,
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    color: 'black',
    fontWeight: 400,
  }),
  input: (provided) => ({ ...provided, color: 'white' }),
  singleValue: (provided) => ({ ...provided, color: 'white' }),
};

export const kitMakerTabs = [
  { path: '/kit-request-form', label: 'Kit Request Form' },
  { path: '/components-list', label: 'BOM' },
  { path: '/kitmaker', label: 'Digital Sample' },
  { path: '/approval', label: 'Submission' },
  // { path: '/new-kit-maker/packaging-options', label: 'Packaging Options' },
  // { path: '/new-kit-maker/kit-overview', label: 'Kit Overview' },
];

export const corsPatch =
  process.env.REACT_APP_API_ENDPOINT + '/api/kit-design/image?url=';

export const statuses = {
  1: { label: 'Draft', status: 1 },
  2: { label: 'Pending Approval', status: 2 },
  3: { label: 'Approved', status: 3 },
};

export const imageIcons = {
  top: faCircle,
  side: faRulerVertical,
  bottom: fasCircle,
  add: faPlus,
};

export const manufacturing = 3;
export const engineering = 2;

export const kitMakerLayoutRoutes = {
  layout: [
    '/kitlist',
    '/kitlist/:id/details',
    '/my-kit-list',
    '/my-kit-list/:id/:tab',
    '/my-kit-list/:id/kitmaker/:tab',
    '/kitlist/:id',
    '/my-kit-list/:id/kitmaker/snapshots/all',
    '/my-kit-list/:id/kitmaker/orphan-components/place-in-tray',
  ],
  listKits: ['/my-kit-list'],
  kitmaker: [
    '/my-kit-list/:id/kitmaker',
    '/my-kit-list/:id/kitmaker/:tab',
    '/my-kit-list/:id/kitmaker/snapshots/all',
  ],
  componentsList: ['/my-kit-list/:id/components-list'],
  sales: ['/my-kit-list/:id/kit-request-form', '/my-kit-list/:id/approval'],
  orphan: ['/my-kit-list/:id/kitmaker/orphan-components/place-in-tray'],
};

export const rules = { allowed: 0, categories: 1, override: 2 };

export const floatingPoints = [
  { x: 0, y: 0 },
  { x: 0, y: 0 },
  { x: 0, y: 0 },
  { x: 0, y: 0 },
];

const isNumber = (value) => {
  return typeof value === 'number' && isFinite(value);
};

export const getImageCoordinates = (
  xPos,
  yPos,
  imgWidth = 0,
  imgHeight = 0,
) => {
  const canvas = document.querySelector('canvas');

  if (isNumber(xPos) && isNumber(yPos)) {
    return [{ x: xPos, y: yPos }];
  } else if (canvas) {
    const { width, height } = canvas.getBoundingClientRect();
    const multiplier = [width / 1000, height / 1000];
    const wrapperCoeff = 250;

    return [
      {
        x: (width + wrapperCoeff - imgWidth * multiplier[0]) / 2,
        y: (height + wrapperCoeff - imgHeight * multiplier[0]) / 2,
      },
    ];
  }

  return floatingPoints;
};

export const unrealPositionValues = { upper: -10000, lower: 1000 };

export const canvasBufferOptions = {
  offset: 120,
  imageSizeOffset: 240,
  mouseHoverOffset: 5,
};

export const labelsVisibilityOptions = [
  { value: 'all', label: 'Show All' },
  { value: 'none', label: 'Hide All' },
  { value: 'onpage', label: 'On Page' },
  { value: 'allHidden', label: 'Show All Hidden' },
  // {
  //   value: "reset",
  //   label: "Reset positions",
  //   borderTop: "1px solid lightgray",
  //   background: "#6585b5",
  //   color: "white",
  // },
];

export const getAspectRatioOffset = () => {
  const aspect = 1.5;
  const { width, height } = window.screen;

  return +(width / height).toFixed(1) === aspect ? 1100 : 550;
};

export const buttons = [
  {
    label: 'Images',
    value: 'images',
  },
  {
    label: 'Label',
    value: 'label',
  },
  {
    label: 'Placement',
    value: 'placement',
  },
];

export const RESTRICTED_ROLE_ALIAS = 'Restricted';
