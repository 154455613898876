import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import TooltipContent from './TooltipContent';
import EditableList from './EditableList';
import Confirmation from '../../../../components/Confirmation/Confirmation';
import { clearComponentTooltipInfo } from '../../../../redux/actions/kitTrays';
import { deleteCmsComponentImage } from '../../../../redux/actions/components';
import { sortBy } from 'lodash-es';
import { defaultPlacement } from '../KitTrays';

const Container = styled.div(
  () => `
  .__react_component_tooltip {
    left: -28px !important;
    padding: 5px !important;
  }
  .place-right::after {
    display: none;
  }
`,
);

const editableList = [
  // { type: "allowed", title: "Allowed Categories" },
  { type: 'singleAllowed', title: 'Single Component Allowed' },
  { type: 'restricted', title: 'Restricted Components' },
  { type: 'incompatible', title: 'Disable When...' },
];

const DropdownSection = ({
  onOptionChange,
  options,
  setShowDropDown,
  setOptions,
  componentPlacement,
  setComponentPlacement,
  cavities,
  cavity,
  allowMultiple,
  updateRules,
}) => {
  const [config, setConfig] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [place, setPlace] = useState('');

  const [uploading, setUploading] = useState(null);
  const [removeComponentImage, setRemoveComponentImage] = useState(null);

  const { hideSidebar } = useSelector((state) => state.menu);

  useEffect(() => {
    if (!hideSidebar) {
      afterHide();
    }
    // eslint-disable-next-line
  }, [hideSidebar]);

  const dispatch = useDispatch();
  const ref = useRef(null);

  const afterHide = useCallback(() => {
    if (ref.current) {
      ref.current.tooltipRef = null;
    }
    ReactTooltip.hide();
    setIsOpen(false);
    setUploading(null);
    setConfig(null);
    setComponentPlacement(defaultPlacement);
    dispatch(clearComponentTooltipInfo());
  }, [dispatch, setComponentPlacement]);

  const onItemClick = (id, type, number, event) => {
    if (!isOpen) setConfig(null);
    else {
      setConfig({ id, type, number });
      if (event.clientY < 430) {
        setPlace('bottom');
      } else {
        setPlace('top');
      }
    }
  };

  useEffect(() => {
    return () => {
      afterHide();
    };
  }, [afterHide]);

  useLayoutEffect(() => {
    ReactTooltip.rebuild();
  }, [options, isOpen, place]);

  const removeItem = (itemId, itemType) => {
    afterHide();
    onOptionChange(
      options[itemType].filter((f) => f.id !== itemId),
      itemType,
    );
  };

  const removeImage = () => {
    const { componentId, imageId } = removeComponentImage;
    dispatch(deleteCmsComponentImage(componentId, imageId, config?.type));
    setRemoveComponentImage(null);
  };

  return (
    <Container>
      {editableList.map((el) => (
        <EditableList
          list={sortBy(options[el.type], ['number', 'name', 'labelName'])}
          onItemClick={onItemClick}
          onItemRemove={removeItem}
          type={el.type}
          showDropdown={setShowDropDown}
          title={el.title}
          key={el.type}
          currentItemId={config?.id}
        />
      ))}

      <ReactTooltip
        ref={ref}
        id="Tip"
        event="click"
        isCapture
        clickable
        place={place}
        effect="float"
        type="light"
        afterHide={afterHide}
        afterShow={() => setIsOpen(true)}
        scrollHide={false}
        resizeHide={false}
      >
        <TooltipContent
          componentId={config?.id}
          setOptions={setOptions}
          uploading={uploading}
          setUploading={setUploading}
          type={config?.type}
          options={options}
          closeTooltip={afterHide}
          componentPlacement={componentPlacement}
          setComponentPlacement={setComponentPlacement}
          removeImage={setRemoveComponentImage}
          cavities={cavities}
          number={config?.number}
          cavity={cavity}
          allowMultiple={allowMultiple}
          updateRules={updateRules}
        />
      </ReactTooltip>

      {removeComponentImage && (
        <Confirmation
          confirm={removeImage}
          text="Are you sure you want to delete this Component Image?"
          close={() => setRemoveComponentImage(null)}
        />
      )}
    </Container>
  );
};

export default DropdownSection;
