import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone'
import { useHistory } from 'react-router-dom';
import { imageFormats, maxImageSize } from '../../../constants/constants';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../redux/actions/snackbar';
import TrayIcon from '../../../shared/TrayIcon';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #8E92AD;
    flex-direction: column;
    border: 3px dashed #8E92AD;
    font-weight: 500;
    padding: 20px;

    img {
        max-width: 100%;
        max-height: 100%;
    }
    cursor: pointer;
`;

const ImageDnD = ({ onTrayChange, tray, clonedTray }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onDrop = useCallback(
    (files) => {
      if (clonedTray) {
        dispatch(
          showSnackbar("warning", "You cant change image of cloned tray")
        );
        return;
      }
      if (!files.length) {
        dispatch(showSnackbar("warning", "File must be image (max size 10MB)"));
        return;
      }

      onTrayChange({ target: { files } });
    },
    [onTrayChange, dispatch, clonedTray]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: maxImageSize,
    accept: imageFormats,
  });

  const pathname = history.location.pathname;
  const byAction = pathname === "/kit-trays" ? "select" : "loadImage";

  if (byAction === "select") {
    return (
      <Container>
        <TrayIcon color="#8E92AD" size="20%" />
        <span style={{ fontSize: 12, marginTop: 5 }}>Please Select a Tray</span>
        <span style={{ fontSize: 12 }}>from the Left Menu</span>
      </Container>
    );
  } else {
    return (
      <Container {...getRootProps()}>
        <input {...getInputProps()} disabled={clonedTray} />
        {isDragActive ? (
          <p>Drop the PNG file here ...</p>
        ) : (
          <>
            {tray.data?.image || tray.data?.imageUrl ? (
              <img src={tray.data?.image || tray.data?.imageUrl} alt="Upload" />
            ) : (
              <>
                <FontAwesomeIcon icon={faImage} style={{ fontSize: 72 }} />
                <span>Upload a Tray image</span>
              </>
            )}
          </>
        )}
      </Container>
    );
  }
};

export default ImageDnD;
