import {
    SET_MENU_STATE
} from '../../constants/actionTypes';

export default (state = { isOpen: false, isActive: false, hideSidebar: false }, action) => {
    switch (action.type) {
        case SET_MENU_STATE:
            return action.data;
        default:
            return state;
    }
};
