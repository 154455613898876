import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  overflow: hidden;
  height: 46px;
  position: relative;

  ${(props) =>
    (props.isFilled || props.isFilled === 0) &&
    `label span {
      transform: translateY(-110%);
      font-size: 14px;
    }
  `}

  input:focus + label span,
  input:valid + label span {
    transform: translateY(-110%);
    font-size: 14px;
    color: #4285f4;
  }

  input:focus + label::after,
  input:valid + label::after {
    transform: translateX(0%);
  }
`;
const Input = styled.input`
  width: 100%;
  height: 40px;
  font-size: 14px;
  border: none;
  padding-top: 20px;
  margin-bottom: 0px !important;
  box-shadow: none !important;
  outline: none;
`;

const Label = styled.label`
  position: absolute;
  bottom: -5px;
  left: 0px;
  pointer-events: none;
  height: 35px;
  width: 100%;
  color: black;
  border-bottom: 1px solid ${(props) => (props.invalid ? "red" : "black")};

  &::after {
    content: "";
    position: absolute;
    height: 35px;
    width: 100%;
    bottom: -1px;
    left: -1px;
    border-bottom: 2px solid ${(props) => (props.invalid ? "red" : "#4285f4")};
    transform: translateX(-100%);
    transition: all 0.3s ease;
  }
`;

const Span = styled.span`
  position: absolute;
  bottom: 2px;
  left: 0px;
  font-size: 16px;
  transition: all 0.3s ease;
`;

const Mark = styled.span`
  color: red !important;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.4;
  `}
`;

const CustomInput = ({
  value = "",
  label,
  invalid,
  errorText,
  isRequired,
  style,
  disabled,
  ...rest
}) => {
  return (
    <Wrapper style={style} disabled={disabled}>
      <Container isFilled={value}>
        <Input value={value === null ? "" : value} required {...rest} />
        <Label invalid={invalid}>
          <Span>
            {label}
            {isRequired && <Mark>*</Mark>}
          </Span>
        </Label>
      </Container>
      {errorText && <Mark>{errorText}</Mark>}
    </Wrapper>
  );
};

export default CustomInput;
