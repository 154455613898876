import styled from '@emotion/styled';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { loadCategories } from '../../../../redux/actions/categories';
import { clearComponentCatalogList, loadComponentCatalog } from '../../../../redux/actions/componentCategories';
import { flatten } from '../../../../utils/categoryMapping';
import SelectedDropdown from '../Cavity/SelectedDropdown';
import FloatingOrderList from './FloatingOrderList';
import { hideReminder, showReminder } from '../../../../redux/actions/snackbar';

const Container = styled.div`
    display: flex;
    padding: 10px;
    flex-direction: column;
`;

const SelectSection = styled.div`
    font-size: 12px;
    padding: 10px 10px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
        font-weight: 500;
        display: flex;
    }

    svg {
        margin-left: 10px;
        cursor: pointer;
    }
`;

const Title = styled.span`
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
`;

const Instructions = styled.span`
    font-size: 12px;
    font-weight: 500;
    margin-top: 40px;
    padding: 0px 10px;
    text-align: center;
`;

const searchDelay = 1000;

const FloatingComponents = ({ showDropdown, setShowDropdown, componentPlacement, setComponentPlacement, onCavityClick, tray }) => {
    const dispatch = useDispatch();

    const { list, componentsLoading, components, cavities, reminder } = useSelector(state => ({
        list: state.categories.list,
        componentsLoading: state.componentCatalog.componentsLoading,
        components: state.componentCatalog.list,
        cavities: state.kitTrays.cavities,
        reminder: state.snackbar.reminder
    }), shallowEqual);

    const virtualCavities = cavities.filter(f => f.isFloating).map(item => ({ ...item, points: [{ x: 0, y: 0 }, { x: 0, y: 0 }, { x: 0, y: 0 }, { x: 0, y: 0 }] }));

    const [options, setOptions] = useState({ allowed: [], singleAllowed: [] });
    const [draggableList, setDraggableList] = useState([]);
    const categories = _.orderBy(flatten(list, false), "name", "asc");

    useEffect(() => {
        dispatch(loadCategories());
        return () => {
            dispatch(hideReminder());
        }
    }, [dispatch]);

    useEffect(() => {
      if (virtualCavities[0]) {
        setOptions({
          allowed: virtualCavities[0].categories.map((c) => ({
            ...c,
            id: c.categoryId,
            typeName: 'Allowed Category',
            type: 'allowed',
          })),
          singleAllowed: virtualCavities[0].componentRules.map((c) => ({
            ...c,
            id: c.component.id,
            number: c.component.number,
            labelName: c.component.labelName,
            typeName: 'Allowed Component',
            type: 'singleAllowed',
          })),
        });
      }
      // eslint-disable-next-line
    }, [cavities]);

    useEffect(() => {
      const unsorted = options.allowed.concat(options.singleAllowed);

      if (draggableList.length <= unsorted.length) {
        setDraggableList(
          draggableList
            .concat(getArrayDifference(unsorted, draggableList))
            .map((item, index) => ({
              ...item,
              assemblyOrder: item.assemblyOrder || index + 1,
            })),
        );
      } else {
        setDraggableList(
          draggableList.filter(
            (x) =>
              !getArrayDifference(draggableList, unsorted).some(
                (y) => y.id === x.id,
              ),
          ),
        );
      }

      // eslint-disable-next-line
    }, [options]);

    const getArrayDifference = (arr1, arr2) => arr1.filter(x => !arr2.some(y => y.id === x.id));

    const onOptionChange = (option, name) => {
        setOptions({ ...options, [name]: option ? option.map(o => ({ ...o, x: -100, y: -100, rotation: 0, placement: null })) : [] });
        if (!reminder) {
            dispatch(showReminder());
        }
    };

    const onComponentSearch = (value) => search(value);

    const search = useMemo(() => _.debounce((value) => dispatch(
        value ? loadComponentCatalog({ partTypeId: '', search: value, page: 0, categoryId: '' }) : clearComponentCatalogList()), searchDelay
    ), [dispatch]);

    const removeItem = (item) => {
        setDraggableList(draggableList.filter(f => f.id !== item.id).map((item, index) => ({ ...item, assemblyOrder: index + 1 })));
        onOptionChange(options[item.type].filter(f => f.id !== item.id), item.type);
        if (!reminder) {
            dispatch(showReminder());
        }
    };

    if (showDropdown) {
        return <SelectedDropdown
            onOptionChange={onOptionChange}
            name={showDropdown}
            allowedCategories={categories.map(item => ({ ...item, typeName: 'Allowed Category', type: 'allowed' }))}
            onComponentSearch={onComponentSearch}
            componentsLoading={componentsLoading}
            components={components.map(c => ({ ...c, typeName: 'Allowed Component', type: 'singleAllowed' }))}
            options={options}
        />;
    }

    const setList = (list) => {
        setDraggableList(list);
        if (!reminder) {
            dispatch(showReminder());
        }
    };

    return (
        <Container>
            <SelectSection>
                <span>Categories</span>
                <FontAwesomeIcon icon={faPencilAlt} onClick={() => setShowDropdown('allowed')} />
                <span>Components</span>
                <FontAwesomeIcon icon={faPencilAlt} onClick={() => setShowDropdown('singleAllowed')} />
            </SelectSection>
            {draggableList.length > 0 ? <Title>Layering Order</Title> : <Instructions>Set allowed categories and components to be floated</Instructions>}
            <FloatingOrderList list={_.orderBy(draggableList, ['assemblyOrder'], ['desc'])} setList={setList} removeItem={removeItem}
                setOptions={setOptions} options={options} setComponentPlacement={setComponentPlacement}
                componentPlacement={componentPlacement} onCavityClick={onCavityClick} cavities={virtualCavities} tray={tray} />
        </Container>
    );
};

export default FloatingComponents;
