import React from 'react';
import styled from '@emotion/styled';

const ListContainer = styled.div(
  ({ offset, width, backcolor, wide, color, fromParentHeight, maxHeight }) => `
  height: calc(${fromParentHeight} - ${offset || 180}px);
  width: ${width || 'unset'};
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 3px;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  ${maxHeight && `max-height: ${maxHeight}px;`}

  ::-webkit-scrollbar {
    width: ${wide ? 8 : 6}px;
    border-radius: 6px;
    background: ${backcolor || '#b6adf9'};
  }

  ::-webkit-scrollbar-track {
    background: ${backcolor || '#1d265b'};
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${color || '#8E93AD'};
    border-radius: 6px;
  }
`);

const FancyScroll = React.forwardRef((props, ref) => (
  <ListContainer
    ref={ref}
    offset={props.offset}
    backcolor={props.styles?.backcolor}
    width={props.styles?.width}
    color={props.styles?.color}
    wide={props.wide}
    fromParentHeight={props.fromParentHeight || '100vh'}
    maxHeight={props.maxHeight}
  >
    {props.children}
  </ListContainer>
));

export default FancyScroll;
