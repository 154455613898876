import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import * as _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPencilAlt, faSort } from '@fortawesome/free-solid-svg-icons'
import { changeUserAccess, changeUserRole, deleteUser, loadRoleList, loadUserList } from '../../../redux/actions/userManagement';
import { createUserAction, defaultUserManagementData, editUserAction, userManagementTableColumns } from '../../../constants/constants';
import TopSection from './TopSection';
import Table from '../../../components/Tables/Table';
import Switcher from '../../../components/Switchers/Switcher';
import Loader from '../../../components/loaders/Loader';
import ResetPassword from './ResetPassword';
import CreateOrEditUser from './CreateOrEditUser';
import Confirmation from '../../../components/Confirmation/Confirmation';
import Wrapper from '../../../components/Wrapper/Wrapper';
import CustomSelect from '../../../components/Selects/CustomSelect';
import LoadingOverlay from 'react-loading-overlay';

const ResetPasswordButton = styled.div`
    border: 1px solid #cdc8c8;
    border-radius: 20px;
    width: fit-content;
    padding: 0px 5px;
    font-size: smaller;
    cursor: pointer;
    margin: 0 auto;
`;

const Th = styled.th`
    cursor: ${props => props.cursor ? 'pointer' : 'auto'};
    & > svg {
        margin-left: 5px;
    }
`;

const Td = styled.td`
    & > div {
        justify-content: center;
    }
`;

const confirmationText = 'Are you sure you want to delete this user?';

const UserManagement = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadRoleList());
        dispatch(loadUserList());
    }, [dispatch]);

    const { users, roles, usersLoading, loadingOverlay } = useSelector(state => state.userManagement);

    const [localUsers, setLocalUsers] = useState([]);
    const [actionView, setActionView] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

    useEffect(() => {
      const sorted = _.orderBy(
        users,
        [
          (user) =>
            user.lastName ? user.lastName.toLowerCase() : user.lastName,
        ],
        sortDirection,
      );
      setLocalUsers(sorted);
      // eslint-disable-next-line
    }, [users]);

    if (usersLoading) return <Loader />;

    const handleChange = (value, id, name) => {
        setLocalUsers(localUsers.map(user => user.id === id ? ({ ...user, [name]: value }) : user));
        dispatch(name === 'role' ? changeUserRole(id, value) : changeUserAccess(id, value));
    };

    const handlePasswordReset = (user) => setActionView(<ResetPassword user={user} close={closeModal} />);
    const handleEdit = (user) =>
      setActionView(
        <CreateOrEditUser
          data={user}
          action={editUserAction}
          roles={roles}
          close={closeModal}
          users={users}
        />,
      );
    const handleDelete = (id) =>
      setActionView(
        <Confirmation
          confirm={() => confirmDelete(id)}
          close={closeModal}
          text={confirmationText}
        />,
      );
    const handleCreate = () =>
      setActionView(
        <CreateOrEditUser
          data={defaultUserManagementData}
          action={createUserAction}
          roles={roles}
          close={closeModal}
          users={users}
        />,
      );

    const closeModal = () => setActionView(null);

    const confirmDelete = (id) => {
        dispatch(deleteUser(id));
        closeModal();
    };

    const handleSearch = (e) => {
        if (!e.target.value) {
            setLocalUsers(users);
            return;
        }

        const searched = users
            .filter(f => f.firstName !== null && f.lastName !== null && f.userName !== null)
            .filter(f => f.firstName.includes(e.target.value) || f.lastName.includes(e.target.value) || f.userName.includes(e.target.value));

        setLocalUsers(searched);
    };

    const sortByField = (header) => {
        if (!header.sortable) {
            return;
        }

        const direction = sortDirection === 'asc' ? 'desc' : 'asc';
        const sorted = _.orderBy(localUsers, [user => sortCondition(user, header.field)], direction);

        setLocalUsers(sorted);
        setSortDirection(direction);
    };

    const sortCondition = (current, field) => {
        if (field === 'role') {
            return current.role.alias;
        } else if (field !== 'isActive' && current[field]) {
            return current[field].toLowerCase();
        }

        return current[field];
    };

    return (
        <div className="main-layout">
            <div className="content-area">
                <LoadingOverlay
                    active={loadingOverlay}
                    spinner
                    styles={{ content: { position: 'fixed', left: '7%', display: 'flex', width: '100%', height: '100%' } }}
                >
                    <TopSection
                        title='User Management'
                        onChange={handleSearch}
                        onClick={handleCreate}
                        blocks={_.countBy(users, 'role.name')}
                        searchable
                        showButton
                    />
                    <Wrapper>
                        <Table>
                            <thead>
                                <tr>{userManagementTableColumns.map((col, index) =>
                                    <Th key={col.field} colSpan={index === userManagementTableColumns.length - 1 ? 2 : 1} onClick={() => sortByField(col)} cursor={col.sortable ? 1 : 0}>
                                        {col.label}
                                        {col.sortable && <FontAwesomeIcon icon={faSort} />}
                                    </Th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {localUsers.map(user => <tr className="text-center" key={user.id}>
                                    <td>{user.firstName}</td>
                                    <td>{user.lastName}</td>
                                    <td>{user.userName}</td>
                                    <td><CustomSelect applyStyles isSearchable={false} options={roles} value={roles.find(r => r.value === user.role.id)} onChange={(value) => handleChange(value, user.id, 'role')} /></td>
                                    <Td><Switcher size='small' checked={user.isActive} onChange={() => handleChange(!user.isActive, user.id, 'isActive')} /></Td>
                                    <td><ResetPasswordButton onClick={() => handlePasswordReset(user)}>Reset Password</ResetPasswordButton></td>
                                    <td><FontAwesomeIcon icon={faPencilAlt} onClick={() => handleEdit(user)} /></td>
                                    <td><FontAwesomeIcon icon={faTrashAlt} onClick={() => handleDelete(user.id)} /></td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </Wrapper>
                    {actionView}
                </LoadingOverlay>
            </div>
        </div>
    );
};

export default UserManagement;
