import * as actionTypes from '../../constants/actionTypes';
import { getToken } from '../../utils/getToken';
import axios from '../axiosMiddleware/axios';
import { showSnackbar } from './snackbar';

export const loadCategories = (isLoaded) => async dispatch => {
    dispatch({ type: actionTypes.GET_CATEGORIES_START, loading: !isLoaded });

    try {
        const res = await axios.get('/api/cms/categories', { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.GET_CATEGORIES_SUCCESS, data: res.data });
    } catch (err) {
        dispatch({ type: actionTypes.GET_CATEGORIES_FAIL, error: err });
        dispatch(showSnackbar('error', err.message));
    }
};

export const uploadDefaultPhoto = (id, file) => async dispatch => {
    dispatch({ type: actionTypes.UPLOAD_DEFAULT_PHOTO_START });

    try {
        const requestBody = new FormData();
        requestBody.append('file', file);

        await axios.post(`/api/cms/categories/${id}/photo`, requestBody, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch(showSnackbar('success', 'Default Photo successfully uploaded'));
        dispatch(loadCategories(true));
    } catch (err) {
        dispatch(showSnackbar('error', err.response.data));
    }
};

export const updateCategory = (category) => async dispatch => {
    dispatch({ type: actionTypes.UPDATE_CATEGORY_START });

    try {
        const requestBody = {
            Name: category.name,
            ParentId: category.option ? category.option.value : category.parentId,
            IsVisible: category.isVisible,
            IsFavorite: category.isFavorite
        };

        await axios.put(`/api/cms/categories/${category.id}`, requestBody, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch(showSnackbar('success', 'Category successfully updated'));
        dispatch({ type: actionTypes.UPDATE_CATEGORY_SUCCESS });
        dispatch(loadCategories(true));
    } catch (err) {
        dispatch(showSnackbar('error', err.response.data));
    }
};

export const updatePartType = (partType) => async dispatch => {
    dispatch({ type: actionTypes.UPDATE_PARTTYPE_START });

    try {
        const requestBody = {
            Name: partType.name,
            IsVisible: partType.isVisible,
            IsFavorite: partType.isFavorite
        };

        await axios.put(`/api/cms/part-types/${partType.id}`, requestBody, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch(showSnackbar('success', 'Part Type successfully updated'));
        dispatch(loadCategories(true));
    } catch (err) {
        dispatch(showSnackbar('error', err.response.data));
    }
};