import * as actionTypes from '../../constants/actionTypes';
import { getToken } from '../../utils/getToken';

const initialState = {
  user: localStorage.getItem('loggeduser')
    ? JSON.parse(localStorage.getItem('loggeduser'))
    : {},
  profileImage: null,
  isAuthenticated: !!getToken(),
  loginLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        user: action.payload.user,
        loginLoading: false,
      };

    case actionTypes.GET_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        profileImage: action.payload?.image,
      };
    case actionTypes.LOGIN_FAILED:
    case actionTypes.REDIRECT:
      return {
        ...state,
        loginLoading: false,
      };

    case actionTypes.LOGIN_STARTED:
      return {
        ...state,
        loginLoading: true,
      };

    case actionTypes.REGISTER:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload?.errors : null,
      };
    case actionTypes.LOGIN_PAGE_UNLOADED:
    case actionTypes.REGISTER_PAGE_UNLOADED:
      return { ...state };
    case actionTypes.ASYNC_START:
      if (
        action.subtype === actionTypes.LOGIN ||
        action.subtype === actionTypes.REGISTER
      ) {
        return { ...state, inProgress: true };
      }
      break;
    case actionTypes.UPDATE_FIELD_AUTH:
      return { ...state, [action.key]: action.value };
    default:
      return state;
  }

  return state;
};
