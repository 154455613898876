import React, { Component } from 'react';
import p1 from '../../shared/img/defaultlist.png';
import agent from '../../agent';
import {
    MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBTable, MDBTableBody, MDBTableHead,
    MDBBtn, MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardBody
} from "mdbreact";
import { connect } from 'react-redux';
import {
    LOGIN_PAGE_UNLOADED,
    LOGOUT
} from '../../constants/actionTypes';
import Loader from "../../components/loaders/Loader";

let KitDetailArray = {
    kits: [

        {
            description: "No results found"
        }
    ]
}

const mapStateToProps = state => {
    return { currentUser: state.common.currentUser }
};
const mapDispatchToProps = dispatch => ({
    onUnload: () =>
        dispatch({ type: LOGIN_PAGE_UNLOADED }),
    onClickLogout: () => dispatch({ type: LOGOUT }),
});

class KitMakerRequestSample extends Component {
    constructor(props) {
        super(props);

        const token = window.localStorage.getItem('jwt');
        if (token) {

        }
        else {
            this.props.onClickLogout()
        }


        this.state = {
            kitDetailData: null
        }
        this.ComponentConfirmationPage = this.ComponentConfirmationPage.bind(this);
        // this.kitMakerSurveyPage=this.kitMakerSurveyPage.bind(this);
    }

    componentWillMount() {
        let Id = null;
        Id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
        const token = window.localStorage.getItem('jwt');
        if (token) {
            agent.KITCATLOG.kitbyId(Id).then((res) => {
                if (res === null) {
                    this.setState({ kitDetailData: KitDetailArray });
                }
                else {
                    this.setState({ kitDetailData: res });
                }
            }
            )
        }
        else {
            this.props.onClickLogout()
        }
    }
    // kitMakerSurveyPage = () => {

    //     let path = `/customkitmaker`;
    //     this.props.history.push(path + "/" );


    // }
    addDefaultSrc(ev) {
        ev.target.src = p1
    }

    ComponentConfirmationPage = (kitId) => {
        let path = "/kitcatalogrequestform"
        let data = kitId
        this.props.history.push(path + "/" + data);
    }

    render() {
        if (this.state.kitDetailData === null) {
            return <Loader />
        } else {
            let detaildata = this.state.kitDetailData;

            let SKU = "";
            let dataComponents, imageComponents = "";

            dataComponents = detaildata.components.map(component => {
                return (

                    <tr>

                        <td>{component.number}</td>
                        <td> {component.description}</td>
                        <td>{component.quantity}</td>
                    </tr>

                )
            })

            imageComponents = detaildata.components.map((component, index) => {
                return (
                    <MDBCarouselItem itemId={index + 1}>
                        <MDBView>
                            <img className="img-fluid d-block w-100 mb20" src={component.imageUrl} alt="index" onError={this.addDefaultSrc} />
                            <h6>{component.number}</h6>
                            <p>{component.description}</p>
                        </MDBView>
                    </MDBCarouselItem>
                )
            })

            SKU = detaildata.number;
            //label = detaildata.labelName;
            //image = detaildata.imageUrl;
            // description = detaildata.description;
            let length = detaildata.components.length;


            return (
                <div className="main-layout">
                    <div className="content-area prdlistview-area mb9">
                        <MDBContainer fluid>
                            <h5 className="text-capitalize mt-5 mt-sm-5 mt-lg-0 mt-md-0">KITMAKER</h5>
                            <MDBRow>
                                <MDBCol md="12">
                                    <MDBCard className="kitmaker mt-0">
                                        <MDBCardBody className="p30">
                                            {/* <h3> ASA-22098-SB </h3> */}
                                            <h3>{SKU}</h3>
                                            <MDBRow>
                                                <MDBCol md="3" className="text-center">

                                                    <MDBCarousel
                                                        activeItem={1}
                                                        length={length}
                                                        showControls={true}
                                                        showIndicators={true}
                                                        className=""
                                                    >
                                                        <MDBCarouselInner>
                                                            {imageComponents}
                                                            {/* <MDBCarouselItem itemId="1">
                                                           <MDBView>
                                                               <img className="img-fluid d-block w-100 mb4" src={p1} waves alt="First slide" />
                                                               <h6>1235-5461s</h6>
                                                               <p>"Spinal syringe 5ml, L5, Glass barrel"</p>
                                                           </MDBView>
                                                       </MDBCarouselItem>
                                                       <MDBCarouselItem itemId="2">
                                                           <MDBView>
                                                               <img className="img-fluid d-block w-100 mb4" src={p1} waves alt="Second slide" />
                                                               <h6>1236-5461s</h6>
                                                               <p>"Spinal syringe 5ml, L5, Glass barrel"</p>
                                                           </MDBView>
                                                       </MDBCarouselItem>
                                                       <MDBCarouselItem itemId="3">
                                                           <MDBView>
                                                               <img className="img-fluid d-block w-100 mb4" src={p1} waves alt="Third slide" />
                                                               <h6>1237-5461s</h6>
                                                               <p>"Spinal syringe 5ml, L5, Glass barrel"</p>
                                                           </MDBView>
                                                       </MDBCarouselItem> */}
                                                        </MDBCarouselInner>
                                                    </MDBCarousel>
                                                </MDBCol>
                                                <MDBCol md="9" className="text-left">
                                                    <p className="heading">COMPONENTS</p>
                                                    <div className="kitmakerform mt-1">
                                                        <MDBTable striped small>
                                                            <MDBTableHead>
                                                                <tr >

                                                                    <th>SKU #</th>
                                                                    <th>Description</th>
                                                                    <th>Quantity</th>
                                                                </tr>
                                                            </MDBTableHead>
                                                            <MDBTableBody small>
                                                                {dataComponents}
                                                                {/* <tr align="center">
                                                           <td>
                                                               <p>1</p>
                                                           </td>
                                                          
                                                           <td>1235-5461s</td>
                                                           <td>Spinal syringe 5ml, L5, Glass barrel</td>
                                                       </tr>
                                                       <tr align="center">
                                                           <td>
                                                               <p>1</p>
                                                           </td>
                                                          
                                                           <td>1235-5461s</td>
                                                           <td>Spinal syringe 5ml, L5, Glass barrel</td>
                                                       </tr>
                                                       <tr align="center">
                                                           <td>
                                                               <p>1</p>
                                                           </td>
                                                          
                                                           <td>1235-5461s</td>
                                                           <td>Spinal syringe 5ml, L5, Glass barrel</td>
                                                       </tr>
                                                       <tr align="center">
                                                           <td>
                                                               <p>1</p>
                                                           </td>
                                                           
                                                           <td>1235-5461s</td>
                                                           <td>Spinal syringe 5ml, L5, Glass barrel</td>
                                                       </tr> */}
                                                            </MDBTableBody>
                                                        </MDBTable>
                                                    </div>
                                                    {/* <h4>4 Components</h4> */}
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow className="text-right">
                                                <MDBCol md="12">
                                                    <MDBBtn className="gbtn mb-0 mt-5" onClick={() => this.ComponentConfirmationPage(detaildata.id)}>Continue</MDBBtn>
                                                </MDBCol>

                                            </MDBRow>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </div>
                </div>
            )
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(KitMakerRequestSample);