import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react';
import styled from '@emotion/styled';
import LoadingOverlay from 'react-loading-overlay';
import { omit, orderBy, debounce } from 'lodash-es';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import Wrapper from '../../components/Wrapper/Wrapper';
import * as action from '../../redux/actions/kits';
import {
  deleteSnapshots,
  listSnapshots,
  saveLayoutData,
  updateAssemblyOrder,
  resetLabelText,
} from '../../redux/actions/snapshots';
import {
  clearComponentCatalogList,
  loadComponentCatalog,
  listOfCompatibleComponents,
} from '../../redux/actions/componentCategories';
import KitMakerActiveMenu from './Components/KitMakerActiveMenu';
import KitMaker from './Tabs/KitMaker';
import {
  cmsAccessRoleList,
  generalAccessRoleList,
  kitMakerLayoutRoutes,
  kitMakerTabs,
  manufacturing,
} from '../../constants/constants';
import KitRequestForm from './Tabs/RequestForm/KitRequestForm';
import KitApproval from './Tabs/KitApproval';
import { createSnapshot } from '../../redux/actions/snapshots';
import { showSnackbar } from '../../redux/actions/snackbar';
import CavitiesContainer from './Components/Orphans/CavitiesContainer';
import { loadKitTrays } from '../../redux/actions/kitTrays';
import ListSubmittedKits from './Tabs/ListSubmittedKits';
import ComponentsList from './Tabs/ComponentsList';
import { resetOverrideForComponent } from '../../redux/actions/components';
import { generateTrayPDF } from './Helpers/snapshotsToPDF';

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: center;
`;

const StyledLoader = styled(LoadingOverlay)`
  width: 100%;
  height: 100%;
  position: fixed;
  margin-top: -10px;
  padding-top: 10px;
  ._loading_overlay_spinner {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
`;
const description = `Please select an existing kit \n to use as base from the Left Menu`;
const searchDelay = 1000; // debounce delay;

const KitList = () => {
  const { kitDetails, trays, recentAddedComponentId } = useSelector(
    (state) => ({
      kitDetails: state.kits.kitDetails,
      trays: state.kitTrays.trays,
      recentAddedComponentId: state.kits.recentAddedComponentId,
    }),
    shallowEqual,
  );
  const {
    tray,
    componentAssignments,
    kitId,
    notes,
    layoutData,
    pages,
    objects,
    version,
  } = { ...kitDetails, kitId: kitDetails?.id };
  const {
    kitDesignList,
    kitDesignLoading,
    kitDesignPages,
    pageBreakImages,
    pageBreakImagesLoading,
    swappedComponentProps,
  } = useSelector((state) => state.kits);
  const { snapshots } = useSelector((state) => state.snapshots);
  const { user } = useSelector((state) => state.auth);
  const { components, componentsLoading } = useSelector(
    (state) => ({
      components: state.componentCatalog.list,
      componentsLoading: state.componentCatalog.componentsLoading,
    }),
    shallowEqual,
  );
  const { role } = useSelector((state) => state.auth.user);
  const [tabs, setTabs] = useState([]);
  const [currentKit, setCurrentKit] = useState(null);
  const [notesObj, setNotesObj] = useState([]);
  const [addNoteEvent, setAddNoteEvent] = useState(false);
  const [notesEditable, setNotesEditable] = useState(true);
  const [selectedNote, setSelectedNote] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [activePage, setActivePage] = useState(0);
  const [canvasElemRef, setCanvasElemRef] = useState(null);
  const [pdfLoader, setPdfLoader] = useState(false);
  const [selectedPageOnCanvas, setSelectedPageOnCanvas] = useState(null);
  const [filterState, setFilterState] = useState({});
  const [labelVisibility, setLabelVisibility] = useState(null);
  const [runPdfGeneration, setRunPdfGeneration] = useState(false);

  const [kitPdfGenerationProcess, setKitPdfGenerationProcess] = useState(false);
  const [excludeFloating, setExcludeFloating] = useState(true);

  const [selectedCavity, setSelectedCavity] = useState(null);
  const [filterComponents, setFilterComponents] = useState({});
  const [pageImage, setPageImage] = useState(null);

  // cavities component, used for pdf generation (detect if image loaded)
  const [imageLoading, setImageLoading] = useState(true);

  const history = useHistory();
  const path = history.location.pathname;

  const [objTop, setObjTop] = useState(null);
  const dispatch = useDispatch();
  const { id } = useParams();

  const generateObjects = () => {
    const assignments = componentAssignments.filter((x) => x.cavityId);
    const result = objects.length
      ? objects.filter((x) =>
          assignments.find(
            (a) =>
              a.cavityId === x.cavityId && a.component.id === x.componentId,
          ),
        )
      : [];

    const lastObject = { ...result[result.length - 1] };

    function getPageId(isNewAdded, assigment) {
      if (swappedComponentProps) {
        dispatch(action.saveSwappedComponentInformation(null));
        return swappedComponentProps.pageId;
      }

      if (isNewAdded) {
        return assigment.pageId;
      }

      return lastObject.pageId;
    }

    assignments.forEach((assigment) => {
      if (
        !result.find(
          (res) =>
            res.componentId === assigment.component.id &&
            res.cavityId === assigment.cavityId,
        )
      ) {
        const isNewAdded = recentAddedComponentId === assigment.component.id;
        result.push({
          cavityId: assigment.cavityId,
          id: lastObject.id++,
          name: assigment.component.labelName || '<empty>',
          sku: assigment.component.number,
          componentId: assigment.component.id,
          description: assigment.component.description,
          isOverride: assigment.isOverride,
          pageId: getPageId(isNewAdded, assigment),
          rotation: 0,
        });
      }
    });

    const mapped = result
      .filter((x) => x)
      .reduce((acc, curr) => {
        const count = assignments.filter(
          (x) =>
            x.cavityId === curr.cavityId && x.component.id === curr.componentId,
        ).length;

        if (count > 1) {
          acc.push({ ...curr, count });
        } else {
          acc.push(omit(curr, 'count'));
        }

        return acc;
      }, [])
      // .filter((x) => (excludeFloating ? x?.sku : true))
      .filter((x) => !x.name.includes('<empty>'))
      .map((x, i) => ({
        ...x,
        pageId: x.pageId || 0,
        id: ++i,
        value: i,
        label: x.name,
      }));

    setNotesObj([]);

    return orderBy(mapped, ['id'], ['asc']);
  };

  const cavities = tray?.cavities;
  const initialPage = [{ id: 0, image: null }];

  const buildObjects = useCallback((componentAssignments) => {
    const result = [];
    if (!componentAssignments) {
      return result;
    }

    const assignments = componentAssignments
      .filter((x) => x.cavityId)
      .map((x) => (!x.pageId ? { ...x, pageId: 0 } : x));

    assignments.forEach((x) => {
      const componentIndex = result.findIndex(
        (item) =>
          item.cavityId === x.cavityId && item.componentId === x.component.id,
      );

      if (componentIndex > -1) {
        return result[componentIndex].count++;
      }

      result.push({
        cavityId: x.cavityId,
        id: x.pageId,
        name: x.component.labelName || '<empty>',
        sku: x.component.number,
        componentId: x.component.id,
        description: x.component.description,
        isOverride: x.isOverride,
        pageId: x.pageId,
        rotation: 0,
        count: 1,
      });
    });

    return orderBy(result, ['id'], ['asc']);
  }, []);

  const buildPages = useCallback((cavities) => {
    const result = [
      {
        id: 0,
        image: cavities.find((x) => x.pageId === 0)?.pageBreakImageId,
      },
    ];

    if (!cavities) {
      return result;
    }

    cavities
      .filter((x) => x.pageId !== null)
      .forEach((x) => {
        const condition = !result.find((r) => r.id === x.pageId);

        if (condition) {
          result.push({
            id: x.pageId,
            image: x.pageBreakImageId !== null ? x.pageBreakImageId : null,
          });
        }
      });

    return orderBy(result, ['id'], ['asc']);
  }, []);

  const buildDefaultPages = useCallback(
    (kitWithSwappedTray) => {
      const pages = buildPages(kitWithSwappedTray?.tray?.cavities || cavities);
      const objects = buildObjects(
        kitWithSwappedTray?.componentAssignments || componentAssignments,
      );

      dispatch(updateAssemblyOrder(kitId, objects, pages, version));

      return { pages: JSON.stringify(pages), objects: JSON.stringify(objects) };
    },
    [
      buildPages,
      buildObjects,
      cavities,
      kitId,
      version,
      dispatch,
      componentAssignments,
    ],
  );

  const componentPlacementCallback = (top) => setObjTop(top?.id);

  useEffect(() => {
    if (kitId && objTop) {
      dispatch(
        updateAssemblyOrder(
          kitId,
          generateObjects(),
          pages?.length > 0 ? pages : initialPage,
          version,
        ),
      );
    }
    // eslint-disable-next-line
  }, [objTop]);

  useEffect(() => {
    if (kitId) {
      dispatch(updateAssemblyOrder(kitId, generateObjects(), pages, version));
    }
    // eslint-disable-next-line
  }, [kitId, componentAssignments]);

  const handleSelectedCavity = (value) => {
    if (value && componentAssignments?.find((x) => x.cavityId === value.id)) {
      setSelectedCavity(value);
    } else {
      setSelectedCavity(null);
    }
  };

  const isEngineering = cmsAccessRoleList.find((r) => r === role?.alias);
  const isSales = generalAccessRoleList.find((r) => r === role?.alias);

  const clearAll = () => {
    setSearchValue('');
    setCurrentKit(null);
    dispatch(action.clearKitListData());
  };

  const onComponentSearch = (value, cavityId = null) =>
    searchComponent(value, cavityId);

  const searchComponent = useMemo(
    () =>
      debounce(
        (value, cavityId) =>
          dispatch(
            value
              ? cavityId
                ? listOfCompatibleComponents(cavityId)
                : loadComponentCatalog({
                    partTypeId: '',
                    search: value,
                    page: 0,
                    categoryId: '',
                  })
              : clearComponentCatalogList(),
          ),
        searchDelay,
      ),
    [dispatch],
  );

  useEffect(() => {
    const isKitList = path === '/kitlist';
    const isMyKitList = path === '/my-kit-list';

    if (isKitList || isMyKitList) clearAll();

    if (isEngineering) {
      dispatch(loadKitTrays());
      dispatch(action.listPageBreakImages());
    }

    isKitList &&
      dispatch(
        action.loadKitListFromKitDesign({
          categories: '',
          search: '',
          componentId: '',
          likeKitId: '',
          pageSize: 50,
          page: 0,
        }),
      );
    isMyKitList && dispatch(action.loadKitListFromKitDesign(isEngineering));

    if (path.includes('/my-kit-list')) {
      (kitId || !currentKit) &&
        dispatch(action.loadKitListFromKitDesign(isEngineering));
      if (path.endsWith('kitmaker')) {
        setSelectedCavity(null);
        setSelectedPageOnCanvas(null);
      }
    }

    if (id && !currentKit) {
      if (path.includes('/kitlist')) {
        const searchParam = history.location.search.split('=')[1] || '';
        dispatch(
          action.loadKitListFromKitDesign({
            categories: '',
            search: searchParam,
            componentId: '',
            likeKitId: '',
            pageSize: 50,
            page: 0,
          }),
        );
        setSearchValue(searchParam);
        dispatch(action.loadKitInfoFromKitDesign(id));
      } else {
        dispatch(action.loadKitInfoFromKitDesign(id, true));
      }
    }

    if (currentKit) {
      if (path.includes('/kitmaker/build')) {
        dispatch(action.buildListPdf(currentKit.id, currentKit.name));
        history.goBack();
      } else if (path.includes('/kitmaker/assembly-pdf')) {
        dispatch(action.downloadAssemblyPdf(currentKit.id));
        history.goBack();
      }
    }
    // eslint-disable-next-line
  }, [dispatch, path]);

  useEffect(() => {
    if (currentKit && path.includes('/my-kit-list')) {
      setTabs(
        kitMakerTabs.map((tab) => ({
          ...tab,
          path: `/my-kit-list/${currentKit.id}${tab.path}`,
        })),
      );
    } else {
      setTabs([]);
    }
  }, [currentKit, path]);

  useEffect(() => {
    kitId && dispatch(listSnapshots(kitId));
    // eslint-disable-next-line
  }, [kitId, path]);

  useEffect(() => {
    if (id && kitDesignList.length) {
      const kit = kitDesignList.find((kit) => kit.id === +id);
      if (kit) {
        setCurrentKit(kit);
        path.includes('/kitlist') &&
          dispatch(action.loadKitInfoFromKitDesign(id));
      } else {
        !path.includes('/kitlist') &&
          history.replace({ pathname: `/my-kit-list` });
      }
    }
    // eslint-disable-next-line
  }, [kitDesignList]);

  useEffect(() => {
    if (selectedCavity && path.includes('/kitlist')) {
      if (path.includes('/details')) {
        history.replace({
          pathname: `/kitlist/${currentKit.id}/details`,
          search: `?search=${searchValue}`,
        });
      } else {
        history.push({
          pathname: `/kitlist/${currentKit.id}/details`,
          search: `?search=${searchValue}`,
        });
      }
    }
    // eslint-disable-next-line
  }, [selectedCavity]);

  const onSearch = (e, isLocal = false) => {
    setSearchValue(e.target.value);
    !isLocal && search(e.target.value);
  };

  const search = useMemo(
    () =>
      debounce(
        (value) =>
          dispatch(
            action.loadKitListFromKitDesign({
              categories: '',
              search: value,
              componentId: '',
              likeKitId: '',
              pageSize: 50,
              page: 0,
            }),
          ),
        searchDelay,
      ),
    [dispatch],
  );

  const handlePagination = (page) => {
    setActivePage(page);
    dispatch(
      action.loadKitListFromKitDesign({
        categories: '',
        search: searchValue,
        componentId: '',
        likeKitId: '',
        pageSize: 50,
        page,
      }),
    );
  };

  const onKitClick = (kit, isMyListRequest) => {
    if (kit.id !== currentKit?.id) {
      dispatch(action.loadKitInfoFromKitDesign(kit.id, isMyListRequest));
      !isMyListRequest &&
        history.push({
          pathname: `/kitlist/${kit.id}`,
          search: `?search=${searchValue}`,
        });

      if (!currentKit) {
        isMyListRequest &&
          history.push({
            pathname: `/my-kit-list/${kit.id}/kit-request-form`,
          });
      } else {
        isMyListRequest &&
          history.replace({ pathname: path.replace(/[0-9]+/g, kit.id) });
      }
    }

    setCurrentKit(kit);
  };

  const useAsBaseKit = () => {
    dispatch(action.createKitFromKitDesign(currentKit.id, history));
  };

  const showKitDetails = (kit) => {
    onKitClick(kit);
    history.push({
      pathname: `/kitlist/${kit.id}/details`,
      search: `?search=${searchValue}`,
    });
  };

  const handleDeleteKit = (kit) => {
    if (kit.status === manufacturing) {
      dispatch(
        showSnackbar('warning', 'You can not delete Kit with status: Approved'),
      );
    } else {
      dispatch(action.deleteFromMyKitList(kit.id, isEngineering || isSales));
    }
  };

  const contactEngineering = (kit) => {
    // @todo connect with api
  };

  const assignTray = (tray, kit) => {
    const params = {
      categories: '',
      search: searchValue,
      componentId: '',
      likeKitId: '',
      pageSize: 50,
      page: activePage,
    };
    dispatch(action.assignTrayToBaseKit(kit.id, tray.id, params));
  };

  const goBack = () => {
    setCurrentKit(null);
    history.replace('/my-kit-list');
  };

  const addComponent = (componentId, isMyKitList) => {
    dispatch(
      action.addComponentAutoAssignCavity(kitId, componentId, isMyKitList),
    );
  };

  const deleteComponent = (componentId, cavityId, isMyKitList, count) => {
    dispatch(
      action.deleteComponentFromCavity(
        kitId,
        componentId,
        cavityId,
        isMyKitList,
        count,
      ),
    );
  };

  const deleteAllComponentsById = (componentId, isMyKitList) => {
    dispatch(action.deleteAllComponentsById(kitId, componentId, isMyKitList));
  };

  const listWrapperRef = useRef();
  const generateTrayPDFCallback = () => {
    generateTrayPDF({
      setKitPdfGenerationProcess,
      canvasElemRef,
      listWrapperRef,
      kitId: kitDetails.id,
    });
  };

  const updateLayoutMemo = useMemo(
    () =>
      debounce(
        (value, kitId) => dispatch(saveLayoutData(kitId, value)),
        searchDelay,
      ),
    [dispatch],
  );
  const resetLabelLayout = (value, kitId) => {
    dispatch(resetLabelText(kitId, value));
  };

  const updateAssemblyOrderMemo = useMemo(
    () =>
      debounce(
        (objects, pages, version, message) =>
          dispatch(
            updateAssemblyOrder(kitId, objects, pages, version, message),
          ),
        searchDelay,
      ),
    [dispatch, kitId],
  );

  const updateComponentRange = useCallback((range) => {
    setFilterComponents(range);
  }, []);

  const notShownQuantityOfSelectedNote = useMemo(() => {
    const id = selectedNote?.componentId || selectedCavity?.component?.id;

    return componentAssignments?.filter(
      (c) => !c.cavityId && c.component.id === id,
    ).length;
  }, [componentAssignments, selectedNote, selectedCavity]);

  const placeSwappedComponent = useCallback(
    (newComponent) => {
      dispatch(
        action.addSwappedComponentToCavityOverride(
          {
            ...newComponent,
            kitId,
          },
          newComponent.swappedComponentProps,
        ),
      );
    },
    [dispatch, kitId],
  );

  const getComponentInfo = useCallback(
    (componentId, cavityId) => {
      return componentAssignments.find(
        (assignment) =>
          assignment.component.id === componentId &&
          assignment.cavityId === cavityId,
      );
    },
    [componentAssignments],
  );

  useEffect(() => {
    if (swappedComponentProps && !swappedComponentProps.isSwapped) {
      const { newComponentId, cavityId, overrideQuantity } =
        swappedComponentProps;

      const newComponent = getComponentInfo(newComponentId, null);

      if (newComponent) {
        placeSwappedComponent({
          ...newComponent,
          overrideQuantity,
          cavityId,
          role: isEngineering,
          swappedComponentProps,
        });
      }
    }
  }, [
    componentAssignments,
    swappedComponentProps,
    placeSwappedComponent,
    kitId,
    isEngineering,
    getComponentInfo,
  ]);

  const swapComponent = (
    oldComponentId,
    cavityId,
    newComponent,
    isMyKitList,
    overrideQuantity,
    updateSwapState,
  ) => {
    const object = objects.find(
      (object) =>
        object.componentId === oldComponentId && object.cavityId === cavityId,
    );

    const component = {
      ...getComponentInfo(oldComponentId, cavityId),
      newComponentId: newComponent.id,
      overrideQuantity,
      pageId: object.pageId,
    };

    dispatch(
      action.swapComponentAutoAssignCavity(
        kitId,
        oldComponentId,
        cavityId,
        newComponent.id,
        isMyKitList,
        overrideQuantity,
        component,
        updateSwapState,
        newComponent,
      ),
    );
  };

  return (
    <div className="main-layout">
      <div
        className="content-area"
        style={{ paddingBottom: 0, paddingLeft: 0 }}
      >
        <StyledLoader
          active={kitDesignLoading || kitPdfGenerationProcess}
          spinner
        >
          <div
            style={{
              display: 'flex',
              height: 'calc(100vh - 65px)',
              overflow: 'hidden',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <KitMakerActiveMenu
                excludeFloating={excludeFloating}
                setExcludeFloating={setExcludeFloating}
                kits={kitDesignList}
                onKitClick={onKitClick}
                notes={notes}
                addNote={(text) => dispatch(action.addNoteToKit(kitId, text))}
                onSearch={onSearch}
                searchValue={searchValue}
                filterState={filterState}
                setFilterState={setFilterState}
                onComponentSearch={onComponentSearch}
                components={components}
                componentsLoading={componentsLoading}
                totalPages={kitDesignPages}
                handlePagination={handlePagination}
                activePage={activePage}
                kit={currentKit}
                tray={tray}
                resetComponentOverride={(componentId, cavityId) =>
                  dispatch(
                    resetOverrideForComponent(kitId, componentId, cavityId),
                  )
                }
                componentAssignments={componentAssignments}
                selectedCavity={selectedCavity}
                setSelectedCavity={handleSelectedCavity}
                addComponent={addComponent}
                deleteComponent={deleteComponent}
                swapComponent={swapComponent}
                deleteKit={handleDeleteKit}
                version={version}
                pageBreakImages={pageBreakImages}
                pageBreakImagesLoading={pageBreakImagesLoading}
                addPageBreakImage={(file, name, update) =>
                  dispatch(action.addPageBreakImage(file, name, update))
                }
                deletePageBreakImage={(id) =>
                  dispatch(action.deletePageBreakImage(id))
                }
                setPageImage={setPageImage}
                objects={objects?.filter((x) => x)}
                pages={pages}
                updateLayout={updateLayoutMemo}
                resetLabelText={resetLabelLayout}
                updateAssemblyOrder={updateAssemblyOrderMemo}
                contactEngineering={contactEngineering}
                user={user}
                selectedPageOnCanvas={selectedPageOnCanvas}
                setSelectedPageOnCanvas={setSelectedPageOnCanvas}
                canvasElemRef={canvasElemRef}
                snapshots={snapshots}
                layoutData={layoutData}
                notesObj={notesObj}
                setNotesObj={setNotesObj}
                setSelectedNote={setSelectedNote}
                setNotesEditable={setNotesEditable}
                selectedNote={selectedNote}
                setAddNoteEvent={setAddNoteEvent}
                addNoteEvent={addNoteEvent}
                showKitDetails={showKitDetails}
                isEngineering={isEngineering}
                isSales={isSales}
                createSnapshot={async (snapshotData) =>
                  await createSnapshot(kitId, snapshotData)
                }
                deleteSnapshots={async () => await deleteSnapshots(kitId)}
                setPdfLoader={setPdfLoader}
                customizeKit={currentKit && isSales ? useAsBaseKit : null}
                goBack={goBack}
                componentPlacementCallback={componentPlacementCallback}
                trayList={trays}
                assignTray={assignTray}
                filterComponents={filterComponents}
                setFilterComponents={updateComponentRange}
                generateTrayPDF={generateTrayPDFCallback}
                imageLoading={imageLoading}
                setLabelVisibility={setLabelVisibility}
                labelVisibility={labelVisibility}
                runPdfGeneration={runPdfGeneration}
                setRunPdfGeneration={setRunPdfGeneration}
                buildDefaultPages={buildDefaultPages}
                notShownQuantityOfSelectedNote={notShownQuantityOfSelectedNote}
                tabs={tabs}
                kitDetails={kitDetails}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Wrapper>
                <Content>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <Switch>
                      <Route
                        exact
                        path={[
                          '/kitlist',
                          '/kitlist/:id',
                          '/kitlist/:id/details',
                        ]}
                      >
                        <KitMaker
                          title={'Digital Sample'}
                          description={description}
                          kit={currentKit}
                          tray={tray}
                          componentAssignments={componentAssignments}
                          useAsBaseKit={currentKit ? useAsBaseKit : null}
                          orderKit={true}
                          selectedCavity={selectedCavity}
                          setSelectedCavity={handleSelectedCavity}
                          isEngineering={isEngineering}
                          contactEngineering={contactEngineering}
                          selectedPageOnCanvas={selectedPageOnCanvas}
                          setSelectedPageOnCanvas={setSelectedPageOnCanvas}
                          pages={pages}
                          pageBreakImages={pageBreakImages}
                          objects={objects?.filter((x) => x)}
                          excludeFloating={excludeFloating}
                          filterComponents={filterComponents}
                          listWrapperRef={listWrapperRef}
                          pageImage={pageImage}
                          imageLoading={imageLoading}
                          setImageLoading={setImageLoading}
                          isSales={isSales}
                          labelVisibility={labelVisibility}
                        />
                      </Route>
                      <Route exact path={kitMakerLayoutRoutes.listKits}>
                        <ListSubmittedKits
                          list={[...kitDesignList.filter((x) => x.status)]}
                          sendToSubmit={(id) =>
                            dispatch(action.sendToSubmit(id))
                          }
                          pushBack={(id) =>
                            dispatch(action.pushBackToSales(id, isEngineering))
                          }
                          submitToManufacturing={(id) =>
                            dispatch(
                              action.submitToManufacturing(id, isEngineering),
                            )
                          }
                          isEngineering={isEngineering}
                          filterState={filterState}
                          kitDesignLoading={kitDesignLoading}
                        />
                      </Route>
                      <Route exact path={kitMakerLayoutRoutes.kitmaker}>
                        <KitMaker
                          title={'Digital Sample'}
                          description={description}
                          pageImage={pageImage}
                          kit={currentKit}
                          tray={tray}
                          excludeFloating={excludeFloating}
                          componentAssignments={componentAssignments}
                          selectedPageOnCanvas={selectedPageOnCanvas}
                          setSelectedPageOnCanvas={setSelectedPageOnCanvas}
                          pages={pages}
                          objects={objects?.filter((x) => x)}
                          pageBreakImages={pageBreakImages}
                          selectedCavity={selectedCavity}
                          setSelectedCavity={handleSelectedCavity}
                          setCanvasElemRef={setCanvasElemRef}
                          pdfLoader={pdfLoader}
                          notesObj={notesObj}
                          setSelectedNote={setSelectedNote}
                          selectedNote={selectedNote}
                          notesEditable={notesEditable}
                          setAddNoteEvent={setAddNoteEvent}
                          setNotesObj={setNotesObj}
                          filterComponents={filterComponents}
                          user={user}
                          kitDetails={kitDetails}
                          listWrapperRef={listWrapperRef}
                          imageLoading={imageLoading}
                          setImageLoading={setImageLoading}
                          isSales={isSales}
                          labelVisibility={labelVisibility}
                          runPdfGeneration={runPdfGeneration}
                          updateLayout={updateLayoutMemo}
                        />
                      </Route>
                      <Route exact path={kitMakerLayoutRoutes.componentsList}>
                        <ComponentsList
                          kit={currentKit}
                          kitDetails={kitDetails}
                          addComponent={addComponent}
                          deleteComponent={deleteComponent}
                          deleteAllComponentsById={deleteAllComponentsById}
                          user={user}
                          wrapperRef={listWrapperRef}
                          isSales={isSales}
                        />
                      </Route>
                      <Route exact path="/my-kit-list/:id/kit-request-form">
                        <KitRequestForm
                          status={currentKit?.status}
                          isEngineering={isEngineering}
                        />
                      </Route>
                      <Route exact path={['/my-kit-list/:id/approval']}>
                        <KitApproval
                          kit={currentKit}
                          isEngineering={isEngineering}
                          sendToSubmit={() =>
                            dispatch(action.sendToSubmit(currentKit.id))
                          }
                          pushBack={() =>
                            dispatch(
                              action.pushBackToSales(
                                currentKit.id,
                                isEngineering,
                              ),
                            )
                          }
                          submitToManufacturing={() =>
                            dispatch(
                              action.submitToManufacturing(
                                currentKit.id,
                                isEngineering,
                              ),
                            )
                          }
                        />
                      </Route>
                      <Route exact path={kitMakerLayoutRoutes.orphan}>
                        <CavitiesContainer prevSelected={selectedCavity} />
                      </Route>
                    </Switch>
                  </div>
                </Content>
              </Wrapper>
            </div>
          </div>
        </StyledLoader>
      </div>
    </div>
  );
};

export default KitList;
