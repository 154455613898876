import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { faImage, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import {
  deleteCmsComponentImage,
  loadCmsComponentInfo,
} from "../../../../redux/actions/components";
import List from "../../../Settings/KitTrays/List";
import GoBack from "../../Helpers/GoBack";
import styled from "@emotion/styled";
import CustomButton from "../../../../components/Buttons/CustomButton";
import {
  floatingPoints,
  getImageCoordinates,
  imageIcons,
  unrealPositionValues,
} from "../../../../constants/constants";
import ReactTooltip from "react-tooltip";
import UploadComponentImage from "../../../Settings/KitTrays/Cavity/UploadComponentImage";
import useClickOutside from "../../../../hooks/useClickOutside";
import FancyScroll from "../../../../components/FancyScroll";
import RadioButton from "../../../../components/Inputs/Radio";
import {
  addComponentToCavityOverride,
  changeOrphanComponent,
  moveComponentToCavityOverride,
  saveComponentsRange,
} from "../../../../redux/actions/kits";
import Confirmation from "../../../../components/Confirmation/Confirmation";
import usePrevious from "../../../../hooks/usePrevious";
import CustomCheckbox from "../../../../components/Inputs/CustomCheckbox";
import Positions from "./Positions";
import UserNote from '../../../../shared/UserNote';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  & > button {
    width: fit-content;
    align-self: center;
  }
`;

const Image = styled.div`
  background: white;
  width: 100px;
  height: 100px;
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #8e92ad;
  flex-direction: column;
  font-size: 12px;
  font-weight: 500;
  img {
    max-width: 100%;
    max-height: 100%;
  }
  svg {
    font-size: 60px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 8px;
  & > div {
    display: flex;
    flex-direction: column;
  }
  & > div > div {
    & > button {
      padding: 5px;
      margin-bottom: 5px;
      background: white;
      color: #262268;
      border-radius: 0;
      font-weight: 600;
      min-width: 70px;
      max-width: 95px;
    }
  }
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
`;

const SubmitButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
`;

const PlaceOrphanInTray = ({
  componentAssignments,
  componentPlacementCallback,
  setFilterComponents,
  deleteComponent,
}) => {
  const { component, orphanComponent, kitDetails } = useSelector((state) => ({
    orphanComponent: state.kits.orphanComponent,
    component: state.components.component,
    kitDetails: state.kits.kitDetails,
  }));

  const floatingCavity = kitDetails?.tray?.cavities?.find(
    (cavity) => cavity.isFloating,
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const ref = useRef(null);
  const activeComponentRef = useRef(null);
  const imageRef = useRef(null);

  const { id } = useParams();
  const {
    component: orphan,
    quantity: orphanQuantity,
    isOrphan,
  } = history.location.state || {};

  const isMyKitList = !!history.location.pathname.includes('/my-kit-list');
  const [removeComponentImage, setRemoveComponentImage] = useState(null);
  const [multiplePlacementFlag, setMultiplePlacementFlag] = useState(null);
  const [floatingFlag, setFloatingFlag] = useState(true);
  const [offset, setOffset] = useState(400);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  useClickOutside(ref, () => ReactTooltip.hide());
  useLayoutEffect(() => {
    ReactTooltip.rebuild();
  }, [orphanComponent]);

  useEffect(() => {
    if (orphan) dispatch(loadCmsComponentInfo(orphan.id));
  }, [dispatch, orphan]);

  const image = activeComponentRef.current?.getElementsByTagName('img')[0];
  useLayoutEffect(() => {
    if (image) {
      setOffset(offset + image.clientHeight);
    }
    // eslint-disable-next-line
  }, [image?.clientHeight]);

  const checkForRealPosition = (pos) =>
    pos < unrealPositionValues.lower && pos > unrealPositionValues.upper
      ? pos
      : 0;
  const setDefaultCavity = (component) =>
    kitDetails?.tray?.cavities?.find(
      (cavity) => cavity.id === component?.cavityId,
    ) || null;

  useEffect(() => {
    if (component && componentAssignments) {
      const image = setCorrectImage();

      const obj = {
        trayId: orphanComponent?.trayId,
        id: orphan.id,
        kitId: +id,
        x: checkForRealPosition(orphan.x),
        y: checkForRealPosition(orphan.y),
        r: orphan.r || 0,
        images: component.images,
        active: image?.url,
        requestCount: orphanQuantity ? image?.quantity : 1,
        orphanQuantity,
        overrideQuantity: orphan.overrideQuantity,
        place: image?.name,
        type: 'override',
        cavity: orphanComponent?.cavity || setDefaultCavity(orphan),
        isOverride: orphan.isOverride,
        assemblyOrder: orphan.assemblyOrder, // component assembly order,
        oldCavityId: orphan.cavityId,
        role: 'Engineering',
        isHorizontalFlipped: orphan.isHorizontalFlipped,
      };

      dispatch(changeOrphanComponent(obj));
    }
    // eslint-disable-next-line
  }, [component, componentAssignments]);

  const prev = usePrevious(orphanComponent);
  useEffect(() => {
    if (orphanComponent && prev) {
      const currentLength = orphanComponent.images?.length;
      const prevLength = prev.images?.length;
      if (currentLength > prevLength && prevLength !== undefined) {
        onImageChange(orphanComponent.images[currentLength - 1].name);
      }

      if (
        !orphanComponent.uncheckFloating &&
        floatingFlag &&
        !orphanComponent.cavity &&
        floatingCavity?.id === orphanComponent.oldCavityId
      ) {
        handleFloatingChange();
      }
    }

    // eslint-disable-next-line
  }, [orphanComponent, floatingCavity]);

  const setCorrectImage = () => {
    const assigment = componentAssignments.find(
      (x) => x.cavityId === orphan.cavityId && x.component.id === orphan.id,
    );

    const image = component.images.find((x) => {
      if (orphanComponent?.imageChanged) {
        return x.url === orphanComponent.active;
      }

      return assigment?.imageUrl === x.url;
    });

    setMultiplePlacementFlag(image?.quantity > 1);

    if (!image) {
      return (
        component.images.find(
          (x) =>
            x.url === orphan?.imageUrl && x.name.toLowerCase() !== 'catalog',
        ) || component?.images[0]
      );
    }

    return image;
  };

  if (!orphan) {
    window.location = '/components';
    return null;
  }

  const onImageChange = (name) => {
    const { url, quantity } =
      component.images.find((img) => img.name === name) || {};

    setMultiplePlacementFlag(quantity > 1);

    dispatch(
      changeOrphanComponent({
        ...orphanComponent,
        active: url,
        place: name,
        imageChanged: true,
        requestCount: quantity,
      }),
    );
  };

  const setPositionIcon = (name) =>
    imageIcons[
      Object.keys(imageIcons).find((key) => name.toLowerCase().includes(key))
    ] || null;

  const handleRadioButton = (e) => {
    const { name } = e.target;
    dispatch(changeOrphanComponent({ ...orphanComponent, type: name }));
  };

  const onSubmit = () => {
    dispatch(saveComponentsRange(null));
    setFilterComponents({});
    const callback = () => {
      history.goBack();
    };

    if (orphan?.action === 'move' && orphanComponent.oldCavityId) {
      dispatch(moveComponentToCavityOverride(orphanComponent));
      history.push(`/my-kit-list/${id}/kitmaker/snapshots`);
    } else {
      componentPlacementCallback(orphanComponent);
      dispatch(addComponentToCavityOverride(orphanComponent, callback));
    }
  };

  const radioButtons = {
    allowed: 'Always for this component in this tray',
    categories: `Always for "${orphan.category.name}" in this tray`,
    override: 'This kit only',
  };

  const removeImage = () => {
    const { componentId, imageId } = removeComponentImage;
    dispatch(deleteCmsComponentImage(componentId, imageId));
    setRemoveComponentImage(null);
  };

  const handleFloatingChange = () => {
    const points = getImageCoordinates(
      orphanComponent.x,
      orphanComponent.y,
      imageRef?.current?.naturalWidth,
      imageRef?.current?.naturalHeight,
    );
    const isFloating = orphanComponent?.cavity?.isFloating;
    const cavity = isFloating
      ? null
      : { ...floatingCavity, points: floatingPoints };

    setFloatingFlag(!!cavity);
    dispatch(
      changeOrphanComponent({
        ...orphanComponent,
        ...(orphanComponent.x === '' &&
          orphanComponent.y === '' && {
            x: -points[0].x,
            y: -points[0].y,
          }),
        cavity,
        trayId: kitDetails?.tray?.id,
      }),
    );
  };

  const handleCancelButton = () => {
    if (orphanComponent?.cavity) {
      dispatch(changeOrphanComponent({ ...orphanComponent, cavity: null }));
    }
    history.goBack();
  };

  const activeComponent = componentAssignments?.find(
    (x) => x.cavityId === orphan.cavityId && x.component.id === orphan.id,
  );

  const handleDeleteComponent = () => {
    const { component, cavityId } = activeComponent;
    deleteComponent(component.id, cavityId, isMyKitList);
    setDeleteConfirmation(false);
    handleCancelButton();
    history.goBack();
  };

  function changeImageFlipProperty() {
    dispatch(
      changeOrphanComponent({
        ...orphanComponent,
        isHorizontalFlipped: !orphanComponent.isHorizontalFlipped,
      }),
    );
  }

  return (
    <Container>
      <GoBack title="Choose a Cavity" />
      <div ref={activeComponentRef}>
        <List
          list={[{ ...orphan, imageUrl: activeComponent?.imageUrl }]}
          isActive={{ ...orphan, imageUrl: activeComponent?.imageUrl }}
          title="Active Component"
          onItemClick={() => null}
          isStatic
        />
      </div>
      <br />

      {!isOrphan && (
        <UserNote
          text="Select IMAGE to override display. NOTE: this will NOT update the component quantity"
          starred
        />
      )}
      <br />
      <FancyScroll offset={offset}>
        <Buttons>
          <Image>
            {orphanComponent?.active ? (
              <img
                ref={imageRef}
                src={orphanComponent?.active}
                alt="Orphan Component"
              />
            ) : (
              <>
                <FontAwesomeIcon icon={faImage} />
                No Image
              </>
            )}
          </Image>
          <div ref={ref}>
            {orphanComponent?.images
              ?.filter((x) => x.name !== 'catalog')
              .map((img) => (
                <div key={img.name}>
                  <CustomButton
                    label={img.name.toUpperCase()}
                    icon={
                      setPositionIcon(img.name) ? (
                        <FontAwesomeIcon icon={setPositionIcon(img.name)} />
                      ) : null
                    }
                    onClick={() => onImageChange(img.name)}
                    border={
                      img.url === orphanComponent?.active ? 'orangered' : ''
                    }
                  />
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{
                      fontSize: 14,
                      cursor: 'pointer',
                      marginLeft: 10,
                      marginTop: 4,
                    }}
                    data-tip
                    data-for="OrphanTip"
                    onClick={() => onImageChange(img.name)}
                  />
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{
                      fontSize: 14,
                      cursor: 'pointer',
                      marginLeft: 10,
                      marginTop: 4,
                      color: 'orangered',
                    }}
                    onClick={() =>
                      setRemoveComponentImage({
                        componentId: orphan.id,
                        imageId: img.id,
                      })
                    }
                  />
                </div>
              ))}
            <div
              data-tip
              data-for="OrphanTip"
              onClick={() =>
                dispatch(
                  changeOrphanComponent({ ...orphanComponent, place: '' }),
                )
              }
            >
              <CustomButton
                label="Add Image"
                icon={<FontAwesomeIcon icon={imageIcons.add} />}
              />
            </div>
            <ReactTooltip
              isCapture={true}
              event="click"
              id="OrphanTip"
              place="right"
              effect="solid"
              type="light"
              clickable={true}
              scrollHide={false}
              offset={{ left: 25 }}
              resizeHide={false}
            >
              <TooltipContent>
                <UploadComponentImage
                  multiplePlacementFlag={multiplePlacementFlag}
                  setMultiplePlacementFlag={setMultiplePlacementFlag}
                  data={orphanComponent}
                  imagePlacement={orphanComponent?.place || ''}
                  maxHeight={160}
                  orphanQuantity={orphanQuantity}
                  changeImageFlipProperty={changeImageFlipProperty}
                  isHorizontalFlipped={orphanComponent?.isHorizontalFlipped}
                />
              </TooltipContent>
            </ReactTooltip>
          </div>
        </Buttons>

        <Positions
          orphanComponent={orphanComponent}
          componentAssignments={componentAssignments}
        />

        {orphanComponent &&
          Object.keys(radioButtons).map((key) => (
            <RadioButton
              checked={orphanComponent.type === key}
              label={radioButtons[key]}
              name={key}
              key={key}
              onChange={handleRadioButton}
            />
          ))}
        <CustomCheckbox
          label="Place as Floating component"
          onChange={handleFloatingChange}
          checked={!!orphanComponent?.cavity?.isFloating}
        />
      </FancyScroll>
      <SubmitButtons>
        <CustomButton
          rounded
          label="Cancel"
          background="gray"
          onClick={handleCancelButton}
        />

        <CustomButton
          rounded
          label="Delete"
          background="red"
          onClick={() => setDeleteConfirmation(true)}
        />

        <CustomButton
          rounded
          label="Save"
          background="#61A431"
          disabled={!orphanComponent?.cavity?.id || !orphanComponent?.active}
          onClick={onSubmit}
        />
      </SubmitButtons>
      {removeComponentImage && (
        <Confirmation
          confirm={removeImage}
          text="Are you sure you want to delete this Component Image?"
          close={() => setRemoveComponentImage(null)}
        />
      )}

      {deleteConfirmation && (
        <Confirmation
          confirm={handleDeleteComponent}
          text="Are you sure you want to delete this Component?"
          close={() => setDeleteConfirmation(false)}
        />
      )}
    </Container>
  );
};

export default PlaceOrphanInTray;
