import React from 'react';
import PropTypes from 'prop-types';

const Loader = (props) => !props.show ? null :
    <div className="loader" id="loader" style={props.style}>
        <span /><span /><span /><span />
        <p style={{ marginTop: 18 }}>{props.name || "Loading"}</p>
    </div>;

Loader.defaultProps = {
    show: true
};

Loader.propTypes = {
    show: PropTypes.bool
};

export default Loader;
