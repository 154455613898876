export const getCaretPosition = (elem) => {
    let caretPos = 0;
    if (document.selection) {
        elem.focus();
        const oSel = document.selection.createRange();
        oSel.moveStart('character', -elem.value.length);
        caretPos = oSel.text.length;
    }
    else if (elem.selectionStart || elem.selectionStart === '0') {
        caretPos = elem.selectionDirection === 'backward' ? elem.selectionStart : elem.selectionEnd;
    }
    return caretPos;
}

export const setCaretPosition = (elem, caretPos) => {
    if (elem != null) {
        if (elem.createTextRange) {
            const range = elem.createTextRange();
            range.move('character', caretPos);
            range.select();
        }
        else {
            if (elem.selectionStart) {
                elem.focus();
                elem.setSelectionRange(caretPos, caretPos);
            }
            else {
                elem.focus();
            }
        }
    }
}