import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import Toast from './Toast';

const Container = styled.div`
    position: fixed;
    bottom: 0;
    right: 15px;
`;

const Snackbar = () => {
    const { snackbars } = useSelector(state => state.snackbar);

    return (
        <Container>
            {snackbars.map(({ type, message }, index) => <Toast type={type} message={message} key={type + index} />)}
        </Container>
    );
};

export default Snackbar;
