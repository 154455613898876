import styled from '@emotion/styled';
import React, { useRef, useState } from 'react';
import FancyScroll from '../../../../components/FancyScroll';
import SearchRounded from '../../../../components/Inputs/SearchRounded';
import useClickOutside from '../../../../hooks/useClickOutside';
import List from '../../KitTrays/List';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
    position: relative;
`;

const SearchList = styled.div`
    position: absolute;
    width: 240px;
    top: 42px;
    background: white;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
    border-radius: 10px;
    margin-left: 10px;
    z-index: 1;
`;

const ResultList = styled(SearchList)`
    box-shadow: none;
    position: relative;
    background: transparent;
    border-radius: 0;
    top: 0px;
    z-index: 0;
`;

const Search = styled.div`
    position: relative;
    color: black;
    margin-bottom: 10px;
`;

const SearchSection = ({ onSearch, onSearchChange, assignComponent, components, component, replacements, showComponentInfo, deleteFallback, fallback, placeholder, placeholderStyle = null, fancyStyle = null, itemColor, offset = 300, onFocus, className }) => {
    const ref = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const hideSearchSection = () => {
        setIsVisible(false);
        if (onFocus) {
            onFocus(false);
        }
    };

    useClickOutside(ref, hideSearchSection);

    const list = components.filter(c => c.id !== component?.id && !replacements.some(r => r.id === c.id));

    const handleFocus = () => {
        setIsVisible(true);
        if (onFocus) {
            onFocus(true);
        }
    };

    return (
        <Container className={className || ''}>
            <div ref={ref}>
                <Search>
                    <SearchRounded placeholder={placeholder} style={placeholderStyle} onKeyPress={(e) => onSearch(e, true)} onChange={onSearchChange} onFocus={handleFocus} />
                </Search>
                {(components.length > 0 && isVisible) && <SearchList>
                    <FancyScroll offset={offset} styles={fancyStyle}><List list={list} onItemClick={assignComponent} color={itemColor} /></FancyScroll>
                </SearchList>}
            </div>
            {replacements.length > 0 && <ResultList>
                <List list={replacements} onItemDelete={deleteFallback} onItemClick={showComponentInfo} isActive={fallback} />
            </ResultList>}
        </Container>
    );
};

SearchSection.defaultProps = {
    replacements: [],
    placeholder: 'Component Search'
};

export default SearchSection;
