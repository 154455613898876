import styled from '@emotion/styled';
import React from 'react';
import CustomSelect from '../../../../components/Selects/CustomSelect';
import { multiSelectStyles } from '../../../../constants/constants';
import _ from 'lodash';

const Container = styled.div`
    font-size: 12px;
    padding: 25px 10px;

    & > span {
        font-weight: 500;
        margin-top: 40px;
        display: block;
        text-align: center;
    }

    input {margin-bottom: 0px;}
`;

const SelectedDropdown = ({ onOptionChange, name, allowedCategories, onComponentSearch, componentsLoading, components, options, inputValue }) => {
    
    const selects = {
      allowed: (
        <CustomSelect
          customStyles={multiSelectStyles}
          placeholder="Select Component Category"
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          options={_.sortBy(allowedCategories, ["name"])}
          isMulti
          onChange={(option) => onOptionChange(option, "allowed")}
          closeMenuOnSelect={false}
          value={options.allowed}
          isSearchable={false}
          isClearable={false}
          backspaceRemovesValue={false}
        />
      ),
      singleAllowed: (
        <CustomSelect
          autoFocus
          customStyles={multiSelectStyles}
          placeholder="Select Allowed Component"
          options={components}
          isMulti
          onChange={(option) => onOptionChange(option, "singleAllowed")}
          getOptionLabel={(option) =>
            option.number.concat("-").concat(option.labelName)
          }
          getOptionValue={(option) => option.id}
          noOptionsMessage={() => "Please type to search"}
          onInputChange={onComponentSearch}
          inputValue={inputValue}
          closeMenuOnSelect={false}
          isLoading={componentsLoading}
          value={options.singleAllowed}
          isClearable={false}
          backspaceRemovesValue={false}
        />
      ),
      restricted: (
        <CustomSelect
          autoFocus
          customStyles={multiSelectStyles}
          placeholder="Select Restricted Component"
          options={components}
          isMulti
          onChange={(option) => onOptionChange(option, "restricted")}
          getOptionLabel={(option) =>
            option.number.concat("-").concat(option.labelName)
          }
          getOptionValue={(option) => option.id}
          noOptionsMessage={() => "Please type to search"}
          onInputChange={onComponentSearch}
          inputValue={inputValue}
          closeMenuOnSelect={false}
          isLoading={componentsLoading}
          value={options.restricted}
          isClearable={false}
          backspaceRemovesValue={false}
        />
      ),
      incompatible: (
        <CustomSelect
          customStyles={multiSelectStyles}
          placeholder="Select Incompatible Category"
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          options={_.sortBy(allowedCategories, ["name"])}
          isMulti
          onChange={(option) => onOptionChange(option, "incompatible")}
          closeMenuOnSelect={false}
          value={options.incompatible}
          isSearchable={false}
          isClearable={false}
          backspaceRemovesValue={false}
        />
      ),
    };

    return (
        <Container>
            {selects[name]}
            {name !== 'incompatible' ? 
                <span>After setting the allowed/restricted components and categories for this cavity, click 'Back' to position in tray.</span> :
                <span>Add all categories of components that will cause this cavity to be disabled, then click 'Back' to continue editing tray.</span>
            }
        </Container>
    );
};

export default SelectedDropdown;
