import React from 'react';
import styled from '@emotion/styled';
import { faDraftingCompass, faLongArrowAltRight, faPencilRuler, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomButton from '../../../components/Buttons/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { validateField, validateFieldList } from './RequestForm/validateField';
import { showSnackbar } from '../../../redux/actions/snackbar';

const Container = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
`;

const Statuses = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    color: #1d265b;
`;

const Status = styled.div(({ active }) => `
    display: flex;
    align-items: center;
    opacity: ${active ? 1 : .5};
    svg { font-size: 30px; margin-right: 10px; }
    span { font-size: 16px; font-waight: 500; }
`);

const Buttons = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 50%;
`;

const KitApproval = ({ kit, isEngineering, sendToSubmit, pushBack, submitToManufacturing }) => {

    const { role, kitDetails } = useSelector(state => ({ role: state.auth.user.role, kitDetails: state.kits.kitDetails }));
    const dispatch = useDispatch();

    const isValidForm = () => {
        for (let field of validateFieldList) {
            if (validateField(field, kitDetails[field])) {
                return false;
            }
        }
        return true;
    }

    if (!kit) return null;

    const handleSubmitButton = (manufactoring = false) => {
        if (isValidForm()) {
            manufactoring ? submitToManufacturing() : sendToSubmit();
        } else {
            dispatch(showSnackbar('error', "You need to enter all fields on kit request form first"));
        }
    }


    return (
        <Container>
            <Statuses>
                <Status active={[1, 2, 3].some(status => status === kit.status)}>
                    <FontAwesomeIcon icon={faPencilRuler} />
                    <span>Submit for<br />Approval</span>
                </Status>
                <FontAwesomeIcon icon={faLongArrowAltRight} style={{ fontSize: 60, opacity: .5 }} />
                <Status active={[2, 3].some(status => status === kit.status)}>
                    <FontAwesomeIcon icon={faDraftingCompass} />
                    <span>Engineering<br />Approval</span>
                </Status>
                <FontAwesomeIcon icon={faLongArrowAltRight} style={{ fontSize: 60, opacity: .5 }} />
                <Status active={kit.status === 3}>
                    <FontAwesomeIcon icon={faThumbsUp} />
                    <span>Approved</span>
                </Status>
            </Statuses>
            <Buttons>
                {!isEngineering ? <CustomButton label='Submit for Approval' onClick={handleSubmitButton} disabled={kit.status !== 1} /> :
                    <>
                        <CustomButton label='Revert to Draft' onClick={pushBack} disabled={(kit.status === 3 && !(role.isSuperAdmin || role.isAdmin)) || kit.status === 1} />
                        <CustomButton label='Approve' onClick={() => handleSubmitButton(true)} disabled={kit.status === 3} />
                    </>
                }
            </Buttons>
        </Container>
    );
};

export default KitApproval;
