import React, { useState } from 'react';
import { requestResetLink } from '../../redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import siteLogo from '../../shared/img/logo.png';
import { MDBInput, MDBBtn } from "mdbreact";
import 'bootstrap/dist/css/bootstrap.min.css';
import "mdbreact/dist/css/mdb.css";
import { Redirect } from 'react-router-dom';
import styled from '@emotion/styled';

const Link = styled.a`
    color: white;
    display: block;
    padding-top: 50px;
    opacity: 0.6;
    &:hover {
        opacity: 1;
        color: white;
    }
`

const Label = styled.div`
    color: white;
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 2em;
`;

const PasswordReset = () => {
    const {isAuthenticated} = useSelector(state => state.auth);

    const dispatch = useDispatch();
    const [email, setEmail] = useState("");

    const handleResetButton = () => {
        dispatch(requestResetLink(email, `${window.location.origin}/password-reset`));
    };

    return (
      <>
        {!isAuthenticated ? (
          <div className="App">
            <section id="authentication-layout">
              <div className="flex-container">
                <div className="img-area">
                  <img src={siteLogo} alt="logo" style={{ width: '50%' }} />
                </div>
                <div className="authentication-area">
                  <Label>Reset Password</Label>
                  <MDBInput
                    label="Email"
                    id="email"
                    type="email"
                    icon="user"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    color="black"
                    style={{ marginBottom: 0 }}
                  />
                  <MDBBtn
                    color="white"
                    type="submit"
                    onClick={handleResetButton}
                  >
                    Send reset link
                  </MDBBtn>
                  <Link href="/">Go back</Link>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <Redirect to="/components" />
        )}
      </>
    );
}

export default PasswordReset;