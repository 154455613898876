import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Route, Switch, useHistory, Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import FancyScroll from '../../../components/FancyScroll';
import PaginationNew from '../../../components/Pagination/PaginationNew';
import SearchRounded from '../../../components/Inputs/SearchRounded';
import List from '../../Settings/KitTrays/List';
import NavigationList from '../../Settings/KitTrays/Tray/NavigationList';
import TrayIcon from '../../../shared/TrayIcon';
import {
  kitMakerRoutes,
  kitMakerLayoutRoutes,
  engineering,
  generalAccessRoleList,
  componentsListRoutes,
} from '../../../constants/constants';
import KitSwapTray from './KitSwapTray';
import GoBack from '../Helpers/GoBack';
import AddComponentToCustomKit from './AddComponentToCustomKit';
import ListComponents from './ListComponents';
import OrphanComponents from './Orphans/OrphanComponents';
import PlaceOrphanInTray from './Orphans/PlaceOrphanInTray';
import Snapshots from './Snapshots/Snapshots';
import NoteTooltip from './NoteTooltip';
import FilterKits from './FilterKits';
import ModeSwitcher from '../../Settings/KitTrays/Tray/ModeSwitcher';
import CustomCheckbox from '../../../components/Inputs/CustomCheckbox';
import CustomButton from '../../../components/Buttons/CustomButton';
import Tabs from '../../Settings/Components/Tabs';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #1d265b;
  margin-top: -11px;
  width: 270px;
  flex: 0 0 auto;
  color: white;
  position: relative;
  height: 100vh;
  z-index: 2;

  & > div {
    position: fixed;
    width: inherit;
    height: 100%;
    background: #1d265b;
    padding: 20px 10px;
  }
`;

const Search = styled.div`
  display: flex;
  position: relative;
  color: black;
  margin-bottom: 10px;
`;

const Pagination = styled.div`
  display: flex;
  overflow-x: hidden;
  justify-content: center;
  & > div {
    margin: 0px;
    a {
      font-size: 10px !important;
    }
    ul {
      margin: 5px 0px !important;
    }
  }
`;

const KitMakerActiveMenu = (props) => {
  const {
    kits,
    searchValue,
    totalPages,
    activePage,
    kit,
    tray,
    componentAssignments,
    selectedCavity,
    components,
    notes,
    isEngineering,
    canvasElemRef,
    user,
    notesObj,
    selectedNote,
    selectedPageOnCanvas,
    setSelectedPageOnCanvas,
    layoutData,
    objects,
    pages = [],
    isSales,
    trayList,
    snapshots,
    updateAssemblyOrder,
  } = props;

  const {
    onKitClick,
    showKitDetails,
    onSearch,
    handlePagination,
    deleteKit,
    setSelectedCavity,
    onComponentSearch,
    addComponent,
    deleteComponent,
    swapComponent,
    addNote,
    createSnapshot,
    deleteSnapshots,
    setPdfLoader,
    customizeKit,
    setNotesObj,
    setSelectedNote,
    setNotesEditable,
    updateLayout,
    resetLabelText,
    addNoteEvent,
    setAddNoteEvent,
    contactEngineering,
    goBack,
    assignTray,
    setLabelVisibility,
    runPdfGeneration,
    setRunPdfGeneration,
    buildDefaultPages,
  } = props;

  const {
    filterState,
    setFilterState,
    filterComponents,
    setFilterComponents,
    generateTrayPDF,
    version,
    pageBreakImages,
    pageBreakImagesLoading,
    addPageBreakImage,
    setPageImage,
    imageLoading,
    excludeFloating,
    setExcludeFloating,
    deletePageBreakImage,
    resetComponentOverride,
    componentPlacementCallback,
    labelVisibility,
    notShownQuantityOfSelectedNote,
    tabs,
    kitDetails,
  } = props;

  const ref = useRef(null);
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(null);

  useEffect(() => {
    afterHide();
    if (history.location.pathname.endsWith('/kitmaker/snapshots')) {
      setFilterComponents({});
      setExcludeFloating(false);
    }
    // eslint-disable-next-line
  }, [history.location.pathname]);

  const afterHide = () => {
    ref.current.tooltipRef = null;
    ReactTooltip.hide();
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [notes, history.location.pathname]);

  const counts = {
    notesCount: notes?.length || 0,
    orphansCount: componentAssignments?.filter((c) => c.isOrphan)?.length || 0,
    componentsCount:
      componentAssignments?.filter((c) => !c.isOrphan)?.length || 0,
  };

  const onPageClick = useCallback(
    (page, type) => {
      let cavities = objects.filter((st) => st.pageId === page.value);

      if (cavities.length) {
        setFilterComponents((prev) => ({
          ...prev,
          [type]:
            type === 'to' ? cavities[0].id : cavities[cavities.length - 1].id,
        }));
      }
    },
    [objects, setFilterComponents],
  );

  return (
    <Container>
      <div>
        <Switch>
          <Route
            exact
            path={[
              '/my-kit-list/:id/kitmaker',
              '/my-kit-list/:id/kitmaker/build',
            ]}
          >
            <GoBack title={!isSales && 'Engineering menu'} goBack={goBack} />
            <Tabs
              tabs={tabs}
              type="select"
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <NavigationList
              list={kitMakerRoutes(tray?.id, snapshots.length === 0, isSales)}
              icon={
                <TrayIcon
                  color="white"
                  size="12px"
                  styles={{ marginRight: 10 }}
                />
              }
              counts={counts}
            />
            <ModeSwitcher
              options={objects}
              filterCavities={filterComponents}
              defaultValue={1}
              setFilterCavities={setFilterComponents}
              limit={10}
              excludeFloating={excludeFloating}
              setExcludeFloating={setExcludeFloating}
              pages={pages
                .filter((page) => objects.find((o) => o.pageId === page.id))
                .map((p, _) => ({
                  label: `Page ${pages.length - p.id}`,
                  value: p.id,
                }))
                .reverse()}
              onPageClick={onPageClick}
            />
            <CustomCheckbox
              label={'Exclude floating component'}
              onChange={() => setExcludeFloating(!excludeFloating)}
              checked={excludeFloating}
              style={{
                paddingBottom: '20px',
                paddingTop: '5px',
                display: 'flex',
                justifyContent: 'center',
              }}
            />
            {filterComponents.from !== undefined &&
              filterComponents.to !== undefined && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <CustomButton
                    rounded
                    background={`#61A431`}
                    label={'Kit Portrait'}
                    onClick={() => generateTrayPDF()}
                  />
                </div>
              )}
          </Route>
          <Route exact path={['/my-kit-list/:id/components-list']}>
            <GoBack goBack={goBack} />
            <Tabs
              tabs={tabs}
              type="select"
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <NavigationList
              list={componentsListRoutes(kit?.id, snapshots.length === 0)}
              icon={
                <TrayIcon
                  color="white"
                  size="12px"
                  styles={{ marginRight: 10 }}
                />
              }
              counts={counts}
            />
          </Route>
          <Route exact path={['/kitlist', '/kitlist/:id']}>
            <Search>
              <SearchRounded
                placeholder="Kit Search..."
                onChange={onSearch}
                value={searchValue}
              />
            </Search>
            <FancyScroll>
              <List
                list={kits}
                title="Kits"
                onItemClick={onKitClick}
                isActive={kit}
                showKitDetails={showKitDetails}
                valueKeys={['trayId', 'trayImageUrl']}
                contactEngineering={isSales ? contactEngineering : null}
                assignTray={isEngineering ? assignTray : null}
                trayList={trayList}
              />
            </FancyScroll>
            {totalPages > 1 && (
              <Pagination>
                <PaginationNew
                  totalPages={totalPages}
                  activePage={activePage}
                  onChange={handlePagination}
                  pagesNumber={3}
                  isMobile={true}
                />
              </Pagination>
            )}
          </Route>
          <Route exact path={['/kitlist/:id/details']}>
            <ListComponents
              status={kit?.status}
              readOnly
              kit={kit}
              offset={250}
              customizeKit={customizeKit}
              setSelectedCavity={setSelectedCavity}
              selectedCavity={selectedCavity}
            />
          </Route>
          <Route exact path={kitMakerLayoutRoutes.listKits}>
            <FilterKits
              filterState={filterState}
              setFilterState={setFilterState}
              kits={kits}
              isEngineering={isEngineering}
            />
          </Route>
          <Route exact path={kitMakerLayoutRoutes.sales}>
            <GoBack title="Submitted Kits" goBack={() => history.goBack()} />
            <Tabs
              tabs={tabs}
              type="select"
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <List
              list={kit ? [kit] : []}
              isActive={null}
              onItemDelete={deleteKit}
              onItemClick={() => {}}
            />
          </Route>
          <Route exact path={['/my-kit-list/:id/kitmaker/orphan-components']}>
            <OrphanComponents
              status={kit?.status}
              deleteComponent={deleteComponent}
              isEngineering={isEngineering}
            />
          </Route>
          <Route
            exact
            path="/my-kit-list/:id/kitmaker/orphan-components/place-in-tray"
          >
            <PlaceOrphanInTray
              componentAssignments={componentAssignments}
              componentPlacementCallback={componentPlacementCallback}
              setFilterComponents={setFilterComponents}
              deleteComponent={deleteComponent}
            />
          </Route>
          <Route exact path={['/my-kit-list/:id/kitmaker/snapshots']}>
            <Snapshots
              setPdfLoader={setPdfLoader}
              deleteSnapshots={deleteSnapshots}
              tray={tray}
              createSnapshot={createSnapshot}
              canvasElemRef={canvasElemRef}
              kit={kit}
              componentAssignments={componentAssignments}
              notesObj={notesObj}
              setNotesEditable={setNotesEditable}
              selectedPage={selectedPageOnCanvas}
              setSelectedPage={setSelectedPageOnCanvas}
              setNotesObj={setNotesObj}
              setSelectedNote={setSelectedNote}
              selectedNote={selectedNote}
              updateLayout={updateLayout}
              resetLabelText={resetLabelText}
              updateAssemblyOrder={updateAssemblyOrder}
              layoutData={layoutData}
              addNoteEvent={addNoteEvent}
              setAddNoteEvent={setAddNoteEvent}
              version={version}
              pageBreakImages={pageBreakImages}
              pageBreakImagesLoading={pageBreakImagesLoading}
              addPageBreakImage={addPageBreakImage}
              objects={objects}
              pages={pages}
              setPageImage={setPageImage}
              imageLoading={imageLoading}
              deletePageBreakImage={deletePageBreakImage}
              setSelectedCavity={setSelectedCavity}
              isSales={isSales}
              labelVisibility={labelVisibility}
              setLabelVisibility={setLabelVisibility}
              runPdfGeneration={runPdfGeneration}
              setRunPdfGeneration={setRunPdfGeneration}
              buildDefaultPages={buildDefaultPages}
              notShownQuantityOfSelectedNote={notShownQuantityOfSelectedNote}
              kitDetails={kitDetails}
            />
          </Route>
          {isEngineering ? (
            <Route exact path="/my-kit-list/:id/kitmaker/swap-tray">
              <KitSwapTray
                kit={kit}
                tray={tray}
                role={isEngineering}
                buildDefaultPages={buildDefaultPages}
                setFilterComponents={setFilterComponents}
              />
            </Route>
          ) : (
            <Redirect to={'/my-kit-list/'} />
          )}
          <Route exact path="/my-kit-list/:id/kitmaker/add-component">
            <AddComponentToCustomKit
              searchedComponents={components}
              onComponentSearch={onComponentSearch}
              selectedCavity={selectedCavity}
              status={kit?.status}
              addComponent={addComponent}
              resetComponentOverride={resetComponentOverride}
              deleteComponent={
                kit?.status === engineering &&
                generalAccessRoleList.find((r) => r === user?.role?.alias)
                  ? null
                  : deleteComponent
              }
              setSelectedCavity={setSelectedCavity}
              onSwap={swapComponent}
              offset={320}
              canMoveComponent={isEngineering}
              updateAssemblyOrder={updateAssemblyOrder}
              objects={objects}
              pages={pages}
              version={version}
              user={user}
              accessLock={
                kit?.status === engineering &&
                generalAccessRoleList.find((r) => r === user?.role?.alias)
              }
              setFilterComponents={setFilterComponents}
              setExcludeFloating={setExcludeFloating}
              notShownQuantityOfSelectedNote={notShownQuantityOfSelectedNote}
            />
          </Route>
          <Route exact path="/my-kit-list/:id/kitmaker/list-components">
            <ListComponents
              selectedCavity={selectedCavity}
              status={kit?.status}
              deleteComponent={
                kit?.status === engineering &&
                generalAccessRoleList.find((r) => r === user?.role?.alias)
                  ? null
                  : deleteComponent
              }
              setSelectedCavity={setSelectedCavity}
              accessLock={
                kit?.status === engineering &&
                generalAccessRoleList.find((r) => r === user?.role?.alias)
              }
              setFilterComponents={setFilterComponents}
              setExcludeFloating={setExcludeFloating}
            />
          </Route>
        </Switch>
        <ReactTooltip
          ref={ref}
          isCapture={true}
          event="click"
          id="note-tip"
          place="right"
          effect="solid"
          type="light"
          clickable={true}
          scrollHide={false}
          resizeHide={false}
          offset={{ left: 170 }}
          afterHide={afterHide}
        >
          <NoteTooltip
            notes={notes}
            user={user}
            addNote={addNote}
            close={afterHide}
          />
        </ReactTooltip>
      </div>
    </Container>
  );
};

export default KitMakerActiveMenu;
