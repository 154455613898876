import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div(
  ({ active }) => `
  color: ${active ? '#61a431' : '#959595'};
  cursor: pointer;

  & svg {
    // width: 1.5rem;
    // height: 1.5rem;
  }
`,
);

const IconToggle = ({ active, children, onClick }) => {
  return (
    <Wrapper active={active} onClick={onClick}>
      {children}
    </Wrapper>
  );
};

export default IconToggle;
