import * as actionTypes from '../../constants/actionTypes';

const initialState = {
    snapshots: [],
    snapshotsLoading: false,
    updateAssemblyOrderLoading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LIST_SNAPSHOTS_START:
            return {
                ...state,
                snapshotsLoading: true,
            }

        case actionTypes.LIST_SNAPSHOTS_SUCCESS:
            return {
                ...state,
                snapshots: action.snapshots,
                snapshotsLoading: false,
            }

        case actionTypes.LIST_SNAPSHOTS_FAIL:
            return {
                ...state,
                snapshotsLoading: false,
            }

        case actionTypes.UPDATE_SNAPSHOT_ORDER_SUCCESS:
            return {
                ...state,
                snapshots: state.snapshots.map(x => x.id === action.Id ? ({...x, orderNumber: action.Order}) : x)
            }

        case actionTypes.UPDATE_ASSEMBLY_ORDER_START:
            return {
                ...state,
                updateAssemblyOrderLoading: true
            }

        case actionTypes.UPDATE_ASSEMBLY_ORDER_SUCCESS:
        case actionTypes.UPDATE_ASSEMBLY_ORDER_FAIL:
            return {
                ...state,
                updateAssemblyOrderLoading: false
            }

        default:
            return { ...state }
    };
};
