import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';

const Icon = styled.div`
    position: absolute;
    top: 10px;
    left: 20px;
`;

const Input = styled.input`
  height: 40px;
  border-radius: 20px;
  width: 240px;
  margin: 0px 10px 0px 10px;
  text-indent: 30px;
`;

// Search rounded with icon
const SearchRounded = (props) => {
    const { ...rest } = props;

    return (
        <>
            <Icon><FontAwesomeIcon icon={faSearch} /></Icon>
            <Input {...rest} />
        </>
    );
};

export default SearchRounded;
