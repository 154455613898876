import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { breadcrumbs, cmsAccessRoleList } from '../../constants/constants';
import CustomSelect from '../Selects/CustomSelect';
import { useSelector } from 'react-redux';

const Container = styled.div`
  display: flex;
  a {
    color: #1d265b;
  }

  & > div:last-of-type {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-right: 60px;
  margin-left: 40px;
  display: flex;
  align-items: center;
`;

const VerticalLine = styled.div`
  border-right: 1px solid black;
  height: 90%;
`;

const Breadcrumb = ({ routes, placeholder, role }) => {
  const history = useHistory();
  const path = history.location.pathname;
  const state = history.location.state;
  const [breadcrumbList, setBreadcrumbList] = useState([]);

  const kit = useSelector((state) => state.kits.kitDetails);

  const styles = {
    container: (provided) => ({
      ...provided,
      minWidth: 100,
      margin: '0px 10px',
    }),
    control: (provided) => ({
      ...provided,
      border: 'none',
      height: 43,
      boxShadow: 'none',
      borderRadius: 0,
      outline: 'none',
      backgroundColor: 'none',
      cursor: 'pointer',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#1d265b',
      fontSize: 14,
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: 12,
      color: 'black',
      fontWeight: 400,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#1d265b',
      fontSize: 14,
    }),
  };

  useEffect(() => {
    const array = [];
    const splitedPath = path.split('/');

    splitedPath.forEach((sp, index) => {
      const breadcrumb = breadcrumbs.find((item) => item.value === sp);

      if (breadcrumb) {
        array.push({
          path: breadcrumb.value,
          label:
            breadcrumb.roleLabel &&
            cmsAccessRoleList.find((r) => r === role?.alias)
              ? breadcrumb.roleLabel
              : breadcrumb.label,
        });
      }

      if (splitedPath.length - 1 === index && state?.tray?.data) {
        array.push({ path: '/', label: state.tray.data.number });
      }

      if (splitedPath.length - 1 === index && state?.component) {
        array.push({ path: '/', label: state.component.number });
      }
    });

    setBreadcrumbList(array);
    // eslint-disable-next-line
  }, [path]);

  const len = breadcrumbList.length;
  const inSubmittedKits = path.includes('/my-kit-list/') && kit;

  return (
    <Container>
      <Title>{breadcrumbList[0]?.label}</Title>
      <div>
        {len > 0 && <VerticalLine />}
        {len > 0 && (
          <>
            <NavLink to="/components">
              <FontAwesomeIcon icon={faHome} style={{ margin: '0px 10px' }} />
            </NavLink>
            {routes
              .filter((f) => f.visible)
              .find((route) => path.includes(route.value)) && (
              <>
                <span style={{ margin: '0px 10px' }}>&#10095;&#10095;</span>
                <CustomSelect
                  placeholder={placeholder}
                  customStyles={styles}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  isSearchable={false}
                  options={routes
                    .filter((route) => !path.includes(route.value))
                    .map((route) => ({
                      value: route.value,
                      label:
                        route.roleLabel &&
                        cmsAccessRoleList.find((r) => r === role?.alias)
                          ? route.roleLabel
                          : route.label,
                    }))}
                  onChange={(option) => history.push(option.value)}
                />
                <span style={{ margin: '0px 10px' }}>&#10095;&#10095;</span>
              </>
            )}
          </>
        )}
        {breadcrumbList.map((breadcrumb, index) =>
          len - 1 === index ? (
            <span key={breadcrumb.path}>{`${breadcrumb.label}`}</span>
          ) : (
            <div key={breadcrumb.path}>
              <NavLink to={'/' + breadcrumb.path}>{breadcrumb.label}</NavLink>
              <span style={{ margin: '0px 10px' }}>&#10095;&#10095;</span>
            </div>
          ),
        )}

        {inSubmittedKits && (
          <>
            <span style={{ margin: '0px 10px' }}>&#10095;&#10095;</span>
            <span style={{ fontWeight: 400 }}>{kit.name.split('_')[0]}</span>
          </>
        )}
      </div>
    </Container>
  );
};

export default Breadcrumb;