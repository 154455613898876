import * as actionTypes from '../../constants/actionTypes';

const initialState = {
    users: [],
    roles: [],
    usersLoading: false,
    rolesLoading: false,
    loadingOverlay: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS_START:
      return {
        ...state,
        usersLoading: action.loading,
        loadingOverlay: action.overlay,
      };

    case actionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.users,
        usersLoading: false,
        loadingOverlay: false,
      };

    case actionTypes.GET_USERS_FAIL:
      return {
        ...state,
        usersLoading: false,
        loadingOverlay: false,
      };

    case actionTypes.GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.roles.map((role) => ({
          value: role.id,
          label: role.name,
        })),
        rolesLoading: false,
      };

    default:
      return { ...state };
  }
};
