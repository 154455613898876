import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useDropzone } from 'react-dropzone'
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { showSnackbar } from '../../../../redux/actions/snackbar';
import { imageFormats, maxImageSize } from '../../../../constants/constants';
import CustomButton from '../../../../components/Buttons/CustomButton';

const ImageSection = styled.div`
    display: flex;
    border: 2px dashed #c8c8c89e;
    align-items: center;
    justify-content: center;
    margin: 20px 20px;
    height: 180px;
    color: #c8c8c89e;
    flex-direction: column;

    & > img {
        width: 100%;
    }
`;

const UploadImage = styled.div`
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
    flex-direction: column;

    button {
        width: fit-content;
        color: #61A431;
        font-weight: 500;
        background: white;
    }
    span {
        font-size: 8px;
        margin-top: 10px;
        color: #c8c8c8;
    }
`;

const ImageDetails = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    align-items: center;
    font-size: 10px;
    font-weight: 500;

    & > div {
        width: 100px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
`;

const ImageComponent = ({ data, onTrayChange }) => {
    const [imgProps, setImgProps] = useState({ wide: 'N/A', height: 'N/A', size: 'N/A' });
    const imgRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
      const image = new Image();

      if (data?.file) {
        const size = (data.file.size / 1024 / 1024).toFixed(2) + ' MB';
        setTimeout(
          () =>
            setImgProps({
              wide: imgRef?.current?.naturalWidth + ' px',
              height: imgRef?.current?.naturalHeight + ' px',
              size,
            }),
          100,
        );
      }

      return () => {
        image.onload = null;
      };
      // eslint-disable-next-line
    }, [data?.file, data?.imageUrl]);

    const onDrop = useCallback(files => {
        if (!files.length) {
            dispatch(showSnackbar('warning', 'File must be image (max size 10MB)'));
            return;
        }

        onTrayChange({ target: { files } });
    }, [onTrayChange, dispatch]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxSize: maxImageSize, accept: imageFormats });

    return (
        <>
            <ImageSection {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? <p>Drop the PNG file here ...</p> :
                    <>
                        {(data?.image || data?.imageUrl) ? <img src={data?.image || data?.imageUrl} alt="Upload" ref={imgRef} /> : <>
                            <FontAwesomeIcon icon={faImage} style={{ fontSize: 72 }} />
                            <span>Upload a Tray image</span>
                        </>}
                    </>
                }
            </ImageSection>
            <UploadImage>
                <CustomButton rounded label='Upload Image' onClick={() => imgRef.current?.click()} />
                <span>*.png Max Size {maxImageSize / 1024 / 1024}MB</span>
            </UploadImage>
            {data?.file && (<ImageDetails>
                <div>
                    <span>Width</span>
                    <span>{imgProps.wide}</span>
                </div>
                <div>
                    <span>Height</span>
                    <span>{imgProps.height}</span>
                </div>
                <div>
                    <span>Size</span>
                    <span>{imgProps.size}</span>
                </div>
            </ImageDetails>)}
        </>
    );
};

export default ImageComponent;
