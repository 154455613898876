import React from 'react';
import styled from '@emotion/styled';
import SearchRounded from '../../../components/Inputs/SearchRounded';
import CustomSelect from '../../../components/Selects/CustomSelect';
import { statuses, selectStyles } from '../../../constants/constants';
import _ from 'lodash';

const Search = styled.div`
    display: flex;
    position: relative;
    color: black;
    margin-bottom: 10px;
`;

const Title = styled.div`
    padding: 10px 15px 0 15px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
`;

const statusesList = [...Object.values(statuses).map(x => ({
    value: x.status,
    label: x.label
})), { value: undefined, label: "Unset" }];


const FilterKits = ({ filterState, setFilterState, kits, isEngineering }) => {

    const users = [
        ..._.uniqBy([...kits.map(x => x.createdBy), ...kits.map(x => x.updatedBy)].filter(x => !!x), 'id').map(x => ({
            label: `${x.firstName || ""} ${x.lastName || ""}`,
            value: x.id
        })),
        { value: undefined, label: "Unset" }
    ];

    const setValue = (value, prop) => {
        setFilterState({ ...filterState, [prop]: value })
    }

    return <>
        <Title>
            <span>Custom Kit Status</span>
        </Title>
        <Search>
            <SearchRounded placeholder="Custom Kit Search..." onChange={(e) => setValue(e.target.value, "search")} value={filterState?.search || ""} />
        </Search>
        {isEngineering && <CustomSelect placeholder="Filter by User" customStyles={selectStyles} isSearchable={false} options={users} value={!!filterState.user && users.find(x => x.value === filterState.user)} onChange={(elem) => setValue(elem.value, "user")} />}
        <CustomSelect placeholder="Filter by Status" customStyles={selectStyles} isSearchable={false} options={statusesList} value={!!filterState.status && statusesList.find(x => x.value === filterState.status)} onChange={(elem) => setValue(elem.value, "status")} />
    </>
}

export default FilterKits;