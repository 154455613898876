import React, { Component } from 'react';
import { MDBPagination, MDBPageItem, MDBPageNav } from "mdbreact";
import agent from '../../agent';
import Loader from "../loaders/Loader";

let KitArray = {
    kits: [
        { description: "No results found" }
    ],
    totalPages: 0,
    totalCount: 0
}


class PreviouslyPagination extends Component{
    constructor(props) {
        super(props);
        this.state = {
            reloadKey: Math.random(),
            kitData: null,
            showComponent: false,
            paginationActive: false,
            isOpen: false,
            currentPage: 1,
            everyPage: 12,
            searchText: null,
            selectedNodeArray: [],
            upperPageBound: 6,
            lowerPageBound: 0,
            pageBound: 3,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            justLikeKitData: null,
            likeKitId: null,
            KitId: null,
            currentPagePrevious: 1,
            upperPageBoundPrevious: 6,
            lowerPageBoundPrevious: 0,
            pageBoundPrevious: 3,
        }
    }
    componentWillMount() {
        const token = window.localStorage.getItem('jwt');
        let expiry = new Date(window.localStorage.getItem("tokenExpiry"));
        let current = new Date();
            if (token && expiry.getTime() >= current.getTime()) {
            agent.SAVEDASK.previouslyOrderedKit().then((res) => {
                if (res.totalCount === 0) {
                    this.setState({ kitData: KitArray });
                }
                else {
                    this.setState({ kitData: res });
                }
            }
            )
        }
        else {
            this.props.onClickLogout()
        }
    }

    handleClickPrevious = (event) => {
        this.setState({
            currentPagePrevious: Number(event.target.id), paginationActive: true
        });
        this.setPrevAndNextBtnClassPrevious(this.state.currentPagePrevious);
        this.loadComponentDataPrevous();

    }
    handleNextClickPrevious = () => {
        if (this.state.currentPage < this.state.everyPagePrevious) {
            this.setState({
                currentPagePrevious: (this.state.currentPagePrevious - 1) + 1
            });
        }
    }
    handlePreviousClickPrevious = () => {
        if (this.state.currentPagePrevious > 1)
            this.setState({
                currentPage: (this.state.currentPagePrevious - 1) - 1,
            });
    }

    btnIncrementClickPrevious=()=> {
        this.setState({ upperPageBoundPrevious: this.state.upperPageBoundPrevious + this.state.pageBoundPrevious });
        this.setState({ lowerPageBoundPrevious: this.state.lowerPageBoundPrevious + this.state.pageBoundPrevious });
        let listidPrevious = this.state.upperPageBoundPrevious + 1;
        this.setPrevAndNextBtnClassPrevious(listidPrevious);
        this.loadComponentDataPrevous();
    }

    setPrevAndNextBtnClassPrevious=(listidPrevious)=> {
        let totalPagePrevious = this.state.currentPagePrevious;
        this.setState({ isNextBtnActive: 'disabled' });
        this.setState({ isNextBtnActive: 'disabled' });
        if (totalPagePrevious === listidPrevious && totalPagePrevious > 1) {
            this.setState({ isPrevBtnActive: '' });
        }
        else if (listidPrevious === 1 && totalPagePrevious > 1) {
            this.setState({ isNextBtnActive: '' });
        }
        else if (totalPagePrevious > 1) {
            this.setState({ isNextBtnActive: '' });
            this.setState({ isPrevBtnActive: '' });
        }
    }

    btnDecrementClickPrevious=() =>{
        this.setState({ upperPageBoundPrevious: this.state.upperPageBoundPrevious - this.state.pageBoundPrevious });
        this.setState({ lowerPageBoundPrevious: this.state.lowerPageBoundPrevious - this.state.pageBoundPrevious });
        let listidPrevious = this.state.upperPageBoundPrevious - this.state.pageBoundPrevious;
        this.setPrevAndNextBtnClassPrevious(listidPrevious);
        this.loadComponentDataPrevous();
    }

    btnPrevClickPrevious=()=>{
        if ((this.state.currentPagePrevious - 1) % this.state.pageBoundPrevious === 0) {
            this.setState({ upperPageBoundPrevious: this.state.upperPageBoundPrevious - this.state.pageBoundPrevious });
            this.setState({ lowerPageBoundPrevious: this.state.lowerPageBoundPrevious - this.state.pageBoundPrevious });
        }
        let listidPrevious = this.state.currentPagePrevious - 1;
        this.setState({ currentPagePrevious: listidPrevious });
        this.setPrevAndNextBtnClassPrevious(listidPrevious - 1);
        this.loadComponentDataPrevous();
    }

    btnNextClickPrevious=()=> {
        if ((this.state.currentPagePrevious + 1) > this.state.upperPageBoundPrevious) {
            this.setState({ upperPageBoundPrevious: this.state.upperPageBoundPrevious + this.state.pageBoundPrevious });
            this.setState({ lowerPageBoundPrevious: this.state.lowerPageBoundPrevious + this.state.pageBoundPrevious });
        }

        let listidPrevious = this.state.currentPagePrevious + 1;
        this.setState({ currentPagePrevious: listidPrevious });
        this.setPrevAndNextBtnClassPrevious(this.state.upperPageBoundPrevious);
        this.loadComponentDataPrevous();
    }


    btnFirstPagePrevious=()=> {
        this.setState({ currentPagePrevious: Number(1) });
        this.setState({ upperPageBoundPrevious: Number(6) });
        this.setState({ lowerPageBoundPrevious: Number(0) });
        this.setPrevAndNextBtnClassPrevious(Number(1));
        this.loadComponentDataPrevous();
    }

    btnLastPagePrevious=()=> {
        if ((this.state.currentPagePrevious) > this.state.upperPageBoundPrevious) {
            this.setState({ currentPagePrevious: this.state.justLikeKitData.length });
            this.setState({ upperPageBoundPrevious: this.state.everyPagePrevious });
            this.setState({ lowerPageBoundPrevious: this.state.currentPagePrevious });
        } else {

            this.setState({ upperPageBoundPrevious: Number(Math.floor((this.state.kitData.length + this.state.everyPage - 1) / this.state.everyPage)) });
            this.setState({ lowerPageBoundPrevious: Number(Math.floor((this.state.kitData.length + this.state.everyPage - 1) / this.state.everyPage)) - 3 });
            this.setState({ currentPagePrevious: Number(Math.floor((this.state.kitData.length + this.state.everyPage - 1) / this.state.everyPage)) });
        }
        this.setPrevAndNextBtnClassPrevious(this.state.kitData.length);
        this.loadComponentDataPrevous();
    }
    loadComponentDataPrevous = () => {
        agent.SAVEDASK.previouslyOrderedKit().then((res) => {
            if (res.totalCount === 0) {
                this.setState({ kitData: KitArray });
            }
            else {
                this.setState({ kitData: res });
                let pageloopPrevious = this.state.currentPagePrevious * 12;
                let datPrevious = [];
                for (let k = pageloopPrevious - 12; k <= pageloopPrevious - 1; k++) {
                    if (this.state.kitData[k] !== undefined) {
                        datPrevious.push(this.state.kitData[k]);
                    }
                }
                const { previousdata } = this.props;
                previousdata(datPrevious);
                

            }
        }
        )
    };

    render(){
        if (this.state.kitData === null ) {
            return <Loader />
        } else {
            var  iitemPrevious = "";
            const pageNumbersPrevious = [];
            const activePageStyle = { background: "#1d265b", color: "#ffffff", padding: "0.4rem 0.75rem" };
            const inactivePageStyle = { background: "#838c8c" };
            let totalPreviouslyOrderedPage = (Math.floor((this.state.kitData.length + this.state.everyPage - 1) / this.state.everyPage));
            for (let i = 1; i <= totalPreviouslyOrderedPage; i++) {
                pageNumbersPrevious.push(i);
            }
            const renderPageNumbersPrevious = pageNumbersPrevious.map(numberPrevious => {
                iitemPrevious = <MDBPageItem active>
                    <MDBPageNav className="page-link" style={this.state.currentPagePrevious === numberPrevious ? activePageStyle : inactivePageStyle}
                        key={numberPrevious}
                        id={numberPrevious}
                        onClick={this.handleClickPrevious}>
                        {numberPrevious}
                    </MDBPageNav>
                </MDBPageItem>;
                return (numberPrevious < this.state.upperPageBoundPrevious + 1) && numberPrevious > this.state.lowerPageBoundPrevious && totalPreviouslyOrderedPage >= numberPrevious ? iitemPrevious : null;
            });
            let pageIncrementBtnPrevious = null;
            if (pageNumbersPrevious.length > this.state.upperPageBoundPrevious) {
                pageIncrementBtnPrevious = <MDBPageItem>
                    <MDBPageNav className="page-link"
                        onClick={this.btnIncrementClickPrevious}>
                        ....
    </MDBPageNav>
                </MDBPageItem>;
            }
            let pageDecrementBtnPrevious = null;
            if (this.state.lowerPageBoundPrevious >= 1) {
                pageDecrementBtnPrevious = <MDBPageItem>
                    <MDBPageNav className="page-link"
                        onClick={this.btnDecrementClickPrevious}>
                        ....
    </MDBPageNav>
                </MDBPageItem>
            }
            let renderPrevBtnPrevious = null;
            if (this.state.currentPagePrevious === 1) {
                renderPrevBtnPrevious = <MDBPageItem>
                    <MDBPageNav className="page-link" >
                        <span id="btnPrev">  </span>
                    </MDBPageNav>
                </MDBPageItem>
            }
            else {
                renderPrevBtnPrevious = <MDBPageItem>
                    <MDBPageNav className="page-link"
                        onClick={this.btnPrevClickPrevious}>
                        <span id="btnPrev"> Prev </span>
                    </MDBPageNav>
                </MDBPageItem>
            }
            let renderNextBtnPrevious = null;
           if(this.state.upperPageBoundPrevious === Number(Math.floor((this.state.kitData.length + this.state.everyPage - 1) / this.state.everyPage))) {
                renderNextBtnPrevious = <MDBPageItem>
                    <MDBPageNav className="page-link" onClick={this.btnNextClickPrevious}>
                        <span id="btnNext">  </span>
                    </MDBPageNav>
                </MDBPageItem>
                renderNextBtnPrevious = this.state.kitData.length === 0 ? null : renderNextBtnPrevious;
            }
            else {
                renderNextBtnPrevious = <MDBPageItem>
                    <MDBPageNav className="page-link" onClick={this.btnNextClickPrevious}>
                        <span id="btnNext"> Next </span>
                    </MDBPageNav>
                </MDBPageItem>
            }
            let renderFirstpagePrevious = null;
            if (this.state.currentPagePrevious === 1) {
                renderFirstpagePrevious = <MDBPageItem onClick={this.btnFirstPagePrevious} >
                    <MDBPageNav className="page-link" aria-label="Previous">
                    </MDBPageNav>
                </MDBPageItem>
            } else {
                renderFirstpagePrevious = <MDBPageItem onClick={this.btnFirstPagePrevious} >
                    <MDBPageNav className="page-link" aria-label="Previous">
                        <span>First</span>
                    </MDBPageNav>
                </MDBPageItem>
            }
            let renderLastPagePrevious = null;
            if(this.state.upperPageBoundPrevious === Number(Math.floor((this.state.kitData.length + this.state.everyPage - 1) / this.state.everyPage))){
                renderLastPagePrevious = <MDBPageItem >
                <MDBPageNav className="page-link"
                 >
                    <span></span>
                </MDBPageNav>
            </MDBPageItem>
            }else{
                renderLastPagePrevious = <MDBPageItem >
                <MDBPageNav className="page-link"
                    onClick={this.btnLastPagePrevious}>
                    <span>Last</span>
                </MDBPageNav>
            </MDBPageItem>
            }
        return(
            (this.state.kitData.length > this.state.everyPage) ?
           <MDBPagination circle>
            {renderFirstpagePrevious}
            {renderPrevBtnPrevious}
            {pageDecrementBtnPrevious}
            {renderPageNumbersPrevious}
            {pageIncrementBtnPrevious}
            {renderNextBtnPrevious}
            {renderLastPagePrevious}
        </MDBPagination>:null
        )
    }
}
}

export default PreviouslyPagination;