import * as actionTypes from '../../constants/actionTypes';
import { getToken } from '../../utils/getToken';
import axios from '../axiosMiddleware/axios';
import { showSnackbar } from './snackbar';

export const loadUserList = (isLoaded) => async dispatch => {
  dispatch({
    type: actionTypes.GET_USERS_START,
    loading: !isLoaded,
    overlay: isLoaded,
  });

  try {
    const res = await axios.get('/api/admin/users', {
      headers: { Authorization: 'Bearer ' + getToken() },
    });

    dispatch({ type: actionTypes.GET_USERS_SUCCESS, users: res.data });
  } catch (err) {
    dispatch({ type: actionTypes.GET_USERS_FAIL, error: err });
    dispatch(showSnackbar('error', err.message));
  }
};

export const loadRoleList = () => async dispatch => {
    dispatch({ type: actionTypes.GET_ROLES_START });

    try {
        const res = await axios.get('/api/admin/roles', { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.GET_ROLES_SUCCESS, roles: res.data });
    } catch (err) {
        dispatch({ type: actionTypes.GET_ROLES_FAIL, error: err });
    }
};

export const changeUserRole = (userId, role) => async dispatch => {
    dispatch({ type: actionTypes.CHANGE_USER_ROLE_START });

    try {
        await axios.put(`/api/admin/users/${userId}/role/${role.value}`, null, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch(showSnackbar('success', 'Change user role success'));
        dispatch({ type: actionTypes.CHANGE_USER_ROLE_SUCCESS });
        dispatch(loadUserList(true));
    } catch (err) {
        let message = '';

        if (err.response?.status === 404) {
            message = "User with the provided id doesn't exist";
        } else if (err.response?.status === 400) {
            message = err.response.data;
        } else {
            message = 'Something went wrong, please try later'
        }

        dispatch(showSnackbar('error', message));
        dispatch({ type: actionTypes.CHANGE_USER_ROLE_FAIL, error: err });
    }
};

export const changeUserAccess = (userId, isActive) => async dispatch => {
    dispatch({ type: actionTypes.CHANGE_USER_ACCESS_START });

    try {
        await axios.put(`/api/admin/users/${userId}/access/${isActive}`, null, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch(showSnackbar('success', 'Change user access success'));
        dispatch({ type: actionTypes.CHANGE_USER_ACCESS_SUCCESS });
        dispatch(loadUserList(true));
    } catch (err) {
        let message = '';

        if (err.response?.status === 404) {
            message = "User with the provided id doesn't exist";
        } else if (err.response?.status === 400) {
            message = err.response.data;
        } else {
            message = 'Something went wrong, please try later'
        }

        dispatch(showSnackbar('error', message));
        dispatch({ type: actionTypes.CHANGE_USER_ACCESS_FAIL, error: err });
    }
};

export const updateUser = (user) => async dispatch => {
    dispatch({ type: actionTypes.UPDATE_USER_START });

    const requestBody = {
        UserName: user.userName,
        FirstName: user.firstName,
        LastName: user.lastName,
        RoleId: user.role.value || user.role.id
    };

    try {
        await axios.put(`/api/admin/users/${user.id}`, requestBody, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.UPDATE_USER_SUCCESS });
        dispatch(showSnackbar('success', 'User update success'));
        dispatch(loadUserList(true));
    } catch (err) {
        let message = '';

        if (err.response?.status === 404) {
            message = "User with the provided id doesn't exist";
        } else if (err.response?.status === 400) {
            message = err.response.data;
        } else {
            message = 'Something went wrong, please try later'
        }

        dispatch(showSnackbar('error', message));
        dispatch({ type: actionTypes.UPDATE_USER_FAIL, error: err });
    }
};

export const createUser = (user) => async dispatch => {
    dispatch({ type: actionTypes.CREATE_USER_START });

    const requestBody = {
        UserName: user.userName,
        Password: user.password,
        FirstName: user.firstName,
        LastName: user.lastName,
        RoleId: user.role.value
    };

    try {
        await axios.post('/api/admin/users', requestBody, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.CREATE_USER_SUCCESS });
        dispatch(showSnackbar('success', 'User successfully created'));
        dispatch(loadUserList(true));
    } catch (err) {
        let message = '';

        if (err.response?.status === 400) {
            message = "Some parameters are invalid";
        } else {
            message = 'Something went wrong, please try later'
        }

        dispatch(showSnackbar('error', message));
        dispatch({ type: actionTypes.CREATE_USER_FAIL, error: err });
    }
};

export const deleteUser = (userId) => async dispatch => {
    dispatch({ type: actionTypes.DELETE_USER_START });

    try {
        await axios.delete(`/api/admin/users/${userId}`, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.DELETE_USER_SUCCESS });
        dispatch(showSnackbar('success', 'User delete success'));
        dispatch(loadUserList(true));
    } catch (err) {
        let message = '';

        if (err.response?.status === 404) {
            message = "User with the provided id doesn't exist";
        } else if (err.response?.status === 400) {
            message = err.response.data;
        } else {
            message = 'Something went wrong, please try later'
        }

        dispatch(showSnackbar('error', message));
        dispatch({ type: actionTypes.DELETE_USER_FAIL, error: err });
    }
};

export const resetPassword = (userId, password, forceChangePassword) => async dispatch => {
    dispatch({ type: actionTypes.RESET_PASSWORD_START });

    const requestBody = {
        Password: password,
        ForceChangePassword: forceChangePassword
    };

    try {
        await axios.post(`/api/admin/users/${userId}/password`, requestBody, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch(showSnackbar('success', 'Reset user password success'));
        dispatch({ type: actionTypes.RESET_PASSWORD_SUCCESS });
    } catch (err) {
        let message = '';

        if (err.response?.status === 404) {
            message = "User with the provided id doesn't exist";
        } else if (err.response?.status === 400) {
            message = err.response.data;
        } else {
            message = 'Something went wrong, please try later'
        }

        dispatch(showSnackbar('error', message));
        dispatch({ type: actionTypes.RESET_PASSWORD_FAIL, error: err });
    }
};
