import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import FancyScroll from '../../../../components/FancyScroll';
import { Container, Content, Description } from "./AssemblyOrder/AssemblyOrder";
import { DragItem } from "./AssemblyOrder/CavityDnD";
import styled from '@emotion/styled';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { updateTrayCavity } from '../../../../redux/actions/kitTrays';
import CustomButton from '../../../../components/Buttons/CustomButton';
import { hideReminder, showReminder } from '../../../../redux/actions/snackbar';

const DeleteIcon = styled.div`
    position: absolute;
    right: 10px;
    transition: all ease-in-out 100ms;
    z-index:
    path {
        fill: none;
    }
    &:hover {
        right: 10px;
    }
`;

const Priority = ({ tray, cavities }) => {
    const dispatch = useDispatch();
    const [state, setState] = useState([]);
    const { reminder } = useSelector(state => state.snackbar);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(_.cloneDeep(list));
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        let count = result.length;
        if (!reminder) {
            dispatch(showReminder());
        }
        return result.map((item) => ({ ...item, assemblyOrder: count-- }));
    };

    const onDragEnd = ({ destination, source }) => {
        if (!destination) return;
        const items = reorder(state, source.index, destination.index);
        updatePriorities(items)
        setState(items);
    };

    const updatePriorities = (items) => {
        let counter = items.filter(comp).length;
        for (let item of items.filter(comp)) {
            item.priority = counter--;
        }
    }

    const onItemDelete = (item) => {
        let rules = _.cloneDeep(state);
        for (let rule of rules) {
            if (rule.priority > item.priority) {
                rule.priority--;
            } else if (rule.priority === item.priority) {
                rule.priority = 0;
            }
        }
        setState(_.orderBy(rules, ['priority'], ['desc']));
        if (!reminder) {
            dispatch(showReminder());
        }
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle,
        userSelect: 'none',
        background: isDragging ? '#cdcde0' : '',
        color: isDragging ? 'black' : ''
    });

    useEffect(() => {
        const result = [];
        for (let cavity of cavities) {
            for (let rule of cavity.componentRules) {
                result.push({
                    priority: rule.priority,
                    component: rule.component,
                    cavity,
                    allowed: rule.allowed
                })
            }
        }
        setState(_.orderBy(result, ['priority'], ['desc']));
    }, [cavities]);

    const saveChanges = () => {
        for (let cavity of cavities) {
            let obj = _.cloneDeep(cavity);

            for (let i = 0; i < obj.componentRules.length; i++) {
                obj.componentRules[i].priority = state.find(x => 
                    x.component.id === obj.componentRules[i].component.id &&
                    cavity.id === x.cavity.id
                ).priority;
            }

            const componentRules = {
                allowed: obj.componentRules.filter(x => x.allowed).map(x => ({...x, id: x.component.id })),
                restricted: obj.componentRules.filter(x => !x.allowed).map(x => ({...x, id: x.component.id }))
            };

            const categories = _.uniqBy(obj.categories, ['Name']).map(x => ({...x, id: x.categoryId}));

            dispatch(updateTrayCavity(tray.data.id, {...obj, componentRules, categories }, null, null, true))

        }
        dispatch(hideReminder());
    }

    const comp = x => x.priority > 0 && x.allowed

    return (
        <>
            <FancyScroll offset={220}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <Container {...provided.droppableProps} ref={provided.innerRef}>
                                <Content>
                                    {state.filter(comp).length > 0 ? state.filter(comp).map((element, i) => (
                                        <Draggable key={element.priority} draggableId={element.priority.toString()} index={i} >
                                            {(provided, snapshot) => (
                                                <DragItem
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                                                        justifyContent: "space-between"
                                                    }}
                                                >
                                                    <div>
                                                        <span>{element.priority}. {element.cavity.name} - {element.component.labelName}</span>
                                                    </div>
                                                    <div style={{ position: 'relative', height: '100%', width: "20px" }}>
                                                        <DeleteIcon>
                                                            <FontAwesomeIcon
                                                                icon={faTimes}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    onItemDelete(element);
                                                                }}
                                                            />
                                                        </DeleteIcon>
                                                    </div>
                                                </DragItem>
                                            )}
                                        </Draggable>
                                    )) : <>
                                        <span>
                                            ! There are no priority components
                                            </span>
                                    </>}
                                    {provided.placeholder}
                                </Content>
                            </Container>
                        )}
                    </Droppable>
                </DragDropContext>
            </FancyScroll>
            <Description><div>Drag Up or Down to change Order</div></Description>
            <CustomButton rounded background='#61A431' label='Save priority' onClick={saveChanges} style={{ margin: '0 auto', display: 'flex', fontSize: 12 }} />
        </>
    );
}

export default Priority;