import styled from '@emotion/styled';
import React from 'react';
import FancyScroll from '../../../components/FancyScroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

const Icon = styled.div`
    position: absolute;
    top: 10px;
    left: 20px;
    color: #1d265b;
    opacity: 0.5;
`;

const IconClose = styled.div`
    position: absolute;
    top: 10px;
    right: 20px;
    color: #1d265b;
    opacity: 0.7;
    &:hover {
        opacity: 1;
    }
    cursor: pointer;
`;

const Input = styled.input`
    height: 40px;
    width: 230px;
    margin: 0px 10px 0px 10px !important;
    text-indent: 30px;
    box-shadow: unset !important;
    background: unset !important;
    font-size: 12px;
    color: #1d265b;
    text-align: center;
    border-bottom: 1px solid #1d265b !important;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
    position: relative;
`;

const SearchList = styled.div`
    position: absolute;
    width: 240px;
    top: 42px;
    background: white;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
    border-radius: 10px;
    margin-left: 10px;
    z-index: 1;
`;

const ResultList = styled(SearchList)`
    box-shadow: none;
    position: relative;
    background: transparent;
    border-radius: 0;
    top: 0px;
    z-index: 0;
`;

const Search = styled.div`
    position: relative;
    color: black;
    margin-bottom: 10px;
`;

const ItemName = styled.div`
    font-size: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const ItemLabel = styled.div`
    font-size: 10px;
    padding-left: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const Item = styled.div`
    text-align: left;
    width: 100%;
    vertical-align: middle;
    padding-top: 18px;
    padding-left: 10px;
    height: 70px;
    &:hover {
        background: #f9f9f9;
    }
    cursor: pointer;
    position: relative;
`;


const CustomSearch = ({ onSearch, onSearchChange, clickItem, components, placeholder, offset = 300, color, buttonName, close }) => {

    return (
        <Container style={{ background: "white", borderRadius: 10, paddingTop: 5, marginTop: 10, width: 300 }}>
            <Search>
                <Icon><FontAwesomeIcon icon={faSearch} /></Icon>
                <Input placeholder={placeholder} onKeyPress={(e) => onSearch(e, true)} onChange={onSearchChange} />
                <IconClose onClick={() => close()}><FontAwesomeIcon icon={faTimes} /></IconClose>
            </Search>
            <ResultList>
                <FancyScroll offset={offset} styles={{ color: "gray", backcolor: "white", width: "290px" }}>
                    {components.map(y => <Item key={y.id} onClick={() => { clickItem(y); close() }} style={{ color }}>
                        <ItemName>{y.number}</ItemName>
                        <ItemLabel>{y.description}</ItemLabel>
                    </Item>)}
                </FancyScroll>
            </ResultList>
        </Container>
    );
};

CustomSearch.defaultProps = {
    replacements: [],
    placeholder: 'Component Search'
};

export default CustomSearch;
