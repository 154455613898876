import {
  GET_PRODUCT_FAMILIES_FAIL,
  GET_PRODUCT_FAMILIES_START,
  GET_PRODUCT_FAMILIES_SUCCESS,
} from '../../constants/actionTypes';

const initialState = {
  productFamilies: [],
  productFamiliesLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_FAMILIES_START:
      return {
        ...state,
        productFamiliesLoading: true,
      };

    case GET_PRODUCT_FAMILIES_SUCCESS:
      return {
        ...state,
        productFamilies: action.productFamilies,
        productFamiliesLoading: false,
      };

    case GET_PRODUCT_FAMILIES_FAIL:
      return {
        ...state,

        productFamiliesLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
};
