import React, { useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import styled from "styled-components";
import last from "lodash/last";
import PropTypes from "prop-types";

const getPaddingLeft = (level, type) => {
  let paddingLeft = level * 15;
  if (type === "folder") paddingLeft += 0;
  return paddingLeft;
};

const StyledTreeNode = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;
  padding-left: ${(props) => getPaddingLeft(props.level, props.type)}px;
  font-size: 14px;
  color: #959595;
  font-weight: 300;
`;

const NodeIcon = styled.div`
  font-size: 12px;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : 5)}px;
`;

const getNodeLabel = (node) => last(node.path.split("$$$$"));

const TreeNode = (props) => {
  const {
    node,
    getChildNodes,
    level,
    onToggle,
    onNodeSelect,
    selectedNode,
    id,
  } = props;

  useEffect(() => {
    if (id) {
      const parent = selectedNode.find((sn) =>
        sn.children.find((ch) => ch.id === id)
      );

      if (parent?.id === node.id && !node.isOpen) {
        onToggle(node);
      }
    }
  }, [id, node, onToggle, selectedNode]);

  const findDeepNode = () => {
    const index = selectedNode.findIndex((s) =>
      s.children.find((ch) => node.id === ch.id)
    );
    if (index > -1) {
      return selectedNode[index].children.find(
        (ch) => ch.checked && ch.id === node.id
      );
    }

    return {};
  };

  const selectedNodeCategory = selectedNode
    ? Array.isArray(selectedNode)
      ? selectedNode.find((s) => s.id === node.id) || findDeepNode()
      : selectedNode.id
    : null;

  return (
    <React.Fragment>
      <StyledTreeNode level={level} type={node.type}>
        <NodeIcon onClick={() => onToggle(node)}>
          {node.type === "folder" &&
            (node.isOpen ? <FaChevronUp /> : <FaChevronDown />)}
        </NodeIcon>

        <NodeIcon marginBottom={10}>
          {node.type === "file" && (
            <div className="round">
              <input
                type="checkbox"
                name="catradio"
                id={node.id}
                onChange={() => onNodeSelect(node, "categories")}
                checked={selectedNodeCategory?.checked || id === node.id}
              />
              <label htmlFor={node.id}></label>
            </div>
          )}
          {node.type === "folder" && node.isOpen === true && (
            <div className="round">
              <input
                type="checkbox"
                name="catradio"
                id={node.id}
                onChange={() => onNodeSelect(node, "categories")}
                checked={selectedNodeCategory?.checked || id === node.id}
              />
              <label htmlFor={node.id}></label>
            </div>
          )}
          {node.type === "folder" && !node.isOpen && (
            <div className="round">
              <input
                type="checkbox"
                name="catradio"
                id={node.id}
                onChange={() => onNodeSelect(node, "categories")}
                checked={selectedNodeCategory?.checked || id === node.id}
              />
              <label htmlFor={node.id}></label>
            </div>
          )}
        </NodeIcon>
        <span role="button" onClick={() => onNodeSelect(node, "categories")}>
          {getNodeLabel(node)}
        </span>
      </StyledTreeNode>
      {node.isOpen &&
        getChildNodes(node).map((childNode, index) => (
          <TreeNode
            {...props}
            node={childNode}
            level={level + 1}
            key={node.path + index}
          />
        ))}
    </React.Fragment>
  );
};

TreeNode.propTypes = {
  node: PropTypes.object.isRequired,
  getChildNodes: PropTypes.func.isRequired,
  level: PropTypes.number.isRequired,
  onToggle: PropTypes.func.isRequired,
  onNodeSelect: PropTypes.func.isRequired,
};

TreeNode.defaultProps = {
  level: 0,
};

export default TreeNode;
