import styled from '@emotion/styled';
import React from 'react';
import CustomInput from '../../../components/Inputs/CustomInput';

export const DateRangeContainer = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  font-weight 600;
  span {
    margin-right: 10px;
  }
  input {
    padding-top: 0px;
    height: 30px;
    background: #f9f9f9;
  }

  & > div {
    height: 30px;
    margin-right: 20px;
  }
`;

export default function DateFilters({ dates, setDates }) {
  const handleDateChange = (e) => {
    setDates((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <DateRangeContainer>
      <span>From</span>
      <CustomInput
        type="date"
        onChange={handleDateChange}
        name="start"
        value={dates.start}
        max={dates.end}
      />
      <span>To</span>
      <CustomInput
        type="date"
        onChange={handleDateChange}
        name="end"
        value={dates.end}
        min={dates.start}
      />
    </DateRangeContainer>
  );
}
