import styled from '@emotion/styled';
import { faImage, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import FancyScroll from '../FancyScroll';

const Container = styled.div`
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5px;
`;

const GridItem = styled.div`
    margin: -1px;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    height: fit-content;
    & > svg {
        position: absolute;
        color: transparent;
        top: 0px;
        right: 2px;
    }
    &:hover > svg {color: orangered;}
`;

const Image = styled.div`
    background: white;
    width: 100px;
    height: 100px;
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #8E92AD;
    flex-direction: column;
    font-size: 12px;
    font-weight: 500;
    img {
        max-width: 100%;
        max-height: 100%;
    }
    svg {
        font-size: 60px;
    }
`;

const Label = styled.span`
    font-size: 10px;
    padding: 5px;
    text-align: center;
    font-weight: 500;
`;

const NoItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 200px;
    justify-content: space-evenly;
    font-weight: 500;
`;

const GridList = ({ list = [], labelKey, offset = 180, label, onItemClick, onItemDelete }) => {
    if (!list.length) {
        return <NoItems>
            <span>No Items</span>
        </NoItems>
    }

    return (
        <FancyScroll offset={offset}>
            <Container>
                {list.map((item, index) =>
                    <GridItem key={item.id + '-' + index} onClick={(e) => onItemClick(item, e)}>
                        {onItemDelete && <FontAwesomeIcon icon={faTimes} onClick={(e) => {
                            e.stopPropagation();
                            onItemDelete(item);
                        }} />}
                        <Image data-tip data-for="GridTip">
                            {item.imageUrl ? <img src={item.imageUrl} alt='Grid Component' /> : <>
                                <FontAwesomeIcon icon={faImage} />
                                No image
                            </>}
                        </Image>
                        <Label>{`${label}: ${item[labelKey]}`}</Label>
                    </GridItem>
                )}
            </Container>
        </FancyScroll>
    );
};

export default GridList;
