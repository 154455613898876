import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssignTray } from '../../KitTrays/components/AssignTray';
import styled from '@emotion/styled';
import { loadKitListFromKitDesign } from '../../../../redux/actions/kits';
import { NavLink } from 'react-router-dom';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const RowWithText = styled(Row)`
    display: flex;
    width: 70%;
    align-self: center;
    text-align: center;
    padding: 15px 0px 10px 0px;
`;

const RowTitle = styled.div`
    display: flex;
    font-weight: 600;
    font-size: 15px;
    width: 30%;
    justify-content: center;
    align-items: center;
`;

const KitLink = styled.div`
    display: flex;
    justify-content: center;
    font-size: 12px;
    text-align: left;
    font-weight: 500;
    text-decoration: underline;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    opacity: ${props => props.disabled ? .4 : 1};
    & > a, & > span {
        color: #62A43E;
        cursor: pointer;
        align-self: flex-end;
        margin: 0px 15px;
    }
    svg {
        margin-right: 5px;
    }
`;

const PublishLinkContainer = ({ activeKit, setLinksData, linksData }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadKitListFromKitDesign(true));
    }, [dispatch]);

    const { kitDesignList } = useSelector(state => state.kits);
    const { trays } = useSelector(state => ({ trays: state.kitTrays.trays }));

    const handleChanges = (name, value) => {
        setLinksData({ ...linksData, [name]: value?.id || null });
    }

    return (
        <Wrapper>
            <RowWithText>
                Select a tray to use for digital sample
            </RowWithText>
            <Row>
                <RowTitle>
                    Tray
                </RowTitle>
                <AssignTray
                    isActive={activeKit}
                    value={linksData.trayId !== undefined ? trays.find(x => x.id === linksData.trayId) : trays.find(x => x.id === activeKit.trayId)}
                    trays={trays.filter(tray => tray.isVisible)}
                    assignTray={(value) => handleChanges('trayId', value)}
                    tray={activeKit}
                    isSearchable={true}
                    isClearable={true}
                    placeholder={'Select tray'}
                />
            </Row>
            <RowWithText>
                Select a custom kit to link component placement and assembly pdf rules
            </RowWithText>
            <Row>
                <RowTitle>
                    Rules
                </RowTitle>
                <AssignTray
                    isActive={activeKit}
                    value={linksData.customKitId !== undefined ? kitDesignList.find(x => x.id === linksData.customKitId) : kitDesignList.find(x => x.id === activeKit.customKitId)}
                    trays={kitDesignList}
                    assignTray={(value) => handleChanges('customKitId', value)}
                    tray={activeKit}
                    isClearable={true}
                    isSearchable={true}
                    placeholder={'Select kit'}
                />
            </Row>

            <br />

            <KitLink>
                <NavLink to={`/kitlist/${activeKit.id}?search=${activeKit.number}`}>Edit Kit</NavLink>
            </KitLink>
        </Wrapper>
    );
}

export default PublishLinkContainer;