import React from 'react';
import styled from "@emotion/styled";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

const Back = styled.div`
    align-items: center;
    display: flex;
    cursor: pointer;
`;

const NavContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 15px 10px;
    padding-top: 0px;
    font-weight: 500;
`;

const GoBack = ({ title, goBack, hideBackLink = false, text = 'Back' }) => {
    const history = useHistory();

    return (
        <NavContainer>
            {!hideBackLink && <Back onClick={goBack ? goBack : () => history.goBack()}>
                <FontAwesomeIcon icon={faAngleLeft} />
                <span style={{ marginLeft: 5 }}>{text}</span>
            </Back>}
            <div style={{ ...(hideBackLink && { textAlign: 'right', width: '100%' }) }}>
                {title}
            </div>
        </NavContainer>
    );
};

export default GoBack;
