import React, { Component } from 'react';
import {
  MDBAlert,
  MDBInput,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdbreact';
import agent from '../../agent';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { LOGIN_PAGE_UNLOADED, LOGOUT } from '../../constants/actionTypes';
import Loader from '../../components/loaders/Loader';
import { showSnackbar } from '../../redux/actions/snackbar';
import { sortBy } from 'lodash';

const mapStateToProps = (state) => {
  return { currentUser: state.common.currentUser };
};
const mapDispatchToProps = (dispatch) => ({
  onUnload: () => dispatch({ type: LOGIN_PAGE_UNLOADED }),
  onClickLogout: () => dispatch({ type: LOGOUT }),
  showError: (error) => dispatch(showSnackbar('error', error.message ?? error)),
});
let CustomKitArray = {
  components: [
    {
      id: 0,
      number: null,
      description: 'Please add components',
      labelName: null,
      quantity: null,
      imageUrl: 'Please add components',
    },
  ],
};

let i = 0;
let j = 0;
let tempVendorArray = [1];
let tempNumArray = [1];
let componentRequests = [];
let arrowproducts = [];

class KitMakerSurveyPage extends Component {
  currentNumber = null;
  currentSuiteApt = null;
  currentCity = null;
  currentZipCode = null;
  currentPhone = null;
  currentStreet = null;

  constructor(props) {
    super(props);
    this.state = {
      kitDetailData: null,
      affectSales: false,
      newComponent: false,
      newComponentVendor: null,
      newComponentNumber: null,
      sampleRequested: true,
      graphicsRequested: false,
      specialInstructions: null,
      number: null,
      street: null,
      suiteApt: null,
      city: null,
      zipCode: null,
      phone: null,
      activeItem: 2,
      activeComponent: 2,
      activeSample: 1,
      componentsNumber: null,
      componentsVendorName: null,
      arrowProductsNumber: null,
      arrowProductsUsage: null,
      arrowProducts: false,
      activeArrowProducts: 2,
      vendorArray: [],
      partnumArray: [],
      load_failed: false,
      kitName: null,
      isPDFLoaded: false,
      isSubmitted: false,
    };
    const urlParams = new URLSearchParams(window.location.search);
    const kitId = urlParams.get('KitId');
    const token = window.localStorage.getItem('jwt');
    if (token) {
      agent.KITMAKER.displaynewComponent(kitId).then((result) => {
        if (result === null) {
          this.setState({ kitDetailData: CustomKitArray });
        } else {
          this.setState({ kitDetailData: result });
          this.setState({ kitName: result.name });
        }
      });
    } else {
      this.props.onClickLogout();
    }
    this.kitMakerConfirmationPage = this.kitMakerConfirmationPage.bind(this);
    this.kitMakerComponentsPage = this.kitMakerComponentsPage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTrue = this.handleTrue.bind(this);
    this.handleFalse = this.handleFalse.bind(this);
    this.pdfGenerate = this.pdfGenerate.bind(this);
    this.onClickCSV = this.onClickCSV.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  state = {
    activeItem: '1',
  };

  componentWillMount() {
    this.currentLoggedInUserDetail = JSON.parse(
      window.localStorage.getItem('loggeduser'),
    );
    this.setState({
      number:
        this.currentLoggedInUserDetail.number === null
          ? ''
          : this.currentLoggedInUserDetail.number,
    });
    this.setState({
      street:
        this.currentLoggedInUserDetail.street === null
          ? ''
          : this.currentLoggedInUserDetail.street,
    });
    this.setState({
      suiteApt:
        this.currentLoggedInUserDetail.suiteApt === null
          ? ''
          : this.currentLoggedInUserDetail.suiteApt,
    });
    this.setState({
      city:
        this.currentLoggedInUserDetail.city === null
          ? ''
          : this.currentLoggedInUserDetail.city,
    });
    this.setState({
      zipCode:
        this.currentLoggedInUserDetail.zipCode === null
          ? ''
          : this.currentLoggedInUserDetail.zipCode,
    });
    this.setState({
      phone:
        this.currentLoggedInUserDetail.phone === null
          ? ''
          : this.currentLoggedInUserDetail.phone,
    });
  }

  toggle = (tab) => (e) => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  submitForm(ev) {
    ev.preventDefault();
    if (
      document.getElementById('partnum' + i) === null &&
      document.getElementById('vendor' + i) === null
    ) {
    } else if (
      document.getElementById('partnum' + i).value === '' &&
      document.getElementById('vendor' + i).value === ''
    ) {
    } else {
      tempVendorArray.forEach((element, i) => {
        let objj = {
          number: document.getElementById('partnum' + i).value,
          vendorName: document.getElementById('vendor' + i).value,
        };
        componentRequests.push(objj);
        objj = '';
      });
    }
    if (
      document.getElementById('number' + i) === null &&
      document.getElementById('usage' + i) === null
    ) {
      tempNumArray.forEach((element, i) => {
        let obj = {
          number: document.getElementById('number' + i),
          usage: document.getElementById('usage' + i),
        };
        arrowproducts.push(obj);
        obj = '';
      });
    } else if (
      document.getElementById('number' + i).value === '' &&
      document.getElementById('usage' + i).value === ''
    ) {
    } else {
      tempNumArray.forEach((element, i) => {
        let obj = {
          number: document.getElementById('number' + i).value,
          usage: document.getElementById('usage' + i).value,
        };
        arrowproducts.push(obj);
        obj = '';
      });
    }
    let data = {
      affectsProductSales: this.state.affectSales,
      newComponents: this.state.newComponent,
      arrowproducts: arrowproducts,
      componentRequests: componentRequests,
      sampleRequested: this.state.sampleRequested,
      graphicsRequested: this.state.graphicsRequested,
      specialInstructions: this.state.specialInstructions,
      number: this.state.number,
      street: this.state.street,
      suiteApt: this.state.suiteApt,
      city: this.state.city,
      zipCode: this.state.zipCode,
      phone: this.state.phone,
    };
    const urlParams = new URLSearchParams(window.location.search);
    const kitId = urlParams.get('KitId');
    this.setState({ isSubmitted: true });
    agent.KITMAKER.customkitConfirmation(data, kitId)
      .then((res) => {
        this.setState({ isSubmitted: false });
        window.sessionStorage.setItem('kitCategory', ' ');
        window.sessionStorage.setItem('kitName', '');
        window.sessionStorage.setItem('regionalManager', '');
        window.sessionStorage.setItem('accountNumber', '');
        window.sessionStorage.setItem('contactTitle', '');
        window.sessionStorage.setItem('contactName', '');
        window.sessionStorage.setItem('salesRep', '');
        window.sessionStorage.setItem('accountNumber', '');
        window.sessionStorage.setItem('facilityName', '');
        window.sessionStorage.setItem('facilityStreet', '');
        window.sessionStorage.setItem('facilitySuite', '');
        window.sessionStorage.setItem('facilityCity', '');
        window.sessionStorage.setItem('facilityZipCode', '');
        window.sessionStorage.setItem('isNewBusiness', '');
        window.sessionStorage.setItem('competitor', '');
        window.sessionStorage.setItem('currentPrice', '');
        window.sessionStorage.setItem('requestedPrice', '');
        window.sessionStorage.setItem('estUsage', '');
        window.sessionStorage.setItem('purchaseType', '');
        window.sessionStorage.setItem('jitName', '');
        window.sessionStorage.setItem('productFamilyId', '');
        let path = '/Confirmation';
        let id = this.state.kitName;
        this.props.history.push(path + '/' + id);
      })
      .catch((err) => {
        this.setState({ isSubmitted: false });
        this.props.showError(err);
      });
  }

  kitMakerConfirmationPage = (KitName) => {
    if (
      document.getElementById('partnum' + i).value === '' &&
      document.getElementById('vendor' + i).value === ''
    ) {
    } else {
      tempVendorArray.forEach((element, i) => {
        let objj = {
          number: document.getElementById('partnum' + i).value,
          vendorName: document.getElementById('vendor' + i).value,
        };
        componentRequests.push(objj);
        objj = '';
      });
    }

    if (
      document.getElementById('number' + i).value === '' &&
      document.getElementById('usage' + i).value === ''
    ) {
    } else {
      tempNumArray.forEach((element, i) => {
        let obj = {
          number: document.getElementById('number' + i).value,
          usage: document.getElementById('usage' + i).value,
        };
        arrowproducts.push(obj);
        obj = '';
      });
    }
    let data = {
      affectsProductSales: this.state.affectSales,
      newComponents: this.state.newComponent,
      arrowproducts: arrowproducts,
      componentRequests: componentRequests,
      sampleRequested: this.state.sampleRequested,
      graphicsRequested: this.state.graphicsRequested,
      specialInstructions: this.state.specialInstructions,
      number: this.state.number,
      street: this.state.street,
      suiteApt: this.state.suiteApt,
      city: this.state.city,
      zipCode: this.state.zipCode,
      phone: this.state.phone,
    };
    const urlParams = new URLSearchParams(window.location.search);
    const kitId = urlParams.get('KitId');
    agent.KITMAKER.customkitConfirmation(data, kitId).then((res) => {
      let path = '/Confirmation';
      let id = KitName;
      this.props.history.push(path + '/' + id);
    });
  };
  kitMakerComponentsPage = (kitDetail) => {
    const urlParams = new URLSearchParams(window.location.search);
    const kitId = urlParams.get('KitId');
    let path = '/savedaskcomplete?savedKitId=';
    this.props.history.push(path + kitId);
  };
  handleTrue = (event) => {
    this.setState({ affectSales: event.target.value });
    this.setState({ activeItem: 1 });

    this.setState({ activeArrowProducts: 1 });
    const x = document.getElementById('numberArrowProducts');
    let stil = window.getComputedStyle(x).getPropertyValue('display');
    if (stil === 'none') {
      x.style.display = 'flex';
    }
  };
  handleNewComponentTrue = (event) => {
    this.setState({ newComponent: event.target.value });
    this.setState({ activeComponent: 1 });
    const x = document.getElementById('vendor');
    let stil = window.getComputedStyle(x).getPropertyValue('display');
    if (stil === 'none') {
      x.style.display = 'flex';
    }
  };

  handleNewComponentFalse = (event) => {
    this.setState({ newComponent: event.target.value });
    this.setState({ activeComponent: 2 });
    this.setState({ componentRequestsnumber: '0' });

    const x = document.getElementById('vendor');
    let stil = window.getComputedStyle(x).getPropertyValue('display');
    if (stil === 'flex') {
      x.style.display = 'none';
    }
  };

  handelArrowProductsTrue = (event) => {
    this.setState({ arrowProducts: event.target.value });
    this.setState({ activeArrowProducts: 1 });
  };

  handelArrowProductsFalse = (event) => {
    this.setState({ arrowProducts: event.target.value });
  };

  handleFalse = (event) => {
    this.setState({ affectSales: event.target.value });
    this.setState({ activeItem: 2 });
    this.setState({ activeArrowProducts: 2 });
    this.setState({ arrowProductsUsage: 'null' });
    this.setState({ arrowProductsNumber: '0' });

    const x = document.getElementById('numberArrowProducts');
    let stil = window.getComputedStyle(x).getPropertyValue('display');
    if (stil === 'flex') {
      x.style.display = 'none';
    }
  };

  handleChange = (event) => {
    switch (event.target.name) {
      case 'arrowProductsnumber':
        this.setState({ arrowProductsNumber: event.target.value });
        break;
      case 'arrowProductsusage':
        this.setState({ arrowProductsUsage: event.target.value });
        break;
      case 'componentRequestsvendorName':
        this.setState({ componentsVendorName: event.target.value });
        break;
      case 'componentRequestsnumber':
        this.setState({ componentsNumber: event.target.value });
        break;
      case 'sampleRequested':
        this.setState({ sampleRequested: true });
        this.setState({ activeSample: 1 });
        this.setState({ graphicsRequested: false });
        break;
      case 'graphicsRequested':
        this.setState({ graphicsRequested: true });
        this.setState({ activeSample: 2 });
        this.setState({ sampleRequested: false });
        break;
      case 'specialInstructions':
        this.setState({ specialInstructions: event.target.value });
        break;
      case 'number':
        this.setState({ number: event.target.value });
        break;
      case 'street':
        this.setState({ street: event.target.value });
        break;
      case 'suiteApt':
        this.setState({ suiteApt: event.target.value });
        break;
      case 'city':
        this.setState({ city: event.target.value });
        break;
      case 'zipCode':
        this.setState({ zipCode: event.target.value });
        break;
      case 'phone':
        this.setState({ phone: event.target.value });
        break;
      default:
        break;
    }
  };

  usageNumberValue = () => {
    var numberNode = null;
    var usageNode = null;
    tempNumArray.push(document.getElementById('usage' + i).value);
    if (
      document.getElementById('usage' + i).value !== null &&
      document.getElementById('number' + i).value !== null
    ) {
      i = i + 1;
      let numberId = 'usage' + i;
      let usageId = 'number' + i;
      let numberDiv = document.createElement('div');
      numberDiv.setAttribute('class', 'col-md-5 text-center offset-md-2');
      numberNode = document.createElement('input');
      numberNode.setAttribute('type', 'text');
      numberNode.setAttribute('value', '');
      numberNode.setAttribute('Id', numberId);
      numberNode.setAttribute('class', 'form-control boxinput');
      numberDiv.appendChild(numberNode);
      document.getElementById('numberArrowProducts').appendChild(numberDiv);
      let usageDiv = document.createElement('div');
      usageDiv.setAttribute('class', 'col-md-3 text-center');
      usageNode = document.createElement('input');
      usageNode.setAttribute('type', 'text');
      usageNode.setAttribute('value', '');
      usageNode.setAttribute('Id', usageId);
      usageNode.setAttribute('class', 'form-control boxinput');
      usageDiv.appendChild(usageNode);
      document.getElementById('numberArrowProducts').appendChild(usageDiv);
    }
    if (
      document.getElementById('usage' + i).value !== '' &&
      document.getElementById('number' + i).value !== ''
    ) {
      i = i + 1;
      let numberId = 'usage' + i;
      let usageId = 'number' + i;
      let numberDiv = document.createElement('div');
      numberDiv.setAttribute('class', 'col-md-5 text-center offset-md-2');
      numberNode = document.createElement('input');
      numberNode.setAttribute('type', 'text');
      numberNode.setAttribute('value', '');
      numberNode.setAttribute('Id', numberId);
      numberNode.setAttribute('class', 'form-control boxinput');
      numberDiv.appendChild(numberNode);
      document.getElementById('numberArrowProducts').appendChild(numberDiv);
      let usageDiv = document.createElement('div');
      usageDiv.setAttribute('class', 'col-md-3 text-center');
      usageNode = document.createElement('input');
      usageNode.setAttribute('type', 'text');
      usageNode.setAttribute('value', '');
      usageNode.setAttribute('Id', usageId);
      usageNode.setAttribute('class', 'form-control boxinput');
      usageDiv.appendChild(usageNode);
      document.getElementById('numberArrowProducts').appendChild(usageDiv);
    }
  };
  vendorPartnumValue = () => {
    tempVendorArray.push(document.getElementById('vendor' + j).value);
    if (document.getElementById('vendor' + j).value !== '') {
      j = j + 1;
      let vendorId = 'vendor' + j;
      let partNumId = 'partnum' + j;
      let vendorDiv = document.createElement('div');
      vendorDiv.setAttribute('class', 'col-md-5 text-center offset-md-2');
      var node = document.createElement('input');
      node.setAttribute('type', 'text');
      node.setAttribute('value', '');
      node.setAttribute('Id', vendorId);
      node.setAttribute('class', 'form-control boxinput');
      vendorDiv.appendChild(node);
      document.getElementById('vendor').appendChild(vendorDiv);
      let partnumDiv = document.createElement('div');
      partnumDiv.setAttribute('class', 'col-md-3 text-center');
      var partnumnode = document.createElement('input');
      partnumnode.setAttribute('type', 'text');
      partnumnode.setAttribute('value', '');
      partnumnode.setAttribute('Id', partNumId);
      partnumnode.setAttribute('class', 'form-control boxinput');
      partnumDiv.appendChild(partnumnode);
      document.getElementById('vendor').appendChild(partnumDiv);
    }
  };

  pdfGenerate(data) {
    this.setState({ isPDFLoaded: true });
    agent.KITMAKER.pdfGenerate(data.id)
      .then((res) => {
        if (res !== null) {
          this.setState({
            responseStatusCode: res.statusCode,
            isPDFLoaded: false,
          });
          var newBlob = new Blob([res], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.href = url;
          let name = data.name;
          const download = name + '.pdf';
          link.setAttribute('download', download);
          document.body.appendChild(link);
          link.click();
        } else {
          this.setState({
            showPdfPop: !this.state.showPdfPop,
            responseStatusCode: res.statusCode,
          });
          setTimeout(
            function () {
              this.setState({
                showPdfPop: !this.state.showPdfPop,
                responseStatusCode: res.statusCode,
              });
            }.bind(this),
            5000,
          );
          window.scroll(0, 0);
        }
      })
      .catch((error) => {
        setTimeout(
          function () {
            this.setState({ load_failed: true, isPDFLoaded: false });
          }.bind(this),
          5000,
        );
        window.scroll(0, 0);
      });
  }

  onClickCSV = (kitId, SKU) => {
    agent.KITCATLOG.getCSVData(kitId).then((res) => {
      var newStrrv = res.replace(/,/g, '', '');
      var newStr = newStrrv.replace(/\|/g, ',');
      var blob = new Blob([newStr]);
      if (window.navigator.msSaveOrOpenBlob)
        window.navigator.msSaveBlob(blob, SKU + '-components.csv');
      else {
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' });
        a.download = SKU + '-components.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    });
  };

  render() {
    if (this.state.kitDetailData === null) {
      return <Loader />;
    } else {
      let detaildata = this.state.kitDetailData;
      let SKU = detaildata.name;
      let dataComponents = '';
      var tempDate = new Date();
      var date =
        tempDate.getFullYear() +
        '-' +
        (tempDate.getMonth() + 1) +
        '-' +
        tempDate.getDate();

      const sortedBySkuDetailData = sortBy(detaildata.components, ['number']);

      dataComponents = sortedBySkuDetailData.map((component) => {
        return (
          <tr>
            <td align="center">{component.quantity}</td>
            <td align="center" className="datalngth">
              {component.number}
            </td>
            <td className="alignment">{component.labelName}</td>
          </tr>
        );
      });

      let regionalManager = detaildata.regionalManager;
      let accountNumber = detaildata.accountNumber;
      let currentPrice =
        detaildata.currentPrice === null
          ? 'N/A'
          : '$' + detaildata.currentPrice;
      let contactTitle = detaildata.contactTitle;
      let contactName = detaildata.contactName;
      let salesRep = detaildata.salesRep;
      // let requestedPrice =
      //   detaildata.estPrice === null ? '' : detaildata.estPrice;
      let estUsage = detaildata.estUsage;
      let facilityName = detaildata.facilityName;
      let facilityStreet = detaildata.facilityStreet;
      let facilityCity = detaildata.facilityCity;
      let facilitySuite = detaildata.facilitySuite;
      let facilityZipCode = detaildata.facilityZipCode;
      //   let facility = detaildata.facility;
      let purchaseType = detaildata.isDirectPurchase;
      let JIT = detaildata.jitName;

      // let requestedPriceFixed = requestedPrice.toFixed(2);

      const alertPopUp = (
        <MDBAlert color="success" dismiss>
          <strong>No Pdf found.</strong>
        </MDBAlert>
      );
      return (
        <div className="main-layout">
          <div className="content-area">
            <LoadingOverlay
              active={this.state.isPDFLoaded || this.state.isSubmitted}
              className=""
              spinner
              text="Please wait..."
            >
              <MDBContainer fluid className="kitmaker">
                <div className="text-capitalize mt-5 mt-sm-5 mt-lg-0 mt-md-0 head-section mb20">
                  <h3>
                    {' '}
                    <span className="headfont">KITMAKER</span> {SKU}{' '}
                  </h3>
                </div>
                <MDBRow>
                  <MDBCol md="12">
                    <MDBCard className="mt-0">
                      <MDBCardBody className="p30 kitmakerform">
                        <div className="form-area mb9">
                          <form>
                            {this.state.load_failed ? alertPopUp : null}
                            <MDBRow>
                              <MDBCol md="12" className="text-center">
                                <h4>Please answer the following questions.</h4>
                              </MDBCol>

                              <MDBCol md="12" className="text-center mt-4">
                                {/* <p>Will this new ASK product affect sales of existing Arrow Products?</p>
                                                                    <div className="btn-section mtb2">
                                                                        <MDBBtn className="mb20-xs" value={true} onClick={this.handleTrue} active={this.state.activeItem === 1}>YES</MDBBtn>
                                                                        <MDBBtn className=" mb20-xs" value={false} onClick={this.handleFalse} active={this.state.activeItem === 2}>NO</MDBBtn>
                                                                    </div> */}
                                <MDBRow id="numberArrowProducts">
                                  <div className="text-center offset-md-3">
                                    {' '}
                                    <p>
                                      If yes,please list product number and
                                      explain the usage so forecast and
                                      inventory can be adjusted appropriately.
                                    </p>
                                  </div>
                                  <MDBCol
                                    md="5"
                                    className="text-center offset-md-2"
                                  >
                                    <label
                                      htmlFor="defaultFormLoginEmailEx"
                                      className="grey-text"
                                    >
                                      Number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control boxinput"
                                      name="arrowProductsnumber"
                                      id="number0"
                                      required
                                    />
                                  </MDBCol>
                                  <MDBCol md="3" className="text-center">
                                    <label
                                      htmlFor="defaultFormLoginEmailEx"
                                      className="grey-text"
                                    >
                                      {' '}
                                      Usage
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control boxinput"
                                      name="arrowProductsusage"
                                      id="usage0"
                                      required
                                    />
                                  </MDBCol>
                                  <MDBCol md="1">
                                    <span
                                      className="Kitmakeraddicon"
                                      value="1"
                                      onClick={() => this.usageNumberValue()}
                                    >
                                      +
                                    </span>
                                  </MDBCol>
                                </MDBRow>
                                <p className="mt-4">
                                  Is there a new component the customer is
                                  requestiong as part of this request?
                                </p>
                                <div className="btn-section mtb2">
                                  <MDBBtn
                                    className="mb20-xs "
                                    value={true}
                                    onClick={this.handleNewComponentTrue}
                                    active={this.state.activeComponent === 1}
                                  >
                                    YES
                                  </MDBBtn>
                                  <MDBBtn
                                    className="mb20-xs "
                                    value={false}
                                    onClick={this.handleNewComponentFalse}
                                    active={this.state.activeComponent === 2}
                                  >
                                    NO
                                  </MDBBtn>
                                </div>
                                <MDBRow id="vendor">
                                  <MDBCol
                                    md="5"
                                    className="text-center offset-md-2"
                                  >
                                    <label
                                      htmlFor="defaultFormLoginEmailEx"
                                      className="grey-text"
                                    >
                                      VENDOR NAME
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control boxinput"
                                      name="componentRequestsvendorName"
                                      id="vendor0"
                                    />
                                  </MDBCol>
                                  <MDBCol md="3" className="text-center">
                                    <label
                                      htmlFor="partnum0"
                                      className="grey-text"
                                    >
                                      {' '}
                                      PART #
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control boxinput"
                                      name="componentRequestsnumber"
                                      id="partnum0"
                                    />
                                  </MDBCol>
                                  <MDBCol md="1">
                                    <span
                                      className="Kitmakeraddicon"
                                      value="1"
                                      onClick={() => this.vendorPartnumValue()}
                                    >
                                      +
                                    </span>
                                  </MDBCol>
                                </MDBRow>
                                <p className="mt-2">
                                  Would the customer prefer to see a prototype
                                  sample or graphice to confirm packaging and
                                  component layout?
                                </p>
                                <div className="btn-section mtb2">
                                  <MDBBtn
                                    className="mb20-xs "
                                    name="sampleRequested"
                                    onClick={this.handleChange}
                                    active={this.state.activeSample === 1}
                                  >
                                    Prototype Sample
                                  </MDBBtn>
                                  <MDBBtn
                                    className="mb20-xs"
                                    name="graphicsRequested"
                                    onClick={this.handleChange}
                                    active={this.state.activeSample === 2}
                                  >
                                    Prototype Graphics
                                  </MDBBtn>
                                </div>
                                <MDBRow>
                                  <MDBCol
                                    md="6"
                                    className="text-center offset-md-3"
                                  >
                                    <textarea
                                      placeholder="If you have a requested price or if shipping by method other than Standard Ground, please indicate here."
                                      className="form-control boxinput"
                                      rows="2"
                                      name="specialInstructions"
                                      value={
                                        this.state.kitDetailData
                                          ?.specialInstructions
                                      }
                                      onChange={this.handleChange}
                                    ></textarea>
                                  </MDBCol>
                                </MDBRow>
                              </MDBCol>
                            </MDBRow>
                          </form>
                        </div>
                        <div className="form-area mb-5">
                          <form onSubmit={this.submitForm}>
                            <MDBRow>
                              <MDBCol
                                md="8"
                                className="text-center offset-md-2"
                              >
                                <h4>
                                  Please confirm sample shipping address
                                  (Teleflex's Sales Representative)
                                </h4>
                                <MDBRow>
                                  {/* <MDBCol md="6"> <MDBInput label="Number *" type="text" min="0" title="Enter only numbers" inputMode="numeric" pattern="[0-9]*" name="number" onChange={this.handleChange} valueDefault={this.state.number} required /></MDBCol> */}
                                  <MDBCol md="12">
                                    {' '}
                                    <MDBInput
                                      label="Street *"
                                      type="text"
                                      name="street"
                                      title="Special charcters are not allowed except Coma (,)"
                                      pattern="^[A-Za-z0-9, _]*[A-Za-z0-9,][A-Za-z0-9, _]*$"
                                      onChange={this.handleChange}
                                      valueDefault={this.state.street}
                                    />
                                  </MDBCol>
                                  <MDBCol md="6">
                                    {' '}
                                    <MDBInput
                                      label="Suite/Apt"
                                      type="text"
                                      name="suiteApt"
                                      title="Special charcters are not allowed"
                                      pattern="^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$"
                                      onChange={this.handleChange}
                                      valueDefault={this.state.suiteApt}
                                    />
                                  </MDBCol>
                                  <MDBCol md="6">
                                    {' '}
                                    <MDBInput
                                      label="City, State *"
                                      type="text"
                                      name="city"
                                      title="Format is City, State (Chicago, Illinois)"
                                      pattern="^[A-Z a-z]*,[ A-Za-z][A-Za-z]*$"
                                      onChange={this.handleChange}
                                      valueDefault={this.state.city}
                                      required
                                    />
                                  </MDBCol>
                                  <MDBCol md="6">
                                    {' '}
                                    <MDBInput
                                      label="ZIP CODE *"
                                      type="text"
                                      name="zipCode"
                                      title="Special charcters are not allowed"
                                      pattern="^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _]*$"
                                      onChange={this.handleChange}
                                      valueDefault={this.state.zipCode}
                                      required
                                    />
                                  </MDBCol>
                                  <MDBCol md="6">
                                    <MDBInput
                                      label="Phone (ex. 5551234567) *"
                                      type="text"
                                      name="phone"
                                      min="0"
                                      title="Enter only numbers"
                                      inputMode="numeric"
                                      pattern="[0-9]*"
                                      onChange={this.handleChange}
                                      valueDefault={this.state.phone}
                                      required
                                    />
                                  </MDBCol>
                                  <MDBCol
                                    md="12"
                                    className="text-center kitmakerinfo mt-5 mb-5"
                                  >
                                    <h3>Estimated Rep. Price</h3>
                                    {/*TODO remove comments when calculation logic is complete*/}
                                    {/*<h1>{'$' + requestedPriceFixed}</h1>*/}
                                    <span>To be quoted</span>
                                  </MDBCol>
                                </MDBRow>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="10"
                                className="text-center offset-md-1"
                              >
                                <MDBRow>
                                  <MDBCol md="12" className="text-center mt-4">
                                    <h3>Component Customer Confirmation</h3>
                                    <div className="formbox">
                                      <MDBRow>
                                        <MDBCol md="6">
                                          <label>Date :</label>
                                          <input
                                            type="text"
                                            value={date}
                                            readOnly
                                          />
                                        </MDBCol>

                                        <MDBCol md="6">
                                          <label>Sales Respresentative:</label>
                                          <input
                                            type="text"
                                            value={salesRep}
                                            readOnly
                                          />
                                        </MDBCol>
                                        <MDBCol md="6">
                                          <label>Purchasing Contact :</label>
                                          <input
                                            type="text"
                                            pattern="^[A-Za-z -]+$"
                                            value={contactName}
                                            readOnly
                                          />
                                        </MDBCol>
                                        <MDBCol md="6">
                                          <label>Regional Manager :</label>
                                          <input
                                            type="text"
                                            pattern="^[A-Za-z -]+$"
                                            value={regionalManager}
                                            readOnly
                                          />
                                        </MDBCol>
                                        <MDBCol md="6">
                                          <label>
                                            Purchasing Contact title:
                                          </label>
                                          <input
                                            type="text"
                                            pattern="^[A-Za-z -]+$"
                                            value={contactTitle}
                                            readOnly
                                          />
                                        </MDBCol>
                                        <MDBCol md="6">
                                          <label>Account Number :</label>
                                          <input
                                            type="text"
                                            pattern="^[A-Za-z0-9_]+$"
                                            value={accountNumber}
                                            readOnly
                                          />
                                        </MDBCol>
                                      </MDBRow>
                                      <MDBRow>
                                        {/* <MDBCol md="6" >
                                                                                        <label>Facility Name and Address:</label>
                                                                                        <input value={facilityName+" "+facilityStreet+" "+facilitySuite+" "+facilityCity+" "+facilityZipCode} readOnly />
                                                                                    </MDBCol> */}
                                        <MDBCol md="6">
                                          <label>Facility Name :</label>
                                          <input
                                            type="text"
                                            name="facilityName"
                                            value={facilityName}
                                            readOnly
                                          />
                                        </MDBCol>
                                        <MDBCol md="6">
                                          <label>Street :</label>
                                          <input
                                            type="text"
                                            name="facilityStreet"
                                            value={facilityStreet}
                                            readOnly
                                          />
                                        </MDBCol>
                                        <MDBCol md="6">
                                          <label>Suite/Apt :</label>
                                          <input
                                            type="text"
                                            name="facilitySuite"
                                            value={facilitySuite}
                                            readOnly
                                          />
                                        </MDBCol>
                                        <MDBCol md="6">
                                          <label>City, State :</label>
                                          <input
                                            type="text"
                                            name="facilityCity"
                                            value={facilityCity}
                                            readOnly
                                          />
                                        </MDBCol>
                                        <MDBCol md="6">
                                          <label>ZipCode :</label>
                                          <input
                                            type="text"
                                            name="facilityZipCode"
                                            value={facilityZipCode}
                                            readOnly
                                          />
                                        </MDBCol>
                                      </MDBRow>
                                    </div>
                                    <MDBRow>
                                      <MDBCol md="3">
                                        <div className="formbox mt-4">
                                          <p>Current Price</p>
                                          <h4>{currentPrice}</h4>
                                        </div>
                                      </MDBCol>
                                      <MDBCol md="3">
                                        <div className="formbox mt-4">
                                          <p>Estimated price</p>
                                          {/*TODO remove comments when calculation logic is complete*/}
                                          {/*<h4>{'$' + requestedPriceFixed}</h4>*/}
                                          <span>To be quoted</span>
                                        </div>
                                      </MDBCol>
                                      <MDBCol md="3">
                                        <div className="mt-4 text-left formsec">
                                          <h4 className="mb-2">
                                            {' '}
                                            <span>
                                              {' '}
                                              <strong>
                                                Purchase Type :{' '}
                                              </strong>{' '}
                                              {purchaseType
                                                ? 'Direct '
                                                : 'Distributor'}{' '}
                                            </span>
                                          </h4>
                                          <h4 className="mb-0">
                                            <span>
                                              <strong>
                                                {' '}
                                                Distributor Name :{' '}
                                              </strong>{' '}
                                              {JIT}
                                            </span>
                                          </h4>
                                        </div>
                                      </MDBCol>
                                      <MDBCol md="3">
                                        <div className="formbox mt-4">
                                          <p>
                                            Estimated annual usage in Eaches:
                                          </p>
                                          <h4>{estUsage}</h4>
                                        </div>
                                      </MDBCol>
                                    </MDBRow>
                                  </MDBCol>
                                </MDBRow>
                                <MDBTable
                                  striped
                                  small
                                  className="mt-5 mb-0"
                                  cellSpacing="10"
                                >
                                  <MDBTableHead>
                                    <tr>
                                      <th align="center">
                                        <b>QTY</b>
                                      </th>
                                      <th align="center">
                                        <b>SKU #</b>
                                      </th>
                                      <th className="makeLeft">
                                        <b>Component Name</b>
                                      </th>
                                    </tr>
                                  </MDBTableHead>
                                  <MDBTableBody>{dataComponents}</MDBTableBody>
                                </MDBTable>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol md="12" className="text-center mt-4">
                                <div className="btn-area mb-4">
                                  <MDBBtn
                                    className="btn-outline-grey greylinebtn"
                                    onClick={() => this.pdfGenerate(detaildata)}
                                  >
                                    .PDF
                                  </MDBBtn>
                                </div>
                                <div className="btn-section mtb2">
                                  <MDBBtn
                                    className="mb20-xs btn-outline-red"
                                    onClick={() =>
                                      this.kitMakerComponentsPage(detaildata)
                                    }
                                  >
                                    Cancel
                                  </MDBBtn>
                                  <MDBBtn
                                    className=" mb20-xs gbtn"
                                    type="submit"
                                    value="submit"
                                  >
                                    Continue
                                  </MDBBtn>
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </form>
                        </div>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </LoadingOverlay>
          </div>
        </div>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KitMakerSurveyPage);
