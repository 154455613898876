import React, { Component } from 'react';
import { MDBInput, MDBCol, MDBContainer, MDBRow, MDBBtn } from "mdbreact";
import { connect } from 'react-redux';
import agent from '../../agent';
import Profile from '../../shared/img/profileimage.png';
import {
    LOGIN_PAGE_UNLOADED,
    LOGOUT
} from '../../constants/actionTypes';


const mapDispatchToProps = dispatch => ({
    onUnload: () =>
        dispatch({ type: LOGIN_PAGE_UNLOADED }),
    onClickLogout: () => dispatch({ type: LOGOUT }),
});

const mapStateToProps = state => {
    return { currentUser: state.common.currentUser }
};

const defaultImage = { Profile };
class MyProfile extends Component {
    currentLoggedInUser = null;
    currentUserName = null;
    currentNumber = null;
    currentSuiteApt = null;
    currentCity = null;
    currentZipCode = null;
    currentPhone = null;
    currentStreet = null;
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            Image: null
        };
        this.RedirectToeditProfile = this.RedirectToeditProfile.bind(this);
    }

    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
    componentWillMount() {
        this.currentLoggedInUserDetail = JSON.parse(window.localStorage.getItem('loggeduser'));
        this.currentLoggedInUser = this.currentLoggedInUserDetail.userName === null ? "" : this.currentLoggedInUserDetail.userName;
        this.currentUserName = this.currentLoggedInUserDetail.firstName === null ? "" : this.currentLoggedInUserDetail.firstName + " " + this.currentLoggedInUserDetail.lastName;
        this.currentNumber = this.currentLoggedInUserDetail.number === null ? "" : this.currentLoggedInUserDetail.number;
        this.currentStreet = this.currentLoggedInUserDetail.street === null ? "" : this.currentLoggedInUserDetail.street;
        this.currentSuiteApt = this.currentLoggedInUserDetail.suiteApt === null ? "" : this.currentLoggedInUserDetail.suiteApt;
        this.currentCity = this.currentLoggedInUserDetail.city === null ? "" : this.currentLoggedInUserDetail.city;
        this.currentZipCode = this.currentLoggedInUserDetail.zipCode === null ? "" : this.currentLoggedInUserDetail.zipCode;
        this.currentPhone = this.currentLoggedInUserDetail.phone === null ? "" : this.currentLoggedInUserDetail.phone;
        const token = window.localStorage.getItem('jwt');
        if (token) {
            try {
              agent.Auth.getProfileImage().then(
                (res) => {
                  if (res === null) {
                    this.setState({ Image: defaultImage });
                  } else {
                    this.setState({ Image: URL.createObjectURL(res) });
                  }
                },
                (err) => console.log(err), // onReject
              );
            } catch (e) {
              this.setState({ Image: defaultImage });
            }
        }
        else {
            this.props.onClickLogout()
        }
    }
    addDefaultSrc = (ev) => {
        ev.target.src = defaultImage
    }
    RedirectToeditProfile = () => {
        let path = '/editprofile';
        this.props.history.push(path);
    }
    render() {
        return (
            <div className="main-layout">
                <div className="content-area">
                    <MDBContainer fluid>
                        <h5 className="mt-5 mt-sm-5 mt-lg-0 mt-md-0">My Profile</h5>
                        <MDBRow>
                            <MDBCol md="12">
                                <section id="authentication-layout">
                                    <div className="flex-container">
                                        <div className="img-area">
                                            <img src={this.state.Image ? this.state.Image : Profile} className="profileImage z-depth-1 rounded-circle" alt="profile" onError={(ev) => this.addDefaultSrc(ev)} />
                                            <br />
                                            <br />
                                            <p className="resolution">*Image Resolution should be 225*225</p>
                                            <p className="resolution">Supported image format - .png, .jpeg, .jpg</p>
                                        </div>
                                        <div className="form-area">
                                            <div className="userdetails text-left">
                                                <strong><span className="salesSpan">Sales Representative Default Address</span></strong>
                                                <p> {this.currentLoggedInUser}</p>
                                            </div>
                                            <form>
                                                <MDBRow className="mb-9">
                                                    <MDBCol md="12"> <MDBInput label="Name" value={this.currentUserName} type="text" readOnly disabled /></MDBCol>
                                                    <MDBCol md="12"> <MDBInput label="Street" type="text" value={this.currentStreet} readOnly disabled /></MDBCol>
                                                    <MDBCol md="6"> <MDBInput label="Suite/Apt" type="text" value={this.currentSuiteApt} readOnly disabled /></MDBCol>
                                                    <MDBCol md="6"> <MDBInput label="City, State" type="text" value={this.currentCity} readOnly disabled /></MDBCol>
                                                    <MDBCol md="6"> <MDBInput label="ZIP CODE" type="text" value={this.currentZipCode} readOnly disabled /></MDBCol>
                                                    <MDBCol md="6"><MDBInput label="Phone" type="text" value={this.currentPhone} readOnly disabled /></MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md="12">
                                                        <MDBBtn className="float-center m-0 mt-4 bbtn" onClick={() => this.RedirectToeditProfile()}>EDIT PROFILE</MDBBtn>
                                                    </MDBCol>
                                                </MDBRow>
                                            </form>
                                        </div>
                                    </div>
                                </section>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);