import React, { Component } from 'react';
import {
  MDBTabPane,
  MDBTabContent,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBCardImage,
  MDBModalBody,
  MDBModalFooter,
} from 'mdbreact';
import { connect } from 'react-redux';
import { KITCATALOG_PAGE_UNLOADED, LOGOUT } from '../../constants/actionTypes';
import agent from '../../agent';
import notfound from '../../shared/img/defaultlist.png';
import customkitImage from '../../shared/img/customized-kit-list-small.png';
import Loader from '../../components/loaders/Loader';
import SavedPagination from '../../components/Pagination/SavedPagination';
import PreviouslyPagination from '../../components/Pagination/PrevoiusPagination';
import ButtonGroup from '../../components/Buttons/ButtonGroup';
import Modal from '../../components/Modal/Modal';

const TAB_BUTTONS = [
  {
    label: 'Draft Requests',
    value: 'Draft Requests',
  },
  {
    label: 'Submitted Requests',
    value: 'Submitted Requests',
  },
];

const mapStateToProps = (state) => ({
  ...state.auth,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onUnload: () => dispatch({ type: KITCATALOG_PAGE_UNLOADED }),
  onClickLogout: () => dispatch({ type: LOGOUT }),
});

let KitArray = {
  kits: [{ description: 'No results found' }],
  totalPages: 0,
  totalCount: 0,
};

class SavedASK extends Component {
  constructor(props) {
    super(props);
    this.changeUsername = (ev) => this.props.onChangeUsername(ev.target.value);
    this.changePassword = (ev) => this.props.onChangePassword(ev.target.value);
    this.submitForm = (username, password) => (ev) => {
      ev.preventDefault();
      this.props.onSubmit(username, password);
    };
    this.onUnloadForm = (Categories, ComponentId, PageSize, Page) => (ev) => {
      ev.preventDefault();
      this.props.onUnload(Categories, ComponentId, PageSize, Page);
    };
    this.state = {
      reloadKey: Math.random(),
      kitData: null,
      showComponent: false,
      paginationActive: false,
      isOpen: false,
      currentPage: 1,
      everyPage: 12,
      searchText: null,
      isChecked: false,
      selectedNodeArray: [],
      fromChild: [],
      loading: 'initial',
      upperPageBound: 6,
      lowerPageBound: 0,
      pageBound: 3,
      isPrevBtnActive: 'disabled',
      isNextBtnActive: '',
      justLikeKitData: null,
      likeKitId: null,
      KitId: null,
      currentPagePrevious: 1,
      upperPageBoundPrevious: 6,
      lowerPageBoundPrevious: 0,
      pageBoundPrevious: 3,
      toBeDeleteKitId: null,
      activeItem: '1',
      getCartComponents: null,
      activeTab: TAB_BUTTONS[0].value,
    };
    this.onSearch = this.onSearch.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.kitCatalogDetail = this.kitCatalogDetail.bind(this);
    this.kitCatalog = this.kitCatalog.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.kitCatalogKits = this.kitCatalogKits.bind(this);
    this.useAsBase = this.useAsBase.bind(this);
    this.kitmakerform = this.kitmakerform.bind(this);
    this.getSavedKitComponents = this.getSavedKitComponents.bind(this);
    this.handleClickPrevious = this.handleClickPrevious.bind(this);
  }

  toggleScreen = (tab) => (e) => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
      this.setState({ blue: !this.state.blue });
    }
    agent.CART.getCartCustomKits().then((res) => {
      if (res === null) {
        this.setState({ getCartComponents: KitArray });
      } else {
        this.setState({ getCartComponents: res });
      }
    });
  };

  componentDataWithCustomKit = () => {
    const eachCard = document.querySelectorAll('.onPrd');
    const xx = document.getElementById('mySidenav');

    let stil = window.getComputedStyle(xx).getPropertyValue('display');
    if (stil === 'none') {
    } else {
      eachCard.forEach((element) => {
        element.classList.add('col-xl-4');
        element.classList.remove('col-xl-6');
      });
    }
  };

  customKits() {
    const p = document.querySelectorAll('.onPrd');
    const x = document.getElementById('mySidenav');
    const y = document.getElementsByClassName('ryttray');
    const z = document.getElementById('onTray');

    let stil = window.getComputedStyle(x).getPropertyValue('display');
    if (stil === 'none') {
      x.style.display = 'inline';
      y[0].style.display = 'inline-table';
      y[0].style.right = '0';
      y[0].style.position = 'relative';
      y[0].style.top = '0';
      z.style.flex = '0 0 75%';
      z.style.maxWidth = '75%';
      for (let i = 0; i < p.length; i++) {
        p[i].classList.add('col-xl-4');
      }
    } else {
      x.style.display = 'none';
      y[0].style.right = '0';
      y[0].style.position = 'fixed';
      y[0].style.top = '65px';
      z.style.flex = '0 0 100%';
      z.style.maxWidth = '100%';
      for (let i = 0; i < p.length; i++) {
        p[i].classList.remove('col-xl-4');
      }
    }
  }

  componentWillMount() {
    const token = window.localStorage.getItem('jwt');
    let expiry = new Date(window.localStorage.getItem('tokenExpiry'));
    let current = new Date();
    if (token && expiry.getTime() >= current.getTime()) {
      agent.SAVEDASK.savedASk().then((res) => {
        if (res === null) {
          this.setState({ justLikeKitData: KitArray });
        } else {
          this.setState({ justLikeKitData: res.body });
        }
      });

      agent.SAVEDASK.previouslyOrderedKit().then((res) => {
        if (res.totalCount === 0) {
          this.setState({ kitData: KitArray });
        } else {
          this.setState({ kitData: res });
        }
      });
      agent.CART.getCartCustomKits().then((res) => {
        if (res === null) {
          this.setState({ getCartComponents: KitArray });
        } else {
          this.setState({ getCartComponents: res });
        }
      });
    } else {
      this.props.onClickLogout();
    }
  }

  componentDidMount = () => {
    window.onpopstate = () => {};
  };

  componentWillUnmount() {
    this.props.onUnload();
  }

  state = {
    isOpen: false,
  };

  defaultData = () => {
    agent.SAVEDASK.savedASk().then((res) => {
      if (res === null) {
        this.setState({ justLikeKitData: KitArray });
      } else {
        this.setState({ justLikeKitData: res.body });
        this.componentDataWithCustomKit();
      }
    });

    agent.SAVEDASK.previouslyOrderedKit().then((res) => {
      if (res.totalCount === 0) {
        this.setState({ kitData: KitArray });
      } else {
        this.setState({ kitData: res });
        this.componentDataWithCustomKit();
      }
    });
  };

  onClickGridView = () => {
    this.setState({ showComponent: false });
    this.defaultData();
  };
  onClickListView = () => {
    this.setState({ showComponent: true });
    this.defaultData();
  };
  onSearch = () => {
    let searchText = this.state.searchText;
    agent.KITCATLOG.kitcatalog(
      '',
      searchText,
      '',
      1,
      this.state.everyPage,
      this.state.currentPage - 1,
    ).then((res) => {
      if (res.totalCount === 0) {
        this.setState({ kitData: KitArray });
      } else {
        this.setState({ kitData: res });
      }
    });
  };

  toggleCollapse = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      toBeDeleteKitId: null,
      modal: !this.state.modal,
    });
  };
  onClearFilter = () => {
    this.setState({ reloadKey: Math.random() });
  };
  kitCatalogDetail = (kitId) => {
    let path = `/kit`;
    let data = kitId;
    this.props.history.push(path + '/' + data);
  };
  onSelect = (node, ischecked) => {
    if (ischecked) {
      this.state.selectedNodeArray.push(node.id);
      this.setState({ selectedNodeArray: this.state.selectedNodeArray });
      this.renderComponent();
    } else {
      var index = this.state.selectedNodeArray.indexOf(node.id);
      if (index >= 0) {
        this.state.selectedNodeArray.splice(index, 1);
      }
      this.renderComponent();
    }
  };

  renderComponent() {
    if (this.state.selectedNodeArray.length > 0) {
      agent.KITCATLOG.kitcatalog(
        this.state.selectedNodeArray,
        '',
        '',
        '',
        '9',
        '1',
      ).then((res) => {
        if (res.totalCount === 0) {
          this.setState({ kitData: KitArray });
        } else {
          this.setState({ kitData: res });
          this.componentDataWithCustomKit();
        }
      });
    } else {
      agent.KITCATLOG.kitcatalog('', '', '', '', '9', '1').then((res) => {
        if (res.totalCount === 0) {
          this.setState({ kitData: KitArray });
        } else {
          this.setState({ kitData: res });
          this.componentDataWithCustomKit();
        }
      });
    }
  }

  handleClick = (dataAsk) => {
    this.setState({ reloadKey: Math.random(), justLikeKitData: dataAsk });
  };
  handleClickPrevious = (dataAskPrevious) => {
    this.setState({ reloadKey: Math.random(), kitData: dataAskPrevious });
  };

  kitCatalog() {
    window.location.reload();
  }

  loadComponentData = () => {
    agent.SAVEDASK.savedASk().then((res) => {
      if (res === null) {
        this.setState({ justLikeKitData: KitArray });
      } else {
        this.setState({ justLikeKitData: res.body });
      }
    });

    agent.SAVEDASK.previouslyOrderedKit().then((res) => {
      if (res.totalCount === 0) {
        this.setState({ kitData: KitArray });
      } else {
        this.setState({ kitData: res });
      }
    });
  };

  handleChange = ({ target }) => {
    this.setState({
      searchText: target.value,
      currentPage: Number(1),
    });
  };

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.onSearch();
    }
  }

  kitCatalogDetail = (kitId) => {
    let path = `/kit`;
    let data = kitId;
    this.props.history.push(path + '/' + data);
  };

  kitCatalogKits = () => {
    let path = `/kits`;
    this.props.history.push(path);
  };

  addDefaultSrc(ev) {
    ev.target.src = notfound;
  }

  useAsBase = (kitId) => {
    let path = '/kitmaker';
    let data = kitId;
    this.props.history.push(path + '?orderedKitId=' + data);
    window.location.reload();
  };

  kitmakerpage = (kitId) => {
    let path = '/kitmaker';
    let data = kitId;
    this.props.history.push(path + '/' + data);
  };

  kitmakerform = (data) => {
    let path = '/savedaskcomplete?savedKitId=';
    let name = data.id;
    this.props.history.push(path + name);
    window.location.reload();
  };
  kitmakerformCustomKit = () => {
    let path = '/kitmaker';
    this.props.history.push(path);
  };

  deleteKit = () => {
    agent.KITMAKER.deleteKit(this.state.toBeDeleteKitId).then((res) => {
      this.setState({ modal: !this.state.modal, toBeDeleteKitId: null });
      agent.SAVEDASK.savedASk().then((res) => {
        if (res === null) {
          this.setState({ justLikeKitData: KitArray });
        } else {
          this.setState({ justLikeKitData: res.body });
        }
      });
    });
  };

  toggle = (kitId) => {
    this.setState({
      modal: !this.state.modal,
      toBeDeleteKitId: kitId,
    });
  };

  onJustLike(kitId) {
    let path = `/savedaskjustlike`;
    this.props.history.push(path + '?baseKitId=' + kitId);
  }

  deleteComponents = (event, component) => {
    agent.CART.deleteCustomKits(component.id).then((res) => {
      agent.CART.getCartCustomKits().then((res) => {
        if (res === null) {
          this.setState({ getCartComponents: KitArray });
        } else {
          this.setState({ getCartComponents: res });
          this.setState({ reloadKey: Math.random() });
        }
      });
    });
  };

  getComponentUseAsBase = () => {
    let path = `/kitmaker`;
    this.props.history.push(path + '?isFavourite=1');
  };
  getSavedKitComponents = (kit) => {
    this.props.history.push(
      '/kitmakercomponents?KitFromBase=' +
        null +
        '&kit=' +
        kit.name +
        '&kitId=' +
        kit.id +
        '&orederedKitId=' +
        null +
        '&isFavourite=1',
    );
    window.location.reload();
  };

  getJustLike = () => {
    let path = `/savedaskjustlike?isFavorite=1`;
    this.props.history.push(path);
  };

  render() {
    if (
      this.state.kitData === null ||
      this.state.justLikeKitData === null ||
      this.state.getCartComponents === null
    ) {
      return <Loader />;
    } else {
      const isListviewIn = this.state.showComponent;
      //const pageNumbers = [];
      //const pageNumbersPrevious = [];
      const activeStyle = { color: '#61a431' };
      const inactiveStyle = { color: '#959595' };
      // const activePageStyle = { background: "#1d265b", color: "#ffffff", padding: "0.4rem 0.75rem" };
      // const inactivePageStyle = { background: "#838c8c" };
      var savedCustom = this.state.justLikeKitData;
      var cartComponents = this.state.getCartComponents;
      let savedKitData,
        previouslyorderKit = null;
      var savedKitCustomData,
        getCartComponents = '';

      //for saved Kits
      let pageloop = this.state.currentPage * 12;
      let dat = [];
      //let totalSavedASKPage = (Math.floor((this.state.justLikeKitData.length + this.state.everyPage - 1) / this.state.everyPage));
      for (let j = pageloop - 12; j <= pageloop - 1; j++) {
        if (this.state.justLikeKitData[j] !== undefined) {
          dat.push(this.state.justLikeKitData[j]);
        }
      }
      let pageloopPrevious = this.state.currentPagePrevious * 12;
      let datPrevious = [];
      for (let k = pageloopPrevious - 12; k <= pageloopPrevious - 1; k++) {
        if (this.state.kitData[k] !== undefined) {
          datPrevious.push(this.state.kitData[k]);
        }
      }
      savedKitCustomData = savedCustom.map((kit) => {
        return (
          <div
            id="customkits"
            className="data-list"
            onPointerMove={this.hoverOn}
            onClick={(event) => this.getSavedKitComponents(kit)}
            key={'savedASKKit_' + kit.name}
          >
            <p className="kitName anchorColor" id="customKitName">
              {kit.name}
              <span></span>
            </p>
            <a href="#no" className="kitCount" id="customKitId">
              Components Count: {kit.componentsCount}
            </a>
            <br />
          </div>
        );
      });
      getCartComponents = cartComponents.map((cartComponent) => {
        return (
          <div id="cartCustom" className="data-list">
            <i
              className="fa fa-window-close"
              aria-hidden="true"
              onClick={(event) => this.deleteComponents(event, cartComponent)}
            ></i>
            <a href="#no" className="kitCount" id="customKitId">
              <p>SKU: {cartComponent.number}</p>
            </a>
            <p className="kitName anchorColor" id="customKitName">
              {cartComponent.description}
              <span></span>
            </p>
          </div>
        );
      });

      if (isListviewIn === false) {
        savedKitData = dat.map((item) => {
          return (
            <MDBCol
              xl="3"
              lg="6"
              md="12"
              className="onPrd"
              id={item.id}
              key={'savedASK_' + item.id}
            >
              <MDBCard className="product-area gprdbrdr">
                <h5 onClick={() => this.onJustLike(item.id)}>Just Like!</h5>
                <a href={'/savedaskcomplete?savedKitId=' + item.id}>
                  {' '}
                  <MDBCardImage
                    className="img-fluid"
                    src={customkitImage}
                    alt=""
                  />
                </a>
                <MDBCardBody>
                  <span>{item.componentsCount} Component</span>
                  <MDBCardTitle className="mb-2">
                    <a className="anchorColor" href="#no">
                      {item.name}
                    </a>
                  </MDBCardTitle>

                  <MDBBtn
                    className="lightgbtn posl0"
                    onClick={() => this.kitmakerform(item)}
                  >
                    Complete Kit
                  </MDBBtn>
                  <MDBBtn color="grey" onClick={() => this.toggle(item.id)}>
                    Delete{' '}
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          );
        });

        previouslyorderKit = datPrevious.map((kit) => {
          return (
            <MDBCol xl="3" lg="6" md="12" id={kit.id} className="onPrd">
              <MDBCard className="product-area">
                <h5 onClick={() => this.onJustLike(kit.id)}>Just Like!</h5>
                <a href={'/kitmaker?orderedKitId=' + kit.id}>
                  {' '}
                  <MDBCardImage
                    className="img-fluid"
                    src={customkitImage}
                    alt=""
                  />
                </a>
                <MDBCardBody>
                  <span>{kit.componentsCount} Components</span>
                  <MDBCardTitle className="anchorColor">
                    <a href="#no">{kit.name}</a>
                  </MDBCardTitle>
                  <MDBCardText></MDBCardText>
                  <MDBBtn
                    className="fullwbbtn"
                    onClick={() => this.useAsBase(kit.id)}
                  >
                    <i className="fa fa-shopping-basket" aria-hidden="true"></i>
                    USE AS A BASE
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          );
        });
      } else if (isListviewIn === true) {
        savedKitData = dat.map((kit) => {
          return (
            <MDBCol lg="12">
              <MDBCard className="product-area product-area-list gprdbrdr">
                <MDBRow>
                  <MDBCol lg="3">
                    <h5 onClick={() => this.onJustLike(kit.id)}>Just Like!</h5>
                    <a href={'/kitmaker?kitId=' + kit.id}>
                      {' '}
                      <MDBCardImage
                        className="img-fluid"
                        src={customkitImage}
                        alt=""
                      />
                    </a>
                  </MDBCol>
                  <MDBCol lg="6">
                    <MDBCardBody>
                      <span>{kit.componentsCount} Component</span>
                      <MDBCardTitle>{kit.name}</MDBCardTitle>
                      <MDBCardText></MDBCardText>
                    </MDBCardBody>
                  </MDBCol>
                  <MDBCol lg="3">
                    <div className="list-btn-area floatright">
                      <MDBBtn
                        className="lightgbtn posl0"
                        onClick={() => this.kitmakerform(kit)}
                      >
                        Complete Kit
                      </MDBBtn>
                      <br />
                      <MDBBtn
                        className="nbbtn"
                        onClick={() => this.toggle(kit.id)}
                      >
                        Delete{' '}
                      </MDBBtn>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>
          );
        });

        previouslyorderKit = datPrevious.map((kit) => {
          return (
            <MDBCol lg="12">
              <MDBCard className="product-area product-area-list ">
                <MDBRow>
                  <MDBCol lg="3">
                    <h5 onClick={() => this.onJustLike(kit.id)}>Just Like!</h5>
                    <a href={'/kitmaker/' + kit.id}>
                      {' '}
                      <MDBCardImage
                        className="img-fluid"
                        src={customkitImage}
                        alt=""
                      />
                    </a>
                  </MDBCol>
                  <MDBCol lg="3">
                    <MDBCardBody>
                      <span>{kit.componentsCount} Components</span>
                      <MDBCardTitle>{kit.name}</MDBCardTitle>
                      <MDBCardText></MDBCardText>
                    </MDBCardBody>
                  </MDBCol>
                  <MDBCol lg="6">
                    <div className="list-btn-area">
                      <MDBBtn
                        className="fullwbbtn"
                        onClick={() => this.useAsBase(kit.id)}
                      >
                        <i
                          className="fa fa-shopping-basket"
                          aria-hidden="true"
                        ></i>
                        USE AS A BASE
                      </MDBBtn>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>
          );
        });
      }

      // if (this.state.kitData.length <= this.state.everyPage) {
      // }else{
      // pagerPrevious = <MDBPagination circle>
      //     {renderFirstpagePrevious}
      //     {renderPrevBtnPrevious}
      //     {pageDecrementBtnPrevious}
      //     {renderPageNumbersPrevious}
      //     {pageIncrementBtnPrevious}
      //     {renderNextBtnPrevious}
      //     {renderLastPagePrevious}
      // </MDBPagination>;
      // }
      return (
        <div className="main-layout">
          <div className="content-area mb4">
            <MDBContainer fluid>
              <MDBRow>
                <MDBCol
                  id="onTray"
                  lg="12"
                  md="12"
                  sm="12"
                  xs="12"
                  className="mt20-xs pl15-xs"
                >
                  <h5>ASK Requests</h5>
                  <MDBCol>
                    <MDBRow>
                      <ButtonGroup
                        buttons={TAB_BUTTONS}
                        isActive={this.state.activeTab}
                        onClick={(option) =>
                          this.setState({ activeTab: option.value })
                        }
                        style={{ padding: 5 }}
                      />
                    </MDBRow>
                  </MDBCol>
                  {dat.length === 0 && datPrevious.length === 0 ? (
                    <MDBCol className="text-center">
                      <p>No results found</p>
                    </MDBCol>
                  ) : (
                    <MDBRow>
                      <MDBCol md="12">
                        <h6 className="mt-5 mt-sm-5 mt-lg-0 mt-md-0 pagehead">
                          <p className="mb-0">
                            <i
                              className="fa fa-th-large"
                              aria-hidden="true"
                              onClick={this.onClickGridView}
                              style={
                                this.state.showComponent === false
                                  ? activeStyle
                                  : inactiveStyle
                              }
                              key={Math.random()}
                            ></i>
                            <i
                              className="fa fa-bars"
                              aria-hidden="true"
                              onClick={this.onClickListView}
                              style={
                                this.state.showComponent === true
                                  ? activeStyle
                                  : inactiveStyle
                              }
                              key={Math.random()}
                            ></i>
                          </p>
                        </h6>
                      </MDBCol>
                      {this.state.activeTab === TAB_BUTTONS[0].value ? (
                        <>
                          {savedKitData}
                          <MDBCol>
                            <MDBRow>
                              <MDBCol className="text-center">
                                <SavedPagination data={this.handleClick} />
                              </MDBCol>
                            </MDBRow>
                          </MDBCol>
                        </>
                      ) : (
                        <>
                          {previouslyorderKit}{' '}
                          <MDBCol className="text-center">
                            {/* {pagerPrevious} */}
                            <PreviouslyPagination
                              previousdata={this.handleClickPrevious}
                            />
                          </MDBCol>
                        </>
                      )}
                    </MDBRow>
                  )}
                </MDBCol>
                <MDBCol
                  lg="3"
                  md="6"
                  sm="6"
                  xs="12"
                  className="mt20-xs pl-lg-0 pl-xl-0"
                >
                  <div className="rytsidetray">
                    <i
                      className="fa fa-shopping-basket ryttray"
                      aria-hidden="true"
                      onClick={this.customKits}
                    ></i>
                    <div id="mySidenav" className="sidetray kitmaker pt-2 ">
                      <div id="authentication-layout">
                        <div className="btn-section mtb2 tab-area mt-0">
                          <MDBBtn
                            className="m-0 mb20-xs btn-whitee"
                            active={this.state.activeItem === '1'}
                            onClick={this.toggleScreen('1')}
                          >
                            Custom kits
                          </MDBBtn>
                          <MDBBtn
                            className="m-0  mb20-xs btn-outline-indigo notwhite"
                            active={this.state.activeItem === '2'}
                            onClick={this.toggleScreen('2')}
                          >
                            {' '}
                            Components
                          </MDBBtn>
                        </div>
                        <MDBTabContent activeItem={this.state.activeItem}>
                          <MDBTabPane tabId="1" role="tabpanel">
                            <div className="tbl-scrl">{savedKitCustomData}</div>
                            <br />
                            <MDBBtn
                              className="fullgbtn"
                              disabled={
                                this.state.justLikeKitData.length === 0
                                  ? true
                                  : false
                              }
                              onClick={() => this.kitmakerformCustomKit()}
                            >
                              CREATE NEW KIT
                            </MDBBtn>
                          </MDBTabPane>
                          <MDBTabPane tabId="2" role="tabpanel">
                            <div className="tbl-scrl">
                              {this.state.getCartComponents.length === 0 ? (
                                <p>No starred component available right now</p>
                              ) : (
                                getCartComponents
                              )}
                            </div>
                            <MDBBtn
                              className="fullgbtn"
                              disabled={
                                this.state.getCartComponents.length === 0
                                  ? true
                                  : false
                              }
                              onClick={() => this.getComponentUseAsBase()}
                            >
                              CREATE NEW KIT
                            </MDBBtn>
                            <MDBBtn
                              className="fullgbtn"
                              disabled={
                                this.state.getCartComponents.length === 0
                                  ? true
                                  : false
                              }
                              onClick={() => this.getJustLike()}
                            >
                              JUST LIKE
                            </MDBBtn>
                          </MDBTabPane>
                        </MDBTabContent>
                      </div>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
          {this.state.modal && (
            <Modal close={this.toggle} size="sm">
              <MDBModalBody>
                <strong>
                  <span className="bottom-info warncolor">
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                  </span>{' '}
                  Are you sure you want to delete the customized kit.
                </strong>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="grey" size="sm" onClick={this.toggleCollapse}>
                  Close
                </MDBBtn>
                <MDBBtn className="ybtn" size="sm" onClick={this.deleteKit}>
                  Delete
                </MDBBtn>
              </MDBModalFooter>
            </Modal>
          )}
        </div>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SavedASK);
