import { debounce } from 'lodash-es';
import React, { useMemo, useState } from 'react';
import CustomInput from '../../../components/Inputs/CustomInput';
import { DateRangeContainer } from './DateFilters';

const style = { width: 500 };

export default function SearchFilter({ setSearch, search }) {
  const [searchText, setSearchText] = useState('');
  const searchLog = useMemo(
    () => debounce((value) => setSearch(value), 1000),
    [setSearch],
  );

  return (
    <DateRangeContainer style={style}>
      <span>Search</span>
      <CustomInput
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
          searchLog(e.target.value);
        }}
        style={style}
      />
    </DateRangeContainer>
  );
}
