import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import SearchRounded from '../../../components/Inputs/SearchRounded';
import CustomButton from '../../../components/Buttons/CustomButton';

const Container = styled.div`
    display: flex;
    padding: 15px 40px 15px 40px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const Title = styled.h4`
    font-weight: 600;
`;

const Badge = styled.div`
    min-width: 150px;
    padding: 8px 15px;
    background: #262268;
    margin: 5px 15px;
    color: white;
    font-weight: 600;
    text-align: center;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    & > span:first-of-type {
        font-size: 25px;
        margin-right: 10px;
    }
    & > span:last-of-type {
        font-size: small;
    }
`;

const Block = styled.div`
    display: flex;
    position: relative;
    flex-wrap: wrap;
    button {

    }
`;

const CounterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const TopSection = ({ onChange, onClick, blocks = {}, searchable, showButton, title, buttonLabel = 'Add User', searchPlaceholder = 'User Search...' }) => {
    return (
        <Container>
            <Title>{title}</Title>
            <CounterContainer>
                <Block>
                    {
                        Object.keys(blocks).map(key => <Badge key={key}>
                            <span>{blocks[key]}</span>
                            <span>{key}</span>
                        </Badge>)
                    }
                </Block>
                {searchable && <Block><SearchRounded onChange={onChange} placeholder={searchPlaceholder} /></Block>}
                {showButton && <Block>
                    <CustomButton onClick={onClick} label={buttonLabel} background='#61A431' color='white' rounded />
                </Block>}
            </CounterContainer>
        </Container>
    );
};

TopSection.propTypes = {
    showButton: PropTypes.bool,
    searchable: PropTypes.bool,
    title: PropTypes.string,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    blocks: PropTypes.object,
    buttonLabel: PropTypes.string,
    searchPlaceholder: PropTypes.string
};

export default TopSection;
