import React from 'react';
import styled from '@emotion/styled';
import Modal from '../../components/Modal/Modal';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    text-align: center;
`;

const Title = styled.div`
    font-size: 18px;
    margin: 20px 0px;
    font-weight: 600;
    display: flex;
    align-self: center;
`;

const ActionContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    padding: 20px 20px;
    align-items: center;
`;

const Button = styled.button`
    background: #7c7c7c;
    border-radius: 5px;
    border: none;
    color: white;
    padding: 10px 30px;
    font-size: 12px;
`;

const Confirm = styled(Button)`
    background: #d03030;
`;

const Confirmation = ({ confirm, close, text, cancelLabel, confirmLabel }) => {
    return (
        <Modal close={close} width={500}>
            <Container>
                <Title>{text}</Title>
            </Container>
            <ActionContainer>
                <Button onClick={close}>{cancelLabel}</Button>
                <Confirm onClick={confirm}>{confirmLabel}</Confirm>
            </ActionContainer>
        </Modal>
    );
};

Confirmation.defaultProps = {
    cancelLabel: 'Cancel',
    confirmLabel: 'Confirm'
};

export default Confirmation;
