import React, { useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import CustomInput from "../../../../components/Inputs/CustomInput";
import CustomSelect from "../../../../components/Selects/CustomSelect";
import ImageComponent from "./Image";
import { multiSelectStyles } from "../../../../constants/constants";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideReminder } from "../../../../redux/actions/snackbar";

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;

  & > div {
    margin-bottom: 20px;
  }

  input {
    background: #1d265b;
    color: #ffffffa3;
    text-align: center;
  }

  input:focus + label span,
  input:valid + label span {
    color: white !important;
    font-size: 12px !important;
  }

  label,
  label:after {
    border-bottom: 1px solid #ffffffa3;
  }

  span {
    color: white;
    font-size: 12px;
    font-weight: 500;
  }
`;

const Title = styled.div`
  justify-content: space-between;
  display: flex;
  width: 220px;
  font-weight: 500;
  font-size: 12px;
  padding: 0 10px;
`;

const Overview = ({
  tray,
  onTrayChange,
  mainDevices,
  mainDeviceChange,
  action,
  trays,
  setTray,
  setClonedTray,
  clonedTray,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id && !action) {
      setTray({
        action: 'Edit Tray',
        data: trays.find((t) => t.id === +id),
      });
    } else if (!id) {
      setTray(tray);
    }
    // eslint-disable-next-line
  }, [trays]);

  useEffect(() => {
    return () => {
      dispatch(hideReminder());
    };
  }, [dispatch]);

  const customFilter = useCallback((candidate, input) => {
    if (input) {
      return (
        candidate.data.name.toLowerCase().includes(input) ||
        candidate.data.number.toLowerCase().includes(input)
      );
    }
    return true; // if not search, then all match
  }, []);

  return (
    <div style={{ fontSize: 12 }}>
      <InputGroup>
        <CustomInput
          value={tray.data?.number || ""}
          name="number"
          onChange={onTrayChange}
          label="SKU"
          isRequired
          invalid={tray.data?.number === ""}
          errorText={!tray.data?.number && "SKU field is required."}
        />
        <CustomInput
          value={tray.data?.name || ""}
          name="name"
          onChange={onTrayChange}
          label="Tray Name"
          disabled={clonedTray}
        />
      </InputGroup>
      <Title>
        <span>Main Device</span>
      </Title>
      <CustomSelect
        isSearchable={false}
        customStyles={multiSelectStyles}
        placeholder="Select Main Device"
        options={mainDevices}
        isMulti
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        value={mainDevices.filter((o) =>
          tray.data?.mainDevices?.some((md) => md.id === o.id)
        )}
        onChange={(option) => mainDeviceChange(option)}
        isDisabled={clonedTray}
      />

      <br />

      <CustomSelect
        placeholder="Clone Tray Data from Existing"
        customStyles={multiSelectStyles}
        isSearchable
        options={trays?.filter((tray) => tray.isVisible)}
        getOptionLabel={(o) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{"Name: " + o.name || "--"}</span>
            <span>{"SKU: " + o.number}</span>
          </div>
        )}
        getOptionValue={(o) => o.id}
        onChange={(option) => {
          setClonedTray(option);
          setTray({ ...tray, data: { ...option, number: tray.data.number } });
        }}
        isMulti={false}
        isClearable
        value={clonedTray}
        filterOption={customFilter}
      />

      {action !== "Create Tray" && (
        <ImageComponent data={tray.data} onTrayChange={onTrayChange} />
      )}
    </div>
  );
};

export default Overview;
