import styled from '@emotion/styled';
import { orderBy } from "lodash-es";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Loader from "../../../../components/loaders/Loader";
import { useDispatch, useSelector } from "react-redux";
import { loadCmsComponentTooltipInfo } from "../../../../redux/actions/kitTrays";
import UploadComponentImage from "./UploadComponentImage";
import { showReminder } from "../../../../redux/actions/snackbar";
import Images from "./TooltipTabs/Images";
import ButtonGroup from "../../../../components/Buttons/ButtonGroup";
import LabelNote from "./TooltipTabs/LabelNote";
import Placement from "./TooltipTabs/Placement";
import { fillImageOverrideJson } from "./Cavities";
import { buttons } from "../../../../constants/constants";
import UserNote from '../../../../shared/UserNote';

const Container = styled.div`
  width: 240px;
  height: 350px;
  cursor: default;
  ::-webkit-scrollbar {
    width: 6px;
    background: ${(props) => props.backcolor || '#b6adf9'};
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.backcolor || '#1d265b'};
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.color || '#8E93AD'};
    border-radius: 3px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  & > svg {
    color: orangered;
    position: absolute;
    right: 0px;
    top: 0px;
    font-size: 14px;
    cursor: pointer;
    z-index: 1;
  }
`;

const ContainerContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Title = styled.div`
  color: #1d2659;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

const TooltipContent = ({
  componentId,
  setOptions,
  type,
  options,
  closeTooltip,
  componentPlacement,
  setComponentPlacement,
  uploading,
  setUploading,
  removeImage,
  cavities,
  number,
  cavity,
  allowMultiple,
  updateRules,
}) => {
  const dispatch = useDispatch();
  const { tooltipData, tooltipLoading, reminder } = useSelector((state) => ({
    tooltipData: state.kitTrays.tooltipData,
    tooltipLoading: state.kitTrays.tooltipLoading,
    reminder: state.snackbar.reminder,
  }));

  const [activeImage, setActiveImage] = useState('');
  const [tab, setTab] = useState('images');

  const currentOption = options[type]?.find((o) => o.id === componentId);
  const { x, y, r, pr, pl, isHorizontalFlipped, labelData } = {
    ...componentPlacement[0].values,
    pr: componentPlacement[0].priority,
    pl: componentPlacement[0].placement,
    isHorizontalFlipped: componentPlacement[0].isHorizontalFlipped,
    labelData: componentPlacement[0].labelData,
  };

  const [clickedCatalogButton, setClickedCatalogButton] = useState(false);

  useEffect(() => {
    if (componentId) {
      dispatch(loadCmsComponentTooltipInfo(componentId, type));
    }
    // eslint-disable-next-line
  }, [componentId, dispatch]);

  useEffect(() => {
    if (tooltipData && activeImage === '') {
      const image = imagesFiltered[0];
      setActiveImage(image?.name);
      setImage(image?.name);
    }
    // eslint-disable-next-line
  }, [allowMultiple]);

  useEffect(() => {
    if (tooltipData) {
      const placement =
        currentOption?.placement ||
        tooltipData.images.filter((x) => x.name !== 'catalog')[0]?.name;
      if (placement) {
        setActiveImage(placement);
        setImage(placement);
      }
    } else if (componentId) {
      setComponentPlacement([
        {
          image: null,
          values: { r: 0, x: 0, y: 0 },
          priority: 0,
          cavity: null,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [tooltipData]);

  useEffect(() => {
    if (options[type] && componentPlacement[0].image) {
      // there can be many images with quantity 1
      // if selected image has more then 1 quantity we set first image with quantity 1 as default, always
      const oneOnly = (imagesFiltered || []).find(
        (img) =>
          (activeImage === img.name && img.quantity === 1) ||
          img.quantity === 1,
      );

      const newOptions = options[type].map((o) => {
        if (o.id === componentId) {
          return {
            ...o,
            x,
            y,
            rotation: r,
            // if we have not image with quantity 1 then we set selected as default
            placement: oneOnly?.name || activeImage,
            priority: pr,
            ...(componentPlacement[0].imageOverrideJson && {
              imageOverrideJson: JSON.stringify(
                componentPlacement[0].imageOverrideJson,
              ),
            }),
            isHorizontalFlipped,
            labelData: JSON.stringify(labelData),
          };
        }

        return o;
      });
      setOptions({ ...options, [type]: newOptions });
    }
    // eslint-disable-next-line
  }, [x, y, r, pr, pl, isHorizontalFlipped, labelData]);

  if (tooltipLoading) {
    return (
      <Container>
        <Loader style={{ left: '40%', top: '45%' }} />
      </Container>
    );
  }

  const onImageClick = (placement) => {
    setActiveImage(placement);
    setImage(placement);
  };

  function getImageOverrideJson() {
    return (options[type] || []).find((o) => o.id === componentId)
      ?.imageOverrideJson;
  }

  function getValues(currentOption, fromMulti) {
    if (fromMulti) {
      return {
        x: fromMulti.x,
        y: fromMulti.y,
        r: fromMulti.r,
      };
    }
    return {
      x: currentOption?.x || 0,
      y: currentOption?.y || 0,
      r: currentOption?.rotation || 0,
    };
  }

  function getLabel(imageOverrideJson, image) {
    return (
      imageOverrideJson?.find((img) => img.imageId === image?.id)?.labelData ??
      {}
    );
  }

  const setImage = (placement) => {
    const image = tooltipData?.images.find((img) => img.name === placement);
    const points = cavity?.points ?? [];

    const currentJson = getImageOverrideJson();
    const imageOverrideJson = currentJson
      ? JSON.parse(currentJson)
      : currentJson;
    const label = getLabel(imageOverrideJson, image);

    const fromMulti = imageOverrideJson?.find((p) => p.imageId === image?.id);
    const values = getValues(currentOption, fromMulti);

    setComponentPlacement([
      {
        values,
        priority: currentOption?.priority || 0,
        placement,
        image: image?.url,
        isHorizontalFlipped: fromMulti?.isHorizontalFlipped,
        labelData: {
          ...label,
          text: label.text || number,
          x: label.x || points[0]?.x,
          y: label.y || points[0]?.y,
        },
        componentId,
        imageId: image?.id,
        imageName: image?.name,
        quantity: image?.quantity,
        imageOverrideJson,
      },
    ]);
    if (!reminder) {
      dispatch(showReminder());
    }
  };

  const onInputChange = (e) => {
    const value =
      e.target.name === 'rotation'
        ? e.target.value > 360
          ? 360
          : e.target.value < -360
          ? -360
          : e.target.value
        : e.target.value;
    setComponentPlacement([
      {
        ...componentPlacement[0],
        values: { ...componentPlacement[0].values, [e.target.name]: value },
      },
    ]);
    if (!reminder) {
      dispatch(showReminder());
    }
  };

  const resetValues = () => {
    setComponentPlacement([
      {
        ...componentPlacement[0],
        values: { ...componentPlacement[0].values, x: 0, y: 0, rotation: 0 },
      },
    ]);
    if (!reminder) {
      dispatch(showReminder());
    }
  };

  const getHighestPriority = () => {
    return Math.max(
      ...cavities.map((cavity) =>
        Math.max(...cavity.componentRules.map((x) => x.priority)),
      ),
    );
  };

  const changePriority = () => {
    const current = componentPlacement[0];
    setComponentPlacement([
      {
        ...current,
        priority: current.priority > 0 ? 0 : getHighestPriority() + 1,
      },
    ]);
    if (!reminder) {
      dispatch(showReminder());
    }
  };

  const noCatalog =
    tooltipData?.images.filter((x) => x.name !== 'catalog') || [];
  const imagesFiltered = orderBy(noCatalog, 'order');

  function changeImageFlipProperty() {
    const imageOverrideJson = fillImageOverrideJson(
      componentPlacement,
      componentPlacement[0].values,
      !isHorizontalFlipped,
    );

    setComponentPlacement([
      {
        ...componentPlacement[0],
        imageOverrideJson,
        isHorizontalFlipped: !isHorizontalFlipped,
      },
    ]);
  }

  return (
    <Container style={{ overflowY: 'auto', overflowX: 'hidden' }}>
      <FontAwesomeIcon icon={faTimesCircle} onClick={closeTooltip} />
      <ContainerContent>
        <Container style={{ height: 'auto' }}>
          {uploading ? (
            <UploadComponentImage
              isCatalog={clickedCatalogButton}
              data={tooltipData}
              imagePlacement={uploading}
              cancel={setUploading}
              type={type}
              allowMultiple={allowMultiple}
              changeImageFlipProperty={changeImageFlipProperty}
              isHorizontalFlipped={isHorizontalFlipped}
              updateRules={updateRules}
            />
          ) : (
            <>
              {number && <Title>{`SKU: ${number}`}</Title>}

              <ButtonGroup
                buttons={buttons}
                isActive={tab}
                onClick={(button) => setTab(button.value)}
                style={{ padding: 5 }}
              />

              {tab === 'images' && (
                <Images
                  imagesFiltered={imagesFiltered}
                  activeImage={activeImage}
                  tooltipData={tooltipData}
                  removeImage={removeImage}
                  componentPlacement={componentPlacement}
                  setUploading={setUploading}
                  onImageClick={onImageClick}
                  changePriority={changePriority}
                  uploading={uploading}
                  setClickedCatalogButton={setClickedCatalogButton}
                  type={type}
                  allowMultiple={allowMultiple}
                />
              )}

              {tab === 'label' && (
                <LabelNote
                  setComponentPlacement={setComponentPlacement}
                  componentPlacement={componentPlacement[0]}
                />
              )}

              {tab === 'placement' && (
                <Placement
                  componentPlacement={componentPlacement}
                  onInputChange={onInputChange}
                  resetValues={resetValues}
                />
              )}
            </>
          )}

          <UserNote
            text={
              'You can use only Highlighted image for default single component display'
            }
            starred
            color="green"
          />
        </Container>
      </ContainerContent>
    </Container>
  );
};

export default TooltipContent;
