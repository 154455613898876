import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const CustomSelect = ({
  options,
  onChange,
  applyStyles,
  customStyles,
  components = {},
  placeholder,
  hideIfNoOptions = false,
  ...rest
}) => {
  const styles = {
    container: (provided) => ({ ...provided, minWidth: 150 }),
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      height: 43,
      boxShadow: 'none',
      borderBottom: `2px solid ${state.menuIsOpen ? '#4285f4' : 'black'}`,
      borderRadius: 0,
      outline: 'none',
      backgroundColor: 'none',
    }),
  };

  const isAllOptionsSelected =
    options?.length === rest?.value?.length && rest.isMulti && hideIfNoOptions;

  return (
    <Select
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      styles={applyStyles ? styles : customStyles}
      menuPlacement={'auto'}
      components={{ ...components, IndicatorSeparator: () => null }}
      menuIsOpen={isAllOptionsSelected ? false : undefined}
      {...rest}
    />
  );
};

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  onChange: PropTypes.func.isRequired,
  applyStyles: PropTypes.bool,
  customStyles: PropTypes.object,
  components: PropTypes.object,
  placeholder: PropTypes.string,
};

export default CustomSelect;
