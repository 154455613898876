import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import List from '../KitTrays/List';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 500;

    & > a, & > span {
        color: white;
        display: flex;
        align-items: center;
        padding: 10px 5px; 
        cursor: pointer;
        padding: 20px;
    }

    & > a:hover, & > span:hover {
        background: #242e68;
        color: white;
    }

    hr {
        margin: 5px;
        border-top: 1px solid white;
    }
`;

const PackagingList = ({ list, match, setSelected, selected }) => {
  const history = useHistory();

  const path = "/packaging-options";

  useEffect(() => {
    if (!selected) {
      setSelected(list.find((x) => x.id === +match.params.id)?.id);
    }
  }, [match.params.id, list, setSelected, selected]);

  return (
    <Container>
      <List
        list={_.sortBy(list, ["id", "name"])}
        onItemClick={(item) => {
          history.push(path + "/" + item.id);
          setSelected(item.id);
        }}
        isActive={{ id: selected }}
      />
    </Container>
  );
};

export default PackagingList;
