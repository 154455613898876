//import { Link } from 'react-router-dom';
import React from 'react';
//import agent from '../../../src/agent';
import { connect } from 'react-redux';
import siteLogo from '../../shared/img/logo.png';
import {  MDBBtn  } from "mdbreact";
import 'bootstrap/dist/css/bootstrap.min.css';
import "mdbreact/dist/css/mdb.css";
import * as Sentry from '@sentry/browser';

import {
   LOGIN_FAILED,
  LOGIN_PAGE_UNLOADED
} from '../../constants/actionTypes';

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
    onLoginFailed: () =>
    dispatch({ type: LOGIN_FAILED}),
  onUnload: () =>
    dispatch({ type: LOGIN_PAGE_UNLOADED })
});

class LoginError extends React.Component {
 
  componentWillUnmount() {
    this.props.onUnload();

  }
 
  RedirectToLogin=()=>{
    this.props.onLoginFailed();
   Sentry.captureException("Invalid userName or password");
   
  }
     

  render() {
  
    return (
      <div className="App">
        <section id="authentication-layout">
          <div className="flex-container">
            <div className="img-area">
              <img src={siteLogo} alt="logo" style={{ width: '50%' }} />
            </div>
            <div className="authentication-area">
              <p>
                The User/Password you are trying to use
                <br />
                does not match with our registers.
              </p>
              <p>Please try again</p>
              <br />
              <MDBBtn
                color="white"
                type="button"
                onClick={this.RedirectToLogin}
              >
                Try Again
              </MDBBtn>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginError);
