import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone'
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import Wrapper from '../../../components/Wrapper/Wrapper';
import CustomButton from '../../../components/Buttons/CustomButton';
import { showSnackbar } from '../../../redux/actions/snackbar';
import { uploadComponentsPricing } from '../../../redux/actions/components';

const Container = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    color: #8E92AD;
    border: 3px dashed #8E92AD;
    font-weight: 500;
    padding: 20px;
    width: 100%;
    min-height: calc(100vh - 150px);
    flex-direction: column;
    align-items: center;
    position: relative;
    & > svg {
        font-size: 72px;
        margin-bottom: 10px;
    }
    & > span {font-size: 12px}
    button {font-size: 12px;}
`;

const UploadComponentsPricing = () => {
    const [file, setFile] = useState(null);
    const dispatch = useDispatch();

    const { uploadPricingLoading } = useSelector(state => ({ uploadPricingLoading: state.components.uploadPricingLoading }));

    const onDrop = useCallback(files => {
        if (!files[0]) {
            dispatch(showSnackbar('warning', 'Only CSV format'));
            return;
        }

        setFile(files[0]);
    }, [dispatch]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: '.csv', maxFiles: 1, disabled: uploadPricingLoading });

    const uploadDocument = (e) => {
        e.stopPropagation();
        dispatch(uploadComponentsPricing(file));
    };

    return (
        <div className="main-layout">
            <div className="content-area">
                <Wrapper>
                    <Container {...getRootProps()}>
                        <input {...getInputProps()} />
                        {isDragActive ? <p>Drop the CSV file here ...</p> : <>
                            {file ? <>
                                <FontAwesomeIcon icon={faFileCsv} />
                                <p style={{ fontSize: 12 }}>{file.name}</p>
                                <CustomButton
                                    label='Upload .csv'
                                    onClick={uploadDocument}
                                    background='#61A431'
                                    icon={uploadPricingLoading ? <i className="fas fa-spinner fa-spin" style={{ marginRight: 5 }}></i> : null}
                                    disabled={uploadPricingLoading}
                                />
                            </> : <>
                                    <FontAwesomeIcon icon={faFileCsv} />
                                    <span>Upload CSV file with the file dialog or </span>
                                    <span>by dragging and dropping file onto the dashed region</span>
                                </>}
                        </>}
                    </Container>
                </Wrapper>
            </div>
        </div >
    );
};

export default UploadComponentsPricing;
