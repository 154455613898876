import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { REDIRECT } from '../constants/actionTypes';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Login from './Login/Login';
import Register from './Signup/Register';
// import ComponentCategories from './ComponentCatalog/ComponentCategories';
import LoginError from './Login/LoginError';
import ErrorBoundary from './ErrorBoundry/ErrorBoundry';
import Layout from './../../src/containers/Layout';
import Snackbar from '../components/Snackbars/Snackbar';
import PasswordReset from './PasswordReset/PasswordReset';
import ResetRequest from './PasswordReset/ResetRequest';
import ReminderToast from '../components/Snackbars/ReminderToast';
// import { REDIRECT } from '../constants/actionTypes';
import { MsalProvider } from '@azure/msal-react';
import RestrictedPage from './Login/RestrictedPage';

const App = ({ instance }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { redirectTo, isAuthenticated } = useSelector((state) => ({
    redirectTo: state.common.redirectTo,
    isAuthenticated: state.auth.isAuthenticated,
  }));

  useEffect(() => {
    if (redirectTo) {
      dispatch(history.push(redirectTo));
      // dispatch({ type: REDIRECT });
    }
  }, [redirectTo, dispatch, history]);

  const layout =
    isAuthenticated &&
    history.location.pathname !== '/login' &&
    history.location.pathname !== '/register' ? (
      <Layout />
    ) : null;

  const isRestricted = history.location.pathname === '/restricted';

  return (
    <ErrorBoundary>
      <MsalProvider instance={instance}>
        {layout}
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/reset-request" component={ResetRequest} />
          <Route path="/password-reset" component={PasswordReset} />
          <Route path="/register" component={Register} />
          <Route path="/loginerror" component={LoginError} />
          <Route exact path="/restricted" component={RestrictedPage} />
          {!isAuthenticated && !isRestricted && <Redirect to="/login" />}
          <Redirect exact from="/" to="/components" />
        </Switch>
        <Snackbar />
        <ReminderToast />
      </MsalProvider>
    </ErrorBoundary>
  );
};

export default App;
