import * as actionTypes from '../../constants/actionTypes';

const initialState = {
  list: [],
  loading: false,
  totalPages: null,
  totalCount: null,
  savedKits: [],
  categories: [],
  partTypes: [],
  cartComponents: [],
  componentsLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMPONENTS_START:
      return {
        ...state,
        componentsLoading: true,
      };

    case actionTypes.GET_COMPONENTS_SUCCESS:
      return {
        ...state,
        list: action.data.components,
        totalPages: action.data.totalPages,
        totalCount: action.data.totalCount,
        componentsLoading: false,
      };

    case actionTypes.GET_COMPONENTS_FAIL:
      return {
        ...state,
        componentsLoading: false,
        errors: action.error,
      };

    case actionTypes.GET_SAVED_KITS_SUCCESS:
      return {
        ...state,
        savedKits: action.kits,
      };

    case actionTypes.GET_COMPONENT_CATEGORIES_START:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_COMPONENT_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.data,
        partTypes: action.data
          .filter((category) => category.partTypes.length)
          .map((item) => item.partTypes)
          .flat()
          .map((item) => ({ ...item, isPartType: true })),
      };

    case actionTypes.GET_COMPONENT_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.GET_COMPONENTS_FROM_CART_SUCCESS:
      return {
        ...state,
        cartComponents: action.data,
      };

    default:
      return { ...state };
  }
};
