import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
`;

const ComponentsGrid = React.forwardRef(({ children }, ref) => {
  return <Container ref={ref}>{children}</Container>;
});

export default ComponentsGrid;
