import styled from '@emotion/styled';
import React from 'react';

const Container = styled.div(({ borderRadius, disabled }) => `
    display: flex;
    button:first-of-type {
        border-top-left-radius: ${borderRadius}px;
        border-bottom-left-radius: ${borderRadius}px;
    }
    button:last-of-type {
        border-top-right-radius: ${borderRadius}px;
        border-bottom-right-radius: ${borderRadius}px;
        border-style: solid !important;
    }
    opacity: ${disabled ? .4 : 1};
`);

const Button = styled.button(({ isActive, activeWhite, borderColor, count }) => `
    padding: 5px 15px;
    background: ${isActive ? (!activeWhite ? '#1D2659' : 'rgba(255, 255, 82559, 0.6)') : (activeWhite ? '#1D2659' : 'white')};
    color: ${isActive ? 'white' : (!activeWhite ? '#1D2659' : 'white')};
    border-color: ${borderColor};
    border-style: solid !important;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    border-style: solid none solid solid !important;
`);

const ButtonGroup = ({ buttons = [], onClick, isActive, disabled, showNone = false, borderRadius = 8, activeWhite = false, borderColor = "#1D2659", ...rest }) => {
    const r = Math.random() * 10000;
    return (
        <Container disabled={disabled} borderRadius={borderRadius} count={buttons?.length > 0 ? buttons.length : 1} >
            {buttons.map(button => 
                <Button 
                    borderColor={borderColor} 
                    activeWhite={activeWhite} 
                    onClick={() => onClick(button, button.value !== isActive)} 
                    isActive={button.value === isActive} 
                    key={r + button.value} 
                    disabled={disabled} 
                    {...rest}
                >{button.label}</Button>)
            }
            {showNone && !buttons.find(x => !x.value) && <Button borderColor={borderColor} activeWhite={activeWhite} borderRadius={borderRadius} onClick={() => {
                const button = buttons.find(x => x.value === isActive);
                button && onClick(button, false);
            }} isActive={!buttons.find(x => x.value === isActive)} disabled={disabled} {...rest}>None</Button>}
        </Container>
    );
};

export default ButtonGroup;
