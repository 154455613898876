import React, { Component } from 'react';
import { MDBBtn, MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardBody } from "mdbreact";
import { connect } from 'react-redux';
import {
    LOGIN_PAGE_UNLOADED,
    LOGOUT
} from '../../constants/actionTypes';

const mapStateToProps = state => {
    return { currentUser: state.common.currentUser }
};
const mapDispatchToProps = dispatch => ({
    onUnload: () =>
        dispatch({ type: LOGIN_PAGE_UNLOADED }),
    onClickLogout: () => dispatch({ type: LOGOUT }),
});
class ComponentCatalogConfirmation extends Component {

    constructor(props) {
        super(props);

        const token = window.localStorage.getItem('jwt');
        if (token) {
        }
        else {
            this.props.onClickLogout()
        }
        this.state = {
            selectedComponent: null,
            screenSize: null
        }
        this.showComponents = this.showComponents.bind(this);
        this.kitMakerComponentsPage = this.kitMakerComponentsPage.bind(this);
    }
    componentWillMount() {
        const token = window.localStorage.getItem('jwt');
        if (token) {
            let name = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
            this.setState({ selectedComponent: name });
            this.screenSize();
        }
    }

    screenSize = () => {
        let currentPage = window.innerHeight;
        let tempSize = (currentPage - 129) + "px";
        this.setState({ screenSize: tempSize });
    }


    showComponents = () => {
        let path = "/components"
        this.props.history.push(path);
    }

    kitMakerComponentsPage = () => {
        let path = "/components"
        this.props.history.push(path);
    }

    render() {
        let componentName = this.state.selectedComponent;
        var tempUrl = componentName.replace(/%20/g, " ");

        return (
            <div className="main-layout">
                <div className="confirmation-area">
                    <MDBContainer fluid>
                        <h5 className="mt-5 mt-sm-5 mt-lg-0 mt-md-0 pagehead">Customer component confirmation</h5>
                        <MDBRow>
                            <MDBCol md="12">
                                <MDBCard className="product-area mt-0" style={{ height: this.state.screenSize }}>
                                    <MDBCardBody className="text-center">
                                        <i className="fa fa-check-circle" aria-hidden="true"></i>
                                        <h3>{tempUrl}</h3>
                                        <h3>Order has been submitted</h3>
                                        <MDBBtn className="floatbtn" onClick={() => this.kitMakerComponentsPage()}>ok</MDBBtn>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ComponentCatalogConfirmation);
