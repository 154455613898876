import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faImage } from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone'
import { useHistory } from 'react-router-dom';
import { imageFormats, maxImageSize } from '../../../constants/constants';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../redux/actions/snackbar';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #8E92AD;
    flex-direction: column;
    border: 3px dashed #8E92AD;
    font-weight: 500;
    padding: 20px;
    overflow: hidden;

    & > img {
        flex-shrink: 0;
        object-fit: contain;
        position: relative;
        width: 100%;
        height: 100%;
    }
    cursor: pointer;
`;

const ImageSelection = ({ onFileSelected, selected, options }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const onDrop = useCallback(files => {
        if (!files.length) {
            dispatch(showSnackbar('warning', 'File must be image (max size 6MB)'));
            return;
        }

        onFileSelected({ target: { files } });
    }, [onFileSelected, dispatch]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxSize: maxImageSize, accept: imageFormats });

    const pathname = history.location.pathname;
    const byAction = pathname === '/packaging-options' ? 'select' : 'loadImage';

    const imageUrl = options.find(x => x.id === selected)?.imageUrl;

    if (byAction === 'select') {
        return (
            <Container>
                <FontAwesomeIcon icon={faBox} style={{ fontSize: 72 }} />
                <span style={{ fontSize: 12, marginTop: 5 }}>Please Select a packaging option</span>
                <span style={{ fontSize: 12 }}>from the Left Menu</span>
            </Container>
        );
    } else {
        return (
            <>
                <Container {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? <p>Drop the PNG file here ...</p> :
                        <>
                            {imageUrl ? <img src={imageUrl} alt="Upload" /> : <>
                                <FontAwesomeIcon icon={faImage} style={{ fontSize: 72 }} />
                                <span>Upload an image</span>
                            </>}
                        </>
                    }
                </Container>
            </>
        );
    }
};

export default ImageSelection;
