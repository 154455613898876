import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faAward } from '@fortawesome/free-solid-svg-icons'

const PartTypeElement = styled.div`
    font-size: 12px;
    display: flex;
    font-weight: 500;
    align-items: baseline;
    background: ${props => props.isActive ? '#1D2659' : ''};
    color: ${props => props.isActive ? 'white' : 'black'};
    cursor: pointer;
    padding: 10px 15px;
    align-items: center;

    & > span {
        padding: 0px 25px;
    }

    &:hover {
        background: ${props => !props.isActive ? '#eeeeee' : ''};
    }
`;

const PartType = ({partType, isActive, activePartType}) => {
    return (
        <PartTypeElement isActive={isActive} onClick={() => activePartType(partType)}>
            {partType.isFavorite && <FontAwesomeIcon icon={faAward} />}
            <span>{partType.name}</span>
            {!partType.isVisible && <FontAwesomeIcon icon={faEyeSlash} style={{color: '#CC2222'}} />}
        </PartTypeElement>
    );
};

export default PartType;
