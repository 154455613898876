import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faSpinner,
  faThumbtack,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { saveCurrentEditingPage } from "../../../../redux/actions/kits";
import { useDispatch, useSelector } from "react-redux";

const Icon = styled.div`
  z-index: 10;
  cursor: pointer;
  path {
    fill: none;
  }
`;

const PageTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
  width: 100%;
`;

const ImageButton = styled.span`
  svg:first-of-type {
    opacity: ${(props) => (props.active ? 1 : 0.7)};
  }
  svg:hover {
    cursor: pointer;
    opacity: 1 !important;
  }
`;

const Line = styled.div`
  border-bottom: 2px solid
    ${({ isActivePage, i }) =>
      isActivePage ? "red" : i === 0 ? "white" : "#fffefe85"};
  width: "95%";
`;

export default function PageElement(props) {
  const dispatch = useDispatch();
  const recentUpdatedPage = useSelector(
    (state) => state.kits.recentUpdatedPage
  );

  // drag props
  const { provided, getItemStyle, snapshot, DragItem } = props;

  // component props
  const {
    deleting,
    state,
    obj,
    selectedPage,
    pageBreakImages,
    pages,
    index,
    update,
    setDeleting,
    setSelectedPage,
    setPosition,
  } = props;

  if (obj.type !== 0) {
    return null;
  }

  const handleImageClick = (page, event) => {
    setSelectedPage(page.elem);
    recalculatePosition(event);
  };

  const onItemDelete = (obj) => {
    const list = state.filter((x) => x.elem.id !== obj.elem.id);
    setDeleting(obj.elem.id);
    update(list, obj);

    if (selectedPage?.id === obj.elem.id) {
      setSelectedPage(null);
    }
  };

  const recalculatePosition = (event) => {
    const margin = window.innerHeight / 2 - event.clientY;
    const halfTooltipHight = 280;
    const buttonsOffset = 80; // title & buttons
    setPosition(margin < -halfTooltipHight ? margin + buttonsOffset : margin);
  };

  const pinPage = (e) => {
    e.stopPropagation();
    const page = recentUpdatedPage?.id === obj.elem.id ? null : obj.elem;
    dispatch(saveCurrentEditingPage(page));
  };

  const isActivePage = obj.elem.id === selectedPage?.id;
  const title = `${
    recentUpdatedPage?.id === obj.elem.id ? "Unpin" : "Pin"
  } this page for future editing`;

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
    >
      <PageTitle>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <ImageButton
            active={pageBreakImages?.find((pbi) => pbi.id === obj.elem.image)}
          >
            <FontAwesomeIcon
              data-tip
              data-for="ImageTip"
              data-event="click"
              icon={faCamera}
              onClick={(event) => handleImageClick(obj, event)}
            />

            <FontAwesomeIcon
              title={title}
              icon={faThumbtack}
              onClick={pinPage}
              style={{
                marginLeft: 10,
                transform: "rotate(45deg)",
                opacity: recentUpdatedPage?.id === obj.elem.id ? "1" : "0.7",
              }}
            />
          </ImageButton>
        </span>
        <span
          style={{
            ...(isActivePage && {
              color: "red",
              fontWeight: "bolder",
            }),
          }}
        >
          Page {pages.length - obj.counter}
        </span>
      </PageTitle>
      <DragItem
        onClick={() => {
          const page = selectedPage?.id === obj.elem.id ? null : obj.elem;
          setSelectedPage(page);
        }}
      >
        <Line isActivePage={isActivePage} index={index} />
        {deleting === obj.elem.id && (
          <FontAwesomeIcon icon={faSpinner} spin style={{ marginLeft: 5 }} />
        )}
        {!deleting && (
          <Icon>
            <FontAwesomeIcon
              icon={faTimes}
              onClick={(e) => {
                e.stopPropagation();
                onItemDelete(obj);
              }}
            />
          </Icon>
        )}
      </DragItem>
    </div>
  );
}
