import React, { useState } from 'react';
import styled from '@emotion/styled';
import FancyScroll from '../../../components/FancyScroll';
import Template from './Template';
import SearchSection from '../../Settings/Components/CompatibleReplacements/SearchSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import CustomCheckbox from '../../../components/Inputs/CustomCheckbox';
import CustomButton from '../../../components/Buttons/CustomButton';

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  background: #1d265b;
  margin-top: -11px;
  width: 270px;
  flex: 0 0 auto;
  color: white;
  position: relative;
  height: 100vh;
  z-index: 1;

  & > div {
    position: fixed;
    width: inherit;
    height: 100%;
    background: #1d265b;
    padding: 15px 10px;
    display: flex;
    flex-direction: column;

    & > button {
      font-size: 12px;
      align-self: center;
      margin-top: 15px;
    }
  }
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 500;
  padding: 10px 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    font-size: 12px;
    border: 1px solid;
    padding: 3px 15px;
  }
`;

const Search = styled.div`
  margin: 10px 0px 0px -10px;
  position: relative;

  & > i {
    top: 12px;
    position: absolute;
    right: 20px;
    color: #1d265b;
  }

  & > span {
    padding: 3px 0px;
    font-size: 12px;
    cursor: pointer;
    width: fit-content;
    display: block;
    text-align: right;
    width: 95%;
    text-decoration: underline;
    font-weight: 500;
  }
`;

const ComponentItem = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 10px;

  svg {
    cursor: pointer;
    color: orangered;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;
  }
`;

const Filters = (props) => {
  const [hideTemplate, setHideTemplate] = useState(false);

  const { components, loading, level, filters, selectedComponents } = props;
  const {
    addComponentToList,
    handleComponentCheckbox,
    addStarredComponentsToList,
    setLevel,
    setFilters,
    onSearch,
    setSelectedComponents,
    onSubmit,
    resetFilters,
  } = props;

  return (
    <LeftSection>
      <div>
        <Title>
          Kit Catalog
          <CustomButton
            label="Reset Filters"
            onClick={resetFilters}
            background="#1d265b"
            rounded
          />
        </Title>
        <FancyScroll offset={190}>
          {!hideTemplate && (
            <Template
              level={level}
              setLevel={setLevel}
              filters={filters}
              setFilters={setFilters}
            />
          )}
          <Search>
            <SearchSection
              components={components.filter(
                (f) => !selectedComponents.find((sc) => sc.id === f.id),
              )}
              onSearch={() => {}}
              onSearchChange={onSearch}
              itemColor="black"
              assignComponent={addComponentToList}
              placeholder="Search component to add"
              onFocus={(isFocus) => setHideTemplate(isFocus)}
            />
            {loading && <i className="fas fa-spinner fa-spin" />}
            {!hideTemplate &&
              selectedComponents.map((component) => (
                <ComponentItem title={component.labelName} key={component.id}>
                  <CustomCheckbox
                    label={component.labelName}
                    checked={component.checked}
                    onChange={() => handleComponentCheckbox(component)}
                  />
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() =>
                      setSelectedComponents(
                        selectedComponents.filter(
                          (sc) => sc.id !== component.id,
                        ),
                      )
                    }
                  />
                </ComponentItem>
              ))}
            {!hideTemplate && (
              <span onClick={addStarredComponentsToList}>
                Add starred components
              </span>
            )}
          </Search>
        </FancyScroll>
        <CustomButton
          label="Apply Filters"
          onClick={onSubmit}
          background="#61A431"
          rounded
        />
      </div>
    </LeftSection>
  );
};

export default Filters;
