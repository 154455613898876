import React from 'react';

const CavityIcon = ({ color, size, styles }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" fill={color} width={size} height={size} viewBox="0 0 20 20" style={styles}>
            <defs>
                <clipPath id="clip-Artboard_4">
                    <rect width="20" height="20" />
                </clipPath>
            </defs>
            <g id="Artboard_4" data-name="Artboard – 4" clipPath="url(#clip-Artboard_4)">
                <g id="Group_63564" data-name="Group 63564" transform="translate(16141 9093)">
                    <g id="Group_63463" data-name="Group 63463" transform="translate(-1858 560)">
                        <path id="Icon_metro-insert-template" data-name="Icon metro-insert-template" d="M6.648,0H9.971V1.662H6.648Zm4.986,0h3.324V1.662H11.633Zm8.309,0V6.648H14.957V4.986h3.324V1.662H16.619V0ZM4.986,4.986H8.309V6.648H4.986Zm4.986,0H13.3V6.648H9.971ZM1.662,1.662V4.986H3.324V6.648H0V0H4.986V1.662Z" transform="translate(-14277.395 -9652) rotate(45)" fill="#fff" />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default CavityIcon;
