import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  MDBAlert,
  // MDBBreadcrumb,
  // MDBBreadcrumbItem,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from 'mdbreact';
import { NavLink } from 'react-router-dom';
import agent from '../../agent';
import notfound from '../../shared/img/defaultlist.png';
import { LOGOUT } from '../../constants/actionTypes';
import catheter from '../../shared/img/Catheters_color.jpg';
import needle from '../../shared/img/Needles_color.jpg';
import drapes from '../../shared/img/Drapes_color.jpg';
import sheaths from '../../shared/img/Sheaths_color.jpg';
import springWireGuides from '../../shared/img/SpringWireGuides_color.jpg';
import syringes_color from '../../shared/img/Syringes_color.jpg';
import tubing_color from '../../shared/img/Tubing_color.jpg';
import valves_color from '../../shared/img/Valves_color.jpg';
import Loader from '../../components/loaders/Loader';
import styled from 'styled-components';

const mapStateToProps = (state) => {
  return { currentUser: state.auth.user };
};
const mapDispatchToProps = (dispatch) => ({
  onClickLogout: () => dispatch({ type: LOGOUT }),
});

let ComponentDetailArray = {
  components: [{ description: 'No results found' }],
  totalPages: 0,
  totalCount: 0,
};

const ImageContainer = styled.div`
  img {
    max-height: 300px;
    object-fit: contain;
  }
`;

class ComponentDetail extends Component {
  constructor(props) {
    super(props);

    const token = window.localStorage.getItem('jwt');
    if (token) {
    } else {
      this.props.onClickLogout();
    }

    this.state = {
      componentId: null,
      componentDetailData: null,
      showPdfPop: null,
      load_failed: false,
      currentUser: props.currentUser,
    };
    this.pdfGenerate = this.pdfGenerate.bind(this);
  }

  componentWillMount() {
    let Id = window.location.href.substring(
      window.location.href.lastIndexOf('/') + 1,
    );
    let expiry = new Date(window.localStorage.getItem('tokenExpiry'));
    let current = new Date();
    const token = window.localStorage.getItem('jwt');

    if (token && expiry.getTime() >= current.getTime()) {
      this.setState({ componentId: Id });
      agent.Home.componentdetail(Id).then((res) => {
        if (res === null) {
          this.setState({ componentDetailData: ComponentDetailArray });
        } else {
          this.setState({ componentDetailData: res });
        }
      });
    } else {
      this.props.onClickLogout();
    }
  }

  addDefaultSrc(ev, category) {
    switch (category.name) {
      case 'CATHETER':
      case 'CATHETER BODY':
      case 'CATHETER/DILATOR':
      case 'CATHETER ASSEMBLY':
        ev.target.src = catheter;
        break;
      case 'CATHETER/NEEDLE':
      case 'NEEDLE':
        ev.target.src = needle;
        break;
      case 'DRAPE':
        ev.target.src = drapes;
        break;
      case 'SHEATH':
      case 'SHEATH EXTENSION ASSEMBLY':
      case 'SHEATH EXTENSION':
      case 'SHEATH/DILATOR':
        ev.target.src = sheaths;
        break;
      case 'SPRING WIRE GUIDE':
      case 'SPRING WIRE GUIDE ASSEMBLY':
        ev.target.src = springWireGuides;
        break;
      case 'SYRINGE':
      case 'SYRINGE ASSEMBLY':
      case 'SYRINGE PUMP SYSTEM':
        ev.target.src = syringes_color;
        break;
      case 'TUBING ASSEMBLY':
      case 'CONNECTOR':
        ev.target.src = tubing_color;
        break;
      case 'VALVE':
      case 'CAP':
        ev.target.src = valves_color;
        break;
      default:
        ev.target.src = notfound;
        break;
    }
  }

  onRequestSample = () => {
    let path = '/ComponentRequestSample?ComponentId=';
    this.props.history.push(path + this.state.componentId);
  };

  pdfGenerate(data) {
    agent.Home.getPdfCatalog(data.id)
      .then((res) => {
        if (res !== null) {
          this.setState({ responseStatusCode: res.statusCode });
          var newBlob = new Blob([res], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(newBlob);

          const link = document.createElement('a');
          link.href = url;
          let name = data.number;
          const download = name + '.pdf';
          link.setAttribute('download', download);
          document.body.appendChild(link);
          link.click();
        } else {
          this.setState({
            showPdfPop: !this.state.showPdfPop,
            responseStatusCode: res.statusCode,
          });
          setTimeout(
            function () {
              this.setState({
                showPdfPop: !this.state.showPdfPop,
                responseStatusCode: res.statusCode,
              });
            }.bind(this),
            5000,
          );
          window.scroll(0, 0);
        }
      })
      .catch((error) => {
        setTimeout(
          function () {
            this.setState({ load_failed: true });
          }.bind(this),
          5000,
        );
        window.scroll(0, 0);
      });
  }

  // backToParent = () => {
  //   let path = '/components';
  //   this.props.history.push(path);
  // };

  render() {
    //let desc = "";
    let desc,
      selectedNode,
      // breadcrumb,
      popularNode,
      parentNode = '';
    if (this.state.componentDetailData === null) {
      return <Loader />;
    } else {
      let detaildata = this.state.componentDetailData;
      let SKU,
        label,
        description,
        dataItem,
        image = '';
      const alertPopUp = (
        <MDBAlert color="success" dismiss>
          <strong>No Pdf found.</strong>
        </MDBAlert>
      );
      dataItem = detaildata.dataItems.map((component, index) => {
        return (
          (desc = component.name.substring(component.name.indexOf('_') + 1)),
          (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{desc}</td>
              <td>{component.value}</td>
            </tr>
          )
        );
      });

      SKU = this.state.componentDetailData.number;
      label = this.state.componentDetailData.labelName;
      description = this.state.componentDetailData.description;
      image = this.state.componentDetailData.imageUrl;
      selectedNode = this.state.componentDetailData.category;
      popularNode = window.sessionStorage.getItem('PopularCategory');
      parentNode = window.sessionStorage.getItem('ComponentCategory');

      if (
        popularNode &&
        popularNode !== 'null' &&
        popularNode !== null &&
        popularNode !== '' &&
        popularNode !== ' '
      ) {
        // breadcrumb = JSON.parse(popularNode).name;
      } else if (
        parentNode &&
        parentNode !== null &&
        parentNode !== 'null' &&
        parentNode !== '' &&
        parentNode !== ' '
      ) {
        // let tempBreadcrumb = JSON.parse(parentNode).path;
        // breadcrumb = tempBreadcrumb.replace(/\$+/g, ' ');
      }

      const canEdit =
        this.state.currentUser.role.isAdmin ||
        this.state.currentUser.role.isSuperAdmin;

      return (
        <div className="main-layout">
          <div className="prdlistview-area mb9">
            <MDBContainer fluid>
              <h5 className="pagehead">
                COMPONENT
                {/*<span>|</span>*/}
                {/*<MDBBreadcrumb className="mt40-xs pb-0">*/}
                {/*  <MDBBreadcrumbItem>*/}
                {/*    <i className="fa fa-home" aria-hidden="true"></i>*/}
                {/*  </MDBBreadcrumbItem>*/}
                {/*  <MDBBreadcrumbItem>*/}
                {/*    <a href={'/components'}>Components</a>*/}
                {/*  </MDBBreadcrumbItem>*/}
                {/*  /!* <MDBBreadcrumbItem><a href={"/components?component=" + (encodeURIComponent(JSON.stringify(selectedNode)))}>{selectedNode.name}</a></MDBBreadcrumbItem> *!/*/}
                {/*  {breadcrumb && (*/}
                {/*    <MDBBreadcrumbItem>*/}
                {/*      <span*/}
                {/*        className="breadcrumblink"*/}
                {/*        onClick={() => this.backToParent()}*/}
                {/*      >*/}
                {/*        {breadcrumb}*/}
                {/*      </span>*/}
                {/*    </MDBBreadcrumbItem>*/}
                {/*  )}*/}
                {/*  <MDBBreadcrumbItem active>*/}
                {/*    {this.state.componentDetailData.number}*/}
                {/*  </MDBBreadcrumbItem>*/}
                {/*</MDBBreadcrumb>*/}
              </h5>
              <MDBRow>
                <MDBCol md="12">
                  <MDBCard className="product-area">
                    <MDBCardBody className="pb-0">
                      <MDBRow>
                        <MDBCol md="12" className="text-center bb">
                          <h2>
                            {SKU}
                            <p>SKU/Article#</p>
                          </h2>
                        </MDBCol>
                      </MDBRow>
                      {this.state.load_failed ? alertPopUp : null}
                      <MDBRow>
                        <MDBCol md="3" className="text-center p30">
                          <ImageContainer>
                            {image !== null ? (
                              <img
                                className="img-fluid"
                                src={image}
                                onError={(e) => {
                                  this.addDefaultSrc(e, selectedNode);
                                }}
                                alt=""
                              />
                            ) : (
                              <img
                                className="img-fluid"
                                src={notfound}
                                onLoad={(e) => {
                                  this.addDefaultSrc(e, selectedNode);
                                }}
                                onError={(e) => {
                                  this.addDefaultSrc(e, selectedNode);
                                }}
                                alt=""
                              />
                            )}
                            <p>
                              Representative photo(s):Actual product
                              specifications may vary from that shown in the
                              photo
                            </p>
                            {canEdit && (
                              <NavLink
                                to={`/cms-components/technical-data/${this.state.componentId}`}
                                style={{ fontSize: 14 }}
                              >
                                Edit Component
                              </NavLink>
                            )}
                          </ImageContainer>
                        </MDBCol>
                        <MDBCol md="9" className="text-left p30 divider">
                          <p className="heading">{label}</p>
                          <ul className="component-data">
                            <li>{description}</li>
                          </ul>
                          <div className="shortinfo mt-4 kitmakerform tblcustom">
                            <MDBTable striped small>
                              <MDBTableHead>
                                <tr>
                                  <th>
                                    <b>#</b>
                                  </th>
                                  <th>
                                    <b>Description</b>
                                  </th>
                                  <th>
                                    <b>Highlights</b>
                                  </th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>{dataItem}</MDBTableBody>
                            </MDBTable>
                          </div>
                          <div className="extrainfo">
                            <h6>
                              <MDBBtn
                                rounded
                                className="float-right"
                                onClick={() => this.onRequestSample()}
                              >
                                REQUEST SAMPLE{' '}
                              </MDBBtn>
                            </h6>
                            <MDBRow className="text-left bottom-info">
                              <MDBCol md="1" className="plr10 text-center">
                                <i
                                  className="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i>
                              </MDBCol>
                              <MDBCol md="11" className="plr10 pl-0">
                                <p>
                                  Indications, contraindictions, warnings and
                                  instructions for use available under the
                                  Documents section on this page, where an IFU
                                  is available.
                                </p>
                              </MDBCol>
                            </MDBRow>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </div>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComponentDetail);
