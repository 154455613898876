import React from 'react';
import styled from '@emotion/styled';
import CustomSelect from '../../../../components/Selects/CustomSelect';
import { multiSelectStyles } from '../../../../constants/constants';
import {
  needleTypeOptions,
  spinalNeedleTypeOptions,
  pouchVsAdvancerTubeOptions,
  multiPortVsSingleOpenEndHoleOptions,
} from '../utils';

const Paragraph = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  padding: 3px 0px 3px 10px;

  & > div {
    margin-right: ${(props) => (!props.direction ? 20 : 0)}px;
  }
`;

const SelectBlock = styled.div`
  font-size: 12px;
  width: 100%;
`;

const Combined = ({
  filters,
  setFilters,
  isEpidural,
  isSpinal,
  isPouch,
  isMultiport,
}) => {
  const {
    epiduralNeedleType,
    spinalNeedleType,
    pouchVsAdvancerTube,
    multiPortVsSingleOpenEndHole,
  } = filters;

  return (
    <Paragraph direction="column">
      {isPouch && (
        <SelectBlock>
          <CustomSelect
            customStyles={multiSelectStyles}
            isSearchable={false}
            isClearable={false}
            options={pouchVsAdvancerTubeOptions}
            onChange={(o) => setFilters({ ...filters, pouchVsAdvancerTube: o })}
            value={pouchVsAdvancerTube}
            placeholder="Pouch vs Advancer Tube"
            isMulti
            closeMenuOnSelect={false}
            hideIfNoOptions
          />
          <br />
        </SelectBlock>
      )}

      {isMultiport && (
        <SelectBlock>
          <CustomSelect
            customStyles={multiSelectStyles}
            isSearchable={false}
            isClearable={false}
            options={multiPortVsSingleOpenEndHoleOptions}
            onChange={(o) =>
              setFilters({ ...filters, multiPortVsSingleOpenEndHole: o })
            }
            value={multiPortVsSingleOpenEndHole}
            placeholder="Multi-Port vs Single Open End Hole"
            isMulti
            closeMenuOnSelect={false}
            hideIfNoOptions
          />
          <br />
        </SelectBlock>
      )}

      {isEpidural && (
        <SelectBlock>
          <CustomSelect
            customStyles={multiSelectStyles}
            isSearchable={false}
            isClearable={false}
            options={needleTypeOptions}
            onChange={(o) => setFilters({ ...filters, epiduralNeedleType: o })}
            value={epiduralNeedleType}
            placeholder="Epidural Needle Type"
            isMulti
            closeMenuOnSelect={false}
            hideIfNoOptions
          />
          <br />
        </SelectBlock>
      )}

      {isSpinal && (
        <SelectBlock>
          <CustomSelect
            customStyles={multiSelectStyles}
            isSearchable={false}
            isClearable={false}
            options={spinalNeedleTypeOptions}
            onChange={(o) => setFilters({ ...filters, spinalNeedleType: o })}
            value={spinalNeedleType}
            placeholder="Spinal Needle Type"
            isMulti
            closeMenuOnSelect={false}
            hideIfNoOptions
          />
        </SelectBlock>
      )}
    </Paragraph>
  );
};

export default Combined;
