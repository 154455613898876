import { useRef } from "react";

export const useFirstRender = (condition) => {
  const ref = useRef(true);
  const firstRender = ref.current;
  if (condition) {
    ref.current = false;
  }

  return firstRender;
};
