import styled from '@emotion/styled';
import React from 'react';
import { components } from 'react-select';
import { catheterSizeOptions, lengthOptions, frSizeOptions, lumens, preloadedtechoptions, pressureInjectableOptions, procedureOptions, centralAccessProps, arterialProps, peripheralAccessProps } from '../../../NewKitCatalog/Filters/utils';
import CustomSelect from '../../../../components/Selects/CustomSelect';
import ButtonGroup from '../../../../components/Buttons/ButtonGroup';
import RadioButton from '../../../../components/Inputs/RadioButton';

const Title = styled.div`
    font-weight: 500;
    padding: 10px 0px;
`;

const Selects = styled.div`
    & > div {
        margin: 5px 0px;
    }
    [class*='-control'] {
        height: 100% !important;
    }
    [class*='-multiValue'] {
        background: #ced3ec;
    }
`;

const VascularInterventionalTypes = ({ kit, setKit }) => {
    const handleProcedureSelect = ({ value }, isSet = true) => setKit({ ...kit, ...centralAccessProps, ...peripheralAccessProps, ...arterialProps, [value]: isSet });

    const coated = (kit.cvc || kit.mac || kit.psi || kit.dialysis || kit.picc || kit.midline);

    return (
      <div
        style={{
          pointerEvents: !kit.vascular ? 'none' : 'auto',
          opacity: !kit.vascular ? 0.4 : 1,
          paddingRight: 20,
        }}
      >
        <Title style={{ textAlign: 'center' }}>
          Vascular/Interventional Options
        </Title>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            pointerEvents: coated ? 'auto' : 'none',
            opacity: coated ? 1 : 0.4,
          }}
        >
          <RadioButton
            style={{ color: 'black' }}
            label="Coated"
            name="coat"
            onChange={() => setKit({ ...kit, coated: true, nonCoated: false })}
            checked={kit.coated}
          />
          <RadioButton
            style={{ color: 'black' }}
            label="Non-Coated"
            name="coat"
            onChange={() => setKit({ ...kit, coated: false, nonCoated: true })}
            checked={kit.nonCoated}
          />
        </div>
        <div>
          <Title>Procedure</Title>
          <CustomSelect
            applyStyles
            isSearchable={false}
            isClearable={false}
            options={procedureOptions.concat([
              { value: 'arterial', label: 'Arterial' },
            ])}
            onChange={(o) => handleProcedureSelect(o)}
            value={
              procedureOptions
                .concat([{ value: 'arterial', label: 'Arterial' }])
                .find((o) => kit[o.value]) || null
            }
            placeholder="Central/Peripheral Access"
          />
          <div
            style={{
              pointerEvents: !kit.arterial ? 'none' : 'auto',
              opacity: !kit.arterial ? 0.4 : 1,
            }}
          >
            <Title>Arterial Options</Title>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <RadioButton
                style={{ color: 'black' }}
                label="Seldinger"
                name="arterialPart"
                onChange={() =>
                  setKit({ ...kit, seldinger: true, integrated: false })
                }
                checked={kit.seldinger}
              />
              <RadioButton
                style={{ color: 'black' }}
                label="Integrated"
                name="arterialPart"
                onChange={() =>
                  setKit({ ...kit, seldinger: false, integrated: true })
                }
                checked={kit.integrated}
              />
            </div>
            <Title>SAC Options</Title>
            <Selects>
              <CustomSelect
                applyStyles
                isSearchable={false}
                isClearable={false}
                options={catheterSizeOptions}
                onChange={(o) =>
                  setKit({
                    ...kit,
                    catheterSize: o ? o.map((option) => option.value) : null,
                  })
                }
                value={catheterSizeOptions.filter((o) =>
                  kit.catheterSize?.find((cSize) => cSize === o.value),
                )}
                placeholder="Catheter Sizes"
                isMulti
                closeMenuOnSelect={false}
              />
            </Selects>
          </div>
          <Title>Preloaded Technology</Title>
          <ButtonGroup
            buttons={preloadedtechoptions}
            showNone
            isActive={kit.preLoadedTech}
            onClick={({ value }, isSet) =>
              setKit({ ...kit, preLoadedTech: isSet ? value : undefined })
            }
            disabled={!kit.picc}
          />
          <br />
          <ButtonGroup
            buttons={pressureInjectableOptions}
            isActive={
              kit.pressureInjectable
                ? 'pressureInjectable'
                : kit.nonPressureInjectable
                ? 'nonPressureInjectable'
                : null
            }
            onClick={({ value }, isSet = true) =>
              setKit({
                ...kit,
                pressureInjectable: value === 'pressureInjectable' && isSet,
                nonPressureInjectable:
                  value === 'nonPressureInjectable' && isSet,
              })
            }
            disabled={!kit.cvc}
            showNone
          />
          <Selects>
            <CustomSelect
              applyStyles
              isSearchable={false}
              isClearable={false}
              options={frSizeOptions}
              onChange={(o) =>
                setKit({
                  ...kit,
                  'Reference Size': o ? o.map((option) => option.value) : null,
                })
              }
              value={frSizeOptions.filter((o) =>
                kit['Reference Size']?.find((size) => +size === o.value),
              )}
              placeholder="FR Sizes"
              components={{
                MultiValue: ({ children, ...props }) => (
                  <components.MultiValue {...props}>
                    {'FR Size: ' + children}
                  </components.MultiValue>
                ),
              }}
              isDisabled={!procedureOptions.some((pr) => kit[pr.value])}
              isMulti
              closeMenuOnSelect={false}
            />
            <CustomSelect
              applyStyles
              isSearchable={false}
              isClearable={false}
              options={lengthOptions}
              onChange={(o) =>
                setKit({
                  ...kit,
                  length: o ? o.map((option) => option.value) : null,
                })
              }
              value={lengthOptions.filter((o) =>
                kit.length?.find((length) => +length === o.value),
              )}
              placeholder="Length"
              components={{
                MultiValue: ({ children, ...props }) => (
                  <components.MultiValue {...props}>
                    {'Length: ' + children}
                  </components.MultiValue>
                ),
              }}
              isDisabled={!procedureOptions.some((pr) => kit[pr.value])}
              isMulti
              closeMenuOnSelect={false}
            />
            <CustomSelect
              applyStyles
              isSearchable={false}
              isClearable={false}
              options={lumens}
              onChange={(o) =>
                setKit({
                  ...kit,
                  lumens: o ? o.map((option) => option.value) : null,
                })
              }
              value={lumens.filter((o) =>
                kit.lumens?.find((lumen) => +lumen === o.value),
              )}
              placeholder="Lumens"
              components={{
                MultiValue: ({ children, ...props }) => (
                  <components.MultiValue {...props}>
                    {'Lumens: ' + children}
                  </components.MultiValue>
                ),
              }}
              isDisabled={
                !procedureOptions
                  .filter(
                    (pr) =>
                      pr.value !== 'psi' &&
                      pr.value !== 'mac' &&
                      pr.value !== 'endurance',
                  )
                  .some((pr) => kit[pr.value])
              }
              isMulti
              closeMenuOnSelect={false}
            />
          </Selects>
        </div>
      </div>
    );
};

export default VascularInterventionalTypes;
