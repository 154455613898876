import React, { useEffect, useState, useRef, version } from 'react';
import styled from '@emotion/styled';
import { isEqual } from 'lodash-es';
import ReactTooltip from 'react-tooltip';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ImageTooltipContent from '../ImageTooltipContent';
import PageElement from './PageElement';
import { useCallback } from 'react';
import ComponentElement from './ComponenElement';

const Container = styled.div`
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  .__react_component_tooltip.place-right::after {
    margin-top: ${(props) => -props.tooltipOffset - 4}px !important;
  }
  .__react_component_tooltip.place-right {
    margin-top: ${(props) => props.tooltipOffset}px !important;
  }
  .__react_component_tooltip {
    padding: 0px;
  }
  .__react_component_tooltip.show {
    margin-left: 10px !important;
  }
`;

const Content = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const DragItem = styled.div`
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  position: relative;
  margin-bottom: 10px;

  opacity: ${(props) => (props.inActive ? 0.5 : 1)};

  &:hover {
    background: #242e68;
    color: white;
    & > div > svg > path {
      fill: orangered;
    }
  }

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    width: 200px;

    & > span:first-of-type {
      font-weight: 500;
    }

    & > span:last-of-type {
      margin-left: 20px;
      font-size: 12px;
      opacity: 0.8;
    }
  }

  & > div:last-of-type {
    font-size: 14px;

    svg:first-of-type {
      cursor: pointer;
      opacity: 0.5;
    }

    svg:hover {
      opacity: 1;
    }
  }
`;

const LayersList = ({
  kit,
  tray,
  disabled,
  updateData,
  objects,
  pages,
  pageBreakImagesLoading,
  addPageBreakImage,
  deletePageBreakImage,
  pageBreakImages,
  selectedPage,
  setSelectedPage,
  componentAssignments,
  generateNotes,
  setNotesObj,
  notesObj,
}) => {
  const [mouseOver, setMouseOver] = useState({});
  const [state, setState] = useState([]);
  const [position, setPosition] = useState(null);
  const [isDragged, setIsDragged] = useState(false);
  const [deleting, setDeleting] = useState(null);
  const tooltipRef = useRef(null);

  const pagesToState = () => {
    let index = 0;

    return pages.reduce((acc, curr) => {
      acc.push({
        type: 0,
        ind: index,
        counter: index,
        elem: { ...curr, id: index },
      });

      index++;

      const components = objects.filter((o) => o.pageId === curr.id);
      components.forEach((component) => {
        acc.push({ type: 1, ind: index, elem: component });
      });

      return acc;
    }, []);
  };

  useEffect(() => {
    const res = pagesToState();
    if (state.length !== res.length || (!isEqual(state, res) && !isDragged)) {
      setState(res);
      setDeleting(null);
    }
    // eslint-disable-next-line
  }, [pages, objects]);

  useEffect(() => {
    (async function () {
      const isChanged = notesObj.some((x) => {
        const { pageId } =
          objects.find(
            (o) => o.cavityId === x.cavityId && o.componentId === x.componentId,
          ) || {};
        return Number.isInteger(pageId) && pageId !== x.pageId;
      });

      if (notesObj.length && isChanged) {
        const notes = await generateNotes(true);
        setNotesObj(notes);
      }
    })();
    // eslint-disable-next-line
  }, [objects]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [
    objects,
    pages,
    mouseOver,
    position,
    pageBreakImagesLoading,
    pageBreakImages,
    selectedPage,
  ]);

  const update = useCallback(
    (order, obj) => {
      const splitedObjects = order
        .filter((x) => x.type)
        .map((x) => x.elem)
        .map((object) => {
          if (object.pageId >= obj?.elem?.id) {
            const nextPageId = object.pageId - 1;
            const pageId = nextPageId < 0 ? 0 : nextPageId;
            return { ...object, pageId };
          }

          return object;
        });
      const splitedPages = order
        .filter((x) => !x.type)
        .map((x) => x.elem)
        .map((p) => (p.id >= obj?.elem?.id ? { ...p, id: p.id - 1 } : p));

      updateData(splitedObjects, splitedPages);
    },
    [updateData],
  );

  const getItemStyle = (isDragging, draggableStyle, elem = undefined) => {
    const result = {
      ...draggableStyle,
      userSelect: 'none',
      background: isDragging ? '#cdcde0' : '',
      color: isDragging ? 'black' : '',
    };
    if (elem?.name?.includes('<empty>')) {
      result.display = 'none';
    }
    return result;
  };

  const onDragEnd = useCallback(
    ({ destination, source }) => {
      if (!destination || destination.index === 0) return;
      const result = Array.from(state);

      const [removed] = result.splice(source.index, 1);
      result.splice(destination.index, 0, removed);

      let index = 0;
      const mapped = result.map((x, i) => {
        if (Number.isInteger(x.counter)) {
          const page = { ...x, counter: index, elem: { ...x.elem, id: index } };
          index++;
          return page;
        }

        return { ...x, ind: i, elem: { ...x.elem, id: i, pageId: index - 1 } };
      });

      setIsDragged(true);
      setState(mapped);
      update(mapped);
    },
    [state, update],
  );

  const onImageSelect = (image) => {
    const index = state.findIndex(
      (x) => x.elem.id === selectedPage?.id && x.type === 0,
    );

    setSelectedPage((prev) => ({
      ...prev,
      image: prev.image === image.id ? null : image.id,
    }));

    setState((prev) =>
      prev.map((p, i) =>
        !!(i === index && p.elem.image === image.id)
          ? { ...p, elem: { ...p.elem, image: null } }
          : p,
      ),
    );

    const newPages = pages.map((p) =>
      p.id === selectedPage.id
        ? { ...p, image: p.image === image.id ? null : image.id }
        : p,
    );

    updateData(objects, newPages, version);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Container
              {...provided.droppableProps}
              tooltipOffset={position || 50}
              ref={provided.innerRef}
            >
              <Content>
                {state.map((obj, i) => (
                  <Draggable
                    key={i}
                    draggableId={i.toString()}
                    index={i}
                    isDragDisabled={i === 0 || disabled}
                  >
                    {(provided, snapshot) => {
                      const innerProps = {
                        provided,
                        getItemStyle,
                        snapshot,
                        DragItem,
                        obj,
                        selectedPage,
                        setSelectedPage,
                      };
                      return (
                        <>
                          <PageElement
                            {...innerProps}
                            deleting={deleting}
                            state={state}
                            pageBreakImages={pageBreakImages}
                            pages={pages}
                            index={i}
                            update={update}
                            setDeleting={setDeleting}
                            setPosition={setPosition}
                          />
                          <ComponentElement
                            {...innerProps}
                            setMouseOver={setMouseOver}
                            componentAssignments={componentAssignments}
                            kit={kit}
                            objects={objects}
                            tray={tray}
                            notesObj={notesObj}
                          />
                        </>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Content>

              <ReactTooltip
                ref={tooltipRef}
                id="ImageTip"
                isCapture
                clickable
                place="right"
                effect="solid"
                type="light"
                scrollHide={false}
                resizeHide={false}
                afterHide={() => setTimeout(() => setSelectedPage(null))}
              >
                <ImageTooltipContent
                  pageBreakImages={pageBreakImages}
                  pageBreakImagesLoading={pageBreakImagesLoading}
                  addPageBreakImage={addPageBreakImage}
                  deletePageBreakImage={deletePageBreakImage}
                  onImageSelect={onImageSelect}
                  selectedPage={selectedPage}
                  hide={() => {
                    tooltipRef.current.tooltipRef = null;
                    ReactTooltip.hide();
                    setSelectedPage(null);
                  }}
                />
              </ReactTooltip>
            </Container>
          )}
        </Droppable>
      </DragDropContext>
      {mouseOver?.sku ? (
        <ReactTooltip
          isCapture={true}
          event="mousemove"
          id="Tip"
          place="right"
          effect="solid"
          type="light"
          eventOff="mouseout"
        >
          <div>SKU: {mouseOver.sku}</div>
          <div>Description: {mouseOver.description}</div>
          {mouseOver.count && <div>Quantity: {mouseOver.count}</div>}
        </ReactTooltip>
      ) : null}
    </>
  );
};

export default LayersList;
