import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { faBars, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from 'lodash';
import Loader from '../../components/loaders/Loader';
import CustomInput from '../../components/Inputs/CustomInput';
import Kit from './components/Kit';
import PaginationNew from '../../components/Pagination/PaginationNew';
import CollapsableMenu from '../../components/Modal/CollapsableMenu';
import Filters from './Filters/Filters';
import {
  loadComponentInfoById,
  loadComponentList,
  loadKitListFromKitFinder,
  saveFilter,
} from '../../redux/actions/kitFinder';
import {
  deleteCartCustomKit,
  getCartCustomKits,
  loadSavedCustomKitsList,
  updateKitVisibility,
} from '../../redux/actions/kits';
import {
  getAppliedFilters,
  getComponentsIdList,
  mapFiltersToString,
} from './Filters/utils';
import { generalAccessRoleList } from '../../constants/constants';

const Container = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  position: relative;
  min-height: 80vh;
  width: 100%;

  & > svg {
    background: white;
    padding: 7px;
    font-size: 32px;
    color: #959595;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    cursor: pointer;
    position: absolute;
    width: 32px;
  }
`;

const SearchSection = styled.div`
  width: 80%;
  position: relative;
  padding: 0px 15px;

  & > div {
    width: 100%;
  }

  input {
    background: none;
  }

  & > i {
    top: 20px;
    position: absolute;
    right: 20px;
  }
`;

const Kits = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const PaginationSection = styled.div`
  display: flex;
  justify-content: center;
`;

const NoResults = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 30px;
  opacity: 0.5;
  font-weight: 600;
`;

const searchDelay = 1000;

const KitFinder = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    kits,
    loading,
    customKitsSaved,
    cart,
    totalPages,
    components,
    componentsLoading,
    component,
    filterState,
    user,
  } = useSelector((state) => ({
    cart: state.kits.cart,
    kits: state.kitFinder.kits,
    component: state.kitFinder.component,
    customKitsSaved: state.kits.customKitsSaved,
    loading: state.kitFinder.loading,
    totalPages: state.kitFinder.totalPages,
    components: state.kitFinder.components,
    componentsLoading: state.kitFinder.componentsLoading,
    filterState: state.kitFinder.filter,
    user: state.auth.user,
  }));
  const isSales = generalAccessRoleList.find((r) => r === user?.role?.alias);

  const [isListView, setIsListView] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const [page, setPage] = useState(0);
  const [tags, setTags] = useState('');
  const [level, setLevel] = useState({
    vascular: false,
    anesthesia: false,
    interventional: false,
  });
  const [filters, setFilters] = useState({});
  const [selectedComponents, setSelectedComponents] = useState([]);

  const isDifferent = (a, b) => !_.isEqual(a, b);

  useEffect(() => {
    if (isDifferent(filters, {}) || isDifferent(selectedComponents, [])) {
      dispatch(saveFilter({ ...filters, selectedComponents }));
    }
    // eslint-disable-next-line
  }, [filters, selectedComponents]);

  useEffect(() => {
    if (
      filterState &&
      isDifferent({ ...filters, selectedComponents }, filterState)
    ) {
      const { selectedComponents, ...rest } = filterState;
      setSelectedComponents(selectedComponents);
      setFilters(rest);
    }
    // eslint-disable-next-line
  }, [filterState]);

  const componentId = history.location.search
    .split('componentId')[1]
    ?.split('=')[1];
  const applyStarredComponents = history.location.search
    .split('applyStarredComponents')[1]
    ?.split('=')[1];

  useEffect(() => {
    if (componentId) {
      dispatch(loadComponentInfoById(componentId));
    }
  }, [componentId, dispatch]);

  useEffect(() => {
    if (component) {
      setSelectedComponents([{ ...component, checked: true }]);
    }
  }, [component]);

  useEffect(() => {
    if (!applyStarredComponents) {
      dispatch(
        loadKitListFromKitFinder({
          search: '',
          pageSize: 12,
          page: 0,
          tags: '',
          components: componentId || '',
        }),
      );
    }
    dispatch(loadSavedCustomKitsList());
    dispatch(getCartCustomKits());
  }, [dispatch, componentId, applyStarredComponents]);

  useEffect(() => {
    if (applyStarredComponents && cart.length > 0) {
      const starred = addStarredComponentsToList();
      const appliedFilters = getAppliedFilters(filters);
      dispatch(
        loadKitListFromKitFinder({
          search: searchValue,
          pageSize: 12,
          page,
          tags: appliedFilters,
          components: getComponentsIdList(starred),
        }),
      );
    }

    // eslint-disable-next-line
  }, [cart, dispatch]);

  const searchKits = useMemo(
    () =>
      _.debounce(
        (value) =>
          dispatch(
            loadKitListFromKitFinder({
              search: value,
              pageSize: 12,
              page,
              tags,
              components: getComponentsIdList(selectedComponents),
            }),
          ),
        searchDelay,
      ),
    [dispatch, page, tags, selectedComponents],
  );

  const onKitsSearch = (e) => {
    setSearchValue(e.target.value);
    searchKits(e.target.value);
  };

  const onComponentsSearch = (e) => searchComponents(e.target.value);
  const searchComponents = useMemo(
    () =>
      _.debounce(
        (value) => dispatch(loadComponentList({ search: value })),
        searchDelay,
      ),
    [dispatch],
  );

  const handlePagination = (page) => {
    setPage(page);
    dispatch(
      loadKitListFromKitFinder({
        search: searchValue,
        pageSize: 12,
        page,
        tags,
        components: getComponentsIdList(selectedComponents),
      }),
    );
  };

  const onKitClick = (kit) =>
    history.push(
      `/kitmakercomponents?KitFromBase=null&kit=${kit.name}&kitId=${
        kit.id
      }&orederedKitId=null&isFavourite=${1}`,
    );
  const onComponentDelete = (component) =>
    dispatch(deleteCartCustomKit(component.id));

  const addComponentToList = (component) => {
    const list = [...selectedComponents];
    if (list.length < 10) {
      list.push({ ...component, checked: true });
      setSelectedComponents(list);
    }
  };

  const handleComponentCheckbox = (component) => {
    const list = selectedComponents.map((sc) =>
      sc.id === component.id ? { ...sc, checked: !sc.checked } : { ...sc },
    );
    setSelectedComponents(list);
  };

  const addStarredComponentsToList = () => {
    const list = [...selectedComponents];
    const starred = cart
      .filter((f) => !list.find((l) => l.id === f.id))
      .map((str) => ({ ...str, checked: true }));
    setSelectedComponents(list.concat(starred));
    return starred;
  };

  const applyFilters = () => {
    const components = getComponentsIdList(selectedComponents);
    const appliedFilters = mapFiltersToString(filters);

    setTags(appliedFilters);
    dispatch(
      loadKitListFromKitFinder({
        search: searchValue,
        pageSize: 12,
        page: 0,
        tags: appliedFilters,
        components,
      }),
    );
  };

  const resetFilters = () => {
    setSelectedComponents([]);
    setFilters({});
    setSearchValue('');
    dispatch(
      loadKitListFromKitFinder({
        search: '',
        pageSize: 12,
        page: 0,
        tags: '',
        components: componentId || '',
      }),
    );
    dispatch(saveFilter(null));
  };

  return (
    <div className="main-layout">
      <div
        className="content-area"
        style={{ paddingBottom: 0, paddingLeft: 0 }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Filters
            components={components}
            loading={componentsLoading}
            level={level}
            setLevel={setLevel}
            filters={filters}
            setFilters={setFilters}
            selectedComponents={selectedComponents}
            setSelectedComponents={setSelectedComponents}
            onSearch={onComponentsSearch}
            addComponentToList={addComponentToList}
            handleComponentCheckbox={handleComponentCheckbox}
            addStarredComponentsToList={addStarredComponentsToList}
            onSubmit={applyFilters}
            resetFilters={resetFilters}
          />

          <Container>
            <SearchSection>
              <CustomInput
                label="Search..."
                onChange={onKitsSearch}
                value={searchValue}
              />
              {loading && searchValue && (
                <i className="fas fa-spinner fa-spin" />
              )}
            </SearchSection>

            <FontAwesomeIcon
              icon={faThLarge}
              style={{
                right: 120,
                top: 30,
                color: isListView ? '#959595' : '#61a431',
              }}
              onClick={() => setIsListView(false)}
            />
            <FontAwesomeIcon
              icon={faBars}
              style={{
                right: 70,
                top: 30,
                color: isListView ? '#61a431' : '#959595',
              }}
              onClick={() => setIsListView(true)}
            />

            <CollapsableMenu
              open={setShowMenu}
              showMenu={showMenu}
              kits={customKitsSaved}
              components={cart}
              onKitClick={onKitClick}
              onComponentDelete={onComponentDelete}
              createNewKit={(isFavorite) =>
                history.push(
                  isFavorite ? '/kitmaker?isFavourite=1' : '/kitmaker',
                )
              }
              justLike={() => history.push('/savedaskjustlike?isFavorite=1')}
            />

            {loading ? (
              <Loader style={{ left: '47%', top: '50%' }} />
            ) : (
              <Kits>
                {kits.length ? (
                  kits.map((kit) => (
                    <Kit
                      kit={kit}
                      onKitClick={(id) => history.push(`/kit/${id}`)}
                      onUseAsBaseClick={(id) => history.push(`/kitmaker/${id}`)}
                      key={kit.id}
                      onJustLikeClick={(id) =>
                        history.push(`/kitcatalogjustlike/${id}`)
                      }
                      updateKitVisibility={(id) =>
                        dispatch(
                          updateKitVisibility(id, !kit.isVisible, {
                            categories: null,
                            search: '',
                            componentId: null,
                            likeKitId: null,
                            pageSize: 12,
                            page: 0,
                          }),
                        )
                      }
                      isListView={isListView}
                      isSales={isSales}
                    />
                  ))
                ) : (
                  <NoResults>
                    There are no kits matching this search criteria
                  </NoResults>
                )}
              </Kits>
            )}

            {!loading && totalPages > 1 && (
              <PaginationSection>
                <PaginationNew
                  totalPages={totalPages}
                  activePage={page}
                  onChange={handlePagination}
                  pagesVisible={5}
                />
              </PaginationSection>
            )}
          </Container>
        </div>
      </div>
    </div>
  );
};

export default React.memo(KitFinder);
