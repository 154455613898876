import React, { useState } from 'react';
import styled from '@emotion/styled';
import { faPencilAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: baseline;
    color: #262268;
    border-radius: 5px;
    background: ${props => props.isEditing ? 'white' : ''};
    margin: 15px 0px;
    min-height: 30px;

    & > input {
        height: 30px;
    }
`;

const Input = styled.input`
    box-shadow: none !important;
    color: #262268;
    font-size: 16px;
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
    border: none !important;
    margin-bottom: 0px !important;
    width: 90%;
`;

const Label = styled.div`
    font-size: 16px;
    font-weight: bold;
    cursor: default;
    margin-bottom: 0px;
    color: #262268;
`;

const EditableSection = (props) => {
    const { value, error, editable, onSave, showSaveIcon, defaultText, ...rest } = props;
    const [editing, setEditing] = useState(false);

    const handleClick = () => {
        setEditing(false);
        if (onSave) {
            onSave();
        }
    };

    return (
        <>
            <Container isEditing={editing}>
                {editing ? <>
                    <Input autoFocus={editing} value={value} {...rest} />
                    {(!error && showSaveIcon) && <span>
                        <FontAwesomeIcon icon={faSave} style={{ cursor: 'pointer', color: '#61A431' }} onClick={handleClick} />
                    </span>}
                </> : <>
                        <Label>{value || defaultText}</Label>
                        {editable && <span>
                            <FontAwesomeIcon icon={faPencilAlt} style={{ cursor: 'pointer', marginLeft: 20 }} onClick={() => setEditing(true)} />
                        </span>}
                    </>}

            </Container>
            <span style={{ fontSize: 12, color: 'red', marginTop: -10, marginBottom: 10 }}>{error}</span>
        </>
    );
};

EditableSection.propTypes = {
    onSave: PropTypes.func,
    value: PropTypes.string,
    error: PropTypes.string,
    editable: PropTypes.bool,
    showSaveIcon: PropTypes.bool
};

EditableSection.defaultProps = {
    showSaveIcon: true,
    value: ''
};

export default EditableSection;
