import React, { Component, useCallback } from 'react';
import {
  MDBTabContent,
  MDBTabPane,
  MDBAlert,
  MDBModalBody,
  // MDBBreadcrumb,
  // MDBBreadcrumbItem,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBContainer,
} from 'mdbreact';
import agent from '../../agent';
import notfound from '../../shared/img/kitsDefaultImage.png';
import { connect } from 'react-redux';
import * as FileSaver from 'file-saver';
import { LOGIN_PAGE_UNLOADED, LOGOUT } from '../../constants/actionTypes';
import Dropzone from '../../shared/Dropzone';
import { updateKitVisibility } from '../../redux/actions/kits';
import Loader from '../../components/loaders/Loader';
import Switcher from '../../components/Switchers/Switcher';
import styled from '@emotion/styled';
import CustomButton from '../../components/Buttons/CustomButton';
import { generalAccessRoleList } from '../../constants/constants';
import Modal from '../../components/Modal/Modal';

const VisibilityWrapper = styled.div`
  display: block;
  margin: auto;
  width: 100px;
  padding-top: 10px;
  padding-left: 20px;
`;

const VisibilityLable = styled.div`
  font-size: 18px;
  font-weight: 500;
  padding-top: 50px;
`;

let KitDetailArray = {
  kits: [
    {
      description: 'No results found',
    },
  ],
};
const mapStateToProps = (state) => {
  return { currentUser: state.common.currentUser, user: state.auth.user };
};

const mapDispatchToProps = (dispatch) => ({
  onUnload: () => dispatch({ type: LOGIN_PAGE_UNLOADED }),
  onClickLogout: () => dispatch({ type: LOGOUT }),
  updateKitVisibility: (id, visibility) =>
    updateKitVisibility(id, visibility)(dispatch),
});
let star = false;

class KitCatalogDetail extends Component {
  currentLoggedInUser = null;
  currentUserName = null;
  currentNumber = null;
  currentStreet = null;
  currentSuiteApt = null;
  currentCity = null;
  currentZipCode = null;
  currentPhone = null;

  constructor(props) {
    super(props);
    const token = window.localStorage.getItem('jwt');
    if (token) {
    } else {
      this.props.onClickLogout();
    }
    this.changeUsername = (ev) => this.props.onChangeUsername(ev.target.value);
    this.changePassword = (ev) => this.props.onChangePassword(ev.target.value);
    this.submitForm = (username, password) => (ev) => {
      ev.preventDefault();
      this.props.onSubmit(username, password);
    };
    this.onUnloadForm = (Categories, ComponentId, PageSize, Page) => (ev) => {
      ev.preventDefault();
      this.props.onUnload(Categories, ComponentId, PageSize, Page);
    };
    this.state = {
      kitDetailData: null,
      modal: false,
      CSVData: null,
      selectedFile: null,
      fileData: null,
      kitId: null,
      notes: null,
      showPopup: false,
      isFileError: false,
      isValidSubmit: true,
      starActive: false,
      activeItem: '1',
      getCartComponents: null,
      savedKitData: null,
      deletedComponents: [],
      reloadKey: Math.random(),
      isPdfNull: false,
      isSales: generalAccessRoleList.find((r) => r === props.user?.role?.alias),
    };
    this.kitMakerAgreement = this.kitMakerAgreement.bind(this);
    this.kitRequestSample = this.kitRequestSample.bind(this);
    this.pdfonClick = this.pdfonClick.bind(this);
    this.onClickCSV = this.onClickCSV.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.customKits = this.customKits.bind(this);
    this.deleteComponents = this.deleteComponents.bind(this);
    this.getSavedKitComponents = this.getSavedKitComponents.bind(this);
    this.getComponentUseAsBase = this.getComponentUseAsBase.bind(this);
    this.updateKitVisibility = this.updateKitVisibility.bind(this);
  }

  toggleScreen = (tab) => (e) => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
      this.setState({ blue: !this.state.blue });
    }
    agent.CART.getCartCustomKits().then((res) => {
      if (res === null) {
        this.setState({ getCartComponents: KitDetailArray });
      } else {
        this.setState({ getCartComponents: res });
      }
    });
  };

  async updateKitVisibility(kitId, isVisible) {
    await this.props.updateKitVisibility(kitId, isVisible);
    this.setState({
      kitDetailData: { ...this.state.kitDetailData, isVisible },
    });
  }

  customKits() {
    const p = document.querySelectorAll('.onPrd');
    const x = document.getElementById('mySidenav');
    const y = document.getElementsByClassName('ryttray');
    const z = document.getElementById('onTray');
    let stil = window.getComputedStyle(x).getPropertyValue('display');
    if (stil === 'none') {
      x.style.display = 'inline';
      y[0].style.display = 'inline-table';
      y[0].style.right = '0';
      y[0].style.position = 'relative';
      y[0].style.top = '0';
      z.style.flex = '0 0 75%';
      z.style.maxWidth = '75%';
      for (let i = 0; i < p.length; i++) {
        p[i].classList.add('col-xl-6');
      }
    } else {
      x.style.display = 'none';
      y[0].style.right = '0';
      y[0].style.position = 'fixed';
      y[0].style.top = '65px';
      z.style.flex = '0 0 100%';
      z.style.maxWidth = '100%';
      for (let i = 0; i < p.length; i++) {
        p[i].classList.remove('col-xl-6');
      }
    }
  }

  componentWillMount() {
    this.currentLoggedInUserDetail = JSON.parse(
      window.localStorage.getItem('loggeduser'),
    );
    this.currentLoggedInUser =
      this.currentLoggedInUserDetail.userName === null
        ? ''
        : this.currentLoggedInUserDetail.userName;
    this.currentUserName =
      this.currentLoggedInUserDetail.firstName === null
        ? ''
        : this.currentLoggedInUserDetail.firstName +
          ' ' +
          this.currentLoggedInUserDetail.lastName;
    this.currentNumber =
      this.currentLoggedInUserDetail.number === null
        ? ''
        : this.currentLoggedInUserDetail.number;
    this.currentStreet =
      this.currentLoggedInUserDetail.street === null
        ? ''
        : this.currentLoggedInUserDetail.street;
    this.currentSuiteApt =
      this.currentLoggedInUserDetail.suiteApt === null
        ? ''
        : this.currentLoggedInUserDetail.suiteApt;
    this.currentCity =
      this.currentLoggedInUserDetail.city === null
        ? ''
        : this.currentLoggedInUserDetail.city;
    this.currentZipCode =
      this.currentLoggedInUserDetail.zipCode === null
        ? ''
        : this.currentLoggedInUserDetail.zipCode;
    this.currentPhone =
      this.currentLoggedInUserDetail.phone === null
        ? ''
        : this.currentLoggedInUserDetail.phone;
    let Id = null;
    Id = window.location.href.substring(
      window.location.href.lastIndexOf('/') + 1,
    );
    this.setState({ kitId: Id });
    const token = window.localStorage.getItem('jwt');
    if (token) {
      agent.KITCATLOG.kitbyId(Id).then((res) => {
        if (res === null) {
          this.setState({ kitDetailData: KitDetailArray });
        } else {
          this.setState({ kitDetailData: res });
        }
      });
      agent.SAVEDASK.savedASk().then((res) => {
        if (res === null) {
          this.setState({ savedKitData: KitDetailArray });
        } else {
          this.setState({ savedKitData: res.body });
        }
      });

      agent.CART.getCartCustomKits().then((res) => {
        if (res === null) {
          this.setState({ getCartComponents: KitDetailArray });
        } else {
          this.setState({ getCartComponents: res });
        }
      });
    } else {
      this.props.onClickLogout();
    }
  }

  componentDidMount() {
    window.onpopstate = () => {
      // window.location.reload(); // wtf ?
    };
  }

  onDrop = () => {
    useCallback((acceptedFiles) => {}, []);
  };
  kitMakerAgreement = (kitId) => {
    let path = `/kitmaker`;
    let data = kitId;
    this.props.history.push(path + '/' + data);
    window.location.reload();
  };
  kitRequestSample = (kitId) => {
    let path = '/kitcatalogrequestform';
    let data = kitId;
    this.props.history.push(path + '/' + data);
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      selectedFile: null,
      fileData: null,
    });
  };
  exportToXlsx = (kitName) => {
    agent.KITCATLOG.getAgreement().then((res) => {
      if (res === null) {
        return <Loader />;
      } else {
        const fileName = kitName + '_Supplemental_user_agreement.xls';
        FileSaver.saveAs(res, fileName);
        this.setState({
          modal: !this.state.modal,
          isValidSubmit: true,
        });
      }
    });
  };
  pdfonClick = (pdfUrl, byte) => {
    if (pdfUrl) {
      window.open(pdfUrl, '_blank');
    } else {
      this.setState({ isPdfNull: true });
    }
  };

  onClickCSV = (kitId, SKU) => {
    agent.KITCATLOG.getCSVData(kitId).then((res) => {
      var newStrrv = res.replace(/,/g, '', '');
      var newStr = newStrrv.replace(/\|/g, ',');
      var blob = new Blob([newStr]);
      if (window.navigator.msSaveOrOpenBlob)
        window.navigator.msSaveBlob(blob, SKU + '-components.csv');
      else {
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' });
        a.download = SKU + '-components.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    });
  };

  addDefaultSrc(ev) {
    ev.target.src = notfound;
  }

  handleChange = ({ target }) => {
    this.setState({
      notes: target.value,
    });
  };
  handleFiles = (files) => {
    this.setState({ selectedFile: files[0].name });
    var reader = new FileReader();
    reader.onload = () => this.setState({ fileData: reader.result });
    reader.readAsText(files[0]);
  };

  onAgreementSubmit = () => {
    if (this.state.fileData === null) {
      this.setState({ isValidSubmit: false });
    } else {
      agent.KITCATLOG.postAgreement(
        this.state.fileData,
        this.state.notes,
        this.state.kitId,
      ).then((res) => {
        this.setState({ selectedFile: null, isValidSubmit: true });
        this.setState({
          modal: !this.state.modal,
        });
        this.setState({
          showPopup: !this.state.showPopup,
        });
        setTimeout(
          function () {
            this.setState({ showPopup: !this.state.showPopup });
          }.bind(this),
          8000,
        );
        window.scroll(0, 0);
      });
    }
  };

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  }

  onCallbackDropZone = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      this.setState({
        fileData: acceptedFiles[0],
        selectedFile: acceptedFiles[0].name,
        isFileError: false,
      });
    } else {
      this.setState({ isFileError: true });
    }
  };
  getComponentDetail = (componentId) => {
    let path = '/components';
    this.props.history.push(path + '/' + componentId);
    window.location.reload();
  };

  onFavorite = (event, favoriteComponent) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ starActive: !this.state.starActive });
    star = !star;
    if (star) {
      event.target.setAttribute('class', 'fa fa-star active');
      agent.CART.postCustomKits(favoriteComponent.id).then((res) => {
        agent.CART.getCartCustomKits().then((res) => {
          if (res === null) {
            this.setState({ getCartComponents: KitDetailArray });
          } else {
            this.setState({ getCartComponents: res });
          }
        });
      });
    } else if (!star) {
      event.target.setAttribute('class', 'fa fa-star');
      agent.CART.deleteCustomKits(favoriteComponent.id).then((res) => {
        agent.CART.getCartCustomKits().then((res) => {
          this.setState({ getCartComponents: res ? res : KitDetailArray });
        });
      });
    }
  };

  deleteComponents = (event, component) => {
    agent.CART.deleteCustomKits(component.id).then((res) => {
      agent.CART.getCartCustomKits().then((res) => {
        if (res === null) {
          this.setState({ getCartComponents: KitDetailArray });
        } else {
          this.setState({ getCartComponents: res });
          this.setState({ reloadKey: Math.random() });
        }
      });
      agent.KITCATLOG.kitbyId(this.state.kitId).then((res) => {
        if (res === null) {
          this.setState({ kitDetailData: KitDetailArray });
        } else {
          this.setState({ kitDetailData: res });
        }
      });
    });
  };

  pdfGenerate(kitId, SKU) {
    this.setState({ isPDFLoaded: true });
    agent.KITCATLOG.pdfGenerate(kitId)
      .then((res) => {
        if (res !== null) {
          this.setState({
            responseStatusCode: res.statusCode,
            isPDFLoaded: false,
          });
          var newBlob = new Blob([res], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.href = url;
          const download = SKU + '.pdf';
          link.setAttribute('download', download);
          document.body.appendChild(link);
          link.click();
        } else {
          this.setState({ showPdfPop: !this.state.showPdfPop });
          setTimeout(
            function () {
              this.setState({ showPdfPop: !this.state.showPdfPop });
            }.bind(this),
            5000,
          );
          window.scroll(0, 0);
        }
      })
      .catch((error) => {
        setTimeout(
          function () {
            this.setState({
              showPdfPop: !this.state.showPdfPop,
              isPDFLoaded: false,
            });
          }.bind(this),
          5000,
        );
        window.scroll(0, 0);
      });
  }

  kitmakerform = () => {
    let path = '/kitmaker';
    this.props.history.push(path);
    window.location.reload();
  };
  getComponentUseAsBase = () => {
    let path = `/kitmaker`;
    this.props.history.push(path + '?isFavourite=1');
    window.location.reload();
  };
  getSavedKitComponents = (kit) => {
    this.props.history.push(
      '/kitmakercomponents?KitFromBase=' +
        null +
        '&kit=' +
        kit.name +
        '&kitId=' +
        kit.id +
        '&orederedKitId=' +
        null,
    );
    window.location.reload();
  };
  getJustLike = () => {
    let path = `/savedaskjustlike?isFavorite=1`;
    this.props.history.push(path);
    window.location.reload();
  };

  render() {
    if (
      this.state.kitDetailData === null ||
      this.state.savedKitData === null ||
      this.state.getCartComponents === null
    ) {
      return <Loader />;
    } else {
      let detaildata = this.state.kitDetailData;
      var savedData = this.state.savedKitData;
      var cartComponents = this.state.getCartComponents;
      let // selectedNode,
        SKU,
        description,
        dataComponents,
        pdfcomponents,
        kitId,
        image,
        getCartComponents,
        savedKitData = '';
      const displayStyle = { display: 'initial' };
      const displayNoneStyle = { display: 'none' };
      dataComponents = detaildata.components.map((component, index) => {
        return (
          <tr key={index} id={index}>
            <td onClick={() => this.getComponentDetail(component.id)}>
              <span className="datalngth cursorptr">{component.number}</span>
            </td>
            <td onClick={() => this.getComponentDetail(component.id)}>
              <span className="cursorptr">{component.labelName}</span>
            </td>
            <td onClick={() => this.getComponentDetail(component.id)}>
              <span className="cursorptr">{component.quantity}</span>
            </td>
            <td>
              <div
                style={{ padding: '15px' }}
                onClick={(event) => this.onFavorite(event, component)}
                id="myfavoritekit"
                className={
                  component.isStarred ? 'fa fa-star active' : 'fa fa-star'
                }
                aria-hidden="true"
                key={this.state.reloadKey}
              ></div>
            </td>
          </tr>
        );
      });
      SKU = detaildata.number;
      // description = detaildata.description;
      image = detaildata.imageUrl;
      description = detaildata.description;
      // selectedNode = detaildata.category;
      pdfcomponents = detaildata.pdfUrl;
      kitId = detaildata.id;
      const alertPopUp = (
        <MDBAlert color="success" dismiss>
          <strong>Successfully submitted Supplemental Agreement.</strong>
        </MDBAlert>
      );
      savedKitData = savedData.map((kit) => {
        return (
          <div
            id="customkits"
            key={kit.id}
            className="data-list"
            onPointerMove={this.hoverOn}
            onClick={(event) => this.getSavedKitComponents(kit)}
          >
            <p className="kitName anchorColor " id="customKitName">
              {kit.name}
              <span></span>
            </p>
            <a href="#no" className="kitCount" id="customKitId">
              Components Count: {kit.componentsCount}
            </a>
            <br />
          </div>
        );
      });
      getCartComponents = cartComponents.map((cartComponent) => {
        return (
          <div id="cartCustom" className="data-list" key={cartComponent.id}>
            <i
              className="fa fa-window-close"
              aria-hidden="true"
              onClick={(event) => this.deleteComponents(event, cartComponent)}
            ></i>
            <a href="#no" className="kitCount" id="customKitId">
              <p>SKU: {cartComponent.number}</p>
            </a>
            <p className="kitName anchorColor" id="customKitName">
              {cartComponent.description}
              <span></span>
            </p>
          </div>
        );
      });

      return (
        <div className="main-layout">
          <div className="prdlistview-area mb9">
            <MDBContainer fluid>
              <MDBRow>
                <MDBCol md="12" id="onTray">
                  <h5 className="pagehead">
                    KITS
                    {/*<span>|</span>*/}
                    {/*<MDBBreadcrumb className="mt40-xs pb-0">*/}
                    {/*  <MDBBreadcrumbItem>*/}
                    {/*    <i className="fa fa-home" aria-hidden="true"></i>*/}
                    {/*  </MDBBreadcrumbItem>*/}
                    {/*  <MDBBreadcrumbItem>*/}
                    {/*    <a href={'/kits'}>Kits</a>*/}
                    {/*  </MDBBreadcrumbItem>*/}
                    {/*  <MDBBreadcrumbItem>*/}
                    {/*    <a*/}
                    {/*      href={*/}
                    {/*        '/kits?kit=' +*/}
                    {/*        encodeURIComponent(JSON.stringify(selectedNode))*/}
                    {/*      }*/}
                    {/*    >*/}
                    {/*      {selectedNode.name}*/}
                    {/*    </a>*/}
                    {/*  </MDBBreadcrumbItem>*/}
                    {/*  <MDBBreadcrumbItem active>*/}
                    {/*    {this.state.kitDetailData.number}*/}
                    {/*  </MDBBreadcrumbItem>*/}
                    {/*</MDBBreadcrumb>*/}
                  </h5>
                  <MDBCard className="product-area">
                    <MDBCardBody className="p30">
                      <MDBRow>
                        <MDBCol md="3" className="text-center bb">
                          {!this.state.isSales && (
                            <CustomButton
                              label="Digital Sample"
                              background="#fff"
                              style={{
                                borderRadius: 0,
                                color: '#61a431 ',
                                border: '1px solid #61a431 ',
                              }}
                              onClick={() =>
                                this.props.history.push(
                                  `/kitlist/${this.state.kitId}/details?search=${SKU}`,
                                )
                              }
                            />
                          )}
                        </MDBCol>
                        <MDBCol md="9" className="text-center bb">
                          <h2>{SKU}</h2>
                        </MDBCol>
                      </MDBRow>
                      {this.state.showPopup ? alertPopUp : null}
                      <MDBRow>
                        <MDBCol md="3" className="text-center p-3">
                          <img
                            className="img-fluid imgh"
                            src={image === null ? notfound : image}
                            alt=""
                            onError={this.addDefaultSrc}
                          />
                          <p>
                            Representative photo(s):Actual product
                            specifications may vary from that shown in the photo
                          </p>
                          <div className="btn-area">
                            <MDBBtn
                              className="btn-outline-grey"
                              onClick={() => this.pdfonClick(pdfcomponents)}
                              style={
                                detaildata.pdfUrl
                                  ? displayStyle
                                  : displayNoneStyle
                              }
                            >
                              GRAPHIC
                            </MDBBtn>
                            <MDBBtn
                              className="btn-outline-grey"
                              onClick={() => this.onClickCSV(kitId, SKU)}
                            >
                              Lidstock
                            </MDBBtn>
                            <MDBBtn
                              className="btn-outline-grey"
                              onClick={() => this.pdfGenerate(kitId, SKU)}
                            >
                              PDF
                            </MDBBtn>
                          </div>
                          {!this.state.isSales && (
                            <div className="btn-area">
                              <VisibilityLable>Visibility</VisibilityLable>
                              <VisibilityWrapper>
                                <Switcher
                                  size="small"
                                  checked={detaildata.isVisible}
                                  onChange={() =>
                                    this.updateKitVisibility(
                                      kitId,
                                      !detaildata.isVisible,
                                    )
                                  }
                                />
                              </VisibilityWrapper>
                            </div>
                          )}
                        </MDBCol>
                        <MDBCol md="9" className="text-left p-3 divider">
                          <p className="heading">{description}</p>
                          <div className="kitmakerform cstmtbl">
                            <MDBTable striped small>
                              <MDBTableHead>
                                <tr>
                                  <th>
                                    <b>SKU #</b>
                                  </th>
                                  <th>
                                    <b>Description</b>
                                  </th>
                                  <th>
                                    <b>Quantity</b>
                                  </th>
                                  <th></th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>{dataComponents}</MDBTableBody>
                            </MDBTable>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="text-right btn-section btnfloat">
                        <MDBCol md="12">
                          <MDBBtn
                            rounded
                            onClick={() => this.exportToXlsx(detaildata.number)}
                          >
                            {' '}
                            REQUEST SUPPLEMENTAL USER AGREEMENT{' '}
                          </MDBBtn>
                          <MDBBtn
                            onClick={() =>
                              this.kitMakerAgreement(detaildata.id)
                            }
                          >
                            <i
                              className="fa fa-shopping-basket"
                              aria-hidden="true"
                            ></i>
                            USE AS A BASE
                          </MDBBtn>
                          <MDBBtn
                            id="requestsample"
                            onClick={() => this.kitRequestSample(detaildata.id)}
                          >
                            <i
                              className="fa fa-shopping-basket"
                              aria-hidden="true"
                            ></i>{' '}
                            REQUEST SAMPLE
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
                <MDBCol
                  lg="3"
                  md="6"
                  sm="6"
                  xs="12"
                  className="mt20-xs pl-lg-0 pl-xl-0"
                >
                  <div className="rytsidetray">
                    <i
                      className="fa fa-shopping-basket ryttray"
                      aria-hidden="true"
                      onClick={this.customKits}
                    ></i>
                    <div id="mySidenav" className="sidetray kitmaker pt-2 ">
                      <div id="authentication-layout">
                        <div className=" mtb2 tab-area mt-0">
                          <MDBBtn
                            className="m-0 mb20-xs btn-whitee"
                            active={this.state.activeItem === '1'}
                            onClick={this.toggleScreen('1')}
                          >
                            Custom kits
                          </MDBBtn>
                          <MDBBtn
                            className="m-0  mb20-xs btn-outline-indigo notwhite"
                            active={this.state.activeItem === '2'}
                            onClick={this.toggleScreen('2')}
                          >
                            {' '}
                            Components
                          </MDBBtn>
                        </div>
                        <MDBTabContent activeItem={this.state.activeItem}>
                          <MDBTabPane tabId="1" role="tabpanel">
                            <div className="tbl-scrl">{savedKitData}</div>
                            <br />
                            <MDBBtn
                              className="fullgbtn"
                              disabled={
                                this.state.savedKitData.length === 0
                                  ? true
                                  : false
                              }
                              onClick={() => this.kitmakerform()}
                            >
                              CREATE NEW KIT
                            </MDBBtn>
                          </MDBTabPane>
                          <MDBTabPane tabId="2" role="tabpanel">
                            <div className="tbl-scrl">
                              {this.state.getCartComponents.length === 0 ? (
                                <p>No starred component available right now</p>
                              ) : (
                                getCartComponents
                              )}
                            </div>
                            <MDBBtn
                              className="fullgbtn"
                              disabled={
                                this.state.getCartComponents.length === 0
                                  ? true
                                  : false
                              }
                              onClick={() => this.getComponentUseAsBase()}
                            >
                              CREATE NEW KIT
                            </MDBBtn>
                            <MDBBtn
                              className="fullgbtn"
                              disabled={
                                this.state.getCartComponents.length === 0
                                  ? true
                                  : false
                              }
                              onClick={() => this.getJustLike()}
                            >
                              JUST LIKE
                            </MDBBtn>
                          </MDBTabPane>
                        </MDBTabContent>
                      </div>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
          {this.state.modal && (
            <Modal size="lg" close={this.toggle}>
              <MDBModalBody className="agreement-data ">
                <MDBRow>
                  <MDBCol lg="12" md="12" sm="12" xs="12" className="mb-1">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      onClick={this.toggle}
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="content-area pt-0">
                  <MDBCol lg="12" md="12" sm="12" xs="12" className="mb-3">
                    <h3>Supplemental User Agreement</h3>
                    <p>Sales Representative Data</p>
                    <p>UserName:&nbsp;{this.currentLoggedInUser} </p>
                    <p> Name:&nbsp;{this.currentUserName}</p>
                    <p>
                      Address:&nbsp;
                      {this.currentNumber}&nbsp;
                      {this.currentStreet}&nbsp;
                      {this.currentSuiteApt}&nbsp;{this.currentCity}&nbsp;
                      {this.currentZipCode}
                    </p>
                  </MDBCol>
                  <MDBCol lg="6" md="6" sm="6" xs="6">
                    <label for="">
                      <b>Agreement Upload:</b>
                    </label>
                    <div className="form-group dropZoneDoc">
                      <Dropzone
                        className="text-center"
                        onDrop={(acceptedFiles) =>
                          this.onCallbackDropZone(acceptedFiles)
                        }
                        accept={'.xls'}
                      />
                      <p id="fileName">
                        <b>
                          {this.state.isFileError === false
                            ? this.state.selectedFile
                            : 'Please upload .xls file only'}
                        </b>
                      </p>
                    </div>
                  </MDBCol>
                  <MDBCol lg="6" md="6" sm="6" xs="6">
                    <div className="form-group">
                      <label for="">
                        <b>Notes:</b>
                      </label>
                      <textarea
                        className="form-control rounded-0"
                        id="Agreementnotes"
                        rows="4"
                        onChange={this.handleChange}
                      ></textarea>
                    </div>
                  </MDBCol>
                </MDBRow>
                <div className="pt-0 border-0 btn-section">
                  <MDBBtn rounded onClick={this.onAgreementSubmit}>
                    SUBMIT SUPPLEMENTAL USER AGREMENT{' '}
                  </MDBBtn>
                </div>
              </MDBModalBody>
            </Modal>
          )}
        </div>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KitCatalogDetail);
