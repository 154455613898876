import React from 'react';
import CustomSelect from '../../../../components/Selects/CustomSelect';
import { multiSelectStyles } from '../../../../constants/constants';
import styled from '@emotion/styled';

const Label = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: 500;

    & > div {
        display: flex;
        justify-content: space-between;
        & > span:last-of-type {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

const TrayList = styled.div(({ isActive }) => `
    width: 100%;
    padding-right: 10px;
    padding-left: 15px;
    [class*='-placeholder'], [class*='-singleValue'], [class*='-indicatorContainer'] {
        color: ${isActive ? 'black' : 'white'};
        justify-content: left;
    }
    [class*='-control'] {
        height: 38px;
        border-bottom: 1px solid ${isActive ? 'black' : 'white'};
    }
    & input {
        line-height: 38px;
        box-shadow: unset;
        color: black !important;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0px !important;
    }
`);

export const AssignTray = ({ isActive, trays, assignTray, value = undefined, tray, applyStyles = false, isSearchable = false, isClearable = false, placeholder = 'Assign Tray' }) => {

    return (
        <TrayList isActive={isActive?.id === tray.id} onClick={(e) => e.stopPropagation()}>
            <CustomSelect
                applyStyles={applyStyles}
                customStyles={{...multiSelectStyles, control: (provided) => ({...multiSelectStyles.control(provided), minHeight: "50px !important"})}}
                isSearchable={isSearchable}
                isClearable={isClearable}
                options={trays}
                value={value}
                getOptionLabel={o => <Label>
                    {o.name && o.name !== "null" && <div>
                        <span title={o.name}>{o.name}</span>
                    </div>}
                    {o.number && <div>
                        <span title={o.number}>{o.number}</span>
                    </div>}
                </Label>}
                getOptionValue={o => isSearchable ? o.name + o.number : o.id}
                onChange={(option) => assignTray(option, tray)}
                placeholder={placeholder}
            />
        </TrayList>
    );
};
