import * as actionTypes from '../../constants/actionTypes';
import { getToken } from '../../utils/getToken';
import axios from '../axiosMiddleware/axios';
import qs from 'querystring';
import { showSnackbar } from './snackbar';

export const loadKitsTableData = ({ loading, ...params }) => async dispatch => {
    dispatch({ type: actionTypes.GET_KITS_TABLE_DATA_START, loading });

    try {
        const res = await axios.get(`/api/kits?${qs.encode(params)}`, { headers: { Authorization: 'Bearer ' + getToken() } });
        dispatch({ type: actionTypes.GET_KITS_TABLE_DATA_SUCCESS, data: res.data });
    } catch (err) {
        dispatch({ type: actionTypes.GET_KITS_TABLE_DATA_FAIL, error: err });
    }
};

export const updateKitTags = (kitId, tags, params) => async dispatch => {
    dispatch({ type: actionTypes.UPDATE_KIT_TAG_START });

    try {
        await axios.post(`/api/cms/kits/${kitId}/tags`, tags, { headers: { Authorization: 'Bearer ' + getToken() } });
        dispatch({ type: actionTypes.UPDATE_KIT_TAG_SUCCESS });
        dispatch(showSnackbar('success', 'Attributes successfully updated'));
        dispatch(loadKitsTableData(params));
    } catch (err) {
        dispatch({ type: actionTypes.UPDATE_KIT_TAG_FAIL });
        dispatch(showSnackbar('success', 'Update attributes failed'));
    };
};
