
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { NavLink, useHistory } from 'react-router-dom';
import siteLogo from '../../../shared/img/logo.png';
import TrayIcon from '../../../shared/TrayIcon';
import Cavities from '../../Settings/KitTrays/Cavity/Cavities';
import Loader from '../../../components/loaders/Loader';
import { faClipboardList, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { filterComponentsFunc } from '../../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { requestSuaTemplate, postAgreement } from '../../../redux/actions/kits';
import { MDBBtn, MDBCol, MDBModal, MDBModalBody, MDBRow } from 'mdbreact';
import Dropzone from "../../../shared/Dropzone";
import ComponentsList from './ComponentsList';

const LogoSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;

    & > img {width: 20%;}

    & > span:first-of-type {
        color: #62A43E;
        font-size: 1.5vw;
        font-weight: 600;
        border-top: 2px solid #1d265b;
        margin-top: 5px;
        width: 20%;
        margin-bottom: 10px;
    }
`;

const Description = styled.div`
    font-size: 1.1vw;
    color: #1d265b;
    font-weight: 500;
    white-space: pre-line;
`;

const KitLink = styled.div`
  text-align: right;
  margin: 10px 0px;
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  & > a,
  & > span {
    color: #62a43e;
    cursor: pointer;
    align-self: flex-end;
    margin: 0px 15px;
  }
  svg {
    margin-right: 5px;
  }
`;

const KitImage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    & > img {
        max-width: 90%;
        max-height: 100%;
    }
`;

const KitMaker = (props) => {
    const {
      title,
      description,
      kit,
      tray,
      componentAssignments,
      selectedCavity,
      isEngineering,
      pdfLoader,
      notesObj,
      selectedNote,
      notesEditable,
      objects,
      pages,
      selectedPageOnCanvas,
      orderKit,
      pageImage,
      excludeFloating,
      isSales,
      labelVisibility,
      runPdfGeneration,
      updateLayout,
    } = props;
    const {
      useAsBaseKit,
      filterComponents,
      setSelectedCavity,
      setCanvasElemRef,
      setNotesObj,
      setSelectedNote,
      setAddNoteEvent,
      contactEngineering,
      kitDetails,
      listWrapperRef,
      pageBreakImages,
      imageLoading,
      setImageLoading,
    } = props;

    const { user } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const {
      location: { state },
    } = useHistory();
    const [showModal, setShowModal] = useState(false);

    const logoSection = (
      <>
        <LogoSection>
          <img src={siteLogo} alt="logo" />
          <span>{title}</span>
          <Description>{description}</Description>
        </LogoSection>
      </>
    );

    const useAsBaseKitLink = kit && tray?.imageUrl && (
      <KitLink style={{ width: "100%" }}>
        {orderKit && (
          <NavLink to={`/kitcatalogrequestform/${kit?.id}`}>
            <FontAwesomeIcon icon={faClipboardList} />
            Request sample
          </NavLink>
        )}
        {orderKit && (
          <span
            onClick={() => {
              dispatch(requestSuaTemplate(kit.number));
              setShowModal(true);
            }}
          >
            <FontAwesomeIcon icon={faClipboardList} />
            Request Supplemental Agreement
          </span>
        )}
        {useAsBaseKit && (
          <span onClick={useAsBaseKit}>
            <FontAwesomeIcon icon={faEdit} />
            Customize this Kit
          </span>
        )}
      </KitLink>
    );

    const [fileData, setFileData] = useState(null);
    const [fileError, setFileError] = useState(null);
    const [modalNotes, setModalNotes] = useState("");

    const toggle = () => {
      setShowModal(false);
      setFileData(null);
      setFileError(null);
      setModalNotes("");
    };

    const onAgreementSubmit = () => {
      if (fileData) {
        dispatch(postAgreement(fileData, modalNotes, kit.id));
        toggle();
      }
    };

    const onCallbackDropZone = (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFileData(acceptedFiles[0]);
      } else {
        setFileError(true);
      }
    };

    const filterAssignments = () => {
      if (objects?.length) {
        const { from, to, fromCavityId, toCavityId } = filterComponents;

        if (
          Number.isInteger(from) &&
          from === to &&
          fromCavityId === toCavityId
        ) {
          const object = objects.find((o) => o.id === from);
          return (componentAssignments || []).filter(
            (c) =>
              object?.cavityId === c.cavityId &&
              object.componentId === c.component?.id,
          );
        }

        const filteredObjectSKUs = filterComponentsFunc(objects, { from, to })
          .map((x) => x?.sku)
          .filter((x) => x !== undefined);
        return (componentAssignments || []).filter((x) =>
          filteredObjectSKUs.includes(x.component?.number),
        );
      }

      return componentAssignments || [];
    };

    const floatingCavity = tray?.cavities.find((x) => x.isFloating);

    const componentPlacement = filterAssignments()
      ?.filter((x) => x.cavityId !== null)
      .filter((x) =>
        excludeFloating && !state?.cavity
          ? x.cavityId !== floatingCavity.id
          : true
      )
      .map((x) => ({
        image: x.imageUrl,
        id: x.component.id,
        values: { x: x.x, y: x.y, r: x.rotation },
        cavityId: x.cavityId,
        sku: x.component.number,
        assemblyOrder: x.assemblyOrder,
        isHorizontalFlipped: x.isHorizontalFlipped,
      }));

    const cavities = tray?.cavities
      .map((x) => ({ ...x, points: JSON.parse(x.vertices || "[]") }))
      .filter((x) =>
        excludeFloating && !state?.cavity ? !x.isFloating : true
      );

    return (
      <>
        {pdfLoader && (
          <Loader
            name={"Generating pdf..."}
            style={{ width: "200px", zIndex: "999" }}
          />
        )}
        {!kit ? (
          logoSection
        ) : (
          <KitImage>
            {useAsBaseKitLink}
            {tray?.imageUrl ? (
              <Cavities
                tray={tray}
                cavities={cavities}
                setSelectedCavity={setSelectedCavity}
                selectedCavity={selectedCavity}
                componentPlacement={componentPlacement}
                pageBreakImages={pageBreakImages}
                setComponentPlacement={() => {}}
                ignoreComponentChanges={true}
                showCavities={false}
                pages={pages}
                selectedPageOnCanvas={selectedPageOnCanvas}
                setCanvasElemRef={setCanvasElemRef}
                notesObj={
                  notesObj
                    ? notesObj.filter((el, i, self) =>
                        el.componentId
                          ? self.findIndex(
                              (t) =>
                                el.componentId === t.componentId &&
                                el.cavityId === t.cavityId
                            ) === i
                          : true
                      )
                    : []
                }
                setNotesObj={setNotesObj}
                notesEditable={notesEditable}
                setSelectedNote={setSelectedNote}
                selectedNote={selectedNote}
                setAddNoteEvent={setAddNoteEvent}
                objects={objects}
                pageImage={pageImage}
                imageLoading={imageLoading}
                setImageLoading={setImageLoading}
                readOnly
                labelVisibility={labelVisibility}
                runPdfGeneration={runPdfGeneration}
                updateLayout={updateLayout}
                kit={kit}
              />
            ) : (
              <>
                <TrayIcon color="#8E92AD" size="20%" />
                <Description>
                  The tray for this kit does not have an image.
                </Description>
                {isEngineering && (
                  <KitLink disabled={!tray?.id}>
                    <NavLink
                      to={{
                        pathname: `/kit-trays/edit/${tray?.id}/overview`,
                        state: { tray: { action: "Edit Tray", data: tray } },
                      }}
                    >
                      Add image now
                    </NavLink>
                  </KitLink>
                )}
                {!isEngineering && contactEngineering && (
                  <KitLink>
                    {/* <span onClick={() => contactEngineering(kit)}>Contact Engineering</span> */}
                  </KitLink>
                )}
              </>
            )}
          </KitImage>
        )}
        {
          /* For pdf generation, it is hidden */ <ComponentsList
            kit={kit}
            kitDetails={kitDetails}
            addComponent={() => {}}
            deleteComponent={() => {}}
            deleteAllComponentsById={() => {}}
            isSales={isSales}
            objects={objects}
            pdfGenerationState={true}
            wrapperRef={listWrapperRef}
            grid={true}
          />
        }
        <MDBModal centered size="lg" isOpen={showModal} toggle={toggle}>
          <MDBModalBody className="agreement-data text-left">
            <MDBRow>
              <MDBCol lg="12" md="12" sm="12" xs="12" className="mb-1">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={toggle}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </MDBCol>
            </MDBRow>
            <MDBRow className="content-area pt-0">
              <MDBCol lg="12" md="12" sm="12" xs="12" className="mb-3">
                <h3>Supplemental User Agreement</h3>
                <p>Sales Representative Data</p>
                <p>UserName:&nbsp;{user.userName} </p>
                <p>
                  Name:&nbsp;{user.firstName} {user.lastName}
                </p>
                <p>
                  Address:&nbsp;
                  {user.phone}&nbsp;
                  {user.street}&nbsp;
                  {user.suiteApt}&nbsp;{user.city}&nbsp;
                  {user.zipCode}
                </p>
              </MDBCol>
              <MDBCol lg="6" md="6" sm="6" xs="6">
                <label htmlFor="">
                  <b>Agreement Upload:</b>
                </label>
                <div className="form-group dropZoneDoc">
                  <Dropzone
                    className="text-center"
                    onDrop={onCallbackDropZone}
                    accept={".xls"}
                  />
                  <p id="fileName">
                    <b>
                      {!fileError
                        ? fileData?.name || ""
                        : "Please upload .xls file only"}
                    </b>
                  </p>
                </div>
              </MDBCol>
              <MDBCol lg="6" md="6" sm="6" xs="6">
                <div className="form-group">
                  <label htmlFor="">
                    <b>Notes:</b>
                  </label>
                  <textarea
                    className="form-control rounded-0"
                    id="Agreementnotes"
                    rows="4"
                    onChange={(e) => setModalNotes(e.target.value)}
                  ></textarea>
                </div>
              </MDBCol>
            </MDBRow>
            <div className="pt-0 border-0 btn-section text-center">
              <MDBBtn rounded onClick={onAgreementSubmit}>
                SUBMIT SUPPLEMENTAL USER AGREMENT{" "}
              </MDBBtn>
            </div>
          </MDBModalBody>
        </MDBModal>
      </>
    );
};

export default KitMaker;
