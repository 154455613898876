import React, { useState } from 'react';
import styled from "@emotion/styled";
import CustomButton from '../../../components/Buttons/CustomButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faTrash, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import FancyScroll from '../../../components/FancyScroll';
import CustomSearch from './CustomSearch';

const Title = styled.div`
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    color: #1d265b;
`;

const Count = styled.div`
    text-align: center;
    font-weight: 300;
    font-size: 12px;
    color: #1d265b;
    padding-top: 5px;
    padding-bottom: 10px;
`;

const Wrapper = styled.div`
    max-width: 300px;
    min-width: 300px;
    padding: 10px;
    margin-left: 10px;
    padding-top: 50px;
    max-height: 90vh;
    position: relative;
`;

const SubmitSection = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 12px;
    bottom: 80px;
    position: absolute;
`;

const Item = styled.div`
    display: flex;
    &:hover {
        background: white;
    }
    flex-direction: column;
`;

const SubItem = styled(Item)`
    color: #2778c3;
    padding-left: 60px;
`;

const ItemNumber = styled.div`
    width: 40px;
    height: 40px;
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;
`;

const ItemInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
`;

const ItemName = styled.div`
    display: flex;
    font-size: 14px;
    text-decoration: underline;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    justify-content: space-between;
`;

const ItemLabel = styled.div`
    display: flex;
    font-size: 10px;
    color: #1d265b;
    padding-left: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const OptionsTitle = styled.div`
    text-align: left;
    font-weight: 500;
    padding-left: 40px;
    font-size: 14px;
    color: #2778c3;
`;

const DeleteButton = styled.div`
    cursor: pointer;
    opacity: 0.7;
    color: red;
    font-size: 12px;
    &:hover {
        opacity: 1;
    }
`;

const AddButton = styled(DeleteButton)`
    color: #2778c3;
`;


const RightMenu = ({
  components,
  options,
  selected,
  onImageDelete,
  onComponentDelete,
  onComponentAdd,
  onComponentSearch,
  searchedComponents,
  deletePackage,
}) => {
  const [addClicked, setAddClicked] = useState(null);

  return (
    <Wrapper>
      <CustomButton
        label="Delete Package"
        rounded
        background="orangered"
        style={{
          fontSize: 10,
          position: "absolute",
          top: -15,
          right: -10,
        }}
        onClick={deletePackage}
      />
      <Title>Included Components</Title>
      {addClicked !== null ? (
        <CustomSearch
          components={searchedComponents}
          assignComponent={() => {}}
          onSearch={(e) => onComponentSearch(e.target.value)}
          onSearchChange={(e) => onComponentSearch(e.target.value)}
          placeholder={
            addClicked === -1
              ? "Search component..."
              : "Search component option..."
          }
          color={addClicked === -1 ? "#1d265b" : "#2778c3"}
          buttonName={addClicked === -1 ? "Add" : "Add as option"}
          close={() => {
            setAddClicked(null);
            onComponentSearch("");
          }}
          clickItem={(comp) =>
            onComponentAdd(comp.id, addClicked === -1 ? undefined : addClicked)
          }
        />
      ) : (
        <React.Fragment>
          <Count>
            {components.length} Component{components.length !== 1 && "s"}
          </Count>
          <FancyScroll>
            {components.map((x, ind) => (
              <Item key={x.id}>
                <div style={{ display: "flex" }}>
                  <ItemNumber>{ind + 1}</ItemNumber>
                  <ItemInfo>
                    <ItemName>
                      <span>{x.name}</span>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "30px",
                        }}
                      >
                        <AddButton onClick={() => setAddClicked(x.id)}>
                          <FontAwesomeIcon
                            icon={faExchangeAlt}
                          ></FontAwesomeIcon>
                        </AddButton>
                        <DeleteButton onClick={() => onComponentDelete(x.id)}>
                          <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                        </DeleteButton>
                      </div>
                    </ItemName>
                    <ItemLabel>{x.label}</ItemLabel>
                  </ItemInfo>
                </div>
                {x.sub.length > 0 && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <OptionsTitle>Component Options</OptionsTitle>
                    {x.sub.map((y) => (
                      <SubItem key={y.id + x.id}>
                        <ItemName>
                          <span>{y.name}</span>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <DeleteButton
                              onClick={() => onComponentDelete(y.id)}
                            >
                              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                            </DeleteButton>
                          </div>
                        </ItemName>
                        <ItemLabel>{y.label}</ItemLabel>
                      </SubItem>
                    ))}
                  </div>
                )}
              </Item>
            ))}
          </FancyScroll>
          <SubmitSection>
            <CustomButton
              rounded
              label={"Add Component"}
              onClick={() => setAddClicked(-1)}
            />
            {options.find((x) => x.id === selected)?.imageUrl && (
              <CustomButton
                label="Delete Image"
                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                background={`orangered`}
                rounded
                style={{
                  position: "absolute",
                  bottom: -50,
                }}
                onClick={() => {
                  onImageDelete(selected);
                }}
              />
            )}
          </SubmitSection>
        </React.Fragment>
      )}
    </Wrapper>
  );
};

export default RightMenu;