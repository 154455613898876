import React, { useRef, useState, useEffect } from 'react';
import Wrapper from "../../../components/Wrapper/Wrapper";
import styled from "@emotion/styled";
import ImageSelection from './ImageSelection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import RightMenu from './RightMenu';

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: center;
`;

const EditorValue = styled.span`
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
  color: #1d265b;
  padding: 10px;
  position: relative;
  margin-right: 20px;
  height: 35px;
  text-align: left;
`;

const EditorValueInput = styled.span`
  height: 35px;
  text-align: left;
  font-weight: bold;
  color: #1d265b;
  border: none;
  outline: none;
  padding: unset !important;
  margin: unset !important;
  background: unset;
  box-shadow: unset !important;
`;

const EditButton = styled.button`
  color: #1d265b;
  border: none;
  background: unset;
  & > svg > path {
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
`;

const SaveButton = styled(EditButton)`
  color: #1d265b;
  height: 35px;
  text-align: center;
  font-weight: bold;
  border: none;
  border-radius: 3px;
  outline: none;
`;

const PackagingOptionsStage = ({
  selected,
  onFileSelected,
  updateOption,
  options,
  onImageDelete,
  onComponentSearch,
  searchedComponents,
  components,
  addComponent,
  deleteComponent,
  deletePackage,
}) => {
  const [editName, setEditName] = useState(false);
  const [name, setName] = useState("");
  const refInput = useRef();

  const mapComponents = (list) => {
    const conv = (x) => ({
      id: x.component.id,
      name: x.component.number,
      label: x.component.description,
    });
    const root = list.filter((x) => x.groupNumber === 0).map(conv);
    for (const elem of root) {
      elem.sub = list.filter((x) => x.groupNumber === elem.id).map(conv);
    }
    return root;
  };

  useEffect(() => {
    if (refInput?.current) {
      onChange({ target: refInput.current });
    }
    // eslint-disable-next-line
  }, [refInput, name]);

  useEffect(() => {
    setEditName(false);
  }, [selected]);

  useEffect(() => {
    if (editName) {
      refInput.current.focus();
    }
  }, [editName]);

  const handleSave = () => {
    updateOption(selected, name);
    setEditName(false);
    setName("");
  };

  const getCaretPosition = (editableDiv) => {
    let caretPos = 0,
      sel,
      range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount) {
        range = sel.getRangeAt(0);
        if (range.commonAncestorContainer.parentNode === editableDiv) {
          caretPos = range.endOffset;
        }
      }
    } else if (document.selection && document.selection.createRange) {
      range = document.selection.createRange();
      if (range.parentElement() === editableDiv) {
        var tempEl = document.createElement("span");
        editableDiv.insertBefore(tempEl, editableDiv.firstChild);
        var tempRange = range.duplicate();
        tempRange.moveToElementText(tempEl);
        tempRange.setEndPoint("EndToEnd", range);
        caretPos = tempRange.text.length;
      }
    }
    return caretPos;
  };

  const setCaretPosition = (elem, position) => {
    var range = document.createRange();
    var sel = window.getSelection();

    range.setStart(elem.childNodes[0], position);
    range.collapse(true);

    sel.removeAllRanges();
    sel.addRange(range);
    elem.focus();
  };

  const onChange = (e) => {
    const value = e.target.innerHTML.replace(/<[^>]*>?/gm, "");
    const position = getCaretPosition(e.target);
    e.target.innerHTML = value;
    setCaretPosition(e.target, position);
    setName(value);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Wrapper>
        <Content>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              position: "relative",
            }}
          >
            {selected && (
              <EditorValue>
                <EditorValueInput
                  ref={refInput}
                  contentEditable={editName}
                  onInput={onChange}
                  onClick={onChange}
                  onKeyUp={onChange}
                  onBlur={onChange}
                  onPaste={onChange}
                  dangerouslySetInnerHTML={{
                    __html:
                      options.find((x) => x.id === selected)?.name?.length > 0
                        ? options.find((x) => x.id === selected)?.name
                        : "Type Option Name",
                  }}
                  style={{ background: editName ? "#B5D3E7" : "unset" }}
                ></EditorValueInput>
                {editName ? (
                  <SaveButton onClick={handleSave}>
                    <FontAwesomeIcon icon={faSave} />
                  </SaveButton>
                ) : (
                  <EditButton
                    onClick={() => {
                      setName(
                        options.find((x) => x.id === selected)?.name || ""
                      );
                      setEditName(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </EditButton>
                )}
              </EditorValue>
            )}
            <ImageSelection
              selected={selected}
              onFileSelected={onFileSelected}
              options={options}
            ></ImageSelection>
          </div>
          {selected && (
            <RightMenu
              components={mapComponents(components)}
              options={options}
              selected={selected}
              onImageDelete={onImageDelete}
              onComponentDelete={(id) => deleteComponent(selected, id)}
              onComponentAdd={(id, groupId) =>
                addComponent(selected, id, groupId)
              }
              onComponentSearch={onComponentSearch}
              searchedComponents={searchedComponents}
              deletePackage={deletePackage}
            />
          )}
        </Content>
      </Wrapper>
    </div>
  );
};

export default PackagingOptionsStage;
