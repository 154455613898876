import React from 'react';
import styled from '@emotion/styled';

const Button = styled.button(({ color, background, rounded, disabled, border, hoverEffect }) => `
  background: ${background};
  padding: 10px 20px;
  border-radius: ${rounded ? '20px' : '5px'};
  border: ${border ? '2px solid ' + border : 'none'};
  color: ${color};
  opacity: ${disabled ? .5 : 1};

  & > svg {
      margin-right: 10px;
  }

  &:hover {
      font-weight: ${hoverEffect ? 'bold' : 'normal'};
  }
`);

const CustomButton = (props) => {
  const { label, icon, ...rest } = props;

  return (
    <Button {...rest}>
      {icon}
      {label}
    </Button>
  );
};

Button.defaultProps = {
  color: 'white',
  background: '#262268',
  label: 'Button',
  rounded: false,
  icon: null
};

export default CustomButton;
