import styled from '@emotion/styled';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';

const Container = styled.div`
    display: block;
    z-index: 20000;
    width: 350px;
    overflow: hidden;
    font-size: .875rem;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: .25rem;
    box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.1);;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    position: fixed;
    right: 10px;
    top: ${props => props.show ? 10 : -100}px;
    -webkit-transition: all 0.3s ease;                  
    -moz-transition: all 0.3s ease;                 
    -o-transition: all 0.3s ease;   
    -ms-transition: all 0.3s ease;          
    transition: all 0.3s ease;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    padding: .25rem .75rem;
    color: #6c757d;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0,0,0,.05);
    font-weight: 500;
    svg {
        color: orange;
        margin-right: 10px;
    }
`;

const Body = styled.div`
    padding: .75rem;
`;

const ReminderToast = () => {
    const { reminder } = useSelector(state => state.snackbar);

    return (
        <Container show={reminder}>
            <Header>
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <span>{reminder?.title}</span>
            </Header>
            <Body>
                {reminder?.message}
            </Body>
        </Container>
    );
};

export default ReminderToast;
