import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loadProductFamiliesList } from '../../../../redux/actions/productFamilies';
import {
  autoSaveKitFormData,
  updateKitFromMyKitList,
  validateCustomKitName,
} from '../../../../redux/actions/kits';
import { validateField, validateFieldList } from './validateField';
import CustomInput from '../../../../components/Inputs/CustomInput';
import CustomSelect from '../../../../components/Selects/CustomSelect';
import Switcher from '../../../../components/Switchers/Switcher';
import FancyScroll from '../../../../components/FancyScroll';
import CustomButton from '../../../../components/Buttons/CustomButton';
import {
  engineering,
  generalAccessRoleList,
  manufacturing,
} from '../../../../constants/constants';
import { hideReminder, showReminder } from '../../../../redux/actions/snackbar';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  padding: 10px;
  text-align: left;

  span,
  input:focus + label span,
  input:valid + label span {
    font-size: 12px !important;
  }

  input {
    background: #f9f9f9;
  }

  mark {
    color: red;
  }

  & > div:first-of-type {
    font-size: 12px;
  }

  button {
    width: fit-content;
    position: fixed;
    right: 50px;
    bottom: 40px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 40%;
  }
`;

const Title = styled.span`
  margin-top: 50px;
  text-align: left;
  color: #1d265b;
  font-weight: 600;

  svg {
    margin-left: 20px;
    cursor: pointer;
    color: #1d265b;
  }
`;

const nameExistsMsg =
  "Name already exists.Please choose another name for your Custom Kit.";
const positiveProperties = [
  "estUsage",
  "requestedPrice",
  "competitorPrice",
  "currentPrice",
];

const KitRequestForm = ({ status, isEngineering }) => {
  const {
    productFamilies,
    kitDetails,
    isNameUnique,
    user,
    autoSavedKitFormData,
    reminder,
  } = useSelector((state) => ({
    productFamilies: state.productFamilies.productFamilies,
    kitDetails: state.kits.kitDetails,
    isNameUnique: state.kits.isNameUnique,
    user: state.auth.user,
    autoSavedKitFormData: state.kits.autoSavedKitFormData,
    reminder: state.snackbar.reminder,
  }));

  const dispatch = useDispatch();
  const [fields, setFields] = useState({ ...kitDetails });
  const [validate, setValidate] = useState({});

  const {
    name,
    regionalManager,
    salesRep,
    contactTitle,
    contactName,
    accountNumber,
    facilityName,
    facilityStreet,
    facilitySuite,
    facilityCity,
    facilityZipCode,
    hospitalAccounts,
    productFamilyId,
    isNewBusiness,
    competitor,
    replacedKit,
    currentPrice,
    requestedPrice,
    estUsage,
    isDirectPurchase,
    jitName,
    id,
  } = fields;

  useEffect(() => {
    autoSavedKitFormData && dispatch(showReminder());
    if (
      autoSavedKitFormData &&
      kitDetails &&
      autoSavedKitFormData.id !== kitDetails.id
    ) {
      dispatch(autoSaveKitFormData(null));
      dispatch(hideReminder());
    }
  }, [kitDetails, dispatch, status, autoSavedKitFormData]);

  useEffect(() => {
    if (autoSavedKitFormData) {
      setFields(autoSavedKitFormData);
    }
  }, [autoSavedKitFormData]);

  useEffect(() => {
    if (kitDetails) {
      setFields({ ...kitDetails, ...autoSavedKitFormData });
    }
  }, [kitDetails, autoSavedKitFormData]);

  const generalAccess = !!generalAccessRoleList.find(
    (r) => r === user?.role?.alias
  );
  const disabled =
    status === manufacturing || (generalAccess && status === engineering);

  useEffect(() => {
    dispatch(loadProductFamiliesList(generalAccess || isEngineering));
  }, [dispatch, generalAccess, isEngineering]);

  useEffect(() => {
    return () => {
      dispatch(hideReminder());
    };
  }, [dispatch]);

  if (!kitDetails) return null;

  const addField = () => {
    const accounts = [...hospitalAccounts];
    accounts.push("");
    setFields({ ...fields, hospitalAccounts: accounts });
    dispatch(autoSaveKitFormData({ ...fields, hospitalAccounts: accounts }));
    if (!reminder) {
      dispatch(showReminder());
    }
  };

  const handleChanges = (e, index) => {
    if (e.target?.name === "kit-name") {
      e.target.name = "name";
    }
    if (e.id) {
      setFields({ ...fields, productFamilyId: e.id });
      dispatch(autoSaveKitFormData({ ...fields, productFamilyId: e.id }));
    } else {
      if (Number.isInteger(index)) {
        const accounts = [...hospitalAccounts];
        accounts[index] = e.target.value;
        setFields({ ...fields, hospitalAccounts: accounts });
        dispatch(
          autoSaveKitFormData({ ...fields, hospitalAccounts: accounts })
        );
      } else {
        const isNegative =
          positiveProperties.find((prop) => prop === e.target.name) &&
          e.target.value < 0;
        if (e.target.name === "name")
          dispatch(validateCustomKitName(e.target.value, id));
        setFields({
          ...fields,
          [e.target.name]: isNegative ? 0 : e.target.value,
        });
        const valid = validateField(e.target.name, e.target.value);
        setValidate({ ...validate, [e.target.name]: valid });
        dispatch(
          autoSaveKitFormData({
            ...fields,
            [e.target.name]: isNegative ? 0 : e.target.value,
          })
        );
      }
    }
    if (!reminder) {
      dispatch(showReminder());
    }
  };

  const handleFocus = (e) => {
    if (e.target?.name === "kit-name") {
      e.target.name = "name";
    }
    if (e.target.value === name && isNameUnique) {
      dispatch(validateCustomKitName(name, id));
    }
    const valid = validateField(e.target.name, e.target.value);
    setValidate({ ...validate, [e.target.name]: valid });
  };

  const onSubmit = () => {
    const obj = {};
    const hospitalAccounts = fields.hospitalAccounts.filter((acc) => !!acc);
    if (isRequired) {
      validateFieldList.forEach(
        (name) =>
          (obj[name] = validateField(name, fields[name], isDirectPurchase))
      );
    }

    setFields({ ...fields, hospitalAccounts });

    if (Object.values(obj).some((val) => !!val)) {
      setValidate(obj);
      return;
    }

    dispatch(updateKitFromMyKitList({ ...fields, hospitalAccounts }, true));
    dispatch(hideReminder());
  };

  const isRequired = !(name ?? "").toLowerCase().includes("graphic");

  return (
    <FancyScroll styles={{ width: '100%' }}>
      <Container disabled={disabled}>
        <Section>
          <CustomInput
            label="What would you like to save this kit request in your profile as ? "
            isRequired
            name="kit-name"
            value={name}
            onChange={handleChanges}
            errorText={!isNameUnique && nameExistsMsg}
            invalid={!isNameUnique}
            onFocus={handleFocus}
          />
          <Row>
            <CustomInput
              label="Regional Manager "
              isRequired={isRequired}
              name="regionalManager"
              value={regionalManager}
              onChange={handleChanges}
              invalid={isRequired && validate.regionalManager}
              errorText={isRequired && validate.regionalManager}
              onFocus={handleFocus}
            />
            <CustomInput
              label="Sales Representative "
              isRequired={isRequired}
              name="salesRep"
              value={salesRep}
              onChange={handleChanges}
              invalid={isRequired && validate.salesRep}
              errorText={isRequired && validate.salesRep}
              onFocus={handleFocus}
            />
          </Row>
          <Row>
            <CustomInput
              label="Purchasing Contact Title "
              isRequired={isRequired}
              name="contactTitle"
              value={contactTitle}
              onChange={handleChanges}
              invalid={isRequired && validate.contactTitle}
              errorText={isRequired && validate.contactTitle}
              onFocus={handleFocus}
            />
            <CustomInput
              label="Purchasing Contact "
              isRequired={isRequired}
              name="contactName"
              value={contactName}
              onChange={handleChanges}
              invalid={isRequired && validate.contactName}
              errorText={isRequired && validate.contactName}
              onFocus={handleFocus}
            />
          </Row>
          <CustomInput
            label="Account Number "
            isRequired={isRequired}
            name="accountNumber"
            value={accountNumber}
            onChange={handleChanges}
            invalid={isRequired && validate.accountNumber}
            errorText={isRequired && validate.accountNumber}
            onFocus={handleFocus}
          />
          <Title>Facilty Name And Address</Title>
          <CustomInput
            label="Facility Name "
            isRequired={isRequired}
            name="facilityName"
            value={facilityName}
            onChange={handleChanges}
            invalid={isRequired && validate.facilityName}
            errorText={isRequired && validate.facilityName}
            onFocus={handleFocus}
          />
          <Row>
            <CustomInput
              label="Street "
              isRequired={isRequired}
              name="facilityStreet"
              value={facilityStreet}
              onChange={handleChanges}
              invalid={isRequired && validate.facilityStreet}
              errorText={isRequired && validate.facilityStreet}
              onFocus={handleFocus}
            />
            <CustomInput
              label="Suite/Apt"
              name="facilitySuite"
              value={facilitySuite}
              onChange={handleChanges}
            />
          </Row>
          <Row>
            <CustomInput
              label="City, State "
              isRequired={isRequired}
              name="facilityCity"
              value={facilityCity}
              onChange={handleChanges}
              invalid={isRequired && validate.facilityCity}
              errorText={isRequired && validate.facilityCity}
              onFocus={handleFocus}
            />
            <CustomInput
              label="ZipCode "
              isRequired={isRequired}
              name="facilityZipCode"
              value={facilityZipCode}
              onChange={handleChanges}
              invalid={isRequired && validate.facilityZipCode}
              errorText={isRequired && validate.facilityZipCode}
              onFocus={handleFocus}
            />
          </Row>
          <Title>
            Add account from sister hospital using Custom Kits (if applicable)
            <FontAwesomeIcon icon={faPlus} onClick={addField} />
          </Title>
          {hospitalAccounts?.map((account, index) => (
            <CustomInput
              label="Subsidiary Account Number"
              value={account}
              key={index}
              name="hospitalAccounts"
              onChange={(e) => handleChanges(e, index)}
            />
          ))}
        </Section>
        <Section>
          <span
            style={{
              color:
                productFamilies.find((pf) => pf.id === productFamilyId) &&
                "#4285f4",
            }}
          >
            Product Family <span style={{ color: "red" }}>*</span>
          </span>
          <CustomSelect
            applyStyles
            isSearchable={false}
            options={productFamilies}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            value={
              productFamilies.find((pf) => pf.id === productFamilyId) || null
            }
            onChange={handleChanges}
          />
          {!productFamilies.find((pf) => pf.id === productFamilyId) && (
            <span style={{ color: "red" }}>Please select Product Family</span>
          )}
          <Title>The ASK product is</Title>
          <br />
          <Switcher
            leftLabel="Replacing Existing Arrow Business"
            rightLabel="New Business"
            checked={isNewBusiness}
            onChange={() =>
              setFields({ ...fields, isNewBusiness: !isNewBusiness })
            }
          />
          {isNewBusiness ? (
            <Row>
              <CustomInput
                label="Current Competitor:"
                name="competitor"
                value={competitor}
                onChange={handleChanges}
              />
              <CustomInput
                label="Competitor's Price ($):"
                name="currentPrice"
                value={currentPrice}
                onChange={handleChanges}
                type="number"
              />
            </Row>
          ) : (
            <Row>
              <CustomInput
                label="Kit Being Replaced:"
                name="replacedKit"
                value={replacedKit}
                onChange={handleChanges}
              />
              <CustomInput
                label="Current Price ($):"
                name="currentPrice"
                value={currentPrice}
                onChange={handleChanges}
                type="number"
              />
            </Row>
          )}
          <br />
          <br />
          <CustomInput
            label="Requested Price ($):"
            name="requestedPrice"
            value={requestedPrice}
            onChange={handleChanges}
            type="number"
          />
          <CustomInput
            label="Estimated Annual Usage in Eaches "
            isRequired={isRequired}
            name="estUsage"
            value={estUsage}
            onChange={handleChanges}
            type="number"
            invalid={isRequired && validate.estUsage}
            errorText={isRequired && validate.estUsage}
            onFocus={handleFocus}
          />
          <br />
          <br />
          <Switcher
            leftLabel="Purchase Type is Distributor"
            rightLabel="Purchase Type is Direct"
            checked={isDirectPurchase}
            onChange={() =>
              setFields({ ...fields, isDirectPurchase: !isDirectPurchase })
            }
          />
          {!isDirectPurchase && (
            <CustomInput
              label="Distributor Name "
              isRequired={isRequired}
              name="jitName"
              value={jitName}
              onChange={handleChanges}
              invalid={isRequired && validate.jitName}
              errorText={isRequired && validate.jitName}
              onFocus={handleFocus}
            />
          )}
          <CustomButton
            onClick={onSubmit}
            label="Save"
            background="#61A431"
            rounded
            disabled={!productFamilies.find((pf) => pf.id === productFamilyId)}
          />
        </Section>
      </Container>
    </FancyScroll>
  );
};

export default KitRequestForm;
