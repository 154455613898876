import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ComponentContenWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ComponentContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ComponentContentOverridenIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 0.25rem;
`;

export const ComponentContentInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 1.5rem;
`;

export const ComponentContentSku = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ComponentContentName = styled.span`
  font-size: 0.75rem;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
