import React from "react";
import PropTypes from "prop-types";
import Switcher from "../../../components/Switchers/Switcher";
import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
`;

const SortSwitcher = ({
  setSortDirection,
  sortDirection,
  leftLabel = "Sku",
  rightLabel = "Desc",
}) => {
  return (
    <Container>
      <span>Order by: </span>
      <Switcher
        leftLabel={leftLabel}
        rightLabel={rightLabel}
        checked={sortDirection === "desc"}
        onChange={() =>
          setSortDirection(sortDirection === "asc" ? "desc" : "asc")
        }
      />
    </Container>
  );
};

SortSwitcher.propTypes = {
  setSortDirection: PropTypes.func.isRequired,
  sortDirection: PropTypes.string.isRequired,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
};

export default SortSwitcher;
