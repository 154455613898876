import React from 'react';
import styled from '@emotion/styled';
import CustomInput from '../../../components/Inputs/CustomInput';
import Switcher from '../../../components/Switchers/Switcher';

const Container = styled.div`
    display: flex;
    padding: 20px;
    flex-direction: column;
    height: 100%;
    text-align: left;

    & > div {
        display: flex;
        min-height: 95%;
        justify-content: space-between;
        width: 100%;
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 45%;

    & > div {
        margin-bottom: 30px;
        input {background: inherit;}
        span {font-size: 14px;}
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    
        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }
    }
`;

const Switchers = styled.div`
    display: flex;
    justify-content: space-around;
`;

const errorText = 'This field is required!';

const TechnicalDetails = ({ component, onInputChange }) => {
    return (
        <Container>
            <div>
                <Column>
                    <CustomInput label='Width (inches) ' value={component.width} name='width' onChange={onInputChange} type='number' isRequired errorText={component.width === '' ? errorText : null} invalid={component.width === ''} />
                    <CustomInput label='Height (inches) ' value={component.height} name='height' onChange={onInputChange} type='number' isRequired errorText={component.height === '' ? errorText : null} invalid={component.height === ''} />
                    <CustomInput label='Depth (inches) ' value={component.depth} name='depth' onChange={onInputChange} type='number' isRequired errorText={component.depth === '' ? errorText : null} invalid={component.depth === ''} />
                    <CustomInput label='Weight (grams) ' value={component.weight} name='weight' onChange={onInputChange} type='number' isRequired errorText={component.weight === '' ? errorText : null} invalid={component.weight === ''} />
                    <Switchers>
                        <Switcher checked={component.isSterile} rightLabel='Is Sterile' onChange={(e) => onInputChange({ target: { name: 'isSterile', value: !component.isSterile } })} />
                        {/* <Switcher checked={component.isFloating} rightLabel='Is Floating' onChange={(e) => onInputChange({ target: { name: 'isFloating', value: !component.isFloating } })} /> */}
                    </Switchers>
                </Column>
                <Column>
                    <CustomInput value={component.cost} label='Margin Pricing' name='cost' onChange={onInputChange} type='number' errorText={component.cost === '' ? errorText : null} invalid={component.cost === ''} style={{ pointerEvents: 'none', opacity: .5 }} />
                    <CustomInput value={component.overrideCost} label='Overide Pricing ' name='overrideCost' onChange={onInputChange} type='number' />
                </Column>
            </div>
        </Container>
    );
};

export default TechnicalDetails;
