import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { debounce, isEqual } from 'lodash-es';
import LayersList from './LayersList';
import FancyScroll from '../../../../components/FancyScroll';
import GoBack from '../../Helpers/GoBack';
import CustomInput from '../../../../components/Inputs/CustomInput';
import { getReplacements } from '../../../../redux/actions/kits';
import {
  labelsVisibilityOptions,
  manufacturing,
  multiSelectStyles,
} from '../../../../constants/constants';
import { generatePdf } from '../../Helpers/snapshotsToPDF';
import CustomSelect from '../../../../components/Selects/CustomSelect';
import { useDispatch, useSelector } from 'react-redux';
import { useFirstRender } from '../../../../hooks/useFirstRender';
import { getCorrectSku, getPageId, overrideNotePosition } from '../helper';
import NoteOptions from './NoteOptions';
import BottomActions from './BottomActions';
import PagesComponentsSwitcher from './PagesComponentsSwitcher';

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
  max-width: ${(props) => props.width || 50}%;

  & > div {
    margin-bottom: 10px;
  }

  input {
    background: #1d265b;
    color: #ffffffa3;
    text-align: center;
  }

  input:focus + label span,
  input:valid + label span {
    color: white !important;
    font-size: 12px !important;
  }

  label,
  label:after {
    border-bottom: 1px solid #ffffffa3;
  }

  span {
    color: white;
    font-size: 12px;
    font-weight: 500;
  }
`;

const SubmitSection = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 12px;
  bottom: ${(props) => (props.selectedNote ? 60 : 100)}px;
  position: absolute;
  left: -10px;
  & > button {
    margin-left: 10px;
  }
`;

const ReminderManufacturing = styled.div`
  display: flex;
  padding: 10px 0px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  user-select: none;
  cursor: pointer;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
`;

const Labels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  width: 50%;
  & > div {
    width: 100%;
  }
`;

const Snapshots = ({
  objects,
  pages,
  updateAssemblyOrder,
  updateLayout,
  selectedPage,
  setSelectedPage,
  setPdfLoader,
  deleteSnapshots,
  tray,
  createSnapshot,
  canvasElemRef,
  kit,
  componentAssignments,
  notesObj,
  setNotesObj,
  setSelectedNote,
  selectedNote,
  setNotesEditable,
  version,
  setAddNoteEvent,
  addNoteEvent,
  pageBreakImages,
  pageBreakImagesLoading,
  addPageBreakImage,
  setPageImage,
  imageLoading,
  deletePageBreakImage,
  setSelectedCavity,
  isSales,
  layoutData,
  setLabelVisibility,
  labelVisibility,
  runPdfGeneration,
  setRunPdfGeneration,
  buildDefaultPages,
  notShownQuantityOfSelectedNote,
  resetLabelText,
  kitDetails,
}) => {
  const history = useHistory();

  const [allNotes, setAllNotes] = useState([]);
  const [scrolledOnce, setScrolledOnce] = useState(false);

  const recentUpdatedPage = useSelector(
    (state) => state.kits.recentUpdatedPage,
  );

  const refContainer = useRef();
  const dispatch = useDispatch();

  const needScrollDown =
    refContainer.current?.scrollHeight > window.innerHeight;
  // scroll down to page 1
  useEffect(() => {
    if (refContainer.current && !scrolledOnce && needScrollDown) {
      refContainer.current.scrollTo({
        top: Number.MAX_SAFE_INTEGER,
        behavior: 'smooth',
      });
      setScrolledOnce(true);
    }
  }, [needScrollDown, scrolledOnce]);

  const setSelectedCavityCallback = useCallback(
    (arg) => {
      setSelectedCavity(arg);
    },
    [setSelectedCavity],
  );

  useEffect(() => {
    setSelectedCavityCallback(null);
  }, [setSelectedCavityCallback]);

  const firstRender = useFirstRender(pages.length);
  useEffect(() => {
    if (recentUpdatedPage) {
      setSelectedPage(recentUpdatedPage);
    } else if (firstRender) {
      setSelectedPage(pages[pages.length - 1]);
    }
  }, [pages, setSelectedPage, firstRender, recentUpdatedPage, dispatch]);

  const generateNotes = async (isPageOrderChanged) => {
    const replData = await getReplacements(kit?.id);
    const result = [];

    const newLayoutData = (layoutData || []).filter((label) => {
      if (label.componentId && label.cavityId) {
        return componentAssignments.find(
          (assignment) =>
            assignment.component.id === label.componentId &&
            assignment.cavityId === label.cavityId,
        );
      }

      return true;
    });

    (tray || { cavities: [] }).cavities.forEach((cavity) => {
      const components = componentAssignments.filter(
        (y) => y.cavityId === cavity.id,
      );

      components.forEach((componentObj) => {
        const template = JSON.parse(
          (componentObj.labelData ?? '').replace(/'/g, '"'),
        );
        const existing = (isPageOrderChanged ? notesObj : newLayoutData).find(
          (x) =>
            x.cavityId === cavity?.id &&
            x.componentId === componentObj.component.id,
        );

        const templateNote = template
          ? { ...template, position: { x: template.x, y: template.y } }
          : {};

        const note = existing ?? templateNote;

        const { highlighted, rotation, isOverridden, isHidden, text } = note;
        const pageId = getPageId(objects, componentObj);

        let position = note.position;
        if (!position) {
          position = overrideNotePosition(cavity, componentObj);
        }

        const info = replData.find(
          (x) => x.number === componentObj.component?.number,
        );

        const sku = getCorrectSku(isOverridden, text, components, componentObj);

        const isExist = result.find(
          (res) =>
            res.componentId === componentObj?.component?.id &&
            res.cavityId === componentObj.cavityId,
        );

        if (!isExist)
          result.push({
            cavityId: componentObj.cavityId,
            componentId: componentObj.component?.id,
            position,
            text: sku,
            replacement: info?.substitutes.join('\n'),
            pageId,
            highlighted: highlighted,
            rotation: +rotation || 0,
            isOverridden,
            isHidden,
          });
      });
    });

    if (!newLayoutData?.length && !result.length) {
      result.push({});
    }

    if (newLayoutData?.length > 0) {
      newLayoutData.forEach((ld) => {
        if (!ld.cavityId) {
          result.push(ld);
        }
      });
    }

    return result.map((res, index) => ({ ...res, id: index }));
  };

  useEffect(() => {
    const getNotes = async () => {
      if (!notesObj.length && kit) {
        const notes = await generateNotes();
        setAllNotes(notes);
        setNotesObj(notes);
        updateLayout(notes, kit.id);
      }
    };
    getNotes();

    const newSelectedNote = notesObj.find((note) => {
      const { cavityId, componentId, id, pageId } = selectedNote || {};
      // we have labels not related to any component or cavity
      // user can create them manually
      if (!cavityId && !componentId) {
        return note.id === id && note.pageId === pageId;
      }

      return note.cavityId === cavityId && note.componentId === componentId;
    });

    const areNotesEqual = isEqual(selectedNote, newSelectedNote);

    if (selectedNote && newSelectedNote && !areNotesEqual) {
      setSelectedNote(newSelectedNote);
    }
    // eslint-disable-next-line
  }, [notesObj, componentAssignments, kit]);

  useEffect(() => {
    return () => {
      setLabelVisibility(null);
    };
  }, [setLabelVisibility]);

  const [versionValue, setVersionValue] = useState(null);

  useEffect(() => {
    if (versionValue === null && version) {
      setVersionValue(version);
    }
  }, [version, versionValue]);

  const addNote = () => {
    let max_id = 0;
    for (let note of allNotes) {
      if (note.id && note.id > max_id) {
        max_id = note.id;
      }
    }
    const newNote = {
      text: 'Edit me',
      position: { x: 100, y: 100 },
      pageId: selectedPage.id,
      id: max_id + 1,
      isHidden: false,
    };

    const temp = notesObj.map((x) => x);
    temp.push(newNote);

    setNotesObj(temp);
    setSelectedNote(newNote);
  };

  useEffect(() => {
    if (addNoteEvent) {
      setAddNoteEvent(false);
      addNote();
    }
    // eslint-disable-next-line
  }, [addNoteEvent]);

  const updateVersionMemo = useMemo(
    () => debounce((value) => updateAssemblyOrder(objects, pages, value), 2000),
    // eslint-disable-next-line
    [updateAssemblyOrder],
  );

  const [currentPagePdf, setCurrentPagePdf] = useState(null);
  const [pdfDocument, setPdfDocument] = useState([]);

  useEffect(() => {
    if (runPdfGeneration) {
      // remark: to much parameters here
      generatePdf({
        currentPagePdf,
        setPdfDocument,
        setCurrentPagePdf,
        setSelectedPage,
        setNotesEditable,
        setPdfLoader,
        pages,
        setRunPdfGeneration,
        name: kit.name,
        pdfDocument,
        canvasElemRef,
        versionValue,
        imageLoading,
        selectedPage,
        isSales,
      });
    }
    // eslint-disable-next-line
  }, [runPdfGeneration, selectedPage, imageLoading, currentPagePdf]);

  const innerprops = {
    objects,
    pages,
    componentAssignments,
    setSelectedPage,
    setAllNotes,
    allNotes,
    kit,
    SubmitSection,
    setNotesObj,
    selectedPage,
    notesObj,
  };

  // snapshots should not go back they should go back to menu
  const back = () => {
    const path = history.location.pathname;
    history.push(path.split('/').slice(0, -1).join('/'));
  };

  return !selectedNote ? (
    <>
      <GoBack title="Create snapshot" goBack={back} />
      <PagesComponentsSwitcher />
      <FancyScroll ref={refContainer} offset={240}>
        {kit?.status === manufacturing && (
          <ReminderManufacturing>
            Approved kits can't be edited
          </ReminderManufacturing>
        )}
        <TopSection>
          <InputGroup>
            <CustomInput
              disabled={kit?.status === manufacturing}
              value={versionValue || ''}
              label={'Graphic Revision'}
              name="version"
              onChange={(e) => {
                updateVersionMemo(e.target.value);
                setVersionValue(e.target.value);
              }}
            />
          </InputGroup>
          <Labels>
            <CustomSelect
              customStyles={multiSelectStyles}
              isSearchable={false}
              isClearable={false}
              options={labelsVisibilityOptions.map((x) =>
                x.value === 'onpage' ? { ...x, disabled: !selectedPage } : x,
              )}
              placeholder="Labels..."
              onChange={(option) => setLabelVisibility(option)}
              value={labelVisibility}
            />
          </Labels>
        </TopSection>

        {objects && pages && (
          <LayersList
            {...innerprops}
            updateData={(objects, pages) =>
              updateAssemblyOrder(objects, pages, version)
            }
            disabled={kit?.status === manufacturing}
            pageBreakImagesLoading={pageBreakImagesLoading}
            addPageBreakImage={addPageBreakImage}
            pageBreakImages={pageBreakImages}
            deletePageBreakImage={deletePageBreakImage}
            tray={tray}
            generateNotes={generateNotes}
          />
        )}
      </FancyScroll>
      <BottomActions
        {...innerprops}
        updateAssemblyOrder={updateAssemblyOrder}
        versionValue={versionValue}
        componentAssignments={componentAssignments}
        setRunPdfGeneration={setRunPdfGeneration}
        manufacturing={manufacturing}
        buildDefaultPages={buildDefaultPages}
      />
    </>
  ) : (
    <NoteOptions
      {...innerprops}
      setSelectedNote={setSelectedNote}
      selectedNote={selectedNote}
      updateLayout={updateLayout}
      resetLabel={resetLabelText}
      InputGroup={InputGroup}
      notShownQuantityOfSelectedNote={notShownQuantityOfSelectedNote}
      kitDetails={kitDetails}
    />
  );
};

export default Snapshots;
