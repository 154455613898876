import React, { Component } from 'react';
import {
  MDBTabPane,
  MDBTabContent,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBCardImage,
  MDBModalBody,
  MDBModalFooter,
} from 'mdbreact';
import { connect } from 'react-redux';
import { KITCATALOG_PAGE_UNLOADED, LOGOUT } from '../../constants/actionTypes';
import agent from '../../agent';
import notfound from '../../shared/img/kitsDefaultImage.png';
import Loader from '../../components/loaders/Loader';
import Pagination from '../../components/Pagination/Pagination';
import Modal from '../../components/Modal/Modal';

const mapStateToProps = (state) => ({
  ...state.auth,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onUnload: () => dispatch({ type: KITCATALOG_PAGE_UNLOADED }),
  onClickLogout: () => dispatch({ type: LOGOUT }),
});

let KitArray = {
  kits: [{ description: 'No kits match the components you have starred' }],
  totalPages: 0,
  totalCount: 0,
};

class SavedASKJustLike extends Component {
  constructor(props) {
    super(props);
    this.changeUsername = (ev) => this.props.onChangeUsername(ev.target.value);
    this.changePassword = (ev) => this.props.onChangePassword(ev.target.value);
    this.submitForm = (username, password) => (ev) => {
      ev.preventDefault();
      this.props.onSubmit(username, password);
    };
    this.onUnloadForm = (Categories, ComponentId, PageSize, Page) => (ev) => {
      ev.preventDefault();
      this.props.onUnload(Categories, ComponentId, PageSize, Page);
    };
    this.state = {
      reloadKey: Math.random(),
      kitData: null,
      showComponent: false,
      paginationActive: false,
      isOpen: false,
      currentPage: 1,
      everyPage: 12,
      searchText: null,
      isChecked: false,
      selectedNodeArray: [],
      fromChild: [],
      loading: 'initial',
      upperPageBound: 6,
      lowerPageBound: 0,
      pageBound: 3,
      isPrevBtnActive: 'disabled',
      isNextBtnActive: '',
      justLikeKitData: null,
      likeKitId: null,
      KitId: null,
      currentPagePrevious: 1,
      upperPageBoundPrevious: 6,
      lowerPageBoundPrevious: 0,
      pageBoundPrevious: 3,
      toBeDeleteKitId: null,
      activeItem: '1',
      getCartComponents: null,
      savedKitCustom: null,
      isFavorite: null,
    };
    this.onSearch = this.onSearch.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.kitCatalogDetail = this.kitCatalogDetail.bind(this);
    this.btnDecrementClick = this.btnDecrementClick.bind(this);
    this.btnIncrementClick = this.btnIncrementClick.bind(this);
    this.btnNextClick = this.btnNextClick.bind(this);
    this.btnPrevClick = this.btnPrevClick.bind(this);
    this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);
    this.kitCatalog = this.kitCatalog.bind(this);
    this.btnFirstPage = this.btnFirstPage.bind(this);
    this.btnLastPage = this.btnLastPage.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.kitCatalogKits = this.kitCatalogKits.bind(this);
    this.useAsBase = this.useAsBase.bind(this);
    this.kitmakerform = this.kitmakerform.bind(this);
    this.getSavedKitComponents = this.getSavedKitComponents.bind(this);
    this.handleClickPrevious = this.handleClickPrevious.bind(this);
    this.btnDecrementClickPrevious = this.btnDecrementClickPrevious.bind(this);
    this.btnIncrementClickPrevious = this.btnIncrementClickPrevious.bind(this);
    this.btnNextClickPrevious = this.btnNextClickPrevious.bind(this);
    this.btnPrevClickPrevious = this.btnPrevClickPrevious.bind(this);
    this.setPrevAndNextBtnClassPrevious =
      this.setPrevAndNextBtnClassPrevious.bind(this);
    this.btnFirstPagePrevious = this.btnFirstPagePrevious.bind(this);
    this.btnLastPagePrevious = this.btnLastPagePrevious.bind(this);
  }

  toggleScreen = (tab) => (e) => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
      this.setState({ blue: !this.state.blue });
    }
    agent.CART.getCartCustomKits().then((res) => {
      if (res === null) {
        this.setState({ getCartComponents: KitArray });
      } else {
        this.setState({ getCartComponents: res });
      }
    });
  };
  componentDataWithCustomKit = () => {
    const eachCard = document.querySelectorAll('.onPrd');
    const xx = document.getElementById('mySidenav');

    let stil = window.getComputedStyle(xx).getPropertyValue('display');
    if (stil === 'none') {
    } else {
      eachCard.forEach((element) => {
        element.classList.add('col-xl-4');
        element.classList.remove('col-xl-6');
      });
    }
  };

  customKits() {
    const p = document.querySelectorAll('.onPrd');
    const x = document.getElementById('mySidenav');
    const y = document.getElementsByClassName('ryttray');
    const z = document.getElementById('onTray');

    let stil = window.getComputedStyle(x).getPropertyValue('display');
    if (stil === 'none') {
      x.style.display = 'inline';
      y[0].style.display = 'inline-table';
      y[0].style.right = '0';
      y[0].style.position = 'relative';
      y[0].style.top = '0';
      z.style.flex = '0 0 75%';
      z.style.maxWidth = '75%';
      for (let i = 0; i < p.length; i++) {
        p[i].classList.add('col-xl-4');
      }
    } else {
      x.style.display = 'none';
      y[0].style.right = '0';
      y[0].style.position = 'fixed';
      y[0].style.top = '65px';
      z.style.flex = '0 0 100%';
      z.style.maxWidth = '100%';
      for (let i = 0; i < p.length; i++) {
        p[i].classList.remove('col-xl-4');
      }
    }
  }
  componentWillMount() {
    const token = window.localStorage.getItem('jwt');
    if (token) {
      const urlParamss = new URLSearchParams(window.location.search);
      const baseKitId = urlParamss.get('baseKitId');
      const isFromCart = urlParamss.get('isFavorite');
      this.setState({ isFavorite: isFromCart });
      this.setState({ KitId: baseKitId });
      if (baseKitId > 0) {
        agent.SAVEDASK.savedASKJustLikeKit(
          baseKitId,
          this.state.currentPage - 1,
        ).then((res) => {
          if (res === null) {
            this.setState({ justLikeKitData: KitArray });
          } else {
            if (res.totalCount > 0) {
              this.setState({ justLikeKitData: res });
            } else {
              this.setState({ justLikeKitData: KitArray });
            }
          }
        });
      }

      if (isFromCart > 0) {
        agent.SAVEDASK.cartJustLikeKit(this.state.currentPage - 1).then(
          (res) => {
            if (res === null) {
              this.setState({ justLikeKitData: KitArray });
            } else {
              if (res.totalCount > 0) {
                this.setState({ justLikeKitData: res });
              } else {
                this.setState({ justLikeKitData: KitArray });
              }
            }
          },
        );
      }

      agent.SAVEDASK.savedASk().then((res) => {
        if (res === null) {
          this.setState({ savedKitCustom: KitArray });
        } else {
          this.setState({ savedKitCustom: res.body });
        }
      });
      agent.CART.getCartCustomKits().then((res) => {
        if (res === null) {
          this.setState({ getCartComponents: KitArray });
        } else {
          this.setState({ getCartComponents: res });
        }
      });
    } else {
      this.props.onClickLogout();
    }
  }

  componentDidMount = () => {
    window.onpopstate = () => {
      window.location.reload();
    };
  };

  componentWillUnmount() {
    this.props.onUnload();
  }
  state = {
    isOpen: false,
  };

  defaultData = () => {
    const urlParamss = new URLSearchParams(window.location.search);
    const baseKitId = urlParamss.get('baseKitId');
    const isFromCart = urlParamss.get('isFavorite');
    this.setState({ isFavorite: isFromCart });
    this.setState({ KitId: baseKitId });
    if (baseKitId > 0) {
      agent.SAVEDASK.savedASKJustLikeKit(
        baseKitId,
        this.state.currentPage - 1,
      ).then((res) => {
        if (res === null) {
          this.setState({ justLikeKitData: KitArray });
        } else {
          if (res.totalCount > 0) {
            this.setState({ justLikeKitData: res });
            this.componentDataWithCustomKit();
          } else {
            this.setState({ justLikeKitData: KitArray });
          }
        }
      });
    }

    if (isFromCart > 0) {
      agent.SAVEDASK.cartJustLikeKit(this.state.currentPage - 1).then((res) => {
        if (res === null) {
          this.setState({ justLikeKitData: KitArray });
        } else {
          if (res.totalCount > 0) {
            this.setState({ justLikeKitData: res });
            this.componentDataWithCustomKit();
          } else {
            this.setState({ justLikeKitData: KitArray });
          }
        }
      });
    }
  };

  onClickGridView = () => {
    this.setState({ showComponent: false });
    this.defaultData();
  };
  onClickListView = () => {
    this.setState({ showComponent: true });
    this.defaultData();
  };
  onSearch = () => {
    let searchText = this.state.searchText;
    agent.KITCATLOG.kitcatalog(
      '',
      searchText,
      '',
      1,
      this.state.everyPage,
      this.state.currentPage - 1,
    ).then((res) => {
      if (res.totalCount === 0) {
        this.setState({ kitData: KitArray });
      } else {
        this.setState({ kitData: res });
      }
    });
  };

  toggleCollapse = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      toBeDeleteKitId: null,
      modal: !this.state.modal,
    });
  };
  onClearFilter = () => {
    this.setState({ reloadKey: Math.random() });
  };
  kitCatalogDetail = (kitId) => {
    let path = `/kit`;
    let data = kitId;
    this.props.history.push(path + '/' + data);
    window.location.reload();
  };
  onSelect = (node, ischecked) => {
    if (ischecked) {
      this.state.selectedNodeArray.push(node.id);
      this.setState({ selectedNodeArray: this.state.selectedNodeArray });
      this.renderComponent();
    } else {
      var index = this.state.selectedNodeArray.indexOf(node.id);
      if (index >= 0) {
        this.state.selectedNodeArray.splice(index, 1);
      }
      this.renderComponent();
    }
  };
  renderComponent() {
    if (this.state.selectedNodeArray.length > 0) {
      agent.KITCATLOG.kitcatalog(
        this.state.selectedNodeArray,
        '',
        '',
        '',
        '9',
        '1',
      ).then((res) => {
        if (res.totalCount === 0) {
          this.setState({ kitData: KitArray });
        } else {
          this.setState({ kitData: res });
          this.componentDataWithCustomKit();
        }
      });
    } else {
      agent.KITCATLOG.kitcatalog('', '', '', '', '9', '1').then((res) => {
        if (res.totalCount === 0) {
          this.setState({ kitData: KitArray });
        } else {
          this.setState({ kitData: res });
          this.componentDataWithCustomKit();
        }
      });
    }
  }

  handleClick = (dataAsk) => {
    this.setState({ reloadKey: Math.random(), justLikeKitData: dataAsk });
    this.componentDataWithCustomKit();
  };
  handleNextClick = () => {
    if (this.state.currentPage < this.state.everyPage) {
      this.setState({
        currentPage: this.state.currentPage - 1 + 1,
      });
    }
  };
  handlePreviousClick = () => {
    if (this.state.currentPage > 1)
      this.setState({
        currentPage: this.state.currentPage - 1 - 1,
      });
  };

  btnIncrementClick() {
    this.setState({
      upperPageBound: this.state.upperPageBound + this.state.pageBound,
    });
    this.setState({
      lowerPageBound: this.state.lowerPageBound + this.state.pageBound,
    });
    let listid = this.state.upperPageBound + 1;
    this.setPrevAndNextBtnClass(listid);
    this.loadComponentData();
  }

  setPrevAndNextBtnClass(listid) {
    let totalPage = this.state.currentPage;
    this.setState({ isNextBtnActive: 'disabled' });
    this.setState({ isNextBtnActive: 'disabled' });
    if (totalPage === listid && totalPage > 1) {
      this.setState({ isPrevBtnActive: '' });
    } else if (listid === 1 && totalPage > 1) {
      this.setState({ isNextBtnActive: '' });
    } else if (totalPage > 1) {
      this.setState({ isNextBtnActive: '' });
      this.setState({ isPrevBtnActive: '' });
    }
  }

  btnDecrementClick() {
    this.setState({ upperPageBound: this.state.upperPageBound - 2 });
    this.setState({
      lowerPageBound: this.state.lowerPageBound - this.state.pageBound,
    });
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setPrevAndNextBtnClass(listid);
    this.loadComponentData();
  }

  btnPrevClick() {
    if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
      this.setState({
        upperPageBound: this.state.upperPageBound - this.state.pageBound,
      });
      this.setState({
        lowerPageBound: this.state.lowerPageBound - this.state.pageBound,
      });
    }
    let listid = this.state.currentPage - 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid - 1);
    this.loadComponentData();
  }

  btnNextClick() {
    if (this.state.currentPage + 1 > this.state.upperPageBound) {
      this.setState({
        upperPageBound: this.state.upperPageBound + this.state.pageBound,
      });
      this.setState({
        lowerPageBound: this.state.lowerPageBound + this.state.pageBound,
      });
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
    this.loadComponentData();
  }

  kitCatalog() {
    window.location.reload();
  }

  btnFirstPage() {
    this.setState({ currentPage: Number(1) });
    this.setState({ upperPageBound: Number(6) });
    this.setState({ lowerPageBound: Number(0) });
    this.setPrevAndNextBtnClass(Number(1));
    this.loadComponentData();
  }

  btnLastPage() {
    // if ((this.state.currentPage) > this.state.upperPageBound) {
    //     this.setState({ currentPage: this.state.justLikeKitData.length });
    //     this.setState({ upperPageBound: this.state.everyPage });
    //     this.setState({ lowerPageBound: this.state.currentPage });
    // } else {
    //     this.setState({ currentPage: this.state.justLikeKitData.length });
    //     this.setState({ upperPageBound: this.state.justLikeKitData.length-1 });
    //     this.setState({ lowerPageBound: this.state.justLikeKitData.length - 2 });
    // }
    // this.setPrevAndNextBtnClass(this.state.justLikeKitData.length);
    // this.loadComponentData();
    if (this.state.currentPage > this.state.upperPageBound) {
      this.setState({ currentPage: this.state.justLikeKitData.length });
      this.setState({ upperPageBound: this.state.everyPage });
      this.setState({ lowerPageBound: this.state.currentPage });
    } else {
      this.setState({
        currentPage: Number(
          Math.floor(
            (this.state.justLikeKitData.length + this.state.everyPage - 1) /
              this.state.everyPage,
          ),
        ),
      });
      this.setState({
        upperPageBound: Number(
          Math.floor(
            (this.state.justLikeKitData.length + this.state.everyPage - 1) /
              this.state.everyPage,
          ),
        ),
      });
      this.setState({
        lowerPageBound:
          Number(
            Math.floor(
              (this.state.justLikeKitData.length + this.state.everyPage - 1) /
                this.state.everyPage,
            ),
          ) - 3,
      });
    }
    this.setPrevAndNextBtnClass(this.state.justLikeKitData.length);
    this.loadComponentData();
  }

  loadComponentData = () => {
    agent.SAVEDASK.savedASk().then((res) => {
      if (res === null) {
        this.setState({ justLikeKitData: KitArray });
      } else {
        this.setState({ justLikeKitData: res.body });
      }
    });

    agent.KITMAKER.previouslyOrderedKit().then((res) => {
      if (res.totalCount === 0) {
        this.setState({ kitData: KitArray });
      } else {
        this.setState({ kitData: res });
      }
    });
  };

  //pagination
  handleClickPrevious = (event) => {
    this.setState({
      currentPagePrevious: Number(event.target.id),
      paginationActive: true,
    });
    this.setPrevAndNextBtnClassPrevious(this.state.currentPagePrevious);
    this.loadComponentData();
  };
  handleNextClickPrevious = () => {
    if (this.state.currentPage < this.state.everyPagePrevious) {
      this.setState({
        currentPagePrevious: this.state.currentPagePrevious - 1 + 1,
      });
    }
  };
  handlePreviousClickPrevious = () => {
    if (this.state.currentPagePrevious > 1)
      this.setState({
        currentPage: this.state.currentPagePrevious - 1 - 1,
      });
  };

  btnIncrementClickPrevious() {
    this.setState({
      upperPageBoundPrevious:
        this.state.upperPageBoundPrevious + this.state.pageBoundPrevious,
    });
    this.setState({
      lowerPageBoundPrevious:
        this.state.lowerPageBoundPrevious + this.state.pageBoundPrevious,
    });
    let listidPrevious = this.state.upperPageBoundPrevious + 1;
    this.setPrevAndNextBtnClassPrevious(listidPrevious);
    this.loadComponentData();
  }

  setPrevAndNextBtnClassPrevious(listidPrevious) {
    let totalPagePrevious = this.state.currentPagePrevious;
    this.setState({ isNextBtnActive: 'disabled' });
    this.setState({ isNextBtnActive: 'disabled' });
    if (totalPagePrevious === listidPrevious && totalPagePrevious > 1) {
      this.setState({ isPrevBtnActive: '' });
    } else if (listidPrevious === 1 && totalPagePrevious > 1) {
      this.setState({ isNextBtnActive: '' });
    } else if (totalPagePrevious > 1) {
      this.setState({ isNextBtnActive: '' });
      this.setState({ isPrevBtnActive: '' });
    }
  }

  btnDecrementClickPrevious() {
    this.setState({
      upperPageBoundPrevious:
        this.state.upperPageBoundPrevious - this.state.pageBoundPrevious,
    });
    this.setState({
      lowerPageBoundPrevious:
        this.state.lowerPageBoundPrevious - this.state.pageBoundPrevious,
    });
    let listidPrevious =
      this.state.upperPageBoundPrevious - this.state.pageBoundPrevious;
    this.setPrevAndNextBtnClassPrevious(listidPrevious);
    this.loadComponentData();
  }

  btnPrevClickPrevious() {
    if (
      (this.state.currentPagePrevious - 1) % this.state.pageBoundPrevious ===
      0
    ) {
      this.setState({
        upperPageBoundPrevious:
          this.state.upperPageBoundPrevious - this.state.pageBoundPrevious,
      });
      this.setState({
        lowerPageBoundPrevious:
          this.state.lowerPageBoundPrevious - this.state.pageBoundPrevious,
      });
    }
    let listidPrevious = this.state.currentPagePrevious - 1;
    this.setState({ currentPagePrevious: listidPrevious });
    this.setPrevAndNextBtnClassPrevious(listidPrevious - 1);
    this.loadComponentData();
  }

  btnNextClickPrevious() {
    if (
      this.state.currentPagePrevious + 1 >
      this.state.upperPageBoundPrevious
    ) {
      this.setState({
        upperPageBoundPrevious:
          this.state.upperPageBoundPrevious + this.state.pageBoundPrevious,
      });
      this.setState({
        lowerPageBoundPrevious:
          this.state.lowerPageBoundPrevious + this.state.pageBoundPrevious,
      });
    }

    let listidPrevious = this.state.currentPagePrevious + 1;
    this.setState({ currentPagePrevious: listidPrevious });
    this.setPrevAndNextBtnClassPrevious(this.state.upperPageBoundPrevious);
    this.loadComponentData();
  }

  btnFirstPagePrevious() {
    this.setState({ currentPagePrevious: Number(1) });
    this.setState({ upperPageBoundPrevious: Number(6) });
    this.setState({ lowerPageBoundPrevious: Number(0) });
    this.setPrevAndNextBtnClassPrevious(Number(1));
    this.loadComponentData();
  }

  btnLastPagePrevious() {
    if (this.state.currentPagePrevious > this.state.upperPageBoundPrevious) {
      this.setState({ currentPagePrevious: this.state.justLikeKitData.length });
      this.setState({ upperPageBoundPrevious: this.state.everyPagePrevious });
      this.setState({ lowerPageBoundPrevious: this.state.currentPagePrevious });
    } else {
      this.setState({
        upperPageBoundPrevious: Number(
          Math.floor(
            (this.state.kitData.length + this.state.everyPage - 1) /
              this.state.everyPage,
          ),
        ),
      });
      this.setState({
        lowerPageBoundPrevious:
          Number(
            Math.floor(
              (this.state.kitData.length + this.state.everyPage - 1) /
                this.state.everyPage,
            ),
          ) - 3,
      });
      this.setState({
        currentPagePrevious: Number(
          Math.floor(
            (this.state.kitData.length + this.state.everyPage - 1) /
              this.state.everyPage,
          ),
        ),
      });
    }
    this.setPrevAndNextBtnClassPrevious(this.state.kitData.length);
    this.loadComponentData();
  }

  //pagination end
  handleChange = ({ target }) => {
    this.setState({
      searchText: target.value,
      currentPage: Number(1),
    });
  };

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.onSearch();
    }
  }

  kitCatalogKits = () => {
    let path = `/kits`;
    this.props.history.push(path);
  };

  addDefaultSrc(ev) {
    ev.target.src = notfound;
  }

  useAsBase = (kitId) => {
    let path = '/kitmaker';
    let data = kitId;
    this.props.history.push(path + '/' + data);
  };

  kitmakerpage = (kitId) => {
    let path = '/kitmaker';
    let data = kitId;
    this.props.history.push(path + '/' + data);
  };

  kitmakerform = (data) => {
    let path = '/savedaskcomplete?savedKitId=';
    let name = data.id;
    this.props.history.push(path + name);
  };
  kitmakerformCustomKit = () => {
    let path = '/kitmaker';
    this.props.history.push(path);
    window.location.reload();
  };

  deleteKit = () => {
    agent.KITMAKER.deleteKit(this.state.toBeDeleteKitId).then((res) => {
      this.setState({ modal: !this.state.modal, toBeDeleteKitId: null });
      agent.SAVEDASK.savedASk().then((res) => {
        if (res === null) {
          this.setState({ justLikeKitData: KitArray });
        } else {
          this.setState({ justLikeKitData: res.body });
        }
      });
    });
  };

  toggle = (kitId) => {
    this.setState({
      modal: !this.state.modal,
      toBeDeleteKitId: kitId,
    });
  };

  deleteComponents = (event, component) => {
    agent.CART.deleteCustomKits(component.id).then((res) => {
      agent.CART.getCartCustomKits().then((res) => {
        if (res === null) {
          this.setState({ getCartComponents: KitArray });
        } else {
          this.setState({ getCartComponents: res });
          this.setState({ reloadKey: Math.random() });
        }
      });
    });
  };

  getComponentUseAsBase = () => {
    let path = `/kitmaker`;
    this.props.history.push(path + '?isFavourite=1');
    window.location.reload();
  };
  getSavedKitComponents = (kit) => {
    this.props.history.push(
      '/kitmakercomponents?KitFromBase=' +
        null +
        '&kit=' +
        kit.name +
        '&kitId=' +
        kit.id +
        '&orederedKitId=' +
        null +
        '&isFavourite=1',
    );
  };

  getJustLike = () => {
    let path = `/savedaskjustlike?isFavorite=1`;
    this.props.history.push(path);
    window.location.reload();
  };

  render() {
    if (
      this.state.justLikeKitData === null ||
      this.state.getCartComponents === null ||
      this.state.savedKitCustom === null
    ) {
      return <Loader />;
    } else {
      const isListviewIn = this.state.showComponent;
      const activeStyle = { color: '#61a431' };
      const inactiveStyle = { color: '#959595' };
      var savedCustom = this.state.savedKitCustom;
      var cartComponents = this.state.getCartComponents;
      let savedKitData = null;
      var savedKitCustomData = '';
      var cartComponentsList = null;

      savedKitCustomData = savedCustom.map((kit) => {
        return (
          <div
            id="customkits"
            className="data-list"
            onPointerMove={this.hoverOn}
            onClick={(event) => this.getSavedKitComponents(kit)}
          >
            <p className="kitName anchorColor" id="customKitName">
              {kit.name}
              <span></span>
            </p>
            <a href="#no" className="kitCount" id="customKitId">
              Components Count: {kit.componentsCount}
            </a>
            <br />
          </div>
        );
      });

      cartComponentsList = cartComponents.map((cartComponent) => {
        return (
          <div id="cartCustom" className="data-list">
            <i
              className="fa fa-window-close"
              aria-hidden="true"
              onClick={(event) => this.deleteComponents(event, cartComponent)}
            ></i>
            <a href="#no" className="kitCount" id="customKitId">
              <p>SKU: {cartComponent.number}</p>
            </a>
            <p className="kitName anchorColor" id="customKitName">
              {cartComponent.description}
              <span></span>
            </p>
          </div>
        );
      });

      if (isListviewIn === false && this.state.justLikeKitData.totalCount > 0) {
        savedKitData = this.state.justLikeKitData.kits.map((dataa) => {
          return (
            <MDBCol xl="3" lg="6" md="12" id={dataa.id} className="onPrd">
              <MDBCard className="product-area prdbrdr">
                <a href={'/kit/' + dataa.id}>
                  <MDBCardImage
                    className="img-fluid"
                    src={dataa.imageUrl === null ? notfound : dataa.imageUrl}
                    onClick={() => this.kitCatalogDetail(dataa.id)}
                    onError={this.addDefaultSrc}
                    alt=""
                  />
                </a>
                <MDBCardBody>
                  <span>{dataa.componentsCount} Components</span>
                  <MDBCardTitle>
                    <a href={'/kit/' + dataa.id}>{dataa.number}</a>
                  </MDBCardTitle>
                  <MDBCardText>
                    <a href={'/kit/' + dataa.id} className="anchorColor">
                      {dataa.description}
                    </a>
                  </MDBCardText>
                  <MDBBtn onClick={() => this.kitCatalogDetail(dataa.id)}>
                    KIT DETAILS
                  </MDBBtn>
                  <MDBBtn
                    className="gbtn"
                    onClick={() => this.useAsBase(dataa.id)}
                  >
                    <i className="fa fa-shopping-basket" aria-hidden="true"></i>
                    USE AS A BASE
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          );
        });
      } else if (
        isListviewIn === true &&
        this.state.justLikeKitData.totalCount > 0
      ) {
        savedKitData = this.state.justLikeKitData.kits.map((kit) => {
          return (
            <MDBCol lg="12">
              <MDBCard className="product-area product-area-list prdbrdr">
                <MDBRow>
                  <MDBCol lg="3">
                    <a href={'/kit/' + kit.id}>
                      {' '}
                      <MDBCardImage
                        className="img-fluid"
                        src={kit.imageUrl === null ? notfound : kit.imageUrl}
                        onClick={() => this.kitCatalogDetail(kit.id)}
                        onError={this.addDefaultSrc}
                        alt=""
                      />
                    </a>
                  </MDBCol>
                  <MDBCol lg="6">
                    <MDBCardBody>
                      <span>{kit.componentsCount} Component</span>
                      <MDBCardTitle>{kit.number}</MDBCardTitle>
                      <MDBCardText>
                        <a href={'/kit/' + kit.id} className="anchorColor">
                          {kit.description}
                        </a>
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCol>
                  <MDBCol lg="3">
                    <div className="list-btn-area floatright">
                      <MDBBtn onClick={() => this.kitCatalogDetail(kit.id)}>
                        KIT DETAILS
                      </MDBBtn>
                      <br />
                      <MDBBtn
                        className="gbtn"
                        onClick={() => this.useAsBase(kit.id)}
                      >
                        <i
                          className="fa fa-shopping-basket"
                          aria-hidden="true"
                        ></i>
                        USE AS A BASE
                      </MDBBtn>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>
          );
        });
      } else {
        savedKitData =
          this.state.isFavorite > 0 ? (
            <MDBCol xl="12" lg="12" md="12" className="text-center">
              <p>No kits match the components you have starred.</p>
            </MDBCol>
          ) : (
            <MDBCol xl="12" lg="12" md="12" className="text-center">
              <p>No results found.</p>
            </MDBCol>
          );
      }

      return (
        <div className="main-layout">
          <div className="content-area mb4">
            <MDBContainer fluid>
              <MDBRow>
                <MDBCol
                  id="onTray"
                  lg="12"
                  md="12"
                  sm="12"
                  xs="12"
                  className="mt20-xs pl15-xs"
                >
                  <h5>Saved ASK</h5>
                  <MDBRow>
                    <MDBCol md="12">
                      <h6 className="mt-5 mt-sm-5 mt-lg-0 mt-md-0 pagehead">
                        <p className="mb-0">
                          <i
                            className="fa fa-th-large"
                            aria-hidden="true"
                            onClick={this.onClickGridView}
                            style={
                              this.state.showComponent === false
                                ? activeStyle
                                : inactiveStyle
                            }
                            key={Math.random()}
                          ></i>
                          <i
                            className="fa fa-bars"
                            aria-hidden="true"
                            onClick={this.onClickListView}
                            style={
                              this.state.showComponent === true
                                ? activeStyle
                                : inactiveStyle
                            }
                            key={Math.random()}
                          ></i>
                        </p>
                      </h6>
                    </MDBCol>
                    {savedKitData}
                  </MDBRow>
                  <MDBRow>
                    <MDBCol className="text-center">
                      <Pagination
                        pageData={this.handleClick}
                        parent={'SaveAskJustKit'}
                        key={this.state.pageReload}
                      />
                    </MDBCol>
                  </MDBRow>
                  {/* <MDBRow>
                                            <MDBCol className="text-center">
                                                {pager}
                                            </MDBCol>
                                        </MDBRow>

                                        <MDBRow>
                                            <MDBCol className="text-center">
                                                {pagerPrevious}
                                            </MDBCol>
                                        </MDBRow> */}
                </MDBCol>
                <MDBCol
                  lg="3"
                  md="6"
                  sm="6"
                  xs="12"
                  className="mt20-xs pl-lg-0 pl-xl-0"
                >
                  <div className="rytsidetray">
                    <i
                      className="fa fa-shopping-basket ryttray"
                      aria-hidden="true"
                      onClick={this.customKits}
                    ></i>
                    <div id="mySidenav" className="sidetray kitmaker pt-2 ">
                      <div id="authentication-layout">
                        <div className="btn-section mtb2 tab-area mt-0">
                          <MDBBtn
                            className="m-0 mb20-xs btn-whitee"
                            active={this.state.activeItem === '1'}
                            onClick={this.toggleScreen('1')}
                          >
                            Custom kits
                          </MDBBtn>
                          <MDBBtn
                            className="m-0  mb20-xs btn-outline-indigo notwhite"
                            active={this.state.activeItem === '2'}
                            onClick={this.toggleScreen('2')}
                          >
                            {' '}
                            Components
                          </MDBBtn>
                        </div>
                        <MDBTabContent activeItem={this.state.activeItem}>
                          <MDBTabPane tabId="1" role="tabpanel">
                            <div className="tbl-scrl">{savedKitCustomData}</div>

                            <br />
                            <MDBBtn
                              className="fullgbtn"
                              disabled={
                                this.state.savedKitCustom.length === 0
                                  ? true
                                  : false
                              }
                              onClick={() => this.kitmakerformCustomKit()}
                            >
                              CREATE NEW KIT
                            </MDBBtn>
                          </MDBTabPane>
                          <MDBTabPane tabId="2" role="tabpanel">
                            <div className="tbl-scrl">
                              {this.state.getCartComponents.length === 0 ? (
                                <p>No starred component available right now</p>
                              ) : (
                                cartComponentsList
                              )}
                            </div>
                            <MDBBtn
                              className="fullgbtn"
                              disabled={
                                this.state.getCartComponents.length === 0
                                  ? true
                                  : false
                              }
                              onClick={() => this.getComponentUseAsBase()}
                            >
                              CREATE NEW KIT
                            </MDBBtn>
                            <MDBBtn
                              className="fullgbtn"
                              disabled={
                                this.state.getCartComponents.length === 0
                                  ? true
                                  : false
                              }
                              onClick={() => this.getJustLike()}
                            >
                              JUST LIKE
                            </MDBBtn>
                          </MDBTabPane>
                        </MDBTabContent>
                      </div>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>

          {this.state.modal && (
            <Modal close={this.toggle} size="sm">
              <MDBModalBody>
                <MDBRow>
                  <MDBCol lg="12" md="12" sm="12" xs="12" className="mb-1">
                    <MDBBtn
                      type="button"
                      className="close"
                      onClick={this.toggleCollapse}
                      data-dismiss="modal"
                    >
                      &times;
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
                <strong>
                  <span className="bottom-info warncolor">
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                  </span>{' '}
                  Are you sure you want to delete the customized kit.
                </strong>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="grey" size="sm" onClick={this.toggleCollapse}>
                  Close
                </MDBBtn>
                <MDBBtn className="ybtn" size="sm" onClick={this.deleteKit}>
                  Delete
                </MDBBtn>
              </MDBModalFooter>
            </Modal>
          )}
        </div>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SavedASKJustLike);