import React, { Component } from 'react';
import { MDBPagination, MDBPageItem, MDBPageNav } from "mdbreact";
import agent from '../../agent';
import Loader from "../../components/loaders/Loader";

let KitArray = {
    kits: [{ description: "No results found" }],
    totalPages: 0,
    totalCount: 0
}

let storedNode, storedNodeId, storedPartNode, storedPartNodeId, storedNodePopular, storedNodeIdPopular, parttype = " ";
let isSearch = true;

class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageData: null,
            paginationActive: false,
            currentPage: 1,
            everyPage: 12,
            searchText: null,
            upperPageBound: 6,
            lowerPageBound: 0,
            pageBound: 3,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            parentIs: "componentcatalog"

        }
    }



    getStoredData(pageCategory) {
        let storedComponentNode = (window.sessionStorage.getItem(pageCategory));
        if (storedComponentNode && storedComponentNode !== " ") {
            storedNode = JSON.parse(window.sessionStorage.getItem(pageCategory));
            storedNodeId = storedNode && storedNode !== "null" && storedNode !== " " && storedNode != null ? storedNode.id : "";
        } else {
            storedNode = "";
            storedNodeId = "";
        }
    }
    getStoredDataPopular(pageCategorypopular) {
        let storedComponentNodePopular = (window.sessionStorage.getItem(pageCategorypopular));
        if (storedComponentNodePopular && storedComponentNodePopular !== " ") {
            storedNodePopular = JSON.parse(window.sessionStorage.getItem(pageCategorypopular));
            storedNodeIdPopular = storedNodePopular && storedNodePopular !== "null" && storedNodePopular !== " " && storedNodePopular != null ? storedNodePopular.id : "";
        } else {
            storedNodePopular = "";
            storedNodeIdPopular = "";
        }
    }


    componentWillMount() {
        const token = window.localStorage.getItem('jwt');
        if (token) {
            switch (this.props.parent) {
                case "kitcatalog":
                    this.setState({ parentIs: "kitcatalog" });
                    this.getStoredData("kitCategory");
                    this.setState({ pageData: KitArray });
                    let componetid = '';
                    componetid = this.props.componentId ? this.props.componentId : '';
                    agent.KITCATLOG.kitcatalog(storedNodeId, this.props.searchText, componetid, '', this.state.everyPage, this.state.currentPage - 1).then((res) => {
                        if (res.totalCount === 0) {
                            this.setState({ pageData: KitArray });
                        }
                        else {
                            this.setState({ pageData: res });
                        }
                    }
                    )
                    break;
                case "componentcatalog":
                    this.setState({ parentIs: "componentcatalog" });
                    this.getStoredData("ComponentCategory");
                    this.getStoredDataPopular("PopularCategory");
                    storedPartNode = window.sessionStorage.getItem("PartType");
                    storedPartNodeId = storedPartNode && storedPartNode !== null && storedPartNode !== "" && storedPartNode !== " " && storedPartNode !== "null" ? JSON.parse(storedPartNode).id : "";

                    if (storedNodeIdPopular) {
                        parttype = storedNodeIdPopular
                    } else if (storedPartNodeId) {
                        parttype = storedPartNodeId
                    }
                    else {
                        parttype = ""
                    }
                    this.setState({ pageData: KitArray });
                    agent.Home.components(storedNodeId, parttype, this.props.searchText, this.state.everyPage, this.state.currentPage - 1).then((res) => {
                        if (res.totalCount === 0) {
                            this.setState({ pageData: KitArray });
                        } else {
                            this.setState({ pageData: res });
                        }
                    })
                    break;
                case "kitmakercomponentjustlike":
                    this.setState({ parentIs: "kitmakercomponentjustlike" });
                    agent.SAVEDASK.savedASKJustLikeKit(this.props.kitId, this.state.currentPage - 1).then((res) => {
                        if (res === null) {
                            this.setState({ pageData: KitArray });
                        }
                        else {
                            if (res.totalCount > 0) {
                                this.setState({ pageData: res });
                            }
                            else {
                                this.setState({ pageData: KitArray });
                            }
                        }
                    }
                    )
                    break;
                case "kitmakercomponent":
                    this.setState({ parentIs: "kitmakercomponent" });
                    this.getStoredData("kitCatalogaddcomponents");
                    this.getStoredDataPopular("PopularCategoryKit");

                    agent.Home.components(storedNodeId, storedNodeIdPopular, this.props.searchText, this.state.everyPage, this.state.currentPage - 1).then((res) => {
                        if (res.totalCount === 0) {
                            this.setState({ pageData: KitArray });
                        }
                        else {
                            this.setState({ pageData: res });
                        }
                    })
                    break;
                case "kitmakercomponentExisting":
                    this.setState({ parentIs: "kitmakercomponentExisting" });
                    this.getStoredData("kitCatalogaddcomponents");
                    agent.KITCATLOG.kitcatalog(storedNodeId, this.props.searchText, '', '', this.state.everyPage, this.state.currentPage - 1).then((res) => {
                        if (res.totalCount === 0) {
                            this.setState({ pageData: KitArray });
                        }
                        else {
                            this.setState({ pageData: res });
                        }
                    })
                    break;

                case "JustLikeKit":
                    this.setState({ parentIs: "JustLikeKit" });
                    agent.KITCATLOG.kitcatalog(storedNodeId, this.props.searchText, '', this.props.likeKitId, this.state.everyPage, this.state.currentPage - 1).then((res) => {
                        if (res.totalCount === 0) {
                            this.setState({ pageData: KitArray });
                        }
                        else {
                            this.setState({ pageData: res });
                        }
                    })
                    break;

                case "SaveAskJustKit":
                    this.setState({ parentIs: "SaveAskJustKit" });
                    agent.SAVEDASK.cartJustLikeKit(this.state.currentPage - 1).then((res) => {
                        if (res === null) {
                            this.setState({ pageData: KitArray });
                        }
                        else {
                            this.setState({ pageData: res });

                        }
                    })
                    break;


                default:
                    break;
            }
        }
        else {
            this.props.onClickLogout()
        }
    }


    handleClick = (event) => {
        this.setState({
            currentPage: Number(event.target.id), paginationActive: true
        });
        this.setPrevAndNextBtnClass(this.state.currentPage);
        this.loadComponentData(this.state.everyPage, Number(event.target.id) - 1);
    }

    handleNextClick = () => {
        if (this.state.currentPage < this.state.pageData.totalCount / this.state.everyPage) {
            this.setState({
                currentPage: (this.state.currentPage - 1) + 1
            });
        }
    }

    handlePreviousClick = () => {
        if (this.state.currentPage > 1)
            this.setState({
                currentPage: (this.state.currentPage - 1) - 1,
            });
    }

    btnIncrementClick = () => {
        this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
        this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
        let listid = this.state.upperPageBound + 1;
        this.setState({ currentPage: listid });
        this.setPrevAndNextBtnClass(listid);
        //let search = this.state.searchText === null ? " " : this.state.searchText;
        this.loadComponentData(this.state.everyPage, listid);
    }

    setPrevAndNextBtnClass = (listid) => {
        let totalPage = 0;
        if (this.state.parentIs === "kitcatalog") {
            totalPage = Math.ceil(this.state.pageData.kits.length / this.state.everyPage);
        } else if (this.state.parentIs === "componentcatalog") {
            totalPage = Math.ceil(this.state.pageData.components.length / this.state.everyPage);
        }

        this.setState({ isNextBtnActive: 'disabled' });
        this.setState({ isNextBtnActive: 'disabled' });
        if (totalPage === listid && totalPage > 1) {
            this.setState({ isPrevBtnActive: '' });
        }
        else if (listid === 1 && totalPage > 1) {
            this.setState({ isNextBtnActive: '' });
        }
        else if (totalPage > 1) {
            this.setState({ isNextBtnActive: '' });
            this.setState({ isPrevBtnActive: '' });
        }
    }

    btnDecrementClick = () => {
        this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
        this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
        let listid = this.state.upperPageBound - this.state.pageBound;
        this.setState({ currentPage: listid + 1 });
        this.setPrevAndNextBtnClass(listid + 1);
        //let search = this.state.searchText === null ? " " : this.state.searchText;
        this.loadComponentData(this.state.everyPage, listid);
    }

    btnPrevClick = () => {
        if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
            this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
            this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
        }
        let listid = this.state.currentPage - 1;
        this.setState({ currentPage: listid });
        this.setPrevAndNextBtnClass(listid - 1);
        //let search = this.state.searchText === null ? " " : this.state.searchText;
        this.loadComponentData(this.state.everyPage, listid - 1);
    }
    btnNextClick = () => {
        if ((this.state.currentPage + 1) > this.state.upperPageBound) {
            this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
            this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
        }
        let listid = this.state.currentPage + 1;
        this.setState({ currentPage: listid });
        this.setPrevAndNextBtnClass(listid);
        //let search = this.state.searchText === null ? " " : this.state.searchText;
        this.loadComponentData(this.state.everyPage, this.state.currentPage);
    }

    btnFirstPage = () => {
        this.setState({ currentPage: Number(1) });
        this.setState({ upperPageBound: Number(6) });
        this.setState({ lowerPageBound: Number(0) });
        this.setPrevAndNextBtnClass(Number(1));
        this.loadComponentData(this.state.everyPage, Number(0));
    }
    btnLastPage = () => {
        if ((this.state.pageData.totalPages) > this.state.upperPageBound) {
            this.setState({ currentPage: this.state.pageData.totalPages });
            this.setState({ upperPageBound: this.state.pageData.totalPages });
            this.setState({ lowerPageBound: this.state.pageData.totalPages - this.state.pageBound });
        } else {
            this.setState({ currentPage: this.state.pageData.totalPages });
            this.setState({ upperPageBound: this.state.pageData.totalPages });
            this.setState({ lowerPageBound: this.state.pageData.totalPages - this.state.pageBound });
        }

        this.setPrevAndNextBtnClass(this.state.pageData.totalPages);
        this.loadComponentData(this.state.everyPage, this.state.pageData.totalPages - 1);
    }

    loadComponentData = (pageSize, page) => {
        const { pageData } = this.props;
        let category = storedNodeId;
        if (this.state.parentIs === "kitcatalog") {
            let componetid = '';
            componetid = this.props.componentId ? this.props.componentId : '';
            agent.KITCATLOG.kitcatalog(category, this.props.searchText, componetid, '', pageSize, page).then((res) => {
                if (res.totalCount === 0) {
                    this.setState({ pageData: KitArray });
                }
                else {
                    this.setState({ pageData: res });
                    pageData(res);
                }
            })
        } else if (this.state.parentIs === "componentcatalog") {
            isSearch = false;
            if (storedNodeIdPopular) {
                parttype = storedNodeIdPopular
            } else if (storedPartNodeId) {
                parttype = storedPartNodeId
            }
            else {
                parttype = ""
            }

            this.setState({ pageData: null });
            agent.Home.components(category, parttype, this.props.searchText, this.state.everyPage, page).then((res) => {
                if (res.totalCount === 0) {
                    this.setState({ pageData: KitArray });
                } else {
                    this.setState({ pageData: res });
                    pageData(res);
                }
            })
        }
        else if (this.state.parentIs === "kitmakercomponentjustlike") {
            agent.SAVEDASK.savedASKJustLikeKit(this.props.kitId, page).then((res) => {
                if (res === null) {
                    this.setState({ pageData: KitArray });
                }
                else {
                    if (res.totalCount > 0) {
                        this.setState({ pageData: res });
                        pageData(res);
                    }
                    else {
                        this.setState({ pageData: KitArray });
                    }
                }
            }
            )
        }

        else if (this.state.parentIs === "kitmakercomponent") {
            agent.Home.components(storedNodeId, '', this.props.searchText, this.state.everyPage, page).then((res) => {
                if (res.totalCount === 0) {
                    this.setState({ pageData: KitArray });
                }
                else {
                    this.setState({ pageData: res });
                    pageData(res);
                }
            })
        }
        else if (this.state.parentIs === "kitmakercomponentExisting") {
            agent.KITCATLOG.kitcatalog(storedNodeId, this.props.searchText, '', this.props.kitId, this.state.everyPage, this.state.currentPage - 1).then((res) => {
                if (res.totalCount === 0) {
                    this.setState({ pageData: KitArray });
                }
                else {
                    this.setState({ pageData: res });
                    pageData(res);
                }
            })
        }
        else if (this.state.parentIs === "JustLikeKit") {
            agent.KITCATLOG.kitcatalog(storedNodeId, this.props.searchText, '', this.props.likeKitId, this.state.everyPage, this.state.currentPage - 1).then((res) => {
                if (res.totalCount === 0) {
                    this.setState({ pageData: KitArray });
                }
                else {
                    this.setState({ pageData: res });
                    pageData(res);
                }
            })
        }

        else if (this.state.parentIs === "SaveAskJustKit") {
            agent.SAVEDASK.cartJustLikeKit(page).then((res) => {
                if (res.totalCount === 0) {
                    this.setState({ pageData: KitArray });
                }
                else {
                    this.setState({ pageData: res });
                    pageData(res);

                }
            })
        }
    };

    // componentDidMount()
    // {
    //     
    //     if(this.props.isSearch)
    //     window.location.reload();
    // }
    //componentDidUpdate() {
    //  
    //window.location.reload();
    // }
    // handleChange = ({ target }) => {
    //     this.setState({
    //         searchText: target.value,
    //         currentPage: Number(1),

    //     });

    // }

    componentDidUpdate() {
        if (this.props.isSearch) {
            if (isSearch) {
                this.loadComponentData(this.state.everyPage, this.state.currentPage - 1);
            } else {
                isSearch = true;
            }
        }
    }

    render() {
        if (this.state.pageData === null) {
            return <p></p>
        } else {
            if (this.state.pageData !== null) {
                var iitem = "";
                const pageNumbers = [];
                const activePageStyle = { background: "#1d265b", color: "#ffffff", padding: "0.4rem 0.75rem" };
                const inactivePageStyle = { background: "#838c8c" };
                for (let i = 1; i <= this.state.pageData.totalPages; i++) {
                    pageNumbers.push(i);
                }
                const renderPageNumbers = pageNumbers.map(number => {
                    iitem = <MDBPageItem active key={"pagination" + number}>
                        <MDBPageNav className="page-link" style={this.state.currentPage === number ? activePageStyle : inactivePageStyle}
                            key={number}
                            id={number}
                            onClick={this.handleClick}>
                            {number}
                        </MDBPageNav>
                    </MDBPageItem>;
                    return (number < this.state.upperPageBound + 1) && number > this.state.lowerPageBound ? iitem : null;
                });
                let pageIncrementBtn = null;

                var increment = <MDBPageItem>
                    <MDBPageNav className="page-link"

                        onClick={this.btnIncrementClick}>
                        ....
    </MDBPageNav>
                </MDBPageItem>
                pageIncrementBtn = this.state.upperPageBound >= this.state.pageData.totalPages ? null : increment;
                let pageDecrementBtn = null;
                if (this.state.lowerPageBound >= 1) {
                    pageDecrementBtn = <MDBPageItem>
                        <MDBPageNav className="page-link"

                            onClick={this.btnDecrementClick}>
                            ....
</MDBPageNav>
                    </MDBPageItem>
                }

                let renderPrevBtn = null;
                if (this.state.currentPage === 1) {
                    renderPrevBtn = <MDBPageItem>
                        <MDBPageNav className="page-link" >

                            <span id="btnPrev">  </span>
                        </MDBPageNav>
                    </MDBPageItem>
                }
                else {
                    renderPrevBtn = <MDBPageItem>
                        <MDBPageNav className="page-link"
                            onClick={this.btnPrevClick}
                        >
                            <span id="btnPrev"> Prev </span>
                        </MDBPageNav>
                    </MDBPageItem>
                }
                let renderNextBtn = null;

                var next = <MDBPageItem>
                    <MDBPageNav className="page-link" onClick={this.btnNextClick}>
                        <span id="btnNext"> Next </span>
                    </MDBPageNav>
                </MDBPageItem>


                renderNextBtn = (this.state.currentPage) === this.state.pageData.totalPages ? null : next;
                let renderFirstpage = null;
                if (this.state.currentPage === 1) {
                    renderFirstpage = <MDBPageItem onClick={this.btnFirstPage} >
                        <MDBPageNav className="page-link" aria-label="Previous">


                        </MDBPageNav>
                    </MDBPageItem>
                } else {
                    renderFirstpage = <MDBPageItem onClick={this.btnFirstPage} >
                        <MDBPageNav className="page-link" aria-label="Previous">
                            <span>First</span>

                        </MDBPageNav>
                    </MDBPageItem>
                }

                let renderLastPage = null;

                var last = <MDBPageItem >

                    <MDBPageNav className="page-link"

                        onClick={this.btnLastPage}>
                        <span>Last</span>
                    </MDBPageNav>
                </MDBPageItem>
                renderLastPage = this.state.pageData.totalPages === this.state.currentPage ? null : last;

                return (
                    (this.state.pageData.totalCount > this.state.everyPage) ?
                        <MDBPagination circle>
                            {renderFirstpage}
                            {renderPrevBtn}
                            {pageDecrementBtn}
                            {renderPageNumbers}
                            {pageIncrementBtn}
                            {renderNextBtn}
                            {renderLastPage}
                        </MDBPagination> : null

                )
            }
            else {
                return <Loader />
            }

        }
    }
}


export default Pagination;