import styled from '@emotion/styled';
import React from 'react';

const Type = styled.span(
  ({ color }) => `
  background: ${color || 'gray'};
  padding: 5px 20px;
  margin-right: 10px;
  font-size: 12px;
  border-radius: 15px;
  font-weight: 600;
  color: white;
  cursor: pointer;
`,
);

export default function TypeFilters(props) {
  const { setLogLevels, logLevels, typeColors } = props;

  const handleLogLevels = (level) => {
    setLogLevels((prevLevels) => {
      if (prevLevels.includes(level)) {
        return prevLevels.filter((prevLevel) => prevLevel !== level);
      }

      return [...prevLevels, level];
    });
  };

  const getColor = (type) => {
    return logLevels.includes(type) ? typeColors[type] : null;
  };

  return (
    <div style={{ marginBottom: 10, width: '70%' }}>
      <Type onClick={() => handleLogLevels(1)} color={getColor(1)}>
        Warning
      </Type>
      <Type onClick={() => handleLogLevels(2)} color={getColor(2)}>
        Error{' '}
      </Type>
      <Type onClick={() => handleLogLevels(3)} color={getColor(3)}>
        Fatal{' '}
      </Type>
    </div>
  );
}
