import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  gap: 0.75rem;
  align-self: baseline;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #f9f9f9;
`;

const IconToggleGroup = ({ children }) => {
  return <Container>{children}</Container>;
};

export default IconToggleGroup;
