import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Draggable } from "react-beautiful-dnd";
import { faCamera, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import {
  addPageBreakImage,
  deletePageBreakImage,
} from "../../../../../redux/actions/kits";
import ImageTooltipContent from "../../../../NewKitMaker/Components/ImageTooltipContent";
import { useCallback } from "react";
import { showReminder } from "../../../../../redux/actions/snackbar";

const Wrapper = styled.div`
  .__react_component_tooltip.place-right::after {
    margin-top: ${(props) => -props.tooltipOffset - 4}px !important;
  }
  .__react_component_tooltip.place-right {
    margin-top: ${(props) => props.tooltipOffset}px !important;
  }
  .__react_component_tooltip {
    padding: 0px;
  }
  .__react_component_tooltip.show {
    margin-left: 10px !important;
  }
`;

const Contaner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
`;

const LineContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 10px;
  }
  svg:hover {
    color: red;
    cursor: pointer;
  }
`;

const Line = styled.div`
  height: 0px;
  border-bottom: 2px solid white;
  width: 100%;
`;

const Title = styled.span`
  font-size: 12px;
  font-weight: 600;
  text-align: right;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > svg {
    cursor: pointer;
    opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  }
  & > span {
    cursor: pointer;
  }
`;

const PageDnD = ({
  page,
  getItemStyle,
  i,
  removeDefaultPage,
  papesQuantity,
  setAssemblyState,
  setSelectedPage,
  selectedPage,
  pageBreakImages,
  pageBreakImagesLoading,
  position,
  setPosition,
}) => {
  const dispatch = useDispatch();
  const tooltipRef = useRef(null);
  const { reminder } = useSelector((state) => state.snackbar);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [pageBreakImagesLoading, pageBreakImages, position]);

  const onImageSelect = useCallback(
    (image) => {
      if (!reminder) {
        dispatch(showReminder());
      }
      const imageId = selectedPage?.image === image.id ? null : image.id;
      setSelectedPage({
        ...selectedPage,
        image: imageId,
      });

      setAssemblyState((prev) =>
        prev.map((cavity) => {
          if (selectedPage.id === cavity.pageId) {
            return {
              ...cavity,
              pageBreakImageId: imageId,
            };
          }

          if (selectedPage.id === cavity.id && !cavity.points) {
            return {
              ...cavity,
              image: imageId,
            };
          }

          return cavity;
        })
      );
    },
    [setAssemblyState, selectedPage, setSelectedPage, dispatch, reminder]
  );

  if (!page.id && page.id !== 0) {
    return null;
  }

  const calculateTooltipPosition = (event) => {
    const margin = window.innerHeight / 2 - event.clientY;
    const halfTooltipHight = 280;
    const buttonsOffset = 80; // title & buttons

    setPosition(margin < -halfTooltipHight ? margin + buttonsOffset : margin);
  };

  return (
    <Wrapper tooltipOffset={position || 50}>
      <Draggable
        draggableId={page.id.toString()}
        index={i}
        isDragDisabled={!page.id}
      >
        {(provided, snapshot) => (
          <Contaner
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <Title isActive={page.image !== null}>
              <FontAwesomeIcon
                data-tip
                data-for="ImageTip"
                data-event="click"
                icon={faCamera}
                onClick={(e) => {
                  calculateTooltipPosition(e);
                  setSelectedPage(page.id === selectedPage?.id ? null : page);
                }}
              />
              <span
                onClick={() =>
                  setSelectedPage(page.id === selectedPage?.id ? null : page)
                }
              >
                Page {papesQuantity - page.id}
                {selectedPage?.id === page.id && " (selected)"}
              </span>
            </Title>
            <LineContainer>
              <Line />
              {!!page.id && (
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() => removeDefaultPage(page.id)}
                />
              )}
            </LineContainer>
          </Contaner>
        )}
      </Draggable>
      <ReactTooltip
        ref={tooltipRef}
        id="ImageTip"
        isCapture
        clickable
        place="right"
        effect="solid"
        type="light"
        scrollHide={false}
        resizeHide={false}
      >
        <ImageTooltipContent
          pageBreakImages={pageBreakImages}
          pageBreakImagesLoading={pageBreakImagesLoading}
          addPageBreakImage={(file, name, update) =>
            dispatch(addPageBreakImage(file, name, update))
          }
          deletePageBreakImage={(id) => dispatch(deletePageBreakImage(id))}
          onImageSelect={onImageSelect}
          selectedPage={selectedPage}
          hide={() => {
            tooltipRef.current.tooltipRef = null;
            ReactTooltip.hide();
            setSelectedPage(null);
          }}
        />
      </ReactTooltip>
    </Wrapper>
  );
};

export default PageDnD;
