import styled from '@emotion/styled';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import TooltipContent from '../Cavity/TooltipContent';
import { useDispatch } from 'react-redux';
import { clearComponentTooltipInfo, updateTrayCavity } from '../../../../redux/actions/kitTrays';
import FancyScroll from '../../../../components/FancyScroll';
import CustomButton from '../../../../components/Buttons/CustomButton';
import { hideReminder } from '../../../../redux/actions/snackbar';
import { deleteCmsComponentImage } from '../../../../redux/actions/components';

const Container = styled.div`
    display: flex;
    padding: 0px 10px;
    flex-direction: column;
`;

const Description = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    & > span:first-of-type {
        font-size: 10px;
        font-weight: 400;
    }
`;

const SubmitSection = styled.div`
    display: flex;
    justify-content: center;
    width: calc(100% - 20px);
    font-size: 12px;
    bottom: 80px;
    position: absolute;
`;

const Type = styled.div`
    display: flex;
    justify-content: space-between;
`;

const getItemStyle = (isDragging, draggableStyle, background) => ({
    ...draggableStyle,
    userSelect: 'none',
    padding: 8,
    margin: `0 0 ${8}px 0`,
    background: background || 'white',
    opacity: isDragging ? .7 : 1,
    color: 'black',
    fontWeight: 500,
    fontSize: 12
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(_.cloneDeep(list));
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    let count = result.length;
    return result.map((item) => ({ ...item, assemblyOrder: count-- }));
};

const FloatingOrderList = ({ list, removeItem, setOptions, options, componentPlacement, setComponentPlacement, setList, onCavityClick, cavities, tray }) => {
    const [component, setComponent] = useState(null);
    const [uploading, setUploading] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
      return () => {
        afterHide();
      };
      // eslint-disable-next-line
    }, []);

    useLayoutEffect(() => {
        ReactTooltip.rebuild();
    }, [list]);

    const onDragEnd = ({ destination, source }) => {
        afterHide();
        if (!destination) return;

        const res = reorder(list, source.index, destination.index);
        setList(res);
    };

    const onItemClick = (item) => {
        if (!isOpen) setComponent(null);
        else {
            setComponent(item);
            onCavityClick(cavities[0], false);
        }
    };

    const afterHide = () => {
        ref.current.tooltipRef = null;
        ReactTooltip.hide();
        setIsOpen(false);
        setUploading(null);
        setComponent(null)
        isOpen && onCavityClick(null);
        setComponentPlacement([{ image: null, values: { r: 0, x: 0, y: 0 }, priority: 0 }]);
        dispatch(clearComponentTooltipInfo());
    };

    const onSubmit = () => {
        const cavity = {
            ...cavities[0],
            categories: options.allowed.map(o => ({ ...o, assemblyOrder: list.find(l => l.id === o.id).assemblyOrder })),
            componentRules: {
                allowed: options.singleAllowed.map(o => ({ ...o, assemblyOrder: list.find(l => l.id === o.id).assemblyOrder })),
                restricted: []
            }
        };

        dispatch(updateTrayCavity(tray.id, cavity));
        dispatch(hideReminder());
        afterHide();
    };

    const onItemDelete = (e, item) => {
        e.stopPropagation();
        removeItem(item);
        ReactTooltip.hide();
    };

    const removeImage = ({ componentId, imageId }) => {
        dispatch(deleteCmsComponentImage(componentId, imageId, 'floatingComponent'));
    };

    return (
        <>
            <FancyScroll offset={260}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='droppable'>
                        {(provided) => <Container {...provided.droppableProps} ref={provided.innerRef}>
                            {list.map((item, index) => <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                {(provided, snapshot) => (
                                    <Description
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                        data-tip
                                        data-for="Tip"
                                        onClick={() => onItemClick(item)}
                                    >
                                        <Type>
                                            <span>{item.typeName}</span>
                                            <span>{`(${item.assemblyOrder})`}</span>
                                        </Type>
                                        {item.number && <span>SKU: {item.number}</span>}
                                        <span>{item.name || item.labelName}</span>
                                        {removeItem && <FontAwesomeIcon icon={faTimes} onClick={(e) => onItemDelete(e, item)} style={{ color: 'orangered', position: 'absolute', cursor: 'pointer', right: 2, top: -1 }} />}
                                    </Description>
                                )}
                            </Draggable>)}
                            {provided.placeholder}
                        </Container>}
                    </Droppable>
                </DragDropContext>
            </FancyScroll>
            <ReactTooltip ref={ref} isCapture={true} event="click" id="Tip" place="right" effect="solid" type="light" afterShow={() => setIsOpen(true)} clickable={true} scrollHide={false} resizeHide={false} offset={{ left: 25 }} afterHide={afterHide}>
                <TooltipContent componentId={component?.id} setOptions={setOptions} uploading={uploading} setUploading={setUploading}
                    type={component?.type} options={options} closeTooltip={afterHide} componentPlacement={componentPlacement} setComponentPlacement={setComponentPlacement} removeImage={removeImage} />
            </ReactTooltip>
            <SubmitSection>
                <CustomButton rounded label='Save Changes' background='#61A431' onClick={onSubmit} />
            </SubmitSection>
        </>
    );
};

export default FloatingOrderList;
