import { useEffect, useState } from 'react';

export default function useWindowResizeInnerHeight() {
  const [innerHeight, setInnerHeight] = useState(false);

  useEffect(() => {
    setInnerHeight(window.innerHeight);
    window.addEventListener(
      'resize',
      function () {
        setInnerHeight(window.innerHeight);
      },
      true,
    );

    return () => {
      window.removeEventListener('resize', null, true);
    };
  }, []);

  return innerHeight;
}
