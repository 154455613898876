import React, { useEffect, useState } from "react";
import { MDBPagination, MDBPageItem, MDBPageNav, MDBCol, MDBRow } from "mdbreact";

const PaginationNew = ({
  activePage,
  onChange,
  totalPages,
  pagesVisible,
  isMobile = false,
  jump,
}) => {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const pages = [];
    const curr = Number.parseInt(activePage / pagesVisible) * pagesVisible + 1;

    for (let i = curr; i < Math.min(totalPages + 1, curr + pagesVisible); i++) {
      // for (let i = 1; i <= Math.ceil(totalPages / pagesVisible); i++) {
      pages.push(i);
    }

    setPages(pages);
    // eslint-disable-next-line
  }, [activePage, pagesVisible, totalPages]);

  const leftSide = activePage !== 0 && (
    <>
      <MDBPageItem onClick={() => onChange(0)}>
        <MDBPageNav aria-label="Previous">
          {!isMobile && <span aria-hidden="true">First</span>}
        </MDBPageNav>
      </MDBPageItem>
      <MDBPageItem onClick={() => onChange(activePage - 1)}>
        <MDBPageNav aria-label="Previous">
          <span aria-hidden="true">{!isMobile ? 'Previous' : '<<'}</span>
        </MDBPageNav>
      </MDBPageItem>
    </>
  );

  const rightSide = activePage !== totalPages && (
    <>
      <MDBPageItem>
        <MDBPageNav aria-label="Next" onClick={() => onChange(activePage + 1)}>
          <span aria-hidden="true">{!isMobile ? 'Next' : '>>'}</span>
        </MDBPageNav>
      </MDBPageItem>
      <MDBPageItem>
        {!isMobile && (
          <MDBPageNav
            aria-label="Last"
            onClick={() => onChange(totalPages - 1)}
          >
            <span aria-hidden="true">Last</span>
          </MDBPageNav>
        )}
      </MDBPageItem>
    </>
  );

  // const leftSteper = activePage > step - 1 && (
  //   <MDBPageItem onClick={() => onChange(activePage - step)}>
  //     <MDBPageNav aria-label="Previous">
  //       <span aria-hidden="true">...</span>
  //     </MDBPageNav>
  //   </MDBPageItem>
  // );

  const rightStepper = pagesVisible < totalPages && (
    <MDBPageItem onClick={jump}>
      <MDBPageNav aria-label="Next">
        <span aria-hidden="true">...</span>
      </MDBPageNav>
    </MDBPageItem>
  );

  const pagesSection = pages.map((page) => (
    <MDBPageItem key={page} active={page === activePage + 1}>
      <MDBPageNav onClick={() => onChange(page - 1)}>{page}</MDBPageNav>
    </MDBPageItem>
  ));

  return (
    <MDBRow>
      <MDBCol>
        <MDBPagination className="mb-5">
          {leftSide}
          {/* {!isMobile ? leftSteper : null} */}
          {pagesSection}
          {!isMobile ? rightStepper : null}
          {rightSide}
        </MDBPagination>
      </MDBCol>
    </MDBRow>
  );
};

export default PaginationNew;
