import styled from "@emotion/styled";
import React from "react";
import CustomCheckbox from "../../../../../components/Inputs/CustomCheckbox";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding: 10px;
`;

const TextArea = styled.textarea`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  margin-bottom: 10px;
`;
const Title = styled.div`
  color: #1d2659;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
`;

const positionStyles = {
  fontWeight: 500,
  color: "#1d2659",
};

const LabelNote = ({ componentPlacement, setComponentPlacement }) => {
  const { labelData } = componentPlacement;

  if (!labelData) {
    return null;
  }

  const setRotation = () => {
    function updateRotation(img) {
      if (img.imageId === componentPlacement.imageId) {
        return {
          ...img,
          labelData: {
            ...img.labelData,
            rotation: +labelData.rotation ? 0 : 90,
          },
        };
      }
      return img;
    }

    setComponentPlacement([
      {
        ...componentPlacement,
        imageOverrideJson:
          componentPlacement.imageOverrideJson.map(updateRotation),
        labelData: {
          ...labelData,
          rotation: +labelData.rotation ? 0 : 90,
        },
      },
    ]);
  };
  console.log(componentPlacement);
  return (
    <Container>
      <Title>Component Label</Title>
      <TextArea
        value={decodeURI(labelData.text)}
        name="Text"
        cols="20"
        rows="3"
        onChange={(e) =>
          setComponentPlacement([
            {
              ...componentPlacement,
              labelData: {
                ...labelData,
                text: encodeURI(e.target.value),
              },
            },
          ])
        }
      />
      <span style={positionStyles}>X: {labelData.x.toFixed(2)}</span>
      <span style={positionStyles}>Y: {labelData.y.toFixed(2)}</span>
      <CustomCheckbox
        checked={+labelData.rotation === 90}
        label="Rotate 90 deg"
        onChange={setRotation}
      />
      <br />
    </Container>
  );
};

export default LabelNote;
