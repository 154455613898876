import styled from "@emotion/styled";
import {
  faArrowsAltH,
  faArrowsAltV,
  faStopCircle,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import CustomInput from "../../../../../components/Inputs/CustomInput";

const Title = styled.div`
  color: #1d2659;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

const InputGroup = styled.div`
  display: flex;
  width: 40%;
  padding: 0px 10px;
  align-items: center;
  position: relative;
  justify-content: space-between;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
  }

  & > div:last-of-type {
    height: 30px;
    width: 50px;
  }

  input {
    background: white;
    padding-top: 0px;
    color: black;
    height: 20px;
    text-align: center;
  }

  label,
  label:after {
    bottom: -1px;
    height: 20px;
  }
`;

const ResetButton = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15px;
  padding: 0px 10px;
  align-items: center;
  position: relative;
  justify-content: space-around;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
`;

const Placement = ({ componentPlacement, onInputChange, resetValues }) => {
  return (
    <>
      <Title>Position Default Values</Title>
      <InputGroup>
        <div>
          <FontAwesomeIcon icon={faArrowsAltH} />
        </div>
        <CustomInput
          value={componentPlacement[0].values.x}
          name="x"
          onChange={onInputChange}
          type="number"
        />
      </InputGroup>
      <InputGroup>
        <div>
          <FontAwesomeIcon icon={faArrowsAltV} />
        </div>
        <CustomInput
          value={componentPlacement[0].values.y}
          name="y"
          onChange={onInputChange}
          type="number"
        />
      </InputGroup>
      <InputGroup>
        <div>
          <FontAwesomeIcon icon={faUndo} />
        </div>
        <CustomInput
          value={componentPlacement[0].values.r}
          name="rotation"
          onChange={onInputChange}
          type="number"
        />
        <FontAwesomeIcon
          icon={faStopCircle}
          style={{
            marginRight: 0,
            position: "absolute",
            top: 0,
            right: 0,
            fontSize: 6,
          }}
        />
      </InputGroup>
      <ResetButton onClick={resetValues}>
        Reset placement to default
      </ResetButton>
    </>
  );
};

export default Placement;
