import axios from './redux/axiosMiddleware/axios';
import { showSnackbar } from './redux/actions/snackbar';
import { LOGIN } from './constants/actionTypes';
import { logout } from './redux/actions/auth';
import { RESTRICTED_ROLE_ALIAS } from './constants/constants';

export const msalConfig = {
  auth: {
    clientId: '5c67ad60-7879-4757-97a0-e8226e6a9a4f', // Your client (application) ID goes here
    authority:
      'https://login.microsoftonline.com/46cec638-8a06-43fd-a7d7-8a626e13367f', // Replace the last part with your tenant ID
    redirectUri: window.location.origin + '/login', // Must be the same in Azure AD portal, can be replace with an environment variable: process.env.REACT_APP_REDIRECT_URL
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ['api://teleflex-treehouse-kitbuilder-api/.default'],
};

export const setUserDataToLocalStorage = async (
  payload,
  msalInstance,
  dispatch,
  history,
) => {
  try {
    const { accessToken, account } = payload;

    const res = await axios.post('/token/external', null, {
      headers: { Authorization: 'Bearer ' + accessToken },
    });

    const { expires, user, accessToken: appAccessToken } = res.data;

    const { role } = user ?? {};

    if (role?.alias === RESTRICTED_ROLE_ALIAS) {
      history.push('/restricted');
      return window.location.reload();
    }

    // set app data to store
    window.localStorage.setItem('jwt', appAccessToken);
    window.localStorage.setItem('tokenExpiry', expires);
    window.localStorage.setItem('loggeduser', JSON.stringify(user));
    // set active MS Azure account
    msalInstance.setActiveAccount(account);

    dispatch({
      type: LOGIN,
      payload: { user: user, accessToken: appAccessToken, expires },
    });
  } catch (err) {
    dispatch(showSnackbar('error', 'Login Failed.'));
    window.sessionStorage.clear();
    dispatch(logout());
  }
};
