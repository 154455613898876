import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useTransition, animated } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimes, faExclamationTriangle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { snackbarColors } from '../../constants/constants';
import { clearSnackbar } from '../../redux/actions/snackbar';

const Container = styled.div(({ background }) => `
    display: flex;
    position: relative;
    background: ${background || 'black'};
    color: white;
    min-width: 315px;
    min-height: 40px;
    font-weight: 400;
    margin-bottom: 10px;
    border-radius: 4px;
    padding: 15px;
    transition: all 0.3s ease-out;
    align-items: center;
    justify-content: space-between;
`);

const Close = styled.div`
    cursor: pointer;
    margin-left: 50px;
`;

const Content = styled.div`
    display: flex;
    align-items: center;

    & > span {
        margin-left: 10px;
    }

    & > div {
        margin-left: 10px;
    }

    & > svg {
        align-self: flex-start;
        margin-top: 5px;
    }
`;

const MessageContent = styled.div`
    display: block;
    align-items: left;
`;

const Toast = ({ type, message }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(clearSnackbar());
        }, 5000);

        return () => {
            clearInterval(interval);
        }
    }, [dispatch]);

    const transitions = useTransition({ type, message }, null, {
        from: { opacity: 0, transform: "translateY(100%)" },
        enter: { opacity: 1, transform: "translateY(0%)" },
        leave: { opacity: 0 }
    });

    return transitions.map(({ item, props, key }) => item && <animated.div key={key} style={props}>
        <Container background={snackbarColors[type]}>
            <Content>
                <FontAwesomeIcon icon={type === 'success' ? faCheckCircle : type === 'error' ? faTimesCircle : faExclamationTriangle} />
                {typeof message === 'object' ? 
                    (message.title ? 
                        <MessageContent>
                            <div style={{ fontWeight: 600 }}>{message.title}</div>
                            <span>{message.fields.join(', ').toLowerCase()}</span>
                        </MessageContent> :
                        Object.values(message).map((msg, index) => <span key={msg + index}>{msg}</span>))
                : <span>{message}</span>}
            </Content>
            <Close onClick={() => dispatch(clearSnackbar())}>
                <FontAwesomeIcon icon={faTimes} />
            </Close>
        </Container>
    </animated.div>)
};

export default Toast;

