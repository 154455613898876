import styled from '@emotion/styled';
import React from 'react';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 0px 10px;

    & > div:first-of-type {
        display: flex;
        justify-content: space-around;

        & > div {
            display: flex;
            flex-direction: column;
        }
    }

    & > div:last-of-type {
        display: flex;
        flex-direction: column;

         & > div {
            justify-content: space-around;
            display: flex;
            align-items: baseline;        
         }
    }
`;

const Title = styled.span`
    text-align: ${props => props.align || 'left'};
    font-weight: 500;
    margin-top: 15px;
`;

const FallbackOverview = ({ fallback }) => {
    return (
        <Container>
            <Title>SKU</Title>
            <span>{fallback.number}</span>
            <Title>Description</Title>
            <span>{fallback.description}</span>
            <Title>Details</Title>
            <span>{fallback.labelName}</span>
            <br />
            <div>
                <div>
                    <Title>Category</Title>
                    <span>{fallback.category?.name || 'N/A'}</span>
                </div>
                <div>
                    <Title>Part Type</Title>
                    <span>{fallback.partType?.name || 'N/A'}</span>
                </div>
            </div>
            <br />
            <div>
                <Title align='center'>Packaged Component Measurements</Title>
                <div>
                    <Title>Wide</Title>
                    <span>{fallback.width}&nbsp;cm</span>
                </div>
                <div>
                    <Title>Height</Title>
                    <span>{fallback.height}&nbsp;cm</span>
                </div>
                <div>
                    <Title>Depth</Title>
                    <span>{fallback.depth}&nbsp;cm</span>
                </div>
                <div>
                    <Title>Weight</Title>
                    <span>{fallback.weight}&nbsp;OZ</span>
                </div>
            </div>
        </Container>
    );
};

export default FallbackOverview;
