import React, { Component } from 'react';
import {
  MDBAlert,
  MDBInput,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdbreact';
import agent from '../../agent';
import { connect } from 'react-redux';
import { LOGOUT } from '../../constants/actionTypes';
import LoadingOverlay from 'react-loading-overlay';
import Loader from '../../components/loaders/Loader';

let ComponentDetailArray = {
  components: [{ description: 'No results found' }],
  totalPages: 0,
  totalCount: 0,
};

const mapStateToProps = (state) => ({
  ...state.auth,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onClickLogout: () => dispatch({ type: LOGOUT }),
});

let i = 0;
let tempAccountArray = [1];

class ComponentCatalogRequestSample extends Component {
  currentNumber = null;
  currentSuiteApt = null;
  currentCity = null;
  currentZipCode = null;
  currentPhone = null;
  currentStreet = null;

  constructor(props) {
    super(props);
    const token = window.localStorage.getItem('jwt');
    if (token) {
      const urlParams = new URLSearchParams(window.location.search);
      const componentId = urlParams.get('ComponentId');
      agent.Home.componentdetail(componentId).then((res) => {
        if (res === null) {
          this.setState({ componentDetailData: ComponentDetailArray });
        } else {
          this.setState({ componentDetailData: res });
        }
      });
    } else {
      this.props.onClickLogout();
    }

    this.state = {
      componentDetailData: null,

      showPdfPop: null,
      Number: null,
      Street: null,
      SuiteApt: null,
      City: null,
      ZIP: null,
      Phone: null,
      regionalManager: null,
      contactTitle: null,
      contactName: null,
      salesRep: null,
      accountNumber: null,
      facility: null,
      specialInstructions: null,
      quantity: 1,
      accounts: [],
      responseStatusCode: null,
      load_failed: false,
      isPDFLoaded: false,
      facilityName: null,
      facilityStreet: null,
      facilitySuite: null,
      facilityCity: null,
      facilityZipCode: null,
      submitformContinue: false,
    };
    this.ComponentConfirmationPage = this.ComponentConfirmationPage.bind(this);
    this.kitMakerComponentsPage = this.kitMakerComponentsPage.bind(this);
    this.incrementItem = this.incrementItem.bind(this);
    this.decreaseItem = this.decreaseItem.bind(this);
    this.pdfGenerate = this.pdfGenerate.bind(this);
    this.accountValue = this.accountValue.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  state = {
    activeItem: '1',
  };

  componentWillMount() {
    this.currentLoggedInUserDetail = JSON.parse(
      window.localStorage.getItem('loggeduser'),
    );
    this.setState({ Number: this.currentLoggedInUserDetail.number });
    this.setState({ Street: this.currentLoggedInUserDetail.street });
    this.setState({ SuiteApt: this.currentLoggedInUserDetail.suiteApt });
    this.setState({ City: this.currentLoggedInUserDetail.city });
    this.setState({ ZIP: this.currentLoggedInUserDetail.zipCode });
    this.setState({ Phone: this.currentLoggedInUserDetail.phone });
  }

  toggle = (tab) => (e) => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };
  ComponentConfirmationPage = () => {
    let path = '/Catalogconfirmation';

    this.props.history.push(path + '/' + this.state.componentDetailData.number);
  };
  kitMakerComponentsPage = () => {
    let path = '/components';
    this.props.history.push(path);
  };

  incrementItem() {
    let tempArray = this.state.quantity;
    if (tempArray === 5) return;
    tempArray = tempArray + 1;
    this.setState({ quantity: tempArray });
  }

  decreaseItem = () => {
    let tempArray = this.state.quantity;
    if (tempArray > 1) {
      tempArray = tempArray - 1;
      this.setState({ quantity: tempArray });
    } else {
      tempArray = 1;
      this.setState({ quantity: tempArray });
    }
  };

  pdfGenerate(data) {
    this.setState({ isPDFLoaded: true, load_failed: false });
    agent.Home.getPdfCatalog(data.id)
      .then((res) => {
        if (res !== null) {
          this.setState({
            isPDFLoaded: false,
            responseStatusCode: res.statusCode,
          });
          var newBlob = new Blob([res], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.href = url;
          let name = data.number;
          const download = name + '.pdf';
          link.setAttribute('download', download);
          document.body.appendChild(link);
          link.click();
        } else {
          this.setState({
            showPdfPop: !this.state.showPdfPop,
            responseStatusCode: res.statusCode,
          });
          setTimeout(
            function () {
              this.setState({
                showPdfPop: !this.state.showPdfPop,
                responseStatusCode: res.statusCode,
              });
            }.bind(this),
            5000,
          );
          window.scroll(0, 0);
        }
      })
      .catch((error) => {
        setTimeout(
          function () {
            this.setState({ load_failed: true, isPDFLoaded: false });
          }.bind(this),
          5000,
        );
        window.scroll(0, 0);
      });
  }

  handleChange = (event) => {
    switch (event.target.name) {
      case 'number':
        this.setState({ number: event.target.value });
        break;
      case 'street':
        this.setState({ street: event.target.value });
        break;
      case 'suiteApt':
        this.setState({ suiteApt: event.target.value });
        break;
      case 'city':
        this.setState({ city: event.target.value });
        break;
      case 'zip':
        this.setState({ zip: event.target.value });
        break;
      case 'phone':
        this.setState({ phone: event.target.value });
        break;
      case 'regionalManager':
        this.setState({ regionalManager: event.target.value });
        break;
      case 'contactTitle':
        this.setState({ contactTitle: event.target.value });
        break;
      case 'contactName':
        this.setState({ contactName: event.target.value });
        break;
      case 'salesRep':
        this.setState({ salesRep: event.target.value });
        break;
      case 'mainAccountNumber':
        this.setState({ mainAccountNumber: event.target.value });
        break;
      case 'accountNumber':
        this.setState({ accountNumber: event.target.value });
        break;
      case 'facilityName':
        this.setState({ facilityName: event.target.value });
        break;
      case 'facilityStreet':
        this.setState({ facilityStreet: event.target.value });
        break;
      case 'facilitySuite':
        this.setState({ facilitySuite: event.target.value });
        break;
      case 'facilityCity':
        this.setState({ facilityCity: event.target.value });
        break;
      case 'facilityZipCode':
        this.setState({ facilityZipCode: event.target.value });
        break;
      case 'specialInstructions':
        this.setState({ specialInstructions: event.target.value });
        break;
      case 'quantity':
        this.setState({ quantity: event.target.value });
        break;
      default:
        break;
    }
  };

  accountValue = () => {
    tempAccountArray.push(document.getElementById('account' + i).value);
    if (document.getElementById('account' + i).value !== '') {
      i = i + 1;
      let Id = 'account' + i;
      var node = document.createElement('input');
      var nodeIcon = document.createElement('span');
      nodeIcon.setAttribute('className', 'addicon');
      nodeIcon.setAttribute('value', '+');
      var textnode = document.createTextNode('+');
      node.setAttribute('type', 'text');
      node.setAttribute('value', '');
      node.setAttribute('Id', Id);
      node.setAttribute('name', 'Test Name');
      node.setAttribute('class', 'offset-md-6');
      node.setAttribute('pattern', '^[A-Za-z0-9_]+$');
      node.appendChild(textnode);
      node.appendChild(nodeIcon);
      document.getElementById('accountDiv').appendChild(node);
    }
  };

  submitForm(ev) {
    ev.preventDefault();
    window.scrollTo(0, 0);
    // if (tempAccountArray) {
    //     tempAccountArray.forEach((element, i) => {
    //         this.state.accounts.push(document.getElementById("account" + i).value);
    //     });
    // } else {
    //     this.state.accounts.push(this.state.accountNumber);
    // }

    let data = {
      street: this.state.Street,
      suiteApt: this.state.SuiteApt,
      city: this.state.City,
      zipCode: this.state.ZIP,
      phone: this.state.Phone,
      regionalManager: this.state.regionalManager,
      contactTitle: this.state.contactTitle,
      contactName: this.state.contactName,
      salesRep: this.state.salesRep,
      accountNumber: this.state.mainAccountNumber,
      facilityName: this.state.facilityName,
      facilityStreet: this.state.facilityStreet,
      facilityCity: this.state.facilityCity,
      facilitySuite: this.state.facilitySuite,
      facilityZipCode: this.state.facilityZipCode,
      specialInstructions: this.state.specialInstructions,
      quantity: this.state.quantity,
      //  "accounts": this.state.accounts,
    };
    this.setState({ submitformContinue: true });
    agent.Home.componetRequestForm(
      data,
      this.state.componentDetailData.id,
    ).then((res) => {
      this.setState({ submitformContinue: true });
      window.sessionStorage.setItem('ComponentCategory', '');
      window.sessionStorage.setItem('PopularCategory', '');
      window.sessionStorage.setItem('PartType', '');
      let path = '/Catalogconfirmation';
      this.props.history.push(
        path + '/' + this.state.componentDetailData.number,
      );
    });
  }

  render() {
    let componentName = '';
    let ComponentDetail = this.state.componentDetailData;
    let dataItem = '';
    if (ComponentDetail === null) {
      return <Loader />;
    } else {
      componentName = ComponentDetail.number;
    }
    const alertPopUp = (
      <MDBAlert color="success" dismiss>
        <strong>PDF not found!</strong>
      </MDBAlert>
    );
    dataItem = (
      <tr>
        <td>{this.state.quantity} </td>
        <td>
          <div className="tbl-addon">
            <a href="#no">
              <i className="fa fa-plus" onClick={this.incrementItem}></i>
            </a>
            <a href="#no">
              <i className="fa fa-minus" onClick={this.decreaseItem}></i>
            </a>
          </div>
        </td>
        <td>{ComponentDetail.number}</td>
        <td className="alignment">{ComponentDetail.description}</td>
      </tr>
    );

    // var tempDate = new Date();
    // var date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate();
    return (
      <div className="main-layout">
        <div className="content-area">
          <LoadingOverlay
            active={this.state.isPDFLoaded || this.state.submitformContinue}
            className=""
            spinner
            text="Please wait..."
          >
            <MDBContainer fluid className="kitmaker">
              <div className="text-capitalize mt-5 mt-sm-5 mt-lg-0 mt-md-0 head-section mb20">
                <h3>
                  <span className="headfont">COMPONENT CATALOG</span>{' '}
                  {componentName}
                </h3>
              </div>
              {this.state.load_failed ? alertPopUp : null}
              <MDBRow>
                <MDBCol md="12">
                  <MDBCard className="mt-0">
                    <MDBCardBody className="p30 kitmakerform">
                      <div className="form-area mb-5">
                        <form onSubmit={this.submitForm}>
                          <MDBRow>
                            <MDBCol md="8" className="text-center offset-md-2">
                              <h4>
                                Please confirm sample shipping address
                                (Teleflex's Sales Representative)
                              </h4>
                              <MDBRow>
                                <MDBCol md="12">
                                  {' '}
                                  <MDBInput
                                    label="Street *"
                                    type="text"
                                    name="street"
                                    title="Special charcters are not allowed except Coma (,)"
                                    pattern="^[A-Za-z0-9, _]*[A-Za-z0-9,][A-Za-z0-9, _]*$"
                                    valueDefault={this.state.Street}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </MDBCol>
                                <MDBCol md="6">
                                  {' '}
                                  <MDBInput
                                    label="Suite/Apt"
                                    type="text"
                                    name="suiteApt"
                                    title="Special charcters are not allowed"
                                    pattern="^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$"
                                    valueDefault={this.state.SuiteApt}
                                    onChange={this.handleChange}
                                  />
                                </MDBCol>
                                <MDBCol md="6">
                                  {' '}
                                  <MDBInput
                                    label="City, State *"
                                    type="text"
                                    name="City"
                                    title="Format is City, State (Chicago, Illinois)"
                                    pattern="^[A-Z a-z]*,[ A-Za-z][A-Za-z]*$"
                                    valueDefault={this.state.City}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </MDBCol>
                                <MDBCol md="6">
                                  {' '}
                                  <MDBInput
                                    label="ZIP CODE *"
                                    type="text"
                                    name="zip"
                                    title="Special charcters are not allowed"
                                    pattern="^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _]*$"
                                    valueDefault={this.state.ZIP}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </MDBCol>
                                <MDBCol md="6">
                                  <MDBInput
                                    label="Phone (ex. 5551234567) *"
                                    type="text"
                                    name="phone"
                                    min="0"
                                    title="Enter only numbers"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    valueDefault={this.state.Phone}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </MDBCol>
                              </MDBRow>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol md="10" className="text-center offset-md-1">
                              {/* <MDBRow>
                                                                    <MDBCol md="12" className="text-center mt-4 boxform">
                                                                        <h3>Customer Component Confirmation</h3>
                                                                        <div className="formbox">
                                                                            <MDBRow>
                                                                                <MDBCol md="6">
                                                                                    <label>Date :</label>
                                                                                    <input type="text" defaultValue={date} />
                                                                                </MDBCol>
                                                                                <MDBCol md="6">
                                                                                    <label>Sales Representative:</label>
                                                                                    <input type="text" name="salesRep" onChange={this.handleChange} />
                                                                                </MDBCol>
                                                                                <MDBCol md="6">
                                                                                    <label>Purchasing Contact * :</label>
                                                                                    <input type="text" pattern="^[A-Za-z -]+$" title="Enter only letters" name="contactName" onChange={this.handleChange} required />
                                                                                </MDBCol>
                                                                                <MDBCol md="6">
                                                                                    <label>Regional Manager * :</label>
                                                                                    <input type="text" name="regionalManager" title="Enetr only letters" pattern="^[A-Za-z -]+$" onChange={this.handleChange} required />
                                                                                </MDBCol>
                                                                                <MDBCol md="6">
                                                                                    <label>Purchasing Contact title *:</label>
                                                                                    <input type="text" name="contactTitle" title="Enter only letters" pattern="^[A-Za-z -]+$" onChange={this.handleChange} required />
                                                                                </MDBCol>


                                                                                <MDBCol md="6">
                                                                                    <label>Facility Name * :</label>
                                                                                    <input type="text" name="facilityName" title="Enter only characters" maxLength="50" pattern="^[A-Za-z -]+$" onChange={this.handleChange} required /></MDBCol>
                                                                                <MDBCol md="6">
                                                                                    <label>Street * :</label>
                                                                                    <input type="text" name="facilityStreet" title="Special charcters are not allowed except Coma (,)" pattern="^[A-Za-z0-9, _]*[A-Za-z0-9,][A-Za-z0-9, _]*$" onChange={this.handleChange} required /></MDBCol>
                                                                                <MDBCol md="6">
                                                                                    <label>Suite/Apt :</label>
                                                                                    <input type="text" name="facilitySuite" title="Special charcters are not allowed" pattern="^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$" onChange={this.handleChange} /></MDBCol>
                                                                                <MDBCol md="6">
                                                                                    <label>City, State * :</label>
                                                                                    <input type="text" name="facilityCity" title="Format is City, State (Chicago, Illinois)" pattern="^[A-Z a-z]*,[ A-Za-z][A-Za-z]*$" onChange={this.handleChange} required /></MDBCol>
                                                                                <MDBCol md="6">
                                                                                    <label>ZipCode * :</label>
                                                                                    <input type="text" name="facilityZipCode" title="Special charcters are not allowed" pattern="^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _]*$" onChange={this.handleChange} required /></MDBCol>
                                                                                <MDBCol md="6">
                                                                                    <label>Account Number * :</label>
                                                                                    <input type="text" name="mainAccountNumber" title="Enter only Alphanumeric Values" pattern="^[A-Za-z0-9_]+$" onChange={this.handleChange} required />
                                                                                </MDBCol>
                                                                                {/* <MDBCol md="6" >
                                                                                    <div id="accountDiv">
                                                                                        <label>Subsidiary Number :</label>
                                                                                        {/* <input type="text" id="account0" name="accountNumber" title="Enter only Alphanumeric Values" pattern="^[A-Za-z0-9_]+$" value={this.state.accounts[0]?this.state.accounts[0]:""} className=" mt-3" /> */}
                              {/* <input type="text" id="account0" name="accountNumber" className=" mt-3" title="Enter only Alphanumeric Values" pattern="^[A-Za-z0-9_]+$" onChange={this.handleChange} />
                                                                                        <span className="addicon formaddicon" value="1" onClick={() => this.accountValue()}>+</span>
                                                                                    </div> */}
                              {/* </MDBCol> */}
                              {/* </MDBRow>
                                                                        </div>
                                                                    </MDBCol>
                                                                </MDBRow> */}
                              <div className="mt-5 kitmakerform tblcustom tbladsub">
                                <MDBTable striped small cellSpacing="10">
                                  <MDBTableHead>
                                    <tr>
                                      <th align="text-center">
                                        <b>Qty</b>
                                      </th>
                                      <th></th>
                                      <th>
                                        <b>SKU #</b>
                                      </th>
                                      <th className="alignment">
                                        <b>Component Name</b>
                                      </th>
                                    </tr>
                                  </MDBTableHead>
                                  <MDBTableBody>{dataItem}</MDBTableBody>
                                </MDBTable>
                              </div>

                              <textarea
                                placeholder="If shipping by method other than Standard Ground, please indicate here."
                                className="form-control boxinput mb-0"
                                rows="4"
                                name="specialInstructions"
                                onChange={this.handleChange}
                              ></textarea>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol md="12" className="text-center mt-4">
                              {/*<div className="btn-area  mb-4">*/}
                              {/*  <MDBBtn*/}
                              {/*    className="btn-outline-grey greylinebtn"*/}
                              {/*    onClick={() =>*/}
                              {/*      this.pdfGenerate(*/}
                              {/*        this.state.componentDetailData,*/}
                              {/*      )*/}
                              {/*    }*/}
                              {/*  >*/}
                              {/*    .PDF*/}
                              {/*  </MDBBtn>*/}
                              {/*</div>*/}
                              <div className="btn-section mtb2">
                                <MDBBtn
                                  className="mb20-xs btn-outline-red"
                                  onClick={() => this.kitMakerComponentsPage()}
                                >
                                  Cancel
                                </MDBBtn>
                                <MDBBtn
                                  className=" mb20-xs gbtn"
                                  type="submit"
                                  value="submit"
                                >
                                  Continue
                                </MDBBtn>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </form>
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentCatalogRequestSample);
