import axios from '../axiosMiddleware/axios';
import { getToken } from '../../utils/getToken';
import * as actionTypes from '../../constants/actionTypes';
import { showSnackbar } from './snackbar';
import { downloadFile, loadKitInfoFromKitDesign } from './kits';

export const snapshotsPdf = async (kitId) => {
  const result = await axios.get(
    `/api/kit-design/kits/${kitId}/snapshots-pdf`,
    {
      headers: { Authorization: 'Bearer ' + getToken() },
      responseType: 'blob',
    },
  );
  downloadFile(result.data, 'Snapshots', 'pdf');
  return result.data;
};

export const updateAssemblyOrder =
  (kitId, objects, pages, version, message) => async (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_ASSEMBLY_ORDER_START });
    const body = {
      Objects: JSON.stringify(objects),
      Pages: JSON.stringify(pages),
      Version: version,
    };
    try {
      if (kitId) {
        await axios.put(`/api/kit-design/kits/${kitId}/assembly-order`, body, {
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json',
          },
        });
        dispatch({
          type: actionTypes.UPDATE_ASSEMBLY_ORDER_SUCCESS,
          result: body,
        });
        message && dispatch(showSnackbar('success', message));
      }
    } catch (err) {
      dispatch({ type: actionTypes.UPDATE_ASSEMBLY_ORDER_FAIL });
      // dispatch(showSnackbar('error', 'Failed to update assembly order'));
    }
  };

export const listSnapshots = (kitId) => async (dispatch) => {
  dispatch({ type: actionTypes.LIST_SNAPSHOTS_START });

  try {
    const res = await axios.get(`/api/kit-design/kits/${kitId}/snapshots`, {
      headers: { Authorization: 'Bearer ' + getToken() },
    });
    dispatch({ type: actionTypes.LIST_SNAPSHOTS_SUCCESS, snapshots: res.data });
  } catch (err) {
    dispatch({ type: actionTypes.LIST_SNAPSHOTS_FAIL, error: err });
  }
};

export const saveLayoutData = (kitId, data) => async (dispatch) => {
  try {
    if (kitId) {
      await axios.put(
        `/api/kit-design/kits/${kitId}/layout-data`,
        `"${JSON.stringify(data).replaceAll('"', "'")}"`,
        {
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json',
          },
        },
      );
      dispatch({
        type: actionTypes.UPDATE_LAYOUT_DATA_SUCCESS,
        data: JSON.stringify(data).replaceAll('"', "'"),
      });
    }
  } catch (err) {
    // dispatch(showSnackbar("error", "Failed to update layout"));
  }
};

export const resetLabelText = (kitId, data) => async (dispatch) => {
  await axios.put(
    `/api/kit-design/kits/${kitId}/layout-data`,
    `"${JSON.stringify(data).replaceAll('"', "'")}"`,
    {
      headers: {
        Authorization: 'Bearer ' + getToken(),
        'Content-Type': 'application/json',
      },
    },
  );
  dispatch({
    type: actionTypes.UPDATE_LAYOUT_DATA_SUCCESS,
    data: JSON.stringify(data).replaceAll('"', "'"),
  });
  showSnackbar('success', 'Labels positions reseted');
  setTimeout(() => dispatch(loadKitInfoFromKitDesign(kitId, true)));
};

export const deleteSnapshots = async (kitId) => {
  await axios.delete(`/api/kit-design/kits/${kitId}/snapshots`, {
    headers: { Authorization: 'Bearer ' + getToken() },
  });
};

export const updateSnapshotOrder = (kitId, Id, Order) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_SNAPSHOT_ORDER_START });

  try {
    await axios.put(
      `/api/kit-design/kits/${kitId}/snapshots/order`,
      { Id, Order },
      { headers: { Authorization: 'Bearer ' + getToken() } },
    );
    dispatch({ type: actionTypes.UPDATE_SNAPSHOT_ORDER_SUCCESS, Id, Order });
  } catch (err) {
    dispatch(showSnackbar('error', 'Failed to update snapshot order'));
    dispatch({ type: actionTypes.UPDATE_SNAPSHOT_ORDER_FAIL, error: err });
  }
};

export const createSnapshot = async (kitId, data) => {
  const formData = new FormData();
  formData.append('image', data);

  await axios.post(`/api/kit-design/kits/${kitId}/snapshots`, formData, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};
