import React, { useState } from 'react';
import styled from '@emotion/styled';
import Modal from '../../../components/Modal/Modal';
import siteLogo from '../../../shared/img/logo.png';
import CustomInput from '../../../components/Inputs/CustomInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { fieldRequiredMessage, fieldsNotMatchMessage } from '../../../constants/constants';
import Switcher from '../../../components/Switchers/Switcher';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../../redux/actions/userManagement';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
`;

const Title = styled.div`
    font-size: 20px;
    margin: 20px 0px;
    font-weight: 600;
`;

const UserDataBlock = styled.div`
    margin-bottom: 10px;
`;

const InputGroup = styled.div`
    width: 50%;
    margin-bottom: 20px;
`;

const ActionContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 20px 20px;
    align-items: center;
    svg {
        color: #d30202;
        font-size: 22px;
        margin-right: 10px;
        cursor: pointer;
    }
`;

const Button = styled.button`
    background: #1D265B;
    border-radius: 20px;
    border: none;
    color: white;
    font-size: small;
    padding: 2px 10px;
`;

const ResetPassword = ({ user, close }) => {
    const dispatch = useDispatch();

    const [state, setState] = useState({
        password: '',
        passwordConfirm: '',
        forceChangePassword: false
    });

    const [validation, setValidation] = useState({
        password: false,
        passwordConfirm: false
    });

    const handleChange = (e) => {
        if (!e.target.value) {
            setValidation({ ...validation, [e.target.name]: fieldRequiredMessage });
        } else {
            setValidation({ ...validation, [e.target.name]: false });
        }

        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleSwitchChange = (e) => setState({ ...state, forceChangePassword: !state.forceChangePassword });

    const handleConfirmPassword = (e) => {
        if (e.target.value !== state.password) {
            setValidation({ ...validation, passwordConfirm: fieldsNotMatchMessage });
        } else {
            setValidation({ ...validation, passwordConfirm: false });
        }

        setState({ ...state, passwordConfirm: e.target.value });
    };

    const onSubmit = () => {
        dispatch(resetPassword(user.id, state.password, state.forceChangePassword));
        close();
    };

    const disabled = !(state.password.length && state.passwordConfirm.length && state.password === state.passwordConfirm);

    return (
      <Modal close={close}>
        <Container>
          <img src={siteLogo} height={20} width={130} alt="logo" />
          <Title>Reset Password</Title>
          <UserDataBlock>{`${user.firstName} ${user.lastName}`}</UserDataBlock>
          <UserDataBlock>{user.userName}</UserDataBlock>
          <div>
            <InputGroup>
              <CustomInput
                label="New Password"
                type="password"
                name="password"
                value={state.password}
                invalid={validation.password}
                errorText={validation.password}
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup>
              <CustomInput
                label="Password Confirm"
                type="password"
                name="passwordConfirm"
                value={state.passwordConfirm}
                invalid={validation.passwordConfirm}
                errorText={validation.passwordConfirm}
                onChange={handleConfirmPassword}
              />
            </InputGroup>
          </div>
          <Switcher
            size="small"
            rightLabel="Force Change Password"
            checked={state.forceChangePassword}
            onChange={handleSwitchChange}
          />
        </Container>
        <ActionContainer>
          <FontAwesomeIcon icon={faTimesCircle} onClick={close} />
          <Button disabled={disabled} onClick={onSubmit}>
            Reset Password
          </Button>
        </ActionContainer>
      </Modal>
    );
};

export default ResetPassword;
