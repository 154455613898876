import * as actionTypes from '../../constants/actionTypes';
import * as _ from 'lodash';

const initialState = {
    trays: [],
    cavities: [],
    loading: false,
    actionLoading: false,
    createdTray: null,
    createdCavity: null,
    mainDevices: [],
    tooltipData: null,
    tooltipLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_KIT_TRAYS_START:
            return {
                ...state,
                loading: action.loading
            }

        case actionTypes.GET_TRAY_CAVITIES_START:
            return {
                ...state,
                actionLoading: true
            }

        case actionTypes.GET_TRAY_CAVITIES_SUCCESS:
            return {
                ...state,
                cavities: _.orderBy(action.cavities.map(x => ({ ...x, points: JSON.parse(x.vertices || "[]") })), ['assemblyOrder'], ['desc']),
                loading: false,
                actionLoading: false
            }

        case actionTypes.GET_KIT_TRAYS_SUCCESS:
            return {
                ...state,
                loading: false,
                trays: action.trays
            }

        case actionTypes.CREATE_KIT_TRAY_SUCCESS:
            return {
                ...state,
                actionLoading: false,
                createdTray: action.createdTray
            }

        case actionTypes.UPDATE_KIT_TRAY_START:
        case actionTypes.CREATE_KIT_TRAY_START:
        case actionTypes.DELETE_KIT_TRAY_START:
        case actionTypes.CHANGE_CAVITIES_ASSEMBLY_ORDER_START:
        case actionTypes.UPDATE_TRAY_CAVITY_START:
            return {
                ...state,
                actionLoading: true,
                createdTray: null
            }

        case actionTypes.UPDATE_KIT_TRAY_SUCCESS:
        case actionTypes.CREATE_KIT_TRAY_FAIL:
        case actionTypes.UPDATE_KIT_TRAY_FAIL:
        case actionTypes.DELETE_KIT_TRAY_SUCCESS:
        case actionTypes.DELETE_KIT_TRAY_FAIL:
        case actionTypes.GET_TRAY_CAVITIES_FAIL:
        case actionTypes.UPDATE_TRAY_CAVITY_FAIL:
        case actionTypes.CHANGE_CAVITIES_ASSEMBLY_ORDER_SUCCESS:
        case actionTypes.CHANGE_CAVITIES_ASSEMBLY_ORDER_FAIL:
            return {
                ...state,
                actionLoading: false
            }

        case actionTypes.GET_MAIN_DEVICE_SUCCESS:
            return {
                ...state,
                mainDevices: action.devices
            }
        case actionTypes.GET_CMS_COMPONENT_TOOLTIP_INFO_START:
            return {
                ...state,
                tooltipLoading: true,
                tooltipData: null
            }

        case actionTypes.GET_CMS_COMPONENT_TOOLTIP_INFO_SUCCESS:
            return {
                ...state,
                tooltipLoading: false,
                tooltipData: action.data
            }

        case actionTypes.GET_CMS_COMPONENT_TOOLTIP_INFO_FAIL:
            return {
                ...state,
                tooltipLoading: false
            }

        case actionTypes.CLEAR_CMS_COMPONENT_TOOLTIP_INFO:
            return {
                ...state,
                tooltipData: null
            }
        case actionTypes.CREATE_TRAY_CAVITY_SUCCESS:
            return {
                ...state,
                actionLoading: false,
                createdCavity: { ...action.data, points: JSON.parse(action.data.vertices) }
            }

        default:
            return { ...state }
    };
};
