import React, { useState } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import Modal from '../../../components/Modal/Modal';
import CustomInput from '../../../components/Inputs/CustomInput';
import siteLogo from '../../../shared/img/logo.png';
import { createUser, updateUser } from '../../../redux/actions/userManagement';
import {
  createUserAction,
  editUserAction,
  fieldRequiredMessage,
  fieldsNotMatchMessage,
  userEmailExistMessage,
} from '../../../constants/constants';
import CustomSelect from '../../../components/Selects/CustomSelect';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Title = styled.div`
  font-size: 20px;
  margin: 20px 0px;
  font-weight: 600;
`;

const InputGroup = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px;
  align-items: center;

  svg {
    color: #d30202;
    font-size: 22px;
    margin-right: 10px;
    cursor: pointer;
  }
`;

const Button = styled.button`
  background: #61a431;
  border-radius: 20px;
  border: none;
  color: white;
  font-size: small;
  padding: 2px 10px;
`;

const Wapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  & > div {
    width: 45%;
  }
`;

const CreateOrEditUser = ({ data, action, close, roles, users }) => {
  const userProps =
    action === editUserAction ? data : { ...data, role: roles[0] };

  const [userData, setUserData] = useState(userProps);
  const [formErrors, setFormErrors] = useState({});

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const val = e.target.value;
    const name = e.target.name;

    const exist = (users ?? []).find(
      (user) => user.userName.toLowerCase() === val.toLowerCase(),
    );

    if (exist) {
      setFormErrors({ ...formErrors, [name]: userEmailExistMessage });
    } else if (!val) {
      setFormErrors({ ...formErrors, [name]: fieldRequiredMessage });
    } else {
      setFormErrors({ ...formErrors, [name]: false });
    }

    setUserData({ ...userData, [name]: val });
  };

  const handleConfirmPassword = (e) => {
    if (userData.password !== e.target.value) {
      setFormErrors({ ...formErrors, [e.target.name]: fieldsNotMatchMessage });
    } else {
      setFormErrors({ ...formErrors, password: false, passwordConfirm: false });
    }

    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleRoleChange = (role) => setUserData({ ...userData, role });

  const onSubmit = () => {
    dispatch(
      action === editUserAction ? updateUser(userData) : createUser(userData),
    );
    close();
  };

  const isDisabled = !(
    Object.values(userData)
      .filter((f) => typeof f === 'string')
      .every((u) => u.length) && Object.values(formErrors).every((err) => !err)
  );

  return (
    <Modal close={close}>
      <Container>
        <img src={siteLogo} height={50} width={130} alt="logo" />
        <Title>{action === editUserAction ? 'Edit User' : 'Create User'}</Title>
        <Wapper>
          <div>
            <InputGroup>
              <CustomInput
                label="First"
                value={userData.firstName}
                invalid={formErrors.firstName}
                errorText={formErrors.firstName}
                name="firstName"
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup>
              <CustomInput
                label="Last"
                value={userData.lastName}
                invalid={formErrors.lastName}
                errorText={formErrors.lastName}
                name="lastName"
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup>
              <CustomSelect
                isSearchable={false}
                options={roles}
                value={
                  roles.find(
                    (r) =>
                      r.value === userData.role?.id ||
                      r.value === userData.role?.value,
                  ) || roles[0]
                }
                onChange={(value) => handleRoleChange(value)}
              />
            </InputGroup>
          </div>
          <div>
            <InputGroup>
              <CustomInput
                label="Email"
                value={userData.userName}
                invalid={formErrors.userName}
                errorText={formErrors.userName}
                name="userName"
                onChange={handleChange}
              />
            </InputGroup>
            {action === createUserAction && (
              <>
                <InputGroup>
                  <CustomInput
                    type="password"
                    label="Password"
                    name="password"
                    value={userData.password}
                    invalid={formErrors.password}
                    errorText={formErrors.password}
                    onChange={handleChange}
                  />
                </InputGroup>
                <InputGroup>
                  <CustomInput
                    type="password"
                    label="Password Confirmation"
                    name="passwordConfirm"
                    value={userData.passwordConfirm}
                    invalid={formErrors.passwordConfirm}
                    errorText={formErrors.passwordConfirm}
                    onChange={handleConfirmPassword}
                  />
                </InputGroup>
              </>
            )}
          </div>
        </Wapper>
      </Container>
      <ActionContainer>
        <FontAwesomeIcon icon={faTimesCircle} onClick={close} />
        <Button disabled={isDisabled} onClick={onSubmit}>
          {action === editUserAction ? 'Save Changes' : 'Create New User'}
        </Button>
      </ActionContainer>
    </Modal>
  );
};

export default CreateOrEditUser;
