import * as actionTypes from '../../constants/actionTypes';

const initialState = {
    snackbars: [],
    reminder: null
};

const snackbar = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_SNACKBAR:
            return {
                ...state,
                snackbars: state.snackbars.concat({
                    message: action.snackbar.message,
                    type: action.snackbar.type
                })
            };

        case actionTypes.SNACKBAR_CLEAR:
            const [, ...rest] = state.snackbars;
            return {
                ...state,
                snackbars: rest
            };

        case actionTypes.SHOW_REMINDER:
            return {
                ...state,
                reminder: action.reminder
            }

        case actionTypes.HIDE_REMINDER:
            return {
                ...state,
                reminder: null
            }

        default:
            return { ...state };
    }
};

export default snackbar;
