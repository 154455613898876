import styled from '@emotion/styled';
import React from 'react';
import Table from '../../../components/Tables/Table';
import PaginationNew from '../../../components/Pagination/PaginationNew';
import FancyScroll from '../../../components/FancyScroll';
import TypeFilters from './TypeFilters';
import LoadingOverlay from 'react-loading-overlay';
import DateFilters from './DateFilters';
import SearchFilter from './SearchFilter';
import { overlayStyle } from '../../../constants/constants';

const Container = styled.div`
  padding: 20px;
`;

const typeColors = ['', '#d68a00', '#e13a3a', '#4c7589'];

const Type = styled.div`
  background: ${(props) => typeColors[props.type]};
  border-radius: 20px;
  padding: 5px 10px;
  color: white;
`;

const PaginationContainer = styled.div`
  .pagination {
    width: 450px;
    margin: 20px auto;
  }
  .mb-5 {
    margin-bottom: 0rem !important;
  }
`;

const THead = styled.thead`
  position: sticky;
  top: 0px;
  background: #f9f9f9;
`;

const HighlightedTr = styled.tr`
  background: ${(props) => (props.highlighted ? '#ebebb4' : 'inherit')};
`;

export default function Logs(props) {
  const {
    page,
    setPage,
    logs,
    totalPages,
    loading,
    search,
    setSearch,
    formatDate,
    logLevels,
    setLogLevels,
    dates,
    setDates,
  } = props;

  return (
    <LoadingOverlay
      active={loading}
      spinner
      text="Please wait..."
      styles={overlayStyle}
    >
      <Container>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TypeFilters
            logLevels={logLevels}
            setLogLevels={setLogLevels}
            typeColors={typeColors}
          />
          <SearchFilter search={search} setSearch={setSearch} />
          <DateFilters dates={dates} setDates={setDates} />
        </div>
        <br />
        <FancyScroll offset={240} wide>
          <Table fontSize={12}>
            <THead>
              <tr>
                <th>Timestamp</th>
                <th>Type</th>
                <th>Message</th>
              </tr>
            </THead>
            <tbody>
              {logs.map((log, index) => (
                <HighlightedTr
                  key={log.timeStamp + index}
                  highlighted={log.highlighted}
                >
                  <td style={{ width: 200 }}>{formatDate(log.timeStamp)}</td>
                  <td style={{ width: 100 }}>
                    <Type type={log.level}>{log.levelDescription}</Type>
                  </td>
                  <td style={{ textAlign: 'left' }}>{log.message}</td>
                </HighlightedTr>
              ))}
            </tbody>
          </Table>
        </FancyScroll>
        {totalPages > 1 && (
          <PaginationContainer>
            <PaginationNew
              totalPages={totalPages}
              activePage={page}
              onChange={(page) => setPage(page)}
              pagesVisible={7}
              isMobile
            />
          </PaginationContainer>
        )}
      </Container>
    </LoadingOverlay>
  );
}
