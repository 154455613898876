import { canvasBufferOptions } from "../../../../constants/constants";

// This function need to render divider images with correct aspect ratio
export const renderWithAspectRatio = (p5, image, canvas) => {
  if (!image) {
    return;
  }

  const { imageSizeOffset, offset } = canvasBufferOptions;

  const { width, height } = image;
  const maxWidth = canvas.offsetWidth - imageSizeOffset;
  const maxHeight = canvas.offsetHeight - imageSizeOffset;

  const imageRatio = height / width;
  const canvasRatio = maxHeight / maxWidth;

  if (imageRatio < canvasRatio) {
    const newHeight = maxWidth * imageRatio;

    p5.image(
      image,
      offset,
      (maxHeight - newHeight) / 2 + offset,
      maxWidth,
      newHeight
    );
  } else {
    const newWidth = (maxWidth * canvasRatio) / imageRatio;

    p5.image(
      image,
      (maxWidth - newWidth) / 2 + offset,
      offset,
      newWidth,
      maxHeight
    );
  }
};
