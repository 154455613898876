import React from 'react';
import styled from '@emotion/styled';

const CustomTable = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  display: table;
  font-size: 12px;
  width: 100%;

  td,
  th {
    border: none;
    border-bottom: 1px solid #e3dddd;
    height: 30px;
    padding: 2px 10px;
    text-align: center;
    /* white-space: nowrap; */
    font-size: ${(props) => props.fontSize}px;
  }

  th {
    font-weight: 600;
  }

  td > svg {
    cursor: pointer;
  }
`;

const Table = ({ children, fontSize }) => {
  return <CustomTable fontSize={fontSize}>{children}</CustomTable>;
};

export default Table;
