import React from 'react';
import styled from '@emotion/styled';
import { NavLink, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.div`
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 500;

    & > a, & > span {
        color: white;
        display: flex;
        align-items: center;
        padding: 10px 5px; 
        cursor: pointer;
    }

    & > a:hover, & > span:hover {
        background: #242e68;
        color: white;
    }

    hr {
        margin: 5px;
        border-top: 1px solid white;
    }
`;

const NavigationList = ({ list, icon = null, counts }) => {
    const history = useHistory();

    const itemContent = (item) => {
        return <>
            {item.icon ? <FontAwesomeIcon style={{ marginRight: 10, color: item.color }} icon={item.icon} /> : icon}
            {item.count ? item.label + ' (' + counts[item.count] + ')' : item.label}
        </>
    }

    return (
        <Container>
            {list.filter(f => f.visible).map(item => {
                let url = !item.direct ? history.location.pathname : '';
                if (url[url.length - 1] === '/') {
                    url = url.substr(0, url.length - 1);
                }
                url += item.path;

                return (
                    <React.Fragment key={url}>
                        {item.divider && <hr />}
                        {item.tooltip ? <span data-tip data-for="note-tip">
                            {item.icon ? <FontAwesomeIcon style={{ marginRight: 10, color: item.color }} icon={item.icon} /> : icon}
                            {item.count ? item.label + '(' + counts[item.count] + ')' : item.label}
                        </span> : (!item.disabled ?
                                <NavLink to={{ pathname: url, state: history.location.state }}>{itemContent(item)}</NavLink> :
                                <div style={{
                                    color: "white",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "10px 5px",
                                    cursor: "default",
                                    opacity: "0.5"
                                }} title={item.disabledTitle}>{itemContent(item)}</div>
                            )
                        }
                        {item.dividerAfter && <hr />}
                    </React.Fragment>
                );
            })}
        </Container>
    );
};

export default NavigationList;
