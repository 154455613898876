export const getToken = () => {
  const expiry = localStorage.getItem('tokenExpiry');
  const token = localStorage.getItem('jwt');

  const exp = isNaN(+expiry) ? expiry : +expiry;
  if (token && new Date(exp) > Date.now() / 1000) {
    return token;
  }

  window.localStorage.clear();
  window.sessionStorage.clear();
  return null;
};
  