import React, { Component } from 'react';
import {
MDBTabPane,
MDBTabContent,
MDBInput,
MDBBtn,
MDBNav,
MDBNavItem,
MDBCol,
MDBContainer,
MDBRow,
MDBCard,
MDBCardBody,
} from 'mdbreact';
import agent from '../../agent';
import { connect } from 'react-redux';
import { LOGIN_PAGE_UNLOADED, LOGOUT } from '../../constants/actionTypes';
import Loader from '../../components/loaders/Loader';

const mapDispatchToProps = (dispatch) => ({
onUnload: () => dispatch({ type: LOGIN_PAGE_UNLOADED }),
onClickLogout: () => dispatch({ type: LOGOUT }),
});

const mapStateToProps = (state) => {
return { currentUser: state.common.currentUser };
};

let i = 0;
let tempAccountArray = [1];

class SavedAskComplete extends Component {
constructor(props) {
  super(props);

  this.state = {
    regionalManager: null,
    contactTitle: null,
    name: null,
    contactName: null,
    salesRep: null,
    accountNumber: null,
    facilityName: null,
    facilityStreet: null,
    facilitySuite: null,
    facilityCity: null,
    facilityZipCode: null,
    isNewBusiness: false,
    competitor: null,
    currentPrice: null,
    requestedPrice: null,
    dealerPrice: null,
    estUsage: null,
    purchaseType: null,
    jitName: null,
    hospital: null,
    accounts: [],
    activeItem: '1',
    activeDirectorItem: '1',
    blue: true,
    savedKit: null,
    reloadKey: Math.random(),
    kitIdFromBase: null,
    SKU: null,
    kitDetail: null,
    baseKitId: null,
    productFamilyId: null,
    baseCustomKitId: null,
    productFamily: null,
    orderedKitid: null,
    isDirectorPurchase: true,
    currentKitId: null,
    purchaseDistributor: true,
    showJitName: true,
  };
  this.submitForm = this.submitForm.bind(this);
  this.changeColor = this.changeColor.bind(this);
  this.accountValue = this.accountValue.bind(this);
}

componentWillMount() {
  const urlParams = new URLSearchParams(window.location.search);
  const KitId = urlParams.get('savedKitId');
  const token = window.localStorage.getItem('jwt');
  if (token) {
    if (KitId > 0) {
      agent.KITMAKER.displaynewComponent(KitId).then((res) => {
        this.setState({ savedKit: res });
        this.setState({ SKU: res.name });
        this.setState({ regionalManager: res.regionalManager });
        this.setState({ contactTitle: res.contactTitle });
        this.setState({ contactName: res.contactName });
        this.setState({ salesRep: res.salesRep });
        this.setState({ accountNumber: res.accountNumber });
        this.setState({ facilityName: res.facilityName });
        this.setState({ facilityStreet: res.facilityStreet });
        this.setState({ facilitySuite: res.facilitySuite });
        this.setState({ facilityCity: res.facilityCity });
        this.setState({ facilityZipCode: res.facilityZipCode });
        this.setState({ isNewBusiness: res.isNewBusiness });
        this.setState({ activeItem: res.isNewBusiness ? '2' : '1' });
        this.setState({ competitor: res.competitor });
        this.setState({ currentPrice: res.currentPrice });
        this.setState({ estUsage: res.estUsage });
        this.setState({ purchaseType: res.purchaseType });
        this.setState({ jitName: res.jitName });
        this.setState({ kitIdFromBase: res.baseKitId });
        this.setState({ baseCustomKitId: res.baseCustomKitId });
        this.setState({ reloadKey: Math.random() });
        this.setState({ currentKitId: KitId });
        this.setState({ productFamilyId: res.productFamilyId });
      });
    }

    agent.KITMAKER.productFamily().then((res) => {
      this.setState({ productFamilyToggle: !this.state.productFamilyToggle });
      this.setState({ productFamily: res });
      (this.state.productFamily ?? []).forEach((element, index) => {
        this.setState({ productFamilyId: element.id });
      });
    });
  } else {
    this.props.onClickLogout();
  }
}

toggle = (tab) => (e) => {
  if (this.state.activeItem !== tab) {
    this.setState({
      activeItem: tab,
    });
    this.setState({ blue: !this.state.blue });
  }
};

toggleDirectPurchase = (tab) => (e) => {
  if (this.state.activeDirectorItem !== tab) {
    this.setState({
      activeDirectorItem: tab,
      isDirectorPurchase: !this.state.isDirectorPurchase,
    });
    this.setState({ Purchaseblue: !this.state.blue });
  }
};

kitmakerComponents = () => {
  let path = '/kitmakercomponents';
  let data = window.location.href.substring(
    window.location.href.lastIndexOf('/') + 1,
  );
  this.props.history.push(path + '/' + data);
};
getName = (event) => {
  if (event.target.value !== null) {
    this.setState({ name: event.target.value });
  } else {
    this.setState({ name: 'CUSTOMIZED-KIT' });
  }
};
validateName = (name, kitid) => {
  // agent.KITMAKER.validateName(name).then((res) => {
  //     if (res === true) {
  //         this.setState({ showName: true });

  //     } else {
  //         this.setState({ showName: false });
  //         window.scroll(0, 0);
  //     }
  // }
  // )

  agent.KITMAKER.validateName(name, kitid).then((res) => {
    if (res === true) {
      this.setState({ showName: true });
    } else {
      this.setState({ showName: false });
      window.scroll(0, 0);
    }
  });
};

handleChange = (event) => {
  switch (event.target.name) {
    case 'kitName':
      if (this.state.currentKitId > 0) {
        this.validateName(event.target.value, this.state.currentKitId);
      } else {
        this.validateName(event.target.value, null);
      }
      this.setState({ name: event.target.value });
      break;
    case 'regionalManager':
      this.setState({ regionalManager: event.target.value });
      break;
    case 'contactTitle':
      this.setState({ contactTitle: event.target.value });
      break;
    case 'contactName':
      this.setState({ contactName: event.target.value });
      break;
    case 'salesRep':
      this.setState({ salesRep: event.target.value });
      break;
    case 'accountNumber':
      this.setState({ accountNumber: event.target.value });
      break;
    case 'facilityName':
      this.setState({ facilityName: event.target.value });

      break;

    case 'facilityStreet':
      this.setState({ facilityStreet: event.target.value });

      break;
    case 'facilitySuite':
      this.setState({ facilitySuite: event.target.value });

      break;
    case 'facilityCity':
      this.setState({ facilityCity: event.target.value });

      break;

    case 'facilityZipCode':
      this.setState({ facilityZipCode: event.target.value });

      break;
    case 'isNewBusiness':
      this.setState({ isNewBusiness: event.target.value });
      break;
    case 'competitor':
      this.setState({ competitor: event.target.value });
      break;
    case 'competitorPrice':
      this.setState({
        currentPrice: event.target.value === '' ? '0' : event.target.value,
      });
      break;
    case 'currentPrice':
      this.setState({
        currentPrice: event.target.value === '' ? '0' : event.target.value,
      });
      break;
    // case "requestedPrice":
    //     this.setState({ requestedPrice: event.target.value })
    //     break;
    case 'estUsage':
      this.setState({ estUsage: event.target.value });
      break;
    case 'purchaseType':
      this.setState({ purchaseType: event.target.value });
      break;
    case 'jitName':
      this.setState({ jitName: event.target.value });
      break;
    case 'productFamilyId':
      this.setState({ productFamilyId: event.target.value });

      break;
    case 'baseCustomKitId':
      this.setState({ baseCustomKitId: event.target.value });
      break;
    default:
      break;
  }
};
getAccount = (event) => {};

submitForm(ev) {
  let name = null;

  ev.preventDefault();
  tempAccountArray.forEach((element, i) => {
    this.state.accounts.push(document.getElementById('account' + i)?.value);
  });
  this.setState({ accounts: this.state.accounts });
  if (this.state.competitor !== null || this.state.currentPrice !== null) {
    this.setState({ isNewBusiness: true });
  } else {
    this.setState({ isNewBusiness: false });
  }
  if (this.state.name == null) {
    name = 'CUSTOMIZED-KIT';
  } else {
    name = this.state.name;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const savedKitId = urlParams.get('savedKitId');
  let formData = {
    name: name,
    regionalManager: this.state.regionalManager,
    contactTitle: this.state.contactTitle,
    contactName: this.state.contactName,
    salesRep: this.state.salesRep,
    accountNumber: this.state.accountNumber,
    facilityName: this.state.facilityName,
    facilityStreet: this.state.facilityStreet,
    facilityCity: this.state.facilityCity,
    facilitySuite: this.state.facilitySuite,
    facilityZipCode: this.state.facilityZipCode,
    isNewBusiness: this.state.isNewBusiness,
    competitor: this.state.competitor,
    requestedPrice: this.state.requestedPrice,
    currentPrice:
      this.state.currentPrice !== null ? this.state.currentPrice : '0',
    estUsage: this.state.estUsage,
    isDirectPurchase: this.state.isDirectorPurchase,
    purchaseType: this.state.purchaseType,
    jitName: this.state.purchaseDistributor ? this.state.jitName : null,
    accounts: this.state.accounts,
    baseKitId: null,
    productFamilyId: this.state.productFamilyId,
    baseCustomKitId: null,
  };
  if (this.state.currentKitId > 0) {
    this.validateName(name, this.state.currentKitId);
  } else {
    this.validateName(name, null);
  }

  if (
    (this.state.purchaseDistributor === true &&
      this.state.jitName !== '' &&
      this.state.jitName !== null) ||
    this.state.isDirectorPurchase === true
  ) {
    this.setState({ showJitName: true });
    agent.KITMAKER.updateSavedKit(formData, savedKitId).then((res) => {
      this.setState({ customKits: res });
      this.props.history.push(
        '/kitmakercomponents?KitFromBase=' +
          this.state.productFamilyId +
          '&kit=' +
          this.state.SKU +
          '&kitId=' +
          savedKitId +
          '&orederedKitId=' +
          savedKitId +
          '&isFavourite=0',
      );
    });
  } else if (this.state.purchaseDistributor === false) {
    agent.KITMAKER.updateSavedKit(formData, savedKitId).then((res) => {
      this.setState({ customKits: res });
      this.props.history.push(
        '/kitmakercomponents?KitFromBase=' +
          this.state.productFamilyId +
          '&kit=' +
          this.state.SKU +
          '&kitId=' +
          savedKitId +
          '&orederedKitId=' +
          savedKitId +
          '&isFavourite=0',
      );
    });
  }
}

accountValue = () => {
  tempAccountArray.push(document.getElementById('account' + i).value);
  if (document.getElementById('account' + i).value !== '') {
    i = i + 1;
    let Id = 'account' + i;
    var node = document.createElement('input');
    var nodeIcon = document.createElement('span');
    nodeIcon.setAttribute('className', 'addicon');
    nodeIcon.setAttribute('value', '+');
    var textnode = document.createTextNode('+');
    node.setAttribute('type', 'text');
    node.setAttribute('value', '');
    node.setAttribute('Id', Id);
    node.setAttribute('name', 'Test Name');
    node.setAttribute('class', 'form-control boxinput');
    node.setAttribute('min', '0');
    node.setAttribute('title', 'Enter Alphanumeric Values');
    //node.setAttribute("inputMode", "numeric");
    node.setAttribute('pattern', '[A-Za-z0-9_]+$');
    node.appendChild(textnode);
    node.appendChild(nodeIcon);
    document.getElementById('accountDiv').appendChild(node);
  }
};
changeColor = () => {
  this.setState({ blue: !this.state.blue });
};

addTextBox = () => {
  var element = document.createElement('input');
  element.setAttribute('type', 'text');
  element.setAttribute('value', '');
  element.setAttribute('name', 'Test Name');
};

render() {
  if (this.state.savedKit === null || this.state.productFamily === null) {
    return <Loader />;
  } else {
    let data = this.state.savedKit;
    let currentPrice = data.currentPrice === null ? '0' : data.currentPrice;
    const alertName = (
      <span className="warntext">
        Name already exists.Please choose another name for your Custom Kit.
      </span>
    );
    const alertJitName = (
      <span className="warntext">Please fill this field.</span>
    );
    var productFamily = null;
    productFamily = this.state.productFamily.map((detail) => {
      return <option value={detail.id}>{detail.name}</option>;
    });
    return (
      <div className="main-layout">
        <div className="content-area">
          <MDBContainer fluid>
            <h5 className="mt-5 mt-sm-5 mt-lg-0 mt-md-0">
              Arrow select products request form
            </h5>
            <MDBRow>
              <MDBCol md="12">
                <MDBCard className="kitmaker mt-0">
                  <MDBCardBody className="p30">
                    <form onSubmit={this.submitForm}>
                      <MDBRow>
                        <MDBCol md="3" mt="1">
                          <p></p>
                          <br />
                          <select
                            className="browser-default custom-select selectOptions"
                            name="productFamilyId"
                            required
                            onChange={this.handleChange}
                            value={this.state.productFamilyId}
                          >
                            <option value="">Product Family</option>
                            {productFamily}
                          </select>
                        </MDBCol>
                        <MDBCol md="8">
                          <MDBInput
                            label="What would you like to save this kit request in your profile as ? *"
                            type="text"
                            autoFocus={true}
                            valueDefault={
                              this.state.SKU ? this.state.SKU : ''
                            }
                            key={this.state.reloadKey}
                            onBlur={this.handleChange}
                            onChange={this.handleChange}
                            name="kitName"
                            required
                          />

                          {this.state.showName === false ? alertName : null}
                        </MDBCol>
                      </MDBRow>
                      <section id="authentication-layout">
                        <MDBRow>
                          <MDBCol lg="6" md="12" className="pt-1">
                            <MDBRow className="mb-9">
                              <MDBCol md="6">
                                {' '}
                                <MDBInput
                                  label="Regional Manager *"
                                  type="text"
                                  name="regionalManager"
                                  title="Enter only letters"
                                  pattern="^[A-Za-z -]+$"
                                  valueDefault={data.regionalManager}
                                  onChange={this.handleChange}
                                  className="form-control"
                                  autoFocus={true}
                                  required
                                />
                              </MDBCol>
                              <MDBCol md="6">
                                {' '}
                                <MDBInput
                                  label="Sales Representative *"
                                  type="text"
                                  name="salesRep"
                                  title=""
                                  valueDefault={data.salesRep}
                                  onChange={this.handleChange}
                                  required
                                />
                              </MDBCol>
                              <MDBCol md="6">
                                {' '}
                                <MDBInput
                                  label="Purchasing Contact Title *"
                                  type="text"
                                  name="contactTitle"
                                  title="Enter only letters"
                                  pattern="^[A-Za-z -]+$"
                                  valueDefault={data.contactTitle}
                                  onChange={this.handleChange}
                                  required
                                />
                              </MDBCol>
                              <MDBCol md="6">
                                {' '}
                                <MDBInput
                                  label="Purchasing Contact *"
                                  type="text"
                                  name="contactName"
                                  title="Enter only letters"
                                  pattern="^[A-Za-z -]+$"
                                  valueDefault={data.contactName}
                                  onChange={this.handleChange}
                                  required
                                />
                              </MDBCol>
                              <MDBCol md="12">
                                {' '}
                                <MDBInput
                                  label="Account Number *"
                                  type="text"
                                  title="Enter Alphanumeric Values"
                                  pattern="^[A-Za-z0-9_]+$"
                                  name="accountNumber"
                                  valueDefault={data.accountNumber}
                                  onChange={this.handleChange}
                                  required
                                />
                              </MDBCol>
                              {/* <MDBCol md="12"><MDBInput label="Facility Name and Address *" type="textarea" name="facility" title="" valueDefault={data.facility} onChange={this.handleChange} required /></MDBCol> */}
                              <MDBCol md="12">
                                <span>Facilty Name And Address</span>
                              </MDBCol>
                              {/* <MDBCol md="12"><MDBInput label="Facility Name *" type="text" name="facilityName" title="" valueDefault={data.facilityName} onChange={this.handleChange} required /></MDBCol>
                                                                  <MDBCol md="6"><MDBInput label="Street *" type="text" name="facilityStreet" title="" valueDefault={data.facilityStreet} onChange={this.handleChange} required /></MDBCol>
                                                                  <MDBCol md="6"><MDBInput label="Suite *" type="text" name="facilitySuite" title="" valueDefault={data.facilitySuite} onChange={this.handleChange} required /></MDBCol>
                                                                  <MDBCol md="6"><MDBInput label="City *" type="text" name="facilityCity" title="" valueDefault={data.facilityCity} onChange={this.handleChange} required /></MDBCol>
                                                                  <MDBCol md="6"><MDBInput label="ZipCode *" type="text" name="facilityZipCode" title="" valueDefault={data.facilityZipCode} onChange={this.handleChange} required /></MDBCol> */}
                              <MDBCol md="12">
                                <MDBInput
                                  label="Facility Name *"
                                  type="text"
                                  name="facilityName"
                                  title="Enter only characters"
                                  maxLength="50"
                                  pattern="^[A-Za-z -]+$"
                                  valueDefault={data.facilityName}
                                  onChange={this.handleChange}
                                  required
                                />
                              </MDBCol>
                              <MDBCol md="6">
                                <MDBInput
                                  label="Street *"
                                  type="text"
                                  name="facilityStreet"
                                  title="Special charcters are not allowed except Coma (,)"
                                  pattern="^[A-Za-z0-9, _]*[A-Za-z0-9,][A-Za-z0-9, _]*$"
                                  valueDefault={data.facilityStreet}
                                  onChange={this.handleChange}
                                  required
                                />
                              </MDBCol>
                              <MDBCol md="6">
                                <MDBInput
                                  label="Suite/Apt "
                                  type="text"
                                  name="facilitySuite"
                                  title="Special charcters are not allowed"
                                  pattern="^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$"
                                  valueDefault={data.facilitySuite}
                                  onChange={this.handleChange}
                                />
                              </MDBCol>
                              <MDBCol md="6">
                                <MDBInput
                                  label="City, State *"
                                  type="text"
                                  name="facilityCity"
                                  title="Format is City, State (Chicago, Illinois)"
                                  pattern="^[A-Z a-z]*,[ A-Za-z][A-Za-z]*$"
                                  valueDefault={data.facilityCity}
                                  onChange={this.handleChange}
                                  required
                                />
                              </MDBCol>
                              <MDBCol md="6">
                                <MDBInput
                                  label="ZipCode *"
                                  type="text"
                                  name="facilityZipCode"
                                  title="Special charcters are not allowed"
                                  pattern="^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _]*$"
                                  valueDefault={data.facilityZipCode}
                                  onChange={this.handleChange}
                                  required
                                />
                              </MDBCol>
                              <MDBCol md="12" className="text-center">
                                <p className="info">
                                  Add account from sister hospital using
                                  Custom Kits (if applicable)
                                </p>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                className="text-center offset-md-3"
                              >
                                <label
                                  htmlFor="defaultFormLoginEmailEx"
                                  className="grey-text"
                                >
                                  {' '}
                                  Subsidiary Account Number
                                  <span
                                    data-placement="right"
                                    data-toggle="tooltip"
                                    className="tooltips"
                                  >
                                    <span className="tooltipstext">
                                      <i
                                        className="fa fa-info"
                                        aria-hidden="true"
                                      ></i>
                                      <span>ACCOUNT#</span>
                                      <br />
                                    </span>
                                  </span>{' '}
                                </label>
                                <div id="accountDiv">
                                  <input
                                    type="text"
                                    id="account0"
                                    min="0"
                                    title="Enter Alphanumeric Values"
                                    pattern="^[A-Za-z0-9_]+$"
                                    className="form-control boxinput "
                                    value={this.state.accounts[0]}
                                    defaultValue={data.accounts[0]}
                                  />
                                  <span
                                    className="addicon"
                                    value="1"
                                    onClick={() => this.accountValue()}
                                  >
                                    +
                                  </span>
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBCol>
                          <MDBCol lg="6" md="12" className="pt-1 pr0">
                            <MDBRow className="mb-9 text-center">
                              <MDBCol md="10" className="offset-md-1 p-xs-0">
                                <h6>The ASK product is</h6>
                                <MDBCol md="12" className="p-xs-0">
                                  <MDBNav className="nav-tabs mt-4">
                                    <MDBNavItem></MDBNavItem>
                                    <MDBNavItem></MDBNavItem>
                                  </MDBNav>
                                  <div className="btn-section mtb2 tab-area">
                                    <MDBBtn
                                      className="mb20-xs btn-whitee"
                                      active={this.state.activeItem === '1'}
                                      onClick={this.toggle('1')}
                                    >
                                      {' '}
                                      Replacing Existing Arrow Business
                                    </MDBBtn>
                                    <MDBBtn
                                      className="mb20-xs btn-outline-indigo notwhite"
                                      active={this.state.activeItem === '2'}
                                      onClick={this.toggle('2')}
                                    >
                                      {' '}
                                      New Business
                                    </MDBBtn>
                                  </div>
                                  <MDBTabContent
                                    activeItem={this.state.activeItem}
                                  >
                                    <MDBTabPane tabId="1" role="tabpanel">
                                      <MDBCol md="12">
                                        {' '}
                                        <MDBInput
                                          label="Current Price ($):"
                                          min="0"
                                          title="Enter only numbers(or with decimals)"
                                          inputMode="numeric"
                                          pattern="^(\d*\.)?\d+$"
                                          type="text"
                                          name="currentPrice"
                                          valueDefault={currentPrice}
                                          onChange={this.handleChange}
                                        />
                                      </MDBCol>
                                    </MDBTabPane>
                                    <MDBTabPane tabId="2" role="tabpanel">
                                      <MDBCol md="12">
                                        {' '}
                                        <MDBInput
                                          label="Current Competitor:"
                                          type="text"
                                          name="competitor"
                                          valueDefault={
                                            data.competitor
                                              ? data.competitor
                                              : 0
                                          }
                                          onChange={this.handleChange}
                                        />
                                      </MDBCol>
                                      <MDBCol md="12">
                                        {' '}
                                        <MDBInput
                                          label="Competitor's Price ($):"
                                          min="0"
                                          title="Enter only numbers(or with decimals)"
                                          inputMode="numeric"
                                          pattern="^(\d*\.)?\d+$"
                                          type="text"
                                          name="competitorPrice"
                                          valueDefault={data.currentPrice}
                                          onChange={this.handleChange}
                                        />
                                      </MDBCol>
                                    </MDBTabPane>
                                  </MDBTabContent>
                                </MDBCol>
                              </MDBCol>
                              {/* <MDBCol md="12" className="mt-4">   <h6>Request pricing for new product</h6></MDBCol> */}
                              {/* <MDBCol md="12"> <MDBInput label="Requested Price ($)" min="0" title="Enter only numbers(or with decimals)" inputMode="numeric" pattern="^(\d*\.)?\d+$" type="text" name="requestedPrice" valueDefault={data.requestedPrice} onChange={this.handleChange} /></MDBCol> */}
                              <MDBCol md="12">
                                {' '}
                                <MDBInput
                                  label="Estimated Annual Usage in Eaches *"
                                  min="0"
                                  title="Enter only numbers"
                                  inputMode="numeric"
                                  pattern="[0-9]*"
                                  type="text"
                                  name="estUsage"
                                  valueDefault={data.estUsage}
                                  onChange={this.handleChange}
                                  required
                                />
                              </MDBCol>
                              {/* <MDBCol md="6"> <MDBInput label="Purchase type *" type="text" valueDefault={data.purchaseType} name="purchaseType" onChange={this.handleChange} required /></MDBCol>
                                                                  <MDBCol md="6"><MDBInput label="Distributor Name" type="text" name="jitName" valueDefault={data.jitName} onChange={this.handleChange} /></MDBCol> */}

                              <MDBCol md="10" className="offset-md-1 p-xs-0">
                                <MDBNav className="nav-tabs mt-4">
                                  <MDBNavItem></MDBNavItem>
                                  <MDBNavItem></MDBNavItem>
                                </MDBNav>
                                <div className="btn-section mtb2 tab-area">
                                  <MDBBtn
                                    className="mb20-xs btn-whitee"
                                    active={
                                      this.state.activeDirectorItem === '1'
                                    }
                                    onClick={this.toggleDirectPurchase('1')}
                                    value={this.state.isDirectorPurchase}
                                  >
                                    Purchase Type is Direct{' '}
                                  </MDBBtn>
                                  <MDBBtn
                                    className="mb20-xs btn-outline-indigo notwhite"
                                    active={
                                      this.state.activeDirectorItem === '2'
                                    }
                                    onClick={this.toggleDirectPurchase('2')}
                                  >
                                    Purchase Type is Distributor
                                  </MDBBtn>
                                </div>
                                <MDBTabContent
                                  activeItem={this.state.activeDirectorItem}
                                >
                                  <MDBTabPane
                                    tabId="1"
                                    role="tabpanel"
                                  ></MDBTabPane>
                                  <MDBTabPane tabId="2" role="tabpanel">
                                    <MDBCol md="12">
                                      <MDBInput
                                        label="Distributor Name"
                                        id="distributorname"
                                        type="text"
                                        name="jitName"
                                        title=""
                                        valueDefault={data.jitName}
                                        onChange={this.handleChange}
                                      />
                                    </MDBCol>
                                    {this.state.showJitName === true
                                      ? alertJitName
                                      : null}
                                  </MDBTabPane>
                                </MDBTabContent>
                              </MDBCol>
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>
                        <div className="btn-area">
                          <MDBRow className="text-right btn-section">
                            <MDBCol>
                              <MDBBtn
                                className="gbtn  mt-5"
                                type="submit"
                                value="submit"
                              >
                                Continue
                              </MDBBtn>
                            </MDBCol>
                          </MDBRow>
                        </div>
                      </section>
                    </form>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </div>
    );
  }
}
}

export default connect(mapStateToProps, mapDispatchToProps)(SavedAskComplete);
