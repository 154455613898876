import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { clearFallbackComponentSearchList, loadCmsComponentInfo } from '../../../../redux/actions/components';
import SearchSection from './SearchSection';
import FallbackOverview from './FallbackOverview';
// import ImageOverview from './ImageOverview';

const Container = styled.div`
    display: flex;
    justify-content: space-around;
    font-size: 14px;
`;

const CompatibleReplacement = ({ component, onSearch, components, addReplacements, deleteFallback }) => {
    // const [image, setImage] = useState(null);
    // const [activeButton, setActiveButton] = useState('display');
    const [fallback, setFallback] = useState({});

    const { fallbackComponent } = useSelector(state => state.components);

    const dispatch = useDispatch();

    useEffect(() => {
        if (fallbackComponent) {
            // setImage(fallbackComponent.images?.find(img => img.name === 'display'));
            setFallback(fallbackComponent);
        }
    }, [fallbackComponent]);

    const assignComponent = (component) => {
        dispatch(clearFallbackComponentSearchList());
        addReplacements(component);
    };

    const onSearchChange = (e) => {
        if (!e.target.value) {
            dispatch(clearFallbackComponentSearchList());
        }
    };

    // const onImageChange = (name) => {
    //     setImage(fallback.images?.find(img => img.name === name));
    //     setActiveButton(name);
    // };

    const showComponentInfo = (fallback) => {
        dispatch(loadCmsComponentInfo(fallback.id, true))
        setFallback(fallback);
    };

    return (
        <Container>
            <SearchSection
                onSearch={onSearch}
                assignComponent={assignComponent}
                onSearchChange={onSearchChange}
                components={components}
                component={component}
                replacements={component.replacements}
                deleteFallback={deleteFallback}
                showComponentInfo={showComponentInfo}
                fallback={fallback}
            />
            <FallbackOverview fallback={fallback} />
            {/* <ImageOverview image={image} activeButton={activeButton} onImageChange={onImageChange} /> */}
        </Container>
    );
};

export default CompatibleReplacement;
