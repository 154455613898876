import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
    display: flex;
    width: 95%;
    box-shadow: 0 4px 8px 2px rgba(0,0,0,0.3);
    border-radius: 0;
    align-self: center;
    margin: 4px auto 20px auto;
    padding: 20px;
    min-height: calc(100% - 24px);
`;

const Wrapper = ({ children }) => {
    return (
        <Container>
            {children}
        </Container>
    );
};

export default Wrapper;
