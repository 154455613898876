import React, { Component } from 'react';
import styled from '@emotion/styled';
import { faBars, faSort, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import agent from '../../agent';
import p1 from '../../shared/img/kitsDefaultImage.png';
import { connect } from 'react-redux';
import {
  MDBModalBody,
  MDBCardTitle,
  MDBModalFooter,
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBTabPane,
  MDBTabContent,
} from 'mdbreact';
import { LOGOUT } from '../../constants/actionTypes';
import Loader from '../../components/loaders/Loader';
import Pagination from '../../components/Pagination/Pagination';
import { loadCategories } from '../../redux/actions/categories';
import KitMakerComponentsMenu from './KitMakerComponentsMenu';
import IconToggleGroup from '../../components/IconToggleGroup/IconToggleGroup';
import IconToggle from '../../components/IconToggleGroup/IconToggle';
import ComponentsGrid from '../../components/ComponentsGrid/ComponentsGrid';
import ComponentGridCard from '../../components/ComponentsGrid/ComponentGridCard';
import FancyScroll from '../../components/FancyScroll';
import CustomButton from '../../components/Buttons/CustomButton';
import { KitMakerPdfButton } from './KitMakerPdfButton';
import { orderBy } from 'lodash-es';
import SpecialInstructions from './SpecialInstructions';
import { setSpecialInstructions } from '../../redux/actions/kits';
import Modal from '../../components/Modal/Modal';

const ToggleIconsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1.5rem;
`;

const ImageCarouselContainer = styled.div`
  padding-top: 55px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .carousel-indicators {
    margin-left: 0;
    margin-right: 0;
  }

  img {
    max-height: 300px !important;
  }
`;

const ContinueButton = styled(CustomButton)`
  font-size: 0.75rem;
  border-radius: 0;
  max-width: 100px;
  margin-left: auto;
`;

const GridViewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const mapStateToProps = (state) => {
  return {
    currentUser: state.common.currentUser,
    categories: state.categories,
  };
};
const mapDispatchToProps = (dispatch) => ({
  onClickLogout: () => dispatch({ type: LOGOUT }),
  loadCategories: () => dispatch(loadCategories()),
});

let ComponentArray = {
  components: [
    {
      description: 'No results found',
    },
  ],
  totalPages: 0,
  totalCount: 0,
};
let KitArray = {
  kits: [
    {
      description: 'No results found',
    },
  ],
  totalPages: 0,
  totalCount: 0,
};

let CustomKitArray = {
  components: [
    {
      id: 0,
      number: null,
      description: null,
      labelName: null,
      quantity: null,
      imageUrl: 'Please add components',
    },
  ],
};

let storedNode = ' ';
let storedNodeId = ' ';
let storedPopularNodeId = ' ';
let storedPopularNode = ' ';

const componentsView = {
  LIST: 'list',
  GRID: 'grid',
};

class KitMakerComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1,
      modal: false,
      duplicateModal: false,
      componentData: null,
      currentPage: 1,
      everyPage: 12,
      //selectedNodeArray: [],
      selectedParentNode: [],
      isChecked: false,
      loadingState: false,
      kitDetailData: null,
      reloadKey: `f${(+new Date()).toString(16)}`,
      selectedComponentList: [],
      selectedKitComponentList: [],
      kit: null,
      searachedCataegory: [],
      ExistingKitData: null,
      IsExistingKit: false,
      IsExistingKitComponents: false,
      componentLength: '',
      ExistingkitComponents: null,
      IsJustLikeKit: false,
      KitData: null,
      justLikeKitData: null,
      isNoResult: false,
      updateQuantity: [1],
      newcomponents: [],
      deletedComponents: [],
      kitName: null,
      KITID: null,
      prevOrderId: null,
      isInitialComponents: false,
      upperPageBound: 6,
      lowerPageBound: 0,
      searchText: '',
      currentKitPage: 1,
      everyKitPage: 12,
      upperPageKitBound: 6,
      lowerPageKitBound: 0,
      pageBound: 3,
      buttonDisables: false,
      getCartComponents: null,
      isFavourrite: 0,
      starActive: false,
      isStarredActive: null,
      isStar: null,
      starResult: false,
      duplicateComponents: null,
      savedKitData: null,
      activeTabItem: '1',
      justLikeCustomKit: null,
      pageReload: null,
      isSearch: false,
      partTypeList: null,
      prevOrderData: null,
      view: componentsView.LIST,
      isFavouriteLoading: false,
      isPdfLoading: false,
      specialInstructions: null,
      sortDirection: 'asc',
    };
    this.listRef = React.createRef();

    const token = window.localStorage.getItem('jwt');
    if (token) {
    } else {
      this.props.onClickLogout();
    }

    this.onSearch = this.onSearch.bind(this);
    this.incrementItem = this.incrementItem.bind(this);
    this.decreaseItem = this.decreaseItem.bind(this);
    this.kitMakerSurvey = this.kitMakerSurvey.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onJustLike = this.onJustLike.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleKitClick = this.handleKitClick.bind(this);
    this.getSavedKitComponents = this.getSavedKitComponents.bind(this);
    this.handleClickJustLike = this.handleClickJustLike.bind(this);
    this.kitCatalogDetail = this.kitCatalogDetail.bind(this);
    this.useAsBase = this.useAsBase.bind(this);
    this.handlePdfLoadingStatus = this.handlePdfLoadingStatus.bind(this);
    this.sortBySku = this.sortBySku.bind(this);
  }

  getJustLikeCustomKitList(kitId) {
    agent.SAVEDASK.savedASKJustLikeKit(kitId, this.state.currentPage - 1).then(
      (res) => {
        if (res === null) {
          this.setState({ justLikeCustomKit: KitArray });
        } else {
          if (res.totalCount > 0) {
            this.setState({ justLikeCustomKit: res });
          } else {
            this.setState({ justLikeCustomKit: KitArray });
          }
        }
      },
    );
  }

  sortBySku() {
    this.setState((prev) => ({
      ...prev,
      sortDirection: prev.sortDirection === 'asc' ? 'desc' : 'asc',
    }));
  }

  componentWillMount() {
    const urlParamss = new URLSearchParams(window.location.search);
    const kitFromBase = urlParamss.get('KitFromBase');
    const kitId = urlParamss.get('kitId');
    const name = urlParamss.get('kit');
    const prevOrder = urlParamss.get('orederedKitId');
    const isFavourite = urlParamss.get('isFavourite');
    let returnedTarget = [];
    const token = window.localStorage.getItem('jwt');
    if (token) {
      this.props.loadCategories();
      let storedComponentNode = window.sessionStorage.getItem(
        'kitCatalogaddcomponents',
      );
      if (storedComponentNode && storedComponentNode !== ' ') {
        storedNode = JSON.parse(
          window.sessionStorage.getItem('kitCatalogaddcomponents'),
        );
        storedNodeId =
          storedNode &&
          storedNode !== 'null' &&
          storedNode !== ' ' &&
          storedNode != null
            ? storedNode.id
            : '';
      } else {
        storedNode = '';
        storedNodeId = '';
      }

      let storedPopularNode =
        window.sessionStorage.getItem('PopularCategoryKit');
      if (storedPopularNode && storedPopularNode !== ' ') {
        storedPopularNode = JSON.parse(
          window.sessionStorage.getItem('PopularCategoryKit'),
        );
        storedPopularNodeId =
          storedPopularNode &&
          storedPopularNode !== 'null' &&
          storedPopularNode !== ' ' &&
          storedPopularNode !== null
            ? storedPopularNode.id
            : '';
        this.setState({ searachedCataegory: storedPopularNode });
      } else {
        storedPopularNode = '';
        storedPopularNodeId = '';
      }

      this.setState({ kitName: name, KITID: kitId });
      if (kitFromBase === 'null' && prevOrder === 'null') {
        agent.KITMAKER.displaynewComponent(kitId).then((res) => {
          if (res.components.length === 0) {
            this.setState({ kitDetailData: res, isInitialComponents: false });
          } else {
            this.setState({ kitDetailData: res, isInitialComponents: false });
            let tempQuantity = [];
            res.components.forEach((element, index) => {
              tempQuantity[index] = element.quantity;
            });
            this.setState({
              updateQuantity: tempQuantity,
              reloadKey: `f${(+new Date()).toString(16)}`,
            });
          }
        });
      } else if (prevOrder > 0 || kitFromBase === 'null') {
        agent.KITMAKER.displaynewComponent(prevOrder).then((response) => {
          if (response) {
            this.setState({ prevOrderData: response });
          }
          response.components.forEach((element) => {
            let objj = { id: element.id, quantity: element.quantity };
            returnedTarget.push(objj);
            objj = null;
          });
          agent.KITMAKER.addComponentToKit(kitId, returnedTarget).then(
            (res) => {
              agent.KITMAKER.displaynewComponent(kitId).then((res) => {
                if (res === null) {
                  this.setState({ kitDetailData: CustomKitArray });
                } else {
                  this.setState({
                    reloadKey: `f${(+new Date()).toString(16)}`,
                    kitDetailData: response,
                  });
                  let tempQuantity = [];
                  res.components.forEach((element, index) => {
                    tempQuantity[index] = element.quantity;
                  });
                  this.setState({ updateQuantity: tempQuantity });
                }
              });
            },
          );
        });
      } else if (kitId > 0) {
        agent.KITCATLOG.kitbyId(kitFromBase).then((res) => {
          if (res === null) {
            this.setState({
              kitDetailData: CustomKitArray,
              reloadKey: `f${(+new Date()).toString(16)}`,
            });
          } else {
            // let componentLength = null;
            agent.KITMAKER.displaynewComponent(kitId).then((result) => {
              // componentLength = result.components.length;
              if (res.components.length === 0) {
                agent.KITMAKER.displaynewComponent(kitId).then((displayres) => {
                  this.setState({ kitDetailData: displayres });
                  let tempQuantity = [];
                  displayres.components.forEach((element, index) => {
                    tempQuantity[index] = element.quantity;
                  });
                  this.setState({
                    updateQuantity: tempQuantity,
                    reloadKey: `f${(+new Date()).toString(16)}`,
                  });
                });
              } else {
                res.components.forEach((element) => {
                  let objj = { id: element.id, quantity: element.quantity };
                  returnedTarget.push(objj);
                  objj = null;
                });
                agent.KITMAKER.addComponentToKit(kitId, returnedTarget).then(
                  (res) => {
                    agent.KITMAKER.displaynewComponent(kitId).then((res) => {
                      if (res === null) {
                        this.setState({ kitDetailData: CustomKitArray });
                      } else {
                        this.setState({
                          kitDetailData: this.state.prevOrderData || res,
                        });
                        let tempQuantity = [];
                        res.components.forEach((element, index) => {
                          tempQuantity[index] = element.quantity;
                        });
                        this.setState({
                          updateQuantity: tempQuantity,
                          reloadKey: `f${(+new Date()).toString(16)}`,
                        });
                      }
                    });
                  },
                );
              }
            });
          }
        });
      }

      if (isFavourite && kitFromBase === 'null') {
        agent.CART.getCartCustomKits().then((res) => {
          res.forEach((element) => {
            let objj = { id: element.id, quantity: 1 };
            returnedTarget.push(objj);
            objj = null;
          });
          agent.KITMAKER.addComponentToKit(kitId, returnedTarget).then(
            (res) => {
              agent.KITMAKER.displaynewComponent(kitId).then((res) => {
                if (res === null) {
                  this.setState({ kitDetailData: CustomKitArray });
                } else {
                  this.setState({
                    kitDetailData: this.state.prevOrderData || res,
                    reloadKey: `f${(+new Date()).toString(16)}`,
                  });
                  let tempQuantity = [];
                  res.components.forEach((element, index) => {
                    tempQuantity[index] = element.quantity;
                  });
                  this.setState({ updateQuantity: tempQuantity });
                }
              });
            },
          );
        });
      }

      agent.SAVEDASK.savedASk().then((res) => {
        if (res === null) {
          this.setState({ savedKitData: CustomKitArray });
        } else {
          this.setState({ savedKitData: res.body });
        }
      });

      agent.CART.getCartCustomKits().then((res) => {
        this.setState({ isStarredActive: res, getCartComponents: res });
      });
    } else {
      this.props.onClickLogout();
    }

    this.getJustLikeCustomKitList(kitId);
  }

  toggleCustomKits = (tab) => (e) => {
    if (this.state.activeTabItem !== tab) {
      this.setState({
        activeTabItem: tab,
        blue: !this.state.blue,
      });
    }
    agent.CART.getCartCustomKits().then((res) => {
      if (res === null) {
        this.setState({ getCartComponents: CustomKitArray });
      } else {
        this.setState({ getCartComponents: res });
      }
    });
  };

  customKits() {
    const p = document.querySelectorAll('.onPrd');
    const x = document.getElementById('mySidenav');
    const y = document.getElementsByClassName('ryttray');
    const z = document.getElementById('onTray');

    let stil = window.getComputedStyle(x).getPropertyValue('display');
    if (stil === 'none') {
      x.style.display = 'inline';
      y[0].style.display = 'inline-table';
      y[0].style.right = '0';
      y[0].style.position = 'relative';
      y[0].style.top = '0';
      z.style.flex = '0 0 75%';
      z.style.maxWidth = '75%';
      for (let i = 0; i < p.length; i++) {
        p[i].classList.add('col-xl-6');
      }
    } else {
      x.style.display = 'none';
      y[0].style.right = '0';
      y[0].style.position = 'fixed';
      y[0].style.top = '65px';
      z.style.flex = '0 0 100%';
      z.style.maxWidth = '100%';
      for (let i = 0; i < p.length; i++) {
        p[i].classList.remove('col-xl-6');
      }
    }
  }

  kitCatalogDetail = (kitId) => {
    let path = `/kit`;
    let data = kitId;
    this.props.history.push(path + '/' + data);
    window.location.reload();
  };

  useAsBase = (kitId) => {
    let path = '/kitmaker';
    let data = kitId;
    this.props.history.push(path + '/' + data);
    window.location.reload();
  };

  onSearch = () => {
    let searchText = this.state.searchText;
    let data = this.state.justLikeKitData;

    window.sessionStorage.setItem('kitCatalogaddcomponents', '');
    storedNode = '';
    storedNodeId = '';

    if (this.state.IsExistingKit) {
      agent.KITCATLOG.kitcatalog(
        storedNodeId,
        searchText,
        '',
        '',
        this.state.everyPage,
        this.state.currentPage - 1,
      ).then((res) => {
        if (res.totalCount === 0) {
          this.setState({
            isNoResult: true,
            ExistingKitData: KitArray,
            isSearch: false,
          });
        } else {
          this.setState({
            ExistingKitData: res,
            isSearch: false,
            pageReload: `f${(+new Date()).toString(16)}`,
          });
        }
      });
    } else if (!this.state.IsExistingKit) {
      agent.Home.components(
        storedNodeId,
        '',
        searchText,
        this.state.everyPage,
        this.state.currentPage - 1,
      ).then((res) => {
        if (res.totalCount === 0) {
          this.setState({ componentData: ComponentArray });
        } else {
          this.setState({
            componentData: res,
            reloadKey: `f${(+new Date()).toString(16)}`,
            pageReload: `f${(+new Date()).toString(16)}`,
          });
          // res.categories && res.categories.forEach(element => {
          //     this.state.searachedCataegory.push(element);
          // });
          //this.setState({ searachedCataegory: this.state.searachedCataegory })
        }
      });
    } else if (this.state.IsJustLikeKit) {
      agent.KITCATLOG.kitcatalog(
        storedNodeId,
        searchText,
        '',
        data.id,
        this.state.everyPage,
        this.state.currentPage - 1,
      ).then((res) => {
        if (res.totalCount === 0) {
          this.setState({ KitData: KitArray });
        } else {
          this.setState({
            KitData: res,
            pageReload: `f${(+new Date()).toString(16)}`,
          });
        }
      });
    } else {
      agent.Home.components(
        storedNodeId,
        '',
        searchText,
        this.state.everyPage,
        this.state.currentPage - 1,
      ).then((res) => {
        if (res.totalCount === 0) {
          this.setState({ componentData: ComponentArray });
        } else {
          this.setState({
            componentData: res,
            searachedCataegory: storedNode,
            reloadKey: `f${(+new Date()).toString(16)}`,
            pageReload: `f${(+new Date()).toString(16)}`,
          });
          // res.categories && res.categories.forEach(element => {
          //     this.state.searachedCataegory.push(element);
          // });
        }
      });
    }
  };

  getSavedKitComponents = (kit) => {
    this.props.history.push(
      '/kitmakercomponents?KitFromBase=' +
        null +
        '&kit=' +
        kit.name +
        '&kitId=' +
        kit.id +
        '&orederedKitId=' +
        null +
        '&isFavourite=1',
    );
    window.location.reload();
  };

  renderComponent() {
    this.setState({ currentPage: 1 });
    if (storedNode !== '') {
      if (this.state.IsExistingKit) {
        agent.KITCATLOG.kitcatalog(
          storedNode.id,
          '',
          '',
          '',
          this.state.everyPage,
          this.state.currentPage - 1,
        ).then((res) => {
          if (res.totalCount === 0) {
            this.setState({ ExistingKitData: KitArray });
          } else {
            this.setState({ ExistingKitData: res });
          }
        });
      } else {
        agent.Home.components(
          storedNodeId,
          '',
          '',
          this.state.everyPage,
          0,
        ).then((res) => {
          if (res.totalCount === 0) {
            this.setState({ componentData: ComponentArray });
          } else {
            this.setState({ componentData: res });
            // var inputs = document.querySelectorAll('.check3');
            // for (var i = 0; i < inputs.length; i++) {
            //     inputs[i].checked = false;
            // }

            // var list = this.state.selectedComponentList;
            // while (list.length > 0) { list.splice(0, 1); }
            // this.setState({ selectedComponentList: list });
          }
        });
      }
    } else if (
      storedPopularNode &&
      storedPopularNode !== '' &&
      storedPopularNode !== 'null'
    ) {
      agent.Home.components(
        '',
        storedPopularNodeId,
        '',
        this.state.everyPage,
        this.state.currentPage - 1,
      ).then((res) => {
        if (res.totalCount === 0) {
          this.setState({ componentData: ComponentArray });
        } else {
          this.setState({ componentData: res });
          // this.componentDataWithCustomKit();
        }
      });
    } else {
      if (this.state.IsExistingKit) {
        agent.KITCATLOG.kitcatalog(
          '',
          '',
          '',
          '',
          this.state.everyPage,
          this.state.currentPage - 1,
        ).then((res) => {
          if (res.totalCount === 0) {
            this.setState({ ExistingKitData: KitArray });
          } else {
            this.setState({ ExistingKitData: res });
          }
        });
      } else {
        agent.Home.components(
          '',
          '',
          '',
          this.state.everyPage,
          this.state.currentPage - 1,
        ).then((res) => {
          if (res.totalCount === 0) {
            this.setState({ componentData: ComponentArray });
          } else {
            this.setState({ componentData: res });
          }
        });
      }
    }
  }

  incrementItem = (componentId) => {
    const urlParams = new URLSearchParams(window.location.search);
    const KitId = urlParams.get('kitId');
    const currentComponent = this.state.kitDetailData.components.find(
      (component) => component.id === componentId,
    );
    if (!currentComponent) return;

    const newData = {
      id: componentId,
      quantity: currentComponent.quantity + 1,
    };
    agent.KITMAKER.addNewComponentToKit(newData, KitId).then(() => {
      this.setState({ isInitialComponents: true });
      this.refreshKitDetails();
    });
  };

  decreaseItem = (componentId) => {
    const urlParams = new URLSearchParams(window.location.search);
    const KitId = urlParams.get('kitId');
    const currentComponent = this.state.kitDetailData.components.find(
      (component) => component.id === componentId,
    );
    if (!currentComponent || currentComponent.quantity === 1) return;

    const newData = {
      id: componentId,
      quantity: currentComponent.quantity - 1,
    };
    agent.KITMAKER.addNewComponentToKit(newData, KitId).then(() => {
      this.setState({ isInitialComponents: true });
      this.refreshKitDetails();
    });
  };

  kitMakerSurvey = (kitId) => {
    // very bad solution but I have not another choise
    // this action updating specialInstructions for kit before redirecting on pdf confirm page
    setSpecialInstructions(
      kitId,
      this.state.kitDetailData.specialInstructions,
    ).then(() => {
      let path = '/kitmakercustom';
      const urlParams = new URLSearchParams(window.location.search);
      const KitFromBase = urlParams.get('KitFromBase');
      const isFavourite = urlParams.get('isFavourite');

      agent.KITMAKER.estimatedPrice(kitId)
        .then((res) => {
          this.props.history.push(
            path + '?KitId=' + kitId + '&KitFromBase=' + KitFromBase,
          );
        })
        .catch((error) => {
          this.props.history.push(
            path + '?KitId=' + kitId + '&KitFromBase=' + KitFromBase,
          );
        });
      if (isFavourite >= 0) {
        if (this.state.kitDetailData.components.length > 0) {
          agent.CART.deleteAllCartComponents().then((res) => {});
        }
      }
    });
  };

  // toggle = () => {
  //     this.setState({
  //         modal: !this.state.modal,
  //         IsExistingKitComponents: false,
  //         searchText: '',
  //         partTypeList: this.props.categories.partTypes.filter(partType => partType.isFavorite)
  //     });
  //     window.sessionStorage.setItem("kitCatalogaddcomponents", " ");
  //     window.sessionStorage.setItem("PopularCategoryKit", " ");
  //     storedNode = "";
  //     storedNodeId = "";
  //     storedPopularNode = ""; storedPopularNodeId = "";
  //     agent.Home.components('', '', '', this.state.everyPage, this.state.currentPage - 1).then((res) => {
  //         if (res.totalCount === 0) {
  //             this.setState({ componentData: ComponentArray });
  //         }
  //         else {
  //             this.setState({ componentData: res });
  //         }
  //     })
  // }

  toggleForDuplicate = () => {
    this.setState({
      duplicateModal: !this.state.duplicateModal,
    });
  };

  toggleForDuplicateClose = () => {
    this.setState({
      duplicateModal: !this.state.duplicateModal,
      duplicateComponents: null,
    });
  };

  addDefaultSrc = (ev) => {
    ev.target.src = p1;
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onSearch();
    }
  };

  onRemoveComponent = async (Component) => {
    const urlParams = new URLSearchParams(window.location.search);
    const KitId = urlParams.get('kitId');
    await agent.KITMAKER.deletenewComponent(KitId, Component.id).then(() => {
      agent.KITMAKER.displaynewComponent(KitId).then((res) => {
        if (res === null) {
          this.setState({ kitDetailData: CustomKitArray });
        } else {
          this.setState({
            kitDetailData: res,
            reloadKey: `f${(+new Date()).toString(16)}`,
          });
        }
      });
      this.state.deletedComponents.push(Component);
      this.setState({ deletedComponents: this.state.deletedComponents });
    });

    this.getJustLikeCustomKitList(this.state.KITID);
  };

  onSelectComponent = (component) => {
    const kitId = this.state.KITID;
    const components = this.state.kitDetailData.components;
    const data = [{ id: component.id, quantity: 1 }];
    const duplicate = components.find((comp) => comp.id === component.id);

    if (duplicate) {
      this.setState({ duplicateComponents: [duplicate] });
      return;
    }

    agent.KITMAKER.addComponentToKit(kitId, data)
      .then(() => agent.KITMAKER.displaynewComponent(kitId))
      .then((kit) => {
        this.setState({ kitDetailData: kit });
        this.getJustLikeCustomKitList(this.state.KITID);
      });
  };

  onToggleView(view) {
    this.setState({ view });
  }

  refreshKitDetails = () => {
    const kitId = this.state.KITID;

    agent.KITMAKER.displaynewComponent(kitId).then((kit) => {
      this.setState({ kitDetailData: kit });
    });
  };

  onExistingKit = () => {
    this.setState({
      IsExistingKit: true,
      IsJustLikeKit: false,
      isNoResult: false,
    });
    //let componentList = this.state.selectedNodeArray;
    agent.KITCATLOG.kitcatalog(
      '',
      '',
      '',
      '',
      this.state.everyPage,
      this.state.currentPage - 1,
    ).then((res) => {
      if (res.totalCount === 0) {
        this.setState({ ExistingKitData: KitArray });
      } else {
        this.setState({ ExistingKitData: res });
      }
    });
  };

  onJustLike = (kit) => {
    this.setState({
      IsJustLikeKit: true,
      IsExistingKit: false,
      isNoResult: false,
    });
    agent.KITCATLOG.kitbyId(kit.id).then((res) => {
      if (res === null) {
        this.setState({ justLikeKitData: KitArray });
      } else {
        this.setState({ justLikeKitData: res });
      }
    });

    agent.KITCATLOG.kitcatalog(
      '',
      '',
      '',
      kit.id,
      this.state.everyPage,
      this.state.currentPage,
    ).then((res) => {
      if (res.totalCount === 0) {
        this.setState({ KitData: KitArray });
      } else {
        this.setState({ KitData: res });
      }
    });
  };

  handleChange = ({ target }) => {
    this.setState({
      searchText: target.value,
      currentPage: Number(1),
    });
  };
  handleClick = (data) => {
    this.setState({ componentData: data });
    // this.setPrevAndNextBtnClass(this.state.currentPage);
    // let search = this.state.searchText === null ? " " : this.state.searchText;
    // this.loadComponentData(search, this.state.everyPage, Number(event.target.id) - 1);
  };

  handleClickJustLike = (data) => {
    this.setState({ justLikeCustomKit: data });
  };
  //Existingkit pagination
  handleKitClick = (data) => {
    this.setState({
      ExistingKitData: data,
    });
  };

  getComponentDetail = (componentId) => {
    let path = '/components';
    this.props.history.push(path + '/' + componentId);
  };

  onFavorite = (event, favoriteComponent) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.isFavouriteLoading) {
      return;
    }

    this.setState({
      starActive: !this.state.starActive,
      isFavouriteLoading: true,
    });
    const isAlreadyActive = this.state.isStarredActive.find(
      (value) => value.id === favoriteComponent.id,
    );

    try {
      if (!isAlreadyActive) {
        event.target.setAttribute('class', 'fa fa-star active');
        agent.CART.postCustomKits(favoriteComponent.id).then((res) => {
          agent.CART.getCartCustomKits().then((res) => {
            if (res === null) {
              this.setState({ getCartComponents: KitArray });
            } else {
              this.setState({ getCartComponents: res });
            }

            this.setState({
              isStarredActive: [
                ...this.state.isStarredActive,
                favoriteComponent,
              ],
            });
          });
        });
      } else if (isAlreadyActive) {
        event.target.setAttribute('class', 'fa fa-star');
        agent.CART.deleteCustomKits(favoriteComponent.id).then(() => {
          agent.CART.getCartCustomKits().then((res) => {
            this.setState({
              getCartComponents: res ? res : KitArray,
              isStarredActive: this.state.isStarredActive.filter(
                (value) => value.id !== favoriteComponent.id,
              ),
            });
          });
        });
      }
    } finally {
      this.setState({ isFavouriteLoading: false });
    }
  };

  getComponentUseAsBase = () => {
    let path = `/kitmaker`;
    this.props.history.push(path + '?isFavourite=1');
    window.location.reload();
  };
  kitmakerform = () => {
    let path = '/kitmaker';
    this.props.history.push(path);
    window.location.reload();
  };

  getJustLike = () => {
    let path = `/savedaskjustlike?isFavorite=1`;
    this.props.history.push(path);
    window.location.reload();
  };

  deleteComponents = (event, component) => {
    // agent.CART.deleteCustomKits(component.id).then((res) => {
    //     agent.CART.getCartCustomKits().then((res) => {
    //         if (res === null) {
    //             this.setState({ getCartComponents: ComponentArray });
    //         } else {
    //             this.setState({ getCartComponents: res });
    //             this.setState({ reloadKey: `f${(+new Date()).toString(16)}` });
    //         }
    //     });

    // });
    agent.CART.deleteCustomKits(component.id).then((res) => {
      agent.CART.getCartCustomKits().then((res) => {
        if (res === null) {
          this.setState({
            isStarredActive: ComponentArray,
            getCartComponents: ComponentArray,
          });
        } else {
          this.setState({
            isStarredActive: res,
            getCartComponents: res,
            reloadKey: `f${(+new Date()).toString(16)}`,
          });
        }
      });
      agent.KITMAKER.displaynewComponent(this.state.KITID).then((res) => {
        if (res === null) {
          this.setState({ kitDetailData: ComponentArray });
        } else {
          this.setState({
            kitDetailData: res,
            reloadKey: `f${(+new Date()).toString(16)}`,
          });
        }
      });
    });
  };

  handlePdfLoadingStatus = (status) => {
    this.setState({ isPdfLoading: status });
  };

  render() {
    if (
      this.state.kitDetailData === null ||
      this.state.savedKitData === null ||
      this.state.getCartComponents === null ||
      this.state.justLikeCustomKit === null
    ) {
      return <Loader />;
    } else {
      let savedKitData,
        getCartComponents,
        SKU,
        dataComponents,
        imageUrl,
        dataComponentsCustomJustLikeKit = '';
      let totalComponets;
      let detaildata = this.state.kitDetailData;
      let alertDuplicateComponents = null;
      let staring = [];
      var savedData = this.state.savedKitData;
      var cartComponents = this.state.getCartComponents;
      let JustLikeCustom = this.state.justLikeCustomKit;

      if (this.state.duplicateComponents) {
        alertDuplicateComponents = this.state.duplicateComponents.map(
          (element) => {
            return <li>{element.number}</li>;
          },
        );
      }
      const { view } = this.state;

      savedKitData = savedData.map((kit) => {
        return (
          <div
            id="customkits"
            className="data-list"
            onPointerMove={this.hoverOn}
            onClick={(event) => this.getSavedKitComponents(kit)}
            key={'savedKit' + kit.id}
          >
            <p className="kitName anchorColor" id="customKitName">
              {kit.name}
              <span></span>
            </p>
            <a href="#no" className="kitCount" id="customKitId">
              Components Count: {kit.componentsCount}
            </a>
            <br />
          </div>
        );
      });
      getCartComponents = cartComponents.map((cartComponent) => {
        return (
          <div id="cartCustom" className="data-list">
            <i
              className="fa fa-window-close"
              aria-hidden="true"
              onClick={(event) => this.deleteComponents(event, cartComponent)}
            ></i>
            <a href="#no" className="kitCount" id="customKitId">
              <p>SKU: {cartComponent.number}</p>
            </a>
            <p className="kitName anchorColor" id="customKitName">
              {cartComponent.description}
              <span></span>
            </p>
          </div>
        );
      });
      if (this.state.justLikeCustomKit.totalCount > 0) {
        dataComponentsCustomJustLikeKit = JustLikeCustom.kits.map((dataa) => {
          return (
            <MDBCol xl="3" lg="6" md="12" id={dataa.id} className="onPrd">
              <MDBCard className="product-area prdbrdr">
                <a href={'/kit/' + dataa.id}>
                  <MDBCardImage
                    className="img-fluid"
                    src={dataa.imageUrl === null ? p1 : dataa.imageUrl}
                    onClick={() => this.kitCatalogDetail(dataa.id)}
                    onError={this.addDefaultSrc}
                    alt=""
                  />
                </a>
                <MDBCardBody>
                  <span>{dataa.componentsCount} Components</span>
                  <MDBCardTitle>
                    <a href={'/kit/' + dataa.id}>{dataa.number}</a>
                  </MDBCardTitle>
                  <MDBCardText className="anchorColor">
                    <a href={'/kit/' + dataa.id}>{dataa.description}</a>
                  </MDBCardText>
                  <MDBBtn onClick={() => this.kitCatalogDetail(dataa.id)}>
                    KIT DETAILS
                  </MDBBtn>
                  <MDBBtn
                    className="gbtn"
                    onClick={() => this.useAsBase(dataa.id)}
                  >
                    <i className="fa fa-shopping-basket" aria-hidden="true"></i>
                    USE AS A BASE
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          );
        });
      } else {
        dataComponentsCustomJustLikeKit = this.state.justLikeCustomKit.kits.map(
          (kit) => {
            return (
              <MDBCol
                xl="12"
                lg="12"
                md="12"
                className="text-center"
                key={'customKit' + kit.id}
              >
                <br />
                <p>{kit.description}</p>
              </MDBCol>
            );
          },
        );
      }

      detaildata.components.forEach((element) => {
        if (this.state.isStarredActive) {
          let isExist = this.state.isStarredActive.filter(
            (x) => x.id === element.id,
          );
          if (isExist.length > 0) {
            staring.push('fa fa-star active');
          } else {
            staring.push('fa fa-star');
          }
        } else {
          staring.push('fa fa-star');
        }
      });
      totalComponets = detaildata.components.length;
      const sortedBySkuDetailData = orderBy(
        detaildata.components,
        ['number'],
        [this.state.sortDirection],
      );
      dataComponents = sortedBySkuDetailData.map((component, index) => {
        return (
          <tr align="left">
            <td
              align="center"
              className="cursorptr"
              onClick={() => this.getComponentDetail(component.id)}
            >
              <p>{component.quantity}</p>
            </td>
            <td>
              <div className="tbl-addon">
                <div className="tbl-addon">
                  {!this.state.isPdfLoading && (
                    <>
                      <a href="#no">
                        <i
                          className="fa fa-plus"
                          onClick={() => this.incrementItem(component.id)}
                        ></i>
                      </a>
                      <a href="#no">
                        <i
                          className="fa fa-minus"
                          onClick={() => this.decreaseItem(component.id)}
                        ></i>
                      </a>
                    </>
                  )}
                </div>
              </div>
            </td>
            <td
              className="cursorptr"
              onClick={() => this.getComponentDetail(component.id)}
            >
              <span className="datalngth">{component.number}</span>
            </td>

            <td
              className="cursorptr"
              onClick={() => this.getComponentDetail(component.id)}
            >
              {component.labelName}
            </td>
            <td>
              <div>
                <i
                  onClick={(event) =>
                    this.onFavorite(event, component, staring[index])
                  }
                  id="myfavoritekiComponents"
                  className={staring[index]}
                  aria-hidden="true"
                  key={this.state.reloadKey}
                ></i>
              </div>
            </td>
            {!this.state.isPdfLoading && (
              <td>
                <i
                  className="fa fa-window-close"
                  aria-hidden="true"
                  onClick={() => this.onRemoveComponent(component)}
                ></i>
              </td>
            )}
          </tr>
        );
      });

      imageUrl = detaildata.components.map((component, index) => {
        const imageStyles = {
          flexShrink: 0,
          objectFit: 'contain',
          position: 'relative',
          width: '100%',
          height: '100%',
        };
        return (
          <MDBCarouselItem itemId={index + 1}>
            <MDBView>
              {component.imageUrl !== null ? (
                <img
                  className="img-fluid d-block w-100 mb4"
                  src={component.imageUrl}
                  alt="index"
                  onError={this.addDefaultSrc}
                  style={imageStyles}
                />
              ) : (
                <img
                  className="img-fluid d-block w-100 mb4"
                  src={p1}
                  alt="index"
                  onError={this.addDefaultSrc}
                  style={imageStyles}
                />
              )}
              <h6>{component.number}</h6>
              <p>{component.description}</p>
            </MDBView>
          </MDBCarouselItem>
        );
      });

      let name = this.state.kitName;
      var tempUrl = name.replace(/%20/g, ' ');

      if (name) {
        SKU = tempUrl;
      } else {
        SKU = 'CUSTOMIZED KIT';
      }
      const displayContent = { display: 'block' };
      const hideContent = { display: 'none' };

      return (
        <div className="main-layout">
          <div
            className="content-area"
            style={{ paddingBottom: 0, paddingLeft: 0 }}
          >
            <div
              style={{
                display: 'flex',
                height: 'calc(100vh - 65px)',
              }}
            >
              <div style={{ display: 'flex' }}>
                <KitMakerComponentsMenu
                  sku={SKU}
                  currentKitId={this.state.KITID}
                  selectComponent={this.onSelectComponent}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <MDBContainer fluid>
                  <MDBRow>
                    <MDBCol md="12" id="onTray" className="onPrd">
                      <h5 className="text-capitalize mt-5 mt-sm-5 mt-lg-0 mt-md-0">
                        KITMAKER
                      </h5>

                      <MDBCard className="kitmaker w-100 mt-0">
                        <ToggleIconsWrapper>
                          <IconToggleGroup>
                            <IconToggle
                              active={view === componentsView.GRID}
                              onClick={() =>
                                this.onToggleView(componentsView.GRID)
                              }
                            >
                              <FontAwesomeIcon size="sm" icon={faThLarge} />
                            </IconToggle>
                            <IconToggle
                              active={view === componentsView.LIST}
                              onClick={() =>
                                this.onToggleView(componentsView.LIST)
                              }
                            >
                              <FontAwesomeIcon size="sm" icon={faBars} />
                            </IconToggle>
                            <KitMakerPdfButton
                              kit={this.state.kitDetailData}
                              listRef={this.listRef}
                              handleLoadingStatus={this.handlePdfLoadingStatus}
                            />
                          </IconToggleGroup>
                        </ToggleIconsWrapper>

                        <MDBCardBody className="p30">
                          {view === componentsView.GRID && (
                            <GridViewContainer>
                              <ComponentsGrid ref={this.listRef}>
                                {detaildata.components.map((component) => (
                                  <ComponentGridCard
                                    key={component.id}
                                    component={component}
                                    increaseComponentQuantity={
                                      this.incrementItem
                                    }
                                    decreaseComponentQuantity={
                                      this.decreaseItem
                                    }
                                    removeComponent={this.onRemoveComponent}
                                    getComponentDetail={this.getComponentDetail}
                                    isPdfLoading={this.state.isPdfLoading}
                                  />
                                ))}
                              </ComponentsGrid>
                              <ContinueButton
                                label="Continue"
                                background="#61A431"
                                disabled={totalComponets === 0}
                                onClick={() =>
                                  this.kitMakerSurvey(this.state.KITID)
                                }
                              />
                            </GridViewContainer>
                          )}

                          {view === componentsView.LIST && (
                            <MDBRow>
                              <MDBCol md="9" className="text-left">
                                <FancyScroll>
                                  <div
                                    style={{
                                      ...(detaildata.components.length === 0
                                        ? hideContent
                                        : displayContent),
                                      paddingRight: 10,
                                    }}
                                    key={this.state.reloadKey}
                                    ref={this.listRef}
                                  >
                                    <MDBTable
                                      small
                                      striped
                                      className="componenttbl"
                                      cellSpacing="10"
                                      key={this.state.reloadKey}
                                    >
                                      <MDBTableHead>
                                        <tr align="left">
                                          <th>Quantity</th>
                                          <th></th>
                                          <th
                                            onClick={this.sortBySku}
                                            style={{ cursor: 'pointer' }}
                                          >
                                            SKU #{' '}
                                            <FontAwesomeIcon icon={faSort} />
                                          </th>
                                          <th>Component Name</th>
                                          <th></th>
                                        </tr>
                                      </MDBTableHead>
                                      <MDBTableBody>
                                        {dataComponents}
                                      </MDBTableBody>
                                    </MDBTable>
                                  </div>
                                </FancyScroll>
                              </MDBCol>
                              <MDBCol md="3" className="text-center">
                                <ImageCarouselContainer>
                                  <div>
                                    <MDBCarousel
                                      key={this.state.reloadKey}
                                      activeItem={1}
                                      length={totalComponets}
                                      showControls={
                                        totalComponets > 0 ? true : false
                                      }
                                      showIndicators={
                                        totalComponets > 0 ? true : false
                                      }
                                      className={totalComponets}
                                    >
                                      <MDBCarouselInner>
                                        {imageUrl}
                                      </MDBCarouselInner>
                                    </MDBCarousel>
                                    <SpecialInstructions
                                      kit={this.state.kitDetailData}
                                      setState={(e) =>
                                        this.setState({
                                          kitDetailData: {
                                            ...this.state.kitDetailData,
                                            specialInstructions: e.target.value,
                                          },
                                        })
                                      }
                                    />
                                  </div>

                                  <ContinueButton
                                    label="Continue"
                                    background="#61A431"
                                    disabled={totalComponets === 0}
                                    onClick={() =>
                                      this.kitMakerSurvey(this.state.KITID)
                                    }
                                  />
                                </ImageCarouselContainer>
                              </MDBCol>
                            </MDBRow>
                          )}
                        </MDBCardBody>
                      </MDBCard>

                      {view === componentsView.LIST && (
                        <>
                          <hr className="odivider" />
                          <MDBRow>
                            <MDBCol
                              id="onTray"
                              lg="12"
                              md="12"
                              sm="12"
                              xs="12"
                              className="mt20-xs pl15-xs"
                            >
                              <br />
                              <h5>JUST LIKE CUSTOM KITS </h5>
                              <MDBRow id="paginationjustlike">
                                {dataComponentsCustomJustLikeKit}
                              </MDBRow>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol
                              className="text-center "
                              id="justpagination"
                            >
                              {/* {justLikePager} */}
                              <Pagination
                                key={this.state.pageReload}
                                pageData={this.handleClickJustLike}
                                parent={'kitmakercomponentjustlike'}
                                kitId={this.state.KITID}
                                searchText={this.state.searchText}
                                isSearch={this.state.isSearch}
                              />
                            </MDBCol>
                          </MDBRow>
                        </>
                      )}
                    </MDBCol>
                    <MDBCol
                      lg="3"
                      md="6"
                      sm="6"
                      xs="12"
                      className="mt20-xs pl-lg-0 pl-xl-0"
                    >
                      <div className="rytsidetray">
                        <i
                          className="fa fa-shopping-basket ryttray"
                          aria-hidden="true"
                          onClick={this.customKits}
                        ></i>
                        <div id="mySidenav" className="sidetray kitmaker pt-2 ">
                          <div id="authentication-layout">
                            <div className="btn-section mtb2 tab-area mt-0">
                              <MDBBtn
                                className="m-0 mb20-xs btn-whitee"
                                active={this.state.activeTabItem === '1'}
                                onClick={this.toggleCustomKits('1')}
                              >
                                Custom kits
                              </MDBBtn>
                              <MDBBtn
                                className="m-0  mb20-xs btn-outline-indigo notwhite"
                                active={this.state.activeTabItem === '2'}
                                onClick={this.toggleCustomKits('2')}
                              >
                                {' '}
                                Components
                              </MDBBtn>
                            </div>
                            <MDBTabContent
                              activeItem={this.state.activeTabItem}
                            >
                              <MDBTabPane tabId="1" role="tabpanel">
                                <div className="tbl-scrl">{savedKitData}</div>
                                <br />
                                <MDBBtn
                                  className="fullgbtn m-0"
                                  disabled={
                                    this.state.savedKitData.length === 0
                                      ? true
                                      : false
                                  }
                                  onClick={() => this.kitmakerform()}
                                >
                                  CREATE NEW KIT
                                </MDBBtn>
                              </MDBTabPane>
                              <MDBTabPane tabId="2" role="tabpanel">
                                <div className="tbl-scrl">
                                  {this.state.getCartComponents.length === 0 ? (
                                    <p>
                                      No starred component available right now
                                    </p>
                                  ) : (
                                    getCartComponents
                                  )}
                                </div>
                                <MDBBtn
                                  className="fullgbtn "
                                  disabled={
                                    this.state.getCartComponents.length === 0
                                      ? true
                                      : false
                                  }
                                  onClick={() => this.getComponentUseAsBase()}
                                >
                                  CREATE NEW KIT
                                </MDBBtn>
                                <MDBBtn
                                  className="fullgbtn "
                                  disabled={
                                    this.state.getCartComponents.length === 0
                                      ? true
                                      : false
                                  }
                                  onClick={() => this.getJustLike()}
                                >
                                  JUST LIKE
                                </MDBBtn>
                              </MDBTabPane>
                            </MDBTabContent>
                          </div>
                        </div>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </div>

              {this.state.duplicateComponents?.length && (
                <Modal close={this.toggleForDuplicateClose}>
                  <MDBModalBody>
                    <MDBRow>
                      <MDBCol lg="12" md="12" sm="12" xs="12" className="mb-1">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.toggleForDuplicateClose}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </MDBCol>
                    </MDBRow>
                    Duplicate component(s):
                    {alertDuplicateComponents}
                  </MDBModalBody>
                  <MDBModalFooter>
                    <MDBBtn
                      color="grey"
                      size="sm"
                      onClick={this.toggleForDuplicateClose}
                    >
                      Close
                    </MDBBtn>
                  </MDBModalFooter>
                </Modal>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KitMakerComponents);
