import * as actionTypes from '../../constants/actionTypes';

const initialState = {
    list: [],
    loading: false,
    componentsCount: null,
    categorySelectOptions: [],
    partTypes: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CATEGORIES_START:
            return {
                ...state,
                loading: action.loading
            }

        case actionTypes.GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.data.categories,
                componentsCount: action.data.componentsCount,
                categorySelectOptions: action.data.categories.map(item => ({ value: item.id, label: item.name })),
                partTypes: action.data.categories.filter(category => category.partTypes.length).map(item => item.partTypes).flat()
            }

        case actionTypes.GET_CATEGORIES_FAIL:
            return {
                ...state,
                loading: false
            }

        default:
            return { ...state }
    };
};
