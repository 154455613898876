import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { faPencilAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomInput from '../../../components/Inputs/CustomInput';
import CustomSelect from '../../../components/Selects/CustomSelect';
import { multiSelectStyles } from '../../../constants/constants';
import { flatten } from '../../../utils/categoryMapping';
import CustomButton from '../../../components/Buttons/CustomButton';
import Switcher from '../../../components/Switchers/Switcher';
import { loadCmsComponentInfo } from '../../../redux/actions/components';
import FancyScroll from '../../../components/FancyScroll';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 10px;

  & > div {
    margin-bottom: 20px;
  }

  input {
    background: #1d265b;
    color: #ffffffa3;
    text-align: center;
  }

  input:focus + label span,
  input:valid + label span {
    color: white !important;
    font-size: 12px !important;
  }

  label,
  label:after {
    border-bottom: 1px solid #ffffffa3;
  }

  span {
    color: white;
    font-size: 12px;
    font-weight: 500;
  }
`;

const Dropdowns = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  font-size: 12px;
  flex-direction: column;

  & > div {
    width: 100%;
  }

  & > span {
    margin-top: 10px;
    font-weight: 500;
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Titles = styled.div`
  display: flex;
  padding: 10px;
  font-size: 12px;
  font-weight: 500;
  justify-content: space-between;

  & > span {
    width: 45%;
    display: flex;
    align-items: center;

    & > svg {
      cursor: pointer;
      margin-left: 10px;
    }
  }
`;

const SelectItem = styled.div`
  display: flex;
  border-radius: 2px;
  font-size: 10px;
  padding: 3px 6px;
  box-sizing: border-box;
  background: white;
  color: black;
  font-weight: 400;
  width: fit-content;
  margin: 2px;
  align-items: center;
  max-width: 110px;

  svg {
    margin-left: 10px;
    opacity: 0.5;
    font-size: 12px;

    &:hover {
      color: red;
      opacity: 1;
    }
  }

  & > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const ComponentOverview = ({
  component,
  onChange,
  categories,
  onSelectChange,
  onSubmit,
  disabled,
  showDropdown,
  setShowDropDown,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (_.isEmpty(component)) {
      dispatch(loadCmsComponentInfo(id));
    }
    // eslint-disable-next-line
  }, []);

  if (_.isEmpty(component)) return null;

  // const image = component.images.find(img => img.name === 'display')?.url;
  const categoryOptions = _.orderBy(flatten(categories, false), 'name', 'asc');

  const partTypeOptions = categoryOptions
    .filter((f) => f.partTypes.length)
    .map((p) => p.partTypes)
    .flat();
  const defaultProps = {
    customStyles: multiSelectStyles,
    isSearchable: false,
    getOptionLabel: (option) => option.name,
    getOptionValue: (option) => option.id,
  };

  const dropdowns = {
    category: (
      <Dropdowns>
        <CustomSelect
          {...defaultProps}
          options={categoryOptions}
          value={component.category}
          onChange={(option) => onSelectChange(option, 'category')}
        />
      </Dropdowns>
    ),
    partType: (
      <Dropdowns>
        <CustomSelect
          {...defaultProps}
          options={partTypeOptions}
          value={component.partType}
          onChange={(option) => onSelectChange(option, 'partType')}
        />
      </Dropdowns>
    ),
  };

  return (
    <>
      <FancyScroll offset={190}>
        <Container>
          {showDropdown ? (
            dropdowns[showDropdown]
          ) : (
            <>
              <InputGroup>
                <CustomInput
                  label="SKU"
                  value={component.number}
                  name="number"
                  onChange={onChange}
                />
                <CustomInput
                  label="Description"
                  value={component.description}
                  name="description"
                  onChange={onChange}
                />
                <CustomInput
                  label="Label Name"
                  value={component.labelName}
                  name="labelName"
                  onChange={onChange}
                />
              </InputGroup>
              <Titles>
                <span>
                  Part Class{' '}
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    onClick={() => setShowDropDown('category')}
                  />
                </span>
                <span>
                  Part Type{' '}
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    onClick={() => setShowDropDown('partType')}
                  />
                </span>
              </Titles>
              <div style={{ display: 'flex' }}>
                {component.category?.name && (
                  <div style={{ width: '50%', padding: '0px 10px' }}>
                    <SelectItem>
                      <span>{component.category.name}</span>
                    </SelectItem>
                  </div>
                )}
                {component.partType?.name && (
                  <div style={{ width: '50%', padding: '0px 10px' }}>
                    <SelectItem>
                      <span>{component.partType.name}</span>
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        onClick={() => onSelectChange(null, 'partType')}
                      />
                    </SelectItem>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Titles>
                  <span>Visibility</span>
                </Titles>
                <Switcher
                  size="small"
                  name="isVisible"
                  checked={component.isVisible}
                  onChange={() =>
                    onChange({
                      target: {
                        name: 'isVisible',
                        value: !component.isVisible,
                      },
                    })
                  }
                />
              </div>
            </>
          )}
        </Container>
      </FancyScroll>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CustomButton
          label="Save Changes"
          rounded
          onClick={onSubmit}
          disabled={disabled}
          background="#61A431"
        />
      </div>
    </>
  );
};

export default ComponentOverview;
