import React, { Component } from 'react';
import { MDBPagination, MDBPageItem, MDBPageNav } from "mdbreact";
import agent from '../../agent';
import Loader from "../loaders/Loader";

let KitArray = {
    kits: [
        { description: "No results found" }
    ],
    totalPages: 0,
    totalCount: 0
}

class SavedASkPagination extends Component{
    constructor(props) {
        super(props);
        this.state = {
            reloadKey: Math.random(),
            kitData: null,
            showComponent: false,
            paginationActive: false,
            isOpen: false,
            currentPage: 1,
            everyPage: 12,
            searchText: null,
            selectedNodeArray: [],
            upperPageBound: 6,
            lowerPageBound: 0,
            pageBound: 3,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            justLikeKitData: null,
            likeKitId: null,
            KitId: null,
            currentPagePrevious: 1,
            upperPageBoundPrevious: 6,
            lowerPageBoundPrevious: 0,
            pageBoundPrevious: 3,
        }
    }

    componentWillMount() {
        const token = window.localStorage.getItem('jwt');
        let expiry = new Date(window.localStorage.getItem("tokenExpiry"));
        let current = new Date();
            if (token && expiry.getTime() >= current.getTime()) {
            agent.SAVEDASK.savedASk().then((res) => {
                if (res === null) {
                    this.setState({ justLikeKitData: KitArray });
                }
                else {
                    this.setState({ justLikeKitData: res.body });
                }
            }
            )
        }
        else {
            this.props.onClickLogout()
        }
    }

    handleClick = (event) => {
        this.setState({
            currentPage: Number(event.target.id), paginationActive: true
        });
        this.setPrevAndNextBtnClass(this.state.currentPage);
        this.loadComponentData();

    }
    handleNextClick = () => {
        if (this.state.currentPage < this.state.everyPage) {
            this.setState({
                currentPage: (this.state.currentPage - 1) + 1
            });
        }
    }
    handlePreviousClick = () => {
        if (this.state.currentPage > 1)
            this.setState({
                currentPage: (this.state.currentPage - 1) - 1,
            });
    }

    btnIncrementClick=()=> {
        this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
        this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
        let listid = this.state.upperPageBound + 1;
        this.setPrevAndNextBtnClass(listid);
        this.loadComponentData();
    }

    setPrevAndNextBtnClass=(listid)=> {
        let totalPage = this.state.currentPage;
        this.setState({ isNextBtnActive: 'disabled' });
        this.setState({ isNextBtnActive: 'disabled' });
        if (totalPage === listid && totalPage > 1) {
            this.setState({ isPrevBtnActive: '' });
        }
        else if (listid === 1 && totalPage > 1) {
            this.setState({ isNextBtnActive: '' });
        }
        else if (totalPage > 1) {
            this.setState({ isNextBtnActive: '' });
            this.setState({ isPrevBtnActive: '' });
        }
    }

    btnDecrementClick=()=> {
        this.setState({ upperPageBound: this.state.upperPageBound - 2 });
        this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
        let listid = this.state.upperPageBound - this.state.pageBound;
        this.setPrevAndNextBtnClass(listid);
        this.loadComponentData();
    }

    btnPrevClick=()=> {
        if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
            this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
            this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
        }
        let listid = this.state.currentPage - 1;
        this.setState({ currentPage: listid });
        this.setPrevAndNextBtnClass(listid - 1);
        this.loadComponentData();
    }

    btnNextClick=()=> {
        if ((this.state.currentPage + 1) > this.state.upperPageBound) {
            this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
            this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
        }
        let listid = this.state.currentPage + 1;
        this.setState({ currentPage: listid });
        this.setPrevAndNextBtnClass(listid);
        this.loadComponentData();
    }
    btnFirstPage=()=> {
        this.setState({ currentPage: Number(1) });
        this.setState({ upperPageBound: Number(6) });
        this.setState({ lowerPageBound: Number(0) });
        this.setPrevAndNextBtnClass(Number(1));
        this.loadComponentData();
    }

    btnLastPage=()=> {
        if ((this.state.currentPage) > this.state.upperPageBound) {
            this.setState({ currentPage: this.state.justLikeKitData.length });
            this.setState({ upperPageBound: this.state.everyPage });
            this.setState({ lowerPageBound: this.state.currentPage });
        } else {
            this.setState({ currentPage: Number(Math.floor((this.state.justLikeKitData.length + this.state.everyPage - 1) / this.state.everyPage)) });
            this.setState({ upperPageBound: Number(Math.floor((this.state.justLikeKitData.length + this.state.everyPage - 1) / this.state.everyPage)) });
            this.setState({ lowerPageBound: Number(Math.floor((this.state.justLikeKitData.length + this.state.everyPage - 1) / this.state.everyPage)) - 3 });
        }
        this.setPrevAndNextBtnClass(this.state.justLikeKitData.length);
        this.loadComponentData();
    }

    loadComponentData = () => {
        let pageloop = this.state.currentPage * 12;
        let dat = [];
        agent.SAVEDASK.savedASk().then((res) => {
            if (res === null) {
                this.setState({ justLikeKitData: KitArray });
            }
            else {
                this.setState({ justLikeKitData: res.body });
                for (let j = pageloop - 12; j <= pageloop - 1; j++) {
                    if (this.state.justLikeKitData[j] !== undefined) {
                        dat.push(this.state.justLikeKitData[j]);
                       
                    }
                }
                const { data } = this.props;
                        data(dat);
            }
        }
        )
      
    };

    render() {
        if ( this.state.justLikeKitData === null ) {
            return <Loader />
        } else {
            var  iitem = "";
            const pageNumbers = [];
            const activePageStyle = { background: "#1d265b", color: "#ffffff", padding: "0.4rem 0.75rem" };
            const inactivePageStyle = { background: "#838c8c" };
            let pageloop = this.state.currentPage * 12;
            let dat = [];
            let totalSavedASKPage = (Math.floor((this.state.justLikeKitData.length + this.state.everyPage - 1) / this.state.everyPage));
            for (let j = pageloop - 12; j <= pageloop - 1; j++) {
                if (this.state.justLikeKitData[j] !== undefined) {
                    dat.push(this.state.justLikeKitData[j]);
                }
            }
            for (let i = 1; i <= totalSavedASKPage; i++) {
                pageNumbers.push(i);
            }

            const renderPageNumbers = pageNumbers.map(number => {
                iitem = <MDBPageItem active>
                    <MDBPageNav className="page-link" style={this.state.currentPage === number ? activePageStyle : inactivePageStyle}
                        key={number}
                        id={number}
                        onClick={this.handleClick}>
                        {number}
                    </MDBPageNav>
                </MDBPageItem>;
                return (number < this.state.upperPageBound + 1) && number > this.state.lowerPageBound && totalSavedASKPage >= number ? iitem : null;
            });
            let pageIncrementBtn = null;
            if (pageNumbers.length > this.state.upperPageBound) {
                pageIncrementBtn = <MDBPageItem>
                    <MDBPageNav className="page-link"
                        onClick={this.btnIncrementClick}>
                        ....
                </MDBPageNav>
                </MDBPageItem>
            }
            let pageDecrementBtn = null;
            if (this.state.lowerPageBound >= 1) {
                pageDecrementBtn = <MDBPageItem>
                    <MDBPageNav className="page-link"
                        onClick={this.btnDecrementClick}>
                        ....
                </MDBPageNav>
                </MDBPageItem>
            }
            let renderPrevBtn = null;
            if (this.state.currentPage === 1) {
                renderPrevBtn = <MDBPageItem>
                    <MDBPageNav className="page-link" >
                        <span id="btnPrev">  </span>
                    </MDBPageNav>
                </MDBPageItem>
            }
            else {
                renderPrevBtn = <MDBPageItem>
                    <MDBPageNav className="page-link"
                        onClick={this.btnPrevClick}>
                        <span id="btnPrev"> Prev </span>
                    </MDBPageNav>
                </MDBPageItem>
            }
            let renderNextBtn = null;
            if (this.state.upperPageBound === (Number(Math.floor((this.state.justLikeKitData.length + this.state.everyPage - 1) / this.state.everyPage)))) {
                renderNextBtn = <MDBPageItem>
                    <MDBPageNav className="page-link" onClick={this.btnNextClick}>
                        <span id="btnNext">  </span>
                    </MDBPageNav>
                </MDBPageItem>
            }
            else {
                renderNextBtn = <MDBPageItem>
                    <MDBPageNav className="page-link" onClick={this.btnNextClick}>
                        <span id="btnNext"> Next </span>
                    </MDBPageNav>
                </MDBPageItem>
            }
            let renderFirstpage = null;
            if (this.state.currentPage === 1) {
                renderFirstpage = <MDBPageItem onClick={this.btnFirstPage} >
                    <MDBPageNav className="page-link" aria-label="Previous">
                    </MDBPageNav>
                </MDBPageItem>
            } else {
                renderFirstpage = <MDBPageItem onClick={this.btnFirstPage} >
                    <MDBPageNav className="page-link" aria-label="Previous">
                        <span>First</span>
                    </MDBPageNav>
                </MDBPageItem>
            }
            let renderLastPage = null;
            if(this.state.upperPageBound === (Number(Math.floor((this.state.justLikeKitData.length + this.state.everyPage - 1) / this.state.everyPage)))){
                renderLastPage = <MDBPageItem >
                <MDBPageNav className="page-link"
                    onClick={this.btnLastPage}>
                    <span></span>
                </MDBPageNav>
            </MDBPageItem>
            }else{
                renderLastPage = <MDBPageItem >
                <MDBPageNav className="page-link"
                    onClick={this.btnLastPage}>
                    <span>Last</span>
                </MDBPageNav>
            </MDBPageItem>
            }
            return(
                (this.state.justLikeKitData.length > this.state.everyPage) ?
                <MDBPagination circle>
                {renderFirstpage}
                {renderPrevBtn}
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                {renderNextBtn}
                {renderLastPage}
            </MDBPagination>:null
            ) 
        }
    }
}




export default SavedASkPagination;