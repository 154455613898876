import React from 'react';
import styled from '@emotion/styled';
import CustomCheckbox from '../../../../components/Inputs/CustomCheckbox';
import RadioButton from '../../../../components/Inputs/RadioButton';

const Paragraph = styled.div`
    display: flex;
    flex-direction: ${props => props.direction || 'row'};
    padding: 3px 0px 3px 10px;
    & > div {
        margin-right: ${props => !props.direction ? 20 : 0}px;
    }
`;

const Checkboxes = ({ filters, setFilters }) => {
    const { us, meds, prefilledSalineSyringe, chloraprep, capAndMask, gown, drape, transducerCover, maxBarrier, duraprep, lorSyringe, providoneLodine, plastic, glass } = filters;

    return (
        <Paragraph direction='column'>
            <CustomCheckbox label='Meds' disabled={!us} onChange={() => setFilters({ ...filters, meds: !meds })} checked={meds} />
            <CustomCheckbox label='Prefilled Saline Syringe' disabled={!us} onChange={() => setFilters({ ...filters, prefilledSalineSyringe: !prefilledSalineSyringe })} checked={prefilledSalineSyringe} />
            <CustomCheckbox label='Chloraprep' onChange={() => setFilters({ ...filters, chloraprep: !chloraprep })} checked={chloraprep} />
            <CustomCheckbox label='Gown' onChange={() => setFilters({ ...filters, gown: !gown })} checked={gown} />
            <CustomCheckbox label='Cap and Mask ' onChange={() => setFilters({ ...filters, capAndMask: !capAndMask })} checked={capAndMask} />
            <CustomCheckbox label='Transducer Cover' onChange={() => setFilters({ ...filters, transducerCover: !transducerCover })} checked={transducerCover} />
            <CustomCheckbox label='Povidone Iodine' onChange={() => setFilters({ ...filters, providoneLodine: !providoneLodine })} checked={providoneLodine} />
            <CustomCheckbox label='Duraprep' onChange={() => setFilters({ ...filters, duraprep: !duraprep })} checked={duraprep} />

            <CustomCheckbox label='LOR Syringe' onChange={() => setFilters({ ...filters, lorSyringe: !lorSyringe, ...(lorSyringe && { plastic: false, glass: false }) })} checked={lorSyringe} />
            {lorSyringe && <Paragraph>
                <RadioButton label='Plastic' name='lorSyringe' onChange={() => setFilters({ ...filters, plastic: true, glass: false })} checked={plastic} />
                <RadioButton label='Glass' name='lorSyringe' onChange={() => setFilters({ ...filters, plastic: false, glass: true })} checked={glass} />
            </Paragraph>}

            <CustomCheckbox label='Drape' onChange={() => setFilters({ ...filters, drape: !drape, ...(drape && { maxBarrier: false }) })} checked={drape} />
            {filters.drape && <Paragraph direction='column'>
                <CustomCheckbox label='Max Barrier' onChange={() => setFilters({ ...filters, maxBarrier: !maxBarrier })} checked={maxBarrier} />
            </Paragraph>}
        </Paragraph>
    );
};

export default Checkboxes;
