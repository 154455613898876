import * as actionTypes from '../../constants/actionTypes';
import { getToken } from '../../utils/getToken';
import axios from '../axiosMiddleware/axios';
import { showSnackbar } from './snackbar';

export const loadProductFamiliesList = (generalAccess) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_PRODUCT_FAMILIES_START });

  const path = generalAccess
    ? '/api/product-families'
    : '/api/cms/product-families';

  try {
    const res = await axios.get(path, {
      headers: { Authorization: 'Bearer ' + getToken() },
    });

    dispatch({
      type: actionTypes.GET_PRODUCT_FAMILIES_SUCCESS,
      productFamilies: res.data,
    });
  } catch (err) {
    dispatch({ type: actionTypes.GET_PRODUCT_FAMILIES_FAIL, error: err });
    dispatch(showSnackbar('error', err.message));
  }
};

export const updateProductFamily = (productFamily) => async dispatch => {
    dispatch({ type: actionTypes.UPDATE_PRODUCT_FAMILY_START });

    const requestBody = {
        Name: productFamily.name,
        Margin: productFamily.margin,
    };

    try {
        await axios.put(`/api/cms/product-families/${productFamily.id}`, requestBody, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.UPDATE_PRODUCT_FAMILY_SUCCESS });
        dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'success', message: 'Product family update success' } });
        dispatch(loadProductFamiliesList());
    } catch (err) {
        let message = '';

        if (err.response?.status === 404) {
            message = "Product family with the provided id doesn't exist";
        } else if (err.response?.status === 400) {
            message = err.response.data;
        } else {
            message = 'Something went wrong, please try later'
        }

        dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'error', message } });
        dispatch({ type: actionTypes.UPDATE_PRODUCT_FAMILY_FAIL, error: err });
    }
};

export const createProductFamily = (productFamily) => async dispatch => {
    dispatch({ type: actionTypes.CREATE_PRODUCT_FAMILY_START });

    const requestBody = {
        Name: productFamily.name,
        Margin: productFamily.margin,
    };

    try {
        await axios.post('/api/cms/product-families', requestBody, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.CREATE_PRODUCT_FAMILY_SUCCESS });
        dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'success', message: 'Product family create success' } });
        dispatch(loadProductFamiliesList());
    } catch (err) {
        let message = '';

        if (err.response?.status === 400) {
            message = 'Some parameters are invalid';
        } else {
            message = 'Something went wrong, please try later'
        }

        dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'error', message } });
        dispatch({ type: actionTypes.CREATE_USER_FAIL, error: err });
    }
};
export const deleteProductFamily = (productFamilyId) => async dispatch => {
    dispatch({ type: actionTypes.DELETE_PRODUCT_FAMILY_START });

    try {
        await axios.delete(`/api/cms/product-families/${productFamilyId}`, { headers: { Authorization: 'Bearer ' + getToken() } });

        dispatch({ type: actionTypes.DELETE_PRODUCT_FAMILY_SUCCESS });
        dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'success', message: 'Product family delete success' } });
        dispatch(loadProductFamiliesList());
    } catch (err) {
        let message = '';

        if (err.response?.status === 404) {
            message = "Product family with the provided id doesn't exist";
        } else if (err.response?.status === 400) {
            message = err.response.data;
        } else {
            message = 'Something went wrong, please try later';
        }

        dispatch({ type: actionTypes.SHOW_SNACKBAR, snackbar: { type: 'error', message } });
        dispatch({ type: actionTypes.DELETE_PRODUCT_FAMILY_FAIL, error: err });
    }
};