import styled from "@emotion/styled";
import { faFolder, faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import FancyScroll from "../../../components/FancyScroll";

const Container = styled.div`
  margin-top: 20px;
  margin-left: -10px;
`;

const Struct = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  & svg {
    margin-right: 5px;
  }
`;

function prepareTree(images) {
  const tree = Object.create(null);

  for (const imagePath of images) {
    const pathList = imagePath.split("_");
    let current = tree;

    for (let i = 0; i < pathList.length; i++) {
      const path = pathList[i];

      if (!(path in current)) {
        current[path] = i === pathList.length - 2 ? { isFile: true } : {};
      }

      current = current[path];
    }
  }

  return tree;
}

function TreeNode({
  node,
  setPageBreakImages,
  images,
  keys,
  setKeys,
  level = 0,
}) {
  const [toggled, setToggled] = useState("");
  const isFolder = !node?.isFile;
  const hasMore = (key) => isFolder && toggled === key;

  function getOpenedKeys(key) {
    const currents = keys.filter((k) => k.level < level);
    return key === toggled ? currents : [...currents, { key, level }];
  }

  function getLastOpenFolder(keys) {
    return keys[keys.length - 1]?.key;
  }

  function getVisibleImages(key) {
    return key ? images.filter((p) => p.name.includes(key)) : images;
  }

  const handleOpenClose = (key) => {
    const openedKeys = getOpenedKeys(key);
    const lastKey = getLastOpenFolder(openedKeys);
    const visibleImages = getVisibleImages(lastKey);
    setToggled(toggled === key ? "" : key);
    setKeys(openedKeys);
    setPageBreakImages(visibleImages);
  };

  return Object.keys(node).map((key) => {
    return (
      <Struct key={key}>
        <div onClick={() => handleOpenClose(key)}>
          {isFolder && (
            <FontAwesomeIcon icon={toggled === key ? faFolderOpen : faFolder} />
          )}
          {key !== "isFile" && <span>{key}</span>}
        </div>
        {hasMore(key) && (
          <TreeNode
            node={node[key]}
            setPageBreakImages={setPageBreakImages}
            images={images}
            keys={keys}
            setKeys={setKeys}
            level={level + 1}
          />
        )}
      </Struct>
    );
  });
}

const FolderView = ({ imageNames = [], images, setPageBreakImages }) => {
  const tree = prepareTree(imageNames);
  const [keys, setKeys] = useState([]);

  return (
    <Container>
      <FancyScroll offset={250}>
        <TreeNode
          node={tree}
          setPageBreakImages={setPageBreakImages}
          images={images}
          keys={keys}
          setKeys={setKeys}
        />
      </FancyScroll>
    </Container>
  );
};

export default FolderView;
