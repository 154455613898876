import React from 'react';
import styled from '@emotion/styled';
import CustomSelect from '../../../../components/Selects/CustomSelect';
import { multiSelectStyles } from '../../../../constants/constants';
import { components } from 'react-select';
import {
  lumens,
  frSizeOptions,
  lengthOptions,
  preloadedtechoptions,
} from '../utils';
import RadioButton from '../../../../components/Inputs/RadioButton';

const Paragraph = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  padding: 3px 0px 3px 10px;

  & > div {
    margin-right: ${(props) => (!props.direction ? 20 : 0)}px;
  }
`;

const SelectBlock = styled.div`
  font-size: 12px;
  width: 100%;
  margin-bottom: 10px;
`;

const Procedure = (props) => {
  const {
    filters,
    setFilters,
    isPressure,
    isSizes,
    isLengths,
    isLumens,
    isPreloaded,
    isCoated,
  } = props;
  const {
    pressureInjectable,
    nonPressureInjectable,
    preLoadedTech,
    frSize,
    length,
    lumens: fLumens,
    coated,
    nonCoated,
  } = filters;

  return (
    <Paragraph direction="column">
      {isCoated && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <RadioButton
            label="Coated"
            name="coat"
            onChange={(value) =>
              setFilters({ ...filters, coated: value, nonCoated: false })
            }
            checked={coated}
          />
          <RadioButton
            label="Non-Coated"
            name="coat"
            onChange={(value) =>
              setFilters({ ...filters, nonCoated: value, coated: false })
            }
            checked={nonCoated}
          />
        </div>
      )}

      {isPressure && (
        <>
          <RadioButton
            label="Pressure Injectable"
            name="type4"
            onChange={(value) =>
              setFilters({
                ...filters,
                pressureInjectable: value,
                nonPressureInjectable: false,
              })
            }
            checked={pressureInjectable}
          />
          <RadioButton
            label="Non Pressure Injectable"
            name="type4"
            onChange={(value) =>
              setFilters({
                ...filters,
                pressureInjectable: false,
                nonPressureInjectable: value,
              })
            }
            checked={nonPressureInjectable}
          />
        </>
      )}

      {isPreloaded && (
        <SelectBlock>
          <CustomSelect
            customStyles={multiSelectStyles}
            isSearchable={false}
            isClearable={false}
            options={preloadedtechoptions}
            placeholder="Pre-loaded Technology"
            onChange={(o) => setFilters({ ...filters, preLoadedTech: [o] })}
            value={preLoadedTech}
            hideIfNoOptions
          />
        </SelectBlock>
      )}

      {isSizes && (
        <SelectBlock>
          <CustomSelect
            customStyles={multiSelectStyles}
            isSearchable={false}
            isClearable={false}
            options={frSizeOptions}
            placeholder="FR Sizes"
            onChange={(o) => setFilters({ ...filters, 'Reference Size': o })}
            value={frSize}
            components={{
              MultiValue: ({ children, ...props }) => (
                <components.MultiValue {...props}>
                  {'FR Size: ' + children}
                </components.MultiValue>
              ),
            }}
            isMulti
            closeMenuOnSelect={false}
            hideIfNoOptions
          />
        </SelectBlock>
      )}

      {isLengths && (
        <SelectBlock>
          <CustomSelect
            customStyles={multiSelectStyles}
            isSearchable={false}
            isClearable={false}
            options={lengthOptions}
            placeholder="Length"
            onChange={(o) => setFilters({ ...filters, length: o })}
            value={length}
            components={{
              MultiValue: ({ children, ...props }) => (
                <components.MultiValue {...props}>
                  {'Length: ' + children}
                </components.MultiValue>
              ),
            }}
            isMulti
            closeMenuOnSelect={false}
            hideIfNoOptions
          />
        </SelectBlock>
      )}

      {isLumens && (
        <SelectBlock>
          <CustomSelect
            customStyles={multiSelectStyles}
            isSearchable={false}
            isClearable={false}
            options={lumens}
            placeholder="Lumens"
            onChange={(o) => setFilters({ ...filters, lumens: o })}
            value={fLumens}
            components={{
              MultiValue: ({ children, ...props }) => (
                <components.MultiValue {...props}>
                  {'Lumens: ' + children}
                </components.MultiValue>
              ),
            }}
            isMulti
            closeMenuOnSelect={false}
            hideIfNoOptions
          />
        </SelectBlock>
      )}
    </Paragraph>
  );
};

export default Procedure;
