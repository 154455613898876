import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faImage,
  faTimesCircle,
  faEyeSlash,
  faEye,
  faAward,
} from '@fortawesome/free-solid-svg-icons';
import { imageFormats } from '../../../constants/constants';
import { showSnackbar } from '../../../redux/actions/snackbar';
import {
  updateCategory,
  updatePartType,
  uploadDefaultPhoto,
} from '../../../redux/actions/categories';
import Switcher from '../../../components/Switchers/Switcher';
import CustomSelect from '../../../components/Selects/CustomSelect';
import { flatten } from '../../../utils/categoryMapping';
import EditableSection from '../../../components/Inputs/EditableSection';

const Container = styled.div`
  display: flex;
  width: 350px;
  background: #e3e3ec;
  height: 85vh;
  padding: 10px;
  flex-direction: column;
`;

const Title = styled.span`
  height: fit-content;
  width: 100%;
  font-size: small;
  font-weight: 400;
  margin-bottom: 20px;
`;

const ImageSection = styled.div`
  display: flex;
  min-height: 200px;
  align-items: center;
  background: #f6f6f6;
  justify-content: center;

  & > img {
    max-width: 100%;
    max-height: 100%;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 23px;
    width: 100%;
    font-weight: 500;
    color: #c5c5c5d1;
  }
`;

const UploadImage = styled.span`
  display: flex;
  align-self: center;
  margin-top: 15px;
  font-size: 10px;
  font-weight: 600;
  color: #262268;
  text-decoration: underline;
  cursor: pointer;
`;

const SwitchersSection = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  padding: 10px;
  justify-content: flex-end;
  flex-direction: column;

  & > div {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    color: #262268;
    font-weight: 500;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const EditSection = ({ data, options, list }) => {
  const [state, setState] = useState({});

  const ref = useRef(null);
  const dispatch = useDispatch();

  const showAdditionalElements = data.type === 'category';
  const title = data.type === 'category' ? 'Part Class' : 'Part Type';

  const filteredOptions = options.filter((o) => o.value !== data.item.id);
  const additionalOptions = [
    { value: null, label: 'Remove Parents' },
    ...filteredOptions,
  ].flat();
  const selectOptions = data.isSubCategoryClicked
    ? additionalOptions
    : filteredOptions;

  useEffect(() => {
    setState({
      parentId: data.parentId,
      image: data.item.photoUrl,
      ...data.item,
    });
  }, [data]);

  const saveChangedName = () => {
    dispatch(
      showAdditionalElements ? updateCategory(state) : updatePartType(state),
    );
  };

  const handleChangeName = (e) => {
    isNameExists(e.target.value);

    if (e.key === 'Enter') {
      saveChangedName();
    }
  };

  const isNameExists = (value) => {
    const name = value.toLowerCase();

    const res = flatten(list);

    if (!name) {
      setState({ ...state, disabled: 'Name is required', name: value });
    } else if (
      !!res.find((f) => f.name.toLowerCase() === name && data.item.id !== f.id)
    ) {
      setState({ ...state, disabled: 'This name already exists', name: value });
    } else {
      setState({ ...state, disabled: null, name: value });
    }
  };

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      if (!imageFormats.some((format) => format === e.target.files[0].type)) {
        dispatch(showSnackbar('error', 'File must be image format'));
        return;
      }

      setState({ ...state, image: URL.createObjectURL(e.target.files[0]) });
      dispatch(uploadDefaultPhoto(state.id, e.target.files[0]));
    }
  };

  const handleSelectChange = (value) => {
    const temp = { ...state };
    temp.option = value;

    setState({ ...state, option: value });
    dispatch(
      showAdditionalElements ? updateCategory(temp) : updatePartType(temp),
    );
  };

  const handleSwitchChange = (e) => {
    const temp = { ...state };
    temp[e.target.name] = !state[e.target.name];

    setState(temp);

    dispatch(
      showAdditionalElements ? updateCategory(temp) : updatePartType(temp),
    );
  };

  return (
    <Container>
      <Title>{title}</Title>
      <EditableSection
        value={state.name}
        onChange={handleChangeName}
        error={state.disabled}
        onSave={saveChangedName}
        editable
      />
      <Content disabled={state.disabled}>
        {showAdditionalElements && (
          <>
            <ImageSection>
              {!state.image ? (
                <div>
                  <span>
                    <FontAwesomeIcon icon={faImage} style={{ fontSize: 70 }} />
                  </span>
                  <span>No Image Available</span>
                </div>
              ) : (
                <img src={state.image} alt="Upload" />
              )}
            </ImageSection>
            <UploadImage>
              <input
                type="file"
                onChange={onImageChange}
                hidden
                ref={ref}
                accept="image/gif, image/jpeg, image/png"
              />
              <span onClick={() => ref.current.click()}>
                Upload Default Photo
              </span>
            </UploadImage>
            <Title style={{ marginTop: 20, marginBottom: 0 }}>
              Sub Part Class Parent
            </Title>
            <CustomSelect
              applyStyles
              isSearchable={false}
              options={selectOptions}
              value={
                state.option ||
                selectOptions.find((o) => o.value === data.parentId)
              }
              onChange={(value) => handleSelectChange(value)}
            />
          </>
        )}
        <SwitchersSection>
          <div>
            <span>Popular</span>
            <span>Visibility</span>
          </div>
          <div>
            <Switcher
              leftLabel={
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={{ fontSize: 16, color: '#a7a7a7' }}
                />
              }
              rightLabel={
                <FontAwesomeIcon
                  icon={faAward}
                  style={{ fontSize: 16, color: '##61A431' }}
                />
              }
              checked={state.isFavorite}
              onChange={handleSwitchChange}
              value={state.isFavorite}
              name="isFavorite"
              background="white"
              checkedColor="#262268"
              uncheckedColor="rgb(167, 167, 167)"
            />
            <Switcher
              leftLabel={
                <FontAwesomeIcon
                  icon={faEyeSlash}
                  style={{ color: '#CC2222', fontSize: 16 }}
                />
              }
              rightLabel={
                <FontAwesomeIcon
                  icon={faEye}
                  style={{ fontSize: 16, color: '#61A431' }}
                />
              }
              checked={state.isVisible}
              onChange={handleSwitchChange}
              value={state.isVisible}
              name="isVisible"
              background="white"
              checkedColor="#61A431"
              uncheckedColor="#CC2222"
            />
          </div>
        </SwitchersSection>
      </Content>
    </Container>
  );
};

export default EditSection;
