import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { updateTrayCavity, createTrayCavity, setPlacement } from '../../../../redux/actions/kitTrays';
import { loadCategories } from '../../../../redux/actions/categories';
import { flatten } from '../../../../utils/categoryMapping';
import { clearComponentCatalogList, loadComponentCatalog } from '../../../../redux/actions/componentCategories';
import { hideReminder, showNecessaryFields, showReminder, showSnackbar } from '../../../../redux/actions/snackbar';
// import { getCaretPosition, setCaretPosition } from '../../ProductFamilies/CaretPosition';
import SelectedDropdown from './SelectedDropdown';
import DropdownSection from './DropdownSection';
import CustomInput from '../../../../components/Inputs/CustomInput';
import CustomButton from '../../../../components/Buttons/CustomButton';
import CavityPreview from './CavityPreview';
import CustomCheckbox from '../../../../components/Inputs/CustomCheckbox';

const SubmitSection = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 12px;
  bottom: 80px;
  position: absolute;
`;

const Schema = styled.div`
  margin: 20px;
  height: 200px;
  border-style: dashed;
  border-color: rgba(255,255,255,0.6);
  border-width: 2px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;

  & > div {
      margin-bottom: 10px;
  }

  input {
      background: #1d265b;
      color: #ffffffa3;
      text-align: center;
  }

  input:focus + label span,
  input:valid + label span {
      color: white !important;
      font-size: 12px !important;
  }

  label, label:after {
      border-bottom: 1px solid #ffffffa3;
  }

  span {
      color: white;
      font-size: 12px;
      font-weight: 500;
  }
`;

// const CollapseMenu = styled.div`
//     display: flex;
//     justify-content: space-between;
//     cursor: pointer;
// `;

// const restrictionLabels = { maxQuantity: 'Max Quantity', maxLength: 'Max Length', maxWidth: 'Max Width', maxVolume: 'Max Volume' };
const searchDelay = 1000; // debounce delay;

const Overview = ({ tray, cavity, cavities, newCavityData, setNewCavityData, onCavityClick, componentPlacement, setComponentPlacement, setShowDropDown, showDropdown, /*hideLimits*/ }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { categories, components, componentsLoading } = useSelector(
    (state) => ({
      categories: state.categories.list,
      components: state.componentCatalog.list,
      componentsLoading: state.componentCatalog.componentsLoading,
    })
  );
  const { reminder } = useSelector((state) => state.snackbar);
  const allowedCategories = _.orderBy(
    flatten(categories, false),
    "name",
    "asc"
  );

  useEffect(() => {
    dispatch(loadCategories());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(hideReminder());
    };
  }, [dispatch]);

  useEffect(() => {
    if (newCavityData && newCavityData.points.length > 0) {
      if (!reminder) {
        dispatch(showReminder());
      }
    }
    // eslint-disable-next-line
  }, [newCavityData]);

  const restrictions = () => {
    return ['maxQuantity', 'maxLength', 'maxWidth', 'maxVolume'].reduce(
      (obj, item) => ({
        ...obj,
        [item]: `${cavity && cavity[item] ? cavity[item] : 0}${
          item !== 'maxQuantity' ? postfix : ''
        }`,
      }),
      {},
    );
  };

  const [cavityState, setCavityState] = useState({});
  const [sizeRestrictions, setSizeRestrictions] = useState({});
  // const [showLimits, setShowLimits] = useState(false);

  useEffect(() => {
    if (!cavity && history.location.pathname.includes('overview')) {
      history.goBack();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (cavity) {
      setCavityState(cavity);
      const restr = restrictions();
      setSizeRestrictions(restr);
      setOptions({
        allowed: cavity
          ? cavity.categories.map((category) => ({
              ...category,
              id: category.categoryId,
            }))
          : [],
        singleAllowed: cavity
          ? cavity.componentRules
              .filter((f) => f.allowed)
              .map((cr) => ({
                ...cr,
                id: cr.component.id,
                number: cr.component.number,
                labelName: cr.component.labelName,
                placement: cr.placement,
              }))
          : [],
        restricted: cavity
          ? cavity.componentRules
              .filter((f) => !f.allowed)
              .map((cr) => ({
                ...cr,
                id: cr.component.id,
                number: cr.component.number,
                labelName: cr.component.labelName,
                placement: cr.placement,
              }))
          : [],
        incompatible: cavity
          ? cavity.incompatibleCategories.map((ic) => ({
              name: ic.name,
              id: ic.categoryId,
            }))
          : [],
      });
    } else {
      setCavityState({});
    }
    // eslint-disable-next-line
  }, [cavity]);

  const postfix = " cm";
  const [options, setOptions] = useState({
    allowed: [],
    singleAllowed: [],
    restricted: [],
    incompatible: [],
  });

  const onSubmit = (fromDropdown = false) => {
    const componentRules = {
      allowed: options.singleAllowed,
      restricted: options.restricted,
    };

    const commonValues = {
      name: cavityState.name,
      specialInstructions: cavityState.specialInstructions,
      allowsMultiple: cavityState.allowsMultiple,
      categories: options.allowed,
      componentRules,
      incompatibleCategories: options.incompatible,
      ...Object.keys(sizeRestrictions).reduce(
        (obj, item) => ({
          ...obj,
          [item]: sizeRestrictions[item].substr(
            0,
            sizeRestrictions[item].length - postfix.length
          ),
        }),
        {}
      ),
    };

    if (cavity) {
      dispatch(updateTrayCavity(tray.data.id, { ...cavity, ...commonValues }));
      // !fromDropdown && onCavityClick(null);
    } else {
      dispatch(
        createTrayCavity(tray.data.id, {
          points: newCavityData?.points,
          ...commonValues,
        }),
      );
      setNewCavityData(null);
    }

    if (reminder) {
      dispatch(hideReminder());
    }

    ReactTooltip.hide();
  };

  function isNotAllowed(option, type = 'singleAllowed') {
    return options[type].find(
      (item) => item.id === option[option.length - 1]?.id,
    );
  }

  function showSnacBarNotification(type) {
    dispatch(
      showSnackbar(
        'warning',
        `This component is already exists in ${type} group, please delete it first`,
      ),
    );
  }

  const onOptionChange = async (option, name) => {
    if (!option || !option.length) {
      setOptions({ ...options, [name]: [] });
      return;
    }

    if (name === 'restricted' && isNotAllowed(option)) {
      showSnacBarNotification('Allowed');
      return;
    }

    if (name === 'singleAllowed' && isNotAllowed(option, 'restricted')) {
      showSnacBarNotification('Restricted');
      return;
    }

    setOptions({ ...options, [name]: option });

    const len = option.length;
    const id = option[len - 1]?.id;
    const placement =
      name !== 'incompatible' ? await setPlacement(id, name, cavity) : null;

    if (placement) {
      const withPlacement = [
        ...option.slice(0, len - 1),
        { ...option[len - 1], ...placement },
      ];
      setOptions({ ...options, [name]: withPlacement });
    }

    if (!reminder) {
      dispatch(showReminder());
    }
  };

  const [inputValue, setInputValue] = useState("");

  const onComponentSearch = (value, { action }) => {
    if (action !== "set-value") {
      setInputValue(value);
      search(value);
    }
  };

  const search = useMemo(
    () =>
      _.debounce(
        (value) =>
          dispatch(
            value
              ? loadComponentCatalog({
                  partTypeId: "",
                  search: value,
                  page: 0,
                  categoryId: "",
                })
              : clearComponentCatalogList()
          ),
        searchDelay
      ),
    [dispatch]
  );

  useEffect(() => {
    setInputValue("");
  }, [showDropdown]);

  if (showDropdown) {
    return (
      <>
        <SelectedDropdown
          onOptionChange={onOptionChange}
          name={showDropdown}
          inputValue={inputValue}
          allowedCategories={allowedCategories.map((item) => ({
            id: item.id,
            name: item.name,
          }))}
          onComponentSearch={onComponentSearch}
          componentsLoading={componentsLoading}
          components={components.map((c) => ({
            id: c.id,
            number: c.number,
            labelName: c.labelName,
          }))}
          options={options}
        />
        <SubmitSection>
          <CustomButton
            rounded
            label={cavity ? "Save cavity changes" : "Create cavity"}
            background={`#61A431`}
            onClick={() => onSubmit(true)}
            disabled={
              !cavityState.name ||
              cavityState.name.length === 0 ||
              (newCavityData && newCavityData.points.length !== 4)
            }
          />
        </SubmitSection>
      </>
    );
  }

  // const handleSizeChange = (e) => {
  //     const reg = /^[0-9]*[.]{0,1}[0-9]{0,2} cm$/;
  //     if ((reg.test(e.target.value) && reg.test(sizeRestrictions[e.target.name])) || !reg.test(sizeRestrictions[e.target.name])) {
  //         setSizeRestrictions({ ...sizeRestrictions, [e.target.name]: e.target.value });
  //     }

  //     if (!reminder) {
  //         dispatch(showReminder());
  //     }
  // };

  // const handleClickInput = (e) => {
  //     if (getCaretPosition(e.target) >= e.target.value.length - postfix.length + 1) {
  //         setCaretPosition(e.target, e.target.value.length - postfix.length);
  //     }
  // };

  const onCavityStateChange = ({ target }) => {
    const { name, value } = target;

    if (!reminder) {
      dispatch(showReminder());
    }

    setCavityState({ ...cavityState, [name]: value });
  };

  const handleClick = () => {
    let requestFields = [];
    if (!cavityState.name || cavityState.name.length === 0) {
      requestFields.push("cavity name");
    }
    if (newCavityData && newCavityData.points.length !== 4) {
      requestFields.push("cavity polygon");
    }
    if (requestFields.length > 0) {
      dispatch(showNecessaryFields(requestFields));
    } else {
      onSubmit();
    }
  };

    return (
      <>
        <Schema>
          <CavityPreview cavity={cavity} />
        </Schema>
        <InputGroup>
          <CustomInput
            name="name"
            value={cavityState.name || ''}
            onChange={onCavityStateChange}
            label="Name"
            isRequired
            invalid={cavityState.name === ''}
            errorText={!cavityState.name && 'Name field is required.'}
          />
          <CustomInput
            name="specialInstructions"
            value={cavityState.specialInstructions || ''}
            onChange={onCavityStateChange}
            label="Special Instructions"
          />
          <CustomCheckbox
            label="Allows multiple components"
            checked={cavityState.allowsMultiple}
            onChange={() =>
              onCavityStateChange({
                target: {
                  name: 'allowsMultiple',
                  value: !cavityState.allowsMultiple,
                },
              })
            }
          />
          {/* {!hideLimits && (
            <CollapseMenu onClick={() => setShowLimits(!showLimits)}>
              <span>Limits</span>
              <FontAwesomeIcon
                icon={showLimits ? faChevronUp : faChevronDown}
              />
            </CollapseMenu>
          )} */}
          {/* {showLimits &&
            Object.keys(sizeRestrictions).map((x) => (
              <CustomInput
                key={x}
                value={sizeRestrictions[x]}
                name={x}
                onChange={handleSizeChange}
                onClickCapture={handleClickInput}
                onKeyUp={handleClickInput}
                label={restrictionLabels[x]}
              />
            ))} */}
        </InputGroup>
        <DropdownSection
          setShowDropDown={setShowDropDown}
          cavities={cavities}
          options={options}
          setOptions={setOptions}
          onOptionChange={onOptionChange}
          cavity={cavity}
          componentPlacement={componentPlacement}
          setComponentPlacement={setComponentPlacement}
          allowMultiple={cavityState.allowsMultiple}
          updateRules={onSubmit}
        />
        <SubmitSection>
          <CustomButton
            rounded
            label={cavity ? 'Save cavity changes' : 'Create cavity'}
            background={`#61A431`}
            onClick={handleClick}
            disabled={
              !cavityState.name ||
              cavityState.name.length === 0 ||
              (newCavityData && newCavityData.points.length !== 4)
            }
          />
        </SubmitSection>
      </>
    );
};

export default Overview;
