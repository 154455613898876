import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div(({ position }) => `
    position: absolute;
    z-index: 1000;
    right: ${position.right};
    top: ${position.top};
    left: ${position.left};
    bottom: ${position.bottom};
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 5px;

    & > div {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-right: 5px;
        margin-bottom: -3px;
    }

    & > div:after {
        content: " ";
        display: block;
        width: 24px;
        height: 24px;
        margin: 8px;
        border-radius: 50%;
        border: 3px solid #000;
        border-color: #000 transparent #000 transparent;
        animation: div 1.2s linear infinite;
    }

    @keyframes div {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`);

const Spinner = ({ text, position }) => {
    return (
        <Container position={position}>
            <div></div>
            {text}
        </Container>
    );
};

Spinner.defaultProps = {
    position: { top: null, right: null, bottom: null, left: null }
};

export default Spinner;
