import React from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import { sortBy } from 'lodash';

const ComponentCategoriesPartTypeFilters = (props) => {
  const { selectPartType, partTypes = [], id } = props;

  return (
    <MDBCard className="mt45">
      <MDBCardBody className="setHeight p-2 scrlbox">
        <MDBCardTitle>Popular</MDBCardTitle>
        <nav
          className="nav pb-1"
          role="navigation"
          style={{ flexDirection: 'column' }}
        >
          {sortBy(partTypes, ['name']).map((item) => (
            <div className="sc-bdVaJa popular-text" key={'catradio' + item.id}>
              <div style={{ display: 'flex' }}>
                <div className="round">
                  <input
                    type="checkbox"
                    name="catradio"
                    id={'catradio' + item.id}
                    checked={item.checked || item.id === id || ''}
                    onChange={() => selectPartType(item, 'popular')}
                  />
                  <label htmlFor={'catradio' + item.id} />
                </div>
                <span
                  role="button"
                  onClick={() => selectPartType(item, 'popular')}
                >
                  {item.name}
                </span>
              </div>
            </div>
          ))}
        </nav>
      </MDBCardBody>
    </MDBCard>
  );
};

export default ComponentCategoriesPartTypeFilters;
