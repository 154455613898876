import React, { useState } from 'react';
import { resetPassword } from '../../redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import siteLogo from '../../shared/img/logo.png';
import { MDBInput, MDBBtn } from 'mdbreact';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { Redirect } from 'react-router-dom';
import styled from '@emotion/styled';

const Link = styled.a`
  color: white;
  display: block;
  padding-top: 50px;
  opacity: 0.6;
  &:hover {
    opacity: 1;
    color: white;
  }
`;
const Label = styled.div`
  color: white;
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 1.5em;
`;

const LabelSmall = styled.div`
  color: white;
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 1em;
`;

const PasswordReset = (props) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const params = props.location.search
    .replace('?', '')
    .split('&')
    .map((x) => x.split('='))
    .reduce(
      (obj, item) => ({
        ...obj,
        [item[0]]: item[1],
      }),
      {},
    );

  const [password, setPassword] = useState('');
  const [passwordConf, setPasswordConf] = useState('');

  const handleButton = () => {
    dispatch(resetPassword(params.email, password, params.code));
  };

  return (
    <>
      {!isAuthenticated ? (
        <div className="App">
          <section id="authentication-layout">
            <div className="flex-container">
              <div className="img-area">
                <img src={siteLogo} alt="logo" style={{ width: '50%' }} />
              </div>
              <div className="authentication-area">
                <Label>Reset Password</Label>
                <LabelSmall>
                  Welcome: <br />
                  {params.email}
                </LabelSmall>
                <MDBInput
                  label="Password"
                  id="password"
                  type="password"
                  icon="lock"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  color="black"
                  style={{ marginBottom: 0 }}
                />
                <MDBInput
                  label="Confirm password"
                  id="passwordConf"
                  type="password"
                  icon="lock"
                  name="passwordConf"
                  onChange={(e) => setPasswordConf(e.target.value)}
                  value={passwordConf}
                  color="black"
                  style={{ marginBottom: 0 }}
                />
                <MDBBtn
                  color="white"
                  type="submit"
                  disabled={passwordConf !== password || password.length < 5}
                  onClick={handleButton}
                >
                  Login
                </MDBBtn>
                <Link href="/">Not your user?</Link>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <Redirect to="/components" />
      )}
    </>
  );
};

export default PasswordReset;
