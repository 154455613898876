import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { orderBy } from "lodash-es";
import {
  faImage,
  faSpinner,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import ImageType from "./ImageType";
import { imageIcons } from "../../../../constants/constants";
import UploadComponentImage from "../../KitTrays/Cavity/UploadComponentImage";
import ReactTooltip from "react-tooltip";
import useClickOutside from "../../../../hooks/useClickOutside";
import FancyScroll from "../../../../components/FancyScroll";
import CustomCheckbox from "../../../../components/Inputs/CustomCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { updateCmsComponentImageOrder } from "../../../../redux/actions/components";

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const ActionSection = styled.div`
  display: flex;
  width: 50%;
  padding: 20px;
  flex-direction: column;
`;

const Preview = styled.div`
  display: flex;
  width: 100%;
  max-height: calc(100vh - 220px);
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  & > img {
    max-height: 100%;
    max-width: 100%;
  }

  & > svg {
    font-size: 100px;
    color: #babdcc;
  }

  & > span {
    bottom: 0px;
    position: absolute;
    text-align: right;
    width: 100%;
    color: red;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Title = styled.span`
  color: #1d265b;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  & > div {
    display: inline-block;
  }
`;

const ComponentImages = styled.div`
  display: flex;
  padding: 20px;
  min-height: 33%;
  flex-wrap: ${(props) => (props.dragMode ? "nowrap" : "wrap")};
  overflow-x: ${(props) => (props.dragMode ? "auto" : "hidden")};
  max-width: 450px;
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  min-height: 300px;
`;

const Images = ({ component, onImageChange, deleteImage }) => {
  const [preview, setPreview] = useState("");
  const [newImage, setNewImage] = useState(null);
  const [firstSelection, setFirstSelection] = useState(true);
  const [imagePlacement, setImagePlacement] = useState("");
  const [draggableImages, setDraggableImages] = useState([]);
  const [dragMode, setDragMode] = useState(false);
  const dispatch = useDispatch();

  const loading = useSelector(
    (state) => state.components.updateImageOrderLoading
  );

  useEffect(() => {
    if (component.images) {
      const images = component.images.filter(
        (img) => img.name !== "display" && img.name !== "scale"
      );
      setDraggableImages(orderBy(images, ["order"], ["asc"]));
    }
  }, [component.images]);

  const ref = useRef(null);

  const hideTooltip = () => {
    ReactTooltip.hide();
    setNewImage(null);
    setImagePlacement("");
  };

  const showTooltip = (image) => {
    setNewImage(component);
    setImagePlacement(image?.name);
  };

  useClickOutside(ref, hideTooltip);

  const imagePreview = component.images?.find((img) => img.id === preview);

  useEffect(() => {
    if (firstSelection && draggableImages?.length > 0 && preview === '') {
      setFirstSelection(false);
      setPreview(
        draggableImages.find((x) => x.name === 'catalog')?.id ||
          draggableImages[0].id,
      );
    }
    // eslint-disable-next-line
  }, [draggableImages]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [component, newImage, preview]);

  if (!component.images) return null;

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list.filter((x) => x.name !== "catalog"));
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const catalog = draggableImages.find((x) => x.name === "catalog");

    return [catalog, ...result].map((item, index) => ({
      ...item,
      order: index,
    }));
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      draggableImages,
      result.source.index,
      result.destination.index
    );

    setDraggableImages(items);
    dispatch(
      updateCmsComponentImageOrder(
        component.id,
        items.map((item) => ({ Id: item.id, Order: item.order }))
      )
    );
  };

  return (
    <Container>
      <ActionSection>
        {<Title>Catalog image:</Title>}
        {
          <ComponentImages>
            <ImageType
              images={component.images}
              setPreview={setPreview}
              preview={preview}
              showTooltip={(image) => showTooltip(image)}
              id={component.images.find((x) => x.name === 'catalog')?.id}
              label="catalog"
              icon={null}
            />
          </ComponentImages>
        }
        <Title>
          Select Tray Placement Image{' '}
          <div ref={ref}>
            <FontAwesomeIcon
              icon={imageIcons.add}
              style={{ cursor: 'pointer', marginLeft: 10 }}
              data-tip
              data-for="Tip"
              onClick={() => setNewImage(component)}
            />
            <ReactTooltip
              isCapture={true}
              event="click"
              id="Tip"
              place="right"
              effect="solid"
              type="light"
              clickable={true}
              scrollHide={false}
              resizeHide={false}
              offset={{ bottom: -70, left: 30 }}
              afterHide={hideTooltip}
            >
              <TooltipContent>
                <UploadComponentImage
                  hideTooltip={hideTooltip}
                  data={newImage}
                  imagePlacement={imagePlacement}
                  isCatalog={imagePlacement === 'catalog'}
                  trayImages={draggableImages.filter(
                    (x) => x.name !== imagePlacement,
                  )}
                  allowMultiple
                  maxHeight={180}
                  noFlip
                  showSave
                />
              </TooltipContent>
            </ReactTooltip>
          </div>
        </Title>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CustomCheckbox
            label="Revise Image Order"
            onChange={() => setDragMode(!dragMode)}
            style={{ margin: '0px 10px', display: 'flex' }}
            checked={dragMode}
          />
          {loading && <FontAwesomeIcon icon={faSpinner} spin />}
        </div>
        <FancyScroll offset={550}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided) => (
                <ComponentImages
                  ref={provided.innerRef}
                  dragMode={dragMode}
                  {...provided.droppableProps}
                >
                  {draggableImages
                    .filter((x) => x.name !== 'catalog')
                    .map((img, index) => (
                      <Draggable
                        key={img.id}
                        draggableId={img.id.toString()}
                        index={index}
                        isDragDisabled={!dragMode}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ImageType
                              images={draggableImages}
                              setPreview={setPreview}
                              preview={preview}
                              onImageChange={onImageChange}
                              showTooltip={(image) => showTooltip(image)}
                              id={img.id}
                              icon={
                                imageIcons[
                                  Object.keys(imageIcons).find((key) =>
                                    img.name.toLowerCase().includes(key),
                                  )
                                ] ? (
                                  <FontAwesomeIcon
                                    icon={
                                      imageIcons[
                                        Object.keys(imageIcons).find((key) =>
                                          img.name.toLowerCase().includes(key),
                                        )
                                      ]
                                    }
                                  />
                                ) : null
                              }
                              key={img.id}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}

                  {provided.placeholder}
                </ComponentImages>
              )}
            </Droppable>
          </DragDropContext>
        </FancyScroll>
      </ActionSection>
      <Wrapper>
        <Preview>
          {imagePreview ? (
            <img
              src={imagePreview.url}
              alt="Preview"
              style={{
                flexShrink: 0,
                objectFit: 'contain',
                position: 'relative',
                width: '75%',
                height: '750%',
              }}
            />
          ) : (
            <FontAwesomeIcon icon={faImage} />
          )}
          <span
            style={{
              pointerEvents: imagePreview ? 'auto' : 'none',
              opacity: imagePreview ? 1 : 0.5,
            }}
            onClick={() => deleteImage(preview)}
          >
            <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: 10 }} />
            Delete Image
          </span>
        </Preview>
      </Wrapper>
    </Container>
  );
};

export default Images;
