import React from 'react';
import styled from '@emotion/styled';
import { Route, Switch } from 'react-router-dom';
import CustomButton from '../../../components/Buttons/CustomButton';
import PackagingList from './PackagingList';
import FancyScroll from '../../../components/FancyScroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox } from '@fortawesome/free-solid-svg-icons';

const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    background: #1d265b;
    margin-top: -11px;
    width: 270px;
    flex: 0 0 auto;
    color: white;
    position: relative;
    height: 100vh;
    z-index: 1;

    & > div {
        position: fixed;
        width: inherit;
        height: 100%;
        background: #1d265b;
    }
`;

const SubmitSection = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 12px;
    bottom: 80px;
    position: absolute;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 15px 10px;
    font-weight: 500;
`;

const PackagingActiveMenu = ({
  options,
  selected,
  setSelected,
  addButtonClicked,
}) => {
  return (
    <LeftSection>
      <div>
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontSize: 12,
              textAlign: "right",
              margin: 5,
            }}
          >
            <FontAwesomeIcon icon={faBox} style={{ marginRight: 10 }} />{" "}
            Packaging Options
          </div>
        </Container>
        <Switch>
          <Route
            path={["/packaging-options", "/packaging-options/:id"]}
            exact
            render={(routeProps) => (
              <FancyScroll>
                <PackagingList
                  list={options}
                  {...routeProps}
                  selected={selected}
                  setSelected={setSelected}
                />
              </FancyScroll>
            )}
          />
        </Switch>
        <SubmitSection>
          <CustomButton
            rounded
            label={"Add packaging option"}
            border="white"
            background={"unset"}
            onClick={addButtonClicked}
          />
        </SubmitSection>
      </div>
    </LeftSection>
  );
};

export default PackagingActiveMenu;
