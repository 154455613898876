import * as actionTypes from '../../constants/actionTypes';

const initialState = {
    kits: [],
    totalPages: 0,
    component: null,
    loading: false,
    components: [],
    componentsLoading: false,
    updateKitTagsLoading: false
};


export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_KITS_TABLE_DATA_START:
            return {
                ...state,
                loading: action.loading
            }

        case actionTypes.GET_KITS_TABLE_DATA_SUCCESS:
            return {
                ...state,
                kits: action.data.kits,
                totalPages: action.data.totalPages,
                loading: false
            }

        case actionTypes.GET_KITS_TABLE_DATA_FAIL:
        case actionTypes.UPDATE_KIT_TAG_FAIL:
        case actionTypes.UPDATE_KIT_TAG_SUCCESS:
            return {
                ...state,
                loading: false,
                updateKitTagsLoading: false
            }

        case actionTypes.UPDATE_KIT_TAG_START:
            return {
                ...state,
                updateKitTagsLoading: true
            }

        default:
            return {
                ...state
            }
    }
};
