import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { kitStatusTableColumns, statuses as statusOptions } from '../../../constants/constants';
import Table from '../../../components/Tables/Table';
import CustomSelect from '../../../components/Selects/CustomSelect';
import FancyScroll from '../../../components/FancyScroll';
import Loader from '../../../components/loaders/Loader';

const Th = styled.th`
  cursor: ${(props) => (props.cursor ? 'pointer' : 'auto')};
  & > svg {
    margin-left: 5px;
  }
`;

const ListSubmittedKits = ({
  list,
  isEngineering,
  sendToSubmit,
  pushBack,
  submitToManufacturing,
  filterState,
  kitDesignLoading,
}) => {
  /*eslint default-case: ["off", { "commentPattern": "^skip\\sdefault" }]*/
  const [kits, setKits] = useState([]);
  const [kitsPrev, setKitsPrev] = useState([]);
  const [renderedRows, setRenderedRows] = useState(null);
  const [descSort, setDescSort] = useState(true);
  const [loader, setLoader] = useState(false);
  const [debouncedFilter, setDebouncedFilter] = useState({});

  const debFilter = useMemo(
    () => _.debounce((value) => setDebouncedFilter(value), 500),
    [],
  );
  const statuses = Object.values(statusOptions).map((s) => ({
    ...s,
    value: s.status,
  }));

  useEffect(() => {
    setLoader(true);
    debFilter(filterState);
    // eslint-disable-next-line
  }, [filterState]);

  useEffect(() => {
    let currentKits = kits;
    if (debouncedFilter.status) {
      currentKits = currentKits.filter(
        (x) => x.status === debouncedFilter.status,
      );
    }
    if (debouncedFilter.user) {
      currentKits = currentKits.filter(
        (x) =>
          x.createdBy?.id === debouncedFilter.user ||
          x.updatedBy?.id === debouncedFilter.user,
      );
    }
    if (debouncedFilter.search) {
      currentKits = currentKits.filter((x) =>
        x.name.toLowerCase().includes(debouncedFilter.search.toLowerCase()),
      );
    }
    setKits(_.orderBy(currentKits, ['created'], 'desc'));
    // eslint-disable-next-line
  }, [debouncedFilter]);

  useEffect(() => {
    if (Array.isArray(list)) {
      setKits(_.orderBy(list, ['created'], 'desc'));
    }
  }, [list]);

  useEffect(() => {
    if (!_.isEqual(kits, kitsPrev)) {
      setRenderedRows(_.uniqBy(kits, 'id').map(renderRow));
      setKitsPrev(kits);
    }
    setLoader(false);
    // eslint-disable-next-line
  }, [kits]);

  const sortByField = (col) => {
    if (!col.sortable) return;
    setLoader(true);
    let sorted = _.sortBy(kits, [col.field], [descSort]);
    if (descSort) {
      sorted = sorted.reverse();
    }
    setKits(sorted);
    setDescSort(!descSort);
  };

  const handleChangeStatus = ({ value }, id) => {
    if (kits.find((x) => x.id === id)?.status !== value) {
      switch (value) {
        case 1:
          pushBack(id);
          break;
        case 2:
          sendToSubmit(id);
          break;
        case 3:
          submitToManufacturing(id);
          break;
      }
    }
  };

  const renderRow = (kit, index) => {
    return (
      <tr key={kit.id}>
        <td>{new Date(kit.created).toLocaleDateString('en-US')}</td>
        <td style={{ width: 300 }}>{kit.name}</td>
        <td>{kit.facilityName || '-'}</td>
        {isEngineering ? (
          <td>
            {!!kit.createdBy &&
              (kit.createdBy.firstName || '') +
                ' ' +
                (kit.createdBy.lastName || '')}
          </td>
        ) : null}
        {isEngineering ? (
          <td>
            {!!kit.updatedBy &&
              (kit.updatedBy.firstName || '') +
                ' ' +
                (kit.updatedBy.lastName || '')}
          </td>
        ) : null}
        <td>
          <CustomSelect
            isDisabled={!isEngineering && kit.status !== 1}
            applyStyles
            isSearchable={false}
            options={statuses}
            isOptionDisabled={(option) => option.value === 3 && !isEngineering}
            value={statuses.find((r) => r.value === kit.status)}
            onChange={(value) => handleChangeStatus(value, kit.id)}
          />
        </td>
        <td>
          <a
            href={`/my-kit-list/${kit.id}/kitmaker`}
            style={{ textDecoration: 'underline' }}
          >
            Details
          </a>
          &nbsp;&nbsp;
          <a
            href={`/my-kit-list/${kit.id}/kitmaker/snapshots`}
            style={{ textDecoration: 'underline' }}
          >
            Graphic
          </a>
        </td>
        <td>
          <img
            alt="TrayImage"
            src={kit.trayImageUrl}
            style={{ maxWidth: 60 }}
          />
        </td>
      </tr>
    );
  };

  return (
    <FancyScroll
      styles={{ color: 'gray', backcolor: 'unset', width: '100%' }}
      offset="140"
    >
      {loader && !kitDesignLoading && (
        <Loader
          name={'Loading list...'}
          style={{ width: '200px', zIndex: '999' }}
        />
      )}
      <Table fontSize={12}>
        <thead>
          <tr>
            {kitStatusTableColumns
              .filter((x) => !(!isEngineering && x.notForSales))
              .map((col, index) => (
                <Th
                  key={'col' + index}
                  // colSpan={index === kitStatusTableColumns.length - 2 ? 2 : 1}
                  onClick={() => sortByField(col)}
                  cursor={col.sortable}
                >
                  {col.label}
                  {col.sortable && <FontAwesomeIcon icon={faSort} />}
                </Th>
              ))}
          </tr>
        </thead>
        <tbody>{renderedRows}</tbody>
      </Table>
    </FancyScroll>
  );
};

export default ListSubmittedKits;
