import React, { useState, useEffect, useMemo, useCallback } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
  loadPackagingOptions,
  createPackagingOption,
  updatePackagingOption,
  deletePackagingOption,
  updatePackagingOptionImage,
  deletePackagingOptionImage,
  clearPackagingOptionComponentsData,
  loadPackagingOptionComponents,
  deletePackagingOptionComponent,
  addComponentToPackagingOption,
} from "../../../redux/actions/packagingOptions";
import {
  clearComponentCatalogList,
  loadComponentCatalog,
} from "../../../redux/actions/componentCategories";
import PackagingActiveMenu from "./PackagingActiveMenu";
import PackagingOptionsStage from "./PackagingOptionsStage";
import { useDispatch, useSelector } from "react-redux";
import Confirmation from "../../../components/Confirmation/Confirmation";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash-es";

const content = {
  position: "fixed",
  left: "10%",
  display: "flex",
  width: "100%",
  height: "100%",
};
const searchDelay = 1000; // debounce delay;

const PackagingOptions = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(loadPackagingOptions());
  }, [dispatch]);

  const { packagingOptions, packagingOptionsLoading, components } = useSelector(
    (state) => state.packagingOptions
  );
  const { searchedComponents } = useSelector((state) => ({
    searchedComponents: state.componentCatalog.list,
  }));

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    dispatch(clearPackagingOptionComponentsData());
    dispatch(loadPackagingOptionComponents(selected));
  }, [selected, dispatch]);

  const onFileSelected = (e) => {
    if (e.target.files && e.target.files[0]) {
      dispatch(updatePackagingOptionImage(selected, e.target.files[0]));
    }
  };

  const [optionDeleteId, setOptionDeleteId] = useState(null);
  const [optionImageDeleteId, setOptionImageDeleteId] = useState(null);

  const onComponentSearch = (value) => searchComponent(value);

  const searchComponent = useMemo(
    () =>
      debounce(
        (value) =>
          dispatch(
            value
              ? loadComponentCatalog({
                  partTypeId: "",
                  search: value,
                  page: 0,
                  categoryId: "",
                })
              : clearComponentCatalogList()
          ),
        searchDelay
      ),
    [dispatch]
  );

  const deletePackage = useCallback(() => {
    setOptionDeleteId(selected);
  }, [selected]);

  const confirmDeleteOption = useCallback(() => {
    dispatch(deletePackagingOption(optionDeleteId));
    setOptionDeleteId(null);
    setSelected(null);
    history.replace("/packaging-options");
  }, [optionDeleteId, dispatch, history]);

  const createPackaging = useCallback(() => {
    const id = Math.max(...packagingOptions.map((po) => po.id)) + 1;
    dispatch(createPackagingOption(`Packaging ${id}`, setSelected, history));
  }, [dispatch, packagingOptions, history]);

  return (
    <div className="main-layout">
      <div
        className="content-area"
        style={{ paddingBottom: 0, paddingLeft: 0 }}
      >
        <LoadingOverlay
          active={packagingOptionsLoading}
          spinner
          styles={{ content }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "calc(100vh - 65px)",
              overflowY: "hidden",
            }}
          >
            <PackagingActiveMenu
              options={packagingOptions}
              selected={selected}
              setSelected={setSelected}
              addButtonClicked={createPackaging}
            />

            <PackagingOptionsStage
              selected={selected}
              onFileSelected={onFileSelected}
              onImageDelete={(id) => setOptionImageDeleteId(id)}
              updateOption={(id, name) =>
                dispatch(updatePackagingOption(id, name))
              }
              options={packagingOptions}
              onComponentSearch={onComponentSearch}
              searchedComponents={searchedComponents}
              components={components}
              deleteComponent={(id, componentId) =>
                dispatch(deletePackagingOptionComponent(id, componentId))
              }
              addComponent={(id, componentId, groupId) =>
                dispatch(
                  addComponentToPackagingOption(id, componentId, groupId)
                )
              }
              deletePackage={deletePackage}
            />
          </div>
          {optionDeleteId && (
            <Confirmation
              confirm={confirmDeleteOption}
              text="Are you sure you want to delete package option?"
              close={() => setOptionDeleteId(null)}
            />
          )}
          {optionImageDeleteId && (
            <Confirmation
              confirm={() => {
                dispatch(deletePackagingOptionImage(optionImageDeleteId));
                setOptionImageDeleteId(null);
              }}
              text="Are you sure you want to delete image for this package option?"
              close={() => setOptionImageDeleteId(null)}
            />
          )}
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default PackagingOptions;
