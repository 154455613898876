import * as actionTypes from '../../constants/actionTypes';

const initialState = {
  list: [],
  loading: false,
  totalPages: null,
  totalCount: null,
  savedKits: [],
  categories: [],
  component: null,
  componentsLoading: false,
  fallbackComponentSearchList: [],
  fallbackComponent: null,
  uploadPricingLoading: false,
  justCreated: null,
  updateImageOrderLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CMS_COMPONENTS_SUCCESS:
      return {
        ...state,
        ...(!action.isFallback
          ? { list: action.data.components }
          : { fallbackComponentSearchList: action.data.components }),
        totalPages: action.data.totalPages,
        totalCount: action.data.totalCount,
        componentsLoading: false,
      };

    case actionTypes.GET_CMS_COMPONENTS_FAIL:
    case actionTypes.GET_CMS_COMPONENT_INFO_FAIL:
    case actionTypes.UPDATE_CMS_COMPONENT_INFO_FAIL:
    case actionTypes.CREATE_CMS_COMPONENT_IMAGE_FAIL:
    case actionTypes.UPDATE_CMS_COMPONENT_IMAGE_FAIL:
    case actionTypes.DELETE_CMS_COMPONENT_IMAGE_FAIL:
    case actionTypes.DELETE_FALLBACK_COMPONENT_FAIL:
    case actionTypes.CREATE_CMS_COMPONENTS_FAIL:
      return {
        ...state,
        componentsLoading: false,
      };

    case actionTypes.CREATE_CMS_COMPONENTS_START:
    case actionTypes.GET_CMS_COMPONENTS_START:
    case actionTypes.GET_CMS_COMPONENT_INFO_START:
    case actionTypes.UPDATE_CMS_COMPONENT_INFO_START:
    case actionTypes.CREATE_CMS_COMPONENT_IMAGE_START:
    case actionTypes.UPDATE_CMS_COMPONENT_IMAGE_START:
    case actionTypes.DELETE_CMS_COMPONENT_IMAGE_START:
    case actionTypes.ADD_FALLBACK_COMPONENT_START:
    case actionTypes.DELETE_FALLBACK_COMPONENT_START:
      return {
        ...state,
        componentsLoading: true,
      };

    case actionTypes.GET_CMS_COMPONENT_INFO_SUCCESS:
      return {
        ...state,
        ...(!action.isFallback
          ? { component: action.data }
          : { fallbackComponent: action.data }),
        componentsLoading: false,
      };

    case actionTypes.UPDATE_CMS_COMPONENT_INFO_SUCCESS:
    case actionTypes.CREATE_CMS_COMPONENT_IMAGE_SUCCESS:
    case actionTypes.UPDATE_CMS_COMPONENT_IMAGE_SUCCESS:
    case actionTypes.DELETE_CMS_COMPONENT_IMAGE_SUCCESS:
    case actionTypes.ADD_FALLBACK_COMPONENT_SUCCESS:
    case actionTypes.DELETE_FALLBACK_COMPONENT_SUCCESS:
      return {
        ...state,
        componentsLoading: false,
      };

    case actionTypes.CREATE_CMS_COMPONENTS_SUCCESS:
      return {
        ...state,
        justCreated: action.data,
        list: [...state.list, action.data],
        componentsLoading: false,
      };

    case actionTypes.CLEAR_FALLBACK_COMPONENT_SEARCH_LIST:
      return {
        ...state,
        fallbackComponentSearchList: [],
      };

    case actionTypes.UPLOAD_COMPONENTS_PRICING_START:
      return {
        ...state,
        uploadPricingLoading: true,
      };

    case actionTypes.UPLOAD_COMPONENTS_PRICING_SUCCESS:
    case actionTypes.UPLOAD_COMPONENTS_PRICING_FAIL:
      return {
        ...state,
        uploadPricingLoading: false,
      };

    case actionTypes.UPDATE_COMPONENT_IMAGE_ORDER_START:
      return {
        ...state,
        updateImageOrderLoading: true,
      };

    case actionTypes.UPDATE_COMPONENT_IMAGE_ORDER_SUCCESS:
    case actionTypes.UPDATE_COMPONENT_IMAGE_ORDER_FAIL:
      return {
        ...state,
        updateImageOrderLoading: false,
      };

    default:
      return { ...state };
  }
};
