import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../../components/loaders/Loader';
import Wrapper from '../../../components/Wrapper/Wrapper';
import { loadCategories } from '../../../redux/actions/categories';
import TopSection from '../UserManagement/TopSection';
import Category from './Category';
import PartType from './PartType';
import EditSection from './EditSection';

const Content = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  margin: 20px 40px;
`;

const Title = styled.h4`
  font-weight: 600;
  display: flex;
  margin-left: 15px;
`;

const Categories = () => {
  const dispatch = useDispatch();
  const [categoryIds, setCategoryIds] = useState([]);
  const [partTypes, setPartTypes] = useState([]);
  const [partTypeId, setPartTypeId] = useState(null);
  const [editItem, setEditItem] = useState({
    type: null,
    item: null,
    isSubCategoryClicked: false,
  });
  const { list, loading, componentsCount, categorySelectOptions } = useSelector(
    (state) => state.categories,
  );

  useEffect(() => {
    dispatch(loadCategories());
  }, [dispatch]);

  useEffect(() => {
    if (partTypes.length) {
      const pTypes =
        list.find((l) => l.id === categoryIds[categoryIds.length - 1])
          ?.partTypes || [];
      setPartTypes(pTypes);
    }
    // eslint-disable-next-line
  }, [list]);

  if (loading) return <Loader />;

  const blocks = {
    'Part Class': list.length,
    'Sub Part Class': list
      .filter((f) => f.children.length)
      .map((ch) => ch.children)
      .flat().length,
    'Part Types': list
      .filter((f) => f.partTypes.length)
      .map((pt) => pt.partTypes)
      .flat().length,
    Components: componentsCount,
  };

  const openMenu = (category, isSubCategoryClicked, parentId) => {
    const { id, partTypes } = category;
    const temp = [...categoryIds];
    const isExist = categoryIds.findIndex((cid) => cid === id);

    if (isSubCategoryClicked && temp.length > 1) {
      temp.pop();
    }

    if (!isSubCategoryClicked && temp.length) {
      setCategoryIds(
        isExist > -1 ? temp.filter((_, index) => index < isExist) : [id],
      );
      setPartTypes(isExist > -1 ? [] : partTypes);
      setEditItem(
        isExist > -1
          ? { type: null, item: null, isSubCategoryClicked, parentId }
          : {
              type: 'category',
              item: category,
              isSubCategoryClicked,
              parentId,
            },
      );
      setPartTypeId(null);
      return;
    }

    temp.push(id);

    setCategoryIds(temp);
    setPartTypes(partTypes);
    setEditItem({
      type: 'category',
      item: category,
      isSubCategoryClicked,
      parentId,
    });
  };

  const activePartType = (partType) => {
    if (partTypeId === partType.id) {
      setPartTypeId(null);

      const id = [...categoryIds].pop();
      const category = list.find(
        (l) => l.id === id || l.children.find((ch) => ch.id === id),
      );

      setEditItem({ type: 'category', item: category });
      return;
    }

    setPartTypeId(partType.id);
    setEditItem({ type: 'partType', item: partType });
  };

  return (
    <div className="main-layout">
      <div className="content-area">
        <TopSection title="Part Classes" blocks={blocks} />
        <Wrapper>
          <Content>
            <Title>Part Class</Title>
            {list.map((category) => (
              <div key={category.id}>
                <Category
                  ids={categoryIds}
                  openMenu={openMenu}
                  category={category}
                />
              </div>
            ))}
          </Content>
          <Content>
            <Title>Part Type</Title>
            {partTypes.map((partType) => (
              <PartType
                partType={partType}
                isActive={partType.id === partTypeId}
                activePartType={activePartType}
                key={partType.id}
              />
            ))}
          </Content>
          {editItem.item && (
            <EditSection
              data={editItem}
              options={categorySelectOptions}
              list={list}
            />
          )}
        </Wrapper>
      </div>
    </div>
  );
};

export default Categories;
