import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Route, Switch } from 'react-router-dom';
import List from '../KitTrays/List';
import SearchRounded from '../../../components/Inputs/SearchRounded';
import TrayNavigation from '../KitTrays/TrayNavigation';
import ComponentOverview from './ComponentOverview';
import FancyScroll from '../../../components/FancyScroll';
import PaginationNew from '../../../components/Pagination/PaginationNew';
import GoBack from '../../NewKitMaker/Helpers/GoBack';
import CustomInput from '../../../components/Inputs/CustomInput';
import CustomSelect from '../../../components/Selects/CustomSelect';
import { multiSelectStyles } from '../../../constants/constants';
import CustomButton from '../../../components/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import { createCmsComponent } from '../../../redux/actions/components';
import { flatten } from "../../../utils/categoryMapping";
import { orderBy } from "lodash-es";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #1d265b;
  margin-top: -11px;
  width: 270px;
  flex: 0 0 auto;
  color: white;
  position: relative;
  height: 100vh;
  z-index: 1;

  & > div {
    position: fixed;
    width: inherit;
    height: 100%;
    background: #1d265b;
    padding: 20px 10px;
  }
`;

const Search = styled.div`
  display: flex;
  position: relative;
  color: black;
  margin-bottom: 10px;
`;

const Pagination = styled.div`
  display: flex;
  overflow-x: hidden;
  justify-content: center;
  & > div {
    margin: 0px;
    a {
      font-size: 10px !important;
    }
    ul {
      margin: 5px 0px !important;
    }
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;

  & > div {
    margin-bottom: 20px;
  }

  input {
    background: #1d265b;
    color: #ffffffa3;
    text-align: center;
  }

  input:focus + label span,
  input:valid + label span {
    color: white !important;
    font-size: 12px !important;
  }

  label,
  label:after {
    border-bottom: 1px solid #ffffffa3;
  }

  span {
    color: white;
    font-size: 12px;
    font-weight: 500;
  }
`;

const Title = styled.div`
  justify-content: space-between;
  display: flex;
  width: 220px;
  font-weight: 500;
  font-size: 12px;
  padding: 0 10px;
`;

const addObjectInitial = {
  sku: "",
  label: "",
  description: "",
  categoryId: null,
};

const ComponentsActiveMenu = ({
  onSearch,
  components,
  onComponentClick,
  showNavigation,
  component,
  overviewChange,
  categories,
  onSelectChange,
  disabled,
  onSubmit,
  searchValue,
  customGoBack,
  handlePagination,
  totalPages,
  activePage,
}) => {
  const [showDropdown, setShowDropDown] = useState(null);
  const [showAddComponent, setShowAddComponent] = useState(false);
  const [addObject, setAddObject] = useState(addObjectInitial);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setAddObject({ ...addObject, [e.target.name]: e.target.value });
  };

  const handleCategoryChange = (option) => {
    setAddObject({ ...addObject, categoryId: option.id });
  };

  const addHandler = () => {
    setShowAddComponent(false);
    dispatch(createCmsComponent({ ...addObject }));
    setAddObject(addObjectInitial);
  };

  return (
    <Container>
      <div>
        {showNavigation && (
          <TrayNavigation
            showDropdown={showDropdown}
            setShowDropDown={setShowDropDown}
            customGoBack={customGoBack}
          />
        )}
        <Switch>
          <Route
            exact
            path="/cms-components"
            render={() =>
              !showAddComponent ? (
                <>
                  <Search>
                    <SearchRounded
                      placeholder="Component Search..."
                      onChange={onSearch}
                      value={searchValue}
                    />
                  </Search>
                  <FancyScroll>
                    <List
                      list={components}
                      title="Components"
                      offset={240}
                      onItemClick={onComponentClick}
                      addItem={() => setShowAddComponent(true)}
                    />
                  </FancyScroll>
                  {totalPages > 1 && (
                    <Pagination>
                      <PaginationNew
                        totalPages={totalPages}
                        activePage={activePage}
                        onChange={handlePagination}
                        pagesNumber={3}
                        isMobile={true}
                      />
                    </Pagination>
                  )}
                </>
              ) : (
                <>
                  <GoBack
                    title="Create component"
                    goBack={() => setShowAddComponent(false)}
                  />
                  <div style={{ fontSize: 12 }}>
                    <InputGroup>
                      <CustomInput
                        value={addObject.sku}
                        name="sku"
                        onChange={handleChange}
                        label="SKU"
                      />
                      <CustomInput
                        value={addObject.label}
                        name="label"
                        onChange={handleChange}
                        label="Label"
                      />
                      <CustomInput
                        value={addObject.description}
                        name="description"
                        onChange={handleChange}
                        label="Description"
                      />
                    </InputGroup>
                    <Title>
                      <span>Category</span>
                    </Title>
                    <CustomSelect
                      isSearchable={false}
                      customStyles={multiSelectStyles}
                      placeholder="Select Category"
                      options={orderBy(
                        flatten(categories, false),
                        "name",
                        "asc"
                      )}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      onChange={handleCategoryChange}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: 20,
                      }}
                    >
                      <CustomButton
                        label="Save"
                        disabled={Object.values(addObject).some(
                          (x) => !x || x === ""
                        )}
                        rounded
                        onClick={addHandler}
                        background="#61A431"
                      />
                    </div>
                  </div>
                </>
              )
            }
          />
          <Route
            path="/cms-components/:pathname/:id"
            render={() => (
              <ComponentOverview
                component={component}
                onChange={overviewChange}
                categories={categories}
                onSelectChange={onSelectChange}
                disabled={disabled}
                onSubmit={onSubmit}
                showDropdown={showDropdown}
                setShowDropDown={setShowDropDown}
              />
            )}
          />
        </Switch>
      </div>
    </Container>
  );
};

export default ComponentsActiveMenu;
