import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import * as _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencilAlt, faSave } from '@fortawesome/free-solid-svg-icons'
import { loadProductFamiliesList, deleteProductFamily, updateProductFamily, createProductFamily } from '../../../redux/actions/productFamilies';
import TopSection from '../UserManagement/TopSection';
import Table from '../../../components/Tables/Table';
import Loader from '../../../components/loaders/Loader';
import Wrapper from '../../../components/Wrapper/Wrapper';
import Confirmation from '../../../components/Confirmation/Confirmation';
import { getCaretPosition, setCaretPosition } from './CaretPosition';

const CreateButtonWrapper = styled.div`
    position: absolute;
    bottom: 20px;
    left: 20px;
`;

const CreateButton = styled.button`
    background: #61A431;
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    margin: 0 auto;
    display: block;
    & > svg {
        padding-left: 10px;
        width: inherit !important;
    }
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }
    margin-bottom: 10px;
`;

const DeleteButton = styled(CreateButton)`
    background: #cc2628;
`

const DeleteButtonWrapper = styled.div`
    position: absolute;
    bottom: 10px;
    left: 0px;
    width: 100%;
`;

const TableWrapper = styled.div`
    position: relative;
    min-height: 70vh;
    width: 70%;
    padding: 0px 50px 100px 50px;
`;

const Th = styled.th`
    font-size: 25px;
    height: 100px !important;
    border-bottom: unset !important;
`;

const Td = styled.td`
    font-size: 15px;
    width: 50%;
    height: 50px !important;
    border-bottom: unset !important;
    cursor: pointer;
`;

const TdSelected = styled.td`
    font-size: 15px;
    width: 50%;
    background: #1d265b !important;
    color: white;
    height: 50px !important;
    border-bottom: unset !important;
`;

const ProductEditorWrapper = styled.div`
    width: 30%;
    background: #e3e3ec;
    padding: 10px;
    max-height: 80vh;
    position: relative;
`;

const ProductEditorLabel = styled.div`
    font-size: 12px;
    text-align: left;
    color: #1d265b;
`;

const ProductEditorValue = styled.div`
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    color: #1d265b;
    padding: 10px;
    position: relative;
    margin-right: 20px;
`;

const ProductEditorValueInput = styled.input`
    width: 100%;
    height: 35px;
    text-align: center;
    font-weight: bold;
    color: #1d265b;
    border: none;
    border-radius: 3px;
    outline: none;
`;

const EditButton = styled.button`
    position: absolute;
    color: #1d265b;
    right: -20px;
    border: none;
    background: unset;
    & > svg > path {
        opacity: 0.8;
        &:hover {
            opacity: 1;
        }
    }
`;

const SaveButton = styled(EditButton)`
    color: #62a43e;
    background: white;
    height: 35px;
    text-align: center;
    font-weight: bold;
    border: none;
    border-radius: 3px;
    outline: none;
    margin-top: 1px;
    box-shadow: 5px 2px 5px 0 rgba(0, 0, 0, 0.16), 10px 2px 10px 0 rgba(0, 0, 0, 0.12)
`;

const confirmationText = 'Are you sure you want to delete this product family?';

const ProductFamilies = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadProductFamiliesList());
    }, [dispatch]);

    const {productFamilies, productFamiliesLoading} = useSelector(state => state.productFamilies);

    const [loadProductFamilies, setProductFamilies] = useState([]);

    const [selectedProductFamily, setSelectedProductFamily] = useState(null);

    const [selectedProperty, selectProperty] = useState(null);

    const [actionView, setActionView] = useState(null);
    const handleDelete = (id) => setActionView(<Confirmation confirm={() => confirmDelete(id)} close={closeModal} text={confirmationText} />);
    const handleSave = async () => {
        selectProperty(null);
        if (!createdModel) {
            dispatch(updateProductFamily(selectedProductFamily));
            dispatch(loadProductFamiliesList());
        }
    };
    const handleChange = (e) => {
        if (e.target.name === "marginPercent") {
            const reg = /^[0-9]*[.]{0,1}[0-9]{0,2}%$/;
            if((reg.test(e.target.value) && reg.test(selectedProductFamily.marginPercent)) || !reg.test(selectedProductFamily.marginPercent)) {
                setSelectedProductFamily({...selectedProductFamily, marginPercent: e.target.value, margin: Number.parseFloat(e.target.value) / 100});
            }
        } else {
            setSelectedProductFamily({...selectedProductFamily, [e.target.name]: e.target.value});
        }
    };
    const [createdModel, setCreatedModel] = useState(false);
    const handleCreateClick = () => {
        setCreatedModel(true);
        setSelectedProductFamily({
            name: "",
            margin: 0,
            marginPercent: "%"
        });
    }
    const handleCreate = () => {
        dispatch(createProductFamily(selectedProductFamily));
        dispatch(loadProductFamiliesList());
        setSelectedProductFamily(null);
    };
    const cancelCreate = () => {
        setSelectedProductFamily(null);
        setCreatedModel(false);
    };
    const closeModal = () => setActionView(null);

    const confirmDelete = (id) => {
        dispatch(deleteProductFamily(id));
        setSelectedProductFamily(null);
        closeModal();
    };

    const handleClickInput = (e) => {
        if (getCaretPosition(e.target) === e.target.value.length) {
            setCaretPosition(e.target, e.target.value.length - 1);
        }
    }

    useEffect(() => {
      const sorted = _.orderBy(productFamilies, [
        (productFamily) => productFamily.name,
      ]);
      setProductFamilies(
        sorted.map((x) => ({
          ...x,
          marginPercent: Number.parseInt(x.margin * 10000) / 100 + '%',
        })),
      );
      // eslint-disable-next-line
    }, [productFamilies]);

    if (productFamiliesLoading) return <Loader />;

    return (
        <div className="main-layout">
            <div className="content-area">
                <TopSection
                    title='Product Families'
                />
                <Wrapper>
                    <TableWrapper>
                        <Table>
                            <thead>
                                <tr>
                                    <Th>Product Family</Th>
                                    <Th>Margin</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {loadProductFamilies.map(productFamily => <tr className="text-center" key={productFamily.id} onClick={() => { selectProperty(null); setCreatedModel(false); setSelectedProductFamily(productFamily); }}>
                                    {selectedProductFamily?.id !== productFamily.id ? <Td>{productFamily.name}</Td>: <TdSelected>{productFamily.name}</TdSelected> }
                                    <Td>{productFamily.marginPercent}</Td>
                                </tr>)}
                            </tbody>
                        </Table>
                        <CreateButtonWrapper><CreateButton onClick={handleCreateClick}>Add product family</CreateButton></CreateButtonWrapper>
                    </TableWrapper>
                    { selectedProductFamily && <ProductEditorWrapper>
                        <ProductEditorLabel>Product Family Name</ProductEditorLabel>
                        <ProductEditorValue>{ selectedProperty === "name" ? 
                            (<><ProductEditorValueInput name="name" value={selectedProductFamily.name} onChange={handleChange}/><SaveButton onClick={handleSave}><FontAwesomeIcon icon={faSave} /></SaveButton></>) :
                            <>{selectedProductFamily.name.length > 0 ? selectedProductFamily.name : "Type Family Name"}<EditButton onClick={() => { selectProperty("name")}}><FontAwesomeIcon icon={faPencilAlt} /></EditButton></>}
                        </ProductEditorValue>
                        <ProductEditorLabel>Margin</ProductEditorLabel>
                        <ProductEditorValue>{ selectedProperty === "margin" ? 
                            (<><ProductEditorValueInput name="marginPercent" value={selectedProductFamily.marginPercent} onChange={handleChange} onClickCapture={handleClickInput} onKeyUp={handleClickInput}/><SaveButton onClick={handleSave}><FontAwesomeIcon icon={faSave} /></SaveButton></>) :
                            <>{selectedProductFamily.marginPercent.length > 1 ? selectedProductFamily.marginPercent : "Add Margin Percentage"}<EditButton onClick={() => { selectProperty("margin")}}><FontAwesomeIcon icon={faPencilAlt} /></EditButton></>}
                        </ProductEditorValue>
                        <DeleteButtonWrapper>
                            {createdModel && <CreateButton onClick={handleCreate}>Add product family</CreateButton>}
                            <DeleteButton onClick={() => createdModel ? cancelCreate() : handleDelete(selectedProductFamily.id)} >{!createdModel ? "Delete product family" : "Cancel"} <FontAwesomeIcon icon={faTrash} /></DeleteButton></DeleteButtonWrapper>
                    </ProductEditorWrapper> }
                </Wrapper>
                {actionView}
            </div>
        </div>
    );
};

export default ProductFamilies;
