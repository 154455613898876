import { getToken } from '../../utils/getToken';
import axios from '../axiosMiddleware/axios';
import * as actionTypes from '../../constants/actionTypes';

export const loadLogs = (params) => async (dispatch) => {
  dispatch({ type: actionTypes.LOAD_LOGS_START });
  try {
    const { page = 0, logLevels = [], dates, search } = params;

    const mapLogLevelsToString = logLevels.reduce((acc, curr) => {
      acc += `&LogLevels=${curr}`;
      return acc;
    }, '');
    const mapDateRangeToString = `&MinDate=${dates.start}&MaxDate=${dates.end}`;
    const searchText = `&SearchText=${search}`;
    const pageNumber = `&Page=${page}`;

    const res = await axios.get(
      '/api/logs?PageSize=100' +
        pageNumber +
        mapLogLevelsToString +
        mapDateRangeToString +
        searchText,
      {
        headers: { Authorization: 'Bearer ' + getToken() },
      },
    );

    dispatch({ type: actionTypes.LOAD_LOGS_SUCCESS, data: res.data });
  } catch (err) {
    dispatch({ type: actionTypes.LOAD_LOGS_FAIL, error: err });
  }
};
