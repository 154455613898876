import React, { useCallback, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { imageFormats, maxImageSize } from "../../../constants/constants";
import { showSnackbar } from "../../../redux/actions/snackbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faList,
  faTimes,
  faTh,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../components/loaders/Loader";
import SearchRounded from "../../../components/Inputs/SearchRounded";
import FolderView from "./FolderView";
import UploadPageBreakImage from "./UploadPageBreakImage";
import Switcher from "../../../components/Switchers/Switcher";
import Confirmation from "../../../components/Confirmation/Confirmation";

const Container = styled.div`
  width: 1000px;
  height: ${({ maxHeight }) => (maxHeight < 600 ? maxHeight : 600)}px;
  position: relative;
  display: flex;
  border-radius: 10px;
  box-shadow: 0 4px 8px 2px rgb(0 0 0 / 30%);
`;

const ImagesWrapper = styled.div(
  ({ listView }) => `
  display: flex;
  align-content: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  ${
    listView &&
    `
    flex-direction: column;
    align-items: baseline;

  `
  };
`
);

const FolderSection = styled.div`
  display: flex;
  width: 25%;
  height: 100%;
  background: #eee;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  flex-direction: column;
  padding: 20px;
`;

const Images = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  padding: 15px;
  position: relative;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 6px;
    background: ${(props) => props.backcolor || "#b6adf9"};
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.backcolor || "#1d265b"};
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.color || "#8E93AD"};
    border-radius: 3px;
  }
`;

const ImageContainer = styled.div(
  ({ listView }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8e92ad;
  flex-direction: column;
  font-weight: 500;
  font-size: 10px;
  width: 25%;
  position: relative;
  padding-top: 10px;

  &:hover {
    background: #eee;
    color: #1d265b;
    border-radius: 10px;
  }

  ${
    !listView &&
    `
    
  img {
    max-width: 75%;
  }`
  }

  span {
    text-align: center;
  }

  ${
    listView &&
    `
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;  
    padding: 10px;
    align-items: center;
    border: 1px solid #eee;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px 2px rgb(0 0 0 / 10%);

    img {
      width: 100%;
    }

  `
  }
`
);

// const Title = styled.div`
//   display: flex;
//   font-weight: 600;
//   font-size: 12px;
//   color: rgb(30, 39, 92);
//   justify-content: center;
// `;

const CloseButton = styled.div`
  position: sticky;
  font-size: 12px;
  color: red;
  top: 0px;
  right: 0px;
  cursor: pointer;
  opacity: 0.7;
  z-index: 100;
  align-self: flex-end;

  &:hover {
    opacity: 1;
  }
`;

const Upload = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #1d265b;
  svg {
    margin-right: 10px;
  }
`;

const Searchbox = styled.div`
  position: relative;
  color: #1d265b;
  margin-left: -25px;
  width: fit-content;
  display: flex;
  align-items: center;
  width: 240px;
  justify-content: space-between;

  & > div > svg {
    font-size: 22px;
    margin-left: 10px;
    cursor: pointer;
  }
`;

const ListView = styled.div`
  margin-left: 25px;
  width: calc(100% - 225px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > div {
    position: absolute;
    right: 100px;
    bottom: 10px;
  }

  & > span:nth-of-type(2) {
    position: absolute;
    top: 8px;
    right: 10px;
  }

  & > span:last-of-type {
    position: absolute;
    bottom: 8px;
    right: 10px;
    color: red;
  }
`;

const ImageTooltipContent = ({
  pageBreakImages: images,
  pageBreakImagesLoading,
  deletePageBreakImage,
  addPageBreakImage,
  onImageSelect,
  selectedPage,
  hide,
}) => {
  const dispatch = useDispatch();

  const [image, setImage] = useState(null);
  const [name, setName] = useState("");
  const [search, setSearch] = useState("");
  const [pageBreakImages, setPageBreakImages] = useState(images);
  const [confirmation, setConfirmation] = useState(null);
  const [listView, setListView] = useState(false);
  const [editableDivider, setEditableDivider] = useState(null);

  useEffect(() => {
    setPageBreakImages(images);
  }, [images]);

  const onDrop = useCallback(
    (files) => {
      if (!files.length) {
        dispatch(showSnackbar("warning", "Please select image"));
        return;
      }
      setImage({ file: files[0], url: URL.createObjectURL(files[0]) });
    },
    [dispatch]
  );

  const handleUpload = (name) => {
    addPageBreakImage(image?.file, name, editableDivider);
    setImage(null);
    if (editableDivider) {
      setEditableDivider(null);
      setName("");
    }
  };

  const onSearch = (e) => {
    const val = e.target.value.toLowerCase();
    setSearch(val);
    setPageBreakImages(
      images.filter((img) => img.name.toLowerCase().includes(val))
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: maxImageSize,
    accept: imageFormats,
  });

  if (pageBreakImagesLoading) {
    return (
      <Container>
        <Loader style={{ left: "40%", top: "45%" }} />
      </Container>
    );
  }

  return (
    <Container maxHeight={window.innerHeight - 140}>
      <FolderSection>
        <Searchbox>
          <div>
            <SearchRounded
              value={search}
              onChange={onSearch}
              placeholder="Search-by"
              style={{ width: 160, height: 35, margin: "0px 0px 0px 10px" }}
            />
          </div>
          <div>
            <FontAwesomeIcon
              icon={faTh}
              style={{ color: !listView ? "#61A431" : "#8e92ad" }}
              onClick={() => setListView(false)}
            />
            <FontAwesomeIcon
              icon={faList}
              style={{ color: listView ? "#61A431" : "#8e92ad" }}
              onClick={() => setListView(true)}
            />
          </div>
        </Searchbox>
        <FolderView
          imageNames={images.map((pbi) => pbi.name)}
          setPageBreakImages={setPageBreakImages}
          images={images}
        />
        <Upload {...getRootProps()}>
          <FontAwesomeIcon icon={faImage} />
          <span>{"Upload new image"}</span>
          <input {...getInputProps()} />
        </Upload>
      </FolderSection>
      <Images>
        <CloseButton onClick={hide}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        {/* <Title>
          <span>{image ? "Upload Image" : "Select Image"}</span>
        </Title> */}
        <ImagesWrapper listView={listView}>
          {!image &&
            !editableDivider &&
            (pageBreakImages || []).map((obj) => (
              <ImageContainer key={obj.url} listView={listView}>
                {listView ? (
                  <div style={{ width: 100, height: "auto" }}>
                    <img src={obj.thumbnailUrl} alt={obj.name} />
                  </div>
                ) : (
                  <img src={obj.thumbnailUrl} alt={obj.name} />
                )}

                {listView ? (
                  <ListView>
                    <span>{obj.name}</span>
                    <Switcher
                      checked={selectedPage?.image === obj.id}
                      onChange={() => setConfirmation(obj)}
                      style={{ position: "absolute" }}
                    />
                    <span
                      style={{
                        marginBottom: 5,
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => setEditableDivider(obj)}
                    >
                      Edit
                    </span>
                    <span
                      style={{
                        marginBottom: 5,
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        deletePageBreakImage(obj.id);
                        setName("");
                      }}
                    >
                      Delete
                    </span>
                  </ListView>
                ) : (
                  <>
                    <span>{obj.name}</span>
                    <Switcher
                      checked={selectedPage?.image === obj.id}
                      onChange={() => setConfirmation(obj)}
                    />
                    <div
                      style={{
                        display: "flex",
                        width: "75%",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          marginBottom: 5,
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => setEditableDivider(obj)}
                      >
                        Edit
                      </span>
                      <span
                        style={{
                          marginBottom: 5,
                          cursor: "pointer",
                          textDecoration: "underline",
                          color: "red",
                        }}
                        onClick={() => {
                          deletePageBreakImage(obj.id);
                          setName("");
                        }}
                      >
                        Delete
                      </span>
                    </div>
                  </>
                )}
              </ImageContainer>
            ))}

          {!!(image && image.url) && (
            <ImageContainer
              style={{
                width: 300,
                height: 200,
                justifyContent: "baseline",
                paddingTop: 0,
              }}
            >
              <img
                src={image?.url}
                alt="Upload"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </ImageContainer>
          )}
        </ImagesWrapper>

        <UploadPageBreakImage
          image={image}
          setName={setName}
          name={name}
          handleUpload={handleUpload}
          setImage={setImage}
          imageNames={images.map((pbi) => pbi.name)}
          editableDivider={editableDivider}
          setEditableDivider={setEditableDivider}
        />
      </Images>

      {confirmation && (
        <Confirmation
          confirm={() => {
            onImageSelect(confirmation);
            setConfirmation(null);
          }}
          text={`Are you sure want to ${
            selectedPage?.image ? "unset" : "set"
          } this divider image ?`}
          close={() => setConfirmation(null)}
          cancelLabel="NO"
          confirmLabel="YES"
        />
      )}
    </Container>
  );
};

export default ImageTooltipContent;
