

import auth from './reducers/auth';
import { combineReducers } from 'redux';
import common from './reducers/common';
import settings from './reducers/settings';
import category from './reducers/category';
import userManagement from './reducers/userManagement';
import productFamilies from './reducers/productFamilies';
import snackbar from './reducers/snackbar';
import categories from './reducers/categories';
import componentCatalog from './reducers/componentCategories';
import kits from './reducers/kits';
import kitTrays from './reducers/kitTrays';
import components from './reducers/components';
import snapshots from './reducers/snapshots';
import kitFinder from './reducers/kitFinder';
import kitTagging from './reducers/kitTagging';
import packagingOptions from './reducers/packagingOptions';
import menu from './reducers/menu';
import logs from './reducers/logs';

export default combineReducers({
  auth,
  common,
  settings,
  category,
  userManagement,
  snackbar,
  categories,
  productFamilies,
  componentCatalog,
  kits,
  kitTrays,
  components,
  snapshots,
  kitFinder,
  kitTagging,
  packagingOptions,
  menu,
  logs,
});
