import React, { Component } from 'react';
import { MDBInput, MDBCol, MDBContainer, MDBRow, MDBBtn, MDBAlert } from "mdbreact";
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import {
    LOGIN_PAGE_UNLOADED,
    LOGOUT
} from '../../constants/actionTypes';
import agent from '../../agent';
import Profile from '../../shared/img/profileimage.png'


const mapDispatchToProps = dispatch => ({
    onUnload: () =>
        dispatch({ type: LOGIN_PAGE_UNLOADED }),
    onClickLogout: () => dispatch({ type: LOGOUT }),
});

const mapStateToProps = state => {
    return { currentUser: state.common.currentUser }
};
let isPasswordNotMatch = false;
const defaultImage = { Profile };
class EditProfile extends Component {
    currentLoggedInUser = null;
    currentUserName = null;
    state = {
        isOpen: false
    };

    constructor(props) {
        super(props);
        this.state = {
            UserName: null,
            FirstName: null,
            LastName: null,
            Number: null,
            Street: null,
            SuiteApt: null,
            City: null,
            ZipCode: null,
            Phone: null,
            file: '',
            imagePreviewUrl: '',
            Image: null,
            isSubmitSuccess: true,
            confirmPassword: null,
            newPassword: null,
            isimagemismatch: false
        }
        const token = window.localStorage.getItem('jwt');
        if (token) {

        }
        else {
            this.props.onClickLogout()
        }

        this.RedirectmyProfile = this.RedirectmyProfile.bind(this);

    }
    addDefaultSrc = (ev) => {
        ev.target.src = Profile
    }

    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
    componentWillMount() {
        this.currentLoggedInUserDetail = JSON.parse(window.localStorage.getItem('loggeduser'));
        this.setState({ LoggedUser: this.currentLoggedInUserDetail.userName });
        this.setState({ UserName: this.currentLoggedInUserDetail.userName });
        this.setState({ FirstName: this.currentLoggedInUserDetail.firstName });
        this.setState({ LastName: this.currentLoggedInUserDetail.lastName });
        this.setState({ Number: this.currentLoggedInUserDetail.number });
        this.setState({ Street: this.currentLoggedInUserDetail.street });
        this.setState({ SuiteApt: this.currentLoggedInUserDetail.suiteApt });
        this.setState({ City: this.currentLoggedInUserDetail.city });
        this.setState({ ZipCode: this.currentLoggedInUserDetail.zipCode });
        this.setState({ Phone: this.currentLoggedInUserDetail.phone });
        const token = window.localStorage.getItem('jwt');
        if (token) {
            agent.Auth.getProfileImage().then((res) => {
                if (res === null) {
                    this.setState({ Image: defaultImage });
                }
                else {
                    this.setState({ Image: URL.createObjectURL(res) });
                }
            });
        }
        else {
            this.props.onClickLogout()
        }
    }
    handleChange = (event) => {
        switch (event.target.name) {
            case "UserName":
                this.setState({ UserName: event.target.value })
                break;
            case "FirstName":
                this.setState({ FirstName: event.target.value })
                break;
            case "LastName":
                this.setState({ LastName: event.target.value })
                break;
            case "Number":
                this.setState({ Number: event.target.value })
                break;
            case "Street":
                this.setState({ Street: event.target.value })
                break;
            case "SuiteApt":
                this.setState({ SuiteApt: event.target.value })
                break;
            case "City":
                this.setState({ City: event.target.value })
                break;
            case "ZipCode":
                this.setState({ ZipCode: event.target.value })
                break;
            case "Phone":
                this.setState({ Phone: event.target.value })
                break;
            default:
                break;
        }


    }
    _handleSubmit(e) {
        e.preventDefault();
        // TODO: do something with -> this.state.file


    }

    _handleImageChange(e) {
        e.preventDefault();
        // let reader = new FileReader();

        var reader = new FileReader();
        const file = e.target.files[0];;
        if (file.type !== "image/jpeg" && file.type !== "image/jpg" && file.type !== "image/png") {
            this.setState({ isimagemismatch: true });
        } else {
            reader.onload = () => this.setState({
                file: file,
                imagePreviewUrl: reader.result
            })
            if (file !== null) {
                reader.readAsDataURL(file);
            }
        }
    }

    imageuploadbtn() {

        document.getElementById('file1').innerHTML = ""
    }

    RedirectmyProfile(ev) {
        ev.preventDefault();
        let UserName = this.state.UserName;
        let FirstName = this.state.FirstName;
        let User = FirstName.replace(/\s+/g, '');
        let LastName = this.state.LastName;
        let last = LastName ? LastName.replace(/\s+/g, '') : LastName;
        //let homeNumber = this.state.Number;
        let Street = this.state.Street;
        let StreetNumber = Street ? Street.replace(/^\s*|\s*$/g, '') : Street;
        let SuiteApt = this.state.SuiteApt;
        let SuitAptTrim = SuiteApt ? SuiteApt.replace(/\s+/g, '') : SuiteApt;
        let City = this.state.City;
        let ZipCode = this.state.ZipCode;
        let ZipCodeTrim = ZipCode ? ZipCode.replace(/\s+/g, '') : ZipCode;
        let Phone = this.state.Phone;
        let PhoneTrim = Phone ? Phone.replace(/\s+/g, '') : Phone;
        let Image = this.state.file;

        this.setState({ isSubmitSuccess: false });
        agent.Auth.updateUser(UserName, User, last, StreetNumber, SuitAptTrim, City, ZipCodeTrim, PhoneTrim, Image).then((res) => {
            // @todo this should be changed 
            const userData = JSON.parse(window.localStorage.getItem('loggeduser'));

            userData.userName = UserName;
            userData.firstName = User;
            userData.lastName = last;
            userData.street = StreetNumber;
            userData.suiteApt = SuitAptTrim;
            userData.city = City;
            userData.zipCode = ZipCodeTrim;
            userData.phone = PhoneTrim;

            window.localStorage.setItem('loggeduser', JSON.stringify(userData));
            let path = '/myprofile';
            this.props.history.push(path);
            this.setState({ isSubmitSuccess: true });
        })
    }

    render() {
        let { imagePreviewUrl } = this.state;
        let $imagePreview = null;

        if (imagePreviewUrl) {

            $imagePreview = (<img src={imagePreviewUrl} className="profileImage z-depth-1 rounded-circle" alt="profile" onError={(ev) => this.addDefaultSrc(ev)} />);
        } else {

            $imagePreview = (<img src={this.state.Image ? this.state.Image : Profile} className="profileImage z-depth-1 rounded-circle" alt="profile" onClick={(e) => this._handleImageChange(e)} onError={(ev) => this.addDefaultSrc(ev)} />);
        }

        const alertPopUp =

            <MDBAlert color="success" dismiss>
                <strong>Password mismatch</strong>
            </MDBAlert>

        const imageextensionmismatch = <MDBAlert color="success" dismiss>
            <strong>Please upload only .png, .jpeg, .jpg image format.</strong>
        </MDBAlert>

        return (
            <div className="main-layout">
                <div className="content-area">
                    <LoadingOverlay
                        active={this.state.isSubmitSuccess === false}
                        className=""
                        spinner
                        text='Please wait...'
                    >
                        <MDBContainer fluid>
                            <h5 className="mt-5 mt-sm-5 mt-lg-0 mt-md-0">Edit Profile</h5>
                            {isPasswordNotMatch ? alertPopUp : null}
                            {this.state.isimagemismatch ? imageextensionmismatch : null}
                            <MDBRow>
                                <MDBCol md="12">
                                    <section id="authentication-layout">
                                        <div className="flex-container">
                                            <div className="img-area">

                                                {$imagePreview}
                                                <br />
                                                <br />
                                                <label htmlFor="upload-photo"><strong>UPLOAD NEW PHOTO</strong></label>
                                                <input type="file" name="photo" id="upload-photo" onChange={(e) => this._handleImageChange(e)} accept="image/*" />
                                                <p className="resolution">Supported image format - .png, .jpeg, .jpg</p>
                                            </div>

                                            <div className="form-area">
                                                <div className="userdetails text-left">
                                                    <p> {this.state.UserName}</p>
                                                    <strong><span className="salesSpan">Sales Representative Default Address</span></strong>
                                                </div>
                                                <form onSubmit={this.RedirectmyProfile}>
                                                    <MDBRow className="mb-9">
                                                        <MDBCol md="6"> <MDBInput label="First Name *" type="text" title="Enter only characters" maxLength="25" pattern="^[A-Za-z -]+$" name="FirstName" validation="required" valueDefault={this.state.FirstName} onChange={this.handleChange} required /></MDBCol>
                                                        <MDBCol md="6"> <MDBInput label="Last Name *" type="text" name="LastName" title="Enter only characters" maxLength="25" pattern="^[A-Za-z -]+$" valueDefault={this.state.LastName} onChange={this.handleChange} required /></MDBCol>
                                                        {/* <MDBCol md="6"> <MDBInput label="Number *" type="text" name="Number" valueDefault={this.state.Number} onChange={this.handleChange} min="0" title="Enter only numbers" inputMode="numeric" pattern="[0-9]*" required /></MDBCol> */}
                                                        <MDBCol md="12"> <MDBInput label="Street *" type="text" name="Street" title="Special charcters are not allowed except Coma (,)" pattern="^[A-Za-z0-9, _]*[A-Za-z0-9,][A-Za-z0-9, _]*$" valueDefault={this.state.Street} onChange={this.handleChange} required /></MDBCol>
                                                        <MDBCol md="6"> <MDBInput label="Suite/Apt" type="text" name="SuiteApt" title="Special charcters are not allowed" pattern="^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$" valueDefault={this.state.SuiteApt} onChange={this.handleChange} /></MDBCol>
                                                        <MDBCol md="6"> <MDBInput label="City, State *" type="text" name="City" title="Format is City, State (Chicago, Illinois)" pattern="^[A-Z a-z]*,[ A-Za-z][A-Za-z]*$" valueDefault={this.state.City} onChange={this.handleChange} required /></MDBCol>
                                                        <MDBCol md="6"> <MDBInput label="ZIP CODE *" type="text" name="ZipCode" valueDefault={this.state.ZipCode} onChange={this.handleChange} title="Special charcters are not allowed" pattern="^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _]*$" required /></MDBCol>
                                                        <MDBCol md="6"><MDBInput label="Phone (ex. 5551234567) *" type="text" name="Phone" valueDefault={this.state.Phone} onChange={this.handleChange} min="0" title="Enter only numbers" inputMode="numeric" pattern="[0-9]*" required /></MDBCol>

                                                        {/* <MDBCol md="6"> <MDBInput label="New password" type="text" name="newPassword" onChange={this.handleChange} /></MDBCol>
                                                            <MDBCol md="6"><MDBInput label="Confirm password" type="text" name="confirmPassword" onChange={this.handleChange} /></MDBCol> */}
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol md="12">
                                                            <MDBBtn className="float-center m-0 mt-4 bbtn" type="submit" value="submit">SUBMIT</MDBBtn>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </form>
                                            </div>
                                        </div>
                                    </section>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </LoadingOverlay>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);