import React from 'react';
import agent from '../../agent';
import { connect } from 'react-redux';
import siteLogo from '../../shared/img/logo.png';
import { MDBInput, MDBBtn } from "mdbreact";
import 'bootstrap/dist/css/bootstrap.min.css';
import "mdbreact/dist/css/mdb.css";
import * as Sentry from '@sentry/browser';



import {
  UPDATE_FIELD_AUTH,
  LOGIN_PAGE_UNLOADED,
  REGISTER
} from '../../constants/actionTypes';

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
  onChangeUsername: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'username', value }),
  onChangePassword: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'password', value }),
    
    onChangeFirstName: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'firstName', value }),
    onChangeLastName: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'lastName', value }),

  onSubmit: (username, password,firstName,lastName) =>
    dispatch({ type: REGISTER, payload: agent.Auth.register(username, password,firstName,lastName) }),
  onUnload: () =>
    dispatch({ type: LOGIN_PAGE_UNLOADED })
});

class Login extends React.Component {
  constructor() {
    super();
    this.changeUsername = ev => this.props.onChangeUsername(ev.target.value);
    this.changePassword = ev => this.props.onChangePassword(ev.target.value);
     this.changeFirstname = ev => this.props.onChangeFirstName(ev.target.value);
     this.onChangeLastName = ev => this.props.onChangeLastName(ev.target.value);

    this.submitForm = (username, password,firstName,lastName) => ev => {
      ev.preventDefault();
      this.props.onSubmit(username, password,firstName,lastName);
      
    };
    try {
      throw new Error('Caught');
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        return;
      }
      Sentry.captureException(err);
  }
}

  componentWillUnmount() {
        this.props.onUnload();
     
  }
  

  render() {
   // throw new Error('caught');
    const username = this.props.username;
    const password = this.props.password;

    const firstName = this.props.firstName;
    const lastName = this.props.lastName;
    return (
      <div className="App">
        <section id="authentication-layout">
          <div className="flex-container">
            <div className="img-area">
              <img src={siteLogo} alt="logo" style={{ width: '50%' }} />
            </div>
            <div className="authentication-area">
              <form
                onSubmit={this.submitForm(
                  username,
                  password,
                  firstName,
                  lastName,
                )}
                style={{ width: '100%' }}
              >
                <MDBInput
                  label="User"
                  type="email"
                  icon="user"
                  value={username}
                  onChange={this.changeUsername}
                  color="black"
                />
                <MDBInput
                  label="Password"
                  type="password"
                  icon="lock"
                  value={password}
                  onChange={this.changePassword}
                  color="black"
                />

                <MDBInput
                  label="FirstName"
                  type="password"
                  icon="lock"
                  value={firstName}
                  onChange={this.changeFirstname}
                  color="black"
                />
                <MDBInput
                  label="LastName"
                  type="password"
                  icon="lock"
                  value={lastName}
                  onChange={this.onChangeLastName}
                  color="black"
                />
                <MDBBtn color="white" type="submit">
                  Sign Up
                </MDBBtn>
              </form>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
