import * as actionTypes from '../../constants/actionTypes';

const initialState = {
  logs: [],
  totalCount: 0,
  totalPages: 0,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_LOGS_START:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.LOAD_LOGS_SUCCESS:
      return {
        ...state,
        logs: action.data.logs,
        totalCount: action.data.totalCount,
        totalPages: action.data.totalPages,
        loading: false,
      };

    case actionTypes.LOAD_LOGS_FAIL:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
