import React from "react";
import { imageIcons } from "../../../../../constants/constants";
import CustomButton from "../../../../../components/Buttons/CustomButton";
import CustomCheckbox from "../../../../../components/Inputs/CustomCheckbox";
import styled from "@emotion/styled";
import { orderBy } from "lodash-es";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const NoImagesLabel = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 5px;
  width: 200px;
  font-size: 12px;
  color: black;
`;

const Title = styled.div`
  color: #1d2659;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  font-size: 8px;

  & > div {
    display: flex;
    flex-direction: column;
    padding-right: 10px;
  }

  & > div > div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    & > button {
      padding: 5px;
      justify-content: space-between;
      display: flex;
      background: white;
      color: #262268;
      border-radius: 0;
      font-weight: 600;
      width: 80px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    }
  }
`;

const ScaledThumbnail = styled.div`
  width: 70px;
  height: 70px;
  padding: 8px 0;
  margin-right: 20px;
  box-sizing: border-box;
  background: url("${(props) => props.src}");
  transform: scaleX(${(props) => (props.isHorizontalFlipped ? -1 : 1)});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  filter: FlipH;
  -ms-filter: "FlipH";
`;

const LinkCatalogImage = styled.div`
  align-self: flex-start;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
`;

function getBorderColor(image, activeImage) {
  if (image.quantity === 1) {
    return 'green';
  }

  if (activeImage === image.name) {
    return '#262268';
  }

  return '';
}

const Images = ({
  imagesFiltered,
  activeImage,
  tooltipData,
  removeImage,
  componentPlacement,
  onImageClick,
  setUploading,
  setClickedCatalogButton,
  changePriority,
  uploading,
  type,
  allowMultiple,
}) => {
  function isImageButtonDisabled(img) {
    if (!allowMultiple) {
      return img.quantity > 1;
    }

    return !imagesFiltered?.find((image) => image.name === img.name)?.url;
  }

  const images = orderBy(imagesFiltered || [], ['order'], ['asc']).filter(
    (x) => x.name !== 'catalog',
  );

  return (
    <>
      <Title>Select Tray Placement Image</Title>
      <Buttons>
        {images.map((img) =>
          activeImage === img.name ? (
            <ScaledThumbnail
              src={componentPlacement[0].image}
              key={img.name}
              isHorizontalFlipped={componentPlacement[0].isHorizontalFlipped}
            />
          ) : null,
        )}
        <div
          style={{
            display: 'flex',
            alignItems: imagesFiltered.length === 0 ? 'center' : undefined,
          }}
        >
          {imagesFiltered.length > 0 ? (
            imagesFiltered.map((img) => (
              <div
                key={img.name + img.id}
                title={
                  isImageButtonDisabled(img)
                    ? 'Please switch on Allow multiple flag to make image avalible'
                    : ''
                }
              >
                <CustomButton
                  label={img.name.toUpperCase()}
                  icon={
                    imageIcons[
                      Object.keys(imageIcons).find((key) =>
                        img.name.toLowerCase().includes(key),
                      )
                    ] ? (
                      <FontAwesomeIcon
                        icon={
                          imageIcons[
                            Object.keys(imageIcons).find((key) =>
                              img.name.toLowerCase().includes(key),
                            )
                          ]
                        }
                      />
                    ) : null
                  }
                  onClick={() => onImageClick(img.name)}
                  border={getBorderColor(img, activeImage)}
                  disabled={isImageButtonDisabled(img)}
                />
                <FontAwesomeIcon
                  icon={faEdit}
                  style={{
                    fontSize: 14,
                    cursor: 'pointer',
                    marginLeft: 5,
                    color: '#262268',
                  }}
                  onClick={() => setUploading(img.name)}
                />
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={{
                    fontSize: 14,
                    cursor: 'pointer',
                    marginLeft: 5,
                    color: 'orangered',
                  }}
                  onClick={() =>
                    removeImage({
                      componentId: tooltipData.id,
                      imageId: img.id,
                    })
                  }
                />
              </div>
            ))
          ) : (
            <NoImagesLabel>
              There are no {allowMultiple ? 'multiple' : ''} images assigned to
              this component
            </NoImagesLabel>
          )}
          <div>
            <CustomButton
              label="Add Image"
              icon={<FontAwesomeIcon icon={imageIcons.add} />}
              onClick={() => setUploading(' ')}
            />
          </div>
        </div>
      </Buttons>
      <br />
      <div
        style={{
          display: 'flex',
          alignSelf: 'flex-end',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          padding: 5,
        }}
      >
        {!uploading && (
          <LinkCatalogImage
            onClick={() => {
              setClickedCatalogButton(true);
              setUploading('catalog');
            }}
          >
            Set catalog image...
          </LinkCatalogImage>
        )}
        {!uploading &&
          type === 'singleAllowed' &&
          componentPlacement[0]?.image && (
            <CustomCheckbox
              label={'Priority Position'}
              onChange={changePriority}
              checked={componentPlacement[0].priority > 0}
              style={{
                alignSelf: 'flex-end',
                justifyContent: 'center',
                padding: '0px',
              }}
            />
          )}
      </div>
    </>
  );
};

export default Images;
