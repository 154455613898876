import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled";

export const DragItem = styled.div`
  display: flex;
  align-items: center;
  min-height: 30px;
  padding: 10px 5px;

  opacity: ${(props) => (props.inActive ? 0.5 : 1)};

  &:hover {
    background: #242e68;
    color: white;
  }

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    width: 170px;

    & > span:first-of-type {
      font-weight: 500;
    }

    & > span:last-of-type {
      margin-left: 20px;
      font-size: 12px;
      opacity: 0.8;
    }
  }

  & > div:last-of-type {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50%;
    align-items: center;
    font-size: 14px;

    svg {
      cursor: pointer;
      opacity: 0.5;
    }

    svg:hover {
      opacity: 1;
    }
  }
`;

const CavityDnD = ({
  cavity,
  isDragDisabled,
  onDragEnd,
  onIconClick,
  onCavityClick,
  inActiveCavityId,
  getItemStyle,
  icon,
  i,
}) => {
  return (
    <Draggable
      draggableId={cavity.id.toString()}
      index={i}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <DragItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
          onClick={
            isDragDisabled && !onCavityClick
              ? null
              : () => onCavityClick(cavity)
          }
          inActive={inActiveCavityId === cavity.id}
        >
          <div>
            <span>{cavity.name}</span>
            <span>{cavity.specialInstructions}</span>
          </div>
          <div>
            {!isDragDisabled && (
              <FontAwesomeIcon
                icon={faCaretDown}
                onClick={(e) => {
                  e.stopPropagation();
                  onDragEnd({
                    destination: { index: i + 1 },
                    source: { index: i },
                  });
                }}
              />
            )}
            <span>{cavity.assemblyOrder}</span>
            {!isDragDisabled && (
              <FontAwesomeIcon
                icon={faCaretUp}
                onClick={(e) => {
                  e.stopPropagation();
                  onDragEnd({
                    destination: { index: i - 1 },
                    source: { index: i },
                  });
                }}
              />
            )}
          </div>
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              onClick={() => onIconClick(cavity)}
              style={{ cursor: "pointer", fontSize: 12 }}
            />
          )}
        </DragItem>
      )}
    </Draggable>
  );
};

export default CavityDnD;
