import React from 'react';
import styled from '@emotion/styled';
import { NavLink, useHistory } from 'react-router-dom';
import CustomSelect from '../../../components/Selects/CustomSelect';
import { multiSelectStyles } from '../../../constants/constants';

const Container = styled.div`
  display: flex;
`;

const Tab = styled.div`
  display: flex;
  margin: 10px 20px;
  width: ${(props) => 100 / props.count}%;
  white-space: nowrap;

  & > * {
    color: black;
    padding-bottom: 10px;
    opacity: 0.3;
    font-weight: 500;
    width: 100%;
    text-align: center;
    opacity: 0.5;
    cursor: pointer;
  }
`;

const activeTab = {
  borderBottom: '4px solid #1D265B',
  opacity: 1,
};

const Tabs = ({
  tabs,
  setTab = null,
  active,
  type,
  selectedTab,
  setSelectedTab,
}) => {
  const history = useHistory();

  if (!tabs.length) {
    return null;
  }

  const handleSwitchTab = (tab) => {
    setSelectedTab(tab);
    history.push({
      pathname: tab.path,
      state: history.location.state,
    });
  };

  if (type === 'select') {
    return (
      <div style={{ fontSize: 12 }}>
        <CustomSelect
          options={tabs}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.path}
          customStyles={{
            ...multiSelectStyles,
            input: (provided) => ({ ...provided, height: 20, color: 'white' }),
          }}
          value={
            selectedTab ||
            tabs.find((tab) => tab.path === history.location.pathname)
          }
          onChange={handleSwitchTab}
          isSearchable={false}
          isClearable={false}
        />
      </div>
    );
  }

  return (
    <Container>
      {tabs.map((tab) => (
        <Tab count={tabs.length} key={tab.label || tab.value || tab}>
          {setTab === null ? (
            <NavLink
              to={{ pathname: tab.path, state: history.location.state }}
              activeStyle={activeTab}
            >
              {tab.label}
            </NavLink>
          ) : (
            <span
              onClick={() => setTab(tab)}
              style={active === tab ? activeTab : {}}
            >
              {tab}
            </span>
          )}
        </Tab>
      ))}
    </Container>
  );
};

Tabs.defaultProps = {
  tabs: [],
};

export default Tabs;
