import React from 'react';
import styled from 'styled-components';
import { generateTrayPDF } from '../NewKitMaker/Helpers/snapshotsToPDF';

const PdfButton = styled.div`
  display: flex;
  cursor: pointer;
  flex: 2;
  align-items: center;
  font-weight: 600;
`;

export const KitMakerPdfButton = ({ kit, listRef, handleLoadingStatus }) => {
  const generatePDFCallback = () => {
    window.scrollTo({ top: 0, left: 0 });
    generateTrayPDF({
      setKitPdfGenerationProcess: handleLoadingStatus,
      listWrapperRef: listRef,
      kitId: kit.name,
      isSales: false,
      fileName: '_ComponentsList.pdf',
      offsetHeight: 400,
      offsetWidth: 200,
      imageOffsetX: 100,
      imageOffsetY: 100,
    });
  };
  return <PdfButton onClick={generatePDFCallback}>PDF</PdfButton>;
};
