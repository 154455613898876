import React from 'react';
import styled from '@emotion/styled';
import CustomCheckbox from '../../../../components/Inputs/CustomCheckbox';
import Switcher from '../../../../components/Switchers/Switcher';
import { accessoryOptions } from '../../../NewKitCatalog/Filters/utils';

const Switchers = styled.div`
    font-weight: 500;
    & > div {
        justify-content: space-between;
        padding: 5px 0px;
    }
`;

const OffsetBlock = styled.div`
    margin-left: ${props => props.offset.left}px;
    margin-right: ${props => props.offset.right}px;
`;

const AccessoryOptionTypes = ({ kit, setKit }) => {
    return (
        <Switchers>
            <Switcher
                leftLabel={'Meds'}
                disabled={!kit.us}
                checked={!!kit.meds}
                onChange={() => setKit({ ...kit, meds: !kit.meds })}
                uncheckedColor='#bbbbbb'
                checkedColor='#1D2659'
            />
            <Switcher
                leftLabel={'Prefilled Saline Syringe'}
                disabled={!!kit.anesthesia || !kit.us}
                checked={!!kit.prefilledSalineSyringe}
                onChange={() => setKit({ ...kit, prefilledSalineSyringe: !kit.prefilledSalineSyringe })}
                uncheckedColor='#bbbbbb'
                checkedColor='#1D2659'
            />
            {accessoryOptions.map(o =>
                <Switcher
                    leftLabel={o.label}
                    disabled={!kit.vascular}
                    checked={!!kit[o.value]}
                    onChange={(e) => setKit({ ...kit, [e.target.name]: !kit[e.target.name] })}
                    uncheckedColor='#bbbbbb'
                    checkedColor='#1D2659'
                    key={o.value}
                    name={o.value}
                />
            )}
            <Switcher
                leftLabel={'Drape'}
                checked={!!kit.drape}
                onChange={() => setKit({ ...kit, drape: !kit.drape, maxBarrier: false })}
                uncheckedColor='#bbbbbb'
                checkedColor='#1D2659'
            />
            {(kit.drape && kit.vascular && !kit.interventional) && <OffsetBlock offset={{ left: 20 }}>
                <CustomCheckbox label='Max Barrier' onChange={() => setKit({ ...kit, maxBarrier: !kit.maxBarrier })} checked={kit.maxBarrier} />
            </OffsetBlock>}
        </Switchers>
    );
};

export default AccessoryOptionTypes;
