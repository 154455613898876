import styled from "@emotion/styled";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import CustomButton from "../../../components/Buttons/CustomButton";
import CustomInput from "../../../components/Inputs/CustomInput";

const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 5px 0px;
`;

const AutoFills = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  max-height: 150px;
  position: absolute;
  z-index: 1;
  flex-direction: column;
  background: #efefef;
  overflow-y: auto;
  top: 45px;

  & > span {
    padding: 5px;
    cursor: pointer;
  }

  & > span:hover {
    background: #1d265b;
    color: white;
  }
`;

const Inputs = styled.div`
  position: relative;
  display: flex;
  align-self: center;
  width: 60%;
  font-size: 10;
  & span {
    font-size: 12px;
  }

  & input::placeholder {
    font-size: 12px;
  }
`;

const UploadPageBreakImage = ({
  image,
  name,
  setName,
  handleUpload,
  setImage,
  imageNames,
  editableDivider,
  setEditableDivider,
}) => {
  const defaultLevels = Array(5).fill("");
  const [levels, setLevels] = useState(defaultLevels);
  const [inFocus, setInFocus] = useState(null);
  const editableName = editableDivider?.name.split("_").pop();

  const autofills = useMemo(
    () =>
      imageNames
        .filter((img) => !levels.includes(img))
        .map((img) => img.split("_"))
        .flat(),
    [imageNames, levels]
  );

  useEffect(() => {
    if (editableDivider) {
      const splitName = editableDivider?.name
        .split("_")
        .filter((l) => l !== editableName);
      setLevels((prev) => prev.map((l, i) => splitName[i] || l));
    }
  }, [editableDivider, editableName]);

  const [autofillToState, setAutofillToState] = useState(autofills);

  const levelChange = (e, index) => {
    const val = e.target.value;
    setAutofillToState(
      autofills.filter((af) => af.toLowerCase().includes(val.toLowerCase()))
    );
    setLevels(levels.map((fn, i) => (i === index ? val : fn)));
  };

  function buildName() {
    return levels.filter((f) => f).join("_");
  }

  const onSubmit = () => {
    setName(`${buildName()}_${name || editableName}`);
    handleUpload(`${buildName()}_${name || editableName}`);
  };

  const cancel = () => {
    setImage(null);
    setName("");
    setLevels(defaultLevels);
    setEditableDivider(null);
  };

  if (!image && !editableDivider) {
    return null;
  }

  return (
    <>
      <>
        {levels.map((fn, i) => (
          <Inputs key={i}>
            <CustomInput
              value={fn}
              onChange={(e) => levelChange(e, i, true)}
              label={"Level " + (i + 1)}
              isRequired={i === 0}
              invalid={!fn && i === 0}
              errorText={!!(!fn && i === 0) ? "Level 1 is required" : ""}
              onFocus={() => setInFocus(i)}
              onBlur={() => {
                setInFocus(null);
                setAutofillToState(autofills);
              }}
              style={{ width: "100%" }}
            />
            {inFocus === i && (
              <AutoFills>
                {autofillToState.map((af, index) => (
                  <span
                    key={af + index}
                    onMouseDown={() =>
                      levelChange({ target: { value: af } }, i)
                    }
                  >
                    {af}
                  </span>
                ))}
              </AutoFills>
            )}
          </Inputs>
        ))}
        <Inputs>
          <CustomInput
            style={{ width: "100%" }}
            value={name || editableName}
            onChange={(e) => setName(e.target.value)}
            label={"Image name"}
            isRequired
            invalid={!name && !editableName}
            errorText={!name && !editableName ? "Name is required" : ""}
          />
        </Inputs>
      </>

      <Buttons>
        <CustomButton label="Cancel" onClick={cancel} background="#959595" />
        <CustomButton
          disabled={
            (!image && !editableDivider) ||
            (!name?.length && !editableName) ||
            !levels[0]
          }
          label={"Save"}
          onClick={onSubmit}
        />
      </Buttons>
    </>
  );
};

export default UploadPageBreakImage;
