import * as actionTypes from '../../constants/actionTypes';
import _ from 'lodash';

const initialState = {
  kits: null,
  cart: [],
  kitsCategories: [],
  customKitsSaved: [],
  kitsCategoriesLoading: false,
  customKitsSavedLoading: false,
  kitsLoading: false,
  cartLoading: false,
  kitDesignList: [],
  kitDesignCount: null,
  kitDesignPages: null,
  kitDesignLoading: false,
  kitDetails: null,
  isNameUnique: true,
  orphanComponent: null,
  autoSavedKitFormData: null,
  pageBreakImages: null,
  pageBreakImagesLoading: false,
  currentComponent: null,
  snapshotsOrderedList: [],
  componentsRange: null,
  recentAddedComponentId: null,
  recentUpdatedPage: null,
  swappedComponentProps: null,
  submittedKitName: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_KITS_START:
      return {
        ...state,
        kitsLoading: true,
      };

    case actionTypes.ADD_PAGE_BREAK_IMAGE_START:
    case actionTypes.DELETE_PAGE_BREAK_IMAGE_START:
    case actionTypes.LIST_PAGE_BREAK_IMAGES_START:
      return {
        ...state,
        pageBreakImagesLoading: true,
      };

    case actionTypes.DELETE_PAGE_BREAK_IMAGE_FAIL:
    case actionTypes.ADD_PAGE_BREAK_IMAGE_FAIL:
    case actionTypes.LIST_PAGE_BREAK_IMAGES_FAIL:
    case actionTypes.UPDATE_PAGE_BREAK_IMAGE_NAME_FAIL:
      return {
        ...state,
        pageBreakImagesLoading: false,
      };

    case actionTypes.LIST_PAGE_BREAK_IMAGES_SUCCESS:
      return {
        ...state,
        pageBreakImages: action.data,
        pageBreakImagesLoading: false,
      };

    case actionTypes.ADD_PAGE_BREAK_IMAGE_SUCCESS:
      return {
        ...state,
        pageBreakImages: [...state.pageBreakImages, action.data],
        pageBreakImagesLoading: false,
      };

    case actionTypes.UPDATE_PAGE_BREAK_IMAGE_NAME_SUCCESS:
      return {
        ...state,
        pageBreakImages: state.pageBreakImages.map((pb) =>
          pb.id === action.data.id ? action.data : pb,
        ),
        pageBreakImagesLoading: false,
      };

    case actionTypes.DELETE_PAGE_BREAK_IMAGE_SUCCESS:
      return {
        ...state,
        pageBreakImages: state.pageBreakImages.filter(
          (x) => x.id !== action.data,
        ),
        pageBreakImagesLoading: false,
      };

    case actionTypes.GET_KITS_SUCCESS:
      return {
        ...state,
        kits: action.kits,
        kitsLoading: false,
      };

    case actionTypes.GET_SAVED_CUSTOM_KITS_START:
      return {
        ...state,
        customKitsSavedLoading: true,
      };

    case actionTypes.GET_SAVED_CUSTOM_KITS_SUCCESS:
      return {
        ...state,
        customKitsSaved: action.customKitsSaved,
        customKitsSavedLoading: false,
      };

    case actionTypes.GET_CART_CUSTOM_KITS_START:
      return {
        ...state,
        cartLoading: true,
      };

    case actionTypes.GET_CART_CUSTOM_KITS_SUCCESS:
      return {
        ...state,
        cart: action.cart,
        cartLoading: false,
      };

    case actionTypes.GET_KITS_CATEGORIES_START:
      return {
        ...state,
        kitsCategoriesLoading: true,
      };

    case actionTypes.GET_KITS_CATEGORIES_SUCCESS:
      return {
        ...state,
        kitsCategories: action.kitsCategories,
        kitsCategoriesLoading: false,
      };

    case actionTypes.REMOVE_ALL_COMPONENTS_START:
    case actionTypes.ADD_COMPONENT_TO_KIT_AUTOASSIGN_START:
    case actionTypes.REMOVE_COMPONENT_FROM_CAVITY_START:
    case actionTypes.GET_KIT_LIST_FROM_KIT_DESIGN_START:
    case actionTypes.CREATE_KIT_LIST_FROM_KIT_DESIGN_START:
    case actionTypes.GET_KIT_INFO_FROM_KIT_DESIGN_START:
    case actionTypes.DELETE_KIT_FROM_KIT_MY_LIST_START:
    case actionTypes.UPDATE_KIT_FROM_MY_KIT_LIST_START:
    case actionTypes.SEND_KIT_TO_SUBMIT_START:
    case actionTypes.PUSH_BACK_KIT_TO_SALES_START:
    case actionTypes.SUBMIT_KIT_TO_MANUFACTURING_START:
    case actionTypes.BUILD_KIT_LIST_START:
    case actionTypes.SWAP_KIT_TRAY_START:
    case actionTypes.DOWLOAD_ASSEMBLY_PDF_START:
    case actionTypes.ASSIGN_TRAY_TO_BASE_KIT_START:
    case actionTypes.ADD_COMPONENT_TO_CAVITY_OVERRIDE_START:
    case actionTypes.MOVE_COMPONENT_TO_CAVITY_OVERRIDE_START:
    case actionTypes.RESET_OVERRIDE_START:
      return {
        ...state,
        kitDesignLoading: true,
      };

    case actionTypes.MOVE_COMPONENT_TO_CAVITY_OVERRIDE_SUCCESS:
    case actionTypes.MOVE_COMPONENT_TO_CAVITY_OVERRIDE_FAIL:
    case actionTypes.ADD_COMPONENT_TO_CAVITY_OVERRIDE_FAIL:
    case actionTypes.ADD_COMPONENT_TO_CAVITY_OVERRIDE_SUCCESS:
    case actionTypes.RESET_OVERRIDE_FAIL:
    case actionTypes.RESET_OVERRIDE_SUCCESS:
      return {
        ...state,
        kitDesignLoading: false,
      };

    case actionTypes.GET_KIT_INFO_FROM_KIT_DESIGN_FAIL:
      return {
        ...state,
        kitDesignLoading: false,
        kitDetails: null,
      };

    case actionTypes.GET_KIT_LIST_FROM_KIT_DESIGN_SUCCESS:
      return {
        ...state,
        kitDesignLoading: false,
        kitDesignList: action.data.kits || [],
        kitDesignCount: action.data.totalCount,
        kitDesignPages: action.data.totalPages,
      };

    case actionTypes.UPDATE_ASSEMBLY_ORDER_SUCCESS:
      return {
        ...state,
        kitDetails: {
          ...state.kitDetails,
          version: action.result.Version,
          pages: JSON.parse(action.result.Pages || '[]'),
          objects: JSON.parse(action.result.Objects || '[]'),
        },
      };

    case actionTypes.REMOVE_COMPONENT_FROM_CAVITY_SUCCESS:
      let array = state.kitDetails.componentAssignments;
      const index = array.findIndex(
        (x) =>
          x.cavityId === action.data.CavityId ||
          x.component.id === action.data.ComponentId,
      );
      if (index > -1) {
        array.splice(index, 1);
      }
      return {
        ...state,
        kitDetails: { ...state.kitDetails, componentAssignments: array },
      };

    case actionTypes.REMOVE_ALL_COMPONENTS_SUCCESS:
      return {
        ...state,
        kitDetails: {
          ...state.kitDetails,
          componentAssignments: state.kitDetails.componentAssignments.filter(
            (x) => x.component.id !== action.data.ComponentId,
          ),
        },
      };

    case actionTypes.CREATE_KIT_LIST_FROM_KIT_DESIGN_SUCCESS:
    case actionTypes.GET_KIT_INFO_FROM_KIT_DESIGN_SUCCESS:
    case actionTypes.DELETE_KIT_FROM_KIT_MY_LIST_SUCCESS:
    case actionTypes.SWAP_KIT_TRAY_SUCCESS:
      return {
        ...state,
        kitDesignLoading: false,
        kitDetails: {
          ...action.data,
          componentAssignments: _.orderBy(
            action.data.componentAssignments,
            ['assemblyOrder'],
            ['asc'],
          ),
          pages: JSON.parse(action.data.pages || '[]'),
          objects: JSON.parse(action.data.objects || '[]'),
          layoutData: JSON.parse(
            action.data.layoutData?.replaceAll("'", '"') || '[]',
          ),
          tray: {
            ...action.data.tray,
            cavities: _.orderBy(
              action.data.tray.cavities.map((x) => ({
                ...x,
                points: JSON.parse(x.vertices || '[]'),
              })),
              ['assemblyOrder'],
              ['desc'],
            ),
          },
        },
      };

    case actionTypes.GET_KIT_LIST_FROM_KIT_DESIGN_FAIL:
    case actionTypes.CREATE_KIT_LIST_FROM_KIT_DESIGN_FAIL:
    case actionTypes.DELETE_KIT_FROM_KIT_MY_LIST_FAIL:
    case actionTypes.UPDATE_KIT_FROM_MY_KIT_LIST_FAIL:
    case actionTypes.SEND_KIT_TO_SUBMIT_FAIL:
    case actionTypes.PUSH_BACK_KIT_TO_SALES_FAIL:
    case actionTypes.SUBMIT_KIT_TO_MANUFACTURING_FAIL:
    case actionTypes.BUILD_KIT_LIST_FAIL:
    case actionTypes.SWAP_KIT_TRAY_FAIL:
    case actionTypes.DOWLOAD_ASSEMBLY_PDF_FAIL:
    case actionTypes.DOWLOAD_ASSEMBLY_PDF_SUCCESS:
    case actionTypes.ASSIGN_TRAY_TO_BASE_KIT_FAIL:
    case actionTypes.ASSIGN_TRAY_TO_BASE_KIT_SUCCESS:
      return {
        ...state,
        kitDesignLoading: false,
      };

    case actionTypes.NOTE_ADD_SUCCESS:
      return {
        ...state,
        kitDetails: {
          ...state.kitDetails,
          notes: [...state.kitDetails.notes, action.data],
        },
      };

    case actionTypes.CLEAR_KIT_LIST_FROM_KIT_DESIGN_DATA:
      return {
        ...state,
        kitDetails: null,
        kitDesignList: [],
      };

    case actionTypes.UPDATE_LAYOUT_DATA_SUCCESS:
      return {
        ...state,
        kitDetails: {
          ...state.kitDetails,
          layoutData: JSON.parse(action.data.replaceAll("'", '"') || '[]'),
        },
      };

    case actionTypes.VALIDATE_KIT_NAME:
      return {
        ...state,
        isNameUnique: action.data,
      };

    case actionTypes.UPDATE_KIT_FROM_MY_KIT_LIST_SUCCESS:
    case actionTypes.SEND_KIT_TO_SUBMIT_SUCCESS:
    case actionTypes.PUSH_BACK_KIT_TO_SALES_SUCCESS:
    case actionTypes.SUBMIT_KIT_TO_MANUFACTURING_SUCCESS:
    case actionTypes.BUILD_KIT_LIST_SUCCESS:
      return {
        ...state,
        kitDesignLoading: false,
      };

    case actionTypes.CHANGE_ORPHAN_COMPONENT:
      return {
        ...state,
        orphanComponent: action.data,
      };

    case actionTypes.AUTO_SAVE_KIT_FORM_DATA:
      return {
        ...state,
        autoSavedKitFormData: action.data,
      };

    case actionTypes.SELECT_CURRENT_COMPONENT:
      return {
        ...state,
        currentComponent: action.component,
      };

    case actionTypes.SAVE_COMPONENTS_RANGE:
      return {
        ...state,
        componentsRange: action.range,
      };

    case actionTypes.ADD_COMPONENT_TO_KIT_AUTOASSIGN_SUCCESS:
      return {
        ...state,
        recentAddedComponentId: action.componentId,
      };

    case actionTypes.SAVE_UPDATED_PAGE:
      return {
        ...state,
        recentUpdatedPage: action.page,
      };

    case actionTypes.SAVE_SWAPPED_COMPONENT_INFORMATION:
      return {
        ...state,
        swappedComponentProps: action.component,
      };

    default:
      return {
        ...state,
      };
  }
};
