import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  height: 30px;
  color: #1d265b;

  & > div:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  & > div:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const Switch = styled.div`
  background: white;
  width: 50%;
  text-align: center;
  line-height: 30px;
  opacity: ${(props) => (props.activeState ? 1 : 0.5)};
  cursor: pointer;
`;

export default function PagesComponentsSwitcher() {
  const history = useHistory();
  const pathName = history.location.pathname;
  const [active, setActive] = useState(pathName.split('/').pop());
  const path = pathName.split('/').slice(0, -1).join('/');

  return (
    <Container>
      <Switch
        activeState={active === 'snapshots'}
        onClick={() => {
          setActive('snapshots');
          history.replace(path + '/snapshots');
        }}
      >
        Pages
      </Switch>
      <Switch
        activeState={active === 'list-components'}
        onClick={() => {
          setActive('list-components');
          history.replace(path + '/list-components');
        }}
      >
        Components
      </Switch>
    </Container>
  );
}
