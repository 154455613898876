import React, { Component } from 'react';
import {
  MDBAlert,
  MDBInput,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdbreact';
import { connect } from 'react-redux';
import { LOGOUT } from '../../constants/actionTypes';
import agent from '../../agent';
import LoadingOverlay from 'react-loading-overlay';
import Loader from '../../components/loaders/Loader';

let KitDetailArray = {
  kits: [
    {
      description: 'No results found',
    },
  ],
};
const mapStateToProps = (state) => ({
  ...state.auth,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onClickLogout: () => dispatch({ type: LOGOUT }),
});
let i = 0;
let tempAccountArray = [1];

class ComponentCatalogRequestSample extends Component {
  currentNumber = null;
  currentSuiteApt = null;
  currentCity = null;
  currentZipCode = null;
  currentPhone = null;
  currentStreet = null;

  constructor(props) {
    super(props);
    const token = window.localStorage.getItem('jwt');
    if (token) {
    } else {
      this.props.onClickLogout();
    }

    this.state = {
      Number: null,
      Street: null,
      SuiteApt: null,
      City: null,
      ZIP: null,
      Phone: null,
      activeItem: '1',
      kitDetailData: null,
      regionalManager: null,
      contactTitle: null,
      name: null,
      contactName: null,
      salesRep: null,
      accountNumber: null,
      facility: null,
      isNewBusiness: false,
      competitor: null,
      currentPrice: null,
      requestedPrice: null,
      dealerPrice: null,
      estUsage: null,
      purchaseType: null,
      jitName: null,
      accounts: [],
      mainAccountNumber: null,
      kitName: null,
      submitformContinue: false,
      kitSampleQty: 1,
      specialNotes: '',
    };
    this.submitForm = this.submitForm.bind(this);
    this.kitMakerComponentsPage = this.kitMakerComponentsPage.bind(this);
    this.accountValue = this.accountValue.bind(this);
    this.onClickCSV = this.onClickCSV.bind(this);
  }

  componentWillMount() {
    this.currentLoggedInUserDetail = JSON.parse(
      window.localStorage.getItem('loggeduser'),
    );
    this.setState({ Number: this.currentLoggedInUserDetail.number });
    this.setState({ Street: this.currentLoggedInUserDetail.street });
    this.setState({ SuiteApt: this.currentLoggedInUserDetail.suiteApt });
    this.setState({ City: this.currentLoggedInUserDetail.city });
    this.setState({ ZIP: this.currentLoggedInUserDetail.zipCode });
    this.setState({ Phone: this.currentLoggedInUserDetail.phone });
    let Id = window.location.href.substring(
      window.location.href.lastIndexOf('/') + 1,
    );
    this.setState({ kitId: Id });
    agent.KITCATLOG.kitbyId(Id).then((res) => {
      if (res === null) {
        this.setState({ kitDetailData: KitDetailArray });
      } else {
        this.setState({ kitDetailData: res });
        this.setState({ kitName: res.number });
      }
    });
  }

  submitForm(ev) {
    ev.preventDefault();
    // tempAccountArray.forEach((element, i) => {
    //     this.state.accounts.push(document.getElementById("account" + i).value);
    // });
    let data = {
      //"number": this.state.Number,
      street: this.state.Street,
      suiteApt: this.state.SuiteApt,
      city: this.state.City,
      zipCode: this.state.ZIP,
      phone: this.state.Phone,
      kitSampleQty: this.state.kitSampleQty,
      specialNotes: this.state.specialNotes,
      // "regionalManager": this.state.regionalManager,
      // "contactTitle": this.state.contactTitle,
      // "contactName": this.state.contactName,
      // "salesRep": this.state.salesRep,
      // "accountNumber": this.state.mainAccountNumber,
      // "facility": this.state.facility,
      // "estUsage": this.state.estUsage,
      // "purchaseType": this.state.purchaseType,
      // "jitName": this.state.jitName,
      // "accounts":
      //     this.state.accounts
    };
    this.setState({ submitformContinue: true });
    agent.KITCATLOG.requestSample(data, this.state.kitId).then((res) => {
      this.setState({ submitformContinue: true });
      window.sessionStorage.setItem('kitCategory', ' ');
      let path = '/Confirmation';
      let name = this.state.kitName;
      this.props.history.push(path + '/' + name);
    });
  }

  kitMakerComponentsPage = () => {
    let path = '/kits';
    this.props.history.push(path);
  };

  handleChange = (event) => {
    switch (event.target.name) {
      case 'Number':
        this.setState({ Number: event.target.value });
        break;
      case 'Street':
        this.setState({ Street: event.target.value });
        break;
      case 'SuiteApt':
        this.setState({ SuiteApt: event.target.value });
        break;
      case 'City':
        this.setState({ City: event.target.value });
        break;
      case 'ZIP':
        this.setState({ ZIP: event.target.value });
        break;
      case 'Phone':
        this.setState({ Phone: event.target.value });
        break;
      case 'regionalManager':
        this.setState({ regionalManager: event.target.value });
        break;
      case 'contactTitle':
        this.setState({ contactTitle: event.target.value });
        break;
      case 'contactName':
        this.setState({ contactName: event.target.value });
        break;
      case 'salesRep':
        this.setState({ salesRep: event.target.value });
        break;
      case 'mainAccountNumber':
        this.setState({ mainAccountNumber: event.target.value });
        break;
      case 'accountNumber':
        this.setState({ accountNumber: event.target.value });
        break;
      case 'facility':
        this.setState({ facility: event.target.value });
        break;
      case 'isNewBusiness':
        this.setState({ isNewBusiness: event.target.value });
        break;
      case 'competitor':
        this.setState({ competitor: event.target.value });
        break;
      case 'currentPrice':
        this.setState({ currentPrice: event.target.value });
        break;
      case 'requestedPrice':
        this.setState({ requestedPrice: event.target.value });
        break;
      case 'estUsage':
        this.setState({ estUsage: event.target.value });
        break;
      case 'purchaseType':
        this.setState({ purchaseType: event.target.value });
        break;
      case 'jitName':
        this.setState({ jitName: event.target.value });
        break;
      case 'productFamilyId':
        this.setState({ productFamilyId: event.target.value });

        break;
      default:
        break;
    }
  };
  accountValue = () => {
    tempAccountArray.push(document.getElementById('account' + i).value);
    if (document.getElementById('account' + i).value !== '') {
      i = i + 1;
      let Id = 'account' + i;
      var node = document.createElement('input');
      var nodeIcon = document.createElement('span');
      nodeIcon.setAttribute('className', 'addicon');
      nodeIcon.setAttribute('value', '+');
      var textnode = document.createTextNode('+');
      node.setAttribute('type', 'text');
      node.setAttribute('value', '');
      node.setAttribute('Id', Id);
      node.setAttribute('name', 'Test Name');
      node.setAttribute('class', 'offset-md-6');
      node.setAttribute('pattern', '^[A-Za-z0-9_]+$');
      node.appendChild(textnode);
      node.appendChild(nodeIcon);
      document.getElementById('accountDiv').appendChild(node);
    }
  };
  addTextBox = () => {
    var element = document.createElement('input');
    element.setAttribute('type', 'text');
    element.setAttribute('value', '');
    element.setAttribute('name', 'Test Name');
  };

  onClickCSV = (kitId, SKU) => {
    agent.KITCATLOG.getCSVData(kitId).then((res) => {
      var newStrrv = res.replace(/,/g, '', '');
      var newStr = newStrrv.replace(/\|/g, ',');
      var blob = new Blob([newStr]);
      if (window.navigator.msSaveOrOpenBlob)
        window.navigator.msSaveBlob(blob, SKU + '-components.csv');
      else {
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' });
        a.download = SKU + '-components.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    });
  };
  pdfonClick = (pdfUrl, byte) => {
    window.open(pdfUrl, 'download');
    window.open(pdfUrl, '_blank');
  };

  pdfGenerate(kitId, SKU) {
    this.setState({ isPDFLoaded: true });
    agent.KITCATLOG.pdfGenerate(kitId)
      .then((res) => {
        if (res !== null) {
          this.setState({
            responseStatusCode: res.statusCode,
            isPDFLoaded: false,
          });
          var newBlob = new Blob([res], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.href = url;
          //let name = SKU;
          const download = SKU + '.pdf';
          link.setAttribute('download', download);
          document.body.appendChild(link);
          link.click();
        } else {
          this.setState({ showPdfPop: !this.state.showPdfPop });
          setTimeout(
            function () {
              this.setState({ showPdfPop: !this.state.showPdfPop });
            }.bind(this),
            5000,
          );
          window.scroll(0, 0);
        }
      })
      .catch((error) => {
        setTimeout(
          function () {
            this.setState({
              showPdfPop: !this.state.showPdfPop,
              isPDFLoaded: false,
            });
          }.bind(this),
          5000,
        );
        window.scroll(0, 0);
      });
  }

  render() {
    if (this.state.kitDetailData === null) {
      return <Loader />;
    } else {
      let detaildata = this.state.kitDetailData;
      let dataComponents = '';

      dataComponents = detaildata.components.map((component) => {
        return (
          <tr>
            <td align="center">{component.quantity}</td>
            <td align="center">{component.number}</td>
            <td className="alignment"> {component.description}</td>
          </tr>
        );
      });
      //var tempDate = new Date();
      // var date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate();
      const alertPopUp = (
        <MDBAlert color="">
          <strong>
            <span className="bottom-info blueColor">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
            </span>{' '}
            All samples must be non-sterile and shipped to an employee of
            Teleflex, LLC. We are not able to provide sterile samples of any
            kind and will not ship directly to the customer.
          </strong>
        </MDBAlert>
      );
      return (
        <div className="main-layout">
          <div className="content-area">
            <LoadingOverlay
              active={this.state.submitformContinue}
              className=""
              spinner
              text="Please wait..."
            >
              <MDBContainer fluid className="kitmaker">
                <div className="text-capitalize mt-5 mt-sm-5 mt-lg-0 mt-md-0 head-section mb20">
                  <h3>
                    <span className="headfont">KITCATALOG</span>
                    {this.state.kitName}
                  </h3>
                </div>
                {alertPopUp}
                <MDBRow>
                  <MDBCol md="12">
                    <MDBCard className="mt-0">
                      <MDBCardBody className="p30 kitmakerform">
                        <div className="form-area mb-3">
                          <form onSubmit={this.submitForm}>
                            <MDBRow>
                              <MDBCol
                                md="10"
                                className="text-center offset-md-1"
                              >
                                <h4>
                                  Please confirm sample shipping address
                                  (Teleflex's Sales Representative)
                                </h4>
                                <MDBRow>
                                  <MDBCol md="12">
                                    {' '}
                                    <MDBInput
                                      label="Street *"
                                      type="text"
                                      name="Street"
                                      title="Special charcters are not allowed except Coma (,)"
                                      pattern="^[A-Za-z0-9, _]*[A-Za-z0-9,][A-Za-z0-9, _]*$"
                                      onChange={this.handleChange}
                                      valueDefault={this.state.Street}
                                      required
                                    />
                                  </MDBCol>
                                  <MDBCol md="6">
                                    {' '}
                                    <MDBInput
                                      label="Suite/Apt"
                                      type="text"
                                      name="SuiteApt"
                                      title="Special charcters are not allowed"
                                      pattern="^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$"
                                      onChange={this.handleChange}
                                      valueDefault={this.state.SuiteApt}
                                    />
                                  </MDBCol>
                                  <MDBCol md="6">
                                    {' '}
                                    <MDBInput
                                      label="City, State *"
                                      type="text"
                                      name="City"
                                      title="Format is City, State (Chicago, Illinois)"
                                      pattern="^[A-Z a-z]*,[ A-Za-z][A-Za-z]*$"
                                      onChange={this.handleChange}
                                      valueDefault={this.state.City}
                                      required
                                    />
                                  </MDBCol>
                                  <MDBCol md="6">
                                    {' '}
                                    <MDBInput
                                      label="ZIP CODE *"
                                      type="text"
                                      name="ZIP"
                                      title="Special charcters are not allowed"
                                      pattern="^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _]*$"
                                      onChange={this.handleChange}
                                      valueDefault={this.state.ZIP}
                                      required
                                    />
                                  </MDBCol>
                                  <MDBCol md="6">
                                    <MDBInput
                                      label="Phone (ex. 5551234567) *"
                                      type="text"
                                      name="Phone"
                                      min="0"
                                      title="Enter only numbers"
                                      inputMode="numeric"
                                      pattern="[0-9]*"
                                      onChange={this.handleChange}
                                      valueDefault={this.state.Phone}
                                      required
                                    />
                                  </MDBCol>
                                </MDBRow>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="10"
                                className="text-center offset-md-1"
                              >
                                {/* <MDBRow>
                                                                    <MDBCol md="12" className="text-center mt-4">
                                                                        <h3>Customer Component Confirmation</h3>
                                                                        <div className="formbox">
                                                                            <MDBRow>
                                                                                <MDBCol md="6">
                                                                                    <label>Date :</label>
                                                                                    <input type="text" value={date} />
                                                                                </MDBCol>
                                                                                <MDBCol md="6">
                                                                                    <label >Sales Respresentative:</label>
                                                                                    <input type="text" name="salesRep" id="salesrep" onChange={this.handleChange} />
                                                                                </MDBCol>
                                                                                <MDBCol md="6">
                                                                                    <label >Purchasing Contact * :</label>
                                                                                    <input type="text" pattern="^[A-Za-z -]+$" title="Enter only letters" id="purcontact" name="contactTitle" onChange={this.handleChange} required />
                                                                                </MDBCol>
                                                                                <MDBCol md="6">
                                                                                    <label>Regional Manager * :</label>
                                                                                    <input type="text" title="Enter only letters" id="regionalmanager" pattern="^[A-Za-z -]+$" name="regionalManager" onChange={this.handleChange} required />
                                                                                </MDBCol>
                                                                                <MDBCol md="6">
                                                                                    <label>Purchasing Contact title * :</label>
                                                                                    <input type="text" title="Enter only letters" id="purcontacttitle" pattern="^[A-Za-z -]+$" name="contactName" onChange={this.handleChange} required />
                                                                                </MDBCol>
                                                                                <MDBCol md="6" >
                                                                                    <label>Account Number *:</label>
                                                                                    <input type="text" name="mainAccountNumber" id="accountnumber" title="Enter only Alphanumeric Values" pattern="^[A-Za-z0-9_]+$" onChange={this.handleChange} required />
                                                                                </MDBCol>
                                                                                <MDBCol md="6" >
                                                                                    <div id="accountDiv">
                                                                                        <label>Subsidiary Number :</label>
                                                                                        <input type="text" id="account0" name="accountNumber" title="Enter only Alphanumeric Values" pattern="^[A-Za-z0-9_]+$" value={this.state.accounts[0]} className=" mt-3" />
                                                                                        <span className="addicon formaddicon" value="1" onClick={() => this.accountValue()}>+</span>
                                                                                    </div>
                                                                                </MDBCol>
                                                                                <MDBCol md="6" >
                                                                                    <label>Facility Name and Address *:</label>
                                                                                    <textarea name="facility" id="address" onChange={this.handleChange} required />
                                                                                </MDBCol>
                                                                            </MDBRow>
                                                                        </div>
                                                                        <MDBRow>
                                                                            <MDBCol md="4" className="">
                                                                                <div className="formbox mt-4 float-left text-left w-100">
                                                                                    <p>Product Type:</p>
                                                                                    <input type="text" color="black" className="w-100 float-left" name="purchaseType" onChange={this.handleChange} />
                                                                                </div>
                                                                            </MDBCol>
                                                                            <MDBCol md="4" className="">
                                                                                <div className="formbox mt-4 float-left text-left w-100">
                                                                                    <p>Distributor Name:</p>
                                                                                    <input type="text" color="black" className="w-100 float-left" name="jitName" onChange={this.handleChange} />
                                                                                </div>
                                                                            </MDBCol>

                                                                            <MDBCol md="4" className="">
                                                                                <div className="formbox mt-4 float-left text-left w-100">
                                                                                    <p>Estimated annual usage in Eaches *:</p>
                                                                                    <input type="text" id="estimatedannual" color="black" className="w-100 float-left" min="0" title="Enter only numbers" inputMode="numeric" pattern="[0-9]*" name="estUsage" onChange={this.handleChange} required />
                                                                                </div>
                                                                            </MDBCol>
                                                                        </MDBRow>
                                                                    </MDBCol>
                                                                </MDBRow> */}
                                <MDBTable
                                  striped
                                  small
                                  className="mt-5"
                                  cellSpacing="10"
                                >
                                  <MDBTableHead>
                                    <tr>
                                      <th align="center">
                                        <b>QTY</b>
                                      </th>
                                      <th align="center">
                                        <b>SKU #</b>
                                      </th>
                                      <th className="alignment">
                                        <b>Description</b>
                                      </th>
                                    </tr>
                                  </MDBTableHead>
                                  <MDBTableBody>{dataComponents}</MDBTableBody>
                                </MDBTable>
                                <MDBCol>
                                  <MDBRow>
                                    Kit sample QTY: {this.state.kitSampleQty}
                                    <div>
                                      <div className="tbl-addon">
                                        <a href="#no">
                                          <i
                                            className="fa fa-plus"
                                            onClick={() =>
                                              this.setState((prev) => {
                                                if (prev.kitSampleQty === 5)
                                                  return;
                                                return {
                                                  kitSampleQty:
                                                    prev.kitSampleQty + 1,
                                                };
                                              })
                                            }
                                            style={{
                                              margin: '0 10px',
                                            }}
                                          ></i>
                                        </a>
                                        <a href="#no">
                                          <i
                                            className="fa fa-minus"
                                            onClick={() =>
                                              this.setState((prev) => {
                                                if (prev.kitSampleQty === 1)
                                                  return;
                                                return {
                                                  kitSampleQty:
                                                    prev.kitSampleQty - 1,
                                                };
                                              })
                                            }
                                          ></i>
                                        </a>
                                      </div>
                                    </div>
                                  </MDBRow>
                                  <MDBRow>
                                    Special notes:
                                    <textarea
                                      value={this.state.specialNotes}
                                      onChange={(e) =>
                                        this.setState({
                                          specialNotes: e.target.value,
                                        })
                                      }
                                      maxLength={500}
                                      style={{
                                        width: '100%',
                                        maxHeight: 100,
                                        minHeight: 100,
                                        fontSize: 12,
                                      }}
                                    />
                                  </MDBRow>
                                </MDBCol>
                              </MDBCol>
                              <MDBCol md="12" className="text-center mt-4">
                                <div className="btn-area">
                                  <MDBBtn
                                    disabled={!detaildata.pdfUrl}
                                    className="btn-outline-grey greylinebtn"
                                    onClick={() =>
                                      this.pdfonClick(detaildata.pdfUrl)
                                    }
                                  >
                                    GRAPHIC
                                  </MDBBtn>
                                  <MDBBtn
                                    className="btn-outline-grey greylinebtn"
                                    onClick={() =>
                                      this.onClickCSV(
                                        detaildata.id,
                                        detaildata.number,
                                      )
                                    }
                                  >
                                    Lidstock
                                  </MDBBtn>
                                  <MDBBtn
                                    className="btn-outline-grey greylinebtn"
                                    onClick={() =>
                                      this.pdfGenerate(
                                        detaildata.id,
                                        detaildata.number,
                                      )
                                    }
                                  >
                                    PDF
                                  </MDBBtn>
                                </div>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol md="12" className="text-center mt-4">
                                <div className="btn-section mtb2">
                                  <MDBBtn
                                    className="mb20-xs btn-outline-red"
                                    onClick={() =>
                                      this.kitMakerComponentsPage()
                                    }
                                  >
                                    Cancel
                                  </MDBBtn>
                                  <MDBBtn
                                    className=" mb20-xs gbtn"
                                    type="submit"
                                    value="submit"
                                  >
                                    Continue
                                  </MDBBtn>
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </form>
                        </div>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </LoadingOverlay>
          </div>
        </div>
      );
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentCatalogRequestSample);
