import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash-es';

import SearchSection from '../Settings/Components/CompatibleReplacements/SearchSection';
import {
  clearComponentCatalogList,
  loadComponentCatalog,
} from '../../redux/actions/componentCategories';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 270px;
  height: 100vh;
  margin-top: -11px;
  background: #1d265b;
  color: white;
  z-index: 1;
`;

const Content = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: inherit;
  height: calc(100% - 55px);
  padding: 20px 10px;
  background: #1d265b;
  text-align: center;
`;

const Sku = styled.h4`
  font-weight: 700;
`;

const SearchInput = styled(SearchSection)`
  margin-top: 4rem;
`;

const Gap = styled.div`
  flex: 0 1 100%;
`;

const BackToKitRequestButton = styled.a`
  font-size: 14px;
  cursor: pointer;
`;

const KitMakerComponentsMenu = ({ sku, selectComponent, currentKitId }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { components } = useSelector(
    (state) => ({
      components: state.componentCatalog.list,
      componentsLoading: state.componentCatalog.componentsLoading,
    }),
    shallowEqual,
  );

  const onComponentSearch = (value) => {
    value ? searchComponent(value) : dispatch(clearComponentCatalogList());
  };

  const onSelectComponent = (component) => {
    selectComponent(component);
  };

  const searchComponent = useMemo(
    () =>
      debounce(
        (value) =>
          value &&
          dispatch(
            loadComponentCatalog({
              partTypeId: '',
              search: value,
              page: 0,
              categoryId: '',
            }),
          ),
        500,
      ),
    [dispatch],
  );

  return (
    <Container>
      <Content>
        <Sku>{sku}</Sku>
        <BackToKitRequestButton
          onClick={() =>
            history.push('/savedaskcomplete?savedKitId=' + currentKitId)
          }
        >
          {'< Kit request form'}
        </BackToKitRequestButton>
        <SearchInput
          placeholder="Add component"
          itemColor="black"
          components={components}
          assignComponent={onSelectComponent}
          onSearch={(e) => onComponentSearch(e.target.value)}
          onSearchChange={(e) => onComponentSearch(e.target.value)}
        />
        <Gap />
      </Content>
    </Container>
  );
};

export default KitMakerComponentsMenu;
