import React, { Component } from 'react';
import { MDBTabContent, MDBTabPane, MDBBtn, MDBCardImage, MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle } from "mdbreact";
import { connect } from 'react-redux';
import { KITCATALOG_PAGE_UNLOADED, LOGOUT, KITCATEGORIES } from '../../constants/actionTypes';
import agent from '../../agent';
import notfound from '../../shared/img/kitsDefaultImage.png';
import Loader from "../../components/loaders/Loader";
import Pagination from "../../components/Pagination/Pagination";

const mapStateToProps = state => ({
    ...state.auth,
    currentUser: state.common.currentUser,
});

const mapDispatchToProps = dispatch => ({
    onUnload: () =>
        dispatch({ type: KITCATALOG_PAGE_UNLOADED }),
    OnselectedTreeNode: (treeNode) =>
        dispatch({ type: KITCATEGORIES, payload: treeNode }),
    onClickLogout: () => dispatch({ type: LOGOUT }),
});
let KitArray = {
    kits: [
        {
            description: "No results found"
        }
    ],
    totalPages: 0,
    totalCount: 0
}
let storedNode = " ";
let storedNodeId = " ";
class KitCatalog extends Component {
    constructor(props) {
        super(props);
        this.changeUsername = ev => this.props.onChangeUsername(ev.target.value);
        this.changePassword = ev => this.props.onChangePassword(ev.target.value);
        this.submitForm = (username, password) => ev => {
            ev.preventDefault();
            this.props.onSubmit(username, password);
        };
        this.onUnloadForm = (Categories, ComponentId, PageSize, Page) => ev => {
            ev.preventDefault();
            this.props.onUnload(Categories, ComponentId, PageSize, Page);
        }
        this.state = {
            reloadKey: Math.random(),
            kitData: null,
            showComponent: false,
            paginationActive: false,
            isOpen: false,
            currentPage: 1,
            everyPage: 6,
            searchText: null,
            isChecked: false,
            fromChild: [],
            loading: 'initial',
            upperPageBound: 6,
            lowerPageBound: 0,
            pageBound: 3,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            justLikeKitData: {},
            likeKitId: null,
            KitId: null,
            activeItem: "1",
            savedKitData: null,
            getCartComponents: null
        }
        this.onSearch = this.onSearch.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.kitCatalogDetail = this.kitCatalogDetail.bind(this);
        this.kitCatalog = this.kitCatalog.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.kitCatalogKits = this.kitCatalogKits.bind(this);
        this.useAsBase = this.useAsBase.bind(this);
        this.getSavedKitComponents = this.getSavedKitComponents.bind(this);
        this.kitmakerform = this.kitmakerform.bind(this);
        let storedComponentNode = (window.sessionStorage.getItem("kitCategory"));
        if (storedComponentNode && storedComponentNode !== " ") {
            storedNode = JSON.parse(window.sessionStorage.getItem("kitCategory"));
            storedNodeId = storedNode && storedNode !== "null" && storedNode !== " " && storedNode != null ? storedNode.id : "";
        } else {
            storedNode = "";
            storedNodeId = "";
        }
    }
    componentWillMount() {
        const token = window.localStorage.getItem('jwt');
        if (token) {
            let kit = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
            this.setState({ KitId: kit })
            if (kit > 0) {
                agent.KITCATLOG.kitbyId(kit).then((res) => {
                    if (res === null) {
                        this.setState({ justLikeKitData: KitArray });
                    }
                    else {
                        this.setState({ justLikeKitData: res });
                    }
                }
                )
            }
            agent.KITCATLOG.kitcatalog('', '', '', kit, this.state.everyPage, this.state.currentPage).then((res) => {
                if (res.totalCount === 0) {
                    this.setState({ kitData: KitArray });
                }
                else {
                    this.setState({ kitData: res });
                }
            }
            )
            agent.SAVEDASK.savedASk().then((res) => {
                if (res === null) {
                    this.setState({ savedKitData: KitArray });
                }
                else {
                    this.setState({ savedKitData: res.body });
                }
            }
            )
            agent.CART.getCartCustomKits().then((res) => {
                if (res === null) {
                    this.setState({ getCartComponents: KitArray });

                } else {
                    this.setState({ getCartComponents: res });
                }
            })
        }
        else {
            this.props.onClickLogout()
        }
    }
    toggle = tab => e => {
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab
            });
            this.setState({ blue: !this.state.blue })
        }
        agent.CART.getCartCustomKits().then((res) => {
            if (res === null) {
                this.setState({ getCartComponents: KitArray });

            } else {
                this.setState({ getCartComponents: res });
            }
        })
    };


    componentDataKit = () => {
        const eachCard = document.querySelectorAll(".onPrdt");
        const xx = document.getElementById("mySidenav");

        let stil = window.getComputedStyle(xx).getPropertyValue("display");
        if (stil === "none") {
        } else {
            eachCard.forEach(element => {
                element.classList.add('col-xl-6')
                element.classList.remove('col-xl-4')
            });
        }
    };
    customKits() {

        const p = document.querySelectorAll(".onPrd");
        const pq = document.querySelectorAll(".onPrdt");
        const x = document.getElementById("mySidenav");
        const y = document.getElementsByClassName("ryttray");
        const z = document.getElementById("onTray");
        let stil = window.getComputedStyle(x).getPropertyValue("display");
        if (stil === "none") {
            x.style.display = "inline";
            y[0].style.display = "inline-table";
            y[0].style.right = "0";
            y[0].style.position = "relative";
            y[0].style.top = "0";
            z.style.flex = "0 0 75%";
            z.style.maxWidth = "75%";
            for (let i = 0; i < p.length; i++) {
                p[i].classList.add('col-xl-6')
                p[i].classList.remove('col-xl-3')

            }
            for (let j = 0; j < pq.length; j++) {
                pq[j].classList.add('col-xl-6')
                pq[j].classList.remove('col-xl-4')

            }
        }
        else {
            x.style.display = "none";
            y[0].style.right = '0';
            y[0].style.position = "fixed";
            y[0].style.top = "65px";
            z.style.flex = "0 0 100%";
            z.style.maxWidth = "100%";
            for (let i = 0; i < p.length; i++) {
                p[i].classList.remove('col-xl-6')
                p[i].classList.add('col-xl-3')

            }
            for (let j = 0; j < pq.length; j++) {
                pq[j].classList.remove('col-xl-6')
                pq[j].classList.add('col-xl-4')

            }
        }
    }
    componentWillUnmount() {
        this.props.onUnload();
    }
    state = {
        isOpen: false
    };

    componentDataWithCustomKit = () => {
        const eachCard = document.querySelectorAll(".onPrd");
        const xx = document.getElementById("mySidenav");

        let stil = window.getComputedStyle(xx).getPropertyValue("display");
        if (stil === "none") {
        } else {
            eachCard.forEach(element => {
                element.classList.add('col-xl-6')
                element.classList.remove('col-xl-4')
            });
        }
    }

    defaultData = () => {
        let kit = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
        if (kit > 0) {
            agent.KITCATLOG.kitbyId(kit).then((res) => {
                if (res === null) {
                    this.setState({ justLikeKitData: KitArray });
                }
                else {
                    this.setState({ justLikeKitData: res });
                    this.componentDataKit();
                }
            }
            )
        }
        agent.KITCATLOG.kitcatalog('', '', '', kit, this.state.everyPage, this.state.currentPage).then((res) => {
            if (res.totalCount === 0) {
                this.setState({ kitData: KitArray });
            }
            else {
                this.setState({ kitData: res });
                this.componentDataWithCustomKit();
            }
        }
        )
    }

    onClickGridView = () => {

        this.setState({ showComponent: false });
        this.defaultData();

    }
    onClickListView = () => {

        this.setState({ showComponent: true });
        this.defaultData();
    }
    onSearch = () => {
        let searchText = this.state.searchText;
        let kit = window.location.href.substring(window.location.href.lastIndexOf('?') + 1);
        let data = JSON.parse(decodeURIComponent(kit));
        agent.KITCATLOG.kitcatalog(storedNodeId, searchText, '', data.id, this.state.everyPage, this.state.currentPage - 1).then((res) => {
            if (res.totalCount === 0) {
                this.setState({ kitData: KitArray });
            }
            else {
                this.setState({ kitData: res });
            }
        })
    };

    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
    onClearFilter = () => {
        this.setState({ reloadKey: Math.random() });
        window.sessionStorage.setItem("kitCategory", " ");
        storedNode = "";
        storedNodeId = "";
        this.setState({ searachedCataegory: "", searchText: "" });
        this.renderComponent();
    }
    kitCatalogDetail = (kitId) => {

        let path = `/kit`;
        let data = kitId;
        this.props.history.push(path + "/" + data);
    }
    onSelect = (node, ischecked) => {
        if (ischecked) {
            this.props.OnselectedTreeNode(node);
            storedNode = JSON.parse(window.sessionStorage.getItem("kitCategory"));
            storedNodeId = storedNode.id;
            this.renderComponent();
        } else {
            this.renderComponent();
        }
    }
    renderComponent() {
        if (storedNodeId > 0) {
            agent.KITCATLOG.kitcatalog(storedNodeId, '', '', this.state.KitId, '9', '1').then((res) => {
                if (res.totalCount === 0) {
                    this.setState({ kitData: KitArray });
                }
                else {
                    this.setState({ kitData: res });
                    this.componentDataKit();
                }
            })
        } else {
            agent.KITCATLOG.kitcatalog('', '', '', this.state.KitId, '9', '1').then((res) => {
                if (res.totalCount === 0) {
                    this.setState({ kitData: KitArray });
                }
                else {
                    this.setState({ kitData: res });
                    this.componentDataKit();
                }
            })
        }
    }
    handleClick = (juslikedata) => {
        this.setState({ reloadKey: Math.random(), kitData: juslikedata });
    }
    kitCatalog() {
        window.location.reload();
    }
    loadComponentData = (search, likeKitId, pageSize, page) => {
        agent.KITCATLOG.kitcatalog(storedNodeId, search, '', likeKitId, pageSize, page).then((res) => {
            if (res.totalCount === 0) {
                this.setState({ kitData: KitArray });
            }
            else {
                this.setState({ kitData: res });
            }
        })
    };
    handleChange = ({ target }) => {
        this.setState({
            searchText: target.value,
            currentPage: Number(1)
        });
    }
    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearch();
        }
    }
    kitCatalogDetail = (kitId) => {
        let path = `/kit`;
        let data = kitId;
        this.props.history.push(path + "/" + data);
    }
    kitCatalogKits = () => {
        let path = `/kits`;
        this.props.history.push(path);
    }
    addDefaultSrc(ev) {
        ev.target.src = notfound
    }
    useAsBase = (kitId) => {
        let path = "/kitmaker"
        let data = kitId;
        this.props.history.push(path + "/" + data);
    };

    kitmakerform = () => {
        let path = "/kitmaker";
        this.props.history.push(path);
    }
    deleteComponents = (event, component) => {
        agent.CART.deleteCustomKits(component.id).then((res) => {
            agent.CART.getCartCustomKits().then((res) => {
                if (res === null) {
                    this.setState({ getCartComponents: KitArray });

                } else {
                    this.setState({ getCartComponents: res });
                    this.setState({ reloadKey: Math.random() });
                }
            })
        });
    }
    getSavedKitComponents = (kit) => {
        this.props.history.push('/kitmakercomponents?KitFromBase=' + null + "&kit=" + kit.name + "&kitId=" + kit.id + "&orederedKitId=" + null + "&isFavourite=1");
    }
    getComponentUseAsBase = () => {
        let path = `/kitmaker`;
        this.props.history.push(path + '?isFavourite=1');
    }
    getJustLike = () => {
        let path = `/savedaskjustlike?isFavorite=1`;
        this.props.history.push(path);
    }
    kits() {
        let path = `/kits`;
        this.props.history.push(path);
    }
    render() {
        if (this.state.kitData === null || this.state.savedKitData === null || this.state.getCartComponents === null) {
            return <Loader />
        } else {
            var KitCatalogArray = this.state.kitData;
            var currentTodos = KitCatalogArray.kits;
            const isListviewIn = this.state.showComponent;
            var kititem, justlikekit, divider, dataa, savedKitData, getCartComponents = "";
            const activeStyle = { color: '#61a431' };
            const inactiveStyle = { color: '#959595' };
            var savedData = this.state.savedKitData;
            var cartComponents = this.state.getCartComponents;
            savedKitData = savedData.map(kit => {
                return (
                    <div id="customkits" className="data-list" onPointerMove={this.hoverOn} onClick={(event) => this.getSavedKitComponents(kit)} >
                        <p className="kitName anchorColor" id="customKitName">{kit.name}<span></span></p>
                        <a href="#no" className="kitCount" id="customKitId">Components Count: {kit.componentsCount}</a>
                        <br />
                    </div>
                )
            })
            getCartComponents = cartComponents.map(cartComponent => {
                return (
                    <div id="cartCustom" className="data-list" >
                        <i className="fa fa-window-close" aria-hidden="true" onClick={(event) => this.deleteComponents(event, cartComponent)}></i>
                        <a href="#no" className="kitCount" id="customKitId"><p>SKU: {cartComponent.number}</p></a>
                        <p className="kitName anchorColor" id="customKitName">{cartComponent.description}<span></span></p>
                    </div>
                )
            })

            if (isListviewIn === false) {
                dataa = this.state.justLikeKitData;
                justlikekit = <MDBRow>
                    <MDBCol xl="4" lg="6" md="12" className="onPrdt" id={dataa.id}>
                        <MDBCard className="product-area prdbrdr">
                            <a href={"/kit/" + dataa.id} >
                                <MDBCardImage className="img-fluid" src={dataa.imageUrl === null ? notfound : dataa.imageUrl} onClick={() => this.kitCatalogDetail(dataa.id)} onError={() => this.addDefaultSrc()} alt="" /> </a>
                            <MDBCardBody>
                                <span>{dataa.componentsCount} Components</span>
                                <MDBCardTitle><a href={"/kit/" + dataa.id}>{dataa.number}</a></MDBCardTitle>
                                <MDBCardText>
                                    <a href={"/kit/" + dataa.id} className="anchorColor">
                                        {dataa.description} </a>
                                </MDBCardText>
                                <MDBBtn onClick={() => this.kitCatalogDetail(dataa.id)}>KIT DETAILS</MDBBtn>
                                <MDBBtn className="gbtn" onClick={() => this.useAsBase(dataa.id)}><i className="fa fa-shopping-basket" aria-hidden="true"></i>USE AS A BASE</MDBBtn>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                divider = <hr className="odivider" />
                if (this.state.kitData.totalCount > 0) {
                    kititem = currentTodos.map(kit => {
                        return (
                            <MDBCol xl="3" lg="6" md="12" className="onPrd" id={kit.id}>
                                <MDBCard className="product-area prdbrdr">
                                    <a href={"/kit/" + kit.id} >
                                        <MDBCardImage className="img-fluid" src={kit.imageUrl === null ? notfound : kit.imageUrl} onError={this.addDefaultSrc} alt="" />
                                    </a>
                                    <MDBCardBody>
                                        <span>{kit.componentsCount} Components</span>
                                        <MDBCardTitle><a href={"/kit/" + kit.id}>{kit.number}</a></MDBCardTitle>
                                        <MDBCardText>
                                            <a className="anchorColor" href={"/kit/" + kit.id}>
                                                {kit.description}
                                            </a>
                                        </MDBCardText>
                                        <MDBBtn onClick={() => this.kitCatalogDetail(kit.id)}>KIT DETAILS</MDBBtn>
                                        <MDBBtn className="gbtn" onClick={() => this.useAsBase(kit.id)}><i className="fa fa-shopping-basket" aria-hidden="true"></i>USE AS A BASE</MDBBtn>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        )
                    },
                    );
                }
                else {
                    kititem = currentTodos.map(kit => {
                        return (
                            <MDBCol xl="12" lg="12" md="12" className="text-center">
                                <p>{kit.description}</p>
                            </MDBCol>
                        )
                    }
                    )
                }
            }
            else if (isListviewIn === true) {
                dataa = this.state.justLikeKitData;
                justlikekit =
                    <MDBCard className="product-area product-area-list prdlstbrdr">
                        <MDBRow>
                            <MDBCol lg="3">
                                <h5 className="unlike"> <a href={"/kits"} >Just Like!</a></h5>
                                <a href={"/kit/" + dataa.id} >
                                    <MDBCardImage className="img-fluid" onClick={() => this.kitCatalogDetail(dataa.id)} src={dataa.imageUrl === null ? notfound : dataa.imageUrl} onError={this.addDefaultSrc} alt="" />
                                </a>
                            </MDBCol>
                            <MDBCol lg="6">
                                <MDBCardBody>
                                    <span>{dataa.componentsCount} Components</span>
                                    <MDBCardTitle><a href={"/kit/" + dataa.id}>
                                        {dataa.number}
                                    </a></MDBCardTitle>
                                    <MDBCardText>
                                        <a href={"/kit/" + dataa.id}>
                                            {dataa.description}
                                        </a>
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCol>
                            <MDBCol lg="3">
                                <div className="list-btn-area">
                                    <MDBBtn onClick={() => this.kitCatalogDetail(dataa.id)}>KIT DETAILS</MDBBtn>
                                    <MDBBtn className="gbtn" onClick={() => this.useAsBase(dataa.id)}><i className="fa fa-shopping-basket" aria-hidden="true"></i>USE AS A BASE</MDBBtn>
                                </div>
                            </MDBCol>
                            <MDBRow>
                            </MDBRow>
                        </MDBRow>
                    </MDBCard>

                divider = <hr className="odivider" />
                if (this.state.kitData.totalCount > 0) {
                    kititem = currentTodos.map(kit => {
                        return (
                            <MDBCol lg="12" key={"kitCatalogJustLike" + kit.id}>
                                <MDBCard className="product-area product-area-list prdlstbrdr">
                                    <MDBRow>
                                        <MDBCol lg="3">
                                            <a href={"/kit/" + kit.id}>
                                                <MDBCardImage className="img-fluid" onClick={() => this.kitCatalogDetail(kit.id)} src={kit.imageUrl === null ? notfound : kit.imageUrl} onError={this.addDefaultSrc} alt="" />
                                            </a>
                                        </MDBCol>
                                        <MDBCol lg="6">
                                            <MDBCardBody>
                                                <span>{kit.componentsCount} Components</span>
                                                <MDBCardTitle>
                                                    <a href={"/kit/" + kit.id}>
                                                        {kit.number}
                                                    </a></MDBCardTitle>
                                                <MDBCardText>
                                                    <a href={"/kit/" + kit.id}>
                                                        {kit.description}
                                                    </a>
                                                </MDBCardText>
                                            </MDBCardBody>
                                        </MDBCol>
                                        <MDBCol lg="3">
                                            <div className="list-btn-area">
                                                <MDBBtn onClick={() => this.kitCatalogDetail(kit.id)}>KIT DETAILS</MDBBtn>
                                                <MDBBtn className="gbtn" onClick={() => this.useAsBase(kit.id)}><i className="fa fa-shopping-basket" aria-hidden="true"></i>USE AS A BASE</MDBBtn>
                                            </div>
                                        </MDBCol>
                                        <MDBRow>
                                        </MDBRow>
                                    </MDBRow>
                                </MDBCard>
                            </MDBCol>
                        )
                    }
                    )
                }
                else {
                    kititem = currentTodos.map(kit => {
                        return (
                            <MDBCol xl="4" lg="6" md="12" className="text-center" key={"kitCatalogJustLikeDesc" + kit.id}>
                                <p>{kit.description}</p>
                            </MDBCol>
                        )
                    }
                    )
                }
            }
            return (
                <div className="main-layout">
                    <div className="content-area">
                        <MDBContainer fluid>
                            <MDBRow>
                                <MDBCol id="onTray" lg="12" md="12 " sm="6" xs="12" className="mt20-xs">
                                    <h5>Kit Catalog - Just Like</h5>
                                    <MDBRow>
                                        <MDBCol lg="12" md="6" sm="6" xs="12" className="" >
                                            <h6><span></span>
                                                <p>
                                                    <a href={"/kits"}>Go to Kit Catalog</a>
                                                    <i className="fa fa-th-large" aria-hidden="true" onClick={this.onClickGridView} style={this.state.showComponent === false ? activeStyle : inactiveStyle} key={Math.random()}></i>
                                                    <i className="fa fa-bars" aria-hidden="true" onClick={this.onClickListView} style={this.state.showComponent === true ? activeStyle : inactiveStyle} key={Math.random()}></i>
                                                </p></h6>
                                            {justlikekit}
                                            {divider}
                                            <MDBRow>
                                                {kititem}
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol className="text-center">
                                                    <Pagination
                                                        pageData={this.handleClick}
                                                        parent={("JustLikeKit")}
                                                        key={this.state.pageReload}
                                                        search={(this.state.searchText)}
                                                        likeKitId={(this.state.KitId)}
                                                    />
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                                <MDBCol lg="3" md="6" sm="6" xs="12" className="mt20-xs pl-lg-0 pl-xl-0">
                                    <div className="rytsidetray">
                                        <i className="fa fa-shopping-basket ryttray" aria-hidden="true" onClick={this.customKits}></i>
                                        <div id="mySidenav" className="sidetray kitmaker pt-2 ">
                                            <div id="authentication-layout">
                                                <div className="btn-section mtb2 tab-area mt-0">
                                                    <MDBBtn className="m-0 mb20-xs btn-whitee" active={this.state.activeItem === "1"} onClick={this.toggle("1")} >Custom kits</MDBBtn>
                                                    <MDBBtn className="m-0  mb20-xs btn-outline-indigo notwhite" active={this.state.activeItem === "2"} onClick={this.toggle("2")} > Components</MDBBtn>
                                                </div>
                                                <MDBTabContent activeItem={this.state.activeItem} >
                                                    <MDBTabPane tabId="1" role="tabpanel">
                                                        <div className="tbl-scrl">
                                                            {savedKitData}
                                                        </div>
                                                        <br />
                                                        <MDBBtn className="fullgbtn" disabled={this.state.savedKitData.length === 0 ? true : false} onClick={() => this.kitmakerform()} >CREATE NEW KIT</MDBBtn>
                                                    </MDBTabPane>
                                                    <MDBTabPane tabId="2" role="tabpanel">
                                                        <div className="tbl-scrl">
                                                            {this.state.getCartComponents.length === 0 ? <p>No starred component available right now</p> : getCartComponents}
                                                        </div>
                                                        <MDBBtn className="fullgbtn" disabled={this.state.getCartComponents.length === 0 ? true : false} onClick={() => this.getComponentUseAsBase()} >CREATE NEW KIT</MDBBtn>
                                                        <MDBBtn className="fullgbtn" disabled={this.state.getCartComponents.length === 0 ? true : false} onClick={() => this.getJustLike()}>JUST LIKE</MDBBtn>
                                                    </MDBTabPane>
                                                </MDBTabContent>
                                            </div>
                                        </div>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </div>
                </div>
            )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KitCatalog);