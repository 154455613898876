import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 0px;
    & > input {
        width: 15px;
        height: 15px;
        margin-right: 10px;
        margin-bottom: 0px;
        cursor: pointer;
    }
    & > span {
        font-weight: 500;
        font-size: 12px;
        cursor: pointer;
    }
`;


const CustomCheckbox = ({ label, checked, style, onChange, ...rest }) => {
    return (
        <Container style={style}>
            <input type="checkbox" checked={!!checked} onChange={onChange} {...rest} />
            <span onClick={onChange}>{label}</span>
        </Container>
    );
};

CustomCheckbox.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    checked: PropTypes.bool,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default CustomCheckbox;
