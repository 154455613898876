import React, { useState, useRef, useEffect } from 'react';
import Sketch from 'react-p5';
import styled from '@emotion/styled';

const Wrapper = styled.div`
    height: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CavityPreview = (props) => {
    const { cavity } = props;
    const wrapperRef = useRef(null);
    const [wrapper, setWrapper] = useState(null);

    useEffect(() => {
        if (wrapperRef.current) {
            setWrapper(wrapperRef.current);
        }
    }, []);

    const fps = 60;
    const size = [180, 185];

    const [multiplier] = useState([size[0] / 1000, size[1] / 1000]);

    const setup = (p5) => {
        p5.frameRate(fps);
        p5.createCanvas(...size).parent(wrapper);
    };

    const draw = (p5) => {
        p5.background(142, 147, 173, 255);
        p5.fill(p5.color(255, 255, 255, 90));
        p5.strokeWeight(0);
        if (cavity) {
            p5.beginShape();
            for (let p of cavity.points) {
                p5.vertex(p.x * multiplier[0], p.y * multiplier[1]);
            }
            p5.endShape(p5.CLOSE);
        } else {
            p5.textAlign(p5.CENTER, p5.CENTER);
            p5.fill(p5.color(255, 255, 255, 200));
            p5.textStyle(p5.BOLD);
            p5.textSize(12);
            p5.text("Cavity creation".toUpperCase(), 90, 60);
            p5.textStyle(p5.NORMAL);
            p5.text("please trace".toUpperCase(), 90, 90);
            p5.text("4 sided polygon".toUpperCase(), 90, 110);
            p5.text("over the tray image".toUpperCase(), 90, 130);
        }
    };

    return (
        <Wrapper ref={wrapperRef}>
            {wrapper && <Sketch setup={setup} draw={draw} />}
        </Wrapper>
    );
};

export default CavityPreview;
