import React from 'react';
import styled from '@emotion/styled';
import RadioButton from '../../../components/Inputs/RadioButton';
import CustomSelect from '../../../components/Selects/CustomSelect';
import {
  catheterSizeOptions,
  centralAccessProps,
  peripheralAccessProps,
  arterialProps,
  anesthesialProps,
} from './utils';
import CustomCheckbox from '../../../components/Inputs/CustomCheckbox';
import { multiSelectStyles } from '../../../constants/constants';
import { Procedure, Checkboxes, NeedleTypes } from './TemplateItems';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 5px;
`;

const Paragraph = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  padding: ${(props) =>
    props.noPadding ? '0px 0px 10px 0px' : '3px 0px 3px 10px'};

  & > div {
    margin-right: ${(props) => (!props.direction ? 20 : 0)}px;
  }
`;

const SelectBlock = styled.div`
  font-size: 12px;
  width: 100%;
`;

const Divider = styled.div`
  margin: 8px 0px;
  border-bottom: 1px solid white;
`;

const Template = ({ level, setLevel, filters, setFilters }) => {
  // vascular types
  const {
    us,
    ous,
    cvc,
    mac,
    centralAccess,
    midline,
    psi,
    dialysis,
    picc,
    arterialType,
    arterial,
    seldinger,
    integrated,
    sac,
    catheterSize,
    peripheralAccess,
    endurance,
  } = filters;

  // anesthesia types & // Access
  const {
    epidural,
    singleShot,
    spinal,
    combined,
    nerveBlock,
    stimulating,
    nonStimulating,
    flexBlock,
    stimuQuick,
    /*accessTray,*/ epiduralNeedleType,
  } = filters;

  return (
    <Container>
      <Paragraph noPadding>
        <RadioButton
          label="US"
          name="filters_region"
          onChange={(value) =>
            setFilters({ ...filters, ous: false, us: value })
          }
          checked={us}
        />
        <RadioButton
          label="OUS"
          name="filters_region"
          onChange={(value) =>
            setFilters({
              ...filters,
              ous: value,
              us: false,
              meds: false,
              prefilledSalineSyringe: false,
            })
          }
          checked={ous}
        />
      </Paragraph>

      <Divider />

      <RadioButton
        label="Vascular"
        name="level1"
        onChange={(value) => {
          setLevel({
            ...level,
            vascular: value,
            anesthesia: false,
            interventional: false,
          });
          setFilters({
            ...filters,
            ...anesthesialProps,
            vascular: value,
            anesthesia: false,
            interventional: false,
          });
        }}
        checked={level.vascular}
      />
      {level.vascular && (
        <>
          <Paragraph direction="column">
            <RadioButton
              label="Central Access"
              name="type"
              onChange={(value) =>
                setFilters({
                  ...filters,
                  ...peripheralAccessProps,
                  ...arterialProps,
                  ...centralAccessProps,
                  centralAccess: value,
                })
              }
              checked={centralAccess}
            />
            {centralAccess && (
              <Paragraph direction="column">
                <Paragraph direction="column">
                  <RadioButton
                    label="CVC"
                    name="Procedure"
                    onChange={(value) =>
                      setFilters({
                        ...filters,
                        ...centralAccessProps,
                        centralAccess: true,
                        cvc: value,
                      })
                    }
                    checked={cvc}
                  />
                  {cvc && (
                    <Procedure
                      filters={filters}
                      setFilters={setFilters}
                      isPressure
                      isSizes
                      isLengths
                      isLumens
                      isCoated
                    />
                  )}

                  <RadioButton
                    label="MAC"
                    name="Procedure"
                    onChange={(value) =>
                      setFilters({
                        ...filters,
                        ...centralAccessProps,
                        centralAccess: true,
                        mac: value,
                      })
                    }
                    checked={mac}
                  />
                  {mac && (
                    <Procedure
                      filters={filters}
                      setFilters={setFilters}
                      isSizes
                      isLengths
                      isCoated
                    />
                  )}

                  <RadioButton
                    label="PSI"
                    name="Procedure"
                    onChange={(value) =>
                      setFilters({
                        ...filters,
                        ...centralAccessProps,
                        centralAccess: true,
                        psi: value,
                      })
                    }
                    checked={psi}
                  />
                  {psi && (
                    <Procedure
                      filters={filters}
                      setFilters={setFilters}
                      isSizes
                      isLengths
                      isCoated
                    />
                  )}

                  <RadioButton
                    label="Dialysis"
                    name="Procedure"
                    onChange={(value) =>
                      setFilters({
                        ...filters,
                        ...centralAccessProps,
                        centralAccess: true,
                        dialysis: value,
                      })
                    }
                    checked={dialysis}
                  />
                  {dialysis && (
                    <Procedure
                      filters={filters}
                      setFilters={setFilters}
                      isSizes
                      isLengths
                      isLumens
                      isCoated
                    />
                  )}
                </Paragraph>
              </Paragraph>
            )}

            <RadioButton
              label="Peripheral Access"
              name="type"
              onChange={(value) =>
                setFilters({
                  ...filters,
                  ...centralAccessProps,
                  ...arterialProps,
                  ...peripheralAccessProps,
                  peripheralAccess: value,
                })
              }
              checked={peripheralAccess}
            />
            {peripheralAccess && (
              <Paragraph direction="column">
                <RadioButton
                  label="PICC"
                  name="Procedure"
                  onChange={(value) =>
                    setFilters({
                      ...filters,
                      ...centralAccessProps,
                      ...peripheralAccessProps,
                      peripheralAccess: true,
                      picc: value,
                    })
                  }
                  checked={picc}
                />
                {picc && (
                  <Procedure
                    filters={filters}
                    setFilters={setFilters}
                    isSizes
                    isLengths
                    isLumens
                    isPreloaded
                    isCoated
                  />
                )}

                <RadioButton
                  label="Midline"
                  name="Procedure"
                  onChange={(value) =>
                    setFilters({
                      ...filters,
                      ...centralAccessProps,
                      ...peripheralAccessProps,
                      peripheralAccess: true,
                      midline: value,
                    })
                  }
                  checked={midline}
                />
                {midline && (
                  <Procedure
                    filters={filters}
                    setFilters={setFilters}
                    isSizes
                    isLengths
                    isLumens
                    isCoated
                  />
                )}

                <RadioButton
                  label="Endurance"
                  name="Procedure"
                  onChange={(value) =>
                    setFilters({
                      ...filters,
                      ...centralAccessProps,
                      ...peripheralAccessProps,
                      peripheralAccess: true,
                      endurance: value,
                    })
                  }
                  checked={endurance}
                />
                {endurance && (
                  <Procedure
                    filters={filters}
                    setFilters={setFilters}
                    isSizes
                    isLengths
                  />
                )}
              </Paragraph>
            )}

            <RadioButton
              label="Arterial/SAC"
              name="type"
              onChange={(value) =>
                setFilters({
                  ...filters,
                  ...centralAccessProps,
                  ...peripheralAccessProps,
                  ...arterialProps,
                  arterialType: value,
                })
              }
              checked={arterialType}
            />
            {arterialType && (
              <Paragraph direction="column">
                <RadioButton
                  label="Arterial"
                  name="arterial"
                  onChange={(value) =>
                    setFilters({
                      ...filters,
                      ...arterialProps,
                      arterialType: true,
                      arterial: value,
                    })
                  }
                  checked={arterial}
                />
                {arterial && (
                  <Paragraph>
                    <RadioButton
                      label="Seldinger"
                      name="arterialPart"
                      onChange={(value) =>
                        setFilters({
                          ...filters,
                          seldinger: value,
                          integrated: false,
                        })
                      }
                      checked={seldinger}
                    />
                    <RadioButton
                      label="Integrated"
                      name="arterialPart"
                      onChange={(value) =>
                        setFilters({
                          ...filters,
                          seldinger: false,
                          integrated: value,
                        })
                      }
                      checked={integrated}
                    />
                  </Paragraph>
                )}

                <RadioButton
                  label="SAC"
                  name="arterial"
                  onChange={(value) =>
                    setFilters({
                      ...filters,
                      ...arterialProps,
                      arterialType: true,
                      sac: value,
                    })
                  }
                  checked={sac}
                />
                {sac && (
                  <Paragraph direction="column">
                    <SelectBlock>
                      <CustomSelect
                        customStyles={multiSelectStyles}
                        isSearchable={false}
                        isClearable={false}
                        options={catheterSizeOptions}
                        placeholder="Catheter Size"
                        onChange={(o) =>
                          setFilters({ ...filters, catheterSize: o })
                        }
                        value={catheterSize}
                        isMulti
                        closeMenuOnSelect={false}
                        hideIfNoOptions
                      />
                    </SelectBlock>
                  </Paragraph>
                )}
              </Paragraph>
            )}
          </Paragraph>

          <Divider />
        </>
      )}

      <RadioButton
        label="Anesthesia"
        name="level1"
        onChange={(value) => {
          setLevel({
            ...level,
            vascular: false,
            anesthesia: value,
            interventional: false,
          });
          setFilters({
            ...filters,
            ...centralAccessProps,
            ...peripheralAccess,
            ...anesthesialProps,
            ...arterialProps,
            anesthesia: value,
            vascular: false,
            interventional: false,
          });
        }}
        checked={level.anesthesia}
      />
      {level.anesthesia && (
        <>
          <Paragraph direction="column">
            <RadioButton
              label="Epidural"
              name="type2"
              onChange={(value) =>
                setFilters({
                  ...filters,
                  ...anesthesialProps,
                  epidural: value,
                })
              }
              checked={epidural}
            />
            {epidural && (
              <NeedleTypes
                filters={filters}
                setFilters={setFilters}
                isEpidural
                isPouch
                isMultiport
                isSpinal={epiduralNeedleType?.length > 0}
              />
            )}

            <RadioButton
              label="Single Shot"
              name="type2"
              onChange={(value) =>
                setFilters({
                  ...filters,
                  ...anesthesialProps,
                  singleShot: 'singleShot',
                })
              }
              checked={singleShot}
            />
            {singleShot && (
              <NeedleTypes
                filters={filters}
                setFilters={setFilters}
                isEpidural
              />
            )}

            <RadioButton
              label="Spinal"
              name="type2"
              onChange={(value) =>
                setFilters({
                  ...filters,
                  ...anesthesialProps,
                  spinal: 'spinal',
                })
              }
              checked={spinal}
            />
            {spinal && (
              <NeedleTypes filters={filters} setFilters={setFilters} isSpinal />
            )}

            <RadioButton
              label="Combined Spinal/Epidural"
              name="type2"
              onChange={(value) =>
                setFilters({
                  ...filters,
                  ...anesthesialProps,
                  combined: 'combined',
                })
              }
              checked={combined}
            />
            {combined && (
              <NeedleTypes
                filters={filters}
                setFilters={setFilters}
                isSpinal
                isEpidural
                isPouch
                isMultiport
              />
            )}

            <RadioButton
              label="Peripheral Nerve Block (PNB)"
              name="type2"
              onChange={(value) =>
                setFilters({
                  ...filters,
                  ...anesthesialProps,
                  nerveBlock: 'nerveBlock',
                })
              }
              checked={nerveBlock}
            />
            {nerveBlock && (
              <Paragraph direction="column">
                <RadioButton
                  label="Stimulating"
                  name="nerveBlock"
                  onChange={(value) =>
                    setFilters({
                      ...filters,
                      ...anesthesialProps,
                      nerveBlock: 'nerveBlock',
                      stimulating: value,
                    })
                  }
                  checked={stimulating}
                />
                {stimulating && (
                  <Paragraph>
                    <CustomCheckbox
                      label="FlexBlock"
                      onChange={() =>
                        setFilters({
                          ...filters,
                          flexBlock: !flexBlock,
                        })
                      }
                      checked={flexBlock}
                    />
                    <CustomCheckbox
                      label="StimuQuick"
                      onChange={() =>
                        setFilters({
                          ...filters,
                          stimuQuick: !stimuQuick,
                        })
                      }
                      checked={stimuQuick}
                    />
                  </Paragraph>
                )}

                <RadioButton
                  label="Non-stimulating (ex. AB-21100-US needle)"
                  name="nerveBlock"
                  onChange={(value) =>
                    setFilters({
                      ...filters,
                      ...anesthesialProps,
                      nerveBlock: 'nerveBlock',
                      nonStimulating: value,
                    })
                  }
                  checked={nonStimulating}
                />
              </Paragraph>
            )}
          </Paragraph>
        </>
      )}

      <Divider />

      <RadioButton
        label="Interventional"
        name="level1"
        onChange={(value) => {
          setLevel({
            ...level,
            vascular: false,
            anesthesia: false,
            interventional: value,
          });
          setFilters({
            ...filters,
            ...anesthesialProps,
            interventional: value,
            vascular: false,
            anesthesia: false,
          });
        }}
        checked={level.interventional}
      />

      <Divider />

      <Checkboxes filters={filters} setFilters={setFilters} />
    </Container>
  );
};

export default Template;
