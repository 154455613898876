import React from 'react';
import styled from '@emotion/styled';
import {
  faEye,
  faEyeSlash,
  faImage,
  faShoppingBasket,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import CustomButton from '../../../components/Buttons/CustomButton';

const Container = styled.div(
  ({ isListView }) => `
    width: ${isListView ? 100 : 22}%;
    display: flex;
    flex-direction: ${isListView ? 'row' : 'column'};
    background: white;
    height: ${isListView ? 130 : 330}px;
    align-items: ${isListView && 'center'};
    padding: 4px 4px 0px 4px;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    margin: 15px 15px;
    justify-content: ${isListView && 'space-between'};
    margin-right: ${isListView && '40px'};
    flex: 1 0 auto;
    max-width: ${isListView ? '100%' : '400px'};
`,
);

const ImageSection = styled.div(
  ({ isListView }) => `
    display: flex;
    flex-direction: column;
    height: ${isListView ? 100 : 65}%;
    width: ${isListView && '20%'};
    justify-content: center;
    background: #F6F6F6;
    align-items: center;
    color: #8E92AD;
    position: relative;
    cursor: pointer;
    svg {font-size: ${isListView ? 60 : 80}px;}
    img {
        max-width: 100%;
        max-height: 100%;
    }
`,
);

const JustLike = styled.span(
  ({ isListView }) => `
    color: #ffffff;
    background: #f69007;
    font-size: ${isListView ? 9 : 11}px;
    font-weight: 400;
    height: ${isListView ? 35 : 40}px;
    width: ${isListView ? 35 : 40}px;
    border-radius: 50%;
    padding: 4px 0;
    text-align: center;
    cursor: pointer;
    right: ${isListView ? 5 : 10}px;
    top: ${isListView ? 5 : 10}px;
    position: absolute;
`,
);

const Description = styled.div`
  font-size: 12px;
  padding: 10px;
  display: flex;
  flex-direction: column;

  a:first-of-type {
    font-size: 14px;
    font-weight: 500;
    padding: 2px 0px;
  }

  a:last-of-type {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    color: black;
  }
`;

const Buttons = styled.div(
  ({ isListView }) => `
    display: flex;
    font-size: 11px;
    height: ${isListView && '100%'};
    flex-direction: ${isListView ? 'column' : 'row'};
    width: ${isListView && '40%'};
    justify-content: ${isListView && 'space-around'};
    align-items: ${isListView && 'center'};
    margin: 0px -4px -5px -4px;
    position: relative;
    button {
        width: 50%;
        border-radius: 0px;
        padding: 10px 10px;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    }
    button:first-of-type {color: black;}
`,
);

const Kit = ({
  onKitClick,
  onJustLikeClick,
  onUseAsBaseClick,
  kit,
  updateKitVisibility,
  isListView,
  isSales,
}) => {
  return (
    <Container isListView={isListView}>
      <ImageSection onClick={() => onKitClick(kit.id)} isListView={isListView}>
        {kit.imageUrl ? (
          <img src={kit.imageUrl} alt="Kit" />
        ) : (
          <>
            <FontAwesomeIcon icon={faImage} />
            <span style={{ fontSize: 14, fontWeight: 500 }}>
              No Image Avilable
            </span>
          </>
        )}
        <JustLike
          onClick={(e) => {
            e.stopPropagation();
            onJustLikeClick(kit.id);
          }}
          isListView={isListView}
        >
          Just Like!
        </JustLike>
      </ImageSection>
      <Description>
        <span>{kit.componentsCount} Components</span>
        <NavLink to={`/kit/${kit.id}`}>{kit.number}</NavLink>
        <NavLink to={`/kit/${kit.id}`}>{kit.description}</NavLink>
      </Description>
      <Buttons isListView={isListView}>
        {!isSales && (
          <FontAwesomeIcon
            icon={kit.isVisible ? faEye : faEyeSlash}
            style={{
              position: 'absolute',
              bottom: !isListView ? 85 : 56,
              right: !isListView ? 20 : 330,
              fontSize: 16,
              color: kit.isVisible ? '#61A431' : 'orangered',
              cursor: 'pointer',
            }}
            onClick={() => updateKitVisibility(kit.id)}
          />
        )}
        <CustomButton
          label="KIT DETAILS"
          background="#EDEDED"
          onClick={() => onKitClick(kit.id)}
        />
        <CustomButton
          label="USE AS A BASE"
          background="#61A431"
          icon={<FontAwesomeIcon icon={faShoppingBasket} />}
          onClick={() => onUseAsBaseClick(kit.id)}
        />
      </Buttons>
    </Container>
  );
};

export default React.memo(Kit);
