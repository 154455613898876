import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { Provider } from 'react-redux';
import React from 'react';
import { store } from './store';
import '../src/css/App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import { createRoot } from 'react-dom/client';
import { createBrowserHistory } from 'history';

import App from './containers/App';
import * as Sentry from '@sentry/browser';

import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig, setUserDataToLocalStorage } from './authConfig';
import { logout } from './redux/actions/auth';

// import mixpanel from 'mixpanel-browser';
// import { MixpanelProvider } from 'react-mixpanel';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://da13cd71ab6e4830bb0881bbde4dfc09@sentry.io/1874066',
  }); // Production panel
}

const root = createRoot(document.getElementById('root'));
const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
const account = msalInstance.getActiveAccount();

if (!account && accounts.length) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((e) => {
  if (e.eventType === EventType.LOGIN_SUCCESS && e.payload.account) {
    setUserDataToLocalStorage(
      e.payload,
      msalInstance,
      store.dispatch,
      createBrowserHistory(),
    );
  }

  if (e.eventType === EventType.LOGOUT_END) {
    store.dispatch(logout());
  }
});

root.render(
  // <MixpanelProvider mixpanel={mixpanel}>
  <Provider store={store}>
    <Router>
      <App instance={msalInstance} />
    </Router>
  </Provider>,
  // </MixpanelProvider>
);

