import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { orderBy, sortBy } from "lodash-es";
import Confirmation from "../../../components/Confirmation/Confirmation";
import FancyScroll from "../../../components/FancyScroll";
import { manufacturing } from "../../../constants/constants";
import { clearComponentCatalogList } from "../../../redux/actions/componentCategories";
import List from "../../Settings/KitTrays/List";
import GoBack from "../Helpers/GoBack";
import ButtonGroup from "../../../components/Buttons/ButtonGroup";
import SortSwitcher from "../Helpers/SortSwitcher";
import PagesComponentsSwitcher from './Snapshots/PagesComponentsSwitcher';

const NotShown = styled.div`
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  color: orange;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  a:hover {
    background: #cdcde0;
    color: black;
  }
`;

const SwitchWrapper = styled.div`
  padding-top: 30px;
  button {
    font-size: 12px;
  }
`;

const buttonsMode = [
  { label: 'Not Shown', value: 0 },
  { label: 'All components', value: 1 },
];

const ListComponents = (props) => {
  const {
    selectedCavity,
    setSelectedCavity,
    deleteComponent,
    status,
    kit,
    /*customizeKit,*/ accessLock,
    setFilterComponents,
    setExcludeFloating,
  } = props;
  const { kitDetails } = useSelector((state) => ({
    kitDetails: state.kits.kitDetails,
  }));
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [showConfirmationData, setShowConfirmationData] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const history = useHistory();
  const dispatch = useDispatch();
  const component = history.location.state?.component;

  const isMyKitList = !!history.location.pathname.includes('/my-kit-list');

  const componentAssignments = kitDetails?.componentAssignments || [];
  const components =
    componentAssignments.map((item) => ({
      ...item.component,
      cavity: item.cavityId,
      isOrphan: item.isOrphan,
    })) || [];
  const activeKit = kit ? kit : kitDetails || [];
  activeKit.activeTray = { number: kitDetails?.tray?.number };
  const isDetailsPage = history.location.pathname.includes('/details');
  const hideSwapComponentElement =
    history.location.state?.location?.includes('/add-component') ||
    isDetailsPage;

  useEffect(() => {
    setFilterComponents &&
      setFilterComponents({ from: undefined, to: undefined }); // bad solution, but mapping func depends from undefined
    setExcludeFloating && setExcludeFloating(false);
  }, [setFilterComponents, setExcludeFloating]);

  useEffect(() => {
    if (selectedCavity) {
      setSelectedCavity(null);
    }
  }, [selectedCavity, setSelectedCavity]);

  useEffect(() => {
    if (component) {
      setSelectedComponent(component);
    }
  }, [component]);

  useEffect(() => {
    dispatch(clearComponentCatalogList());
  }, [selectedComponent, dispatch]);

  const handleDeleteComponent = () => {
    deleteComponent(
      showConfirmationData.id,
      showConfirmationData.cavity,
      isMyKitList,
    );
    setSelectedCavity(null);
    setShowConfirmationData(null);
  };

  const handleComponentClick = (component) => {
    const cavity = kitDetails.tray.cavities.find(
      (cavity) => cavity.id === component.cavity,
    );

    const assigment = componentAssignments.find((x) => {
      if (cavity) {
        return x.cavityId === cavity?.id && x.component.id === component.id;
      }

      return x.component.id === component.id && x.cavityId === null;
    });

    history.push({
      pathname: `/my-kit-list/${activeKit.id}/kitmaker/${
        cavity ? 'add-component' : 'orphan-components/place-in-tray'
      }`,
      state: {
        cavity,
        component: {
          ...assigment,
          ...assigment.component,
        },
      },
    });

    setSelectedComponent(component);
  };

  const customGoBack = () => {
    if (hideSwapComponentElement && selectedComponent && !component) {
      setSelectedComponent(null);
      setSelectedCavity(null);
    } else {
      history.goBack();
    }
  };

  const uniqueComponents = (components, mode) => {
    const result = components
      .filter((x) => mode === 1 || !x.cavity)
      .reduce((acc, curr, _, arr) => {
        if (!acc.find((x) => x.id === curr.id && x.cavity === curr.cavity)) {
          acc.push({
            ...curr,
            count: arr.filter(
              (x) => x.id === curr.id && x.cavity === curr.cavity,
            ).length,
          });
        }

        return acc;
      }, []);

    return sortBy(result, ['labelName']);
  };

  const showActiveComponent = isDetailsPage && selectedCavity;

  const [mode, setMode] = useState(1);

  return (
    <Container>
      <GoBack title="Components" goBack={customGoBack} />
      <PagesComponentsSwitcher />
      <List
        list={[activeKit]}
        isActive={activeKit}
        onItemClick={() => null}
        isStatic
      />

      <SwitchWrapper>
        <SortSwitcher
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
        />
        <br />
        <ButtonGroup
          buttons={buttonsMode}
          isActive={buttonsMode.find((x) => x.value === mode)?.value}
          onClick={(button) => setMode(button.value)}
          borderRadius={0}
          activeWhite={true}
          borderColor={'white'}
        />
      </SwitchWrapper>
      <NotShown>Not Shown Components Displayed in Orange</NotShown>
      {showActiveComponent ? (
        <List
          list={[
            components.find(
              (component) => component.cavity === selectedCavity.id,
            ),
          ]}
          isActive={components.find(
            (component) => component.cavity === selectedCavity.id,
          )}
          title="Active Component"
          onItemClick={() => null}
          isStatic
        />
      ) : (
        <FancyScroll offset={!activeKit?.imageUrl ? 360 : 530}>
          <List
            list={orderBy(
              uniqueComponents(components, mode),
              'number',
              sortDirection,
            )}
            onItemClick={
              status === manufacturing || accessLock
                ? () => null
                : handleComponentClick
            }
            onItemDelete={deleteComponent ? setShowConfirmationData : null}
            isActive={selectedComponent}
          />
        </FancyScroll>
      )}
      {showConfirmationData && (
        <Confirmation
          confirm={() => handleDeleteComponent()}
          text="Are you sure you want to delete this Component?"
          close={() => setShowConfirmationData(null)}
        />
      )}
    </Container>
  );
};

export default ListComponents;
